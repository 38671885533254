import { FETCHING_PROPERTY_PROFILE, GET_PROPERTY_PROFILE_FAIL, GET_PROPERTY_PROFILE_SUCCESS } from './PropertyProfileActionTypes';

/**
 * Report reducer
 *
 * @export
 * @param {object} state
 * @param {ReportAction} action
 * @returns {object} state
 */
const initialPropertyProfile = {
    isFetchingPropertyProfile: false,
    propertyProfile:{},
    error:{}
}

const fetchingPropertyProfile = (state) => ({
    ...state,
    isFetchingPropertyProfile: true
});

const successfullyFetchPropertyProfile = (state, propertyProfile) => ({
    ...state,
    propertyProfile,
    isFetchingPropertyProfile: false,
    error: {}
});

const errorFetchingPropertyProfile = (state, error) => ({
    ...state,
    error,
    isFetchingPropertyProfile: false
});

const propertyProfile = (state = initialPropertyProfile, action = {}) => {
    switch (action.type) {
        case FETCHING_PROPERTY_PROFILE:
            return fetchingPropertyProfile(state);
        case GET_PROPERTY_PROFILE_SUCCESS:
            return successfullyFetchPropertyProfile(state, action.payload);
        case GET_PROPERTY_PROFILE_FAIL:
            return errorFetchingPropertyProfile(state, action.payload);
        default:
            return state;
    }
};

export default propertyProfile;
