import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import find from "lodash/find";
import toNumber from "lodash/toNumber";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import DepositFlow from "../ProspectDeposit/ProspectDeposit";

class EmdRouteDeposit extends React.Component {
    getProfile = () => get(this.props.user, "profile");

    componentDidMount = () => {
        const { user, history } = this.props;
        const isProfileFetched = get(user, "isProfileFetched");
        const isNotEmd = !get(user, "profile.publicProfile.userInfo.prospect");

        if (isProfileFetched && isNotEmd) {
            history.replace("/");
        }
    };

    getParamId = () => get(this.props, "match.params.id");

    findEarnestMoneyDeposit = () => {
        const earnestMoneyDeposit = get(
            this.props,
            "user.profile.earnestMoneyDeposits"
        );
        const emdId = toNumber(this.getParamId());
        return find(earnestMoneyDeposit, { id: emdId });
    };

    render = () => {
        return (
            <Switch>
                <Route
                    path={`${this.props.match.path}`}
                    render={() => (
                        <DepositFlow
                            editEMD={this.findEarnestMoneyDeposit()}
                            history={this.props.history}
                            isEdit
                        />
                    )}
                />
                <Route render={() => <Redirect to={this.props.match.path} />} />
            </Switch>
        );
    };
}

EmdRouteDeposit.propTypes = {
    user: PropTypes.object,
    history: PropTypes.any,
    match: PropTypes.any
};

export default withRouter(
    connect(
        state => ({
            user: state.user
        }),
        {}
    )(EmdRouteDeposit)
);
