import React, { Component } from 'react';
import { Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ProfilePicture } from '../ProfilePicture/ProfilePicture';
import './tabbed.css';
import './tabbed.mobile.css';

class Tabbed extends Component {
    buildTabs = () => React.Children.map(
        this.props.children,
        (item) => React.cloneElement(item, { tabClassName: 'new-tab' }),
    );

    buildProfilePicture = () => (
        <div className="new-tabs-component__profile-picture-container">
            <ProfilePicture picture={this.props.picture} />
        </div>
    );

    render = () => (
        <div className={`new-tabs-component__container${this.props.withPicture ? '--with-picture' : ''}`}>
            <Tabs
                id={this.props.id}
                activeKey={this.props.activeKey}
                animation={this.props.animation}
                className={this.props.className}
                onSelect={this.props.onSelect}
            >
                {this.props.withPicture ? this.buildProfilePicture() : null}
                {this.buildTabs()}
            </Tabs>
        </div>
    );
}

Tabbed.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    animation: PropTypes.bool,
    activeKey: PropTypes.string,
    children: PropTypes.node,
    onSelect: PropTypes.func,
    withPicture: PropTypes.bool,
    picture: PropTypes.string,
};

Tabbed.defaultProps = {
    id: 'new-tabs-component',
    className: 'new-tabs-component',
    animation: false,
    withPicture: false,
};

export default Tabbed;
