import React, { Fragment } from "react";
import { Field } from "formik";
import get from "lodash/get";
import BaseWizardStep from "../BaseWizardStep";
import { getBudgetPercentageFormatter } from "../Shared";
import { SliderField } from "../../../Form";
import mixpanel from "mixpanel-browser";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";
import { defaultBudgetRange } from "./WizardStepsContainer";

class StepDownPayment extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        mixpanel.track("Completed How Much Is Your Down Payment", {
            downPaymentPercentage: get(values, "downPayment")
        });
    };

    formatBudgetLabelFromBudget = budget => {
        const match = defaultBudgetRange.filter(f => f.value == budget);
        if (match && match.length > 0) {
            return match[0].label;
        }
        return "--";
    };

    renderDownPaymentAndLoan() {
        const {
            values: { budget, downPayment }
        } = this.props;
        const rangeFormatter = getBudgetPercentageFormatter(
            budget,
            downPayment || 0
        );
        const amount = rangeFormatter.formatAmount();

        if (amount[0] === "")
            return <div className="for-budget-estimation is-empty" />;

        return (
            <div className="for-budget-estimation">
                <div className="buyer__signup__field__row">
                    <div className="buyer__signup__field width-50">
                        <div className="budget-amount-title">
                            Estimated Loan Amount
                        </div>
                        <div className="budget-amount-value is-big">
                            {rangeFormatter.formatRange(
                                100 - (downPayment || 0)
                            )}
                        </div>
                    </div>
                    <div className="buyer__signup__field width-50">
                        <div className="budget-amount-title">
                            Estimated Down Payment
                        </div>
                        <div className="budget-amount-value is-big is-emphasized">
                            {rangeFormatter.formatRange()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const isStepFulfilled = this.areFieldsValid(["budget", "downPayment"]);
        const {
            values: { budget, downPayment }
        } = this.props;
        const labelDownPayment = `${downPayment || 0}%`;

        return (
            <Fragment>
                <ScrollToTopOnMount />
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        What is your down payment?
                    </div>

                    <div className="buyer__signup__fieldset is-beyond-widest">
                        <div className="buyer__signup__field__row no--bottom--border">
                            <div className="buyer__signup__field width-50">
                                <div className="budget-amount-title">
                                    Your Budget
                                </div>
                                <br />
                                <div className="budget-amount-value is-big">
                                    {this.formatBudgetLabelFromBudget(budget)}
                                </div>
                            </div>
                            <div className="buyer__signup__field width-50">
                                <div className="budget-amount-title">
                                    Down Payment
                                </div>
                                <div className="budget-slider">
                                    Move the slider to select your down payment
                                </div>
                                <div className="budget-amount-value">
                                    {labelDownPayment}
                                </div>
                            </div>
                        </div>

                        <div
                            className="buyer__signup__field__row"
                            style={{ marginTop: -16 }}
                        >
                            <div className="buyer__signup__field width-50"></div>
                            <div className="buyer__signup__field width-50">
                                <div style={{ position: "relative" }}>
                                    <Field
                                        component={SliderField}
                                        name="downPayment"
                                        range={[0, 100]}
                                        values={[downPayment]}
                                        step={5}
                                    />
                                </div>
                                {this.renderFieldError("downPayment")}
                            </div>
                        </div>

                        {this.renderDownPaymentAndLoan()}
                    </div>
                </div>
                {this.renderStepButtons(
                    {
                        canProceed: isStepFulfilled,
                        hasMixPanelTracking: true
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepDownPayment;
