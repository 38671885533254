import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import { Button } from '../Button/Button';

import './contentModal.css';

const small = (props) => (
    props.small ? ' content-modal--small' : ''
)

const progress = (props) => (
    props.progress ? ' content-modal--progress' : ''
)

const ContentModal = (props) => (
    <Modal
        animation
        autoFocus
        backdrop
        enforceFocus
        show={props.isVisible}
        className={`content-modal${small(props)}${progress(props)} ${props.className}`}
    >
        <ModalHeader className="content-modal__header">
            <Button
                icon 
                dark 
                symbol="times"
                type="button"
                onClick={props.onClose}
            >
            </Button>
        </ModalHeader>
        <ModalBody className="content-modal__body">
            <div className="content-modal__content-data">
                {props.content}
            </div>
            {props.isCancelButton ? <div className="content-modal__container-buttons">
                <Button
                    onClick={props.onClose}
                >
                    {props.cancelButtonText}
                </Button>
            </div> : null}
        </ModalBody>
    </Modal>
);

ContentModal.propTypes = {
    content: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    cancelButtonText: PropTypes.string,
    className: PropTypes.string,
    small: PropTypes.bool,
    isCancelButton: PropTypes.bool,
    progress: PropTypes.bool,
};

ContentModal.defaultProps = {
    cancelButtonText: 'Close',
    small: false,
    isCancelButton: true,
    progress: false,
    className: '',
};

export default ContentModal;
