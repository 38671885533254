import React, { Component } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import map from 'lodash/map';
import toString from 'lodash/toString';
import concat from 'lodash/concat';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';

import './customrealtorexperiencefield.css';

export default class CustomRealtorExperienceField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            experience: this.props.experience,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.experience !== prevState.experience) {
            this.props.onEdit(this.state.experience);
        }
    }

    onChange = (element) => {
        this.setState({ experience: element.value });
    };

    getExperienceLabel = (experience) => {
        switch (Number(experience)) {
        case 1:
            return '1 year';
        case 15:
            return '15 years or more';
        default:
            return `${experience} years`;
        }
    }

    buildValue = () => ({
        value: this.state.experience,
        label: this.getExperienceLabel(this.state.experience),
    })

    buildEditableField = () => {
        const getOptions = () => concat(map(range(1, 16), (year) => ({
            label: this.getExperienceLabel(year),
            value: toString(year),
        })));

        return (
            <AutoCompleteInput
                placeholder="Select"
                value={this.buildValue()}
                getOptions={getOptions}
                onChange={this.onChange}
                initializeGettingOptions
            />
        );
    };

    render = () => {
        return (
            <div id="realtor-tour-experience" className="realtor-experience-field">
                {this.buildEditableField()}
            </div>
        );
    };
}

CustomRealtorExperienceField.propTypes = {
    experience: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onEdit: PropTypes.func,
};
