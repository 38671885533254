import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import toString from 'lodash/toString';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import { Button } from '../Button/Button';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';

import './decliningmodal.css';

class DecliningModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisable: true,
        };
    }

    onChange = (event) => {
        const { onSelectRejection } = this.props;
        let val = false;
        if (event.value.id === '0') {
            val = true;
        }
        this.setState({
            isDisable: val,
        });
        onSelectRejection(event.value.index - 1);
    }

    onClose = () => {
        this.setState({ isDisable: true });
        this.props.onClose();
    };

    getOptions = () => map([{ id: 0, name: 'CHOOSE YOUR REASON' }, ...this.props.buyerMatchDeclinedOptions],
        (option, key) => ({
            label: option.name,
            value: { id: toString(option.id), index: key },
        }));

    render = () => (
        <Modal
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            className="decliningmodal"
        >
            <ModalHeader className="decliningmodal__header">
                <button
                    type="button"
                    className="btn-link decliningmodal__close-button"
                    onClick={this.onClose}
                >
                    <i className="fa fa-times-circle" />
                </button>
            </ModalHeader>
            <ModalBody className="decliningmodal__body">
                <span className="decliningmodal__text">
                    Why are you declining this candidate?
                </span>
                <div className="decliningmodal__container decliningmodal__container-select">
                    <AutoCompleteInput
                        isActive={!this.state.isDisable}
                        placeholder="CHOOSE YOUR REASON"
                        getOptions={this.getOptions}
                        onChange={this.onChange}
                        initializeGettingOptions
                    />
                </div>
                <Button className="decliningmodal__button" disabled={this.state.isDisable} onClick={this.props.onDeclineMatch}>Submit</Button>
            </ModalBody>
        </Modal>
    )
}

DecliningModal.propTypes = {
    isVisible: PropTypes.bool,
    buyerMatchDeclinedOptions: PropTypes.array,
    onClose: PropTypes.func,
    onDeclineMatch: PropTypes.func,
    onSelectRejection: PropTypes.func,
};

export default DecliningModal;
