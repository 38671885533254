import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import CloseModalButton from '../CloseModalButton/CloseModalButton';

import './onboarding-modal.css';

export default class OnboardingModal extends React.PureComponent {
    static propTypes = {
        isSuccessful: PropTypes.bool,
        isVisible: PropTypes.bool,
        onClose: PropTypes.func
    };

    buildTitle = () => (
        this.props.isSuccessful ?
        <h3 className="onboarding-modal__title">Congratulations!</h3> :
        <h3 className="onboarding-modal__title">Oops!</h3>
    );

    buildSuccessfulContent = () => (
        <p className="text-center">
            Your account has successfully been verified. Please log in
        </p>
    );

    buildFailedContent = () => (
        <p className="text-center">
            Unfortunately we were not able to verify your account.
            <br />
            Please contact us at <a href="mailto:hello@lemonbrew.com">hello@lemonbrew.com</a> so we can assist you further
        </p>
    );

    buildContent = () => (
        this.props.isSuccessful ?
        this.buildSuccessfulContent() :
        this.buildFailedContent()
    );

    render = () => (
        <Modal
            className="onboarding-modal"
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            onHide={this.props.onClose}
        >
            <div className="pull-right">
                <CloseModalButton onClick={this.props.onClose} />
            </div>
            {this.buildTitle()}
            {this.buildContent()}
        </Modal>
    );
}
