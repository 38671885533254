import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import AgentPhone from "./AgentPhone";

import "./adminRoleTables.css";

export default class BrokerInfo extends React.Component {
    buildTitle = () => (
        <div className="row admin-role-tables__broker-title">
            Broker Details
        </div>
    );

    buildEmail = () => {
        const email = get(this.props.broker, "email");
        const emailLink = `mailto: ${email}`;

        return (
            <div className={"admin-role-tables__broker-second-row"}>
                <div className="admin-role-tables__broker-second-row-text">
                    EMAIL
                </div>
                <div className="admin-role-tables__broker-email">
                    <a href={emailLink}>{email}</a>
                </div>
            </div>
        );
    };

    buildPhone = () => (
        <AgentPhone
            phone={get(this.props.broker, "phoneNumber")}
            withoutPadding
        />
    );

    buildLicenseId = () => (
        <div className={"admin-role-tables__broker-second-row"}>
            <div className="admin-role-tables__broker-second-row-text">
                LICENSE ID
            </div>
            <div className="admin-role-tables__broker-licence">
                {get(this.props.broker, "licenseId")}
            </div>
        </div>
    );

    getFirstName = () => get(this.props.broker, "firstName");

    getLastName = () => get(this.props.broker, "lastName");

    buildBroker = () => (
        <div className={"admin-role-tables__broker-second-row"}>
            <div className="admin-role-tables__broker-second-row-text">
                BROKER
            </div>
            <div className="admin-role-tables__broker-name-complete">
                {this.getFirstName()} {this.getLastName()}
            </div>
        </div>
    );

    buildSecondRow = () => (
        <div className="row admin-role-tables__item-row admin-role-tables__broker-row">
            <div className="col-xs-12 col-sm-6">{this.buildPhone()}</div>
            <div className="col-xs-12 col-sm-6">{this.buildLicenseId()}</div>
        </div>
    );

    buildFirstRow = () => (
        <div className="row admin-role-tables__item-row admin-role-tables__broker-row">
            <div className="col-xs-12 col-sm-6">{this.buildBroker()}</div>
            <div className="col-xs-12 col-sm-6">{this.buildEmail()}</div>
        </div>
    );

    render = () => (
        <div>
            <div className="admin-role-tables__item admin-role-tables__broker-item">
                {this.buildTitle()}
                {this.buildFirstRow()}
                {this.buildSecondRow()}
            </div>
        </div>
    );
}

BrokerInfo.propTypes = {
    broker: PropTypes.object
};
