import React, { Component } from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const mask = {
    phone: [
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d?/
    ],
    amount: createNumberMask(),
    amountWithoutSymbol: createNumberMask({
        prefix: "",
        allowDecimal: true
    }),
    number: createNumberMask({
        prefix: "",
        postfix: "",
        includeThousandsSeparator: false,
        maxValue: 100,
        minValue: 0
    })
};

export default class MaskedInputField extends Component {
    handleChange = e =>
        this.props.onChange(`${e.target.value}`.replace(/[$,._]/g, ""));

    allowDecimal = e =>
        this.props.onChange(`${e.target.value}`.replace(/[$,_ ]/g, ""));

    render = () => (
        <MaskedInput
            {...this.props}
            mask={mask[this.props.maskType]}
            type={this.props.type}
            onChange={
                !this.props.allowDecimal ? this.handleChange : this.allowDecimal
            }
        />
    );
}

MaskedInputField.defaultProps = {
    type: "text",
    allowDecimal: false
};

MaskedInputField.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    maskType: PropTypes.oneOf([
        "number",
        "phone",
        "amount",
        "amountWithoutSymbol"
    ]).isRequired,
    type: PropTypes.string
};
