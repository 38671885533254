import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import DecliningModal from '../DecliningModal/DecliningModal';
import RealtorPendingSellerMatchItem from '../RealtorPendingSellerMatchItem/RealtorPendingSellerMatchItem';
import RealtorPendingMatchItem from '../RealtorPendingMatchItem/RealtorPendingMatchItem';

export default class RealtorProfilePendingAllMatchesTab extends React.PureComponent {
    buildBuyerPendingMatch = (pendingMatch) => {
        const { isLoadingAcceptMatchingService } = this.props.buyer;
        const id = get(pendingMatch, 'userInfo.id');
        return (
            <RealtorPendingMatchItem
                key={id}
                picture={get(pendingMatch, 'userInfo.profilePicUrl')}
                name={invoke(this.props.buyer, 'buildPendingMatchFullName', pendingMatch)}
                location={invoke(this.props.buyer, 'buildPendingMatchLocation', pendingMatch)}
                budget={invoke(this.props.buyer, 'buildPendingMatchBudget', pendingMatch)}
                maxBudget={invoke(this.props.buyer, 'buildMatchMaxBudget', pendingMatch)}
                rebate={get(pendingMatch, 'rebatePercentage')}
                onAcceptMatch={() => invoke(this.props.buyer, 'onAcceptMatch', get(pendingMatch, 'userInfo.id'))}
                onRejectMatch={() => invoke(this.props.buyer, 'onRejectMatch', get(pendingMatch, 'userInfo.id'))}
                isLoading={isLoadingAcceptMatchingService[id]}
            />
        );
    };

    buildSellerPendingMatch = (pendingMatch) => {
        const { isLoadingAcceptMatchingService } = this.props.seller;
        const id = get(pendingMatch, 'userInfo.id');
        return (
            <RealtorPendingSellerMatchItem
                key={id}
                picture={get(pendingMatch, 'userInfo.profilePicUrl')}
                name={'Selling:'}
                location={get(pendingMatch, 'activeProperty.address.full')}
                homeValueLow={get(pendingMatch, 'activeProperty.homeValueLow')}
                homeValueHigh={get(pendingMatch, 'activeProperty.homeValueHigh')}
                timeFrame={get(pendingMatch, 'activeProperty.sellingTimeline')}
                onAcceptMatch={() => invoke(this.props.seller, 'onAcceptMatch', get(pendingMatch, 'userInfo.id'))}
                onRejectMatch={() => invoke(this.props.seller, 'onRejectMatch', get(pendingMatch, 'userInfo.id'))}
                isLoading={isLoadingAcceptMatchingService[id]}
            />
        );
    };

    buildBuyers = () => (
        <React.Fragment>
            <DecliningModal
                isVisible={this.props.buyer.isRejectMatchModalVisible}
                buyerMatchDeclinedOptions={this.props.buyer.buyerMatchDeclinedOptions}
                onClose={this.props.buyer.onCloseRejectMatchModal}
                onDeclineMatch={this.props.buyer.onDeclineServiceMatch}
                onSelectRejection={this.props.buyer.onSelectRejection}
            />
            {map(this.props.buyer.buyerMatchRequests, this.buildBuyerPendingMatch)}
        </React.Fragment>
    );

    buildSellers = () => (
        <React.Fragment>
            <DecliningModal
                isVisible={this.props.seller.isRejectMatchModalVisible}
                buyerMatchDeclinedOptions={this.props.seller.sellerMatchDeclinedOptions}
                onClose={this.props.seller.onCloseRejectMatchModal}
                onDeclineMatch={this.props.seller.onDeclineServiceMatch}
                onSelectRejection={this.props.seller.onSelectRejection}
            />
            {map(this.props.seller.sellerMatchRequests, this.buildSellerPendingMatch)}
        </React.Fragment>
    );

    render = () => (
        <div className="realtor-profile-pending-matches-tab__content">
            {this.buildBuyers()}
            {this.buildSellers()}
        </div>
    );
}