import React from 'react';
import * as PropTypes from 'prop-types';

import './Spinner.css';

const SpinnerShape = ({ className = '' }) =>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 103.63 122.65" className={`lemonbrew-spinner ${className}`}>
        <g>
            <path fill="#ffd149"
                  d="M85.83 16.3C66.8-2.3 36.39-2.3 17.36 16.3A47.84 47.84 0 003.1 50.52a49.467 49.467 0 0014.58 35.41l29.52 31.12a6.23 6.23 0 004.39 1.8c1.65-.01 3.23-.67 4.39-1.85L85.5 85.93a49.4 49.4 0 0014.57-35.41A47.78 47.78 0 0085.83 16.3zm-6.46 61.89A39.032 39.032 0 0151.7 89.66c-21.58-.01-39.06-17.51-39.05-39.09s17.51-39.06 39.09-39.05c21.58.01 39.06 17.51 39.05 39.09a39.121 39.121 0 01-11.42 27.58z"/>
            <path
                className="slice-1"
                fill="#ffd149"
                d="M75.23 23.88c-5.78-5.04-12.85-8.09-20.19-8.73-.83-.07-1.54.56-1.56 1.39L52.96 44c-.02 1.26 1.49 1.93 2.4 1.05l19.93-19.06c.61-.58.58-1.55-.06-2.11z"/>
            <path
                className="slice-2"
                fill="#ffd149"
                d="M87.09 48.16c-.47-7.65-3.47-14.69-8.04-20.24-.54-.65-1.51-.71-2.12-.13L57.07 46.78c-.93.89-.31 2.46.97 2.48l27.58.43c.85.02 1.52-.69 1.47-1.53z"/>
            <path
                className="slice-3"
                fill="#ffd149"
                d="M56.66 54.64l19.1 19.65c.61.63 1.63.6 2.21-.05 5.19-5.83 8.05-12.78 8.67-20.08.07-.87-.6-1.63-1.48-1.64l-27.4-.43c-1.34-.02-2.04 1.59-1.1 2.55z"/>
            <path
                className="slice-4"
                fill="#ffd149"
                d="M52.53 56.83l-.43 27.91c-.01.79.65 1.43 1.45 1.38 7.73-.45 14.84-3.49 20.64-8.21.62-.51.69-1.44.13-2.01l-19.44-20c-.86-.86-2.33-.28-2.35.93z"/>
            <path
                className="slice-5"
                fill="#ffd149"
                d="M28.19 77.29c5.78 5.04 12.85 8.09 20.19 8.73.83.07 1.54-.56 1.56-1.39l.52-27.45c.02-1.26-1.49-1.93-2.4-1.05L28.13 75.18c-.61.58-.57 1.56.06 2.11z"/>
            <path
                className="slice-6"
                fill="#ffd149"
                d="M16.33 53.01c.47 7.65 3.47 14.69 8.04 20.24.54.65 1.51.71 2.12.13l19.86-18.99c.93-.89.31-2.46-.97-2.48l-27.58-.43c-.84-.02-1.52.69-1.47 1.53z"/>
            <path
                className="slice-7"
                fill="#ffd149"
                d="M46.77 46.54l-19.1-19.65c-.61-.63-1.63-.6-2.21.05-5.19 5.83-8.05 12.78-8.67 20.08-.07.87.6 1.63 1.48 1.64l27.4.43c1.34.02 2.03-1.59 1.1-2.55z"/>
            <path
                className="slice-8"
                fill="#ffd149"
                d="M50.89 44.35l.44-27.91c.01-.79-.65-1.43-1.45-1.38-7.73.45-14.84 3.49-20.64 8.21-.62.51-.69 1.44-.13 2.01l19.44 20c.85.86 2.32.28 2.34-.93z"/>
        </g>
    </svg>;

export default class Spinner extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        show: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        show: true,
    };

    render() {
        return (
            <div className={`lemonbrew-spinner-container ${this.props.className}`}>
                <SpinnerShape className={ this.props.show ? 'active' : 'inactive' } />
            </div>
        )
    }
}
