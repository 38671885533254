import React, { Component } from "react";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import Pin from "../../images/icons/pin.svg";
import get from "lodash/get";
import _map from "lodash/map";
import { GoogleMaps } from "../GoogleMaps/GoogleMaps";
import "./comparables.scss";
import { Button } from "react-bootstrap";
import { NumberFormat } from "../NumberFormat/NumberFormat";

/* Maximum number of comparables to display when view has been expanded */
const MIN_COMPARABLES_TO_SHOW = 5;
/* Minimum number of comparables to display when view is not expanded */
const MAX_COMPARABLES_TO_SHOW = 10;

class Comparables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliceIndex: MIN_COMPARABLES_TO_SHOW
        };
    }

    onToggle = () => {
        this.setState(state => ({
            sliceIndex:
                state.sliceIndex === MIN_COMPARABLES_TO_SHOW
                    ? MAX_COMPARABLES_TO_SHOW
                    : MIN_COMPARABLES_TO_SHOW
        }));
    };

    buildPropertyAddress = propertyAddress => {
        return `${propertyAddress.substr(0, 33)} ${
            propertyAddress.length > 30 ? "..." : ""
        }`;
    };

    render() {
        const lat = get(this.props, "lat");
        const lng = get(this.props, "lng");
        const comparables = get(this.props, "comparables");
        const value = get(this.props, "value");
        const streetAddress = get(this.props, "streetAddress");
        const state = get(this.props, "state");
        const city = get(this.props, "city");
        const zipCode = get(this.props, "zip");
        const propertyAddress = `${streetAddress}, ${city}, ${state} ${zipCode}`;

        return (
            <div className="comparables__container">
                <div className="header">Comparable Sales</div>
                <div className="map__view">
                    {lat && lng && (
                        <GoogleMaps
                            center={{
                                lat: parseFloat(lat),
                                lng: parseFloat(lng)
                            }}
                            zoom={15}
                            height={"100"}
                        >
                            <MarkerWithLabel
                                position={{
                                    lat: parseFloat(lat),
                                    lng: parseFloat(lng)
                                }}
                                labelAnchor={
                                    new window.google.maps.Point(120, 120)
                                }
                                className="marker__with__label"
                                icon={{
                                    url: Pin
                                }}
                                labelStyle={{
                                    width: "233px",
                                    boxShadow: "0 1px 5px 0 rgba(0,0,0,0.5)",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                    paddingRight: "5px",
                                    backgroundColor: "#fff",
                                    borderRadius: "3px",
                                    height: "96px",
                                    position: "relative"
                                }}
                            >
                                <div>
                                    <div className="address">
                                        {this.buildPropertyAddress(
                                            propertyAddress
                                        )}
                                    </div>
                                    <div className="value">
                                        <NumberFormat
                                            number={value}
                                            format="currency"
                                            currency={"USD"}
                                            decimals={0}
                                        />
                                    </div>
                                    <div className="for__sale">
                                        <div>FOR SALE</div>
                                    </div>
                                </div>
                            </MarkerWithLabel>
                            {_map(comparables, (comparable, index) => (
                                <MarkerWithLabel
                                    key={index}
                                    position={{
                                        lat: parseFloat(comparable.Latitude),
                                        lng: parseFloat(comparable.Longitude)
                                    }}
                                    icon={{
                                        url: Pin
                                    }}
                                    labelAnchor={
                                        new window.google.maps.Point(17, 60)
                                    }
                                    labelStyle={{
                                        width: "33px",
                                        boxShadow:
                                            "0 1px 5px 0 rgba(0,0,0,0.5)",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        color: "#111",
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        backgroundColor: "#fff",
                                        borderRadius: "3px",
                                        height: "36px",
                                        textAlign: "center",
                                        paddingTop: "10px"
                                    }}
                                >
                                    <div>{index + 1}</div>
                                </MarkerWithLabel>
                            ))}
                        </GoogleMaps>
                    )}
                </div>
                <div className="list__view">
                    {comparables && (
                        <div className="comparables__table">
                            <div className="table__header">
                                <div className="item__number">
                                    <div>#</div>
                                </div>
                                <div className="mi">
                                    <div>MI</div>
                                </div>
                                <div className="address">
                                    <div>Address</div>
                                </div>
                                <div className="sold">
                                    <div>Sold</div>
                                </div>
                                <div className="sold__for">
                                    <div>Sold For</div>
                                </div>
                                <div className="sq__ft">
                                    <div>Sq. Ft.</div>
                                </div>
                                <div className="bed__baths">
                                    <div>Beds / Bths</div>
                                </div>
                                <div className="lot__size">
                                    <div>Lot Size</div>
                                </div>
                                <div className="age">
                                    <div>Age</div>
                                </div>
                            </div>
                            {_map(
                                comparables.slice(0, this.state.sliceIndex),
                                (comparable, index) => (
                                    <div key={index}>
                                        <div className="table__row">
                                            <div className="item__number">
                                                <div>{index + 1}</div>
                                            </div>
                                            <div className="mi">
                                                <div>
                                                    {comparable.Distance
                                                        ? comparable.Distance
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="address">
                                                <div>{`${
                                                    comparable.Address
                                                        .StreetAddress
                                                        ? comparable.Address
                                                              .StreetAddress
                                                        : ""
                                                },
                                                    ${
                                                        comparable.Address.City
                                                            ? comparable.Address
                                                                  .City
                                                            : ""
                                                    } ,
                                                    ${
                                                        comparable.Address.State
                                                            ? comparable.Address
                                                                  .State
                                                            : ""
                                                    }
                                                    ${
                                                        comparable.Address.Zip5
                                                            ? comparable.Address
                                                                  .Zip5
                                                            : ""
                                                    }`}</div>
                                            </div>
                                            <div className="sold">
                                                <div>
                                                    {comparable.LastSaleDate
                                                        ? comparable.LastSaleDate.split(
                                                              "T"
                                                          )[0].replace(
                                                              /-/g,
                                                              "/"
                                                          )
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="sold__for">
                                                <NumberFormat
                                                    number={
                                                        comparable.SalePrice
                                                    }
                                                    format="currency"
                                                    currency={"USD"}
                                                    decimals={0}
                                                />
                                            </div>
                                            <div className="sq__ft">
                                                <div>
                                                    {comparable.LivingArea
                                                        ? comparable.LivingArea
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="bed__baths">
                                                <div>{`${
                                                    comparable.BedRooms != null
                                                        ? comparable.BedRooms
                                                        : "-"
                                                } / 
                                                    ${
                                                        comparable.BathRooms !=
                                                        null
                                                            ? comparable.BathRooms
                                                            : "-"
                                                    }`}</div>
                                            </div>
                                            <div className="lot__size">
                                                <div>
                                                    {comparable.LotArea
                                                        ? comparable.LotArea
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="age">
                                                <div>
                                                    {comparable.Age
                                                        ? comparable.Age
                                                        : "-"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="separator" />
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
                <div className="toggle__view">
                    {comparables &&
                    comparables.length > MIN_COMPARABLES_TO_SHOW ? (
                        <Button
                            className="toggle__button"
                            onClick={this.onToggle.bind(this)}
                        >
                            <div>
                                {this.state.sliceIndex === 5
                                    ? "VIEW MORE"
                                    : "VIEW LESS"}
                            </div>
                        </Button>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
}

export default Comparables;
