import React from "react";
import PropTypes from "prop-types";
import EarnestMoneyDepositProfileDeposit from "../EarnestMoneyDepositProfileDeposit/EarnestMoneyDepositProfileDeposit";
import { NEEDS_VALIDATION } from "../EarnestMoneyDepositStatusPill/EarnestMoneyDepositStatusPill";
import "./earnestmoneydepositprofiledepositneedsvalidationbuttonwrapper.css";

export default class EarnestMoneyDepositProfileDepositNeedsValidationButtonWrapper extends React.PureComponent {
    buildDeposit = () => (
        <EarnestMoneyDepositProfileDeposit
            deposit={this.props.deposit}
            depositId={this.props.depositId}
            stateButtonShowDeposit={this.props.stateButtonShowDeposit}
            toggleShowDeposit={this.props.toggleShowDeposit}
            property={this.props.property}
            state={this.props.state}
            deposits={this.props.deposits}
            earnestMoneyDepositCancel={this.props.earnestMoneyDepositCancel}
            eligibleForCancel={this.props.eligibleForCancel}
            fetchingCancelDeposit={this.props.fetchingCancelDeposit}
            fetchCancelDeposit={this.props.fetchCancelDeposit}
            cancelDepositId={this.props.cancelDepositId}
            type={this.props.type}
            memo={this.props.memo}
            onEdiDepositClick={this.props.onEdiDepositClick}
            isMidUserRole={this.props.isMidUserRole}
            isReadOnlyUserRole={this.props.isReadOnlyUserRole}
            isImpersonatingUser={this.props.isImpersonatingUser}
        />
    );

    render = () => {
        const deposit = this.buildDeposit();

        if (this.props.state !== NEEDS_VALIDATION) {
            return deposit;
        }

        return (
            <button
                type="button"
                className="earnest-money-deposit-profile-deposit-needs-validation-button-wrapper"
                onClick={this.props.onClickOnNeedsValidation}
            >
                {deposit}
            </button>
        );
    };
}

EarnestMoneyDepositProfileDepositNeedsValidationButtonWrapper.propTypes = {
    ...EarnestMoneyDepositProfileDeposit.propTypes,
    onClickOnNeedsValidation: PropTypes.func
};
