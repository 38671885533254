import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import every from 'lodash/every';

import './spin.css';

class Spin extends React.Component {
    static propTypes = {
        colors: PropTypes.array,
        prizes: PropTypes.array.isRequired,
        startAngleValue: PropTypes.number,
        imageSizes: PropTypes.object,
        spinAngleStart: PropTypes.number,
        spinTimeTotal: PropTypes.number,
        textColor: PropTypes.string,
        successSpinWheel: PropTypes.bool,
        selectedPrizeIndex: PropTypes.number,
        spin: PropTypes.func,
        stopRotateWheel: PropTypes.func,
        stopRotateWheelWithoutPrize: PropTypes.func,
        clockwise: PropTypes.bool,
        pointerDegrees: PropTypes.number,
    };

    static defaultProps = {
        colors: ['#FFFADC', '#FFF5B9', '#FFF096', '#FFEB74', '#FFE75C', '#FFE33A', '#FFDE17'],
        startAngleValue: 270,
        imageSizes: {
            imageAngle: 95,
            dx: -40,
            dy: -40,
        },
        spinAngleStart: 30, // Angle with which it starts to spin, gives it speed
        spinTimeTotal: 12000,
        textColor: '#fff',
        successSpinWheel: false,
        clockwise: true,
        pointerDegrees: 90, // Degrees in which the marker is located (90 is the top)
    }

    constructor(props) {
        super(props);
        this.state = {
            spinTime: 0,
            arc: ((2 * Math.PI) / this.props.prizes.length), // Arc depends the count of the prizes;
            startAngle: this.props.startAngleValue * Math.PI / 180, // Start drawing from here
            isSpinClick: false,
        };
        this.images = [];
    }

    componentDidMount() {
        this.drawWheelAfterPrizeImagesAreLoaded();
    }

    drawWheelAfterPrizeImagesAreLoaded = () => {
        this.images = map(this.props.prizes, (prize) => {
            const image = new Image(40, 40);
            image.onload = () => {
                image.setAttribute('loaded', 'true');
                this.drawWheelIfAllImagesAreLoaded();
            };
            image.onerror = () => {
                image.setAttribute('loaded', 'true');
                this.drawWheelIfAllImagesAreLoaded();
            };
            image.src = prize.iconUrl;
            return image;
        });
    };

    drawWheelIfAllImagesAreLoaded = () => {
        const isImageLoaded = (image) => image.getAttribute('loaded') === 'true';
        const allLoaded = every(this.images, isImageLoaded);
        if (allLoaded) {
            this.drawSpinWheel();
        }
    };

    drawSpinWheel = () => {
        const {
            colors,
            prizes,
            imageSizes,
            textColor,
        } = this.props;
        const { startAngle, arc } = this.state;
        const canvas = document.getElementById('canvas');
        if (canvas.getContext) {
            const outsideRadius = 200;
            const textRadius = 120;
            const insideRadius = 42;

            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, 500, 500);
            ctx.font = 'bold 18px Helvetica, Arial';

            for (let i = 0; i < prizes.length; i++) {
                const angle = startAngle + i * arc;
                ctx.beginPath();
                ctx.strokeStyle = '#fff';
                ctx.lineWidth = 16;
                ctx.arc(250, 250, 200, angle, angle + arc, false);
                ctx.stroke();
                ctx.fillStyle = colors[i];
                ctx.beginPath();
                ctx.arc(250, 250, outsideRadius, angle, angle + arc, false);
                // Background wheel color
                ctx.strokeStyle = '#fff';
                ctx.lineWidth = 8;
                ctx.lineTo(250, 250);
                if (i === prizes.length - 1) {
                    ctx.beginPath();
                    ctx.arc(250, 250, outsideRadius, angle, angle + arc, false);
                    // Left and bottom border color of the last item
                    ctx.strokeStyle = '#fff';
                    ctx.lineWidth = 4;
                    ctx.lineTo(250, 250);
                    ctx.fill();
                    ctx.stroke();
                } else {
                    ctx.fill();
                    ctx.stroke();
                }
                ctx.beginPath();
                // Inside Radio
                ctx.strokeStyle = '#fff';
                ctx.arc(250, 250, insideRadius, angle, angle + arc, false);
                ctx.lineWidth = 5;
                ctx.stroke();
                ctx.save();
                ctx.fillStyle = textColor;
                ctx.translate(250 + Math.cos(angle + arc / 2) * textRadius,
                    250 + Math.sin(angle + arc / 2) * textRadius);
                ctx.rotate(angle + arc / 2 + Math.PI / 2 + 80);
                const image = this.images[i];
                ctx.drawImage(image, 0, 0, 100, 100, -10, -image.height + 10, 60, 60);
                ctx.beginPath();
                ctx.rotate(imageSizes.imageAngle * Math.PI / 180);
                ctx.restore();
            }

            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = '#009F4D';
            ctx.shadowOffsetY = 2;
            ctx.shadowColor = '#00401F';
            ctx.arc(250, 250, 40, 0, Math.PI * 2, false);
            ctx.fill();
            ctx.restore();

            // Arrow
            const arrowImage = document.getElementById('arrow');
            ctx.beginPath();
            ctx.drawImage(arrowImage, 260 - 25, 260 - (outsideRadius + 5) - 30, 30, 50);

            // center logo
            const logoImage = document.getElementById('center-logo');
            ctx.beginPath();
            ctx.drawImage(logoImage, 250 - 25, 250 - 38, 50, 50);

            // Text
            ctx.save();
            const text = 'SPIN';
            ctx.fillStyle = '#fff';
            ctx.fillText(text, 250 - 20, 250 + 25, 50, 50);
            ctx.restore();
        }
    }

    spin = () => {
        this.setState({
            spinTime: 0,
            isSpinClick: true,
        });
        this.props.spin();
        this.rotateWheel();
    }

    decelerateToRight = (selectedPrizeIndex, index) => {
        if (selectedPrizeIndex < index) {
            return 1 - 0.1 * (this.props.prizes.length - Math.abs(selectedPrizeIndex - index));
        }

        return 1 - 0.1 * Math.abs(selectedPrizeIndex - index);
    }

    decelerateToLeft = (selectedPrizeIndex, index) => {
        if (selectedPrizeIndex > index) {
            return 1 - 0.1 * (this.props.prizes.length - Math.abs(index - selectedPrizeIndex));
        }

        return 1 - 0.1 * Math.abs(index - selectedPrizeIndex);
    }

    addCircumference = (angle) => {
        if (angle < 0) {
            return this.addCircumference(angle + 360);
        }
        return angle;
    }

    getIndex = () => {
        const { pointerDegrees } = this.props;
        const { startAngle, arc } = this.state;
        const startAngleToDegrees = startAngle * 180 / Math.PI;
        const degrees = startAngleToDegrees + pointerDegrees > 0
            ? startAngleToDegrees + pointerDegrees
            : this.addCircumference(startAngleToDegrees + pointerDegrees);
        const arcToDegrees = arc * 180 / Math.PI;
        return Math.floor((360 - degrees % 360) / arcToDegrees);
    }

    getMiddle = () => {
        const { prizes, pointerDegrees, clockwise } = this.props;
        const { startAngle, arc } = this.state;
        // It is located in the center of the sector
        const middle = (360 / (prizes.length + 1)) * 0.01;
        const middleStartAngle = clockwise ? startAngle - middle : startAngle + middle;
        const startAngleToDegrees = middleStartAngle * 180 / Math.PI;
        const degrees = startAngleToDegrees + pointerDegrees > 0
            ? startAngleToDegrees + pointerDegrees
            : this.addCircumference(startAngleToDegrees + pointerDegrees);
        const arcToDegrees = arc * 180 / Math.PI;
        return Math.floor((360 - degrees % 360) / arcToDegrees);
    }

    calculateNewStartAngle = () => {
        const {
            spinAngleStart,
            spinTimeTotal,
            selectedPrizeIndex,
            clockwise,
        } = this.props;

        const easeOut = this.easeOut(this.state.spinTime, 0.3, spinAngleStart, spinTimeTotal);
        const spinAngle = spinAngleStart - easeOut;
        const index = this.getMiddle();
        if (spinAngle < 1 || easeOut > 47) {
            const decelerate = clockwise
                ? this.decelerateToRight(selectedPrizeIndex, index)
                : this.decelerateToLeft(selectedPrizeIndex, index);
            let decelerateToRadians = (decelerate * Math.PI / 180);
            decelerateToRadians = clockwise ? decelerateToRadians : -decelerateToRadians;
            this.setState((prevState) => ({
                startAngle: (prevState.startAngle + decelerateToRadians),
            }));
        } else {
            let spinAngleToRadians = (spinAngle * Math.PI / 180);
            spinAngleToRadians = clockwise ? spinAngleToRadians : -spinAngleToRadians;
            this.setState((prevState) => ({
                startAngle: (prevState.startAngle + spinAngleToRadians),
            }));
        }
    }

    rotateWheel = () => {
        const {
            spinTimeTotal,
            selectedPrizeIndex,
            successSpinWheel,
        } = this.props;
        const { spinTime } = this.state;

        this.setState((prevState) => ({ spinTime: (prevState.spinTime + 30) }));

        const index = this.getIndex();
        const isInTheMiddle = this.getMiddle() === selectedPrizeIndex;
        if (spinTime >= spinTimeTotal && successSpinWheel
                && (index === selectedPrizeIndex) && isInTheMiddle) {
            this.stopRotateWheel();
            return;
        }
        if (spinTime >= spinTimeTotal - 500
                && successSpinWheel && selectedPrizeIndex === -1) {
            this.props.stopRotateWheelWithoutPrize();
            return;
        }
        this.calculateNewStartAngle();
        this.drawSpinWheel();
        requestAnimationFrame(this.rotateWheel);
    }

    stopRotateWheel = () => (this.props.stopRotateWheel());

    easeOut = (t, b, c, d) => {
        const ts = (t /= d) * t;
        const tc = ts * t;
        return b + c * (tc + -3 * ts + 3 * t);
    }

    render() {
        return (
            <div className="spin-wheel--container">
                <canvas id="canvas" width="500" height="500" />
                <input type="button" disabled={this.state.isSpinClick} onClick={this.spin} className="btn btn-default spin-wheel__button" id="spin" />
            </div>
        );
    }
}

export default Spin;
