import React from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import isFunction from "lodash/isFunction";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import "./autoCompleteInput.css";

export default class AutoCompleteInput extends React.Component {
    static propTypes = {
        cacheOptions: PropTypes.bool,
        getOptions: PropTypes.func.isRequired,
        initializeGettingOptions: PropTypes.bool,
        onChange: PropTypes.func,
        onInputChange: PropTypes.func,
        isMulti: PropTypes.bool,
        getOptionsMillisecondsDelay: PropTypes.number,
        openMenuOnClick: PropTypes.bool,
        value: PropTypes.any,
        placeholder: PropTypes.any,
        components: PropTypes.any,
        isClearable: PropTypes.bool,
        hasError: PropTypes.bool,
        onCreateOption: PropTypes.func,
        buildCreateOptionLabel: PropTypes.func,
        isActive: PropTypes.bool,
        isOnTop: PropTypes.bool,
        isSearchable: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isOptionsOnTop: PropTypes.bool,
        isDots: PropTypes.bool,
        onBlur: PropTypes.func,
        closeMenuOnSelect: PropTypes.bool
    };

    static defaultProps = {
        hasError: false,
        cacheOptions: true,
        isActive: false,
        isOnTop: false,
        isSearchable: true,
        isDisabled: false,
        isDots: false,
        buildCreateOptionLabel: userInput =>
            `We couldn't find this brokerage. Create "${userInput}"`
    };

    componentDidMount() {
        this.optionsTimer = null;
    }

    getOptionsAfterDelay = input => {
        this.cancelPreviousOptionsFetching();
        return this.fetchOptionsAfterDelay(input);
    };

    cancelPreviousOptionsFetching = () => {
        clearTimeout(this.optionsTimer);
    };

    resolveOptions = input => {
        const { getOptions } = this.props;

        if (isFunction(getOptions)) {
            return getOptions(input);
        }

        return [];
    };

    fetchOptionsAfterDelay = input =>
        new Promise(resolve => {
            this.optionsTimer = setTimeout(
                () => resolve(this.resolveOptions(input)),
                this.props.getOptionsMillisecondsDelay || 0
            );
        });

    buildCssClassName = () => {
        if (this.props.hasError) {
            return "auto-complete-input auto-complete-input__error";
        }

        if (this.props.isActive) {
            return "auto-complete-input auto-complete-input__active";
        }

        if (this.props.isOnTop) {
            return "auto-complete-input auto-complete-input__on-top";
        }

        if (this.props.isDots) {
            return "auto-complete-input auto-complete-input__dots";
        }

        if (this.props.isOptionsOnTop) {
            return "auto-complete-input auto-complete-input__menu-on-top";
        }

        return "auto-complete-input";
    };

    buildComponents = () => {
        if (this.props.isDots) {
            return {
                IndicatorsContainer: () => null,
                Placeholder: () => (
                    <div className="auto-complete-input__placeholder--dots">
                        ...
                    </div>
                ),
                Menu: props => (
                    <components.Menu
                        {...props}
                        className="auto-complete-input__menu--dots"
                    />
                )
            };
        }
        return this.props.components;
    };

    render = () => {
        const Component = this.props.onCreateOption
            ? AsyncCreatableSelect
            : AsyncSelect;

        return (
            <Component
                className={this.buildCssClassName()}
                cacheOptions={this.props.cacheOptions}
                isMulti={this.props.isMulti}
                loadOptions={this.getOptionsAfterDelay}
                defaultOptions={this.props.initializeGettingOptions}
                openMenuOnClick={this.props.openMenuOnClick}
                value={this.props.value}
                placeholder={this.props.placeholder}
                components={this.buildComponents()}
                isClearable={this.props.isClearable}
                onInputChange={this.props.onInputChange}
                onChange={this.props.onChange}
                onCreateOption={this.props.onCreateOption}
                formatCreateLabel={this.props.buildCreateOptionLabel}
                isSearchable={this.props.isSearchable}
                isDisabled={this.props.isDisabled}
                onBlur={this.props.onBlur}
                closeMenuOnSelect={this.props.closeMenuOnSelect}
            />
        );
    };
}
