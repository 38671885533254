import pickBy from 'lodash/pickBy';
import findIndex from 'lodash/findIndex';
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { Form } from 'formik';
import StepNewLicense from './StepNewLicense';
import StepBrokerInformation from './StepBrokerInformation';
import { Button } from '../../../Button/Button';
import '../buyer-signup-form.css';
import './addNewLicense.css';

const newLicenseSteps = [
    { key: 'newLicense', component: StepNewLicense },
    { key: 'brokerInformation', component: StepBrokerInformation },
];

class WizardStepsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            showConfirm: false,
        };
    }

    componentDidMount() {
        const { status: { currentStep = '' } } = this.props;
        if (currentStep) {
            // Initialize first step
            const step = findIndex(newLicenseSteps, { key: currentStep });
            if (step > 0) this.setState({ step });
        }

        this.props.validateForm();
    }

    renderProgress = () => {
        const { step } = this.state;
        const { status: { formState } } = this.props;
        return <div className="add-new-license__progress--content">
            <div className="add-new-license__progress buyer__signup__progress">
                { newLicenseSteps.map(({ key, label, component }, index) => {
                    const isComplete = index < step || formState === 'submitted';
                    const isSkipped = component.skip && component.skip(this.props, this.state);
                    const className = [
                        'add-new-license__step step',
                        isComplete ? 'is-complete' : index === step ? 'is-current' : '',
                        isSkipped ? 'is-disabled' : ''
                    ].join(' ');
                    return (
                        <div
                            key={key}
                            onClick={() => this.goToPreviousStep(isComplete, isSkipped, index)}
                            className={className}
                        >
                            <div className="step-point">
                                { isComplete
                                    ? (isSkipped ? <i className="fas fa-times" /> : <i className="fas fa-check" /> )
                                    : null }
                            </div>
                        </div>
                    )}
                )}
            </div>
        </div>
    };

    navigateStep(delta) {
        const step = this.getNextStep(delta);
        this.setState({ step });
    }

    getNextStep(delta = 1, step = this.state.step) {
        for (let next = step + delta; next>=0 && next<=newLicenseSteps.length - 1; next += delta) {
            const { component } = newLicenseSteps[next];
            if (!(component.skip && component.skip(this.props, this.state))) {
                return next;
            }
        }
        return null;
    }

    renderStepButtons = options  => {
        const { step } = this.state;
        const { isSubmitting, submitForm } = this.props;
        const hasNextStep = this.getNextStep() !== null;

        let canProceed;
        if (typeof options === 'boolean') {
            canProceed = options;
        } else {
            canProceed = options.canProceed || false;
        }

        return (
            <div className="add-new-license__controls buyer__signup__controls">
                { step < 1
                    ? (
                        <Button
                            type="button"
                            onClick={ () => this.props.onClose() }
                        >
                            Close
                        </Button>
                     ) : (
                        <Button
                            type="button"
                            onClick={ () => this.navigateStep(-1) }
                        >
                            Back
                        </Button>
                    )
                }

                <Button
                    type="button"
                    disabled={ !canProceed }
                    className={` ${ !canProceed ? 'is-disabled' : ''}`}
                    onClick={ hasNextStep
                        ? () => this.navigateStep(1)
                        : () => submitForm()
                    }
                >
                    { isSubmitting
                        ? <i style={{ 'textAlign': 'center', 'padding': 0 }} className="fas fa-spinner fa-spin"/>
                        : <Fragment>{ hasNextStep ? 'Next': 'Save' }</Fragment>
                    }
                </Button>
            </div>
        )
    };

    trackProgress = () => {
        const { values, mapValuesToPayload, notifyUpdate } = this.props;
        
        if (!(mapValuesToPayload && notifyUpdate)) return;

        const data = this.props.mapValuesToPayload(values);
        const payload = pickBy(data, value =>
            !(value === undefined
                || (typeof value === 'number' && isNaN(value))
                || (typeof value === 'string' && value.trim().length === 0)
            )
        );

        return notifyUpdate(payload);
    };

    render() {
        const { status: { formState = 'initial' } } = this.props;

        const StepComponent = newLicenseSteps[this.state.step].component;
        if (!StepComponent) return null;

        if (formState === 'submitting') return <Redirect to="/profile" />;

        if (formState === 'submitted') return <Redirect to="/profile" />;

        return (
            <Fragment>
                { this.renderProgress() }

                <Form className="buyer__signup">
                    { formState === 'initial'
                        ? (
                            <StepComponent
                                {...this.props}
                                onTrackProgress={this.trackProgress}
                                renderNavigation={this.renderStepButtons}
                            />
                        )
                        : null
                    }
                </Form>
            </Fragment>
        );
    }
}

WizardStepsForm.propTypes = {
    status: PropTypes.object,
    userProfile: PropTypes.object,
    userInfo: PropTypes.object,
    isSubmitting: PropTypes.bool,
    submitForm: PropTypes.func,
    validateForm: PropTypes.func,
    mapValuesToPayload: PropTypes.func,
    onClose: PropTypes.func,
    states: PropTypes.func,
};

export default WizardStepsForm;
