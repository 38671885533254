import get from 'lodash/get';
import map from 'lodash/map';
import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import {components} from 'react-select';
import SimpleUrlAutoCompleteInput from '../../SimpleUrlAutoCompleteInput/SimpleUrlAutoCompleteInput';
import UserProfileContext from '../UserProfileContext';
import {Button} from '../../../components/Button/Button';
 
const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <i className="fa fa-search" />
    </components.DropdownIndicator>
);

class ProfileInterestField extends React.Component {
    static contextType = UserProfileContext;

    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        lookup: PropTypes.string,
        field: PropTypes.string,
        isArray: PropTypes.bool,
    };

    state = {
        isEditing: false
    };

    beginEditing = () => {
        const { name } = this.props;
        const savedValue = get(this.context, `profile.publicProfile.${name}`, '');
        const value = Array.isArray(savedValue)
            ? savedValue.map(value => ({
                label: value.name,
                value
            }))
            : savedValue;

        this.setState({
            value,
            isEditing: true
        });
    };

    updateValue = value => this.setState({ value });

    handleOnChange = e => this.updateValue(e.target.value);

    closeEditing = () => this.setState({ isEditing: false });

    emptyValue = () => this.props.isArray ? [] : '';

    applyValue = () => {
        const { name, field = name } = this.props;
        const rawValue = this.state.value ? this.state.value : this.emptyValue();
        const value = Array.isArray(rawValue)
            ? rawValue.map(item => item.value)
            : rawValue;

        this.context.onUpdateProfile({ [field]: value });
        this.closeEditing();
    };

    renderEditButton() {
        if (this.context.isPublicView) return null;

        const { isEditing } = this.state;
        return (
            <i className={`profile__preferences__edit fa fa-edit ${isEditing ? 'is-disabled' : ''}`}
               onClick={this.beginEditing}
            />
        )
    }

    renderTitle() {
        const { label } = this.props;
        return (
            <div className="profile__preferences__title">
                { label }
                { this.renderEditButton() }
            </div>
        )
    }

    renderEditor() {
        if (!this.state.isEditing) return null;

        const { label, lookup } = this.props;
        const { value } = this.state;
        return (
            <Fragment>
                { lookup ?
                    <SimpleUrlAutoCompleteInput
                        labelProp="name"
                        autoCompleteUri={lookup}
                        isMulti
                        getOptionsMillisecondsDelay={300}
                        value={value}
                        onChange={this.updateValue}
                        placeholder="Search"
                        components={{DropdownIndicator}}
                        allowRepeatedValues={false}
                        isClearable={false}
                    /> :
                    <div className="profile__preferences__field">
                        <textarea
                            placeholder={label}
                            onChange={this.handleOnChange}
                            value={value || ''}
                        />
                    </div>
                }
                <div className="profile__preferences__controls">
                    <Button inverted small
                        onClick={this.closeEditing}
                    >
                        Cancel
                    </Button>

                    <Button small
                        onClick={this.applyValue}
                    >
                        Apply
                    </Button>
                </div>
            </Fragment>
        );
    }

    renderValue() {
        if (this.state.isEditing) return null;

        const { name } = this.props;
        const value = get(this.context, `profile.publicProfile.${name}`, '');
        const renderedValue = Array.isArray(value)
            ? map(value, v => v.name).join(', ')
            : value;

        return (
            <div className="profile__preferences__text">{ renderedValue }</div>
        )
    }

    render () {
        return (
            <Fragment>
                { this.renderTitle() }
                { this.renderEditor() }
                { this.renderValue() }
            </Fragment>
        )
    }
}

export default ProfileInterestField;
