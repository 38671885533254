import React, { Fragment } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import join from "lodash/join";
import { format } from "date-fns";
import BrewBoardNumberRange from "./BrewBoardNumberRange";
import { MoreInfoCloseIcon, CustomMoreInfoButton } from "./MoreInfoButton";
import { STATUS_REALTOR } from "../../../shared/constants";
import AvatarAndNameCol from "./AvatarAndNameCol";
import StatusRow from "../../StatusRow/StatusRow";
import AgentPhone from "./AgentPhone";

import "../userAdminProfile.css";

class BuyerTable extends React.Component {
    buildAreasLooking = user => {
        const addresses = map(get(user, "publicProfile.newAddresses"), "city");
        return (
            <div className="admin-role-tables__address-looking">
                {join(addresses, ", ")}
            </div>
        );
    };

    buildBrewscore = user => {
        const id = get(user, "publicProfile.userInfo.id");
        const showMoreInfo = get(user, "showMoreInfo", false);
        return (
            <>
                <div className="admin-role-tables__brewscore">
                    {get(user, "publicProfile.brewScore")}
                </div>
                <div className="admin-role-tables__more-info">
                    <CustomMoreInfoButton id={id} showMoreInfo={showMoreInfo} />
                </div>
            </>
        );
    };

    buildFirstRow = user => (
        <Fragment>
            <div className="admin-role-tables__item-row buyer-table">
                <div className="admin-role-tables__action-button">
                    {this.props.buildActionButton(user)}
                </div>
                <div className="admin-role-tables__item-row-section-plh">
                    <div className="admin-role-tables__item-row-section-one">
                        <div className="admin-role-tables__buyer-title">
                            BUYER
                        </div>
                        <AvatarAndNameCol user={get(user, "publicProfile")} />
                    </div>
                    <div className="admin-role-tables__item-row-section-two">
                        <div className=" admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__buyer-title">
                                BREWSCORE
                            </div>
                            {this.buildBrewscore(user)}
                        </div>
                        <div className=" admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__buyer-title">
                                AREAS LOOKING
                            </div>
                            {this.buildAreasLooking(user)}
                        </div>
                        <div className="admin-role-tables__sale-price admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__buyer-title">
                                BUDGET
                            </div>
                            {BrewBoardNumberRange(
                                get(user, "publicProfile.budgetMin.amount"),
                                get(user, "publicProfile.budgetMax.amount")
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    buildCheckOrCrossFrom = value =>
        value ? (
            <div className="profile__summary__glyph is-ok" />
        ) : (
            <div className="profile__summary__glyph is-error" />
        );

    buildAssets = user => (
        <div className="admin-role-tables__assets">
            <div className="admin-role-tables__assets-text">ASSETS</div>
            {this.buildCheckOrCrossFrom(
                get(user, "publicProfile.assetsVerified") === true
            )}
        </div>
    );

    buildCredit = user => (
        <div className="admin-role-tables__credit">
            <div className="admin-role-tables__credit-text">CREDIT</div>
            {this.buildCheckOrCrossFrom(
                get(user, "publicProfile.creditVerified") === true
            )}
        </div>
    );

    buildEmployment = user => (
        <div className="admin-role-tables__employment">
            <div className="admin-role-tables__employment-text">EMPLOYMENT</div>
            {this.buildCheckOrCrossFrom(
                get(user, "publicProfile.employmentVerified") === true
            )}
        </div>
    );

    buildIncome = user => (
        <div className="admin-role-tables__income">
            <div className="admin-role-tables__income-text">INCOME</div>
            {this.buildCheckOrCrossFrom(
                get(user, "publicProfile.incomeVerified") === true
            )}
        </div>
    );

    buildDate = user => (
        <div className="admin-role-tables__date-content">
            <div className="admin-role-tables__date-text">CREATED DATE</div>
            <div className="admin-role-tables__date-format">
                {format(
                    get(user, "publicProfile.userInfo.ts"),
                    this.props.dateFormat
                )}
            </div>
        </div>
    );

    buildSecondRow = user => {
        const moreInfo = get(user, "showMoreInfo", false);

        if (!moreInfo) return;

        return (
            <div className="admin-role-tables__item-row secondary">
                <div className="admin-role-tables__item-row-section-phone">
                    <AgentPhone
                        phone={get(user, "publicProfile.userInfo.phone")}
                    />
                </div>
                <div className="admin-role-tables__item-row-section-list">
                    <div className="">{this.buildAssets(user)}</div>
                    <div className="">{this.buildCredit(user)}</div>
                    <div className="">{this.buildEmployment(user)}</div>
                    <div className="">{this.buildIncome(user)}</div>
                </div>
                <div className="admin-role-tables__date">
                    {this.buildDate(user)}
                </div>
            </div>
        );
    };

    buildStatus = user => {
        const approved = get(user, "hasRealtorAcceptedMatch", false)
            ? "MATCHED"
            : "NEEDAGENT";
        const status = find(STATUS_REALTOR, { id: approved });
        return <StatusRow status={status} />;
    };

    buildContentList = () => (
        <div className="admin-role-tables__body">
            {map(this.props.users, (user, index) => {
                const id = get(user, "publicProfile.userInfo.id");
                const showMoreInfo = get(user, "showMoreInfo", false);
                return (
                    <div className="admin-role-tables__item" key={index}>
                        <MoreInfoCloseIcon
                            id={id}
                            showMoreInfo={showMoreInfo}
                            onClickMoreInfo={this.props.onClickMoreInfo}
                        />
                        {this.buildStatus(user)}
                        {this.buildFirstRow(user)}
                        {this.buildSecondRow(user)}
                    </div>
                );
            })}
        </div>
    );

    render = () => <div>{this.buildContentList()}</div>;
}

export default BuyerTable;

BuyerTable.propTypes = {
    dateFormat: PropTypes.string,
    users: PropTypes.any,
    buildActionButton: PropTypes.func,
    onClickMoreInfo: PropTypes.func
};
