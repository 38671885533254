import React, { Fragment } from "react";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import isFunction from "lodash/isFunction";
import BaseWizardStep from "../BaseWizardStep";
import mixpanel from "mixpanel-browser";
import AgentsInfo from "../../../AgentsInfo/AgentsInfo";
import { chooseAgent, chooseSellerAgent } from "../../../../util/service_api";
import BuyerFlowNoAgentCard from "../../../BuyerFlowNoAgentCard/BuyerFlowNoAgentCard";
import BuyerIncreaseRebateModal from "../../../BuyerIncreaseRebateModal/BuyerIncreaseRebateModal";

import AgentsJSON from "../../../../agents.json";

class StepScore extends BaseWizardStep {
    constructor(props) {
        super(props);
        this.state = {
            isVisibleIncreaseRebateModal: false
        };
    }

    onOpenIncreaseRebateModal = () =>
        this.setState({ isVisibleIncreaseRebateModal: true });

    onCloseIncreaseRebateModal = () =>
        this.setState({ isVisibleIncreaseRebateModal: false });

    mixpanelFunction = () => {
        const isEmptyMatches = isEmpty(
            get(this.props.searchAgents, "searchAgents.agents")
        );
        if (isEmptyMatches) {
            mixpanel.track("Clicked On Submit Contact Info", {});
        } else {
            mixpanel.track("Clicked On Select Agent", {});
        }
    };

    countAgentsScanned = () =>
        Number(get(this.props.searchAgents, "searchAgents.agentsCount")) || 0;

    buildAgentsScannedInLocationFormatted = () => {
        const approximateLocations = map(
            get(this.props.values, "approximateLocations"),
            "state"
        );
        const knownLocations = map(
            get(this.props.values, "knownLocations"),
            "state"
        );
        const nonEmptyLocations = [
            ...approximateLocations,
            ...knownLocations
        ].filter(state => !!state);
        return nonEmptyLocations.join(", ");
    };

    buildAgentsScannedInCitiesFormatted = () => {
        const approximateLocations = map(
            get(this.props.values, "approximateLocations"),
            "city"
        );
        const knownLocations = map(
            get(this.props.values, "knownLocations"),
            "city"
        );
        const nonEmptyLocations = [
            ...approximateLocations,
            ...knownLocations
        ].filter(city => !!city);
        return nonEmptyLocations.join(", ");
    };

    buildTitle = () => {
        const scannedInLocation = this.buildAgentsScannedInLocationFormatted();
        return `We scanned LemonBrew Partner Agents in ${scannedInLocation}…`;
    };

    buildEmptyAgents = () => (
        <Fragment>
            <div className="buyer__signup__step">
                <div className="buyer__signup__question">
                    {this.buildTitle()}
                </div>
                <div className="buyer__signup__fieldset">
                    <BuyerFlowNoAgentCard
                        locations={this.buildAgentsScannedInCitiesFormatted()}
                    />
                </div>
            </div>
        </Fragment>
    );

    handleSelectAgent = match => {
        const { userProfile } = this.props;
        const isBuyer = get(userProfile, "publicProfile.userInfo.buyer");
        const isSeller = get(userProfile, "publicProfile.userInfo.seller");

        if (isSeller) {
            chooseSellerAgent(match.id).then(() => {
                if (isFunction(this.props.onChooseAgent)) {
                    this.props.onChooseAgent(match.agent);
                }
            });
            return;
        }

        if (isBuyer) {
            chooseAgent(match.id).then(() => {
                if (isFunction(this.props.onChooseAgent)) {
                    this.props.onChooseAgent(match.agent);
                }
            });
            return;
        }
    };

    buildAgentsList = () => {
        const { searchAgents, brewScore } = this.props;
        const agents = get(searchAgents, "searchAgents.agents");
        const hasMixPanelTracking = true;
        const rebateAmount = get(brewScore, "brewScore.rebateAmount");

        return (
            <AgentsInfo
                matches={agents}
                canSelectAgent={false}
                profile={this.props.profile}
                rebateAmount={rebateAmount}
                showStatus={true}
                handleSelectAgent={this.handleSelectAgent}
                onChooseAgent={() => {
                    setTimeout(
                        () =>
                            this.props.onNavigateStep(
                                1,
                                hasMixPanelTracking,
                                this.mixpanelFunction
                            ),
                        0
                    );
                }}
            />
        );
    };

    /**
     * This was for testing with static JSON because then endpoint is not / was not working
     * in local development mode
     */
    // buildAgentsListStaticData = () => {
    //     const { brewScore } = this.props;
    //     const agents = AgentsJSON.agents;
    //     const hasMixPanelTracking = true;
    //     const rebateAmount = 1000;

    //     return (
    //         <AgentsInfo
    //             matches={agents}
    //             canSelectAgent={false}
    //             profile={this.props.profile}
    //             rebateAmount={rebateAmount}
    //             showStatus={true}
    //             handleSelectAgent={this.handleSelectAgent}
    //             onChooseAgent={() => {
    //                 setTimeout(
    //                     () =>
    //                         this.props.onNavigateStep(
    //                             1,
    //                             hasMixPanelTracking,
    //                             this.mixpanelFunction
    //                         ),
    //                     0
    //                 );
    //             }}
    //         />
    //     );
    // };

    buildFoundAgentsTitle = () => {
        const { searchAgents } = this.props;
        const agents = get(searchAgents, "searchAgents.agents");
        const agentsCount = size(agents);

        if (agentsCount > 1) {
            return `Found ${agentsCount} highly qualified, responsive, local real estate agents that match perfectly with your profile`;
        }

        return "Found 1 highly qualified, responsive, local real estate agent that matches perfectly with your profile";
    };

    buildAgents = () => (
        <div className="buyer__signup__fieldset is-full-width">
            <div className="buyer__signup__agents-list">
                {this.buildAgentsList()}
            </div>
        </div>
    );

    buildAgentsView = () =>
        !isEmpty(get(this.props.searchAgents, "searchAgents.agents"))
            ? this.buildAgents()
            : this.buildEmptyAgents();
    /**
     * for testing with static data (endpoint was not working)
     */
    // buildAgentsView = () => {
    //     return (
    //         <div className="buyer__signup__fieldset is-full-width">
    //             <div className="buyer__signup__agents-list">
    //                 {this.buildAgentsListStaticData()}
    //             </div>
    //         </div>
    //     );
    // };

    buildIncreaseRebateModal = () => {
        const {
            onContactRebate,
            isFetchingContactRebate,
            successContactRebate,
            transactionContactTypes
        } = this.props;

        return (
            <BuyerIncreaseRebateModal
                isVisible={this.state.isVisibleIncreaseRebateModal}
                onClose={this.onCloseIncreaseRebateModal}
                onCancel={this.onCloseIncreaseRebateModal}
                onSend={onContactRebate}
                isSended={successContactRebate}
                isSending={isFetchingContactRebate}
                transactionContactTypes={transactionContactTypes}
            />
        );
    };

    render() {
        const isEmptyMatches = isEmpty(
            get(this.props.searchAgents, "searchAgents.agents")
        );

        return (
            <Fragment>
                {this.buildIncreaseRebateModal()}
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__fieldset is-almost-full-widest">
                        {this.buildAgentsView()}
                    </div>
                </div>
                {this.renderStepButtons(
                    {
                        canProceed: true,
                        hasMixPanelTracking: true,
                        buttonName: "Submit Contact Info",
                        hideNextButton: !isEmptyMatches,
                        hideBackButton: true
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepScore;
