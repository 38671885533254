import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import toNumber from "lodash/toNumber";
import { format } from "date-fns";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import AdminProfileStatsAgentInfo from "./AdminProfileStatsAgentInfo";
import AdminProfileStatsCurrentQuarter from "./AdminProfileStatsCurrentQuarter";
import StatsTable from "../AdminProfile/AdminRoleTables/StatsTable";
import {
    getStats,
    setSearchParameters
} from "../../reducers/admin/AdminActions";
import AdminProfileStatsHeader from "components/AdminProfileStatsHeader/AdminProfileStatsHeader";
import "./adminProfileStats.css";

class AdminProfileStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        };
    }

    componentDidMount = () => {
        if (isFunction(this.props.onGetStats)) {
            this.props.onGetStats();
        }
    };

    getIsFetchedStats = () => get(this.props.admin, "getStats.isFetchedStats");

    getIsFetchingStats = () =>
        get(this.props.admin, "getStats.isFetchingStats");

    getStats = () => get(this.props.admin, "getStats.stats");

    onSearchParams = params => {
        const firstDay = new Date(
            this.selectedYear(),
            this.selectedMonth() - 1,
            1
        );
        const lastDay = new Date(this.selectedYear(), this.selectedMonth(), 0);
        const firstDayFormat = format(firstDay, this.props.dateFormat);
        const lastDayFormat = format(lastDay, this.props.dateFormat);
        const data = {
            filterBy: "ROLE_REALTOR",
            startDate: firstDayFormat,
            endDate: lastDayFormat,
            ...params
        };
        if (isFunction(this.props.onSearchParams)) {
            this.props.onSearchParams(data);
        }
    };

    buildStatsTable = () => (
        <div className="admin-profile-stats__table-container">
            <StatsTable
                dateFormat={this.props.dateFormat}
                stats={get(this.getStats(), "realtorsPerState")}
                onSearchParams={this.onSearchParams}
            />
        </div>
    );

    getCurrentMonthStatsAll = () =>
        get(this.props.admin, "getStats.stats.selectedPeriodStats.all");

    getCurrentMonthStatsApproved = () =>
        get(this.props.admin, "getStats.stats.selectedPeriodStats.approved");

    getMtdAll = () => get(this.props.admin, "getStats.stats.mtdAll");

    getMtdApproved = () => get(this.props.admin, "getStats.stats.mtdApproved");

    getYtdAll = () => get(this.props.admin, "getStats.stats.ytdAll");

    getYtdApproved = () => get(this.props.admin, "getStats.stats.ytdApproved");

    getRealtorsByCurrentQuarter = () =>
        get(this.props.admin, "getStats.stats.realtorsByCurrentQuarter");

    selectedMonth = () => get(this.props.admin, "getStats.stats.selectedMonth");

    selectedYear = () => get(this.props.admin, "getStats.stats.selectedYear");

    buildInformation = () => (
        <div className="row admin-profile-stats__information">
            <AdminProfileStatsAgentInfo
                title={"NEW AGENTS"}
                number={this.getCurrentMonthStatsAll()}
                mtd={this.getMtdAll()}
                ytd={this.getYtdAll()}
            />
            <AdminProfileStatsAgentInfo
                title={"APPROVED"}
                number={this.getCurrentMonthStatsApproved()}
                mtd={this.getMtdApproved()}
                ytd={this.getYtdApproved()}
            />
            <AdminProfileStatsCurrentQuarter
                getRealtorsByCurrentQuarter={this.getRealtorsByCurrentQuarter()}
            />
        </div>
    );

    onChangeDate = date => this.setState({ date });

    onChangeViewDate = () =>
        this.setState({ isVisibleCalendar: !this.state.isVisibleCalendar });

    handleSelectStateMonth = v => {
        const month = toNumber(v.target.value);
        const data = {
            month,
            year: this.selectedYear()
        };
        if (isFunction(this.props.onGetStats)) {
            this.props.onGetStats(data);
        }
    };

    handleSelectStateYear = v => {
        const year = toNumber(v.target.value);
        const data = {
            month: this.selectedMonth(),
            year
        };
        if (isFunction(this.props.onGetStats)) {
            this.props.onGetStats(data);
        }
    };

    buildResults = () => (
        <>
            <AdminProfileStatsHeader
                selectedDate={{
                    month: this.selectedMonth(),
                    year: this.selectedYear()
                }}
                onChangeStartMonth={this.handleSelectStateMonth}
                onChangeStartYear={this.handleSelectStateYear}
            />
            <div className="admin-profile-stats__container">
                {this.buildInformation()}
                {this.buildStatsTable()}
            </div>
        </>
    );

    buildContent = () => {
        if (this.getIsFetchingStats()) {
            return <SpinnerLoader />;
        }

        return this.buildResults();
    };

    render = () => (
        <div className="admin-profile-stats">{this.buildContent()}</div>
    );
}

AdminProfileStats.propTypes = {
    admin: PropTypes.object,
    onGetStats: PropTypes.func,
    onSearchParams: PropTypes.func
};

AdminProfileStats.defaultProps = {
    dateFormat: "YYYY-MM-DD"
};

export default connect(
    state => ({
        admin: state.admin
    }),
    {
        onGetStats: getStats,
        onSearchParams: setSearchParameters
    }
)(AdminProfileStats);
