import React, { useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import isFunction from "lodash/isFunction";
import Button from "components/Button/Button";
import "./plaid-button.css";

const PlaidButton = ({ product, onSuccess, onBeforeOpenPlaid, ...props }) => {
    const onPlaidSuccess = useCallback(
        (token, metadata) => {
            if (isFunction(onSuccess)) {
                onSuccess({ token, metadata });
            }
        },
        [onSuccess]
    );

    const config = {
        clientName: "LemonBrew",
        env: process.env.REACT_APP_PLAID_ENVIRONMENT || "sandbox",
        product: product || ["auth"],
        publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
        selectAccount: true,
        onSuccess: onPlaidSuccess
    };

    const { error, open, ready } = usePlaidLink({ ...config });

    const handleClick = () => {
        const isBeforeOptionFunction = isFunction(onBeforeOpenPlaid);

        Promise.resolve()
            .then(() => {
                if (isBeforeOptionFunction) {
                    return onBeforeOpenPlaid();
                }
            })
            .then(open);
    };

    return (
        <Button
            {...props}
            onClick={handleClick}
            disabled={props.disabled || error || !ready}
        >
            {props.children}
        </Button>
    );
};

export default PlaidButton;
