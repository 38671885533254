import pickBy from "lodash/pickBy";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import * as PropTypes from "prop-types";
import { Form } from "formik";
import { Button } from "../../../../components/Button/Button";

import StepPropertyIsWorth from "./StepPropertyIsWorth.jsx";
import StepAddress from "./StepAddress";
import StepTimeline from "./StepTimeline";
import StepContact from "../../StepContactPhone/StepContact";
import StepConfirm from "./StepConfirm";
import StepFinish from "./StepFinish";
import StepScanningAgents from "../../BuyerSignUp/WizardSteps/StepScanningAgents";
import StepAgents from "../../BuyerSignUp/WizardSteps/StepAgents";
import { getStepInfoByKey } from "./WizardStepMap";

import "../../BuyerSignUp/buyer-signup-form.css";

class WizardStepsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            showConfirm: false,
            confirmText: ""
        };
    }

    componentDidMount() {
        const {
            status: { currentStep = "" }
        } = this.props;
        if (currentStep) {
            // Initialize first step
            const step = findIndex(this.getSteps(), { key: currentStep });
            if (step > 0) this.setState({ step });
        }
        this.props.validateForm();
    }

    renderProgress() {
        const progressBarArray = this.getSteps();
        const { step } = this.state;
        const {
            status: { formState }
        } = this.props;
        let actualndex = Math.round((step * 100) / progressBarArray.length);
        return (
            <div className="buyer__signup__progress__wrap">
                <div className="buyer__signup__flow__percentage">
                    {actualndex}%
                </div>
                <div className="buyer__signup__goal">Meet Your Agent</div>
                <div className="buyer__signup__progress buyer__flow">
                    {progressBarArray.map(
                        ({ key, label, component }, index) => {
                            const isComplete =
                                index < step || formState === "submitted";
                            const isSkipped =
                                component.skip &&
                                component.skip(this.props, this.state);
                            const className = [
                                "step",
                                isComplete
                                    ? "is-complete"
                                    : index === step
                                    ? "is-current"
                                    : isSkipped
                                    ? "is-disabled"
                                    : "",
                                progressBarArray[index] === progressBarArray[0]
                                    ? "is-first"
                                    : "",
                                progressBarArray[index] ===
                                progressBarArray[progressBarArray.length - 1]
                                    ? "is-last"
                                    : ""
                            ].join(" ");
                            return (
                                <div
                                    key={key}
                                    onClick={() => {
                                        if (isComplete && !isSkipped)
                                            this.setState({ step: index });
                                    }}
                                    className={className}
                                >
                                    <div className="step-label">{label}</div>
                                    <div className="step-point"></div>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        );
    }

    navigateStep = (delta, hasMixPanelTracking = false, mixpanel) => {
        if (hasMixPanelTracking) {
            this.mixpanelTracking(hasMixPanelTracking, mixpanel);
        }
        const step = this.getNextStep(delta);
        this.setState({ step });
    };

    getNextStep(delta = 1, step = this.state.step) {
        for (
            let next = step + delta;
            next >= 0 && next <= this.getSteps().length - 1;
            next += delta
        ) {
            const { component } = this.getSteps()[next];
            if (!(component.skip && component.skip(this.props, this.state))) {
                return next;
            }
        }
        return null;
    }

    mixpanelTracking = (hasMixPanelTracking, mixpanel, skip = false) => {
        if (hasMixPanelTracking) {
            mixpanel(skip);
        }
    };

    handleNextClick = (
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm
    ) => {
        if (hasNextStep) {
            this.mixpanelTracking(hasMixPanelTracking, mixpanel);
            this.navigateStep(1);
            return;
        }

        this.mixpanelTracking(hasMixPanelTracking, mixpanel);
        submitForm();
    };

    handleSkipButton = (confirmText, hasMixPanelTracking, mixpanel) => {
        this.setState({
            showConfirm: true,
            confirmText,
            hasMixPanelTracking,
            mixpanel
        });
    };

    handleBrowseAgents = (
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm
    ) => {
        const { values } = this.props;
        const data = this.props.mapValuesToSearchAgentsPayload(values);
        this.props
            .getSearchSellerAgents(data)
            .then(() =>
                this.handleNextClick(
                    hasNextStep,
                    hasMixPanelTracking,
                    mixpanel,
                    submitForm
                )
            );
    };

    getBrowseAgents = (
        canProceed,
        canNavigate,
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm
    ) => {
        const { searchAgents } = this.props;
        const isFetchingSearchAgents = get(
            searchAgents,
            "isFetchingSearchAgents"
        );
        return (
            <Button
                type="button"
                disabled={!(canProceed && canNavigate)}
                className={` ${
                    !(canProceed && canNavigate) ? "is-disabled" : ""
                }`}
                onClick={() =>
                    this.handleBrowseAgents(
                        hasNextStep,
                        hasMixPanelTracking,
                        mixpanel,
                        submitForm
                    )
                }
                isLoading={isFetchingSearchAgents}
            >
                BROWSE AGENTS
            </Button>
        );
    };

    renderStepButtons = (options, mixpanel) => {
        const { step } = this.state;
        const { isSubmitting, submitForm } = this.props;
        const hasNextStep = this.getNextStep() !== null;

        let canProceed;
        let hideNextButton = false;
        let canSkip = false;
        let canNavigate = true;
        let confirmText = "Are you sure you want to skip?";
        let hasMixPanelTracking = false;
        let getBrowseAgents = false;
        let buttonName = "";
        let buttonsWithoutMargin = false;
        let hideBackButton = false;
        let isLoading = false;
        let handleNext = this.handleNextClick;
        if (typeof options === "boolean") {
            canProceed = options;
        } else {
            handleNext = options.handleNext || this.handleNextClick;
            isLoading = options.isLoading || false;
            canProceed = options.canProceed || false;
            canSkip = options.canSkip || false;
            if (canSkip && canSkip.confirmText)
                confirmText = canSkip.confirmText;
            if (options.canNavigate === false) canNavigate = false;
            hasMixPanelTracking = options.hasMixPanelTracking || false;
            getBrowseAgents = options.getBrowseAgents || false;
            hideNextButton = options.hideNextButton || false;
            buttonName = options.buttonName || "";
            buttonsWithoutMargin = options.buttonsWithoutMargin || false;
            hideBackButton = options.hideBackButton || false;
        }

        return (
            <Fragment>
                <div
                    className={`buyer__signup__controls${
                        buttonsWithoutMargin ? "--without-margin" : ""
                    }`}
                >
                    {step < 1 || hideBackButton ? null : (
                        <Button
                            type="button"
                            disabled={!canNavigate}
                            text
                            onClick={() => this.navigateStep(-1)}
                        >
                            {`${"< Back"}`}
                        </Button>
                    )}

                    {canSkip ? (
                        <Button
                            type="button"
                            inverted
                            onClick={() =>
                                this.handleSkipButton(
                                    confirmText,
                                    hasMixPanelTracking,
                                    mixpanel
                                )
                            }
                        >
                            Skip
                        </Button>
                    ) : null}

                    {getBrowseAgents ? (
                        this.getBrowseAgents(
                            canProceed,
                            canNavigate,
                            hasNextStep,
                            hasMixPanelTracking,
                            mixpanel,
                            submitForm
                        )
                    ) : hideNextButton ? null : (
                        <Button
                            type="button"
                            disabled={!(canProceed && canNavigate)}
                            className={` ${
                                !(canProceed && canNavigate)
                                    ? "is-disabled"
                                    : ""
                            }`}
                            isLoading={isLoading}
                            onClick={() =>
                                handleNext(
                                    hasNextStep,
                                    hasMixPanelTracking,
                                    mixpanel,
                                    submitForm
                                )
                            }
                        >
                            {isSubmitting ? (
                                <i
                                    style={{ textAlign: "center", padding: 0 }}
                                    className="fas fa-spinner fa-spin"
                                />
                            ) : (
                                <Fragment>
                                    {hasNextStep
                                        ? buttonName
                                            ? buttonName
                                            : "Continue"
                                        : "Finish"}
                                </Fragment>
                            )}
                        </Button>
                    )}
                </div>
            </Fragment>
        );
    };

    trackProgress = () => {
        const {
            values,
            mapValuesToPayload,
            customBudgetRange,
            notifyUpdate
        } = this.props;
        const step = this.getSteps()[this.state.step];

        if (!(mapValuesToPayload && notifyUpdate)) return;

        const data = this.props.mapValuesToPayload(values);
        const payload = pickBy(
            data,
            value =>
                !(
                    value === undefined ||
                    (typeof value === "number" && isNaN(value)) ||
                    (typeof value === "string" && value.trim().length === 0)
                )
        );

        payload.onboardingFormState = JSON.stringify({
            values,
            budget: customBudgetRange.get(),
            step: step.key
        });

        const completedStep = this.getNextStep(-1);
        const stepInfo = getStepInfoByKey(
            this.getSteps()[completedStep || 0].key
        );

        return notifyUpdate(payload, stepInfo);
    };

    renderSkipConfirmation() {
        if (!this.state.showConfirm) return null;
        const { confirmText, hasMixPanelTracking, mixpanel } = this.state;
        const skip = true;

        return ReactDOM.createPortal(
            <Fragment>
                <div
                    className="modal-blocker"
                    onClick={e => e.preventDefault()}
                />
                <div className="modal-container" style={{ height: "auto" }}>
                    <div className="modal-header">
                        Please confirm
                        <div
                            className="modal-close"
                            onClick={() =>
                                this.setState({ showConfirm: false })
                            }
                        >
                            <i className="fa fa-times-circle" />
                        </div>
                    </div>
                    <div className="modal-body-container">
                        <div className="modal-body">
                            {confirmText}
                            <div className="buyer__signup__controls align-right">
                                <Button
                                    inverted
                                    onClick={() => {
                                        this.setState({ showConfirm: false });
                                        this.navigateStep(1);
                                        this.mixpanelTracking(
                                            hasMixPanelTracking,
                                            mixpanel,
                                            skip
                                        );
                                    }}
                                >
                                    Yes, Skip
                                </Button>
                                <Button
                                    onClick={() =>
                                        this.setState({ showConfirm: false })
                                    }
                                >
                                    No, Go Back
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>,
            document.querySelector("#modal-root")
        );
    }

    getSteps = () => {
        const buyerSignupSteps = [
            { key: "home_address", label: "Address", component: StepAddress },
            {
                key: "purchase_timeline",
                label: "Timeline",
                component: StepTimeline
            },
            {
                key: "plan_using_proceeds",
                label: "Property Worth",
                component: StepPropertyIsWorth
            },
            {
                key: "scanning_agents",
                label: "Scanning agents...",
                component: StepScanningAgents
            },
            { key: "agents", label: "agents", component: StepAgents },
            {
                key: "contact_info",
                label: "Phone Verification",
                component: StepContact
            },
            { key: "confirmation", label: "Confirm", component: StepConfirm }
        ];

        return [...buyerSignupSteps];
    };

    render() {
        const {
            status: { formState = "initial" }
        } = this.props;

        if (formState === "submitted") return <StepFinish />;

        const StepComponent = this.getSteps()[this.state.step].component;
        if (!StepComponent) return null;

        if (formState === "submitting") return <StepFinish />;

        return (
            <Fragment>
                <Form className="buyer__signup buyer">
                    {this.renderProgress()}
                    {formState === "initial" ? (
                        <StepComponent
                            {...this.props}
                            isBuyerFlow={true}
                            onNavigateStep={this.navigateStep}
                            onTrackProgress={this.trackProgress}
                            onSkipStep={options =>
                                this.setState({
                                    ...options,
                                    showConfirm: true
                                })
                            }
                            renderNavigation={this.renderStepButtons}
                        />
                    ) : null}
                </Form>
                {this.renderSkipConfirmation()}
            </Fragment>
        );
    }
}

WizardStepsForm.propTypes = {
    status: PropTypes.object,
    userProfile: PropTypes.object,
    userInfo: PropTypes.object,
    isSubmitting: PropTypes.bool,
    submitForm: PropTypes.func,
    validateForm: PropTypes.func,
    mapValuesToPayload: PropTypes.func,
    mapValuesToSearchAgentsPayload: PropTypes.func,
    notifyUpdate: PropTypes.func,
    customBudgetRange: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func,
        update: PropTypes.func
    }),
    getSearchSellerAgents: PropTypes.func,
    searchAgents: PropTypes.object
};

export default WizardStepsForm;
