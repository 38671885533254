import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MaskedInputField from '../MaskedInput/MaskedInputField';
import { NumberFormat } from '../NumberFormat/NumberFormat';

import './inputWithErrorMessage.css';

export default class InputWithErrorMessage extends React.PureComponent {
    buildSymbol = () => (
        !isEmpty(this.props.symbol)
            ? <span className="input-with-error-message__span">{this.props.symbol}</span>
            : null
    );

    buildErrorMessage = () => (
        <div className="input-with-error-message__error--content">
            <div className="input-with-error-message__error">{this.props.errorMessage}</div>
        </div>
    )

    buildNumberFormat = () => (
        <NumberFormat
            format="currency"
            decimals={2}
            number={this.props.value}
            className="input-with-error-message__amount"
        />
    )

    buildInputWithErrorMessage = () => (
        <MaskedInputField
            {...this.props}
            className="input-with-error-message__amount"
            maskType="amountWithoutSymbol"
            allowDecimal
        />
    )

    render = () => {
        const {
            isEditable,
            dashed,
            isError,
        } = this.props;
        const buildInput = isEditable ? this.buildInputWithErrorMessage() : this.buildNumberFormat();
        const symbol = isEditable ? this.buildSymbol() : null;

        return (
            <div className={`input-with-error-message__box ${isEditable && dashed ? 'input-with-error-message__box--dashed' : ''}`}>
                <div className="input-with-error-message__container">
                    {symbol}
                    {buildInput}
                </div>
                {isError ? this.buildErrorMessage() : null}
            </div>
        );
    }
}

InputWithErrorMessage.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    isEditable: PropTypes.bool,
    errorMessage: PropTypes.string,
    isError: PropTypes.bool,
    symbol: PropTypes.string,
    dashed: PropTypes.bool,
};
