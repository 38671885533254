import { CREATING_REALTOR_PROFILE, CREATE_REALTOR_PROFILE_FAIL, CREATE_REALTOR_PROFILE_SUCCESS } from './RealtorProfileActionTypes';
import * as APIUtil from '../../util/service_api';

const creatingRealtorProfile = () => ({ type: CREATING_REALTOR_PROFILE });

export const createRealtorProfileSuccess = payload => ({
    type: CREATE_REALTOR_PROFILE_SUCCESS,
    payload: payload.status.toString(),
});

export const createRealtorProfileFailed = payload => ({
    type: CREATE_REALTOR_PROFILE_FAIL,
    payload: payload.status.toString(),
});

export const createRealtorProfile = (payload) => (dispatch) => {

    dispatch(creatingRealtorProfile());

    return APIUtil.createRealtorProfile(payload)
        .then(response => dispatch(createRealtorProfileSuccess(response)))
        .catch((err) => dispatch(createRealtorProfileFailed(err.response)))
};
