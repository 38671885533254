import get from "lodash/get";
import React, { Fragment } from "react";
import { Field } from "formik";
import { AmountField } from "../../../Form";
import forEach from "lodash/forEach";
import set from "lodash/set";
import BaseWizardStep from "../../BuyerSignUp/BaseWizardStep";
import { rangeOptionsWithSuffix } from "../../BuyerSignUp/Shared";
import mixpanel from "mixpanel-browser";

const minDropdownValue = 1;
const maxDropdownValue = 5;

class StepPropertyWorth extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values, searchAgents } = this.props;
        const agentsCount = get(searchAgents, "searchAgents.agentsCount");
        const agents = get(searchAgents, "searchAgents.agents");

        let matchedAgents = {};
        forEach(agents, (value, index) => {
            set(matchedAgents, `agentId${index}`, get(value, "userInfo.id"));
            set(
                matchedAgents,
                `agentProfilePicUrl${index}`,
                get(value, "userInfo.profilePicUrl")
            );
            set(
                matchedAgents,
                `agentBrokerage${index}`,
                get(value, "brokerage.name")
            );
            set(
                matchedAgents,
                `agentPosition${index}`,
                get(value, "position.name")
            );
        });

        mixpanel.track("Clicked On Browse Agents", {
            agentsCount: agentsCount,
            ...matchedAgents,
            sellerHomeValue: get(values, "homeValue"),
            sellerNumberOfBathrooms: get(values, "numberOfBathrooms"),
            sellerNumberOfBedrooms: get(values, "numberOfBedrooms"),
            sellerHomeValueMin: get(values, "homeValueMin"),
            sellerHomeValueMax: get(values, "homeValueMax")
        });
    };

    rangeOptions = (min, max, fn) => (
        <Fragment>
            {fn(min, max).map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Fragment>
    );

    render() {
        const isStepFulfilled = this.areFieldsValid([
            "homeValueMin",
            "homeValueMax",
            "numberOfBedrooms",
            "numberOfBathrooms"
        ]);
        const includeChooseOption = true;

        return (
            <Fragment>
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        How much would you like to sell your home for?
                    </div>
                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field">
                                <div
                                    className="buyer__signup__field__question margin-label white-font bold"
                                    style={{ textAlign: "left" }}
                                >
                                    Min value
                                </div>
                                <Field
                                    type="text"
                                    name="homeValueMin"
                                    placeholder="$ Min Value"
                                    component={AmountField}
                                    className="is-bigger"
                                />
                                {this.renderFieldError("homeValueMin")}
                            </div>

                            <div className="buyer__signup__field">
                                <div
                                    className="buyer__signup__field__question margin-label white-font bold"
                                    style={{ textAlign: "left" }}
                                >
                                    Max value
                                </div>
                                <Field
                                    type="text"
                                    name="homeValueMax"
                                    placeholder="$ Max Value"
                                    component={AmountField}
                                    className="is-bigger"
                                />
                                {this.renderFieldError("homeValueMax")}
                            </div>
                        </div>
                    </div>
                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field">
                                <div
                                    className="buyer__signup__field__question margin-label white-font bold"
                                    style={{ textAlign: "left" }}
                                >
                                    How many beds?
                                </div>
                                <Field
                                    component="select"
                                    name="numberOfBedrooms"
                                    className="buyer__signup__preferences-field"
                                >
                                    {rangeOptionsWithSuffix(
                                        minDropdownValue,
                                        maxDropdownValue,
                                        "+",
                                        includeChooseOption
                                    )}
                                </Field>
                                {this.renderFieldError("numberOfBedrooms")}
                            </div>

                            <div className="buyer__signup__field">
                                <div
                                    className="buyer__signup__field__question margin-label white-font bold"
                                    style={{ textAlign: "left" }}
                                >
                                    How many baths?
                                </div>
                                <Field
                                    component="select"
                                    name="numberOfBathrooms"
                                    className="buyer__signup__preferences-field"
                                >
                                    {rangeOptionsWithSuffix(
                                        1,
                                        5,
                                        "+",
                                        includeChooseOption
                                    )}
                                </Field>

                                {this.renderFieldError("numberOfBathrooms")}
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderStepButtons(
                    {
                        canProceed: isStepFulfilled,
                        hasMixPanelTracking: true,
                        getBrowseAgents: true
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepPropertyWorth;
