import React from 'react';
import './index.css';

export default () =>
    <div className="lb__tour__content">
        <h1>Understanding the BrewScore</h1>
        <div className="lb__p2">
            LemonBrew is transforming the real estate transaction by creating a transparent connection between the consumer and real estate agent. No more guessing on the consumer’s financial creditworthiness or motivation to buy or sell. A digitally secure and accurate solution at your fingertips within seconds.
        </div>
        <div className="lb__tour__content__inner">
            <div className="lb__tour__content__inner__side__left">
            </div>
            <div className="lb__tour__content__inner__middle">
                <div className="lb__tour__content__inner__middle__block">
                    <div className="pulsate__one">
                        <div className="lb__tour__content__inner__side__left__block">
                            <div className="lb__tour__content__inner__side__left__block__info">
                                <div className="lb__p1">01</div>
                                <div className="lb__p1__strong">Rebate</div>
                            </div>
                            <div className="lb__panel">
                                <span className="lb__p">A financially vetted buyer may get a rebate from the Partner Agent based on the buyer's BrewScore. The higher the BrewScore, the higher the rebate!</span>
                            </div>
                        </div>
                    </div>
                    <div className="pulsate__two">
                        <div className="lb__tour__content__inner__side__left__block">
                            <div className="lb__tour__content__inner__side__left__block__info">
                                <div className="lb__p1">03</div>
                                <div className="lb__p1__strong">BrewScore</div>
                            </div>
                            <div className="lb__panel">
                                <span className="lb__p">Our proprietary algorithm calculates the buyer's BrewScore. This determines the buyer's financial credibility & willingness to purchase a home.</span>
                            </div>
                        </div>
                    </div>
                    <div className="pulsate__three">
                        <div className="lb__tour__content__inner__side__right__block">
                            <div className="lb__tour__content__inner__side__right__block__info">
                                <div className="lb__p1">02</div>
                                <div className="lb__p1__strong">Summary</div>
                            </div>
                            <div className="lb__panel">
                                <span className="lb__p">Here is a summary of the buyer's desired location, time frame, budget and other preferences.</span>
                            </div>
                        </div>
                    </div>
                    <div className="pulsate__four">
                        <div className="lb__tour__content__inner__side__right__block">
                            <div className="lb__tour__content__inner__side__right__block__info">
                                <div className="lb__p1">04</div>
                                <div className="lb__p1__strong">Verification</div>
                            </div>
                            <div className="lb__panel">
                                <span className="lb__p">Our system digitally verifies the buyer's credit score, estimated income, assets and employment history.</span>
                            </div>
                        </div>
                    </div>
                    <div className="lb__tour__content__inner__middle__block__avatar"/>
                    <h2>Congratulation Sean Doran!</h2>
                    <div className="lb__tour__content__inner__middle__block__nav">
                        <span>Purchase summary</span>
                        <span>Preferences</span>
                        <span>Your Agent*</span>
                    </div>
                    <div className="lb__tour__content__inner__middle__block__container">
                        <div className="lb__tour__content__inner__middle__block__container__list">
                            <div className="lb__tour__content__inner__middle__block__container__list__info">
                                You are eligible for a rebate of <span>$2,800</span> from our Partner Agent.
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__head">
                                Your Purchase Summary
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__location"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Location:</b></span>
                                    <span>Hoboken NJ, Jersey City NJ, Weehawken NJ</span>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__property"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Property Type:</b></span>
                                    <span>Condo/Co-op</span>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__timeframe"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Timeframe:</b></span>
                                    <span>3 to 6 months</span>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__budget"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Budget:</b></span>
                                    <span>$550,000 to $700,000</span>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__down__payment"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Down Payment:</b></span>
                                    <span>$140,000</span>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__loan"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Estimated Loan Amount:</b></span>
                                    <span>$560,000</span>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__outline__mortgage"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Estimated Mortgage Payment:</b></span>
                                    <span>$2,755</span>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__taxes"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Estimated Monthly Property Taxes:</b></span>
                                    <span>$1,225</span>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__list__item">
                                <span className="img__total__payment"/>
                                <div className="lb__tour__content__inner__middle__block__container__list__item__wrap">
                                    <span><b>Total Estimated Payment: </b> <span className="green__text"> (More details)</span></span>
                                    <span>$4,423</span>
                                </div>
                            </div>
                        </div>
                        <div className="lb__tour__content__inner__middle__block__container__score">
                            <div className="lb__p"><b>Your BrewScore</b></div>
                            <div className="lb__tour__content__inner__middle__block__container__score__info">
                                <div className="lb__tour__content__inner__middle__block__container__score__info__lime">
                                </div>
                                <div className="lb__tour__content__inner__middle__block__container__score__info__num">
                                    80
                                </div>
                            </div>
                            <div className="lb__score__link">What is the BrewScore?</div>
                            <div className="lb__tour__content__inner__middle__block__container__score__list__item">
                                <div className="lb__tour__content__inner__middle__block__container__score__list__item__ico__credit"/>
                                <div className="lb__tour__content__inner__middle__block__container__score__list__item__wrap">
                                    <div className="lb__p"><b>Credit</b> (verified)</div>
                                    <div className="lb__list__temp__green"/>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__score__list__item">
                                <div className="lb__tour__content__inner__middle__block__container__score__list__item__ico__income"/>
                                <div className="lb__tour__content__inner__middle__block__container__score__list__item__wrap">
                                    <div className="lb__p"><b>Estimated Income</b> (verified)</div>
                                    <div className="lb__list__temp__green"/>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__score__list__item">
                                <div className="lb__tour__content__inner__middle__block__container__score__list__item__ico__assets"/>
                                <div className="lb__tour__content__inner__middle__block__container__score__list__item__wrap">
                                    <div className="lb__p"><b>Assets</b> (unverified)</div>
                                    <div className="lb__list__temp__red"/>
                                </div>
                            </div>
                            <div className="lb__tour__content__inner__middle__block__container__score__list__item">
                                <div className="lb__tour__content__inner__middle__block__container__score__list__item__ico__employement"/>
                                <div className="lb__tour__content__inner__middle__block__container__score__list__item__wrap">
                                    <div className="lb__p"><b>Employment</b> (verified)</div>
                                    <div className="lb__list__temp__green"/>
                                </div>
                            </div>
                            <div className="lb__score__btn">Dispute</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lb__tour__content__inner__side__right">
            </div>
        </div>
    </div>;
