import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "react-bootstrap";
import Dropzone from "react-dropzone";
import get from "lodash/get";
import first from "lodash/first";
import invoke from "lodash/invoke";
import isFunction from "lodash/isFunction";
import { Button } from "../Button/Button";
import AutoCompleteInput from "../AutoCompleteInput/AutoCompleteInput";
import CloseModalButton from "../CloseModalButton/CloseModalButton";
import PreviewPicture from "./PreviewPicture/PreviewPicture";

import "./earnestmoneydepositvalidationmodal.css";

export default class EarnestMoneyDepositValidationModal extends React.PureComponent {
    isUploadDocumentButtonDisabled = () =>
        this.props.isLoading ||
        !this.props.document ||
        !this.props.documentType;

    onSelectFile = file => {
        invoke(this.props, "onSelectDocument", file);
    };

    onRemoveFile = () => {
        const noFile = undefined;
        this.onSelectFile(noFile);
    };

    onDocumentTypeChange = documentType => {
        invoke(this.props, "onDocumentTypeChange", documentType);
    };

    buildSelectedFileName = () => {
        const fileName =
            get(this.props.document, "name") || "Upload Your Photo ID";
        return (
            <div className="earnest-money-deposit-validation-modal__file-name">
                {fileName}
            </div>
        );
    };

    buildDocumentTypeSelect = () => (
        <AutoCompleteInput
            placeholder="SELECT THE TYPE OF DOCUMENT"
            onChange={this.onDocumentTypeChange}
            getOptions={() => this.props.documentTypes}
            initializeGettingOptions
        />
    );

    setAvatarEditorRef = editor => (this.editor = editor);

    buildFileUploader = () => (
        <>
            <Dropzone
                onDropAccepted={files => this.onSelectFile(first(files))}
                multiple={false}
                disabled={this.props.isLoading}
                accept="image/jpeg, image/png"
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {this.buildSelectedFileName()}
                        <Button small disabled={this.props.isLoading}>
                            Browse
                        </Button>
                    </div>
                )}
            </Dropzone>
            <PreviewPicture
                onSetRef={this.setAvatarEditorRef}
                onRemove={this.onRemoveFile}
                picture={this.props.document}
            />
        </>
    );

    buildErrorMessage = () => {
        if (!this.props.error) {
            return null;
        }

        return <span className="text-danger">{this.props.error}</span>;
    };

    onSendPhoto = () => {
        if (!this.editor) return;
        if (!isFunction(this.props.onUploadDocument)) return;
        const canvas = this.editor.getImage();
        canvas.toBlob(this.props.onUploadDocument, "image/jpeg");
    };

    render = () => (
        <Modal
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            className="earnest-money-deposit-validation-modal"
        >
            <ModalHeader className="text-right">
                <CloseModalButton onClick={this.props.onCancel} />
            </ModalHeader>
            <ModalBody>
                <h1 className="earnest-money-deposit-validation-modal__title">
                    For Your Security We Need a Picture of Your Government
                    Issued ID
                </h1>
                <div className="earnest-money-deposit-validation-modal__document-type-container">
                    {this.buildDocumentTypeSelect()}
                </div>
                <div className="earnest-money-deposit-validation-modal__file-container">
                    {this.buildFileUploader()}
                </div>
                <div className="earnest-money-deposit-validation-modal__error-container">
                    {this.buildErrorMessage()}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    onClick={this.props.onCancel}
                    shadow={false}
                    design="default-inverted"
                    inverted
                >
                    Do it later
                </Button>
                <Button
                    disabled={this.isUploadDocumentButtonDisabled()}
                    isLoading={this.props.isLoading}
                    onClick={this.onSendPhoto}
                >
                    Send ID photo
                </Button>
            </ModalFooter>
        </Modal>
    );
}

EarnestMoneyDepositValidationModal.propTypes = {
    document: PropTypes.instanceOf(File),
    documentType: PropTypes.object,
    documentTypes: PropTypes.array,
    onSelectDocument: PropTypes.func,
    onDocumentTypeChange: PropTypes.func,
    isVisible: PropTypes.bool,
    onCancel: PropTypes.func,
    onUploadDocument: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.string
};
