import React from 'react';
import PropTypes from 'prop-types';

function Track({ source, target, getTrackProps, disabled }) {
    return (
        <div
            className="range-slider-field-track"
            style={{
                backgroundColor: disabled ? "#eee" : "#009f4d",
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`
            }}
            {...getTrackProps()}
        />
    );
}

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

Track.defaultProps = {
    disabled: false
};

export default Track;
