import React, { Component } from 'react';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import first from 'lodash/first';
import isFunction from 'lodash/isFunction';
import size from 'lodash/size';
import some from 'lodash/some';
import { components } from 'react-select';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import LocationAutoCompleteInput from '../LocationAutoCompleteInput/LocationAutoCompleteInput';

import './customrealtorservinginfield.css';

import {
    maxNumberOfCities,
} from '../../shared/constants';
import { getAddressFromPlaceDetails } from '../../util/util';

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <i className="fa fa-search" />
    </components.DropdownIndicator>
);

export default class CustomRealtorServingInField extends Component {
    static propTypes = {
        onEdit: PropTypes.func,
        maxOptions: PropTypes.number,
    };

    static defaultProps = {
        maxOptions: maxNumberOfCities,
    }

    constructor(props) {
        super(props);

        this.state = {
            value: this.valuesToLabeledOptions(props.value),
            hasErrorServingField: false,
            messageError: '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.value !== prevState.value) {
            this.onConfirmEdit();
        }
    }

    valuesToLabeledOptions = (values) => map(values, this.valueToLabeledOption);

    valueToLabeledOption = (value) => {
        const label = this.buildLabel(value);
        return value && { value, label };
    };

    labeledOptionsToValues = (labeledOptions) => map(labeledOptions, this.labeledOptionToValue);

    labeledOptionToValue = (labeledOption) => get(labeledOption, 'value');

    onChangeField = (fieldNewValue) => {
        const { maxOptions } = this.props;
        if (size(fieldNewValue) > this.props.maxOptions) {
            this.setState({ hasErrorServingField: true, messageError: `The maximum number of cities to add is ${maxOptions}` });
            return;
        }
        this.setState({ value: fieldNewValue, hasErrorServingField: false, messageError: '' });
    };

    buildEditableField = () => {
        const allowAllResults = null;
        return (
            <LocationAutoCompleteInput
                value={this.state.value}
                getOptionsMillisecondsDelay={300}
                onChange={this.onChangeField}
                hasError={this.state.hasErrorServingField}
                components={{ DropdownIndicator }}
                placeholder={this.props.placeholder}
                isMulti
                types={allowAllResults}
            />
        );
    };

    buildLabel = (value) => {
        const neighborhood = get(value, 'neighborhood');
        const city = get(value, 'city');
        const abbreviation = get(value, 'state');
        return neighborhood || city + ', ' + abbreviation;
    }


    cleanLocationInputValidations = () => {
        this.setState({ hasErrorServingField: false, messageError: '' });
    };

    onConfirmEdit = () => {
        const { value } = this.state;
        const fieldNewValue = this.labeledOptionsToValues(value);
        const alreadyValidLocations = reject(fieldNewValue, 'place_id');

        this.convertNewLabeledOptionsToValidValues(value)
            .then((newLabeledOptionsAsValidValues) => [
                ...alreadyValidLocations,
                ...newLabeledOptionsAsValidValues,
            ])
            .then(this.updateServingInFieldAndExecuteCallback)
            .finally(this.cleanLocationInputValidations());
    };

    convertNewLabeledOptionsToValidValues = (newLabeledOptions) => {
        const values = this.labeledOptionsToValues(newLabeledOptions);
        const newValues = filter(values, 'place_id');
        const placeIds = map(newValues, 'place_id');
        const geocodePlaces = map(placeIds, geocodeByPlaceId);

        return Promise
            .all(geocodePlaces)
            .then((locations) => map(
                locations,
                (location) => this.geocodeToValue(first(location)),
            ));
    };

    hasRepeatedCities = (saleAddresses) => {
        const areEqualAddresses = (addressA, addressB) => {
            const cityA = trim(get(addressA, 'city')).toLowerCase();
            const stateA = trim(get(addressA, 'state')).toLowerCase();
            const neighborhoodA = trim(get(addressA, 'neighborhood')).toLowerCase();
            const cityB = trim(get(addressB, 'city')).toLowerCase();
            const stateB = trim(get(addressB, 'state')).toLowerCase();
            const neighborhoodB = trim(get(addressB, 'neighborhood')).toLowerCase();

            return (
                cityA === cityB &&
                stateA === stateB &&
                neighborhoodA === neighborhoodB
            );
        };

        return some(saleAddresses, (saleAddress) => {
            const duplicatedAddresses = filter(
                saleAddresses,
                (possibleDuplicatedAddress) => areEqualAddresses(
                    saleAddress,
                    possibleDuplicatedAddress
                )
            );
            return duplicatedAddresses.length > 1;
        });
    };

    hasInvalidCities = (saleAddresses) => some(
        saleAddresses,
        (saleAddress) => saleAddress == null || !saleAddress.city || !saleAddress.state
    );

    updateServingInFieldAndExecuteCallback = (saleAddresses) => {
        if (this.hasRepeatedCities(saleAddresses)) {
            this.setState({
                hasErrorServingField: true,
                messageError: 'Duplicated cities are not allowed'
            });
            return;
        }

        if (this.hasInvalidCities(saleAddresses)) {
            this.setState({
                hasErrorServingField: true,
                messageError: 'Addresses must at least have a city and state'
            });
            return;
        }
        this.props.onEdit(saleAddresses)
    };

    geocodeToValue = (geocode) => {
        const info = getAddressFromPlaceDetails(geocode);

        return {
            streetNumberName: trim(`${info.streetName} ${info.streetNumber}`),
            neighborhood: info.neighborhood,
            apartmentSuiteNumber: '',
            city: info.city,
            state: info.state,
            zipcode: info.zipcode,
            longitude: info.lng,
            latitude: info.lat
        };
    };

    onSuccessfullyEdit = (fieldNewValue) => {
        this.setState({ isEditing: false });

        if (isFunction(this.props.onEdit)) {
            this.props.onEdit(fieldNewValue);
        }
    };

    render = () => {
        const { hasErrorServingField, messageError } = this.state;
        const fieldError = hasErrorServingField
            ? (<div className="realtor-serving-in-field__field-label__error">{messageError}</div>)
            : null;

        return (
            <div className="realtor-serving-in-field">
                {this.buildEditableField()}
                {fieldError}
            </div>
        );
    };
}
