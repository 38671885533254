import React, { Component, Fragment } from 'react';
import BrewingMachine from '../BrewingMachine/BrewingMachine';
import ScrollToTopOnMount from '../ScrollTopOnMount/ScrollTopOnMount';

import './buyersignupfinishedscreen.css';

const Trivia = [
    <Fragment>Homebuyers can <b>SAVE MORE MONEY</b> by using LemonBrew Abstract for their title insurance and closing services. </Fragment>,
    <Fragment>Homeowners in Scotland paint their door red when they have paid off their mortgage!</Fragment>,
    <Fragment>The average home in America sold for approximately <b>$5,000 in 1900</b></Fragment>,
    <Fragment>In Denmark people throw old dishes at the homes of their closest friends on New Years Eve. The more broken
        plates on your doorstop, the more friends you have!</Fragment>,
    <Fragment>The most common street name in the U.S. is “<u>Second</u>.” Oddly, “Third” comes in second place, and
        “First” is in third place. This just doesn’t seem to add up!</Fragment>,
    <Fragment>Most homeowners tend to stay in their homes for about six years.</Fragment>,
    <Fragment>25% of people with two-car garages don’t have room to park cars inside them and 32% only have room for one
        vehicle.</Fragment>,
    <Fragment>The oldest house in America is the Henry Whitfield House in Guilford, Connecticut, built in 1639. It’s
        been a museum since as far back as 1899.</Fragment>
];

class BuyerSignUpFinishedScreen extends Component {
    state = {
        current: 0
    };

    componentDidMount() {
        this.interval = setInterval(this.switchSlide, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    switchSlide = () =>
        this.setState(state => ({
            current: (state.current + 1) % Trivia.length
        }));

    render() {
        return (
            <Fragment>
            <ScrollToTopOnMount />
                <div className="buyer-sign-up-finished-screen-base"></div>
                <div className="buyer-sign-up-finished-screen">
                    <div className="buyer-sign-up-finished-screen__shelves">
                        <div className="buyer-sign-up-finished-screen__machine">
                            <BrewingMachine />
                        </div>
                        <div className="buyer-sign-up-finished-screen__content">
                            <h1 className="buyer-sign-up-finished-screen__title">
                                Please wait while we brew your results
                            </h1>
                        </div>
                        <div className="buyer-sign-up-finished-screen__subtitle">
                            This process could take up to 2 minutes...
                        </div>

                        <div className="buyer-sign-up-finished-screen__trivia__container">
                            <div className="buyer-sign-up-finished-screen__trivia__hint">
                                <div className="is-small">did you</div>
                                <div className="is-big">know</div>
                            </div>

                            { Trivia.map((item, index) =>
                                <div
                                    key={index}
                                    className={`buyer-sign-up-finished-screen__trivia ${ index === this.state.current ? 'is-current' : '' }`}
                                >
                                    { item }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default BuyerSignUpFinishedScreen;