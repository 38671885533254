import React, { Fragment } from "react";
import { Field } from "formik";
import map from "lodash/map";
import pickBy from "lodash/pickBy";
import get from "lodash/get";
import BaseWizardStep from "./BaseWizardStep";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { months } from "../../../../shared/constants";
import { rangeOptions } from "../../../../util/util";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";
import PlaidButton from "./PlaidButton";

class StepFinancial extends BaseWizardStep {
    state = {
        voaFinished: false,
        isAccepting: false,
        showFinicity: false
    };

    SsnDigit = () => (
        <div className="buyer__signup__field__without__appearance buyer__signup__field__row">
            <div className="buyer__signup__field">
                <Field
                    className="lb-narrow-input"
                    type="password"
                    name="ssn"
                    autoComplete="off"
                    placeholder="SOCIAL SECURITY NUMBER"
                />
                <div className="buyer__signup__field__tooltip">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Popover id="ssn-tooltip">
                                Our secure verification process requires us to
                                verify your identity using your social security
                                number.
                            </Popover>
                        }
                    >
                        <i className="fa fa-info-circle" />
                    </OverlayTrigger>
                </div>
                {this.buildFieldError("ssn")}
            </div>
        </div>
    );

    birthMonth = () => (
        <div className="buyer__signup__field width-30">
            <Field component="select" name="birthday.month" placeholder="MONTH">
                <option value="">MONTH</option>
                {map(months, month => (
                    <option key={month.value} value={month.value}>
                        {month.label}
                    </option>
                ))}
            </Field>
            {this.buildFieldError("birthday.month")}
        </div>
    );

    birthDayOfMonth = () => {
        const days = rangeOptions(1, 31);
        return (
            <div className="buyer__signup__field width-25">
                <Field component="select" name="birthday.day" placeholder="DAY">
                    <option value="">DAY</option>
                    {map(days, day => (
                        <option key={day.value} value={day.value}>
                            {day.label}
                        </option>
                    ))}
                </Field>
                {this.buildFieldError("birthday.day")}
            </div>
        );
    };

    birthYear = () => {
        const currentYear = new Date().getFullYear();
        const yearOptions = rangeOptions(currentYear - 10, currentYear - 80);

        return (
            <div className="buyer__signup__field width-30">
                <Field
                    component="select"
                    name="birthday.year"
                    placeholder="YEAR"
                >
                    <option value="">YEAR</option>
                    {map(yearOptions, year => (
                        <option key={year.value} value={year.value}>
                            {year.label}
                        </option>
                    ))}
                </Field>
                {this.buildFieldError("birthday.year")}
            </div>
        );
    };

    titleDOB = () => (
        <div className="buyer__signup__field width-20 row-label">
            <div className="buyer__signup__field__label without-padding white-font">
                Date of Birth:
            </div>
        </div>
    );

    dateOfBirth = () => (
        <Fragment>
            <div className="emd__date-of-birth buyer__signup__field__row">
                {this.titleDOB()}
                {this.birthMonth()}
                {this.birthDayOfMonth()}
                {this.birthYear()}
            </div>
            <div className="buyer__signup__field__row">
                <div className="buyer__signup__field">
                    {this.renderFormError("birthday")}
                </div>
            </div>
        </Fragment>
    );

    informativeText = () => (
        <div className="buyer__signup__field__row">
            <div className="buyer__signup__field__label">
                <div className="buyer__signup__field-lemonbrew-info">
                    By clicking &quot;Verify&quot;, I authorize, by electronic
                    signature, LemonBrew Abstract to securely connect to my
                    financial institution.
                </div>
            </div>
        </div>
    );

    onSuccessfullySelectedBankAccounts = plaidResponse => {
        this.props.setFieldValue("acceptedVoaTermsAndConditions", false);

        if (this.props.isAuthenticated) {
            const shouldValidate = false;
            this.props.setFieldValue(
                "plaidResponse",
                plaidResponse,
                shouldValidate
            );

            this.props.submitForm();
        }
    };

    updateSSNAndDateOfBirth = () => {
        const { values, mapValuesToPayload, updateProspect } = this.props;
        const data = mapValuesToPayload(values);
        const payload = pickBy(
            data,
            value =>
                !(
                    value === undefined ||
                    (typeof value === "number" && isNaN(value)) ||
                    (typeof value === "string" && value.trim().length === 0)
                )
        );

        this.setState({ lastUpdate: payload });
        return Promise.resolve().then(() => updateProspect({ ...payload }));
    };

    isVerifiedButtonDisabled = () => {
        const { prospect, isSubmitting } = this.props;
        const { isAccepting } = this.state;
        const canProceed = this.isStepValid();
        const fetchingProspect = get(prospect, "fetchingProspect");

        return !canProceed || fetchingProspect || isAccepting || isSubmitting;
    };

    buildVerifyIDButton = () => {
        const { prospect, isSubmitting } = this.props;
        const { isAccepting } = this.state;
        const fetchingProspect = get(prospect, "fetchingProspect");
        const disabled = this.isVerifiedButtonDisabled();
        return (
            <PlaidButton
                type="button"
                disabled={disabled}
                className={` ${disabled ? "is-disabled" : ""}`}
                isLoading={fetchingProspect || isAccepting || isSubmitting}
                onBeforeOpenPlaid={this.updateSSNAndDateOfBirth}
                onSuccess={this.onSuccessfullySelectedBankAccounts}
            >
                Verify
            </PlaidButton>
        );
    };

    isStepValid = () =>
        this.areFieldsValid([
            "ssn",
            "birthday",
            "birthday.day",
            "birthday.month",
            "birthday.year"
        ]);

    render() {
        const { voaFinished, voaSuccess } = this.state;
        const isStepFulfilled = voaFinished && voaSuccess;
        const button = this.buildVerifyIDButton();
        return (
            <Fragment>
                <ScrollToTopOnMount />
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        Verify your identity
                    </div>
                    <div className="buyer__signup__fieldset">
                        {this.SsnDigit()}
                        {this.dateOfBirth()}
                        {this.informativeText()}

                        {this.renderStepButtons(
                            {
                                canProceed: isStepFulfilled,
                                verifiedFoundingSources: isStepFulfilled,
                                hasMixPanelTracking: true,
                                notPreviousStep: true,
                                replaceButton: true
                            },
                            this.mixpanelFunction,
                            button
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default StepFinancial;
