import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import join from "lodash/join";
import { Modal, ModalBody } from "react-bootstrap";
import CloseModalButton from "../CloseModalButton/CloseModalButton";
import { Button } from "../Button/Button";
import {
    realtorContactBuyer,
    realtorContactSeller
} from "../../util/service_api";

import "./realtorprofilecontactmodal.css";

export default class RealtorProfileContactModal extends React.Component {
    static propTypes = {
        isSeller: PropTypes.bool,
        buyerId: PropTypes.number.isRequired,
        brokerageName: PropTypes.string.isRequired,
        buyerName: PropTypes.string.isRequired,
        agentName: PropTypes.string.isRequired,
        agentPhone: PropTypes.string.isRequired,
        citiesBuyerLookingInto: PropTypes.arrayOf(PropTypes.string).isRequired,
        isVisible: PropTypes.bool,
        onClose: PropTypes.func,
        onSuccessfullyContact: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialMessage = () =>
        `Hi ${this.props.buyerName}

My name is ${this.props.agentName} with ${
            this.props.brokerageName
        } and I would love to assist you in helping you find your dream home.

Would you be available to hop on a quick call to discuss exactly what you are looking for in ${this.buildCitiesBuyerIsLookingInto()}?. If so, please let me know a day and time that works best for you, as well as the best number to reach you at. I can also be reached at ${
            this.props.agentPhone
        }. I look forward to working with you.`;

    buildCitiesBuyerIsLookingInto = () =>
        join(this.props.citiesBuyerLookingInto, ", ");

    getInitialState = () => ({
        isLoading: false,
        message: this.getInitialMessage(),
        error: false
    });

    onClose = () => {
        this.setState(this.getInitialState());
        if (isFunction(this.props.onClose)) {
            this.props.onClose();
        }
    };

    onChangeMessage = e => {
        this.setState({ message: e.target.value });
    };

    onSuccessfullySubmit = () => {
        this.setState(this.getInitialState());
        if (isFunction(this.props.onSuccessfullyContact)) {
            this.props.onSuccessfullyContact();
        }
    };

    onSubmitFailure = () => {
        this.setState({ error: true });
    };

    onSubmit = e => {
        e.preventDefault();

        this.setState({
            isLoading: true,
            error: false
        });

        const realtorContact = this.props.isSeller
            ? realtorContactSeller
            : realtorContactBuyer;

        realtorContact(
            { buyerId: this.props.buyerId, realtorId: this.props.realtorId },
            this.state.message
        )
            .then(this.onSuccessfullySubmit)
            .catch(this.onSubmitFailure)
            .finally(() => this.setState({ isLoading: false }));
    };

    buildErrorMessageIfRequired = () => {
        if (!this.state.error) {
            return null;
        }

        return (
            <div className="form-group text-center text-danger">
                {!this.props.isSeller &&
                    "There was an error while contacting the buyer, please try again in a few minutes"}
                {this.props.isSeller &&
                    "There was an error while contacting the seller, please try again in a few minutes"}
            </div>
        );
    };

    render = () => (
        <Modal
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            className="realtor-profile-contact-modal"
        >
            <ModalBody>
                <div className="text-right">
                    <CloseModalButton onClick={this.onClose} />
                </div>
                <h1 className="realtor-profile-contact-modal__title">
                    {this.props.isSeller ? "Contact seller" : "Contact buyer"}
                </h1>
                <div className="row">
                    <label htmlFor="message" className="col-xs-12 form-group">
                        Message
                        <textarea
                            id="message"
                            name="message"
                            className="form-control realtor-profile-contact-modal__message-input"
                            rows="10"
                            value={this.state.message}
                            onChange={this.onChangeMessage}
                        />
                    </label>
                    {this.buildErrorMessageIfRequired()}
                    <div className="form-group text-center">
                        <Button text onClick={this.onClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={this.onSubmit}
                            isLoading={this.state.isLoading}
                            disabled={
                                this.state.isLoading || !this.state.message
                            }
                        >
                            Contact
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
