import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import invoke from "lodash/invoke";
import { Modal } from "react-bootstrap";
import Button from "../../Button/Button";
import Cropper from "react-cropper";

import "../profilepicture.css";
import "cropperjs/dist/cropper.css";
export default class ProfilePictureModal extends Component {
    static propTypes = {
        onUpload: PropTypes.func,
        isVisible: PropTypes.bool,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            src: null,
            cropResult: null
        };
    }

    onChange = e => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result });
        };
        reader.readAsDataURL(files[0]);
    };

    setCropRef = cropper => {
        this.cropper = cropper;
    };

    updateSrcImageOnModalClose = () => {
        this.setState({ src: null });
    };

    onCrop = () => {
        const canvas = invoke(this.cropper, "getCroppedCanvas");
        invoke(canvas, "toBlob", this.onCanvasToFile);
        this.setState({ src: null });
    };

    onCanvasToFile = file => {
        invoke(this.props, "onUpload", file);
    };

    croppButton = () => {
        return <Button onClick={this.onCrop}>Apply Profile Image</Button>;
    };

    buildSelectPictureButton = () => {
        const { cropResult } = this.state;
        const { src } = this.state;

        return (
            <Fragment>
                <div className="react-image-cropper-container">
                    <div className="react-image-cropper-container-inner">
                        <label
                            for="file-upload"
                            className="button button--inverted"
                        >
                            Select Image
                            <input
                                id="file-upload"
                                type="file"
                                onChange={this.onChange}
                            />
                        </label>
                        <Cropper
                            className="cropper-plh"
                            aspectRatio={1 / 1}
                            guides={false}
                            src={src}
                            ref={this.setCropRef}
                        />
                    </div>
                    <div>
                        <div className="cropper-box">
                            {cropResult !== null ? (
                                <div>
                                    <img
                                        src={cropResult}
                                        alt="Cropped result"
                                        id="croppedResult"
                                    />
                                </div>
                            ) : null}
                        </div>
                        {src !== null ? this.croppButton() : null}
                    </div>
                    <br style={{ clear: "both" }} />
                </div>
            </Fragment>
        );
    };

    render() {
        return (
            <Modal
                show={this.props.isVisible}
                onHide={this.props.onClose}
                className="modal-profile-image-upload"
            >
                <Modal.Header>
                    <Button
                        icon
                        dark
                        symbol="times"
                        onClick={this.props.onClose}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <h2>Select your profile image</h2>
                    <Fragment>{this.buildSelectPictureButton()}</Fragment>
                </Modal.Body>
                <Modal.Footer>
                    <div className="profile-picture-tips">
                        <h3>Upload tips</h3>
                        <ul className="profile-picture-tips-list">
                            <li className="profile-picture-tips-list-item">
                                <b>Please</b> make sure you use a{" "}
                                <b>high quality picture</b>.
                            </li>
                            <li className="profile-picture-tips-list-item">
                                You are able to{" "}
                                <b>resize, crop and reposition</b> your picture
                                before uploading it.
                            </li>
                            <li className="profile-picture-tips-list-item">
                                <b>Make sure</b> the picture you upload has{" "}
                                <b>good lighting</b> and your{" "}
                                <b>face is visible</b>.
                            </li>
                            <li className="profile-picture-tips-list-item">
                                Your profile picture is a <b>critical factor</b>{" "}
                                in being <b>matched</b> with a lead.
                            </li>
                        </ul>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
