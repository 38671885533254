export const RECEIVE_USER_AUTH_ERROR = "RECEIVE_USER_AUTH_ERROR";
export const RECEIVE_USER_PROFILE = "RECEIVE_USER_PROFILE";
export const INVALIDATE_USER = "INVALIDATE_USER";
export const SUCCESSFULLY_ACCEPTED_MATCH = "SUCCESSFULLY_ACCEPTED_MATCH";
export const SUCCESSFULLY_REJECTED_MATCH = "SUCCESSFULLY_REJECTED_MATCH";
export const SUCCESSFULLY_UPDATE_BUYER_STATUS =
    "SUCCESSFULLY_UPDATE_BUYER_STATUS";
export const RECEIVE_UPDATE_BUYER_STATUS_ERROR =
    "RECEIVE_UPDATE_BUYER_STATUS_ERROR";
export const FETCH_UPLOAD_REALTOR_FILE_ATTACHMENT =
    "FETCH_UPLOAD_REALTOR_FILE_ATTACHMENT";
export const SUCCESSFULLY_UPLOAD_REALTOR_FILE_ATTACHMENT =
    "SUCCESSFULLY_UPLOAD_REALTOR_FILE_ATTACHMENT";
export const SUCCESSFULLY_REMOVE_REALTOR_FILE_ATTACHMENT =
    "SUCCESSFULLY_REMOVE_REALTOR_FILE_ATTACHMENT";
export const UPLOAD_REALTOR_FILE_ATTACHMENT_ERROR =
    "UPLOAD_REALTOR_FILE_ATTACHMENT_ERROR";
export const SUCCESSFULLY_FETCH_ACTIVE_MATCHES =
    "SUCCESSFULLY_FETCH_ACTIVE_MATCHES";
export const FETCH_ACTIVE_MATCHES_ERROR = "FETCH_ACTIVE_MATCHES_ERROR";
export const FETCH_SIGNUP_USER = "FETCH_SIGNUP_USER";
export const SUCCESSFULLY_SIGNUP_USER = "SUCCESSFULLY_SIGNUP_USER";
export const ERROR_SIGNUP_USER = "ERROR_SIGNUP_USER";
export const DISPLAY_NEW_ACTIVE_MATCH_MODAL = "DISPLAY_NEW_ACTIVE_MATCH_MODAL";
export const HIDE_NEW_ACTIVE_MATCH_MODAL = "HIDE_NEW_ACTIVE_MATCH_MODAL";
export const SUCCESSFULLY_COMPLETED_REALTOR_TOUR =
    "SUCCESSFULLY_COMPLETED_REALTOR_TOUR";
export const FETCH_BREW_SCORE_USER = "FETCH_BREW_SCORE_USER";
export const SUCCESS_BREW_SCORE_USER = "SUCCESS_BREW_SCORE_USER";
export const ERROR_BREW_SCORE_USER = "ERROR_BREW_SCORE_USER";
export const SUCCESS_SIGN_UP_USER = "SUCCESS_SIGN_UP_USER";
export const ERROR_SIGN_UP_USER = "ERROR_SIGN_UP_USER";
export const FETCH_SEARCH_AGENTS_USER = "FETCH_SEARCH_AGENTS_USER";
export const SUCCESS_SEARCH_AGENTS_USER = "SUCCESS_SEARCH_AGENTS_USER";
export const ERROR_SEARCH_AGENTS_USER = "ERROR_SEARCH_AGENTS_USER";
export const ERROR_SIGN_UP_USER_SELLER = "ERROR_SIGN_UP_USER_SELLER";
export const SUCCESS_SIGN_UP_USER_SELLER = "SUCCESS_SIGN_UP_USER_SELLER";
export const FETCH_SEARCH_SELLER_AGENTS_USER =
    "FETCH_SEARCH_SELLER_AGENTS_USER";
export const SUCCESS_SEARCH_SELLER_AGENTS_USER =
    "SUCCESS_SEARCH_SELLER_AGENTS_USER";
export const ERROR_SEARCH_SELLER_AGENTS_USER =
    "ERROR_SEARCH_SELLER_AGENTS_USER";
export const FETCH_CONTACT_REBATE = "FETCH_CONTACT_REBATE";
export const SUCCESS_CONTACT_REBATE = "SUCCESS_CONTACT_REBATE";
export const ERROR_CONTACT_REBATE = "ERROR_CONTACT_REBATE";
export const REMOVE_REALTOR_FILE_ATTACHMENT = "REMOVE_REALTOR_FILE_ATTACHMENT";
export const UPDATE_BUYER_STATUS = "UPDATE_BUYER_STATUS";
