import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Button } from '../Button/Button';

import './BrewBoardDownloadModal.css';

export default class BrewBoardDownloadModal extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        onClose: PropTypes.func,
        onOptionChange: PropTypes.func,
        handleDownloadLink: PropTypes.string,
        selectedExtensionType: PropTypes.string,
        searchTermUsed: PropTypes.string,
    };

    render = () => {
        return (
            <Modal 
                show={this.props.isVisible}
                onHide={this.props.onClose} 
                className="modal-file-download">
                    <Modal.Header>
                        <Button
                            icon
                            dark
                            symbol="times"
                            onClick={this.props.onClose}
                        >
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <h1>Choose your format</h1>
                        <div className="modal-file-download-radio">
                            <div className={`modal-file-download-radio-item ${this.props.selectedExtensionType === 'csv' ? ' active' : ''} csv`}>
                            <label>
                                <input type="radio" value="csv"
                                    checked={this.props.selectedExtensionType === 'csv'}
                                    onChange={this.props.onOptionChange} 
                                    onClick={this.props.onOptionChange} />CSV</label>
                            </div>
                            <div className={`modal-file-download-radio-item ${this.props.selectedExtensionType === 'xlsx' ? ' active' : ''} xlsx`}>
                                <label>
                                <input type="radio" value="xlsx"
                                    checked={this.props.selectedExtensionType === 'xlsx'}
                                    onChange={this.props.onOptionChange}
                                    onClick={this.props.onOptionChange} />XLSX</label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-file-download-controlls">
                            <Button inverted onClick={this.props.onClose}>
                                Cancel
                            </Button>
                            <a className="button" href={this.props.handleDownloadLink} onClick={this.props.onClose}>Export</a>
                        </div>
                    </Modal.Footer>
                </Modal>
        );
    };
}

