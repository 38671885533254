import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import './statusRow.css';

const StatusRow = (props) => {
    return (
        <div className={`row status-role__item-row--${props.align}`}>
            <hr className={`status-role__hr-status--${get(props.status, 'color')}`}/>
            <div className={`col-xs-12 status-role__status status-role__status--${props.align} status-role__status--${get(props.status, 'color')}`}>
                {get(props.status, 'displayName')}
            </div>
        </div>
    );
};

StatusRow.propTypes = {
    status: PropTypes.any,
    align: PropTypes.oneOf([
        'right',
        'left',
    ]),
};

StatusRow.defaultProps = {
    align: 'left',
};

export default StatusRow;
