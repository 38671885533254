import React from 'react';
import ProfileInterestField from './ProfileInterestField';

export default () =>
    <div className="profile__personal__container">
        <div className="profile__personal__section is-full-width">
            <div className="profile__message is-centred">
                <b>Psst!</b> Anything else you'd like to share with your Partner Agent?
            </div>

            <div className="profile__preferences">
                <div className="profile__preferences__column">
                    <ProfileInterestField
                        name="interests"
                        label="Interests"
                        lookup="autocomplete/interest"
                        isArray
                    />

                    <ProfileInterestField
                        name="otherPreferences"
                        label="Desired Housing Preferences"
                        field="buyerOtherPreferences"
                    />
                </div>

                <div className="profile__preferences__column">
                    <ProfileInterestField
                        name="languages"
                        label="Languages"
                        lookup="autocomplete/language"
                        isArray
                    />

                    <ProfileInterestField
                        name="cuisines"
                        label="Favorite Cuisines"
                        lookup="autocomplete/cuisine"
                        isArray
                    />
                </div>
            </div>
        </div>
    </div>;
