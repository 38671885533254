import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";

import RealtorSignUp from "./RealtorSignUp/RealtorSignUp";
import BrokerSignUp from "./BrokerSignUp/BrokerSignUp";
import EmdSignUp from "./EmdSignUp/EmdSignUp";
import BuyerSignUp from "./BuyerSignUp/BuyerSignUp";
import SellerSignUpBrew from "./SellerSignUpBrew/SellerSignUpBrew";

class SignUp extends Component {
    static propTypes = {
        routeTitle: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            signUpType: "buyer"
        };
    }

    render() {
        return (
            <Fragment>
                <div className="signup__container">
                    <div className="signup__title">{this.props.routeTitle}</div>

                    <div className="signup__borrower">
                        <Route
                            path="/signup/realtor"
                            component={RealtorSignUp}
                        />
                    </div>
                    <Route path="/signup/seller" component={SellerSignUpBrew} />
                    <Route path="/signup/transfer" component={EmdSignUp} />
                    <Route path="/signup/buyer" component={BuyerSignUp} />
                    <Route path="/signup/broker" component={BrokerSignUp} />
                    <Route
                        exact
                        path="/signup/"
                        render={() => <Redirect to="/get-started" />}
                    />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(SignUp);
