import React, { Component } from "react";
import PropTypes from "prop-types";
import invoke from "lodash/invoke";

import "./socialMedia.css";

export default class socialMedia extends Component {
    onChangeUrl = (label, event) => {
        const newUrl = event.target.value;
        invoke(this.props, "onEdit", label, newUrl);
    };

    buildIconLink = (url, icon) =>
        url ? (
            <a
                href={url}
                className="social-media__link"
                rel="noopener noreferrer"
                target="_blank"
            >
                <div className="social-media__icon-box">
                    <i
                        className={`fab fa-${icon} social-media__icon__${icon}`}
                    />
                </div>
            </a>
        ) : null;

    buildEditField = (url, icon, label) => (
        <div className="social-media__edit">
            <div className="social-media__icon-box">
                <i className={`fab fa-${icon} social-media__icon__${icon}`} />
            </div>
            <div class="input-group social-media__input">
                <input
                    id="url"
                    type="url"
                    class="form-control"
                    name="url"
                    placeholder="https://www.example.com"
                    value={url}
                    onChange={e => this.onChangeUrl(label, e)}
                />
            </div>
        </div>
    );

    buildEditMedia = () => (
        <div className={this.props.className}>
            {this.buildEditField(
                this.props.linkedInUrl,
                this.props.linkedinIcon,
                "publicProfile.linkedInUrl"
            )}
            {this.buildEditField(
                this.props.igUrl,
                this.props.instagramIcon,
                "publicProfile.igUrl"
            )}
            {this.buildEditField(
                this.props.facebookUrl,
                this.props.facebookIcon,
                "publicProfile.facebookUrl"
            )}
            {this.buildEditField(
                this.props.youtubeUrl,
                this.props.youtubeIcon,
                "publicProfile.youtubeUrl"
            )}
        </div>
    );

    buildMediaLinks = () => (
        <div className={this.props.className}>
            {this.buildIconLink(
                this.props.linkedInUrl,
                this.props.linkedinIcon
            )}
            {this.buildIconLink(this.props.igUrl, this.props.instagramIcon)}
            {this.buildIconLink(
                this.props.facebookUrl,
                this.props.facebookIcon
            )}
            {this.buildIconLink(this.props.youtubeUrl, this.props.youtubeIcon)}
        </div>
    );

    render() {
        return this.props.edit ? this.buildEditMedia() : this.buildMediaLinks();
    }
}

socialMedia.defaultProps = {
    linkedinIcon: "linkedin",
    instagramIcon: "instagram",
    facebookIcon: "facebook",
    youtubeIcon: "youtube",
    className: "social-media",
    edit: false
};

socialMedia.propTypes = {
    linkedInUrl: PropTypes.string,
    igUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    youtubeUrl: PropTypes.string,
    className: PropTypes.string,
    edit: PropTypes.bool,
    onEdit: PropTypes.func
};
