import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Button } from '../Button/Button';
import CloseModalButton from '../CloseModalButton/CloseModalButton';
import {
    PhoneField,
} from '../Form';
import {
    BUYER_INCREASE_REBATE_MODAL,
    REGEXP_VALIDATION_EMAIL,
    REGEXP_10_CHAR,
} from '../../shared/constants';
import find from 'lodash/find';
import mailbox from '../../styles/assets/Inbox-Icon.svg';
import mixpanel from 'mixpanel-browser';

import './buyerIncreaseRebateModal.css';

export default class BuyerIncreaseRebateModal extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        onSend: PropTypes.func,
        isSending: PropTypes.bool,
        isSended: PropTypes.bool,
        transactionContactTypes: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.resetState();
        }
    }

    resetState = () => (
        this.setState(this.getInitialState())
    )

    getInitialState = () => ({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        firstNameError: null,
        lastNameError: null,
        emailError: null,
        phoneError: null,
        isSendInit: false,
        emailField: true,
    });

    onChangeFirstName = (e) => this.setState({ firstName: e.target.value, firstNameError: null });

    onChangeLastName = (e) => this.setState({ lastName: e.target.value, lastNameError: null });

    onChangeEmail = (e) => this.setState({ email: e.target.value, emailError: null });

    onChangePhone = (e) => this.setState({ phone: e, phoneError: null });

    onChangeEmailField = () => this.setState({ emailField: true });

    onChangePhoneField = () => this.setState({ emailField: false });

    buildErrorMessage = (error) => (
        <div className="buyer-increase-rebate-modal__password--error">
            <div className="buyer-increase-rebate-modal__field-error--content">
                <div className="buyer-increase-rebate-modal__field-error">
                    {error}
                </div>
            </div>
        </div>
    )

    setErrorMessageFieldRequired = (field, value, message) => {
        const newValue = !value ? BUYER_INCREASE_REBATE_MODAL['required'](message) : null;
        this.setState({ [`${field}Error`]: newValue });
    }

    setErrorMessage = (field, type) => {
        const newValue = BUYER_INCREASE_REBATE_MODAL[type];
        this.setState({ [`${field}Error`]: newValue });
    }

    validateErrorMessage = (field) => {
        const errorMessage = get(this.state, `${field}Error`);
        return errorMessage ? this.buildErrorMessage(errorMessage) : null;
    }

    validateEmail = (field) => {
        const element = get(this.state, field);

        if(!isEmpty(element)) {
            if (!REGEXP_VALIDATION_EMAIL.test(element)) {
                this.setErrorMessage(field, 'email');
                return;
            }
        }

        if(isEmpty(element)) {
            this.setErrorMessageFieldRequired(field, element,'Email');
            return;
        }

        this.setState({ [`${field}Error`]: null });
    }

    validatePhone = (field) => {
        const element = get(this.state, field);

        const phoneWithoutDash = element.replace(/\D/g, '');
        if(!isEmpty(phoneWithoutDash)) {
            if (!REGEXP_10_CHAR.test(phoneWithoutDash)) {
                this.setErrorMessage(field, 'phone');
                return;
            }
        }

        if(isEmpty(phoneWithoutDash)) {
            this.setErrorMessageFieldRequired(field, phoneWithoutDash,'Phone');
            return;
        }

        this.setState({ [`${field}Error`]: null });
    }

    buildFirstNameField = () => {
        const { firstName } = this.state;
        return (
            <div className="form-group buyer-increase-rebate-modal__container">
                <input
                    id="firstName"
                    type="text"
                    className="form-control buyer-increase-rebate-modal__form-field--first-name"
                    value={firstName}
                    onChange={this.onChangeFirstName}
                    placeholder="First Name"
                    onBlur={() => this.setErrorMessageFieldRequired('firstName', firstName, 'First Name')}
                />
                {this.validateErrorMessage('firstName')}
            </div>
        );
    }

    buildLastNameField = () => {
        const { lastName } = this.state;
        return (
            <div className="form-group buyer-increase-rebate-modal__container">
                <input
                    id="lastName"
                    type="text"
                    className="form-control buyer-increase-rebate-modal__form-field--last-name"
                    value={lastName}
                    onChange={this.onChangeLastName}
                    placeholder="Last Name"
                    onBlur={() => this.setErrorMessageFieldRequired('lastName', lastName, 'Last Name')}
                />
                {this.validateErrorMessage('lastName')}
            </div>
        );
    }

    buildEmailField = () => (
        <div className="form-group buyer-increase-rebate-modal__container">
            <input
                id="email"
                type="email"
                className="form-control buyer-increase-rebate-modal__form-field--email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                onBlur={() => this.validateEmail('email')}
            />
            {this.validateErrorMessage('email')}
        </div>
    )

    buildPhoneField = () => (
        <div className="form-group buyer-increase-rebate-modal__container">
            <PhoneField
                id="phone"
                type="text"
                className="form-control buyer-increase-rebate-modal__form-field--email"
                value={this.state.phone}
                onChange={this.onChangePhone}
                onBlur={() => this.validatePhone('phone')}
                isInputField={true}
            />
            {this.validateErrorMessage('phone')}
        </div>
    )

    buildIconCheckedRadioField = (handleClick) => (
        <i onClick={handleClick} className="fa fa-dot-circle buyer-increase-rebate-modal__radio" />
    )

    buildIconRadioField = (handleClick) => (
        <i onClick={handleClick} class="fa fa-circle buyer-increase-rebate-modal__radio" />
    )

    buildIcon = (checked, handleClick) => (
        checked ? this.buildIconCheckedRadioField(handleClick) : this.buildIconRadioField(handleClick)
    )

    buildEmailOrPhoneField = () => {
        const { emailField } = this.state;
        return (
            <div className="form-group buyer-increase-rebate-modal__radio-buttons">
                {this.buildIcon(emailField, this.onChangeEmailField)}
                <div className="buyer-increase-rebate-modal__radio-label">
                    Email or
                </div>
                {this.buildIcon(!emailField, this.onChangePhoneField)}
                <div className="buyer-increase-rebate-modal__radio-label">
                    Phone
                </div>
                {emailField ? this.buildEmailField() : this.buildPhoneField()}
            </div>
        );
    }

    buildForm = () => (
        <div>
            <div className="row buyer-increase-rebate-modal__fields">
                <div className="buyer-increase-rebate-modal with-padding col-xs-12">
                    {this.buildFirstNameField('emailAddressFirst')}
                </div>
                <div className="buyer-increase-rebate-modal with-padding col-xs-12">
                    {this.buildLastNameField('emailAddressSecond')}
                </div>
                <div className="buyer-increase-rebate-modal with-padding col-xs-12">
                    {this.buildEmailOrPhoneField('emailAddressThird')}
                </div>
            </div>
        </div>
    );

    haveEmptyFields = () => {
        const {
            firstName,
            lastName,
            email,
            phone,
            emailField,
        } = this.state;
        const emailOrPhoneField = emailField ? email : phone;

        return !(firstName || lastName || emailOrPhoneField);
    };

    haveErrors = () => {
        const {
            firstNameError,
            lastNameError,
            emailError,
            phoneError,
            emailField,
        } = this.state;
        const emailOrPhoneError = emailField ? emailError : phoneError;

        return (firstNameError || lastNameError || emailOrPhoneError);
    };

    isDisableSendButton = () => {
        const { isSending, isSended } = this.props;

        return (this.haveEmptyFields() || this.haveErrors() || isSending || isSended);
    };

    onSend = () => {
        const { onSend } = this.props;
        const {
            firstName,
            lastName,
            email,
            phone,
            emailField,
        } = this.state;

        const contactValue = emailField ? email : phone;
        const contact = find(this.props.transactionContactTypes, (item) => get(item, 'name') === 'buyer_increase_rebate');
        const emailValue = emailField ? contactValue : '';
        const phoneValue = emailField ? '' : contactValue;

        if (isFunction(onSend)) {
            this.setState({ isSendInit: true });
            const data = {
                contact: {
                    firstName,
                    lastName,
                    email: emailValue,
                    businessPhone: phoneValue,
                },
                transactionContactType: contact,
            };
            onSend(data)
                .then(() => {
                    mixpanel.track("Completed Send Increase Rebate", {
                        "firstName": firstName,
                        "lastName": lastName,
                        "email": emailValue,
                        "phone": phoneValue,
                        "transactionContactTypeId": get(contact, 'id'),
                        "transactionContactTypeName": get(contact, 'name'),
                        "transactionContactTypeDisplayName": get(contact, 'displayName'),
                        "transactionContactTypeState": get(contact, 'state'),
                    });
                })
        }
    }

    buildRebateModalContent = () => (
        <>
            <h1 className="buyer-increase-rebate-modal__title">
                Do you want to increase your rebate?
            </h1>
            <div className="buyer-increase-rebate-modal__subtitle">
                Fill out the form and one of our BrewCrew members will be in touch with you.
            </div>
            {this.buildForm()}
            <div className="buyer-increase-rebate-modal__footer">
                <Button
                    onClick={this.onSend}
                    disabled={this.isDisableSendButton()}
                    isLoading={this.props.isSending}
                >
                    CONTACT US
                </Button>
            </div>
        </>
    )

    buildModalMailInfo = () => (
        <div className="buyer-increase-rebate-modal__mail-information">
            We have received your request to increase your rebate.
            Someone from our team will be reaching out to you shortly.
            <br />
            <br />
            <img src={mailbox} alt="mailbox" />
        </div>
    )

    render = () => {
        const {
            isSended,
            onClose,
            isVisible,
        } = this.props;
        
        const buildContent = isSended ? this.buildModalMailInfo() : this.buildRebateModalContent();

        return (
            <Modal
                animation
                autoFocus
                backdrop
                enforceFocus
                show={isVisible}
                className="buyer-increase-rebate-modal"
            >
                <ModalHeader>
                    <CloseModalButton onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    {buildContent}
                </ModalBody>
            </Modal>
        );
    }
}
