import React from "react";
import CustomRealtorAutoCompleteField from "../../CustomRealtorAutoCompleteField/CustomRealtorAutoCompleteField";

import "./languages.scss";

const StepThree = props => {
    return (
        <div className="languages__container">
            <div className="level__one__text">
                What's your preferred language of communication?
            </div>
            <CustomRealtorAutoCompleteField
                fieldProp="languages"
                labelProp="name"
                autoCompleteUri="autocomplete/language"
                placeholder="Search"
                value={props.value}
                isMulti
                onEdit={props.onEdit}
                allowRepeatedValues={false}
                allowSelectingWithCheckbox
            />
        </div>
    );
};

export default StepThree;
