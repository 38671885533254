import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import UserBuyerProfile from '../UserProfile/UserBuyerProfile';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { loadBuyer } from '../../util/service_api';

class BuyerPublicProfileComponent extends React.Component {
    static propTypes = {
        match: PropTypes.any,
        onFetchProfile: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { profile: null };
    }

    componentDidMount = () => {
        const {
            onFetchProfile,
            match,
        } = this.props;

        const profileId = get(match, 'params.id');

        Promise.resolve()
            .then(() => onFetchProfile(profileId))
            .then((profile) => this.setState({
                profile: { publicProfile: profile },
            }));
    };

    render = () => {
        if (!this.state.profile) {
            return <SpinnerLoader />;
        }

        return <UserBuyerProfile isPublicView profile={this.state.profile} />;
    };
}

const BuyerPublicProfile = connect(
    null,
    {
        onFetchProfile: (invitationCode) => () => loadBuyer(invitationCode),
    },
)(BuyerPublicProfileComponent);

export default BuyerPublicProfile;
