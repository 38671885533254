import React from 'react';
import { Link } from 'react-router-dom';
import '../BuyerSignUp/SignUpStep/termsandpolicies.css';

const RealtorTermsAndConditions = () => (
        <div className="lb__documentation__content">
            <h1 className="lb__documentation__content__title">REAL ESTATE AGENT AND BROKER TERMS OF USE</h1>
            <div className="lb__documentation__content__inner">
                <h2 className="lb__documentation__content__inner__title">1. General</h2>
                <div className="lb__documentation__content__inner__text">
                This Real Estate Agent Terms of Use Agreement (“Agreement”) governs your (the “Real Estate Agent”) access and use of the website, <Link to="https://www.lemonbrew.com">www.LemonBrew.com</Link> (“Website”), including sections of the Website related to your profile and delivering and accessing Referrals (the “Real Estate Agent Referral Platform”). This Website is owned and operated by LemonBrew Technologies Corp., and the terms and conditions herein apply to LemonBrew Technologies Corp., its parent companies, subsidiaries, affiliates, including its licensed real estate broker subsidiaries, referred to below as "LemonBrew", "we", "us", "our" or "Company". Your use of this Website and your transactions conducted with Company in connection with this Website are subject to the following terms and conditions.
                </div>
                <div className="lb__documentation__content__inner__text">
                The terms and conditions herein are in addition to, and do not override, the specific terms and conditions in your agreements with LemonBrew, including but not limited to the Referral Agreement. Refer to your LemonBrew Realty Referral Agreement for more information.
                </div>
                <h2 className="lb__documentation__content__inner__title">2. Services</h2>
                <h3 className="lb__documentation__content__inner__subtitle">a.      Introduction.</h3>
                <div className="lb__documentation__content__inner__text">
                LemonBrew is an online web platform where Consumers may be matched with Real Estate Agents. LemonBrew is not a mortgage lender or mortgage broker. We do not make any loan commitments or fund any mortgage loans. A Referral is defined when LemonBrew has introduced the Real Estate Agent to the Buyer or Seller via phone, email, text or through the LemonBrew Website or portal. By accepting a Referral, the Real Estate Agent hereby agrees to the terms and conditions set for on the LemonBrew Realty Referral Agreement. You understand and agree that we may modify and / or change the prices of the products and services offered, including any referral fees.
                </div>
                <h3 className="lb__documentation__content__inner__subtitle">b.      Brewscore.</h3>
                <div className="lb__documentation__content__inner__text">
                LemonBrew provides the BrewScore to review and match Consumers with Real Estate Agents. The BrewScore and Website allow Consumers to submit, and authorize LemonBrew to obtain, information regarding, among other things, the Consumer’s finances, assets, credit history, and homebuying preferences, for use in matching the Consumer to Real Estate Agents and reviewing the Consumer’s potential to receive a rebate from the Real Estate Agent.
                </div>
                <h2 className="lb__documentation__content__inner__title">3. Privacy Policy</h2>
                <div className="lb__documentation__content__inner__text">
                Our privacy policy ("Privacy Policy") describes the information that we collect from you when you visit and use our Website, how we share it, and how we use it. By using the Website you consent to our Privacy Policy and the collection, use and sharing of information as discussed in the policy.
                </div>
                <h2 className="lb__documentation__content__inner__title">4. Use and Access of our Website</h2>
                <div className="lb__documentation__content__inner__text">
                By using this Website, you accept and agree with these terms and conditions whether or not you complete a transaction with Company and whether or not you complete a transaction on our Website or through any other channel associated with Company. By using this Website and our Services, you represent and warrant to us that you are of sufficient legal age to enter into this Agreement. You agree to not pretend that you are, or that you represent, someone else, or impersonate any other person or entity. You represent and warrant that the information that you provide to us will be current, true and accurate. Any incorrect or incomplete information may affect the value of our service.
                </div>
                <div className="lb__documentation__content__inner__text">
                We reserve the right at all times, in its sole discretion and without notice to you, to deny your access to and use of this Website. You agree that you may not use or attempt to use any part of this Website to represent that you have any affiliation with LemonBrew without express written permission from us. You agree and acknowledge that you have the sole responsibility and liability for your use of this Website and for providing or obtaining, and for maintaining, all of the hardware, software, electrical power, telecommunications, Internet services, and other products or services necessary or desirable for you to access and use this Website.
                </div>
                <h2 className="lb__documentation__content__inner__title">5. Limitation of Liability</h2>
                <div className="lb__documentation__content__inner__text">
                You agree to indemnify LemonBrew and all of the employees, officers, directors, agents and representatives thereof and to hold all of them harmless from, all costs, claims, damages, expenses or other losses, including attorney’s fees and court costs, that arise from or are related to your use of this Website and/or your breach/violation of or failure to comply with the Terms of Use.
                </div>
                <div className="lb__documentation__content__inner__text">
                The content, services, calculations, information, products and materials on or available through this Website are provided "as is" and without warranties of any kind, either expressed or implied. To the fullest extent permissible under applicable law, LemonBrew disclaims all warranties, express or implied, including, but not limited to, all implied warranties of merchantability, fitness for a particular purpose, title and non- infringement. We make no representation or warranty regarding the content, services, calculations, information, products or materials, or the use or results of use of the content, services, calculations, products or materials, on or available through this web site. The content, services, calculations, information, products and materials on or available through this Website could include inaccuracies or typographical errors and could become inaccurate because of developments occurring after their respective dates of preparation or publication. Our aggregate liability to you for any cause whatsoever and regardless of the form of action, will at all times, be limited to the amount paid to us by you for use of the Website.
                </div>
                <h2 className="lb__documentation__content__inner__title">6. No Warranty</h2>
                <div className="lb__documentation__content__inner__text">
                You assume all risks concerning the suitability and accuracy of the information provided by LemonBrew and all risks associated with the representation of any consumers, which may include face to face meetings with the customer in empty homes, driving to and from various destinations and other activities typically associated with a real estate transaction. LemonBrew assumes no responsibility for and disclaims all liability for any such activities, and any inaccuracies, errors or omissions. Much of the data contained in the LemonBrew Service is provided to us by third parties and therefore we do not control the accuracy or completeness of the information contained in the Services, including the BrewScore. The content, Services, calculations, information, products and materials on or available through this Website are provided "as is" and without warranties of any kind, either expressed or implied. To the fullest extent permissible under applicable law, LemonBrew disclaims all warranties, express or implied, including, but not limited to, all implied warranties of merchantability, fitness for a particular purpose, title and non- infringement. We make no representation or warranty regarding the content, Services, calculations, information, products or materials, or the use or results of use of the content, Services, calculations, products or materials, on or available through this web site. The content, Services, calculations, information, products and materials on or available through this Website could include inaccuracies or typographical errors and could become inaccurate because of developments occurring after their respective dates of preparation or publication.
                </div>
                <h2 className="lb__documentation__content__inner__title">7. Voluntary Participation</h2>
                <div className="lb__documentation__content__inner__text">
                Your participation in Real Estate Agent Referral Platform is voluntary and can be terminated by either party at any time with a written notice. Any Referrals made prior to such termination are still bound by this your agreements with LemonBrew, including these terms and conditions.
                </div>
                <h2 className="lb__documentation__content__inner__title">8. No Employment Relationship</h2>
                <div className="lb__documentation__content__inner__text">
                You will not act as LemonBrew employee under the meaning of or application of any United States federal or state laws relating to unemployment insurance, retirement benefits, Social Security, worker's compensation, or any regulations that may impute any obligation or liability to LemonBrew because of an employment relationship. You are not the agent of the Company and are not authorized to make any representation, contract, or commitment on behalf of the Company. You are not entitled to any of the benefits which LemonBrew may make available to its employees, such as group insurance, profit sharing or retirement benefits.
                </div>
                <h2 className="lb__documentation__content__inner__title">9. Real Estate Agent’s Representations</h2>
                <div className="lb__documentation__content__inner__text">
                You represent that you are of legal age and capable of forming a legally binding contract and that you hold an active real estate agent’s and/or real estate broker’s license in good standing in the state or country in which you conduct business. You agree to maintain your real estate license and comply with all applicable state and federal laws and regulations. You agree to provide all required disclosures, documentation, and comply with all applicable Real Estate Agent responsibilities. You represent and warrant that you have obtained the permission of his or her broker to use the Broker Referral Platform, pay the Referral Fee, and provide the Real Estate Agent Credit to the Referred Consumer (as these terms are defined and/or calculated in the Referral Agreement).
                </div>
                <div className="lb__documentation__content__inner__text">
                All information you submit to us is true and complete and you will maintain and promptly update your profile or other information you provide to us.
                </div>
                <div className="lb__documentation__content__inner__text">
                You agree that you will not use the Website or Services as, or represent yourself as a Consumer, even if you intend to buy property. You agree that fraudulent usage of any of our Services may subject you to liability.
                </div>
                <h2 className="lb__documentation__content__inner__title">10. Fair Credit Reporting Act Certification</h2>
                <div className="lb__documentation__content__inner__text">
                By participating in the Real Estate Agent Referral Platform, the you acknowledge that you are the end user of a credit report provided by LemonBrew, and subject to the applicable requirements of the federal Fair Credit Reporting Act and Regulation V, as well as all other applicable state and federal laws and regulations. You agree to the FCRA User Certification, and certify to LemonBrew that the Real Estate Agent understands and will comply with its obligations under the FCRA. You certify that all reports (including the BrewScore) will be obtained and used only in connection with one of the following permissible purposes: (i) According to written instruction provided by the Consumer to LemonBrew and you (As a LemonBrew Partner Agent), as shown by an active Consumer account on the LemonBrew portal; and/or (ii) for a legitimate business need in connection with a transaction initiated by the Consumer.
                </div>
                <h2 className="lb__documentation__content__inner__title">11.  Indemnification</h2>
                <div className="lb__documentation__content__inner__text">
                You are responsible for the real estate brokerage services provided to the Referred Consumer. You will indemnify and hold harmless LemonBrew and its affiliates, Broker’s and its affiliates' officers, directors, employees, sublicensees, parents, subsidiaries, affiliates, customers and agents from any and all claims, losses, liabilities, damages, expenses and costs (including attorneys' fees and court costs) which result from a breach or alleged breach of any representation or warranty (a “Claim”) set forth above; provided that the we gives you written notice of any such Claim and you have the right to participate in the defense of any such Claim at your expense.
                </div>
                <h2 className="lb__documentation__content__inner__title">12.  Other Legal Terms</h2>
                <h3 className="lb__documentation__content__inner__subtitle">a.      Severability.</h3>
                <div className="lb__documentation__content__inner__text">
                If any provision of this Agreement is found by a court of competent jurisdiction or arbitrator to be illegal, void, or unenforceable, the unenforceable provision will be modified so as to render it enforceable and effective to the maximum extent possible in order to effect the intention of the provision.
                </div>
                <h3 className="lb__documentation__content__inner__subtitle">b.     Notices.</h3>
                <div className="lb__documentation__content__inner__text">
                You may contact us here <a href="mailto:legal@lemonbrew.com">legal@lemonbrew.com</a>. Or via mail or courier at:<br /><br />
                PO Box 18272<br />
                1233 The PLZ<br />
                Charlotte, NC 28205
                </div>
                <h3 className="lb__documentation__content__inner__subtitle">c.     No Waiver. </h3>
                <div className="lb__documentation__content__inner__text">
                Our failure to act with respect to a breach of this Agreement by you or others does not waive our right to act with respect to that breach or subsequent similar or other breaches.
                </div>
                <h3 className="lb__documentation__content__inner__subtitle">d.      Assignment and Delegation.</h3>
                <div className="lb__documentation__content__inner__text">
                You may not assign or delegate any rights or obligations under the Agreement. Any purported assignment and/or delegation by you shall be ineffective. We may freely assign or delegate all rights and obligations under the Agreement, fully or partially without notice to you. We may also substitute, by way of unilateral novation, effective upon notice to you, LemonBrew for any third party that assumes our rights and obligations under this Agreement.
                </div>
                <h3 className="lb__documentation__content__inner__subtitle">e.      Amendments to This Agreement and Termination</h3>
                <div className="lb__documentation__content__inner__text">
                We reserve the right to modify, supplement, or replace all or any portion of the terms of this Agreement at any time by posting updated terms on the Website.
                </div>
                <h3 className="lb__documentation__content__inner__subtitle">f.       GoverningLaw.</h3>
                <div className="lb__documentation__content__inner__text">
                This Agreement shall be governed by, interpreted, construed, applied and enforced in accordance with the laws of the State of New Jersey. The courts of the State of New Jersey, or the federal court of the United States situated therein, as applicable, shall  have sole and exclusive jurisdiction over any action, claim, demand, proceeding or lawsuit whatsoever arising under or in relation to this Agreement or its subject matter. The parties irrevocably agree, consent and submit themselves to the subject matter and personal jurisdiction of such courts for such purposes.
                </div>
                <h3 className="lb__documentation__content__inner__subtitle">g.      Unavailability.</h3>
                <div className="lb__documentation__content__inner__text">
                In the event that legislation or governmental regulations prohibit the use of any products or services, we may terminate our products services upon the effective date of such legislation or regulations and Real Estate Agent shall discontinue using our products or services. Upon termination, the parties agree to continue their cooperation in order to effect an orderly termination of their relationship.
                </div>
                <h2 className="lb__documentation__content__inner__title">13.  Licenses</h2>
                <div className="lb__documentation__content__inner__text">
                You acknowledge that you have reviewed the licenses held by LemonBrew at:<br /><br />
                <a href='https://www.lemonbrew.com/our-licenses' target='_blank' rel='noopener noreferrer'>https://www.lemonbrew.com/our-licenses</a>.
                </div>
            </div>
        </div>
   );

export default RealtorTermsAndConditions;
