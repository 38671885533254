import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import noop from "lodash/noop";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import mixpanel from "mixpanel-browser";

import "./earnestmoneydepositfirst.css";

export default class EarnestMoneyDepositFirst extends React.PureComponent {
    static propTypes = {
        onMakeDepositClick: PropTypes.func,
        onlyButton: PropTypes.bool,
        positiveBalance: PropTypes.bool,
        isMidUserRole: PropTypes.bool,
        isReadOnlyUserRole: PropTypes.bool,
        isImpersonatingUser: PropTypes.bool
    };

    static defaultProps = {
        onlyButton: false
    };

    buildNegativeBalanceTooltip = content => {
        const popoverText = `You do not have enough funds to create a transfer. 
            Please add another account in order to create a transfer.`;

        if (this.props.positiveBalance) return;

        return (
            <CustomTooltip
                popoverText={popoverText}
                content={content}
                type="black"
            ></CustomTooltip>
        );
    };

    buildDisabledButtonTooltip = content => {
        const popoverText = `You do not have sufficient privileges to perform this action`;

        return (
            <div className="emd-landing-page__tooltip">
                <CustomTooltip
                    popoverText={popoverText}
                    content={content}
                ></CustomTooltip>
            </div>
        );
    };

    onMakeDepositClick = () => {
        const { onMakeDepositClick, onlyButton } = this.props;
        const textButton = onlyButton
            ? "Create New Transfer"
            : "Start Your Transfer";
        mixpanel.track(`Clicked On ${textButton}`);
        onMakeDepositClick();
    };

    buildReadOnlyButton = textButton => {
        const contentTooltip = (
            <Button onClick={noop} disable>
                {textButton}
            </Button>
        );

        return (
            <div className="emd-first__button-wrapper">
                <div className="emd-first__button-transfer">
                    {this.buildDisabledButtonTooltip(contentTooltip)}
                </div>
            </div>
        );
    };

    buildDepositButton = () => {
        const { onlyButton, positiveBalance } = this.props;
        const textButton = onlyButton
            ? "CREATE NEW TRANSFER"
            : "START YOUR TRANSFER";
        const textUnderButton = !onlyButton ? (
            <div className="emd-first__text">
                <p>It's Free and Secure</p>
            </div>
        ) : null;
        const content = <div className="emd-first__button-tooltip"></div>;
        const disabledDepositButton =
            this.props.isImpersonatingUser && this.props.isReadOnlyUserRole;

        if (disabledDepositButton) {
            return this.buildReadOnlyButton(textButton);
        }

        return (
            <>
                <div className="emd-first__button-wrapper">
                    <div className="emd-first__button-transfer">
                        <Link to="/transfer/deposit">
                            <Button
                                onClick={this.onMakeDepositClick}
                                disabled={!positiveBalance}
                            >
                                {textButton}
                            </Button>
                        </Link>
                        {this.buildNegativeBalanceTooltip(content)}
                    </div>
                </div>
                {textUnderButton}
            </>
        );
    };

    render = () => {
        const { onlyButton } = this.props;

        if (onlyButton) {
            return this.buildDepositButton();
        }

        return (
            <div className="emd-first">
                <div className="emd-first__wrapper">
                    <div className="emd-first__text">
                        <h2>Let's Get Started</h2>
                    </div>
                    {this.buildDepositButton()}
                </div>
            </div>
        );
    };
}
