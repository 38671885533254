import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import RealtorSignUpContainer from './RealtorSignUpContainer';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.handleNextstep = this.handleNextstep.bind(this);
    }

    handleNextstep() {
        return this.setState((prevState, prevProps) => { });
    }

    render() {
        return (
            <div className="signup__realtor">
                <RealtorSignUpContainer />
            </div>
        );
    }
}

export default withRouter(SignUp);
