import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import RealtorActiveSellerMatchItem from '../RealtorActiveSellerMatchItem/RealtorActiveSellerMatchItem';

export default class RealtorProfileActiveSellersMatchesTab extends React.PureComponent {
    buildActiveMatch = (activeMatch) => (
        <RealtorActiveSellerMatchItem
            key={get(activeMatch, 'seller.userInfo.id')}
            picture={invoke(this.props, 'getMatchPictureUrl', activeMatch)}
            name={invoke(this.props, 'buildMatchFullName', activeMatch)}
            location={invoke(this.props, 'buildLocation', activeMatch)}
            budget={invoke(this.props, 'buildMatchBudget', activeMatch)}
            timeFrame={get(activeMatch, 'seller.activeProperty.sellingTimeline')}
            maxBudget={invoke(this.props, 'buildMatchMaxBudget', activeMatch)}
            status={invoke(this.props, 'getMatchStatus', activeMatch)}
            viewProfileUrl={invoke(this.props, 'buildViewProfileForMatch', activeMatch)}
        />
    );
    
    render = () => (
        <div className="realtor-profile-active-matches-tab">
            {map(this.props.sellersList, this.buildActiveMatch)}
        </div>
    );
}