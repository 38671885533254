import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import set from 'lodash/set';
import isFunction from 'lodash/isFunction';
import flow from 'lodash/flow';
import findIndex from 'lodash/findIndex';
import partialRight from 'lodash/partialRight';
import trim from 'lodash/trim';
import RealtorProfileTabbed, { ACTIVE_MATCHES_TAB, PENDING_MATCHES_TAB } from '../RealtorProfileTabbed/RealtorProfileTabbed';
import {
    acceptMatch,
    declineMatch,
    updateBuyerStatus,
    uploadRealtorFileAttachment,
    removeRealtorFileAttachment,
} from '../../reducers/user/UserActions';
import { declineSellerMatch, acceptSellerMatch, updateSellerStatus, removeRealtorSellerFileAttachment, uploadRealtorSellerFileAttachment, updateRealtorSellerStatus } from '../../reducers/user/realtorSellerMatch/RealtorSellerMatchActions';
import { contactTypeCalifornia } from '../../shared/constants';

class RealtorProfileComponent extends React.Component {
    static propTypes = {
        tab: PropTypes.string,
        onChangeTab: PropTypes.func,
        user: PropTypes.object,
        configApp: PropTypes.object,
        onAcceptMatch: PropTypes.func,
        onDeclineMatch: PropTypes.func,
        onChangeStatus: PropTypes.func,
        onUploadFile: PropTypes.func,
        onRemoveFile: PropTypes.func,
        onSaveContactInfo: PropTypes.func,
        onUpdateLocationInformation: PropTypes.func.isRequired,
        displayBuyerProfile: PropTypes.number,
        displaySellerProfile: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            indexRejectionValue: 0,
            selectedStatusIndex: 0,
            selectedTypeFileName: 0,

            indexSellerRejectionValue: 0,
            selectedSellerStatusIndex: 0,
            selectedSellerTypeFileName: 0,
        };
    }

    onUploadPicture = (picture) => {
        // REFACTOR
        const { user: { profile } } = this.props;
        const profileWithUpdatedPicture = { ...profile };
        set(profileWithUpdatedPicture, 'publicProfile.userInfo.profilePicUrl', picture);
        this.updateField(profileWithUpdatedPicture);
    };

    onAcceptMatch = (id) => {
        if (isFunction(this.props.onAcceptMatch)) {
            this.props.onAcceptMatch(id);
        }
    };

    onDeclineMatch = (id) => {
        const { configApp } = this.props;
        const { indexRejectionValue } = this.state;
        const buyerMatchDeclinedOptions = get(
            configApp,
            'config.matchStatuses.realtor_buyer_match_declined',
        );
        const selectedDeclinedOption = get(buyerMatchDeclinedOptions, indexRejectionValue);

        if (isFunction(this.props.onDeclineMatch)) {
            this.props.onDeclineMatch(id, selectedDeclinedOption);
        }
    };

    onSelectRejection =(indexRejectionValue) => {
        this.setState({ indexRejectionValue });
    };

    onConfirmSellerMatch = (id) => {
        if (isFunction(this.props.onAcceptSellerMatch)) {
            this.props.onAcceptSellerMatch(id);
        }
    };

    onDeclineSellerMatch = (id) => {
        const { configApp } = this.props;
        const { indexSellerRejectionValue } = this.state;
        const sellerMatchDeclinedOptions = get(
            configApp,
            'config.matchStatuses.realtor_seller_match_declined',
        );
        const selectedDeclinedOption = get(sellerMatchDeclinedOptions, indexSellerRejectionValue);

        if (isFunction(this.props.onDeclineSellerMatch)) {
            this.props.onDeclineSellerMatch(id, selectedDeclinedOption);
        }
    };

    onSelectSellerRejection = (indexSellerRejectionValue) => {
        this.setState({ indexSellerRejectionValue });
    };

    onChangeStatus = (reason, buyerId) => {
        const { configApp } = this.props;
        const { selectedStatusIndex } = this.state;
        const status = get(configApp.config, 'matchStatuses.realtor_buyer_match_updated');
        const selectedStatus = get(status, selectedStatusIndex);
        const data = {
            currentStatus: selectedStatus,
            statusNote: reason,
        };

        if (isFunction(this.props.onChangeStatus)) {
            this.props.onChangeStatus(buyerId, data);
        }
    };

    onConfirmSellerChangeStatus = (reason, sellerId) => {
        const { configApp } = this.props;
        const { selectedSellerStatusIndex } = this.state;
        const status = get(configApp.config, 'matchStatuses.realtor_seller_match_updated');
        const selectedStatus = get(status, selectedSellerStatusIndex);
        const data = {
            currentStatus: selectedStatus,
            statusNote: reason,
        };

        if (isFunction(this.props.onChangeStatus)) {
            this.props.onConfirmSellerChangeStatus(sellerId, data);
        }
    };

    onSelectStatus = (selectedStatusIndex) => {
        this.setState({ selectedStatusIndex });
    };

    onSelectSellerStatus = (selectedSellerStatusIndex) => {
        this.setState({ selectedSellerStatusIndex });
    };

    onUploadFile = (file, match) => {
        const { selectedTypeFileName } = this.state;

        if (isFunction(this.props.onUploadFile)) {
            const realtorMatchId = get(match, 'realtorMatchId');
            this.props.onUploadFile(realtorMatchId, file, selectedTypeFileName);
        }
    };

    onUploadSellerFile = (file, match) => {
        const { selectedSellerTypeFileName } = this.state;

        if (isFunction(this.props.onUploadSellerFile)) {
            const realtorMatchId = get(match, 'realtorMatchId');
            this.props.onUploadSellerFile(realtorMatchId, file, selectedSellerTypeFileName);
        }
    };

    onUploadSelectTypeFile = (selectedTypeFileName) => {
        this.setState({ selectedTypeFileName });
    };

    onUploadSellerSelectTypeFile = (selectedSellerTypeFileName) => {
        this.setState({ selectedSellerTypeFileName });
    };

    onRemoveFile = (file, match) => {
        if (isFunction(this.props.onRemoveFile)) {
            const realtorMatchId = get(match, 'realtorMatchId');
            const fileId = get(file, 'id');
            this.props.onRemoveFile(realtorMatchId, fileId);
        }
    };

    onRemoveSellerFile = (file, match) => {
        if (isFunction(this.props.onRemoveSellerFile)) {
            const realtorMatchId = get(match, 'realtorMatchId');
            const fileId = get(file, 'id');
            this.props.onRemoveSellerFile(realtorMatchId, fileId);
        }
    };

    buildTransactionContacts = () => {
        const { configApp, user } = this.props;
        const california = contactTypeCalifornia;
        const stateId = get(user.profile, 'publicProfile.state.name');
        const transactionContactTypes = get(configApp.config, 'transactionContactTypes');
        return stateId === california ? get(transactionContactTypes, `${stateId}`) : get(transactionContactTypes, 'Other');
    };

    getBuyerList = (user) => get(user.profile, 'buyers');

    getSelectedBuyerIndex = (buyersList, buyerID) => flow(
        partialRight(findIndex, (match) => get(match, 'buyer.userInfo.id') === buyerID),
    )(buyersList);

    onUpdateLocationInformation = (buyerID, data) => {
        const { user } = this.props;
        const buyersList = this.getBuyerList(user);
        const selectedBuyerIndex = this.getSelectedBuyerIndex(buyersList, buyerID);
        const dataLocationInfo = {
            currentStatus: get(buyersList[selectedBuyerIndex], 'currentStatus'),
            propertyAddress: get(data, 'propertyAddress'),
            commission: get(data, 'commission'),
            finalPurchasePrice: get(data, 'finalPurchasePrice'),
            newConstruction: get(data, 'newConstruction'),
            notes: get(buyersList[selectedBuyerIndex], 'notes'),
            anticipatedClosingDate: get(data, 'anticipatedClosingDate'),
        };
        this.props.onUpdateLocationInformation(buyerID, dataLocationInfo);
    }

    render = () => {
        const { user, configApp } = this.props;

        const buyerMatchDeclinedOptions = get(configApp.config, 'matchStatuses.realtor_buyer_match_declined');
        const realtorStatusOptions = get(configApp.config, 'matchStatuses.realtor_buyer_match_updated');
        const documentTypes = get(configApp.config, 'documentTypes.lemonbrew_realtor_buyer_match');
        const buyersList = get(user.profile, 'buyers');
        const buyerMatchRequests = get(user.profile, 'buyerMatchRequests');
        const downloadFilesList = get(user.profile, 'documents');
        const isUploading = get(user, 'isFetchingUploadFile');
        const isFetchedUploading = get(user, 'isFetchedUploadFile');
        const transactionContactTypes = this.buildTransactionContacts();
        const buyerDocuSignLink = get(user, 'docuSignLink');
        const brokerageName = get(user.profile, 'publicProfile.brokerage.name');
        const agentFirstName = get(user.profile, 'publicProfile.userInfo.firstName');
        const agentLastName = get(user.profile, 'publicProfile.userInfo.lastName');
        const agentName = `${trim(agentFirstName)} ${trim(agentLastName)}`;
        const agentPhone = get(user.profile, 'publicProfile.userInfo.phone') || '0280154722909';
        const sellerMatchDeclinedOptions = get(configApp.config, 'matchStatuses.realtor_seller_match_declined');
        const sellerMatchRequests = get(user.profile, 'sellerMatchRequests');
        const sellersList = get(user.profile, 'sellers');
        const realtorSellerStatusOptions = get(configApp.config, 'matchStatuses.realtor_seller_match_updated');
        const isUploadingSeller = get(user, 'profile.isUploadingSellerFile');
        const isSellerUploadSuccessfully = get(user, 'profile.isSellerFileUploadedSuccessfully');

        return (
            <RealtorProfileTabbed
                tab={this.props.tab}
                onChangeTab={this.props.onChangeTab}
                onUploadPicture={this.onUploadPicture}
                onConfirmMatch={this.onAcceptMatch}
                onConfirmDeclineMatch={this.onDeclineMatch}
                buyerMatchDeclinedOptions={buyerMatchDeclinedOptions}
                onSelectRejection={this.onSelectRejection}
                onConfirmChangeStatus={this.onChangeStatus}
                realtorStatusOptions={realtorStatusOptions}
                onSelectStatus={this.onSelectStatus}
                buyersList={buyersList}
                onUpdateLocationInformation={this.onUpdateLocationInformation}
                onUploadFile={this.onUploadFile}
                onUploadSelectTypeFile={this.onUploadSelectTypeFile}
                isUploading={isUploading}
                isFetchedUploading={isFetchedUploading}
                onRemoveFile={this.onRemoveFile}
                buyerMatchRequests={buyerMatchRequests}
                downloadFilesList={downloadFilesList}
                transactionContactTypes={transactionContactTypes}
                onSaveContactInfo={this.props.onSaveContactInfo}
                displayBuyerProfile={this.props.displayBuyerProfile}
                displaySellerProfile={this.props.displaySellerProfile}
                buyerDocuSignLink={buyerDocuSignLink}
                documentTypes={documentTypes}
                brokerageName={brokerageName}
                agentName={agentName}
                agentPhone={agentPhone}
                sellerMatchDeclinedOptions={sellerMatchDeclinedOptions}
                sellerMatchRequests={sellerMatchRequests}
                onConfirmSellerDeclineMatch={this.onDeclineSellerMatch}
                onSelectSellerRejection={this.onSelectSellerRejection}
                onConfirmSellerMatch={this.onConfirmSellerMatch}
                sellersList={sellersList}
                realtorSellerStatusOptions={realtorSellerStatusOptions}
                onSelectSellerStatus={this.onSelectSellerStatus}
                onConfirmSellerChangeStatus={this.onConfirmSellerChangeStatus}
                onUploadSellerFile={this.onUploadSellerFile}
                onUploadSellerSelectTypeFile={this.onUploadSellerSelectTypeFile}
                isUploadingSeller={isUploadingSeller}
                isSellerUploadSuccessfully={isSellerUploadSuccessfully}
                onRemoveSellerFile={this.onRemoveSellerFile}
                onSaveSellerContactInfo={this.props.onSaveSellerContactInfo}
            />
        );
    };
}

const RealtorProfile = connect(
    (state) => ({
        user: state.user,
        configApp: state.configApp,
    }),
    {
        onAcceptMatch: acceptMatch,
        onDeclineMatch: declineMatch,
        onChangeStatus: updateBuyerStatus,
        onUploadFile: uploadRealtorFileAttachment,
        onRemoveFile: removeRealtorFileAttachment,
        onSaveContactInfo: updateBuyerStatus,
        onUpdateLocationInformation: updateBuyerStatus,
        onDeclineSellerMatch: declineSellerMatch,
        onAcceptSellerMatch: acceptSellerMatch,
        onConfirmSellerChangeStatus: updateSellerStatus,
        onUploadSellerFile: uploadRealtorSellerFileAttachment,
        onRemoveSellerFile: removeRealtorSellerFileAttachment,
        onSaveSellerContactInfo: updateRealtorSellerStatus,
    },
)(RealtorProfileComponent);

export { ACTIVE_MATCHES_TAB, PENDING_MATCHES_TAB };
export default RealtorProfile;
