import React from "react";
import invoke from "lodash/invoke";
import "./RadioField.css";

const buildImage = option => {
    if (option.image) {
        return (
            <div className="radio__field__image">
                <img src={option.image} alt="" />
            </div>
        );
    }
};

const buildSelectedCheck = option => {
    // removing the upper right checkbox
    return;
    // if (!option || !option.image) return;
    // return (
    //     <div className="radio__field__selected-option-check">
    //         <i className="fa fa-check" />
    //     </div>
    // );
};

export default ({
    field,
    form,
    options = [],
    itemClassName = "",
    ...props
}) => (
    <div className={`radio__field ${props.className || ""}`}>
        {options.map(option => (
            <div
                key={option.value}
                className={`radio__field__item ${itemClassName} ${
                    field.value === option.value ? "is-active" : ""
                }`}
                onClick={() => {
                    form.setFieldValue(field.name, option.value);
                    form.setFieldTouched(field.name);
                    invoke(props, "onChange");
                }}
            >
                {field.value === option.value && buildSelectedCheck(option)}
                {buildImage(option)}
                <div className={`radio__field__label ${itemClassName}`}>
                    {option.label}
                </div>
            </div>
        ))}
    </div>
);
