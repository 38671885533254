import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import isFunction from "lodash/isFunction";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Button } from "../Button/Button";
import CloseModalButton from "../CloseModalButton/CloseModalButton";
import Recaptcha from "../Recaptcha/Recaptcha";
import {
    EMD_WIRE_INSTRUCTION_MODAL,
    REGEXP_VALIDATION_EMAIL
} from "../../shared/constants";
import mailbox from "../../styles/assets/Inbox-Icon.svg";

import "./emdChooseModal.css";

export default class EmdChooseModal extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        onSend: PropTypes.func,
        isSending: PropTypes.bool,
        isSended: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.resetState();
        }
    }

    resetState = () => this.setState(this.getInitialState());

    markRecaptchaFieldAsInvalid = () => {
        this.setState({
            recaptchaState: false
        });
    };

    markRecaptchaFieldAsValid = () => {
        this.setState({
            recaptchaState: true
        });
    };

    getInitialState = () => ({
        emailInfo: false,
        email: "",
        emailError: null,
        recaptchaState: false
    });

    onChangeEmail = e =>
        this.setState({ email: e.target.value, emailError: null });

    onCancel = () => this.setState({ emailInfo: !this.state.emailInfo });

    buildErrorMessage = error => (
        <div className="emd-choose-modal__password--error">
            <div className="emd-choose-modal__field-error--content">
                <div className="emd-choose-modal__field-error">{error}</div>
            </div>
        </div>
    );

    setErrorMessageFieldRequired = (field, value, message) => {
        const newValue = !value
            ? EMD_WIRE_INSTRUCTION_MODAL["required"](message)
            : null;
        this.setState({ [`${field}Error`]: newValue });
    };

    setErrorMessage = (field, type) => {
        const newValue = EMD_WIRE_INSTRUCTION_MODAL[type];
        this.setState({ [`${field}Error`]: newValue });
    };

    validateErrorMessage = field => {
        const errorMessage = get(this.state, `${field}Error`);
        return errorMessage ? this.buildErrorMessage(errorMessage) : null;
    };

    validateEmail = field => {
        const element = get(this.state, field);

        if (!isEmpty(element)) {
            if (!REGEXP_VALIDATION_EMAIL.test(element)) {
                this.setErrorMessage(field, "email");
                return;
            }
        }

        if (isEmpty(element)) {
            this.setErrorMessageFieldRequired(field, element, "Email");
            return;
        }

        this.setState({ [`${field}Error`]: null });
    };

    buildRecaptchaField = () => {
        return (
            <Recaptcha
                onSuccessfullyCompleted={this.markRecaptchaFieldAsValid}
                onError={this.markRecaptchaFieldAsInvalid}
            />
        );
    };

    buildEmailField = () => {
        const { email } = this.state;
        return (
            <div className="form-group emd-choose-modal__container emd-choose-modal__container-email">
                <div className="recaptcha-plh">
                    {this.buildRecaptchaField()}
                </div>
                <label
                    className="emd-choose-modal__form-label--email"
                    htmlFor="addressLabel"
                >
                    Your email
                    <input
                        id="email"
                        type="email"
                        className="form-control emd-choose-modal__form-field"
                        value={email}
                        onChange={this.onChangeEmail}
                        onBlur={() => this.validateEmail("email")}
                    />
                </label>
                {this.validateErrorMessage("email")}
            </div>
        );
    };

    haveEmptyFields = () => {
        const { email } = this.state;

        return !email;
    };

    haveErrors = () => {
        const { emailError, recaptchaState } = this.state;

        return emailError || recaptchaState !== true;
    };

    isDisableSendButton = () => {
        const { isSending, isSended } = this.props;

        return (
            this.haveEmptyFields() || this.haveErrors() || isSending || isSended
        );
    };

    onSend = () => {
        const { onSend, transactionContactType } = this.props;
        const { email } = this.state;

        if (isFunction(onSend)) {
            this.setState({ isSendInit: true });
            const data = {
                contact: {
                    email
                },
                transactionContactType
            };

            onSend(data);
        }
    };

    buildModalMailInfo = () => (
        <div className="emd-choose-modal__mail-information">
            Email sent, thank you
            <br />
            <br />
            <img src={mailbox} alt="mailbox" />
        </div>
    );

    buildCertifiedContent = () => {
        if (this.state.emailInfo) return this.buildEmailField();

        return (
            <div className="emd-choose-modal__certified-modal-content">
                <div className="emd-choose-modal__certified-modal-content-title">
                    Please make certified check payable to:
                </div>
                <div className="emd-choose-modal__certified-modal-content-text">
                    <strong>LemonBrew Abstract LLC</strong>
                    <br />
                    517 Rte 1 South Suite # 4002
                    <br />
                    Iselin, NJ 08830
                </div>
            </div>
        );
    };

    render = () => {
        const { isSended, onClose, isVisible } = this.props;

        const buildContent = isSended
            ? this.buildModalMailInfo()
            : this.buildCertifiedContent();
        const cancelButtonText = this.state.emailInfo
            ? "Cancel"
            : "Email me info";
        const sendButtonText = this.state.emailInfo ? "Send" : "Continue";

        return (
            <Modal
                animation
                autoFocus
                backdrop
                enforceFocus
                show={isVisible}
                className="emd-choose-modal"
            >
                <ModalHeader>
                    <CloseModalButton onClick={onClose} />
                </ModalHeader>
                <ModalBody className="emd-choose-modal__body">
                    {buildContent}
                    {isSended ? null : (
                        <div className="emd-choose-modal__container-buttons">
                            <Button inverted onClick={this.onCancel}>
                                {cancelButtonText}
                            </Button>
                            {this.state.emailInfo ? (
                                <Button
                                    onClick={this.onSend}
                                    disabled={this.isDisableSendButton()}
                                    isLoading={this.props.isSending}
                                >
                                    {sendButtonText}
                                </Button>
                            ) : (
                                <a
                                    className="emd-choose-modal__cancel-button"
                                    href="https://www.lemonbrewabstract.com"
                                >
                                    <Button>{sendButtonText}</Button>
                                </a>
                            )}
                        </div>
                    )}
                </ModalBody>
            </Modal>
        );
    };
}
