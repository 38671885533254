import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { MoreInfoCloseIcon, MoreInfoButton } from './MoreInfoButton';
import { Button } from '../../Button/Button';
import ContentModal from '../../ContentModal/ContentModal';
import BrokerInfo from './BrokerInfo';

import './adminRoleTables.css';

export default class LicenseTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisibleViewBroker: false,
            isVisibleAddLicenseModal: false,
            brokerInfo: [],
        };
    }

    buildHeaderList = () => (
        <div className="row admin-role-tables__header">
            <div className="">STATE</div>
            <div className="">NAME</div>
            <div className="">LICENSE TYPE</div>
            <div className="">BROKER</div>
            <div className="col-xs-1"></div>
        </div>
    )

    buildShowMoreInfoLink = (license) => {
        const id = get(license, 'id');
        const showMoreInfo = get(license, 'showMoreInfo', false);
        return (
            <div className="admin-role-tables__more-info">
                <MoreInfoButton id={id} showMoreInfo={showMoreInfo} onClickMoreInfo={this.props.onClickMoreInfo} />
            </div>
        );
    }

    getName = (license) => (
        <>
            <div className="admin-role-tables__license-name">
                {get(license, 'name')}
            </div>
            {this.buildShowMoreInfoLink(license)}
        </>
    );

    getStateName = (license) => get(license, 'state.name');

    getBoardCommission = (license) =>  (
        <div className="admin-role-tables__license-commission">
            {get(license, 'boardCommission')}
        </div>
    );

    getType = (license) => get(license, 'type');

    getDesignation = (license) => (
        <div className="admin-role-tables__license-designation">
            {get(license, 'designation')}
        </div>
    );

    getNumber = (license) => get(license, 'number');

    buildStateAndNumber = (license) => (
        <>
            <div className="admin-role-tables__license-state-name">
                {this.getStateName(license)}
            </div>
            <div className="admin-role-tables__license-number">
                #{this.getNumber(license)}
            </div>
        </>
    )

    buildLicenseType = (license) => (
        <div className="admin-role-tables__license-type">
            {this.getType(license)}
        </div>
    );

    onOpenViewBroker = (brokerInfo) => (this.setState({
        brokerInfo: brokerInfo,
        isVisibleViewBroker: true,
    }));

    onCloseViewBroker = () => (this.setState({ isVisibleViewBroker: false }));

    buildBrokerInfoButton = (license) => (
        <>
            <div className="admin-role-tables__license-broker-name">
                {get(license, 'broker.firstName')} {get(license, 'broker.lastName')}
            </div>
            <Button
                className="btn button admin-role-tables__button-more-info button--text"
                onClick={() => this.onOpenViewBroker(get(license, 'broker'))}
                shadow={false}
                small
            >
                View broker details
            </Button>
        </>
    );

    buildFirstRow = (license) => (
        <div className="admin-role-tables__item-row license">
            <div className="">
                <div className="admin-role-tables__license-title">NAME</div>
                {this.getName(license)}
            </div>
            <div className="">
                <div className="admin-role-tables__license-title">STATE</div>
                {this.buildStateAndNumber(license)}
            </div>
            <div className="">
                <div className="admin-role-tables__license-title">LICENSE TYPE</div>
                {this.buildLicenseType(license)}
            </div>
            <div className="">
                <div className="admin-role-tables__license-title">BROKER</div>
                {this.buildBrokerInfoButton(license)}
            </div>
        </div>
    );

    buildCommission = (license) => (
        <div className={'admin-role-tables__license-second-row'}>
            <div className="admin-role-tables__license-second-row-text">
                BOARD / COMISSION
            </div>
            {this.getBoardCommission(license)}
        </div>
    )

    buildDesignation = (license) => (
        <div className={'admin-role-tables__license-second-row'}>
            <div className="admin-role-tables__license-second-row-text">
                DESIGNATION
            </div>
            {this.getDesignation(license)}
        </div>
    )

    buildSecondRow = (license) => {
        const moreInfo = get(license, 'showMoreInfo', false);

        if(!moreInfo) return;

        return (
            <div className="admin-role-tables__item-row  license-secondary">
                <div className="">
                    {this.buildCommission(license)}
                </div>
                <div className="">
                    {this.buildDesignation(license)}
                </div>
                <div className=""></div>
            </div>
        );
    }

    buildBrokerInfo = () => (
        <BrokerInfo broker={this.state.brokerInfo} />
    );

    buildContentList = () => (
        <div className="admin-role-tables__body">
            <ContentModal
                isVisible={this.state.isVisibleViewBroker}
                onClose={this.onCloseViewBroker}
                content={this.buildBrokerInfo()}
                className="admin-role-tables"
                small
            />
            {map(this.props.licenses, (license, index) => {
                const id = get(license, 'id');
                const showMoreInfo = get(license, 'showMoreInfo', false);
                return (
                    <div className="admin-role-tables__item" key={index}>
                        <MoreInfoCloseIcon id={id} showMoreInfo={showMoreInfo} onClickMoreInfo={this.props.onClickMoreInfo} />
                        <div className="admin-role-tables__license">
                            {this.buildFirstRow(license)}
                            {this.buildSecondRow(license)}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    render = () => (
        <div>        
            {this.buildContentList()}
        </div>
    );
}

LicenseTable.propTypes = {
    dateFormat: PropTypes.string,
    licenses: PropTypes.any,
    onClickMoreInfo: PropTypes.func,
};