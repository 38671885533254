import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button } from '../Button/Button';

import './Landing.css';

import iconHeart from '../../styles/assets/icon-heart.svg';
import iconHouse from '../../styles/assets/icon-house.svg';
import iconLock from '../../styles/assets/icon-lock.svg';
import VimeoVideo from 'react-vimeo';

const Landing = () => (
<div className="landing__container">
        <div className="landing__hero__container">
            <div className="landing__hero">
                <div className="landing__hero__content">

                    <div className="landing__hero__title">
                        Gain access to the <br/> industry's best leads.
                    </div>

                    <div className="landing__hero__text">
                        Our industry leading matching algorithm gives you,
                        the Real Estate Agent, exclusive and qualified leads. We use artificial intelligence and machine learning
                        algorithms to analyze thousands of data points to find the best consumer
                        match for you. Because of this, we've found that our leads convert at a much
                        higher rate than the competition.
                    </div>

                    <div>
                        <Link to="/signup/realtor">
                            <Button>SIGN UP FOR FREE</Button>
                        </Link>
                        <br/><br/>
                        Already have an account? <Link to="/signin">Log In</Link>
                    </div>
                </div>
            </div>
        </div>


        <div className="landing__cta__container for-video">
            <div className="landing__cta__container-column for-title">
                LemonBrew Is The
                <br />
                Partnership You Have
                <br />
                Been Looking For!
            </div>

            <div className="landing__cta__container-column">
                <VimeoVideo
                    className="video"
                    videoId="342095634"
                />
            </div>
        </div>

        <div className="landing__values__container">
            <div className="landing__values__item">
                <div className="landing__values__image">
                    <img src={iconHeart} alt=""/>

                </div>
                <p><strong>No Up-Front Costs</strong></p>
                <p className="is-justified">
                    There are no sign up fees or annual membership fees. No cost.
                    No obligation. No risk. All money!
                </p>
            </div>

            <div className="landing__values__item">
                <div className="landing__values__image">
                    <img src={iconHouse} alt=""/>

                </div>
                <p><strong>Only Pay at Close</strong></p>
                <p className="is-justified">
                    Only pay referral fee on closed deals.
                    And you'll be closing a lot of deals!
                </p>
            </div>

            <div className="landing__values__item">
                <div className="landing__values__image">
                    <img src={iconLock} alt=""/>
                </div>
                <p><strong>Stay with Your Brokerage</strong></p>
                <p className="is-justified">
                    You will receive high-quality, vetted referrals without
                    leaving your current brokerage. Why leave a happy home?
                </p>
            </div>
        </div>

        <div className="landing__cta__container">
            <div className="lading__cta">
                <div className="landing__cta__title">
                    We've been expecting you.
                </div>
                <p>
                    Become a LemonBrew Partner Agent
                </p>
                <div className="landing__cta__control">
                    <Link to="/signup/realtor">
                        <Button>SIGN UP FOR FREE</Button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
);

export default withRouter(Landing);
