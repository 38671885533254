import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import get from "lodash/get";
import map from "lodash/map";
import pick from "lodash/pick";
import trim from "lodash/trim";
import first from "lodash/first";
import filter from "lodash/filter";
import reject from "lodash/reject";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import isFunction from "lodash/isFunction";
import set from "lodash/set";
import Button from "../Button/Button";
import { chooseAgent } from "../../util/service_api";
import AgentMatchCardModal from "../AgentMatchCardModal/AgentMatchCardModal";
import RealtorProfileSideColumn from "../RealtorProfileSideColumn/RealtorProfileSideColum";
import RealtorProfileContent from "../RealtorProfileContent/RealtorProfileContent";
import { updateRealtorProfile } from "../../util/service_api";
import { getAddressFromPlaceDetails } from "../../util/util";
import { geocodeByPlaceId } from "react-places-autocomplete";
import {
    MIN_VALUE_AVG_SALE_PRICE,
    MAX_VALUE_AVG_SALE_PRICE,
    MIN_VALUE_AVG_BEDROOMS,
    MAX_VALUE_AVG_BEDROOMS,
    MIN_VALUE_PROPERTIES_SOLD,
    MAX_VALUE_PROPERTIES_SOLD
} from "../../shared/constants";

import "../../styles/render-components/userprofile.css";

class UserRealtorProfile extends Component {
    static propTypes = {
        canEdit: PropTypes.bool,
        className: PropTypes.string,
        profile: PropTypes.any,
        onUpdateProfile: PropTypes.func,
        isPublicView: PropTypes.bool,
        showAcceptButton: PropTypes.bool,
        onChooseAgent: PropTypes.func,
        showContactButton: PropTypes.bool,
        isImpersonatingUser: PropTypes.bool,
        canEditAgentName: PropTypes.bool
    };

    static defaultProps = {
        className: "container user-profile__container",
        showAcceptButton: false,
        showContactButton: true
    };

    constructor(props) {
        super(props);
        this.state = {
            isAcceptMatchModalVisible: false,
            isEditing: false,
            newProfile: cloneDeep(this.props.profile),
            prevProfile: cloneDeep(this.props.profile),
            displayErrors: false
        };
    }

    onEdit = () => {
        this.setState({ isEditing: true });
    };

    onCancelEdit = () => {
        this.props.onUpdateProfile(this.state.prevProfile);
        this.setState({
            isEditing: false,
            displayErrors: false,
            newProfile: this.state.prevProfile
        });
    };

    labeledOptionsToValues = labeledOptions =>
        map(labeledOptions, this.labeledOptionToValue);

    labeledOptionToValue = labeledOption =>
        get(labeledOption, "value") || labeledOption;

    processServingInValues = newServingInValues => {
        const fieldNewValue = this.labeledOptionsToValues(newServingInValues);
        const alreadyValidLocations = reject(fieldNewValue, "place_id");

        return this.convertNewLabeledOptionsToValidValues(
            newServingInValues
        ).then(newLabeledOptionsAsValidValues => [
            ...alreadyValidLocations,
            ...newLabeledOptionsAsValidValues
        ]);
    };

    convertNewLabeledOptionsToValidValues = newLabeledOptions => {
        const values = this.labeledOptionsToValues(newLabeledOptions);
        const newValues = filter(values, "place_id");
        const placeIds = map(newValues, "place_id");
        const geocodePlaces = map(placeIds, geocodeByPlaceId);

        return Promise.all(geocodePlaces).then(locations =>
            map(locations, location => this.geocodeToValue(first(location)))
        );
    };

    geocodeToValue = geocode => {
        const info = getAddressFromPlaceDetails(geocode);

        return {
            streetNumberName: trim(`${info.streetName} ${info.streetNumber}`),
            neighborhood: info.neighborhood,
            apartmentSuiteNumber: "",
            city: info.city,
            state: info.state,
            zipcode: info.zipcode,
            longitude: info.lng,
            latitude: info.lat
        };
    };

    validateForm = newFields =>
        Yup.object({
            numberOfPropertiesSold: Yup.number()
                .min(MIN_VALUE_PROPERTIES_SOLD)
                .max(MAX_VALUE_PROPERTIES_SOLD),
            averageSalePrice: Yup.number()
                .min(MIN_VALUE_AVG_SALE_PRICE)
                .max(MAX_VALUE_AVG_SALE_PRICE),
            averageNumberOfBedrooms: Yup.number()
                .min(MIN_VALUE_AVG_BEDROOMS)
                .max(MAX_VALUE_AVG_BEDROOMS)
        }).validate(newFields);

    updateIfFormIsValid = () => {
        const newPublicProfile = get(this.state.newProfile, "publicProfile");
        const newFields = pick(newPublicProfile, [
            /* Fields in left column */
            "numberOfPropertiesSold",
            "averageSalePrice",
            "averageNumberOfBedrooms",

            /* Fields in Content */
            "saleAddresses",
            "yearsOfExperience",
            "linkedInUrl",
            "igUrl",
            "facebookUrl",
            "youtubeUrl",
            "languages",
            "designations",
            "professionalCertifications",
            "specializations",
            "interests",
            "cuisines",
            "aboutMe"
        ]);

        const newAverageSalePrice =
            get(newFields, "averageSalePrice.amount") ||
            get(newFields, "averageSalePrice");
        set(newFields, "averageSalePrice", newAverageSalePrice);

        this.validateForm(newFields)
            .then(() => this.onConfirmEdit(newFields))
            .catch(() => {
                this.setState({ displayErrors: true });
            });
    };

    onConfirmEdit = newFields =>
        this.processServingInValues(newFields.saleAddresses)
            .then(saleAddresses => ({ ...newFields, saleAddresses }))
            .then(newFieldsWithProcessedSaleAddresses => {
                return updateRealtorProfile(
                    newFieldsWithProcessedSaleAddresses
                );
            })
            .then(this.onSuccessfullyEdit)
            .catch(this.onFailureEdit);

    onSuccessfullyEdit = () => {
        this.setState({
            isEditing: false,
            displayErrors: false
        });
    };

    onFailureEdit = () => {};

    buildEditButton = () => (
        <Button
            inverted
            onClick={this.onEdit}
            className="user-profile__edit-profile-button"
        >
            Edit Profile
        </Button>
    );

    buildCancelButton = () => (
        <Button inverted onClick={this.onCancelEdit}>
            Cancel
        </Button>
    );

    buildSaveButton = () => (
        <Button onClick={this.updateIfFormIsValid}>Save</Button>
    );

    buildCancelAndSaveButtonRow = () => (
        <Fragment>
            <div className="user-profile-details__content-buttons">
                {this.buildCancelButton()}
                {this.buildSaveButton()}
            </div>
        </Fragment>
    );

    onUpdateField = (field, newValue) => {
        const newProfile = cloneDeep(this.state.newProfile);
        set(newProfile, field, newValue);

        this.setState({ newProfile });

        if (isFunction(this.props.onUpdateProfile)) {
            this.props.onUpdateProfile(newProfile);
        }
    };

    openConfirmationModal = () => {
        this.setState({ isAcceptMatchModalVisible: true });
    };

    closeConfirmationModal = () => {
        this.setState({ isAcceptMatchModalVisible: false });
    };

    onUploadPicture = picture => {
        const pictureLabel = "publicProfile.userInfo.profilePicUrl";
        this.onUpdateField(pictureLabel, picture);
    };

    buildSideColumn = () => {
        const {
            isPublicView,
            profile,
            showAcceptButton,
            showContactButton,
            isImpersonatingUser
        } = this.props;
        return (
            <div>
                <RealtorProfileSideColumn
                    isEditing={this.state.isEditing}
                    isPublicView={isPublicView}
                    profile={profile}
                    onUpdateField={this.onUpdateField}
                    onUploadPicture={this.onUploadPicture}
                    showAcceptButton={showAcceptButton}
                    showContactButton={showContactButton}
                    openConfirmationModal={this.openConfirmationModal}
                    isImpersonatingUser={isImpersonatingUser}
                    displayErrors={this.state.displayErrors}
                />
            </div>
        );
    };

    buildContent = () => {
        const { isPublicView, canEditAgentName, profile } = this.props;
        return (
            <RealtorProfileContent
                isEditing={this.state.isEditing}
                isPublicView={isPublicView}
                profile={profile}
                onUpdateField={this.onUpdateField}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                canEditAgentName={canEditAgentName}
            />
        );
    };

    chooseAgent = () => {
        const agentId = get(this.props.profile, "publicProfile.userInfo.id");
        chooseAgent(agentId).then(() => {
            if (isFunction(this.props.onChooseAgent)) {
                this.props.onChooseAgent(agentId);
            }
        });
    };

    render = () => {
        const { canEdit } = this.props;
        const { isEditing } = this.state;
        const isProfileNotFetched = isEmpty(this.props.profile);

        if (isProfileNotFetched) {
            return <div />;
        }
        const firstName = get(
            this.props.profile,
            "publicProfile.userInfo.firstName"
        );

        return (
            <div className={this.props.className}>
                {canEdit && !isEditing ? this.buildEditButton() : null}
                <div className="user-profile-details">
                    <div className="user-profile-details__content-button"></div>
                    <div className="user-profile-details__sidebar">
                        <AgentMatchCardModal
                            isVisible={this.state.isAcceptMatchModalVisible}
                            closeConfirmationModal={this.closeConfirmationModal}
                            firstName={firstName}
                            onChooseAgent={this.chooseAgent}
                        />
                        {this.buildSideColumn()}
                    </div>
                    <div className="user-profile-details__content">
                        {this.buildContent()}
                        {isEditing ? this.buildCancelAndSaveButtonRow() : null}
                    </div>
                </div>
            </div>
        );
    };
}

export default UserRealtorProfile;
