import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Modal, ModalBody } from 'react-bootstrap';
import CloseModalButton from '../CloseModalButton/CloseModalButton';
import { Button } from '../Button/Button';
import { hideNewActiveMatchesModal } from '../../reducers/user/UserActions';
import { hideNewActiveSellerMatchModal } from '../../reducers/user/realtorSellerMatch/RealtorSellerMatchActions';

import './realtor-new-active-match-modal.css';

class RealtorNewActiveMatchModalComponent extends React.PureComponent {
    render = () => (
        <Modal
            animations
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            className="realtor-new-active-match-modal"
        >
            <ModalBody className="text-center">
                <div className="text-right">
                    <CloseModalButton onClick={this.props.onClose} />
                </div>
                <h1>New Accepted Match!</h1>
                <p className="realtor-new-active-match-modal__content">
                    Thanks for waiting, your new active match is ready.
                </p>
                <footer className="realtor-new-active-match-modal__footer">
                    <Link to="/realtor/active-matches">
                        <Button onClick={this.props.onClose}>Go to my active matches</Button>
                    </Link>
                </footer>
            </ModalBody>
        </Modal>
    );
}

const RealtorNewActiveMatchModal = connect(
    (state) => ({ isVisible: get(state, 'user.displayNewActiveMatchModal') || get(state, 'user.profile.isNewActiveSellerMatchModalVisible') }),
    {
        onClose: () => (dispatch) => {
            dispatch(hideNewActiveMatchesModal());
            dispatch(hideNewActiveSellerMatchModal());
        }
    }
)(RealtorNewActiveMatchModalComponent);

export default RealtorNewActiveMatchModal;
