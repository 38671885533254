import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import RealtorProfile, { ACTIVE_MATCHES_TAB } from '../RealtorProfile/RealtorProfile';

export default class RealtorActiveMatchesTabRoute extends React.PureComponent {
    static propTypes = {
        hasActiveMatches: PropTypes.bool,
        onChangeTab: PropTypes.func,
        displaySellerProfile: PropTypes.number,
        displayBuyerProfile: PropTypes.number,
    };

    render = () => {
        const { hasActiveMatches, displaySellerProfile, displayBuyerProfile } = this.props;

        if (hasActiveMatches) {
            return (
                <RealtorProfile
                    tab={ACTIVE_MATCHES_TAB}
                    onChangeTab={this.props.onChangeTab}
                    displaySellerProfile={displaySellerProfile}
                    displayBuyerProfile={displayBuyerProfile}
                />
            );
        }

        return <Redirect to="/realtor/pending-matches" />;
    };
}
