import React from 'react';
import PropTypes from 'prop-types';

import './earnestmoneydepositstatuspill.css';

export const DRAFT = 'draft';
export const IN_PROGRESS = 'in_progress';
export const COMPLETE = 'complete';
export const NEEDS_VALIDATION = 'verification_document_needed';
export const DISCARDED = 'discarded';
export const CANCEL = 'cancel';
export const FAIL = 'fail';

export default class EarnestMoneyDepositStatusPill extends React.PureComponent {
    static propTypes = {
        status: PropTypes.oneOf([
            DRAFT,
            IN_PROGRESS,
            COMPLETE,
            NEEDS_VALIDATION,
            DISCARDED,
            CANCEL,
            FAIL,
        ]),
    };

    getStatusStyle = () => {
        switch (this.props.status) {
        case DRAFT:
            return 'earnest-money-deposit-status-pill__draft';
        case IN_PROGRESS:
            return 'earnest-money-deposit-status-pill__in-progress';
        case COMPLETE:
            return 'earnest-money-deposit-status-pill__complete';
        case NEEDS_VALIDATION:
            return 'earnest-money-deposit-status-pill__needs-validation';
        case DISCARDED:
            return 'earnest-money-deposit-status-pill__discarded';
        case CANCEL:
            return 'earnest-money-deposit-status-pill__cancel';
        case FAIL:
            return 'earnest-money-deposit-status-pill__fail';
        default:
            return '';
        }
    }

    getStatusLabel = () => {
        switch (this.props.status) {
        case DRAFT:
            return 'DRAFT';
        case IN_PROGRESS:
            return 'IN PROGRESS';
        case COMPLETE:
            return 'COMPLETED';
        case NEEDS_VALIDATION:
            return 'ID VERIFICATION REQUIRED';
        case DISCARDED:
            return 'DISCARDED';
        case CANCEL:
            return 'CANCELLED';
        case FAIL:
            return 'FAILED';
        default:
            return '';
        }
    }

    render = () => (
        <div className={`earnest-money-deposit-status-pill ${this.getStatusStyle()}`}>
            {this.getStatusLabel()}
        </div>
    );
}
