import React from 'react';
import { Link } from 'react-router-dom';
import { Field, ErrorMessage } from 'formik';
import { PhoneField } from '../../../Form';
import PasswordStrengthInfoTooltipLinkStyle from '../../../PasswordStrengthInfoTooltipLinkStyle';

const MAX_LENGTH_FIRST_NAME = '50';
const MAX_LENGTH_LAST_NAME = '50';

export default class AboutYourself extends React.Component {
    buildCreateYourAccount = () => (
        <div className="field">
            <h2 className="realtor__signupform__header-create-account">
                Hey Partner, Tell Us About Yourself
            </h2>
            <span className="realtor__signupform__have-account">
                Already have an account?
                {' '}
                <Link to="/signin">Log In</Link>
            </span>
        </div>
    );

    buildFieldError = (field) => (
        <ErrorMessage name={field}>
            {(msg) => (
                <div className="field-error--content">
                    <div className="field-error">
                        {msg}
                    </div>
                </div>
            )}
        </ErrorMessage>
    );

    buildFirstNameField = () => {
        const fieldName = 'aboutYourself.firstName';
        return (
            <div className="name__box">
                <div className="name">
                    <Field
                        type="text"
                        name={fieldName}
                        placeholder="FIRST NAME"
                        maxLength={MAX_LENGTH_FIRST_NAME}
                    />
                    {this.buildFieldError(fieldName)}
                </div>
            </div>
        );
    };

    buildLastNameField = () => {
        const fieldName = 'aboutYourself.lastName';
        return (
            <div className="name__box">
                <Field
                    type="text"
                    name={fieldName}
                    placeholder="LAST NAME"
                    className="name"
                    maxLength={MAX_LENGTH_LAST_NAME}
                />
                {this.buildFieldError(fieldName)}
            </div>
        );
    };

    buildEmailField = () => {
        const fieldName = 'aboutYourself.email';
        return (
            <div className="name__box field">
                <Field
                    name={fieldName}
                    component="input"
                    type="email"
                    placeholder="EMAIL"
                />
                {this.buildFieldError(fieldName)}
            </div>
        );
    };

    buildPhoneField = () => {
        const fieldName = 'aboutYourself.realtorPhone';
        return (
            <div className="name__box field">
                <Field
                    type="text"
                    name={fieldName}
                    placeholder="PHONE NUMBER"
                    component={PhoneField}
                />
                { this.buildFieldError(fieldName) }
            </div>
        );
    };

    buildPasswordField = () => (
        <>
            <div className="password__container field">
                <div className="password__box">
                    <Field
                        type="password"
                        name="aboutYourself.password"
                        placeholder="PASSWORD"
                    />
                    <div className="password__error">
                        {this.buildFieldError('aboutYourself.password')}
                    </div>
                </div>
                <div className="password__box">
                    <Field
                        type="password"
                        name="aboutYourself.password2"
                        placeholder="CONFIRM PASSWORD"
                    />
                    <div className="password__error">
                        {this.buildFieldError('aboutYourself.password2')}
                    </div>
                </div>
            </div>
            <div className="password__requeriments">
                <PasswordStrengthInfoTooltipLinkStyle />
            </div>
        </>
    );

    buildTosLink = () => (
        <a
            className="signup__realtor--links"
            target="_blank"
            rel="noopener noreferrer"
            href="/terms-of-use-realtor"
        >
            Terms of Service
        </a>
    );

    buildNoticeUserLink = () => (
        <a
            className="signup__realtor--links"
            target="_blank"
            rel="noopener noreferrer"
            href="https://s3.amazonaws.com/lb-public-documents/LemonBrew-FCRA-Notice-to-Users.pdf"
        >
            Notice to Users
        </a>
    );

    buildPrivacyPolicyLink = () => (
        <a
            className="signup__realtor--links"
            target="_blank"
            rel="noopener noreferrer"
            href="/privacy-policy-realtor"
        >
            Privacy Policy
        </a>
    );

    buildTosField = () => (
        <section>
            <div className="text-center">
                {'By clicking the "Continue" button, you agree to our '}
                {this.buildTosLink()}
                {' and '}
                {this.buildPrivacyPolicyLink()}
                {' and '}
                {this.buildNoticeUserLink()}
                {' of Consumer Reports.'}
            </div>
        </section>
    );

    render = () => (
        <div className="realtor__signupform__step-container">
            {this.buildCreateYourAccount()}
            <section className="section personal">
                <div className="name__container">
                    {this.buildFirstNameField()}
                    {this.buildLastNameField()}
                </div>
                <div className="field note">
                    <p>*Please enter your name as per your real estate license</p>
                </div>
                <div className="name__container">
                    {this.buildEmailField()}
                    {this.buildPhoneField()}
                </div>
                {this.buildPasswordField()}
                {this.buildTosField()}
            </section>
        </div>
    );
}
