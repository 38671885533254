import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import toInteger from 'lodash/toInteger';
import {
    ControlLabel, FormControl, FormGroup, Checkbox,
} from 'react-bootstrap';
import { Calendar } from 'react-calendar';
import format from 'date-fns/format';
import addDays from 'date-fns/add_days';
import compareAsc from 'date-fns/compare_asc';
import MaskedInputField from '../MaskedInput/MaskedInputField';
import LocationAutoCompleteInput from '../LocationAutoCompleteInput/LocationAutoCompleteInput';
import { getLocationInformationService } from '../../util/service_api';
import { getAddressFromPlaceDetails, getUTCDate } from '../../util/util';
import {
    MIN_FINAL_PURCHASE_PRICE,
    MAX_FINAL_PURCHASE_PRICE,
} from '../../shared/constants';

import './realtormatchprofilesubjectpropertyinformation.css';

export default class RealtorMatchProfileSubjectPropertyInformationEdit extends Component {
    constructor(props) {
        super(props);
        const {
            commission,
            finalPurchasePrice,
            newConstruction,
            anticipatedClosingDate,
            country,
            propertyAddress,
        } = this.props;
        const streetNumberName = get(propertyAddress, 'streetNumberName');
        const state = get(propertyAddress, 'state');
        const city = get(propertyAddress, 'city');
        const apartmentSuiteNumber = get(propertyAddress, 'apartmentSuiteNumber') || undefined;
        const lat = get(propertyAddress, 'latitude');
        const lng = get(propertyAddress, 'longitude');
        const zipCode = get(propertyAddress, 'zipcode');
        const descriptionServingIn = streetNumberName ? `${streetNumberName}, ${city}, ${state}, ${country}` : '';
        this.state = {
            servingIn: {
                value: {
                    description: descriptionServingIn,
                },
                label: descriptionServingIn,
            },
            streetNumberName,
            lat,
            lng,
            zipCode,
            state,
            city,
            apartmentSuiteNumber,
            commission,
            finalPurchasePrice,
            newConstruction,
            anticipatedClosingDate: this.anticipatedClosingDateInitialDate(anticipatedClosingDate),
            isVisibleCalendar: false,
            hasErrorCommisionStructureField: false,
            hasErrorAddressField: false,
            hasErrorFinalPurchase: false,
            hasErrorFinalPurchaseFieldRange: false,
            isEmptyAddressField: isEmpty(descriptionServingIn),
            isEmptyFinalPurchase: isNil(this.props.finalPurchasePrice),
            isEmptyCommissionStructureField: isNil(this.props.commission),
        };
    }

    anticipatedClosingDateInitialDate = (anticipatedClosingDate) => {
        let closingDate = anticipatedClosingDate;

        if (!closingDate) { closingDate = new Date(); }

        // Business Logic: The closing date must be greater than the actual date.
        closingDate = compareAsc(new Date(), closingDate) > 0 ? new Date() : closingDate;

        return getUTCDate(closingDate);
    }

    getMoreLocationInformationService = (placeId) => {
        const params = {
            placeId,
        };
        getLocationInformationService().getDetails(params, (predictions, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                return status;
            }
            const {
                address: streetNumberName, state, city, zipcode: zipCode, lat, lng
            } = getAddressFromPlaceDetails(predictions);
            this.setState({
                streetNumberName,
                lat,
                lng,
                zipCode,
                state,
                city,
            });
            return status;
        });
    };

    validateAddress = (addressSelected) => {
        const addressType = get(addressSelected, 'value.types');
        return includes(addressType, 'street_address');
    };

    onChangeAddressField = (fieldNewValue) => {
        const hasErrorAddressField = false;
        this.setState({ servingIn: fieldNewValue });
        if (!this.validateAddress(fieldNewValue)) {
            this.setState({
                hasErrorAddressField: !hasErrorAddressField,
                isEmptyAddressField: isEmpty(fieldNewValue),
            });
            return;
        }
        const placeId = trim(get(fieldNewValue, 'value.place_id'));
        this.getMoreLocationInformationService(placeId);
        this.setState({
            hasErrorAddressField,
            isEmptyAddressField: isEmpty(fieldNewValue),
        });
    };

    onChangeApartmentSuiteNumberField = (event) => {
        this.setState({ apartmentSuiteNumber: event.target.value });
    };

    onChangeFinalPurchasePrice = (value) => {
        const valueToNumber = toInteger(value);
        const hasErrorFinalPurchaseFieldRange = (valueToNumber < MIN_FINAL_PURCHASE_PRICE
            || valueToNumber > MAX_FINAL_PURCHASE_PRICE);
        this.setState({
            finalPurchasePrice: value,
            hasErrorFinalPurchaseFieldRange,
            hasErrorFinalPurchase: !value,
            isEmptyFinalPurchase: isNil(value),
        });
    };

    onChangeComission = (event) => {
        const { value, maxLength } = event.target;
        const valueFormatted = value.slice(0, maxLength);
        const hasErrorCommisionStructureFieldRange = (valueFormatted < 0 || valueFormatted > 100);
        const hasErrorCommisionStructureField = !valueFormatted;
        this.setState({
            commission: valueFormatted,
            hasErrorCommisionStructureFieldRange,
            hasErrorCommisionStructureField,
            isEmptyCommissionStructureField: isNil(valueFormatted),
        });
    };

    onChangeNewConstructionChk = (event) => {
        this.setState({ newConstruction: event.target.checked });
    };

    buildBuyerAnticipatedClosingDate = (date) => format(date, this.props.dateFormat);

    onChangeCalendar = (event) => {
        const { isVisibleCalendar } = this.state;
        this.setState({
            anticipatedClosingDate: event,
            isVisibleCalendar: !isVisibleCalendar,
        });
    }

    formIsValid = () => {
        const {
            isEmptyAddressField,
            isEmptyCommissionStructureField,
            isEmptyFinalPurchase,
            hasErrorAddressField,
            hasErrorCommisionStructureFieldRange,
            hasErrorFinalPurchase,
            hasErrorFinalPurchaseFieldRange,
            hasErrorCommisionStructureField,
        } = this.state;
        return hasErrorAddressField
            || hasErrorCommisionStructureFieldRange
            || hasErrorFinalPurchase
            || hasErrorFinalPurchaseFieldRange
            || hasErrorCommisionStructureField
            || isEmptyAddressField
            || isEmptyCommissionStructureField
            || isEmptyFinalPurchase;
    };

    buildConfirmEditButton = () => (
        <button
            type="button"
            className="btn subject-property-information__save-button"
            onClick={this.onConfirmEdit}
            disabled={this.formIsValid()}
        >
            SAVE
        </button>
    );

    setCalendarVisibility = () => {
        const { isVisibleCalendar } = this.state;
        this.setState({ isVisibleCalendar: !isVisibleCalendar });
    }

    onConfirmEdit = () => {
        const {
            apartmentSuiteNumber,
            finalPurchasePrice,
            newConstruction,
            zipCode,
            lat,
            lng,
            city,
            state,
            streetNumberName,
            anticipatedClosingDate,
            commission,
        } = this.state;
        const fomattedDate = format(anticipatedClosingDate, this.props.dateFormat);
        const data = {
            propertyAddress: {
                streetNumberName,
                apartmentSuiteNumber,
                city,
                state,
                latitude: lat,
                longitude: lng,
                zipcode: zipCode,
            },
            commission,
            finalPurchasePrice,
            newConstruction,
            anticipatedClosingDate: fomattedDate,
        };
        this.props.onConfirmEdit(data);
    }

    buildBuyerAnticipatedClosingDate = (date) => format(date, this.props.dateFormat);

    buildCommissionStructureFieldError = () => {
        const {
            hasErrorCommisionStructureField,
            hasErrorCommisionStructureFieldRange,
        } = this.state;
        let labelError;
        if (hasErrorCommisionStructureField) labelError = 'This field is required';
        if (hasErrorCommisionStructureFieldRange) labelError = 'Commission should be between 0 and 100';
        if (labelError) return (<p className="subject-property-information__label-error">{ labelError }</p>);
        return null;
    };

    buildFinalPurchaseFieldError = () => {
        const {
            hasErrorFinalPurchase,
            hasErrorFinalPurchaseFieldRange,
        } = this.state;
        let labelError;
        if (hasErrorFinalPurchase) labelError = 'This field is required';
        if (hasErrorFinalPurchaseFieldRange) labelError = `Final purchase should be between ${MIN_FINAL_PURCHASE_PRICE} and ${MAX_FINAL_PURCHASE_PRICE}`;
        if (labelError) return (<p className="subject-property-information__label-error">{ labelError }</p>);
        return null;
    }

    buildRealtorLocationForm = () => {
        const {
            servingIn,
            newConstruction,
            anticipatedClosingDate,
            finalPurchasePrice,
            commission,
            apartmentSuiteNumber,
            isVisibleCalendar,
            hasErrorAddressField,
        } = this.state;
        return (
            <div className="subject-property-information__row row">
                <div className="subject-property-information col-xs-12">
                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <FormGroup>
                            <ControlLabel className="subject-property-information__box-information is-subtitle">PROPERTY ADDRESS</ControlLabel>
                            <LocationAutoCompleteInput
                                getOptionsMillisecondsDelay={300}
                                value={servingIn}
                                placeholder="ENTER ADDRESS OR ZIPCODE..."
                                onChange={this.onChangeAddressField}
                            />
                            <p className={`${hasErrorAddressField ? 'subject-property-information__label-error' : 'subject-property-information__label-error__not-visible'}`}>
                                Please select a valid address
                            </p>
                        </FormGroup>
                    </div>
                    <div className="col-xs-12 col-sm-3 col-md-3">
                        <FormGroup>
                            <ControlLabel className="subject-property-information__box-information is-subtitle">APT#</ControlLabel>
                            <FormControl
                                componentClass="input"
                                value={apartmentSuiteNumber}
                                placeholder="#"
                                onChange={this.onChangeApartmentSuiteNumberField}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-xs-12 col-sm-3 col-md-3">
                        <Checkbox className="subject-property-information__row checkbox" onChange={this.onChangeNewConstructionChk} checked={newConstruction}>
                        NEW CONSTRUCTION
                        </Checkbox>
                    </div>
                </div>
                <div className="subject-property-information col-xs-12">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <FormGroup>
                            <ControlLabel className="subject-property-information__box-information is-subtitle">FINAL PURCHASE PRICE</ControlLabel>
                            <MaskedInputField
                                className="form-control"
                                maskType="amount"
                                value={String(finalPurchasePrice)}
                                placeholder="$"
                                onChange={this.onChangeFinalPurchasePrice}
                            />
                            { this.buildFinalPurchaseFieldError() }
                        </FormGroup>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <FormGroup>
                            <ControlLabel className="subject-property-information__box-information is-subtitle">COMMISSION STRUCTURE %</ControlLabel>
                            <FormControl
                                type="number"
                                componentClass="input"
                                value={commission}
                                placeholder="%"
                                onChange={this.onChangeComission}
                                maxLength={3}
                                min="0"
                                max="100"
                            />
                            { this.buildCommissionStructureFieldError() }
                        </FormGroup>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <FormGroup>
                            <ControlLabel className="subject-property-information__box-information is-subtitle">ANTICIPATED CLOSING DATE</ControlLabel>
                            <FormControl
                                componentClass="input"
                                value={format(anticipatedClosingDate, this.props.calendarFormat)}
                                onClick={this.setCalendarVisibility}
                                onChange={() => {}}
                            />
                            <Calendar
                                className={`${!isVisibleCalendar ? 'react-calendar__not-visible' : null}`}
                                onChange={this.onChangeCalendar}
                                value={anticipatedClosingDate}
                                activeStartDate={anticipatedClosingDate}
                                minDate={new Date()}
                                maxDate={addDays(new Date(), 45)}
                                formatMonth={(date) => this.buildBuyerAnticipatedClosingDate(date)}
                            />
                        </FormGroup>
                    </div>
                </div>
                {this.buildConfirmEditButton()}
            </div>
        );
    }

    render = () => this.buildRealtorLocationForm();
}

RealtorMatchProfileSubjectPropertyInformationEdit.defaultProps = {
    dateFormat: 'YYYY-MM-DDTHH:mm:ss',
    calendarFormat: 'MM/DD/YYYY',
    country: 'USA',
};

RealtorMatchProfileSubjectPropertyInformationEdit.propTypes = {
    propertyAddress: PropTypes.object,
    finalPurchasePrice: PropTypes.number,
    commission: PropTypes.number,
    newConstruction: PropTypes.bool,
    onConfirmEdit: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    calendarFormat: PropTypes.string,
    country: PropTypes.string,
    anticipatedClosingDate: PropTypes.string,
};
