import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';
import RealtorSignUpSuccess from '../SignUp/RealtorSignUp/RealtorSignUpSuccess/RealtorSignUpSuccess';

import './spinWheelPrize.css';

class SpinWheelPrize extends React.Component {
    static propTypes = {
        selectedPrizeDescription: PropTypes.string,
        selectedPrizeUrl: PropTypes.string,
        selectedPrizeIndex: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = {
            isSuccessView: false,
        };
    }

    continueOperation = () => (this.setState({ isSuccessView: true }));

    createButton = () => (
        <Link
            className="spin-wheel-prize__link"
            to="/signup/realtor/success"
        >
            <Button
                onClick={this.continueOperation}
                className="spin-wheel-prize__confirm-button"
            >
                Continue
            </Button>
        </Link>
    )

    noPrizeWin = () => (
        <div className="spin-wheel-prize">
            <div className="spin-wheel-prize--img">
                <div className="spin-wheel-prize__text">
                    <div className="spin-wheel-prize__next-time-title">
                        No prize won. Better luck next time!
                    </div>
                    {this.createButton()}
                </div>
            </div>
        </div>
    )

    render() {
        if (this.state.isSuccessView) {
            return <RealtorSignUpSuccess />;
        }
        if (this.props.selectedPrizeIndex === -1) {
            return this.noPrizeWin();
        }

        return (
            <div className="spin-wheel-prize">
                <div className="spin-wheel-prize--img">
                    <div className="spin-wheel-prize__text">
                        <div className="spin-wheel-prize__title">
                            Congratulations!
                        </div>
                        <div className="spin-wheel-prize__subtitle">
                            {this.props.selectedPrizeDescription}
                        </div>
                        <div className="spin-wheel-prize__prize">
                            <div className="spin-wheel-prize__prize--content-img">
                                <img className="spin-wheel-prize__prize--img" src={this.props.selectedPrizeUrl} alt="" />
                            </div>
                        </div>
                        {this.createButton()}
                    </div>
                </div>
            </div>
        );
    }
}

export default SpinWheelPrize;
