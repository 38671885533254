import React, { Fragment } from 'react';
import {
    formatCurrency,
    numberRangeOptions
} from '../../../util/util';

export const rangeOptions = (min, max) =>
    <Fragment>
        { numberRangeOptions(min, max).map(option =>
            <option
                key={ option.value }
                value={ option.value }
            >{ option.label }</option>
        ) }
    </Fragment>;

export const rangeOptionsWithSuffix = (min, max, suffix, addChooseOption) =>
    <Fragment>
        { addChooseOption && <option>Choose an option</option> }
        { numberRangeOptions(min, max).map((option) =>
            <option
                key={ option.value }
                value={ option.value }
            >{ `${option.label}${suffix}` }</option>
        ) }
    </Fragment>;

export function getBudgetPercentageFormatter(budget, percent) {
    const ratio = parseFloat(percent);
    let range;

    try {
        range = JSON.parse(budget);
    } catch(_) {
        range = null
    }

    if (!range || isNaN(ratio) || ratio > 100) range = null;

    const mapRange = (range, p) =>
        range.map(v =>
            v === undefined
                ? ''
                : p <= 0 ? '$0' : `${formatCurrency(v * p / 100, 0)}`
        );

    const formatAmount = (percent = ratio) => {
        const value = range === null ? [''] : mapRange(range, percent);
        return percent > 0 ? value : [value.pop()];
    };

    return {
        formatAmount,
        formatRange: (percent = ratio, separator = ' – ') =>
            formatAmount(percent).join(separator)
    }
}
export const Locality = {
    neighborhood: '',
    city: '',
    state: '',
    lat: '',
    lng: '',
};

export const EmptyLocation = {
    location: '',
    ...Locality
};

export const EmptyAddress = {
    address: '',
    apartmentSuiteNumber: '',
    zipcode: '',
    ...Locality
};

export const adaptStreetAddress = ({ address, lat: latitude, lng: longitude, ...rest }) => ({
    streetNumberName: (address || '').split(/\s*,\s*/).shift(),
    latitude,
    longitude,
    ...rest
});

export const adaptCityAndState = ({ location, lat: latitude, lng: longitude, ...rest }) => ({
    latitude,
    longitude,
    ...rest
});

export const adaptStreetAddressFromService = ({ 
    apartmentSuiteNumber,
    neighborhood,
    ...rest }) => ({
        neighborhood: neighborhood ? neighborhood : '',
        apartmentSuiteNumber: apartmentSuiteNumber ? apartmentSuiteNumber : '',
        ...rest,
});
