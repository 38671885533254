import get from 'lodash/get';
import trim from 'lodash/trim';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import {ProfilePicture} from '../ProfilePicture/ProfilePicture';
import IconDispute from '../../styles/assets/icon-Dispute.svg';

class BuyerDisputedProfileComponent extends React.Component {
    static propTypes = {
        profile: PropTypes.object,
    };

    render = () => {
        const { profile } = this.props;
        if (!profile) return <SpinnerLoader />;

        const isBuyer = get(profile, 'publicProfile.userInfo.buyer');
        const isDisputing = get(profile, 'disputing');

        if (!(isBuyer && isDisputing)) return <Redirect to={{ pathname: '/' }} />;

        const picture = trim(get(profile, 'publicProfile.userInfo.profilePicUrl'));
        const firstName = trim(get(profile, 'publicProfile.userInfo.firstName'));

        return (
            <div className="profile__container">
                <div className="profile__panel">
                    <ProfilePicture
                        className="pulled-up"
                        picture={picture}
                        canEdit={false}
                        onUploadPicture={this.props.onUploadPicture}
                    />

                    <div style={{ margin: '50px auto 20px' }}>
                        <img src={IconDispute} alt="Dispute" />
                    </div>

                    <div className="profile__caption">
                        {`Hi ${firstName}, your account`}
                        <br />
                        is under dispute
                    </div>

                    <div className="profile__message is-centred is-emphasized" style={{ margin: '30px auto 120px' }}>
                        <b>Be patient</b>, your LemonBrew account will be suspended and locked until we finish the investigation.<br />
                        This process could take several days, If you have any questions, do not hesitate to contact us.
                    </div>
                </div>
            </div>
        );

    };
}
export default connect(
    state => ({
        profile: state.user.profile,
    }),
)(BuyerDisputedProfileComponent);
