import React from 'react';
import { Link } from 'react-router-dom';
import './termsandpolicies.css';

const TermsAndConditions = () =>
    <div className="lb__documentation__content">
        <h1 className="lb__documentation__content__title">CONSUMER TERMS OF USE</h1>
        <div className="lb__documentation__content__inner">
            <h2 className="lb__documentation__content__inner__title">1. General</h2>
            <div className="lb__documentation__content__inner__text">
            This Terms of Use Agreement (“Agreement”) governs your (the “Consumer”) access and use of the website, <Link to='htts://www.LemonBrew.com' target='_blank' rel='noopener noreferrer'>www.LemonBrew.com</Link> (“Website”), and our services (defined below). This Website is owned and operated by LemonBrew Technologies Corp., and the terms and conditions herein apply to LemonBrew Technologies Corp., its parent companies, subsidiaries, and affiliates, including its licensed real estate broker subsidiaries, referred to below as "LemonBrew", "we", "us", "our" or "Company". Your use of this Website is subject to the following terms and conditions.
            </div>
            <h2 className="lb__documentation__content__inner__title">2. Services</h2>
            <h3 className="lb__documentation__content__inner__subtitle">a. Introduction.</h3>
            <div className="lb__documentation__content__inner__text">
            LemonBrew is an online web platform where Consumers may be matched with real estate agents. A Match is defined when LemonBrew has introduced you to a real estate agent (also referred to as a “LemonBrew Partner Agent”) via phone, email, text or through the Website or portal. Any products or services provided by LemonBrew, either through the Website or otherwise, including a Match, a BrewScore, and a HomeBrew Report, are defined herein as “Services.”
            </div>
            <div className="lb__documentation__content__inner__text">
            LemonBrew is not a mortgage lender or mortgage broker. We do not make any loan commitments or fund any mortgage loans.
            </div>
            <div className="lb__documentation__content__inner__text">
            You understand and agree that we may, in exchange for matching you with a LemonBrew Partner Agent, be compensated by the Partner Agent as a percentage of the purchase price.
            </div>
            <div className="lb__documentation__content__inner__text">
            Although LemonBrew is a licensed real estate brokerage, all real estate brokerage activities are performed by the LemonBrew Partner Agent with whom you have been matched.
            </div>
            <div className="lb__documentation__content__inner__text">
            Your participation in LemonBrew’s Website and Services is voluntary and can be terminated at any time. Any Matches made prior to such termination are still bound our agreement with the LemonBrew Partner Agent. In the event that legislation or governmental regulations prohibit the use of any products or services, we may terminate our Services upon the effective date of such legislation or regulations and you shall discontinue using our Services. Upon termination, you and we agree to continue our cooperation in order to effect an orderly termination of our relationship.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">b. Disclaimer to Consumer Using the Services.</h3>
            <div className="lb__documentation__content__inner__text">
            You understand and agree that LemonBrew is not representing you as a real estate agent or broker or property manager. Also by using the Website or the Services, you may come into contact with or use the services of various real estate agents or brokers, vendors, advertisers or other third parties. LemonBrew does not control or supervise any such affiliates, agents, brokers, vendors, advertisers or other third parties, and LemonBrew is not responsible for their actions. All terms and conditions between a Consumer and a real estate professional are governed by a separate legal agreement that does not involve LemonBrew and is solely between the Consumer and the real estate professional. LemonBrew does not endorse, validate, or recommend any legal agreements between Consumers and real estate professionals. Please consult your own attorney.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">c. BrewScore. </h3>
            <div className="lb__documentation__content__inner__text">
            The Website allows Consumers to submit, and to authorize LemonBrew to obtain, information from third parties regarding, among other things, your finances, assets, credit use and history, and homebuying preferences, to calculate a BrewScore for determining your potential to receive a rebate from the Real Estate Agent and in matching you to LemonBrew Partner Agents. The BrewScore is meant for your personal use only. You acknowledge and agree that LemonBrew (including through the BrewScore and other services) has not and does not provide you legal, tax, financial, or other advice (including, without limitation, advice on how to improve or repair your credit or credit scores), and that the BrewScore and other services are not designed or intended to provide any such advice. We provide Services and related information solely to assist you in locating real estate agents and related real estate services and you agree that you will not use the Website or Services for any other purpose, especially a commercial purpose.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">d. Your Right to Obtain a Consumer Report Free of Charge. </h3>
            <div className="lb__documentation__content__inner__text">
            Consumer reporting agencies are required by law to give you a copy of your consumer report (sometimes referred to as a credit report) upon request at no charge or for a nominal fee. None of the products offered through this site are intended as a substitute for the consumer report that may be available to you without charge.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">e. Personal Information; Permissible Purpose Authorization.</h3>
            <div className="lb__documentation__content__inner__text">
            As needed to provide Services to you, you authorize and instruct us to obtain and compile your: (i) credit information from one or more consumer reporting agencies; (ii) "non-public personal information," “personal information,” and/or “highly restricted personal information” about or concerning you as defined by the Gramm-Leach-Bliley Act; and (iii) other personal information, including information provided by you.
            </div>
            <div className="lb__documentation__content__inner__text">
            You acknowledge and agree that your access to the Services, the Website, and any consumer credit information contained therein is subject to your prior written authorization and our verification of your identity. As such, you understand and agree that by using the Services, you are providing “written instructions” in accordance with the FCRA for us to obtain credit information about you from one or more of the three nationwide consumer reporting agencies and you hereby authorize us to access your personal credit information in order to provide the Services. We may also use information from third party sources to verify your identity.
            </div>
            <div className="lb__documentation__content__inner__text">
            By using the Services, you certify that you are eighteen (18) years or older and that all of the information provided incident to using the Services is true, accurate, complete and up to date. You must also establish a user identification and password, and you agree to keep them strictly confidential and not to share them with others.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">f. Disputes & Questions About Your BrewScore.</h3>
            <div className="lb__documentation__content__inner__text">
            Any questions or disputes regarding the accuracy of any information provided by you in the BrewScore must be directed to, and will be handled by, LemonBrew. Questions or disputes regarding the accuracy of information obtained by LemonBrew from third parties, including consumer reporting agencies (i.e., Experian), must be directed to, and will be handled by, the applicable third party. The FCRA permits consumers to dispute inaccurate information in their credit report without charge; however, accurate information cannot be changed. If you choose to dispute your BrewScore, your LemonBrew account will be suspended and locked until we (or the applicable third party) finish/es the investigation. LemonBrew is required by law to give you a copy of your consumer disclosure (the consumer information about you retained by LemonBrew) upon request, at no charge or for a nominal fee. If you believe your BrewScore contains inaccurate or incomplete information, you may request, at no charge to you, that LemonBrew research the information contained in your LemonBrew consumer disclosure. Further, if you believe or suspect that you have been the victim of identity theft, you may request, at no charge to you, that LemonBrew place an alert on your LemonBrew consumer report. To obtain more information about your rights, read the <Link to='https://www.consumerfinance.gov/learnmore/' target='_blank' rel='noopener noreferrer'>Summary of FCRA Rights</Link>.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">g. Dispute Your BrewScore:</h3>
            <div className="lb__documentation__content__inner__text">
            To dispute your BrewScore, please go to <Link to='www.lemonbrew.com/dispute' target='_blank' rel='noopener noreferrer'>www.lemonbrew.com/dispute</Link> and complete the dispute form by (i) identifying the type of information you would like to dispute, (ii) faxing the applicable information to us at (866) 400-2388, and (iii) clicking “Finish” on the dispute form. All information you provide during the dispute process must be accurate and true. Providing false information obstructs the dispute process and could be punishable by law. We cannot change accurate information or information verified as accurate. You agree you will not send us any information that violates any laws or third party rights (including but not limited to a third party's intellectual property or privacy right), or contains objectionable material. If we believe or have reason to believe the information you sent is subject to the foregoing restrictions, we will not use such information. The information you send may be shared by us with third parties as necessary for the purpose of processing your dispute. Contact us at (833) 536-6627- for more information.
            </div>
            <div className="lb__documentation__content__inner__text">
            To dispute your Experian credit information or Experian Credit Score, please contact Experian directly at <a href="tel:8666171894">(866) 617-1894</a>.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">h. HomeBrew Report.</h3>
            <div className="lb__documentation__content__inner__text">
            LemonBrew's HomeBrew Report is provided solely for general education and informational purposes only. The HomeBrew Report is not a formal appraisal but an easy-to-read home value report designed to help you better understand neighborhood data and current market trends. LemonBrew uses data from third party companies and affiliates to create the score. The estimated market value, neighborhood information, property tax data and other information contained in the HomeBrew Report is not guaranteed by LemonBrew and should not be used as a substitute for a professional appraiser's opinion or the expertise of a real estate agent.
            </div>
            <h2 className="lb__documentation__content__inner__title">3. Privacy Policy</h2>
            <div className="lb__documentation__content__inner__text">
            Our privacy policy ("Privacy Policy") describes the information that we collect from you when you visit and use our Website, how we share it, and how we use it. By using the Website you consent to our Privacy Policy and the collection, use and sharing of information as discussed in the policy.
            </div>
            <h2 className="lb__documentation__content__inner__title">4. Use and Access of our Website</h2>
            <div className="lb__documentation__content__inner__text">
            By using this Website, you accept and agree with these terms and conditions whether or not you complete a transaction with Company and whether or not you complete a transaction on our Website or through any other channel associated with Company. By using this Website and our Services, you represent and warrant to us that you are of sufficient legal age to enter into this Agreement. You agree to not pretend that you are, or that you represent, someone else, or impersonate any other person or entity. You represent and warrant that the information that you provide to us will be current, true and accurate. Any incorrect or incomplete information may affect the value of our service.
            </div>
            <div className="lb__documentation__content__inner__text">
            We reserve the right at all times, in its sole discretion and without notice to you, to deny your access to and use of this Website. You agree that you may not use or attempt to use any part of this Website to represent that you have any affiliation with LemonBrew without express written permission from us. You agree and acknowledge that you have the sole responsibility and liability for your use of this Website and for providing or obtaining, and for maintaining, all of the hardware, software, electrical power, telecommunications, Internet services, and other products or services necessary or desirable for you to access and use this Website.
            </div>
            <h2 className="lb__documentation__content__inner__title">5. Indemnification and Limitation of Liability</h2>
            <div className="lb__documentation__content__inner__text">
            You agree to indemnify LemonBrew and all of the employees, officers, directors, agents and representatives thereof and to hold all of them harmless from, all costs, claims, damages, expenses or other losses, including attorney’s fees and court costs, that arise from or are related to your use of this Website and/or your breach/violation of or failure to comply with the Agreement. Neither LemonBrew nor any of our affiliated persons will be liable to you for any loss or injury arising out of or caused, in whole or in part, by any negligent acts or omissions of any such person in preparing, reporting or delivering the Services, verifying your identity, or in doing anything related thereto. Neither we nor the affiliated persons will be liable to you for direct, special, indirect, incidental, consequential, punitive or emotional distress damages in connection with your use of this Website, any use or reliance upon information found at this Website or provided by us or any Services provided at this Website or through any other medium, even if advised of the possibility of such damages.
            </div>
            <h2 className="lb__documentation__content__inner__title">6. Communications Consent</h2>
            <div className="lb__documentation__content__inner__text">
            You acknowledge and agree that we may contact you via electronic means, including e-mail, calls and text messages, at the email address and telephone number provided by you telemarketing or non-telemarketing purposes. You further agree that we may make this contact by using a predictive dialer or an automatic telephone dialing system, and/or by using an artificial or prerecorded voice. You agree to notify us promptly if a telephone number you provided to us is no longer a number at which you may be reached (such as in the event of number reassignment or if you want to revoke your consent to be contacted by telephone). Please note that mobile messaging, text, and data rates may apply.
            </div>
            <h2 className="lb__documentation__content__inner__title">7. Consent For Election Signatures, Records And Disclosure</h2>
            <div className="lb__documentation__content__inner__text">
            <em>Please read this information carefully and print a copy and/or retain this information for future reference.</em>
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">a. Introduction.</h3>
            <div className="lb__documentation__content__inner__text">
            This section notifies you of your rights when receiving electronic disclosures, notices, and information. Using the Website and/or Services and agreeing to this Agreement, you acknowledge that you received this notice and that you consent to conduct transactions using electronic signatures, electronic disclosures, electronic records, and electronic contract documents ("disclosures").
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">b. Option for Paper or Non-Electronic Records.</h3>
            <div className="lb__documentation__content__inner__text">
            You may request any disclosures in paper copy by contacting the third party lending platforms directly.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">c. Scope of Consent.</h3>
            <div className="lb__documentation__content__inner__text">
            This consent applies to all interactions you have with LemonBrew, the Website, and/or the Services, and includes those interactions engaged in on any mobile device, including phones, smart-phones, and tablets. Disclosures may be provided through the Website or by e-mail.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">d. Hardware and Software Requirements.</h3>
            <div className="lb__documentation__content__inner__text">
                To access and retain this Agreement and all disclosures electronically, you will need to use the following computer software and hardware: A PC or MAC compatible computer or other device capable of accessing the Internet, access to an e-mail account, and an Internet Browser software program that supports at least 128 bit encryption, such as Microsoft
                <span role="img" aria-label="register-icon">®️</span> Internet Explorer, Netscape
                <span role="img" aria-label="register-icon">®️</span>  or Mozilla Firefox
                <span role="img" aria-label="register-icon">®️</span> . To read some documents, you may need a PDF file reader like Adobe
                <span role="img" aria-label="register-icon">®️</span> Acrobat Reader X
                <span role="img" aria-label="register-icon">®️</span>  or Foxit
                <span role="img" aria-label="register-icon">®️</span> . You will need a printer or a long-term storage device, such as your computer's disk drive, to retain a copy of this Agreement and all disclosures for future reference.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">e. Withdrawing Consent.</h3>
            <div className="lb__documentation__content__inner__text">
            You may withdraw your consent by contacting us at <a href="mailto:support@lemonbrew.com">support@lemonbrew.com</a> or <a href="tel:8335366627">(833) 536-6627</a>. If you decide to withdraw this consent, the legal effectiveness, validity, and enforceability of any and all prior electronic disclosures will not be affected.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">f. Change To Your Contact Information.</h3>
            <div className="lb__documentation__content__inner__text">
            You should keep us informed of any change in your electronic address or mailing address. You may update such information by logging into your LemonBrew account on the Website or by contacting us at <a href="mailto:support@lemonbrew.com">support@lemonbrew.com</a> or <a href="tel:8335366627">(833) 536-6627</a>.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">g. CONSENT.</h3>
            <div className="lb__documentation__content__inner__text">
            <b>BY AGREEING TO THIS CONSUMER TERMS OF USE AGREEMENT AND/OR USING THE WEBSITE OR SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS INFORMATION ABOUT ELECTRONIC SIGNATURES, RECORDS, DISCLOSURES. YOU CONSENT TO USING ELECTRONIC SIGNATURES AND HAVING ALL DISCLOSURES PROVIDED OR MADE AVAILABLE TO YOU IN ELECTRONIC FORM. YOU ACKNOWLEDGE THAT YOU MAY REQUEST A PAPER COPY OF THE ELECTRONIC RECORDS AND DISCLOSURES, WHICH WILL BE PROVIDED TO YOU AT NO CHARGE.</b>
            </div>
            <h2 className="lb__documentation__content__inner__title">8. No Warranty</h2>
            <div className="lb__documentation__content__inner__text">
            Much of the data contained in the LemonBrew Service is provided to us by third parties and therefore we do not control the accuracy or completeness of the information contained in the Services. The content, Services, calculations, information, products and materials on or available through this Website are provided "as is" and without warranties of any kind, either expressed or implied. To the fullest extent permissible under applicable law, LemonBrew disclaims all warranties, express or implied, including, but not limited to, all implied warranties of merchantability, fitness for a particular purpose, title and non- infringement. We make no representation or warranty regarding the content, Services, calculations, information, products or materials, or the use or results of use of the content, Services, calculations, products or materials, on or available through this web site. The content, Services, calculations, information, products and materials on or available through this Website could include inaccuracies or typographical errors and could become inaccurate because of developments occurring after their respective dates of preparation or publication.
            </div>
            <h2 className="lb__documentation__content__inner__title">9. Third Party Sites</h2>
            <div className="lb__documentation__content__inner__text">
            The Website may include links to third party websites ("Third Party Sites"). You should review any applicable terms or privacy policy of a Third Party Site before using it or sharing any information with it, because you may give the third-party permission to use your information in ways we would not. LemonBrew is not responsible for and does not endorse any features, content, advertising, products or other materials on or available from Third Party Sites.
            </div>
            <h2 className="lb__documentation__content__inner__title">10. Intellectual Property Ownership and License</h2>
            <h3 className="lb__documentation__content__inner__subtitle">a.  Copyright.</h3>
            <div className="lb__documentation__content__inner__text">
            All materials (including source code, data, images, and other content) contained in the Website, including the selection and arrangement of the materials, are owned by LemonBrew or are licensed by LemonBrew for use on the Website.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">b. Trademarks.</h3>
            <div className="lb__documentation__content__inner__text">
            LemonBrew, the LemonBrew logos and other LemonBrew trademarks, service marks, graphics, and logos used in connection with the wWebsite and services are trademarks or registered trademarks of LemonBrew. Other trademarks and logos used in connection with LemonBrew may be the trademarks of their respective owners, and such use is not intended to grant us any rights in such trademarks.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">c. Other Intellectual Property.</h3>
            <div className="lb__documentation__content__inner__text">
            LemonBrew also owns trade secrets and know-how that contribute to the functionality of the Website and services.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">d. Restrictions.</h3>
            <div className="lb__documentation__content__inner__text">
            Except as enabled and directed on the Services, you may not modify, decompile, reproduce, redistribute, attempt to commercially gain from your use (or misuse) of the Website any of its components. We may revoke your permission to access and use the Website or Services, and we may block or prevent you from accessing the Website or Services, in our sole discretion without notice. If you violate this Agreement, your permission to access and use the Website and/or Services is automatically revoked.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">e. Reservation of Rights.</h3>
            <div className="lb__documentation__content__inner__text">
            LemonBrew reserves all of its intellectual property rights in the Website. This Agreement does not grant you any right or license with respect to any trademarks and logos.
            </div>
            <h2 className="lb__documentation__content__inner__title">11. Not A Credit Repair Organization Or Contract</h2>
            <div className="lb__documentation__content__inner__text">
            LemonBrew is not a credit repair organization and does not provide any form of credit repair advice or counseling. We do not offer, provide, or furnish any Services, or any advice, counseling, or assistance, for the express or implied purpose of improving your credit record, credit history, or credit rating. By this we mean that we do not claim we can "clean up" or "improve" your credit record, credit history, or credit rating. These items (credit record, history, and rating) are based on your past or historical credit behavior, and accurate and timely adverse credit information cannot be changed.
            </div>
            <h2 className="lb__documentation__content__inner__title">12. Equal Housing Opportunity</h2>
            <div className="lb__documentation__content__inner__text">
            LemonBrew complies with all applicable laws, regulations, and policies regarding the achievement of equal housing opportunity throughout the areas we serve, and to compliance with all applicable human rights legislation. We encourage and support an affirmative advertising and marketing program in which there are no barriers to obtaining housing because of race, color, religion, sex, sexual orientation, gender identity or expression, handicap, familial status, national origin, or other legally protected group.
            </div>
            <h2 className="lb__documentation__content__inner__title">13. AGREEMENT TO RESOLVE ALL DISPUTES BY BINDING INDIVIDUAL ARBITRATION.</h2>
            <div className="lb__documentation__content__inner__text">
            <b>PLEASE READ THIS ENTIRE SECTION CAREFULLY BECAUSE IT AFFECTS YOUR LEGAL RIGHTS BY REQUIRING ARBITRATION OF DISPUTES (EXCEPT AS SET FORTH BELOW) AND A WAIVER OF THE ABILITY TO BRING OR PARTICIPATE IN A CLASS ACTION, CLASS ARBITRATION, OR OTHER REPRESENTATIVE ACTION. ARBITRATION PROVIDES A QUICK AND COST EFFECTIVE MECHANISM FOR RESOLVING DISPUTES, BUT YOU SHOULD BE AWARE THAT IT ALSO LIMITS YOUR RIGHTS TO DISCOVERY AND APPEAL.</b>
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">a. Binding Arbitration.</h3>
            <div className="lb__documentation__content__inner__text">
            Any Claim (as defined below) raised by either you or LemonBrew against the other shall be subject to mandatory, binding arbitration. As used in this arbitration provision, the term "Claim" or "Claims" means any claim, dispute, or controversy between you and us relating in any way to your relationship with LemonBrew, including but not limited to any Claim arising from or relating to this Agreement, the Services or this Website, or any information you receive from us, whether based on contract, statute, common law, regulation, ordinance, tort, or any other legal or equitable theory, regardless of what remedy is sought. This arbitration obligation extends to claims you may assert against LemonBrew or its subsidiaries, affiliates, successors, assigns, employees, and agents. The term "Claim" shall have the broadest possible construction.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">b. No Class or Representative Arbitrations.</h3>
            <div className="lb__documentation__content__inner__text">
            The arbitration will be conducted as an individual arbitration. Neither you nor we consent or agree to any arbitration on a class or representative basis, and the arbitrator shall have no authority to proceed with arbitration on a class or representative basis. No arbitration will be consolidated with any other arbitration proceeding without the consent of all parties. This arbitration provision applies to and includes any Claims made and remedies sought as part of any class action, private attorney general action, or other representative action. By consenting to submit your Claims to arbitration, you will be forfeiting your right to bring or participate in any class action (whether as a named plaintiff or a class member) or to share in any class action awards, including class claims where a class has not yet been certified, even if the facts and circumstances upon which the Claims are based already occurred or existed.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">c. Right to Opt-Out of this Arbitration Provision.</h3>
            <div className="lb__documentation__content__inner__text">
            IF YOU DO NOT WISH TO BE BOUND BY THE ARBITRATION PROVISION, YOU HAVE THE RIGHT TO EXCLUDE YOURSELF. Opting out of the arbitration provision will have no adverse effect on your relationship with LemonBrew or the delivery of Services to you. In order to exclude yourself from the arbitration provision, you must notify LemonBrew in writing within 30 days of the date that you first accept this Agreement on the Website. To be effective, timely written notice of opt out must be delivered to [ADDRESS] and must include your name and address, as well as a clear statement that you do not wish to resolve disputes with LemonBrew through arbitration. If you have previously notified LemonBrew that you wish to opt-out of arbitration, you are not required to do so again. Any opt-out request postmarked after the opt-out deadline or that fails to satisfy the other requirements above will not be valid, and you must pursue your Claim in arbitration or small claims court.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">d. Initiation of Arbitration.</h3>
            <div className="lb__documentation__content__inner__text">
            Arbitration shall be administered by the American Arbitration Association ("AAA") under its Consumer Arbitration Rules in effect at the time the arbitration is filed unless any portion of those rules is inconsistent with any specific terms of this arbitration provision or this Agreement, in which case the terms of this arbitration provision and this Agreement will govern. The AAA’s rules may be obtained at www.adr.org, or by calling the AAA at 1-88-778-7879. To commence an arbitration, you must file a copy of your written arbitration demand with the AAA (either online at www.adr.org or by mail addressed to AAA, Case Filing Services, 1101 Laurel Oak Road, Suite 100, Voorhees, NJ 08043). The arbitration shall be before a single arbitrator. The arbitrator will have the power to award a party any relief or remedy that the party could have received in court in accordance with the law or laws that apply to the dispute, subject to any limitations of liability or damages that exist under this Agreement. This agreement to arbitrate involves interstate commerce and is made pursuant to the Federal Arbitration Act, 9 U.S.C. sections 1-16 (the "FAA"). Any claim or dispute as to the enforceability of this arbitration provision's restrictions on your right to participate in or pursue a class action or class wide arbitration shall be decided by a court and not an arbitrator.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">e. Payment of Arbitration Fees and Costs.</h3>
            <div className="lb__documentation__content__inner__text">
            In the event you file a Claim in arbitration in accordance with these provisions, we will advance all arbitration filing fees if you ask that we do so, in writing, prior to the commencement of the arbitration. The payment of any such fees will be made directly by us to the AAA. Such requests should be mailed to [ADDRESS]. We will also pay all arbitrator fees. If LemonBrew prevails in the arbitration, then the arbitrator shall have the authority to require that you reimburse LemonBrew for the filing fees advanced, but only to the extent such fees would be recoverable by us in a judicial action. You are responsible for all other fees and costs you incur in the arbitration, including attorney's fees and expert witness fees, except that the arbitrator shall have the authority to award attorney's fees and costs to the prevailing party; (i) based on applicable law; (ii) under the rules of the arbitration administrator; or (iii) if the arbitrator rules in your favor and the arbitrator expressly determines that there is a good reason for requiring us to pay those fees and costs.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">f. Continuation.</h3>
            <div className="lb__documentation__content__inner__text">
            This arbitration provision shall survive: (i) termination or changes in this Agreement or the relationship between you and us; and (ii) termination or changes in our providing any Services to you.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">g. Small claims court.</h3>
            <div className="lb__documentation__content__inner__text">
            Notwithstanding anything in this section, either you or LemonBrew may bring an individual action in small claims court as long as (i) the claim is not aggregated with the claim of any other person, and (ii) the small claims court is located in the same county and state as your address that you most recently provided to LemonBrew according to LemonBrew’s records in connection with this Agreement.
            </div>
            <h2 className="lb__documentation__content__inner__title">14. Other Legal Terms</h2>
            <h3 className="lb__documentation__content__inner__subtitle">a. Severability.</h3>
            <div className="lb__documentation__content__inner__text">
            If any provision of this Agreement is found by a court of competent jurisdiction or arbitrator to be illegal, void, or unenforceable, the unenforceable provision will be modified so as to render it enforceable and effective to the maximum extent possible in order to effect the intention of the provision.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">b. Notices.</h3>
            <div className="lb__documentation__content__inner__text">
            You may contact us here <a href="mailto:legal@lemonbrew.com">legal@lemonbrew.com</a>. Or via mail or courier at:<br /><br />
            PO Box 18272<br />
            1233 The PLZ<br />
            Charlotte, NC 28205
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">c. Entire Agreement.  </h3>
            <div className="lb__documentation__content__inner__text">
            You agree that this Agreement constitutes the entire, complete and exclusive agreement between you and LemonBrew regarding the Services and supersedes all prior agreements and understandings, whether written or oral, or whether established by custom, practice, policy or precedent, with respect to the subject matter of this Agreement.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">d. No Waiver. </h3>
            <div className="lb__documentation__content__inner__text">
            Our failure to act with respect to a breach of this Agreement by you or others does not waive our right to act with respect to that breach or subsequent similar or other breaches.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">e. Amendments to This Agreement.</h3>
            <div className="lb__documentation__content__inner__text">
            We reserve the right to modify, supplement, or replace all or any portion of the terms of this Agreement at any time by posting it on the Website.
            </div>
            <h3 className="lb__documentation__content__inner__subtitle">f. GoverningLaw.</h3>
            <div className="lb__documentation__content__inner__text">
            This Agreement shall be governed by, interpreted, construed, applied and enforced in accordance with the laws of the State of New Jersey. The courts of the State of New Jersey, or the federal court of the United States situated therein, as applicable, shall  have sole and exclusive jurisdiction over any action, claim, demand, proceeding or lawsuit whatsoever arising under or in relation to this Agreement or its subject matter. The parties irrevocably agree, consent and submit themselves to the subject matter and personal jurisdiction of such courts for such purposes.
            </div>
        </div>
    </div>;

export default TermsAndConditions;
