import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import { months } from "shared/constants";
import { rangeOptions } from "../../util/util";

import "./admin-profile-stats-header.css";

const buildDefaultYears = () => {
    const currentYear = new Date().getFullYear();
    return rangeOptions(currentYear, currentYear - 1);
};

export default class AdminProfileStatsHeader extends React.PureComponent {
    static propTypes = {
        selectedDate: PropTypes.object,
        minDate: PropTypes.object,
        months: PropTypes.array,
        years: PropTypes.array
    };

    static defaultProps = {
        minDate: { year: 2018, month: 11 },
        months: months,
        years: buildDefaultYears()
    };

    getMinMonth = () => get(this.props, "minDate.month");

    getMinYear = () => get(this.props, "minDate.year");

    getSelectedStartMonth = () => get(this.props, "selectedDate.month");

    getSelectedStartYear = () => get(this.props, "selectedDate.year");

    isMonthDisabled = month =>
        this.getSelectedStartYear() === this.getMinYear() &&
        get(month, "value") < this.getMinMonth();

    isYearDisabled = year =>
        this.getSelectedStartMonth() < this.getMinMonth() &&
        get(year, "value") < this.getMinYear() + 1;

    buildMonth = month => (
        <option
            key={get(month, "value")}
            value={get(month, "value")}
            disabled={this.isMonthDisabled(month)}
            className="admin-profile-stats-header__select-option"
        >
            {get(month, "label")}
        </option>
    );

    buildMonths = () => map(this.props.months, this.buildMonth);

    buildStartMonthSelect = () => (
        <div className="admin-profile-stats-header__select-content">
            <select
                className="form-control admin-profile-stats-header__select"
                onChange={this.props.onChangeStartMonth}
                value={this.getSelectedStartMonth()}
            >
                {this.buildMonths()}
            </select>
            <i className="fas fa-sort admin-profile-stats-header__select-icon"></i>
        </div>
    );

    buildYear = year => (
        <option
            key={get(year, "value")}
            value={get(year, "value")}
            disabled={this.isYearDisabled(year)}
            className="admin-profile-stats-header__select-option"
        >
            {get(year, "label")}
        </option>
    );

    buildYears = () => map(this.props.years, this.buildYear);

    buildStartYearSelect = () => (
        <div className="admin-profile-stats-header__select-content">
            <select
                className="admin-profile-stats-header__select"
                onChange={this.props.onChangeStartYear}
                value={this.getSelectedStartYear()}
            >
                {this.buildYears()}
            </select>
            <i className="fas fa-sort admin-profile-stats-header__select-icon"></i>
        </div>
    );

    render = () => (
        <div className="admin-profile-stats-header">
            <div className="admin-profile-stats-header__title">
                <div className="admin-profile-stats-header__title-text">
                    Stats for
                    {this.buildStartMonthSelect()}
                    {this.buildStartYearSelect()}
                </div>
            </div>
        </div>
    );
}
