import React from "react";
import get from "lodash/get";
import { Field } from "formik";

export default class Review extends React.Component {
    componentDidMount = () => {
        this.resetAcknowledgment();
    };

    resetAcknowledgment = () => {
        this.props.setFieldValue("review.acknowledgment", false);
        this.props.setFieldTouched("review.acknowledgment");
    };

    buildBrokerageAcknowledgmentCheckbox = brokerage => {
        const isApproved = Boolean(get(brokerage, "approved"));

        if (isApproved) {
            return null;
        }

        return (
            <div className="row realtor__signupform__summary__field-container">
                <div className="col-xs-12">
                    <div className="checkbox">
                        <label htmlFor="acknowledgment">
                            <Field
                                type="checkbox"
                                id="acknowledgment"
                                name="review.acknowledgment"
                            />
                            We will be sending an email to your Broker at the
                            above email address. You acknowledge that the
                            information provided above is accurate.
                        </label>
                    </div>
                </div>
            </div>
        );
    };

    render = () => {
        const { values } = this.props;
        const name = `${get(values, "aboutYourself.firstName")} ${get(
            values,
            "aboutYourself.lastName"
        )}`;
        const email = get(values, "aboutYourself.email");
        const phone = get(values, "aboutYourself.realtorPhone");
        const licenseID = get(values, "aboutYourJob.licenseID");
        const licensedIn = get(values, "aboutYourJob.stateAbbreviation");
        const licensedAs = get(values, "aboutYourJob.realtorPosition.name");
        const brokerage = get(values, "aboutYourBrokerage.brokerage");
        const brokerageName = get(brokerage, "name") || brokerage;
        const brokerName = `${get(
            values,
            "aboutYourBrokerage.brokerFirstName"
        )} ${get(values, "aboutYourBrokerage.brokerLastName")}`;
        const brokerEmail = get(values, "aboutYourBrokerage.brokerEmail");
        const brokerPhone = get(values, "aboutYourBrokerage.brokerPhoneNumber");
        const fullTime =
            get(values, "aboutYourJob.realtorIsFullTime") === "fullTime"
                ? "Full Time"
                : "Part Time";

        return (
            <div className="container">
                <p className="realtor__signupform__header-create-account">
                    Almost there! Let's review...
                </p>
                <div className="realtor__signupform__summary">
                    <div className="row">
                        <div className="col-xs-12 col-md-6 realtor__signupform__summary__container">
                            <p className="realtor__signupform__summary__main-title">
                                You are:
                            </p>
                            <p className="realtor__signupform__summary__main-title main-label">
                                {name}
                            </p>
                            <div className="realtor__signupform__summary__separator-line" />
                            <div className="row realtor__signupform__summary__field-container">
                                <div className="col-xs-12 col-md-4 realtor__signupform__summary__title">
                                    EMAIL:
                                </div>
                                <div className="col-xs-12 col-md-8 realtor__signupform__summary__value">
                                    {email}
                                </div>
                            </div>
                            <div className="row realtor__signupform__summary__field-container">
                                <div className="col-xs-12 col-md-4 realtor__signupform__summary__title">
                                    PHONE:
                                </div>
                                <div className="col-xs-12 col-md-8 realtor__signupform__summary__value">
                                    {phone}
                                </div>
                            </div>
                            <div className="row realtor__signupform__summary__field-container">
                                <div className="col-xs-12 col-md-4 realtor__signupform__summary__title">
                                    REAL ESTATE LICENSE ID:
                                </div>
                                <div className="col-xs-12 col-md-8 realtor__signupform__summary__value">
                                    {licenseID}
                                </div>
                            </div>
                            <div className="row realtor__signupform__summary__field-container">
                                <div className="col-xs-12 col-md-4 realtor__signupform__summary__title">
                                    LICENSED IN:
                                </div>
                                <div className="col-xs-12 col-md-8 realtor__signupform__summary__value">
                                    {licensedIn}
                                </div>
                            </div>
                            <div className="row realtor__signupform__summary__field-container">
                                <div className="col-xs-12 col-md-4 realtor__signupform__summary__title">
                                    LICENSED AS:
                                </div>
                                <div className="col-xs-12 col-md-8 realtor__signupform__summary__value">
                                    {`${licensedAs} (${fullTime})`}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 realtor__signupform__summary__container">
                            <p className="realtor__signupform__summary__main-title">
                                Brokerage:
                            </p>
                            <p className="realtor__signupform__summary__main-title main-label">
                                {brokerageName}
                            </p>
                            <div className="realtor__signupform__summary__separator-line" />
                            <div className="row realtor__signupform__summary__field-container">
                                <div className="col-xs-12 col-md-4 realtor__signupform__summary__title">
                                    BROKER NAME:
                                </div>
                                <div className="col-xs-12 col-md-8 realtor__signupform__summary__value">
                                    {brokerName}
                                </div>
                            </div>
                            <div className="row realtor__signupform__summary__field-container">
                                <div className="col-xs-12 col-md-4 realtor__signupform__summary__title">
                                    BROKER PHONE:
                                </div>
                                <div className="col-xs-12 col-md-8 realtor__signupform__summary__value">
                                    {brokerPhone}
                                </div>
                            </div>
                            <div className="row realtor__signupform__summary__field-container">
                                <div className="col-xs-12 col-md-4 realtor__signupform__summary__title">
                                    BROKER EMAIL:
                                </div>
                                <div className="col-xs-12 col-md-8 realtor__signupform__summary__value">
                                    {brokerEmail}
                                </div>
                            </div>
                            {this.buildBrokerageAcknowledgmentCheckbox(
                                brokerage
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
