import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { Link } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import { Button } from '../Button/Button';

import './agentMatchCardModal.css';

export default class AgentMatchCardModal extends Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        closeConfirmationModal: PropTypes.func,
        firstName: PropTypes.string,
        onChooseAgent: PropTypes.func,
    };

    confirmOperation = () => {
        this.props.closeConfirmationModal();
        if (isFunction(this.props.onChooseAgent)) {
            this.props.onChooseAgent();
        }
    };

    render = () => (
        <Fragment>
            <Modal
                className="agent-match-card-modal"
                animation
                autoFocus
                backdrop
                enforceFocus
                show={this.props.isVisible}
                onHide={this.props.closeConfirmationModal}
            >
                <ModalHeader className="declining-modal__header">
                    <button
                        type="button"
                        className="btn-link declining-modal__close-button"
                        onClick={this.props.closeConfirmationModal}
                    >
                        <i className="fa fa-times-circle" />
                    </button>
                </ModalHeader>
                <ModalBody>
                    <div className="agent-match-card-modal__title">
                        Confirm agent?
                    </div>
                    <p className="agent-match-card-modal__content">
                        {`You understand that by clicking on the I AGREE button immediately following this notice, 
                        you are providing ‘written instructions’ to ${this.props.firstName} under the Fair Credit 
                        Reporting Act authorizing ${this.props.firstName} to obtain your rebate amount, 
                        determined by your BrewScore.  You authorize ${this.props.firstName} to obtain such 
                        information solely to determine if they will take you on as one of their client.`}
                    </p>
                    <div className="text-center agent-match-card-modal__buttons">
                        <Link
                            className="agent-match-card-modal__link"
                            to="/profile#agent"
                        >
                            <Button
                                onClick={this.confirmOperation}
                                className="agent-match-card-modal__confirm-button"
                            >
                                I AGREE
                            </Button>
                        </Link>
                        <Button
                            design="default-inverted"
                            onClick={this.props.closeConfirmationModal}
                            className="agent-match-card-modal__cancel-button"
                        >
                            CANCEL
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
}
