import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import DecliningModal from '../DecliningModal/DecliningModal';
import RealtorPendingMatchItem from '../RealtorPendingMatchItem/RealtorPendingMatchItem';

export default class RealtorProfilePendingBuyerMatchesTab extends React.PureComponent {
    buildPendingMatch = (pendingMatch, key) => {
        const { isLoadingAcceptMatchingService } = this.props;
        const id = get(pendingMatch, 'userInfo.id');
        return (
            <RealtorPendingMatchItem
                key={key}
                picture={get(pendingMatch, 'userInfo.profilePicUrl')}
                name={invoke(this.props, 'buildPendingMatchFullName', pendingMatch)}
                location={invoke(this.props, 'buildPendingMatchLocation', pendingMatch)}
                budget={invoke(this.props, 'buildPendingMatchBudget', pendingMatch)}
                maxBudget={invoke(this.props, 'buildMatchMaxBudget', pendingMatch)}
                rebate={get(pendingMatch, 'rebatePercentage')}
                onAcceptMatch={() => invoke(this.props, 'onAcceptMatch', get(pendingMatch, 'userInfo.id'))}
                onRejectMatch={() => invoke(this.props, 'onRejectMatch', get(pendingMatch, 'userInfo.id'))}
                isLoading={isLoadingAcceptMatchingService[id]}
            />
        );
    };

    render = () => (
        <div className="realtor-profile-pending-matches-tab__content">
            <DecliningModal
                isVisible={this.props.isRejectMatchModalVisible}
                buyerMatchDeclinedOptions={this.props.buyerMatchDeclinedOptions}
                onClose={this.props.onCloseRejectMatchModal}
                onDeclineMatch={this.props.onDeclineServiceMatch}
                onSelectRejection={this.props.onSelectRejection}
            />
            {map(this.props.buyerMatchRequests, this.buildPendingMatch)}
        </div>
    );
}