import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../Spinner/Spinner';

export const GlobalLoaderComponent = ({ isVisible, children }) => {
    if (!isVisible) return children;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0'
        }}>
            <Spinner className="immediate"/>
        </div>
    );
};

const GlobalLoader = connect(
    (state) => ({ isVisible: state.globalLoader.isVisible }),
)(GlobalLoaderComponent);

export default GlobalLoader;
