import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import DecliningModal from '../DecliningModal/DecliningModal';
import RealtorPendingSellerMatchItem from '../RealtorPendingSellerMatchItem/RealtorPendingSellerMatchItem';

export default class RealtorProfilePendingSellerMatchesTab extends React.PureComponent {
    buildPendingMatch = (pendingMatch, key) => {
        const { isLoadingAcceptMatchingService } = this.props;
        const id = get(pendingMatch, 'userInfo.id');
        return (
            <RealtorPendingSellerMatchItem
                key={key}
                picture={get(pendingMatch, 'userInfo.profilePicUrl')}
                name={'Selling:'}
                location={get(pendingMatch, 'activeProperty.address.full')}
                homeValueLow={get(pendingMatch, 'activeProperty.homeValueLow')}
                homeValueHigh={get(pendingMatch, 'activeProperty.homeValueHigh')}
                timeFrame={get(pendingMatch, 'activeProperty.sellingTimeline')}
                onAcceptMatch={() => invoke(this.props, 'onAcceptMatch', get(pendingMatch, 'userInfo.id'))}
                onRejectMatch={() => invoke(this.props, 'onRejectMatch', get(pendingMatch, 'userInfo.id'))}
                isLoading={isLoadingAcceptMatchingService[id]}
            />
        );
    };

    render = () => (
        <div className="realtor-profile-pending-matches-tab__content">
            <DecliningModal
                isVisible={this.props.isRejectMatchModalVisible}
                buyerMatchDeclinedOptions={this.props.sellerMatchDeclinedOptions}
                onClose={this.props.onCloseRejectMatchModal}
                onDeclineMatch={this.props.onDeclineServiceMatch}
                onSelectRejection={this.props.onSelectRejection}
            />
            {map(this.props.sellerMatchRequests, this.buildPendingMatch)}
        </div>
    );
}