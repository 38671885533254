import React from 'react';
import PropTypes from 'prop-types';

import './realtorMatchItemSummary.css';

export default class RealtorMatchItemSummary extends React.PureComponent {
    static propTypes = {
        icon: PropTypes.string.isRequired,
        label: PropTypes.node.isRequired,
        value: PropTypes.node.isRequired,
    };

    buildIcon = () => (
        this.props.icon && <img className="realtor-profile-item-summary__icon--size" src={this.props.icon} alt={this.props.label} />
    );

    render = () => (
        <div className="realtor-profile-item-summary__row">
            <div className="realtor-profile-item-summary__icon">
                {this.buildIcon()}
            </div>
            <div className="realtor-profile-item-summary__label">
                {this.props.label}
            </div>
            <div className="realtor-profile-item-summary__value">{this.props.value}</div>
        </div>
    );
}
