import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import { Link } from "react-router-dom";
import { Button } from "../../Button/Button";

import "./adminRoleTables.css";

export default class StatsTable extends React.Component {
    buildHeaderList = () => (
        <div className="row admin-role-tables__header">
            <div className="col-xs-3">STATE</div>
            <div className="col-xs-3 admin-role-tables__header-center">
                NEW AGENTS
            </div>
            <div className="col-xs-4 admin-role-tables__header-center">
                APPROVED (SIGNED AGREEMENT)
            </div>
            <div className="col-xs-2 admin-role-tables__header-center">
                NOT APPROVED
            </div>
        </div>
    );

    buildFirstRow = (stat, state) => (
        <div className="admin-role-tables__item-row stats">
            <div className="col-xs-3">{state}</div>
            <div className="col-xs-3 admin-role-tables__stat-all">
                <Link to={"/admin-profile"}>
                    <Button
                        small
                        text
                        className=" admin-role-tables__stat-link"
                        onClick={() => this.props.onSearchParams({ state })}
                    >
                        {get(stat, "all")}
                    </Button>
                </Link>
            </div>
            <div className="col-xs-4 admin-role-tables__stat-approved">
                <Link to={"/admin-profile"}>
                    <Button
                        small
                        text
                        className=" admin-role-tables__stat-link"
                        onClick={() =>
                            this.props.onSearchParams({ state, approved: true })
                        }
                    >
                        {get(stat, "approved")}
                    </Button>
                </Link>
            </div>
            <div className="col-xs-2 admin-role-tables__stat-nonapproved">
                <Link to={"/admin-profile"}>
                    <Button
                        small
                        text
                        className=" admin-role-tables__stat-link"
                        onClick={() =>
                            this.props.onSearchParams({
                                state,
                                approved: false
                            })
                        }
                    >
                        {get(stat, "nonApproved")}
                    </Button>
                </Link>
            </div>
        </div>
    );

    buildContentList = () => (
        <div className="admin-role-tables__body">
            {map(this.props.stats, (stat, index) => {
                return (
                    <div className="admin-role-tables__item" key={index}>
                        <div className="admin-role-tables__stat">
                            {this.buildFirstRow(stat, index)}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    render = () => (
        <div className="admin-role-tables__stats">
            {this.buildHeaderList()}
            {this.buildContentList()}
        </div>
    );
}

StatsTable.propTypes = {
    dateFormat: PropTypes.string,
    stats: PropTypes.any,
    onSearchParams: PropTypes.func
};
