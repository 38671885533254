import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import trim from "lodash/trim";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import classnames from "classnames";
import { Marker, InfoWindow } from "react-google-maps";
import { GoogleMaps } from "../GoogleMaps/GoogleMaps";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AutoCompleteInput from "../AutoCompleteInput/AutoCompleteInput";
import RealtorBiographyField from "../RealtorBiographyField/RealtorBiographyField";
import RealtorNameField from "../RealtorNameField/RealtorNameField";
import RealtorAgentInformation from "../RealtorAgentInformation/RealtorAgentInformation";
import BrokerageLabel from "../BrokerageLabel/BrokerageLabel";
import RealtorServingInField from "../RealtorServingInField/RealtorServingInField";
import RealtorExperienceField from "../RealtorExperienceField/RealtorExperienceField";
import SocialMedia from "../SocialMedia/SocialMedia";

import "./realtorprofilecontent.css";

const SELECT_PROPERTIES_TYPE_OPTION = [
    {
        id: "saleAddresses",
        displayName: "Sales"
    },
    {
        id: "listingAddresses",
        displayName: "Listings"
    }
];

const googleMapsGreenDot =
    "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
const googleMapsYellowDot =
    "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";

class RealtorProfileContent extends Component {
    static propTypes = {
        profile: PropTypes.any,
        isPublicView: PropTypes.bool,
        onUpdateField: PropTypes.func.isRequired,
        isEditing: PropTypes.bool,
        onEdit: PropTypes.func,
        canEditAgentName: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = this.getInitState();
    }

    getInitState = () => ({
        selectedMapOption: {
            label: "Sales",
            value: "saleAddresses"
        },
        makersList: get(this.props.profile, "publicProfile.saleAddresses")
    });

    buildMarkers = () => {
        const { makersList, selectedMapOption } = this.state;
        return map(makersList, (item, key) => {
            const streetNumberName = get(item, "streetNumberName");
            const lat = get(item, "latitude");
            const lng = get(item, "longitude");
            const iconStyle =
                get(selectedMapOption, "value") === "saleAddresses"
                    ? googleMapsGreenDot
                    : googleMapsYellowDot;
            return (
                <Marker
                    key={key}
                    title={streetNumberName}
                    name="Location"
                    position={{ lat, lng }}
                    options={{ icon: iconStyle }}
                >
                    <InfoWindow>
                        <div className="realtor-profile-content__google-maps-marker">
                            {streetNumberName}
                        </div>
                    </InfoWindow>
                </Marker>
            );
        });
    };

    getOptions = () =>
        map(SELECT_PROPERTIES_TYPE_OPTION, option => ({
            label: option.displayName,
            value: option.id
        }));

    onChange = option =>
        this.setState({
            selectedMapOption: option,
            makersList: get(this.props.profile, `publicProfile.${option.value}`)
        });

    soldHomesCopy = () => {
        const firstName = get(
            this.props.profile,
            "publicProfile.userInfo.firstName"
        );
        const option = get(this.state.selectedMapOption, "value");
        switch (option) {
            case "listingAddresses":
                return `${firstName}'s Listings`;
            case "saleAddresses":
                return `${firstName}'s Sold Homes`;
            default:
                return `${firstName}'s Transactions`;
        }
    };

    buildSoldHomesMap = () => {
        const { profile } = this.props;
        const servingIn = get(profile, "publicProfile.saleAddresses[0]");
        const servingInLat = get(servingIn, "latitude") || 0;
        const servingInLng = get(servingIn, "longitude") || 0;
        return (
            <div className="realtor-profile-content__card-container">
                <h2 className="realtor-profile-content__sold-homes-title">
                    {this.soldHomesCopy()}
                    <div className="pull-right realtor-profile-content__sold-homes-select">
                        <AutoCompleteInput
                            placeholder="All Properties"
                            getOptions={this.getOptions}
                            onChange={this.onChange}
                            value={this.state.selectedMapOption}
                            initializeGettingOptions
                        />
                    </div>
                </h2>
                <div className="realtor-profile-content__map">
                    <GoogleMaps
                        height="100%"
                        center={{ lat: servingInLat, lng: servingInLng }}
                        zoom={11}
                        isMarkerShown
                    >
                        {this.buildMarkers()}
                    </GoogleMaps>
                </div>
            </div>
        );
    };

    buildBiography = () => {
        const { profile, isPublicView, onUpdateField } = this.props;
        const biographyField = "publicProfile.aboutMe";
        const biography = get(profile, biographyField);
        return (
            <div className="biography-container">
                <RealtorBiographyField
                    isEditing={this.props.isEditing}
                    canEdit={!isPublicView}
                    biography={biography}
                    onEdit={newBiography =>
                        onUpdateField(biographyField, newBiography)
                    }
                />
            </div>
        );
    };

    onEditFirstName = newFirstName => {
        const { onUpdateField } = this.props;
        const firstNameField = "publicProfile.userInfo.firstName";
        onUpdateField(firstNameField, newFirstName);
    };

    onEditLastName = newLastName => {
        const { onUpdateField } = this.props;
        const lastNameField = "publicProfile.userInfo.lastName";
        onUpdateField(lastNameField, newLastName);
    };

    buildNameField = () => {
        const { profile, isPublicView, canEditAgentName } = this.props;
        const firstNameField = "publicProfile.userInfo.firstName";
        const firstName = get(profile, firstNameField);
        const lastNameField = "publicProfile.userInfo.lastName";
        const lastName = get(profile, lastNameField);
        const isBrokerageApproved = get(
            profile,
            "publicProfile.brokerage.approved"
        );

        return (
            <div className="agent-profile-name-field">
                <RealtorNameField
                    canEdit={!isPublicView && canEditAgentName}
                    firstName={firstName}
                    lastName={lastName}
                    onEditFirstName={this.onEditFirstName}
                    onEditLastName={this.onEditLastName}
                    isEditing={this.props.isEditing}
                />
                <BrokerageLabel
                    isApproved={isBrokerageApproved}
                    showApprovalStatusIcon={!isPublicView}
                />
            </div>
        );
    };

    getLinkedInUrl = () => get(this.props.profile, "publicProfile.linkedInUrl");

    getIgUrl = () => get(this.props.profile, "publicProfile.igUrl");

    getFacebookUrl = () => get(this.props.profile, "publicProfile.facebookUrl");

    getYoutubeUrl = () => get(this.props.profile, "publicProfile.youtubeUrl");

    buildAgentBasicInformation = () => {
        const { profile, isPublicView, onUpdateField, isEditing } = this.props;
        const jobName =
            trim(get(profile, "publicProfile.position.name")) ||
            "Real Estate Agent";
        const brokerageName = get(profile, "publicProfile.brokerage.name");
        const isBrokerageApproved = get(
            profile,
            "publicProfile.brokerage.approved"
        );
        const servingInField = "publicProfile.saleAddresses";
        const servingInData = get(profile, servingInField);
        const servingIn = orderBy(servingInData, [item => item.city], ["asc"]);
        const licenseId = trim(get(profile, "publicProfile.licenseId"));

        const experienceField = "publicProfile.yearsOfExperience";
        const experience = get(profile, experienceField) || 0;
        const licenseTooltip = (
            <Tooltip id="realtor-profile-switch__tooltip">
                To make changes to this field please email
                agenthelp@lemonbrew.com
            </Tooltip>
        );
        const cssRealtorProfileName = classnames({
            "realtor-profile-content__name--editing": isEditing,
            "realtor-profile-content__name": !isEditing
        });

        return (
            <div className="realtor-profile-content__basic-info-container">
                <div className="realtor-profile-content__name-job-container">
                    <div className={cssRealtorProfileName}>
                        {this.buildNameField()}
                        <div className="realtor-profile-content__media">
                            <SocialMedia
                                linkedInUrl={this.getLinkedInUrl()}
                                igUrl={this.getIgUrl()}
                                facebookUrl={this.getFacebookUrl()}
                                youtubeUrl={this.getYoutubeUrl()}
                                edit={isEditing}
                                onEdit={onUpdateField}
                            />
                        </div>
                    </div>
                    <div className="realtor-profile-content__job">
                        {jobName} at{" "}
                        <BrokerageLabel
                            name={brokerageName}
                            isApproved={isBrokerageApproved}
                        />
                        {!isPublicView ? (
                            <OverlayTrigger
                                placement="top"
                                overlay={licenseTooltip}
                            >
                                <i className="fa fa-info-circle" />
                            </OverlayTrigger>
                        ) : null}
                    </div>

                    <div className="info-container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <span className="section__title">
                                    How can I help you:
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <RealtorServingInField
                                    isEditing={isEditing}
                                    canEdit={!isPublicView}
                                    value={servingIn}
                                    onEdit={newServingIn =>
                                        onUpdateField(
                                            servingInField,
                                            newServingIn
                                        )
                                    }
                                />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-3">
                                <RealtorExperienceField
                                    isEditing={isEditing}
                                    canEdit={!isPublicView}
                                    experience={experience}
                                    onEdit={newExperience =>
                                        onUpdateField(
                                            experienceField,
                                            newExperience
                                        )
                                    }
                                />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-3">
                                <h5 className="realtor-agent-information__basic-info-title">
                                    Real Estate License #:
                                </h5>
                                <div className="realtor-agent-information__basic-info">
                                    {licenseId}{" "}
                                    {!isPublicView ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={licenseTooltip}
                                        >
                                            <i className="fa fa-info-circle" />
                                        </OverlayTrigger>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RealtorAgentInformation
                    isPublicView={isPublicView}
                    profile={profile}
                    onUpdateField={onUpdateField}
                    isEditing={isEditing}
                />
            </div>
        );
    };

    render = () => {
        const { isPublicView } = this.props;
        return (
            <Fragment>
                {this.buildAgentBasicInformation()}
                {this.buildBiography()}
                {isPublicView && this.buildSoldHomesMap()}
            </Fragment>
        );
    };
}

export default RealtorProfileContent;
