import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import trim from "lodash/trim";
import { Button } from "../Button/Button";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ProfilePicture } from "../ProfilePicture/ProfilePicture";
import RealtorVideoProfileButton from "../RealtorVideoProfileButton/RealtorVideoProfileButton";
import RealtorAvgField from "../RealtorAvgField/RealtorAvgField";
import {
    MIN_VALUE_AVG_SALE_PRICE,
    MAX_VALUE_AVG_SALE_PRICE,
    MIN_VALUE_AVG_BEDROOMS,
    MAX_VALUE_AVG_BEDROOMS,
    MIN_VALUE_PROPERTIES_SOLD,
    MAX_VALUE_PROPERTIES_SOLD
} from "../../shared/constants";
import RealtorProfileAvailableSwitch from "../RealtorProfileAvailableSwitch/RealtorProfileAvailableSwitch";

import "./realtorprofilesidecolumn.css";

const buildAgentPicture = props => {
    const { profile, isPublicView, onUploadPicture } = props;
    const picture = get(profile, "publicProfile.userInfo.profilePicUrl");

    return (
        <div>
            <ProfilePicture
                picture={picture}
                canEdit={!isPublicView}
                onUploadPicture={onUploadPicture}
            />
        </div>
    );
};

buildAgentPicture.propTypes = {
    isPublicView: PropTypes.any.isRequired,
    profile: PropTypes.string.isRequired,
    onUploadPicture: PropTypes.func.isRequired,
    isEditing: PropTypes.bool
};

class RealtorContactButton extends Component {
    render() {
        const { profile } = this.props;

        const popoverTitle = (
            <Fragment>
                <b>Contact Real Estate Agent!</b>
                <i
                    className="fa fa-times-circle pull-right"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.refs.overlay.hide()}
                />
            </Fragment>
        );

        const phone = get(profile, "publicProfile.userInfo.phone");
        const email = get(profile, "publicProfile.userInfo.email");
        const contactTooltipContent = (
            <Popover
                id="agent-contact-info-tooltip"
                title={popoverTitle}
                style={{ maxWidth: "none" }}
            >
                Email address: <a href={`mailto:${email}`}>{email}</a>
                <br />
                Phone number: <a href={`tel:${phone}`}>{phone}</a>
            </Popover>
        );

        const firstName = get(profile, "publicProfile.userInfo.firstName");
        return (
            <OverlayTrigger
                ref="overlay"
                placement="top"
                trigger="click"
                overlay={contactTooltipContent}
            >
                <Button small>Contact {firstName}</Button>
            </OverlayTrigger>
        );
    }
}

const buildSideColumnActionButtons = props => {
    const {
        profile,
        onUpdateField,
        isPublicView,
        isEditing,
        showAcceptButton,
        showContactButton,
        openConfirmationModal,
        isImpersonatingUser
    } = props;
    const videoProfileUrl = get(profile, "publicProfile.profileVideoUrl");

    const acceptButton = showAcceptButton ? (
        <Button small onClick={openConfirmationModal}>
            Accept
        </Button>
    ) : null;

    const contactButton =
        showContactButton && !acceptButton ? (
            <RealtorContactButton {...props} />
        ) : null;

    const videoProfileButton = (
        <RealtorVideoProfileButton
            isEditing={isEditing}
            videoUrl={videoProfileUrl}
            canEdit={!isPublicView}
            isImpersonatingUser={isImpersonatingUser}
            onUpdateField={onUpdateField}
            className="realtor-profile-side-column__button-video-profile button--small"
        />
    );

    const switchAvailable = (
        <RealtorProfileAvailableSwitch
            profile={profile}
            onUpdateField={onUpdateField}
        />
    );

    return (
        <div className="realtor-profile-side-column__button-container">
            {isPublicView && acceptButton}
            {isPublicView && contactButton}
            {!isPublicView && switchAvailable}
            {videoProfileButton}
        </div>
    );
};

buildSideColumnActionButtons.propTypes = {
    isPublicView: PropTypes.any.isRequired,
    profile: PropTypes.string.isRequired,
    showAcceptButton: PropTypes.bool.isRequired,
    showContactButton: PropTypes.bool.isRequired,
    openConfirmationModal: PropTypes.bool.isRequired,
    isImpersonatingUser: PropTypes.bool,
    onUpdateField: PropTypes.func.isRequired,
    isEditing: PropTypes.func,
    displayErrors: PropTypes.bool
};

const buildAverages = props => {
    const {
        profile,
        isPublicView,
        onUpdateField,
        isEditing,
        displayErrors
    } = props;
    const avgSalePriceField = "publicProfile.averageSalePrice";
    const avgSalePriceAmount =
        Number(get(profile, `${avgSalePriceField}.amount`)) || 0;
    const avgSalePriceCurrency = trim(
        get(profile, `${avgSalePriceField}.currency`)
    );
    const avgSalePrice = {
        amount: avgSalePriceAmount,
        currency: avgSalePriceCurrency
    };
    const avgBedRoomsField = "publicProfile.averageNumberOfBedrooms";
    const avgBedRooms = Number(get(profile, avgBedRoomsField)) || 0;
    const numberOfPropertiesSold =
        Number(get(profile, "publicProfile.numberOfPropertiesSold")) || 0;
    const numberOfPropertiesSoldField = "publicProfile.numberOfPropertiesSold";
    return (
        <div className="realtor-profile-side-column__avg-container">
            <div
                id="realtor-tour-properties-sold"
                className="realtor-profile-side-column__avg-field"
            >
                <RealtorAvgField
                    displayErrors={displayErrors}
                    isEditing={isEditing}
                    fieldLabel="Properties sold:"
                    fieldProp="numberOfPropertiesSold"
                    format="decimal"
                    value={numberOfPropertiesSold}
                    minValue={MIN_VALUE_PROPERTIES_SOLD}
                    maxValue={MAX_VALUE_PROPERTIES_SOLD}
                    canEdit={!isPublicView}
                    onChange={newNumberPropertiesSold =>
                        onUpdateField(
                            numberOfPropertiesSoldField,
                            newNumberPropertiesSold
                        )
                    }
                    maskType="number"
                />
            </div>
            <div
                id="realtor-tour-avg-sale-price"
                className="realtor-profile-side-column__avg-field"
            >
                <RealtorAvgField
                    displayErrors={displayErrors}
                    isEditing={isEditing}
                    fieldLabel="AVG. Home Sale Price"
                    fieldProp="averageSalePrice"
                    format="currency"
                    value={avgSalePrice}
                    minValue={MIN_VALUE_AVG_SALE_PRICE}
                    maxValue={MAX_VALUE_AVG_SALE_PRICE}
                    canEdit={!isPublicView}
                    onChange={newAvgSalePrice =>
                        onUpdateField(avgSalePriceField, newAvgSalePrice)
                    }
                    maskType="amount"
                    placeholder="$"
                />
            </div>
            <div
                id="realtor-tour-avg-number-beedrooms"
                className="realtor-profile-side-column__avg-field"
            >
                <RealtorAvgField
                    displayErrors={displayErrors}
                    isEditing={isEditing}
                    fieldLabel="AVG. Number Of BedRooms"
                    fieldProp="averageNumberOfBedrooms"
                    format="decimal"
                    value={avgBedRooms}
                    minValue={MIN_VALUE_AVG_BEDROOMS}
                    maxValue={MAX_VALUE_AVG_BEDROOMS}
                    canEdit={!isPublicView}
                    onChange={newAvgBedRooms =>
                        onUpdateField(avgBedRoomsField, newAvgBedRooms)
                    }
                    maskType="number"
                />
            </div>
        </div>
    );
};

buildAverages.propTypes = {
    isPublicView: PropTypes.any.isRequired,
    profile: PropTypes.string.isRequired,
    onUpdateField: PropTypes.func.isRequired,
    isEditing: PropTypes.bool
};

const RealtorProfileSideColumn = props => (
    <div>
        {buildAgentPicture(props)}
        {buildSideColumnActionButtons(props)}
        {buildAverages(props)}
    </div>
);

export default RealtorProfileSideColumn;
