import React, { Fragment } from "react";
import { Field } from "formik";
import get from "lodash/get";
import { purchaseTimelinesSeller } from "../../../../shared/constants";
import FormikBuyerFlowRadioButton from "../../../BuyerFlowButton/FormikBuyerFlowRadioButton";
import BaseWizardStep from "../../BuyerSignUp/BaseWizardStep";
import mixpanel from "mixpanel-browser";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";

class StepTimeline extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        mixpanel.track("Completed How Soon Are You Looking To Buy", {
            purchaseTimeline: get(values, "purchaseTimeline")
        });
    };

    stepTimelineNext = () => {
        setTimeout(
            () => this.props.onNavigateStep(1, true, this.mixpanelFunction),
            0
        );
    };

    render() {
        const isStepFulfilled = !!this.props.values.purchaseTimeline;
        const hasMixPanelTracking = true;

        return (
            <Fragment>
                <ScrollToTopOnMount />
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        How soon are you looking to sell?
                    </div>

                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field align-center">
                                <Field
                                    name="purchaseTimeline"
                                    itemClassName="wider"
                                    options={purchaseTimelinesSeller}
                                    component={FormikBuyerFlowRadioButton}
                                    optionContainerClassName="buyer__signup__timeline-field"
                                />

                                {this.renderFieldError("purchaseTimeline")}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    handleNext: this.stepTimelineNext
                })}
            </Fragment>
        );
    }
}

export default StepTimeline;
