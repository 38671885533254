import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';

export default class LocationAutoCompleteInput extends Component {
    static propTypes = {
        value: PropTypes.any,
        isMulti: PropTypes.bool,
        getOptionsMillisecondsDelay: PropTypes.number,
        onChange: PropTypes.func,
        hasError: PropTypes.bool,
        components: PropTypes.any,
        isDisabled: PropTypes.bool,
        types: PropTypes.array
    };

    static defaultProps = {
        types: ['geocode']
    };

    getService = () => {
        if (!this.service && window.google) {
            this.service = new window.google.maps.places.AutocompleteService();
        }

        return this.service;
    }

    getLocations = (term) => new Promise((resolve, reject) => {
        const { types } = this.props;
        const service = this.getService();

        if (!service) {
            reject();
            return;
        }

        const params = {
            input: term,
            componentRestrictions: { country: ['us'] }
        };

        if (types) {
            params.types = types;
        }

        service.getPlacePredictions(params, (predictions, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                reject(status);
            } else {
                resolve(this.getPredictionsAsOptions(predictions));
            }
        });
    });

    getPredictionsAsOptions = (predictions) => map(predictions, (prediction) => {
        const description = get(prediction, 'description');
        return {
            value: prediction,
            label: description,
        };
    });

    render = () => (
        <AutoCompleteInput
            isMulti={this.props.isMulti}
            getOptions={this.getLocations}
            getOptionsMillisecondsDelay={this.props.getOptionsMillisecondsDelay}
            openMenuOnClick={false}
            value={this.props.value}
            onChange={this.props.onChange}
            hasError={this.props.hasError}
            components={this.props.components}
            isDisabled={this.props.isDisabled}
            placeholder={this.props.placeholder}
        />
    );
}
