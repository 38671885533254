import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import toLower from 'lodash/toLower';
import React from 'react';

import './RadioField.css';

export default ({ field, form, options = [], itemClassName = '', ...props }) =>
    <div className={`fico__radio__field ${props.className || ''}`}>{
        map(options, option => {
            const [label, description] = option.label.split(/\n/);
            return (
                <div
                    key={option.value.id}
                    className={`fico__radio__field__item for-${toLower(label)} ${itemClassName} ${isEqual(field.value, option.value) ? 'is-active' : ''}`}
                    onClick={() => {
                        form.setFieldValue(field.name, option.value);
                        form.setFieldTouched(field.name)
                    }}>
                    <div className="fico__radio__field__icon"/>
                    <div className="fico__radio__field__label">{label}</div>
                    <div className="fico__radio__field__description">{description}</div>
                </div>
            );
        })
    }</div>;
