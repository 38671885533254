import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './customrealtorbiographyfield.css';

export default class CustomRealtorBiographyField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            biography: props.biography || '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.biography !== prevState.biography) {
            this.props.onEdit(this.state.biography);
        }
    }

    onChange = (event) => {
        this.setState({ biography: event.target.value });
    };

    buildEditableField = () => (
        <textarea
            className="form-control realtor-biography-field__field-editable"
            onChange={this.onChange}
            maxLength="2500"
            value={this.state.biography}
        />
    );

    render = () => {
        return (
            <div id="realtor-tour-biography" className="realtor-biography-field">
                {this.buildEditableField()}
            </div>
        );
    };
}

CustomRealtorBiographyField.propTypes = {
    biography: PropTypes.string,
    onEdit: PropTypes.func,
};
