import React from "react";
import AvatarEditor from "react-avatar-editor";
import picture from "../../../styles/assets/test-document-upload-success.png";
import "./preview-picture.css";

export default class PreviewPicture extends React.Component {
    constructor(props) {
        super(props);
        this.state = { rotate: 0 };
    }

    rotate = () => this.setState({ rotate: this.state.rotate + 90 });

    buildNotes = () => (
        <div className="emd-preview-picture">
            <div className="emd-preview-picture-icon">
                <img
                    src={picture}
                    alt=""
                    className="emd-preview-picture__example-picture"
                />
            </div>
            <div className="emd-preview-picture-list">
                <h5 class="emd-preview-picture__important">IMPORTANT!</h5>
                <ul class="emd-preview-picture__notes">
                    <li>Full name and ID # should be clearly visible.</li>
                    <li>ID cannot be cutoff from any side.</li>
                    <li>Image must be horizontal and legible.</li>
                    <li>The file must be either jpg, jpeg or png.</li>
                    <li>The file size needs to be less than 10MB.</li>
                </ul>
                <strong className="emd-preview-picture__final-note">
                    Otherwise the verification will be rejected.
                </strong>
            </div>
        </div>
    );

    buildRotateButton = () => (
        <button
            type="button"
            className="emd-preview-picture__action-rotate"
            onClick={this.rotate}
        >
            Rotate <i className="fa fa-undo" />
        </button>
    );

    buildRemoveButton = () => (
        <button
            type="button"
            className="emd-preview-picture__action-remove"
            onClick={this.props.onRemove}
        >
            Remove <i className="fa fa-trash" />
        </button>
    );

    buildPreview = () => (
        <div className="emd-preview-picture">
            <div className="text-center">
                <AvatarEditor
                    ref={this.props.onSetRef}
                    image={this.props.picture}
                    width={250}
                    height={150}
                    border={0}
                    color={[0, 0, 0, 0.5]}
                    scale={1}
                    rotate={this.state.rotate}
                />
            </div>
            <div className="emd-preview-picture__actions">
                {this.buildRemoveButton()}
                {this.buildRotateButton()}
            </div>
        </div>
    );

    render = () => {
        if (this.props.picture) return this.buildPreview();
        return this.buildNotes();
    };
}
