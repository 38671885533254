import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import invoke from 'lodash/invoke';
import { format } from 'date-fns';
import ContentModal from '../../ContentModal/ContentModal';
import TransfersTable from './TransfersTable';
import AvatarAndNameCol from './AvatarAndNameCol';
import SpinnerLoader from '../../SpinnerLoader/SpinnerLoader';
import { NumberFormat } from '../../NumberFormat/NumberFormat';
import { statusProspect } from '../../../shared/constants';
import classNames from 'classnames';
import '../userAdminProfile.css';

const earnestMoneyDepositType = 'earnest_money_deposit';

export default class ProspectTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisibleCompleteTransfer: false,
            receiptContentInHtml: null,
        };
    }

    buildEmdList = () => (
        <TransfersTable
            dateFormat={this.props.dateFormat}
            earnestMoneyDeposits={this.props.earnestMoneyDeposits}
        />
    );

    buildCompleteTransfer = () => {
        let content;
        let receipt;
        if(this.props.isFetchingTransferReceipt) {
            content = <SpinnerLoader />;
        }

        if(this.props.successTransferReceipt) {
            receipt = <div id="receipt" dangerouslySetInnerHTML={{ __html: this.state.receiptContentInHtml }}></div>
        }

        return (
            <>
                <div>{content}</div>
                {receipt}
            </>
        )
    }

    onOpenCompleteTransfer = (earnestMoneyDepositId, prospectId) => {
        Promise
        .resolve()
        .then(() => invoke(this.props, 'onCompleteTransfer', earnestMoneyDepositId, prospectId))
        .then((response) => this.setState({ receiptContentInHtml: response }));
        this.setState({ isVisibleCompleteTransfer: true })
    };

    onCloseCompleteTransfer = () => (this.setState({ isVisibleCompleteTransfer: false }));

    buildFirstRow = (user) => {
        const prospectId = get(user, 'publicProfile.userInfo.id');
        const phone = get(user, 'publicProfile.userInfo.phone');
        const phoneNum = `tel: ${phone}`;
        const firstEarnestMoneyDeposit = head(get(user, 'earnestMoneyDeposits'));
        const earnestMoneyDepositId = get(firstEarnestMoneyDeposit, 'id');
        const emdStatusName = get(firstEarnestMoneyDeposit, 'status.name');
        const typeEMDTransfer = get(firstEarnestMoneyDeposit, 'type.name') === earnestMoneyDepositType;

        const className = classNames(
            'transfer--status',
            get(statusProspect, `${emdStatusName}.className`),
        );
        const statusName = classNames(
            get(statusProspect, `${emdStatusName}.statusName`),
        )
        const isClickable = classNames({
            'is-complete': emdStatusName === 'complete',
            'is-clickable': typeEMDTransfer,
        });

        return (
            <div className="admin-role-tables__item-row admin-role-tables__prospect">
                <div className="row status-role__item-row--left prospect-status-plh">
                    {!isEmpty(get(user, 'earnestMoneyDeposits'))
                        ? (
                            <span
                                className={className}
                                onClick={emdStatusName === 'complete' && typeEMDTransfer ?
                                    () => this.onOpenCompleteTransfer(earnestMoneyDepositId, prospectId)
                                    : () => { }}
                            ><span className="transfer--name">{statusName}</span></span>
                        ) : null
                    }
                </div>
                {this.props.buildActionButton(user)}
                <div className="admin-role-tables__item-row-section-plh">
                    <div className="admin-role-tables__item-row-section-one">
                        <div className="admin-role-tables__prospect-title">TRANSFER MADE BY</div>
                        <AvatarAndNameCol user={get(user, 'publicProfile')} showEmail={true} />
                    </div>
                    <div className="admin-role-tables__item-row-section-two">
                        <div className="admin-role-tables__agent-phone admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__prospect-title">PHONE</div>
                            <a href={phoneNum}>
                                {phone}
                            </a>
                        </div>
                        <div className="admin-role-tables__item-without-padding admin-role-tables__timestamp admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__prospect-title">CREATED DATE</div>
                            {format(get(user, 'publicProfile.userInfo.ts'), this.props.dateFormat)}
                        </div>
                        <div className="admin-role-tables__agent-amount admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__prospect-title">AMOUNT</div>
                            {!isEmpty(get(user, 'earnestMoneyDeposits'))
                                ? (
                                    <NumberFormat
                                        className={isClickable}
                                        currency={'USD'}
                                        number={get(user, 'earnestMoneyDeposits[0].totalAmount')}
                                        format="currency"
                                        decimals={2}
                                        onClick={emdStatusName === 'complete' && typeEMDTransfer ?
                                            () => this.onOpenCompleteTransfer(earnestMoneyDepositId, prospectId)
                                            : () => { }}
                                    />
                                ) : <div>-</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    buildContentList = () => (
        <div className="admin-role-tables__body">
            {map(this.props.users, (user, index) => {
                return (
                    <div className="admin-role-tables__item" key={index}>
                        {this.buildFirstRow(user)}
                    </div>
                );
            })}
        </div>
    );

    render = () => (
        <div>
            <ContentModal
                isVisible={this.props.isVisibleViewTransfers}
                onClose={this.props.onCloseViewTransfers}
                content={this.buildEmdList()}
                className="admin-profile"
            />
            <ContentModal
                isVisible={this.state.isVisibleCompleteTransfer}
                onClose={this.onCloseCompleteTransfer}
                content={this.buildCompleteTransfer()}
                className="admin-role-tables__receipt-modal"
            />
            {this.buildContentList()}
        </div>
    );
}

ProspectTable.propTypes = {
    dateFormat: PropTypes.string,
    users: PropTypes.any,
    buildActionButton: PropTypes.func,
    onCompleteTransfer: PropTypes.func,
    isFetchingTransferReceipt: PropTypes.bool,
    successTransferReceipt: PropTypes.bool,
};
