import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import map from 'lodash/map';
import get from 'lodash/get';
import trim from 'lodash/trim';
import find from 'lodash/find';
import invoke from 'lodash/invoke';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import isFunction from 'lodash/isFunction';
import RealtorMatchProfile from '../RealtorMatchProfile/RealtorMatchProfile';
import RealtorInternalTab from '../RealtorInternalTab/RealtorInternalTab';
import RealtorProfileActiveBuyersMatchesTab from '../RealtorProfileActiveBuyersMatchesTab/RealtorProfileActiveBuyersMatchesTab';
import RealtorProfileActiveAllMatchesTab from '../RealtorProfileActiveAllMatchesTab/RealtorProfileActiveAllMatchesTab';
import RealtorProfileActiveSellersMatchesTab from '../RealtorProfileActiveSellersMatchesTab/RealtorProfileActiveSellersMatchesTab';
import './realtorprofileactivematchestab.css';

class RealtorProfileActiveMatchesTab extends Component {
    getSelectedBuyerProfile = () => find(
        this.props.buyersList,
        (item) => get(item, 'buyer.userInfo.id') === this.props.displayBuyerProfile,
    );

    getSelectedSellerProfile = () => find(
        this.props.sellersList,
        (item) => get(item, 'seller.userInfo.id') === this.props.displaySellerProfile,
    );

    getMatchPhone = (match) => get(match, 'buyer.userInfo.phone');

    getMatchId = (match) => get(match, 'buyer.userInfo.id');

    getSellerMatchId = (match) => get(match, 'seller.userInfo.id');

    getMatchPictureUrl = (match) => get(match, 'buyer.userInfo.profilePicUrl');

    getSellerMatchPictureUrl = (match) => get(match, 'seller.userInfo.profilePicUrl');

    getMatchEmail = (match) => get(match, 'buyer.userInfo.email');

    getMatchPhone = (match) => get(match, 'buyer.userInfo.phone');

    buildMatchFullName = (match) => {
        const firstName = trim(get(match, 'buyer.userInfo.firstName'));
        const lastName = trim(get(match, 'buyer.userInfo.lastName'));
        const fullName = trim(`${firstName} ${lastName}`);
        return fullName;
    };

    buildSellerMatchFullName = (match) => {
        const firstName = trim(get(match, 'seller.userInfo.firstName'));
        const lastName = trim(get(match, 'seller.userInfo.lastName'));
        const fullName = trim(`${firstName} ${lastName}`);
        return fullName;
    };

    buildMatchBudget = (match) => ({
        amount: get(match, 'buyer.budgetMin.amount'),
        currency: get(match, 'buyer.budgetMin.currency'),
    });

    buildSellerMatchBudget = (match) => get(match, 'seller.activeProperty.homeValueLow');

    buildSellerMatchMaxBudget = (match) => get(match, 'seller.activeProperty.homeValueHigh');

    getUserId = (match) => get(match, 'buyer.userInfo.id');

    buildMatchMaxBudget = (match) => ({
        amount: get(match, 'buyer.budgetMax.amount'),
        currency: get(match, 'buyer.budgetMax.currency'),
    });

    buildMatchDownPayment = (match) => ({
        amount: get(match, 'buyer.desiredDownPayment.amount'),
        currency: get(match, 'buyer.desiredDownPayment.currency'),
    });

    buildMatchVerifiedDownPayment = (match) => ({
        amount: get(match, 'buyer.verifiedDownPayment.amount'),
        currency: get(match, 'buyer.verifiedDownPayment.currency'),
    });

    buildMatchLoanAmount = (match) => ({
        amount: get(match, 'buyer.loanAmount.amount'),
        currency: get(match, 'buyer.loanAmount.currency'),
    });

    buildMatchMortgage = (match) => ({
        amount: get(match, 'buyer.mortgage.amount'),
        currency: get(match, 'buyer.mortgage.currency'),
    });

    getMatchNotes = (match) => get(match, 'notes');

    getMatchRebatePercentage = (match) => Number(get(match, 'buyer.rebatePercentage')) || 0;

    getMatchBrewScore = (match) => get(match, 'buyer.brewScore');

    getMatchStatus = (match) => trim(get(match, 'currentStatus.name'));

    getPropertyAddress = (match) => get(match, 'propertyAddress');

    getCommission = (match) => get(match, 'commission');

    getFinalPurchasePrice = (match) => get(match, 'finalPurchasePrice');

    getNewConstruction = (match) => get(match, 'newConstruction');

    getAnticipatedClosingDate = (match) => get(match, 'anticipatedClosingDate');

    onConfirmChangeStatus = (reason) => {
        const selectedProfile = this.getSelectedBuyerProfile();
        const buyerId = trim(get(selectedProfile, 'buyer.userInfo.id'));
        this.props.onConfirmChangeStatus(reason, buyerId);
    };

    onConfirmSellerChangeStatus = (reason) => {
        const selectedProfile = this.getSelectedSellerProfile();
        const sellerId = trim(get(selectedProfile, 'seller.userInfo.id'));
        this.props.onConfirmSellerChangeStatus(reason, sellerId);
    }

    formatFileSize = (sizeInBytes) => `${((Number(sizeInBytes) || 0) / 1000)} KB`;

    buildDocuments = (match) => map(
        get(match, 'documents'),
        (document) => ({
            id: get(document, 'id'),
            name: get(document, 'documentTypeDisplayName'),
            size: this.formatFileSize(get(document, 'fileSize')),
            date: get(document, 'ts'),
            url: get(document, 'url'),
        }),
    );

    buildLocation = (match) => {
        const newAddresses = get(match, 'buyer.newAddresses');
        return map(newAddresses, (location) => trim(get(location, 'city'))).join(', ');
    }

    buildSellerLocation = (match) => get(match, 'seller.activeProperty.address.full');

    buildCitiesBuyerIsLookingInto = (match) => {
        const newAddresses = get(match, 'buyer.newAddresses');
        return map(newAddresses, (location) => {
            const streetNumberName = trim(get(location, 'streetNumberName'));
            const apartmentSuiteNumber = trim(get(location, 'apartmentSuiteNumber'));
            const city = trim(get(location, 'city'));
            const state = trim(get(location, 'state'));
            return trim(`${streetNumberName} ${apartmentSuiteNumber} ${city} ${state}`);
        });
    };

    onUpdateLocationInformation = (data) => {
        const buyerId = get(this.getSelectedBuyerProfile(), 'buyer.userInfo.id');
        this.props.onUpdateLocationInformation(buyerId, data);
    }

    onUploadFile = (file, match) => {
        const { onUploadFile } = this.props;

        if (!isFunction(onUploadFile)) {
            return;
        }

        onUploadFile(file, match);
    };

    onUploadSellerFile = (file, match) => {
        const { onUploadSellerFile } = this.props;

        if (!isFunction(onUploadSellerFile)) {
            return;
        }

        onUploadSellerFile(file, match);
    };

    onRemoveFile = (file, match) => {
        const { onRemoveFile } = this.props;

        if (!isFunction(onRemoveFile)) {
            return;
        }

        onRemoveFile(file, match);
    };

    onRemoveSellerFile = (file, match) => {
        const { onRemoveSellerFile } = this.props;

        if (!isFunction(onRemoveSellerFile)) {
            return;
        }

        onRemoveSellerFile(file, match);
    };

    getTransactionContactTypesForBuyer = () => filter(
        this.props.transactionContactTypes,
        (contactType) => startsWith(get(contactType, 'name'), 'realtor_buyer')
    );

    buildViewProfile = () => {
        const selectedProfile = this.getSelectedBuyerProfile();

        return (
            <RealtorMatchProfile
                brokerageName={this.props.brokerageName}
                agentName={this.props.agentName}
                agentPhone={this.props.agentPhone}
                citiesBuyerLookingInto={this.buildCitiesBuyerIsLookingInto(selectedProfile)}
                id={this.getMatchId(selectedProfile)}
                picture={this.getMatchPictureUrl(selectedProfile)}
                name={this.buildMatchFullName(selectedProfile)}
                location={this.buildLocation(selectedProfile)}
                timeFrame={get(selectedProfile, 'buyer.purchaseTimeline.displayName')}
                budget={this.buildMatchBudget(selectedProfile)}
                maxBudget={this.buildMatchMaxBudget(selectedProfile)}
                desiredDownPayment={this.buildMatchDownPayment(selectedProfile)}
                verifiedDownPayment={this.buildMatchVerifiedDownPayment(selectedProfile)}
                loanAmount={this.buildMatchLoanAmount(selectedProfile)}
                mortgage={this.buildMatchMortgage(selectedProfile)}
                homeType={get(selectedProfile, 'buyer.homeType.displayName')}
                rebate={this.getMatchRebatePercentage(selectedProfile)}
                brewScore={this.getMatchBrewScore(selectedProfile)}
                idStatus={(get(selectedProfile, 'currentStatus.id'))}
                status={this.getMatchStatus(selectedProfile)}
                propertyAddress={this.getPropertyAddress(selectedProfile)}
                commission={this.getCommission(selectedProfile)}
                finalPurchasePrice={this.getFinalPurchasePrice(selectedProfile)}
                newConstruction={this.getNewConstruction(selectedProfile)}
                anticipatedClosingDate={this.getAnticipatedClosingDate(selectedProfile)}
                onClose={this.onCloseViewProfile}
                realtorStatusOptions={this.props.realtorStatusOptions}
                onConfirmChangeStatus={this.onConfirmChangeStatus}
                onSaveContactInfo={this.props.onSaveContactInfo}
                onSelectStatus={this.props.onSelectStatus}
                onUpdateLocationInformation={this.onUpdateLocationInformation}
                documents={this.buildDocuments(selectedProfile)}
                onUploadFile={(file) => this.onUploadFile(file, selectedProfile)}
                onUploadSelectTypeFile={this.props.onUploadSelectTypeFile}
                isUploading={this.props.isUploading}
                isFetchedUploading={this.props.isFetchedUploading}
                onRemoveFile={(file) => this.onRemoveFile(file, selectedProfile)}
                transactionContactTypes={this.getTransactionContactTypesForBuyer()}
                transactionContacts={get(selectedProfile, 'transactionContacts')}
                notes={this.getMatchNotes(selectedProfile)}
                buyerUserInfoId={get(selectedProfile, 'buyer.userInfo.id')}
                documentTypes={this.props.documentTypes}
                email={this.getMatchEmail(selectedProfile)}
                phone={this.getMatchPhone(selectedProfile)}
            />
        );
    };

    getTransactionContactTypesForSeller = () => filter(
        this.props.transactionContactTypes,
        (contactType) => startsWith(get(contactType, 'name'), 'realtor_seller')
    );

    buildSellerViewProfile = () => {
        const selectedProfile = this.getSelectedSellerProfile();
        const bedRooms = Number(get(selectedProfile, 'seller.activeProperty.totalBedrooms')) || 0;
        const bathRooms = Number(get(selectedProfile, 'seller.activeProperty.totalBaths')) || 0;
        const sellerId = get(selectedProfile, 'seller.userInfo.id');

        return (
            <RealtorMatchProfile
                isSeller
                bedRooms={bedRooms}
                bathRooms={bathRooms}
                brokerageName={this.props.brokerageName}
                agentName={this.props.agentName}
                agentPhone={this.props.agentPhone}
                id={this.getSellerMatchId(selectedProfile)}
                picture={this.getSellerMatchPictureUrl(selectedProfile)}
                name={this.buildSellerMatchFullName(selectedProfile)}
                location={this.buildSellerLocation(selectedProfile)}
                timeFrame={get(selectedProfile, 'seller.activeProperty.sellingTimeline')}
                budget={this.buildSellerMatchBudget(selectedProfile)}
                maxBudget={this.buildSellerMatchMaxBudget(selectedProfile)}
                idStatus={(get(selectedProfile, 'currentStatus.id'))}
                status={this.getMatchStatus(selectedProfile)}
                onClose={this.onCloseViewProfile}
                realtorStatusOptions={this.props.realtorSellerStatusOptions}
                onSelectStatus={this.props.onSelectSellerStatus}
                onConfirmChangeStatus={this.onConfirmSellerChangeStatus}
                documents={this.buildDocuments(selectedProfile)}
                onUploadFile={(file) => this.onUploadSellerFile(file, selectedProfile)}
                onUploadSelectTypeFile={this.props.onUploadSellerSelectTypeFile}
                isUploading={this.props.isUploadingSeller}
                isFetchedUploading={this.props.isSellerUploadSuccessfully}
                onRemoveFile={(file) => this.onRemoveSellerFile(file, selectedProfile)}
                notes={this.getMatchNotes(selectedProfile)}
                documentTypes={this.props.documentTypes}
                transactionContactTypes={this.getTransactionContactTypesForSeller()}
                onSaveContactInfo={this.props.onSaveSellerContactInfo}
                sellerUserInfoId={sellerId}
                onSaveSellerContactInfo={this.props.onSaveSellerContactInfo}
                transactionContacts={get(selectedProfile, 'transactionContacts')}
            />
        );
    };

    buildViewProfileForMatch = (match) => `/realtor/active-matches/view/${get(match, 'buyer.userInfo.id')}`;

    buildSellerViewProfileForMatch = (match) => `/realtor/active-matches/seller/view/${get(match, 'seller.userInfo.id')}`;

    buildInternalTabRoutes = () => {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/buyers`}>
                    <RealtorProfileActiveBuyersMatchesTab
                        buyersList={this.props.buyersList}
                        getMatchPictureUrl={this.getMatchPictureUrl}
                        buildMatchFullName={this.buildMatchFullName}
                        buildLocation={this.buildLocation}
                        buildMatchBudget={this.buildMatchBudget}
                        buildMatchMaxBudget={this.buildMatchMaxBudget}
                        getMatchRebatePercentage={this.getMatchRebatePercentage}
                        getMatchBrewScore={this.getMatchBrewScore}
                        getMatchStatus={this.getMatchStatus}
                        buildViewProfileForMatch={this.buildViewProfileForMatch}
                    />
                </Route>
                <Route path={`${this.props.match.path}/sellers`}>
                    <RealtorProfileActiveSellersMatchesTab
                        sellersList={this.props.sellersList}
                        getMatchPictureUrl={this.getSellerMatchPictureUrl}
                        buildMatchFullName={this.buildSellerMatchFullName}
                        buildLocation={this.buildSellerLocation}
                        buildMatchBudget={this.buildSellerMatchBudget}
                        buildMatchMaxBudget={this.buildSellerMatchMaxBudget}
                        getMatchStatus={this.getMatchStatus}
                        buildViewProfileForMatch={this.buildSellerViewProfileForMatch}
                    />
                </Route>
                <Route path={`${this.props.match.path}`}>
                    <RealtorProfileActiveAllMatchesTab
                        buyer={{
                            buyersList: this.props.buyersList,
                            getMatchPictureUrl: this.getMatchPictureUrl,
                            buildMatchFullName: this.buildMatchFullName,
                            buildLocation: this.buildLocation,
                            buildMatchBudget: this.buildMatchBudget,
                            buildMatchMaxBudget: this.buildMatchMaxBudget,
                            getMatchRebatePercentage: this.getMatchRebatePercentage,
                            getMatchBrewScore: this.getMatchBrewScore,
                            getMatchStatus: this.getMatchStatus,
                            buildViewProfileForMatch: this.buildViewProfileForMatch,
                        }}
                        seller={{
                            sellersList: this.props.sellersList,
                            getMatchPictureUrl: this.getSellerMatchPictureUrl,
                            buildMatchFullName: this.buildSellerMatchFullName,
                            buildLocation: this.buildSellerLocation,
                            buildMatchBudget: this.buildSellerMatchBudget,
                            buildMatchMaxBudget: this.buildSellerMatchMaxBudget,
                            getMatchStatus: this.getMatchStatus,
                            buildViewProfileForMatch: this.buildSellerViewProfileForMatch,
                        }}
                    />
                </Route>
            </Switch>
        );
    };

    buildInternalTabs = () => {
        const { history } = this.props;
        const pathname = get(history, 'location.pathname');
        return (
            <RealtorInternalTab tabs={[
                {
                    label: 'all',
                    isSelected: pathname === "/realtor/active-matches",
                    onClick: () => invoke(this.props, 'onChangeTab', 'active-matches')
                },
                {
                    label: 'buyers',
                    isSelected: pathname === "/realtor/active-matches/buyers",
                    onClick: () => invoke(this.props, 'onChangeTab', 'active-matches/buyers')
                },
                {
                    label: 'sellers',
                    isSelected: pathname === "/realtor/active-matches/sellers",
                    onClick: () => invoke(this.props, 'onChangeTab', 'active-matches/sellers')
                }
            ]} />
        );
    };

    buildActiveMatches = () => (
        <section>
            <div>
                {this.buildInternalTabs()}
            </div>
            {this.buildInternalTabRoutes()}
        </section>
    );

    render = () => {
        const selectedBuyerProfile = this.getSelectedBuyerProfile();

        if (selectedBuyerProfile) {
            return this.buildViewProfile(selectedBuyerProfile);
        }

        const selectedSellerProfile = this.getSelectedSellerProfile();

        if (selectedSellerProfile) {
            return this.buildSellerViewProfile(selectedSellerProfile);
        }

        return this.buildActiveMatches();
    }
}

RealtorProfileActiveMatchesTab.propTypes = {
    brokerageName: PropTypes.string.isRequired,
    agentName: PropTypes.string.isRequired,
    agentPhone: PropTypes.string.isRequired,
    buyersList: PropTypes.array.isRequired,
    realtorStatusOptions: PropTypes.array,
    onSelectStatus: PropTypes.func.isRequired,
    onConfirmChangeStatus: PropTypes.func.isRequired,
    onUpdateLocationInformation: PropTypes.func.isRequired,
    onSaveContactInfo: PropTypes.func.isRequired,
    transactionContactTypes: PropTypes.array.isRequired,
    onUploadFile: PropTypes.func.isRequired,
    onUploadSelectTypeFile: PropTypes.func,
    isUploading: PropTypes.bool,
    isFetchedUploading: PropTypes.bool,
    onRemoveFile: PropTypes.func.isRequired,
    displayBuyerProfile: PropTypes.number,
    displaySellerProfile: PropTypes.number,
    documentTypes: PropTypes.array.isRequired,
    onSaveSellerContactInfo: PropTypes.func,
};

export default withRouter(connect(
    null,
    null,
)(RealtorProfileActiveMatchesTab));