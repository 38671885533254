import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faMinus,
    faTimes,
    faCheck,
    faAddressBook,
    faChartPie
} from "@fortawesome/free-solid-svg-icons";

import "./button.css";

library.add(fab, faPlus, faMinus, faTimes, faCheck, faAddressBook, faChartPie);

/**
 * @visibleName Button
 */
export class Button extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf(["submit", "button", "reset"]),
        symbol: PropTypes.string,
        small: PropTypes.bool,
        isLoading: PropTypes.bool,
        loadingText: PropTypes.string,
        className: PropTypes.string,
        children: PropTypes.node
    };

    static defaultProps = {
        type: "submit",
        isLoading: false,
        loadingText: ""
    };

    render = () => {
        const {
            symbol,
            gray,
            grayInverted,
            yellow,
            type,
            className,
            small,
            extraSmall,
            inverted,
            text,
            icon,
            transparent,
            red,
            darkerRed,
            fixedIcon,
            dark,
            isLoading,
            loadingText,
            disable,
            ...props
        } = this.props;

        const classes = [
            "button",
            gray ? " button--gray" : "",
            grayInverted ? " button--gray-inverted" : "",
            yellow ? " button--yellow" : "",
            inverted ? " button--inverted" : "",
            small ? " button--small" : "",
            extraSmall ? " button--extra-small" : "",
            text ? " button--text" : "",
            icon ? " button--icon" : "",
            transparent ? " button--transparent" : "",
            red ? " button--red" : "",
            darkerRed ? " button--darker-red" : "",
            fixedIcon ? " button--fixed-icon" : "",
            dark ? " button--dark" : "",
            disable ? " button--disable" : "",
            className
        ].join(" ");

        const iconContent = symbol ? (
            <div className="button-icon--placeholder">
                <span>
                    <FontAwesomeIcon icon={symbol} />
                </span>
            </div>
        ) : null;

        const content = isLoading ? (
            <div>
                <i
                    style={{ textAlign: "center", padding: 0 }}
                    className="fas fa-spinner fa-spin"
                />
                {` ${loadingText}`}
            </div>
        ) : (
            this.props.children
        );

        return (
            <button {...props} type={type} symbol={symbol} className={classes}>
                {content}
                {iconContent}
            </button>
        );
    };
}

export default Button;
