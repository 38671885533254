import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { NumberFormat } from '../NumberFormat/NumberFormat';
import { Button } from '../Button/Button';
import CloseModalButton from '../CloseModalButton/CloseModalButton';
import {
    ERROR_MESSAGE_EMD_CONGRATULATION_MODAL,
    REGEXP_VALIDATION_EMAIL,
} from '../../shared/constants';

import './emdCongratulationsModal.css';

export default class EmdCongratulationsModal extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        onSend: PropTypes.func,
        isSendingEmail: PropTypes.bool,
        isSendedEmail: PropTypes.bool,
        amount: PropTypes.any,
        address: PropTypes.string,
        earnestMoneyDepositId: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.resetState();
        }
    }

    resetState = () => (
        this.setState(this.getInitialState())
    )

    getInitialState = () => ({
        emailAddressFirst: '',
        emailAddressSecond: '',
        emailAddressThird: '',
        emailAddressFourth: '',
        emailAddressFifth: '',
        emailAddressFirstError: null,
        emailAddressSecondError: null,
        emailAddressThirdError: null,
        emailAddressFourthError: null,
        emailAddressFifthError: null,
        isSendInit: false
    });

    onChangeEmailAddress = (e, field) => this.setState({ [`${field}`]: e.target.value, [`${field}Error`]: null });

    buildErrorMessage = (error) => (
        <div className="emd-congratulations-modal__password--error">
            <div className="emd-congratulations-modal__field-error--content">
                <div className="emd-congratulations-modal__field-error">
                    {error}
                </div>
            </div>
        </div>
    )

    setErrorMessage = (field, type) => {
        const newValue = ERROR_MESSAGE_EMD_CONGRATULATION_MODAL[type];
        this.setState({ [`${field}Error`]: newValue });
    }

    validateErrorMessage = (field) => {
        const errorMessage = get(this.state, `${field}Error`);
        return errorMessage ? this.buildErrorMessage(errorMessage) : null;
    }

    validateEmail = (field) => {
        const element = get(this.state, field);

        if(!isEmpty(element)) {
            if (!REGEXP_VALIDATION_EMAIL.test(element)) {
                this.setErrorMessage(field, 'email');
                return;
            }
        }

        this.setState({ [`${field}Error`]: null });
    }

    buildEmailField = (field) => {
        return (
            <div className="form-group emd-congratulations-modal__container">
                <input
                    id={field}
                    type="email"
                    className="form-control emd-congratulations-modal__form-field--email"
                    value={get(this.state, field)}
                    onChange={(e) => this.onChangeEmailAddress(e, field)}
                    placeholder="Add email address"
                    onBlur={() => this.validateEmail(field)}
                />
                {this.validateErrorMessage(field)}
            </div>
        );
    }

    buildAddress = () => (
        <div className="form-group emd-congratulations-modal__container">
            <div className="emd-congratulations-modal__address-title">
                For this property:
            </div>
            <div className="emd-congratulations-modal__address">
                {this.props.address}
            </div>
        </div>
    );

    buildAmount = () => (
        <div className="form-group emd-congratulations-modal__container">
            <div className="emd-congratulations-modal__amount-title">
                Total Amount:
            </div>
            <div className="emd-congratulations-modal__amount">
                <NumberFormat
                    format="currency"
                    decimals={2}
                    number={this.props.amount}
                />
            </div>
        </div>
    );

    buildInfoEmails = () => (
        <div className="form-group emd-congratulations-modal__container">
            <div className="emd-congratulations-modal__info-emails">
                You can send a receipt of this transfer to up to 5 people. If you wish to do so, please enter their email addresses below:
            </div>
        </div>
    )

    buildInfoEMD = () => (
        <div>
            <div className="row">
                <div className="emd-congratulations-modal col-xs-6">
                    {this.buildAmount()}
                </div>
                <div className="emd-congratulations-modal col-xs-6">
                    {this.buildAddress()}
                </div>
                <div className="emd-congratulations-modal col-xs-12">
                    {this.buildInfoEmails()}
                </div>
            </div>
        </div>
    )

    buildForm = () => (
        <div>
            <div className="row">
                <div className="emd-congratulations-modal with-padding col-xs-12">
                    {this.buildEmailField('emailAddressFirst')}
                </div>
                <div className="emd-congratulations-modal with-padding col-xs-12">
                    {this.buildEmailField('emailAddressSecond')}
                </div>
                <div className="emd-congratulations-modal with-padding col-xs-12">
                    {this.buildEmailField('emailAddressThird')}
                </div>
                <div className="emd-congratulations-modal with-padding col-xs-12">
                    {this.buildEmailField('emailAddressFourth')}
                </div>
                <div className="emd-congratulations-modal with-padding col-xs-12">
                    {this.buildEmailField('emailAddressFifth')}
                </div>
            </div>
        </div>
    );

    haveEmptyFields = () => {
        const {
            emailAddressFirst,
            emailAddressSecond,
            emailAddressThird,
            emailAddressFourth,
            emailAddressFifth,
        } = this.state;

        return !(emailAddressFirst || emailAddressSecond || emailAddressThird || emailAddressFourth || emailAddressFifth);
    };

    haveErrors = () => {
        const {
            emailAddressFirstError,
            emailAddressSecondError,
            emailAddressThirdError,
            emailAddressFourthError,
            emailAddressFifthError,
        } = this.state;

        return (emailAddressFirstError || emailAddressSecondError || emailAddressThirdError || emailAddressFourthError || emailAddressFifthError);
    };

    isDisableSendButton = () => {
        const { isSendingEmail, isSendedEmail } = this.props;

        return (this.haveEmptyFields() || this.haveErrors() || isSendingEmail || isSendedEmail);
    };

    onSend = () => {
        const { onSend, earnestMoneyDepositId } = this.props;
        const {
            emailAddressFirst,
            emailAddressSecond,
            emailAddressThird,
            emailAddressFourth,
            emailAddressFifth,
        } = this.state;

        if (isFunction(onSend)) {
            this.setState({ isSendInit: true });
            const data = [];
            if(emailAddressFirst) data.push({ email: emailAddressFirst });
            if(emailAddressSecond) data.push({ email: emailAddressSecond });
            if(emailAddressThird) data.push({ email: emailAddressThird });
            if(emailAddressFourth) data.push({ email: emailAddressFourth });
            if(emailAddressFifth) data.push({ email: emailAddressFifth });

            onSend(earnestMoneyDepositId, data);
        }
    }

    buildSuccessMessage = () => (
        <div className="emd-congratulations-modal__success-message">
            Your emails have successfully been sent
        </div>
    )

    render = () => {
        const {
            isSendingEmail,
            isSendedEmail,
            onCancel,
            onClose,
            isVisible,
        } = this.props;
        const textSendButton = 'Send';
        const textCancelButton = isSendedEmail ? 'Close' : 'Cancel';
        const successMessage = isSendedEmail && this.buildSuccessMessage();

        return (
            <Modal
                animation
                autoFocus
                backdrop
                enforceFocus
                show={isVisible}
                className="emd-congratulations-modal"
            >
                <ModalHeader>
                    <CloseModalButton onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <h1 className="emd-congratulations-modal__title">
                        <strong>Congratulations!</strong> Your transfer was successfully created.
                    </h1>
                    {this.buildInfoEMD()}
                    {this.buildForm()}
                    <div className="emd-congratulations-modal__footer">
                        <Button
                            onClick={this.onSend}
                            disabled={this.isDisableSendButton()}
                            isLoading={isSendingEmail}
                            loadingText="Sending..."
                        >
                            {textSendButton}
                        </Button>
                        <Button
                            inverted
                            onClick={onCancel}
                        >
                            {textCancelButton}
                        </Button>
                    </div>
                    {successMessage}
                </ModalBody>
            </Modal>
        );
    }
}
