import React from 'react';
import get from 'lodash/get';
// import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

const PriceTrendGraph = (props) => {
    
        const salePrice = get(props, 'values.salePrice') || []
        const listingPrice = get(props, 'values.listingPrice') || []
        /**
         * loop through the sales data and change the keys to x and y as
         * they are the only valid keys accepted by @nivo/line chart
         */

        const saleData = salePrice
            ? salePrice.map(sale => {
                const dataValues = {};
                dataValues.x = sale[0];
                dataValues.y = sale[1];
                return dataValues;
            })
            : [];
        
        const listingData = listingPrice
        ? listingPrice.map(sale => {
            const dataValues = {};
            dataValues.x = sale[0];
            dataValues.y = sale[1];
            return dataValues;
        })
        : [];

        /**
         * append a start and an end point to the line chart such
         * that all the other values will be at the center
         */

        return (
            <ResponsiveLine
                data={[
                    {
                      "id": "List Price",
                      "data": listingData
                    },
                    {
                        "id": "Sale Price",
                        "data": saleData
                      }
                  ]}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false }}
                curve="natural"
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                }}
                colors={{ scheme: 'accent' }}
                enablePoints={false}
                enableGridX={false}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 1,
                        symbolSize: 12,
                        symbolShape: 'square',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
        )
}

export default PriceTrendGraph;