import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./buyer-flow-button.css";

/**
 * @examples
 * <BuyerFlowButton isSelected big icon={<i className="fa fa-check" style={{color: 'green'}} />}>Yes</BuyerFlowButton>
 * <BuyerFlowButton big icon={<i className="fa fa-times" style={{color: 'red'}} />}>No</BuyerFlowButton>
 * <BuyerFlowButton>Other</BuyerFlowButton>
 */
const BuyerFlowButton = props => {
    const cls = {
        "buyer-flow-button": true
    };
    cls["font-big"] = props.isFontBig;
    cls["max-width"] = props.isMaxWidth;
    cls["selected"] = props.isSelected;
    cls["big"] = props.big;

    return (
        <button {...props} className={classNames(cls)}>
            <span className="buyer-flow-button__icon">
                <i className={props.icon} style={props.iconStyle} />
            </span>
            <span className="buyer-flow-button__label">{props.children}</span>
        </button>
    );
};

BuyerFlowButton.propTypes = {
    isSelected: PropTypes.bool,
    big: PropTypes.bool,
    icon: PropTypes.any,
    isFontBig: PropTypes.bool,
    isMaxWidth: PropTypes.bool
};

export default BuyerFlowButton;
