import React from 'react';
import PropTypes from 'prop-types';
import delay from 'lodash/delay';
import SpinWheelPrize from '../SpinWheelPrize/SpinWheelPrize';
import redMarker from '../../styles/assets/red-marker.png';
import logoImg from '../../styles/assets/lemon.100x100.png';

import './spinWheel.css';
import Spin from '../Spin/Spin';

class SpinWheel extends React.Component {
    static propTypes = {
        colors: PropTypes.array,
        prizes: PropTypes.array,
        spinBrewWheel: PropTypes.func,
        successSpinWheel: PropTypes.bool,
        selectedPrizeIndex: PropTypes.number,
        selectedPrizeName: PropTypes.string,
        selectedPrizeDescription: PropTypes.string,
        selectedPrizeUrl: PropTypes.string,
    };

    static defaultProps = {
        successSpinWheel: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            showPrize: false,
        };
    }

    spin = () => {
        this.props.spinBrewWheel();
    };

    showPrize = () => (this.setState({ showPrize: true }));

    stopRotateWheel = () => {
        delay(this.showPrize, 1000);
    }

    stopRotateWheelWithoutPrize = () => (this.showPrize());

    render() {
        const { showPrize } = this.state;

        if (showPrize) {
            return (
                <SpinWheelPrize
                    selectedPrizeName={this.props.selectedPrizeName}
                    selectedPrizeDescription={this.props.selectedPrizeDescription}
                    selectedPrizeUrl={this.props.selectedPrizeUrl}
                    selectedPrizeIndex={this.props.selectedPrizeIndex}
                />
            );
        }

        return (
            <div className="spin-wheel">
                <div className="spin-wheel--img">
                    <div className="spin-wheel__text">
                        <div className="spin-wheel__title">
                            Feeling lucky?
                        </div>
                        <div className="spin-wheel__subtitle">
                            Spin the wheel and win awesome prizes!
                        </div>
                    </div>
                    <div className="spin-wheel--images">
                        <img src={redMarker} width="40" height="40" id="arrow" alt="" />
                        <img src={logoImg} width="40" height="40" id="center-logo" alt="" />
                    </div>
                    <Spin
                        colors={this.props.colors}
                        prizes={this.props.prizes}
                        successSpinWheel={this.props.successSpinWheel}
                        selectedPrizeIndex={this.props.selectedPrizeIndex}
                        spin={this.spin}
                        stopRotateWheel={this.stopRotateWheel}
                        stopRotateWheelWithoutPrize={this.stopRotateWheelWithoutPrize}
                    />
                </div>
            </div>
        );
    }
}

export default SpinWheel;
