import React, { Fragment } from "react";
import { Field } from "formik";
import get from "lodash/get";
import map from "lodash/map";
import split from "lodash/split";
import invoke from "lodash/invoke";
import replace from "lodash/replace";
import BaseWizardStep from "../BaseWizardStep";
import FormikBuyerFlowRadioButton from "../../../BuyerFlowButton/FormikBuyerFlowRadioButton";
import mixpanel from "mixpanel-browser";

class StepBudget extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        const budget = split(get(values, "budget"), ",");
        const budgetMin = replace(get(budget, 0), "[", "");
        const budgetMax = replace(get(budget, 1), "]", "");
        mixpanel.track("Completed What Is Your Budget", {
            budgetMin: budgetMin,
            budgetMax: budgetMax
        });
    };

    stepBudgetNext = () => {
        setTimeout(
            () => this.props.onNavigateStep(1, true, this.mixpanelFunction),
            0
        );
    };

    hasFoundMatchForAddress = () => {
        const { customBudgetRange } = this.props;
        const ranges = invoke(customBudgetRange, "get");
        return Boolean(get(ranges, "0.foundMatch"));
    };

    buildOptions = () => {
        const { customBudgetRange } = this.props;
        const ranges = customBudgetRange.get();
        return map(ranges, range => ({
            ...range,
            buttonProps: {
                children: range.label,
                isMaxWidth: true
                // icon: "fa fa-check",
                // iconStyle: { color: "white" }
            }
        }));
    };

    buildTitle = () => {
        if (!this.hasFoundMatchForAddress()) {
            return "What is your budget?";
        }

        const location = get(this.props.values, "knownLocations[0].address");
        return `The estimated price range for ${location} is`;
    };

    render() {
        const isStepFulfilled = this.areFieldsValid(["budget"]);
        const hasMixPanelTracking = true;

        return (
            <Fragment>
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        {this.buildTitle()}
                    </div>

                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field align-center">
                                <Field
                                    name="budget"
                                    itemClassName="wider"
                                    options={this.buildOptions()}
                                    component={FormikBuyerFlowRadioButton}
                                    optionContainerClassName="buyer__signup__timeline-field"
                                />

                                {/* {this.renderFieldError("purchaseTimeline")} */}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderStepButtons(
                    {
                        canProceed: isStepFulfilled,
                        hasMixPanelTracking: true,
                        handleNext: this.stepBudgetNext
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepBudget;
