import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { NumberFormat } from '../NumberFormat/NumberFormat';

import './realtormatchprofileheader.css';

export default class RealtorMatchProfileHeader extends React.PureComponent {
    getRebatePercentage = () => {
        const rebate = isNumber(this.props.rebate) ? this.props.rebate : 0;
        return `${rebate}%`;
    }

    buildBudget = () => (
        <div className="realtor-match-profile-header__budget">
            <div className="realtor-match-profile-header__budget-label">
                {this.props.isSeller ? 'Price range' : 'Budget'}
            </div>
            <div className="realtor-match-profile-header__budget-value">
                <span>
                    <NumberFormat
                        number={get(this.props.budget, 'amount')}
                        currency={get(this.props.budget, 'currency')}
                        decimals={0}
                        format="currency"
                    />
                    {' - '}
                    <NumberFormat
                        number={get(this.props.maxBudget, 'amount')}
                        currency={get(this.props.maxBudget, 'currency')}
                        decimals={0}
                        format="currency"
                    />
                </span>
            </div>
        </div>
    );

    buildRebate = () => (
        <div className="realtor-match-profile-header__rebate">
            <div className="realtor-match-profile-header__rebate-label">
                Rebate
            </div>
            <div className="realtor-match-profile-header__rebate-value">
                {this.getRebatePercentage()}
            </div>
        </div>
    );

    buildTimeFrame = () => (
        <div className="realtor-match-profile-header__brew-score">
            <div className="realtor-match-profile-header__brew-score-label">
                TimeFrame
            </div>
            <div className="realtor-match-profile-header__brew-score-value">
                {this.props.timeFrame}
            </div>
        </div>
    );

    buildBrewScore = () => (
        <div className="realtor-match-profile-header__brew-score">
            <div className="realtor-match-profile-header__brew-score-label">
                BrewScore
            </div>
            <div className="realtor-match-profile-header__brew-score-value">
                <NumberFormat
                    number={this.props.brewScore}
                    format="decimal"
                    decimals={0}
                />
            </div>
        </div>
    );

    buildStatus = () => (
        <div className="realtor-match-profile-header__status">
            <div className="realtor-match-profile-header__status-label">
                Status
            </div>
            <div className="realtor-match-profile-header__status-value">
                {this.props.status}
            </div>
        </div>
    );

    render = () => (
        <div className="realtor-match-profile-header">
            <h1 className="realtor-match-profile-header__name">{this.props.name}</h1>
            <h5 className="realtor-match-profile-header__location">
                <i className="fa fa-map-marker-alt realtor-match-profile-header__location-icon" />
                {this.props.location}
            </h5>
            <div className="realtor-match-profile-header__info">
                {this.buildBudget()}
                {this.props.isSeller && this.buildTimeFrame()}
                {!this.props.isSeller && this.buildRebate()}
                {!this.props.isSeller && this.buildBrewScore()}
                {this.buildStatus()}
            </div>
        </div>
    );
}

RealtorMatchProfileHeader.propTypes = {
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    maxBudget: PropTypes.any.isRequired,
    budget: PropTypes.any.isRequired,
    rebate: PropTypes.number.isRequired,
    brewScore: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
};
