import * as APIUtil from "../../util/service_api";
import get from "lodash/get";
import size from "lodash/size";
import {
    INVALIDATE_USER,
    RECEIVE_USER_AUTH_ERROR,
    RECEIVE_USER_PROFILE,
    SUCCESSFULLY_ACCEPTED_MATCH,
    SUCCESSFULLY_REJECTED_MATCH,
    SUCCESSFULLY_UPDATE_BUYER_STATUS,
    UPDATE_BUYER_STATUS,
    RECEIVE_UPDATE_BUYER_STATUS_ERROR,
    FETCH_UPLOAD_REALTOR_FILE_ATTACHMENT,
    SUCCESSFULLY_UPLOAD_REALTOR_FILE_ATTACHMENT,
    SUCCESSFULLY_REMOVE_REALTOR_FILE_ATTACHMENT,
    UPLOAD_REALTOR_FILE_ATTACHMENT_ERROR,
    SUCCESSFULLY_FETCH_ACTIVE_MATCHES,
    FETCH_ACTIVE_MATCHES_ERROR,
    FETCH_SIGNUP_USER,
    SUCCESSFULLY_SIGNUP_USER,
    ERROR_SIGNUP_USER,
    DISPLAY_NEW_ACTIVE_MATCH_MODAL,
    HIDE_NEW_ACTIVE_MATCH_MODAL,
    SUCCESSFULLY_COMPLETED_REALTOR_TOUR,
    FETCH_BREW_SCORE_USER,
    SUCCESS_BREW_SCORE_USER,
    ERROR_BREW_SCORE_USER,
    SUCCESS_SIGN_UP_USER,
    ERROR_SIGN_UP_USER,
    FETCH_SEARCH_AGENTS_USER,
    SUCCESS_SEARCH_AGENTS_USER,
    ERROR_SEARCH_AGENTS_USER,
    SUCCESS_SIGN_UP_USER_SELLER,
    ERROR_SIGN_UP_USER_SELLER,
    FETCH_SEARCH_SELLER_AGENTS_USER,
    SUCCESS_SEARCH_SELLER_AGENTS_USER,
    ERROR_SEARCH_SELLER_AGENTS_USER,
    FETCH_CONTACT_REBATE,
    SUCCESS_CONTACT_REBATE,
    ERROR_CONTACT_REBATE,
    REMOVE_REALTOR_FILE_ATTACHMENT
} from "./UserActionTypes";
import {
    showGlobalLoader,
    hideGlobalLoader
} from "../global-loader/GlobalLoaderActions";

export const receiveAuthErrors = () => ({
    type: RECEIVE_USER_AUTH_ERROR,
    payload: {
        errors: "Your email and/or password are incorrect. Please try again."
    }
});

export const receiveUserProfile = profile => ({
    type: RECEIVE_USER_PROFILE,
    payload: {
        profile
    }
});

export const updateBuyerProfile = profile => dispatch => {
    APIUtil.updateBuyer(profile).then(response =>
        dispatch(receiveUserProfile(response.data))
    );
};

export const successfullyAcceptedMatch = match => ({
    type: SUCCESSFULLY_ACCEPTED_MATCH,
    payload: { match }
});

export const successfullyRejectedMatch = matchId => ({
    type: SUCCESSFULLY_REJECTED_MATCH,
    payload: { matchId }
});

export const fetchSignupUser = () => ({
    type: FETCH_SIGNUP_USER
});

export const successfullySignupUser = () => ({
    type: SUCCESSFULLY_SIGNUP_USER
});

export const errorSignUp = error => ({
    type: ERROR_SIGNUP_USER,
    payload: { error }
});

export const fetchBrewScore = () => ({
    type: FETCH_BREW_SCORE_USER
});

export const successBrewScore = response => ({
    type: SUCCESS_BREW_SCORE_USER,
    payload: response
});

export const errorBrewScore = error => ({
    type: ERROR_BREW_SCORE_USER,
    payload: { error }
});

export const fetchSearchAgents = () => ({
    type: FETCH_SEARCH_AGENTS_USER
});

export const successSearchAgents = response => ({
    type: SUCCESS_SEARCH_AGENTS_USER,
    payload: response
});

export const errorSearchAgents = error => ({
    type: ERROR_SEARCH_AGENTS_USER,
    payload: { error }
});

export const fetchSearchSellerAgents = () => ({
    type: FETCH_SEARCH_SELLER_AGENTS_USER
});

export const successSearchSellerAgents = response => ({
    type: SUCCESS_SEARCH_SELLER_AGENTS_USER,
    payload: response
});

export const errorSearchSellerAgents = error => ({
    type: ERROR_SEARCH_SELLER_AGENTS_USER,
    payload: { error }
});

export const successSignupBuyer = response => ({
    type: SUCCESS_SIGN_UP_USER,
    payload: response
});

export const errorSignupBuyer = error => ({
    type: ERROR_SIGN_UP_USER,
    payload: { error }
});

export const successSignupSeller = response => ({
    type: SUCCESS_SIGN_UP_USER_SELLER,
    payload: response
});

export const errorSignupSeller = error => ({
    type: ERROR_SIGN_UP_USER_SELLER,
    payload: { error }
});

export const fetchContactRebate = response => ({
    type: FETCH_CONTACT_REBATE
});

export const successContactRebate = response => ({
    type: SUCCESS_CONTACT_REBATE,
    payload: response
});

export const errorContactRebate = error => ({
    type: ERROR_CONTACT_REBATE,
    payload: { error }
});

export const successfullyUpdateBuyerStatus = buyerData => ({
    type: SUCCESSFULLY_UPDATE_BUYER_STATUS,
    payload: { buyerData }
});

export const receiveUpdateBuyerStatusErrors = error => ({
    type: RECEIVE_UPDATE_BUYER_STATUS_ERROR,
    payload: { error }
});

export const fetchingUploadRealtorFileAttachment = () => ({
    type: FETCH_UPLOAD_REALTOR_FILE_ATTACHMENT
});

export const successfullyUploadRealtorFileAttachment = (
    realtorMatchId,
    attachment
) => ({
    type: SUCCESSFULLY_UPLOAD_REALTOR_FILE_ATTACHMENT,
    payload: { realtorMatchId, attachment }
});

export const successfullyRemoveRealtorFileAttachment = (
    realtorMatchId,
    fileId
) => ({
    type: SUCCESSFULLY_REMOVE_REALTOR_FILE_ATTACHMENT,
    payload: { realtorMatchId, fileId }
});

export const uploadRealtorFileAttachmentErrors = error => ({
    type: UPLOAD_REALTOR_FILE_ATTACHMENT_ERROR,
    payload: { error }
});

export const displayNewActiveMatchesModal = () => ({
    type: DISPLAY_NEW_ACTIVE_MATCH_MODAL
});

export const hideNewActiveMatchesModal = () => ({
    type: HIDE_NEW_ACTIVE_MATCH_MODAL
});

const requestActiveMatchesUntilNewFound = currentActiveMatches => dispatch => {
    const hasNewActiveMatches = newActiveMatches =>
        size(currentActiveMatches) < size(newActiveMatches);

    const dispatchNewActiveMatchesModalOrRetry = newActiveMatches => {
        if (hasNewActiveMatches(newActiveMatches)) {
            dispatch(successfullyFetchActiveMatches(newActiveMatches));
            dispatch(displayNewActiveMatchesModal());
        } else {
            dispatch(
                waitAndRequestActiveMatchesUntilNewFound(currentActiveMatches)
            );
        }
    };

    APIUtil.getActiveMatches()
        .then(dispatchNewActiveMatchesModalOrRetry)
        .catch(() => {
            dispatch(
                waitAndRequestActiveMatchesUntilNewFound(currentActiveMatches)
            );
        });
};

const waitAndRequestActiveMatchesUntilNewFound = currentActiveMatches => dispatch => {
    const requestActiveMatches = () =>
        dispatch(requestActiveMatchesUntilNewFound(currentActiveMatches));
    const afterThirtySeconds = 30 * 1000;
    setTimeout(requestActiveMatches, afterThirtySeconds);
};

export const acceptMatch = matchId => (dispatch, getState) => {
    const currentActiveMatches = get(getState(), "user.profile.buyers");
    dispatch(waitAndRequestActiveMatchesUntilNewFound(currentActiveMatches));
    dispatch(successfullyAcceptedMatch(matchId));
};

export const declineMatch = (matchId, data) => dispatch => {
    APIUtil.declineMatch(matchId, data).then(() =>
        dispatch(successfullyRejectedMatch(matchId))
    );
};

export const updateBuyerStatus = (params, data) => dispatch => {
    dispatch(removingRealtorFileAttachment());
    APIUtil.updateBuyerInformation(params, data)
        .then(response => dispatch(successfullyUpdateBuyerStatus(response)))
        .catch(error => {
            dispatch(receiveUpdateBuyerStatusErrors(error));
            return Promise.reject(error);
        });
};

export const updatingBuyerStatus = () => ({
    type: UPDATE_BUYER_STATUS
});

export const fetchActivesMatchesErrors = errors => ({
    type: FETCH_ACTIVE_MATCHES_ERROR,
    payload: { errors }
});

export const successfullyFetchActiveMatches = activeMatches => ({
    type: SUCCESSFULLY_FETCH_ACTIVE_MATCHES,
    payload: { activeMatches }
});

export const fetchActiveMatches = () => dispatch => {
    APIUtil.getActiveMatches()
        .then(response => dispatch(successfullyFetchActiveMatches(response)))
        .catch(error => {
            dispatch(fetchActivesMatchesErrors(error));
            return Promise.reject(error);
        });
};

export const uploadRealtorFileAttachment = (
    realtorMatchId,
    file,
    fileType
) => dispatch => {
    dispatch(fetchingUploadRealtorFileAttachment());
    APIUtil.uploadAttachment(realtorMatchId, file, fileType)
        .then(response =>
            dispatch(
                successfullyUploadRealtorFileAttachment(
                    realtorMatchId,
                    response
                )
            )
        )
        .catch(error => dispatch(uploadRealtorFileAttachmentErrors(error)));
};

export const removeRealtorFileAttachment = (
    realtorMatchId,
    fileId
) => dispatch => {
    dispatch(removingRealtorFileAttachment());
    APIUtil.removeRealtorMatchAttachment(realtorMatchId, fileId).then(() =>
        dispatch(
            successfullyRemoveRealtorFileAttachment(realtorMatchId, fileId)
        )
    );
};

export const removingRealtorFileAttachment = () => ({
    type: REMOVE_REALTOR_FILE_ATTACHMENT
});

export const invalidateUser = () => ({
    type: INVALIDATE_USER,
    payload: {}
});

export const logoutUser = () => {
    APIUtil.logoutUser();
    return invalidateUser();
};

export const loadUserProfile = () => dispatch =>
    APIUtil.loadUserProfile()
        .then(response => {
            dispatch(receiveUserProfile(response));
            return response;
        })
        .catch(error => {
            if (error && error.response && error.response.status === 401) {
                dispatch(invalidateUser());
            }
            return Promise.reject(error);
        });

export const loginUser = formData => dispatch =>
    APIUtil.loginUser(formData)
        .then(() => {
            dispatch(loadUserProfile());
        })
        .catch(errors => {
            dispatch(receiveAuthErrors(errors));
            return Promise.reject(errors);
        });

export const initialLoadUserProfile = () => dispatch => {
    dispatch(showGlobalLoader());
    dispatch(loadUserProfile())
        .catch(() => {})
        .finally(() => dispatch(hideGlobalLoader()));
};

export const signupUser = token => dispatch => {
    dispatch(fetchSignupUser());
    APIUtil.signupUser(token)
        .then(() => dispatch(successfullySignupUser()))
        .catch(error => dispatch(errorSignUp(error)));
};

const successfullyCompletedRealtorTour = () => ({
    type: SUCCESSFULLY_COMPLETED_REALTOR_TOUR
});

export const preventRealtorTourFromReAppearing = () => dispatch => {
    APIUtil.completeRealtorTour().finally(() =>
        dispatch(successfullyCompletedRealtorTour())
    );
};

export const getBrewScore = data => dispatch => {
    dispatch(fetchBrewScore());
    return APIUtil.getBrewScore(data)
        .then(response => {
            dispatch(successBrewScore(response));
            return response;
        })
        .catch(error => dispatch(errorBrewScore(error)));
};

export const getSearchAgents = data => dispatch => {
    dispatch(fetchSearchAgents());
    return APIUtil.getSearchAgents(data)
        .then(response => {
            dispatch(successSearchAgents(response));
            return response;
        })
        .catch(error => dispatch(errorSearchAgents(error)));
};

export const getSearchSellerAgents = data => dispatch => {
    dispatch(fetchSearchSellerAgents());
    return APIUtil.getSearchSellerAgents(data)
        .then(response => {
            dispatch(successSearchSellerAgents(response));
            return response;
        })
        .catch(error => dispatch(errorSearchSellerAgents(error)));
};

export const signupBuyer = data => dispatch =>
    APIUtil.signupBuyer(data)
        .then(response => {
            dispatch(successSignupBuyer(response));
            return response;
        })
        .catch(error => dispatch(errorSignupBuyer(error)));

export const signupSeller = data => dispatch =>
    APIUtil.signupSeller(data)
        .then(response => {
            dispatch(successSignupSeller(response));
            return response;
        })
        .catch(error => dispatch(errorSignupSeller(error)));

export const contactRebate = data => dispatch => {
    dispatch(fetchContactRebate());
    return APIUtil.contactRebate(data)
        .then(response => {
            dispatch(successContactRebate(response));
            return response;
        })
        .catch(error => dispatch(errorContactRebate(error)));
};
