import * as configActionTypes from './ConfigActionTypes';

const initialState = {
    config: null,
    isFetching: false,
    error:''
};

const configReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case configActionTypes.CONFIG_LIST_REQUESTED:
        return {
            isFetching: true,
        };

    case configActionTypes.CONFIG_LIST_SUCCESS:
        return {
            isFetching: false,
            config: payload
        };

    case configActionTypes.CONFIG_LIST_FAILURE:
        return {
            isFetching: false,
            error: payload
        };

    default:
        return state
    }
};

export default configReducer;
