import get from "lodash/get";
import React, { Fragment } from "react";
import { Field } from "formik";
import { yesNo } from "../../../../shared/constants";
import FormikBuyerFlowRadioButton from "../../../BuyerFlowButton/FormikBuyerFlowRadioButton";
import BaseWizardStep from "../BaseWizardStep";
import mixpanel from "mixpanel-browser";

class StepPlanUsingProceeds extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        const buyerIsUsingNetProceedsFromCurrentHome =
            get(values, "buyerIsUsingNetProceedsFromCurrentHome") === "yes"
                ? true
                : false;

        mixpanel.track(
            "Completed Do You Plan On Using Proceeds From This Sale To Buy Your New Home",
            {
                buyerIsUsingNetProceedsFromCurrentHome: buyerIsUsingNetProceedsFromCurrentHome
            }
        );
    };

    stepPlanUsingProceedsNext = () => {
        setTimeout(
            () => this.props.onNavigateStep(1, true, this.mixpanelFunction),
            0
        );
    };

    render() {
        const isStepFulfilled = this.areFieldsValid([
            "buyerIsUsingNetProceedsFromCurrentHome"
        ]);

        return (
            <Fragment>
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        Do you plan on using proceeds from this sale to buy your
                        new home?
                    </div>
                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field display-flex">
                                <Field
                                    name="buyerIsUsingNetProceedsFromCurrentHome"
                                    className="is-horizontal"
                                    options={yesNo}
                                    component={FormikBuyerFlowRadioButton}
                                />
                                {/* {this.renderFieldError(
                                    "buyerIsUsingNetProceedsFromCurrentHome"
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    hideNextButton: false,
                    handleNext: this.stepPlanUsingProceedsNext
                })}
            </Fragment>
        );
    }
}

export default StepPlanUsingProceeds;
