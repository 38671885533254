import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import last from 'lodash/last';
import find from 'lodash/find';
import join from 'lodash/join';
import invoke from 'lodash/invoke';
import reject from 'lodash/reject';
import isFunction from 'lodash/isFunction';
import { components } from 'react-select';
import SimpleUrlAutoCompleteInput from '../SimpleUrlAutoCompleteInput/SimpleUrlAutoCompleteInput';
import { updateRealtorProfile } from '../../util/service_api';

import './realtorautocompletefield.css';

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <i className="fa fa-search" />
    </components.DropdownIndicator>
);

const CheckboxOption = (props) => {
    const selectedOptions = invoke(props, 'getValue');
    const optionLabel = get(props, 'label');
    const isSelected = find(selectedOptions, ['label', optionLabel]) != null;

    return (
        <components.Option {...props}>
            <div className="realtor-auto-complete-field__option">
                { isSelected && <div className="realtor-auto-complete-field__checkbox selected"><i className="fa fa-check" /></div> }
                { !isSelected && <div className="realtor-auto-complete-field__checkbox" /> }
                {get(props, 'children')}
            </div>
        </components.Option>
    );
};

export default class RealtorAutoCompleteField extends Component {
    static propTypes = {
        isEditing: PropTypes.func,
        fieldLabel: PropTypes.string.isRequired,
        fieldProp: PropTypes.string.isRequired,
        labelProp: PropTypes.string.isRequired,
        autoCompleteUri: PropTypes.string.isRequired,
        value: PropTypes.any,
        isMulti: PropTypes.bool,
        canEdit: PropTypes.bool,
        onEdit: PropTypes.func,
        allowRepeatedValues: PropTypes.bool,
        cacheOptions: PropTypes.bool,
        allowSelectingWithCheckbox: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            // isEditing: false,
            value: this.getOriginalValueAsLabeledOption(),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.canEdit !== this.props.canEdit) {
            this.onCancelEdit();
        }
    }

    getOriginalValueAsLabeledOption = () => (this.props.isMulti
        ? this.valuesToLabeledOptions(this.props.value)
        : this.valueToLabeledOption(this.props.value));

    valuesToLabeledOptions = (values) => map(values, this.valueToLabeledOption);

    valueToLabeledOption = (value) => {
        const label = get(value, this.props.labelProp);
        return value && { value, label };
    };

    labeledOptionToValue = (labeledOption) => get(labeledOption, 'value');

    labeledOptionsToValues = (labeledOptions) => map(labeledOptions, this.labeledOptionToValue);

    updateValue = (value) => {
        this.setState({ value });
        this.props.onEdit(map(value, 'value'));
    };

    onChangeField = (fieldNewValue, event) => {
        if (!this.props.allowSelectingWithCheckbox) {
            this.updateValue(fieldNewValue);
            return;
        }

        const isRemoving = get(event, 'action') === 'remove-value';
        if (isRemoving) {
            this.updateValue(fieldNewValue);
            return;
        }

        const prevValue = this.state.value;
        const newSelectedOption = last(fieldNewValue);
        const newOptionLabel = get(newSelectedOption, 'label');
        const isOptionAlreadySelected = find(prevValue, ['label', newOptionLabel]) != null;

        if (isOptionAlreadySelected) {
            const deselectOption = reject(prevValue, { label: newOptionLabel });
            this.updateValue(deselectOption);
            return;
        }

        this.updateValue(fieldNewValue);
    };

    buildEditableField = () => (
        <SimpleUrlAutoCompleteInput
            labelProp={this.props.labelProp}
            autoCompleteUri={this.props.autoCompleteUri}
            isMulti={this.props.isMulti}
            getOptionsMillisecondsDelay={300}
            value={this.state.value}
            onChange={this.onChangeField}
            placeholder="Search"
            components={{
                DropdownIndicator,
                Option: this.props.allowSelectingWithCheckbox ? CheckboxOption : components.Option
            }}
            isClearable={false}
            allowRepeatedValues={this.props.allowRepeatedValues}
            cacheOptions={this.props.cacheOptions}
            allowSelectingWithCheckbox={this.props.allowSelectingWithCheckbox}
            closeMenuOnSelect={!this.props.allowSelectingWithCheckbox}
        />
    );

    buildFieldValue = () => {
        const { isMulti, value, labelProp } = this.props;
        let fieldValue;

        if (isMulti) {
            const labels = map(value, labelProp);
            fieldValue = join(labels, ', ');
        } else {
            fieldValue = get(value, labelProp);
        }

        return <div className="realtor-auto-complete-field__field-value">{fieldValue}</div>;
    };

    onConfirmEdit = () => {
        const { isMulti, fieldProp } = this.props;
        const { value } = this.state;
        const fieldNewValue = isMulti
            ? this.labeledOptionsToValues(value)
            : this.labeledOptionToValue(value);

        updateRealtorProfile({ [fieldProp]: fieldNewValue })
            .then(() => this.onSuccessfullyEdit(fieldNewValue))
            .catch(this.onFailureEdit);
    };

    onSuccessfullyEdit = (fieldNewValue) => {
        this.setState({ isEditing: false });

        if (isFunction(this.props.onEdit)) {
            this.props.onEdit(fieldNewValue);
        }
    };

    onFailureEdit = () => {};

    onEdit = () => this.setState({ isEditing: true });

    onCancelEdit = () => {
        this.setState({
            isEditing: false,
            value: this.getOriginalValueAsLabeledOption(),
        });
    };

    buildEditButton = () => (
        <button
            type="button"
            className="btn-link"
            onClick={this.onEdit}
        >
            Edit
        </button>
    );

    buildCancelEditButton = () => (
        <button
            type="button"
            className="btn-link realtor-auto-complete-field__cancel-button"
            onClick={this.onCancelEdit}
        >
            Cancel
        </button>
    );

    buildConfirmEditButton = () => (
        <button
            type="button"
            className="btn-link realtor-auto-complete-field__save-button"
            onClick={this.onConfirmEdit}
        >
            Save
        </button>
    );

    buildCancelAndConfirmEditButtons = () => (
        <span>
            {this.buildCancelEditButton()}
            {this.buildConfirmEditButton()}
        </span>
    );

    render = () => {
        const { fieldLabel } = this.props;
        const { isEditing } = this.props;
        const field = isEditing
            ? this.buildEditableField()
            : this.buildFieldValue();

        return (
            <div className="realtor-auto-complete-field">
                <h5 className="realtor-auto-complete-field__field-label">
                    {fieldLabel}
                    {/*{canEdit && !isEditing && this.buildEditButton()}
                    {isEditing && this.buildCancelAndConfirmEditButtons()}*/}
                </h5>
                {field}
            </div>
        );
    };
}
