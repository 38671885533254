import React from "react";
import { Button } from "../../Button/Button";

import get from "lodash/get";

import "./agents_info.scss";

export default props => {
    const firstName = get(props, "match.userInfo.firstName");
    const lastName = get(props, "match.userInfo.lastName");
    const name = `${firstName} ${lastName}`;
    const email = get(props, "match.userInfo.email");
    const phone = get(props, "match.userInfo.phone");
    const picture = get(props, "match.userInfo.profilePicUrl");
    const brokerageName = get(props, "match.brokerage.name");
    const mailLink = `mailto:${email}`;
    const phoneLink = `tel:${phone}`;
    const buttonLabel = get(props, "buttonLabel");
    const disabledButton = get(props, "disabledButton");
    const button = (
        <Button
            isLoading={props.isLoading}
            disabled={disabledButton}
            onClick={props.onClick}
            type="button"
        >
            {buttonLabel}
        </Button>
    );

    return (
        <div className="agents__info__container">
            <div className="image__col">
                <img className="profile__picture" src={picture} alt="" />
            </div>
            <div className="col">
                <div className="item">
                    <div className="key">{name}</div>
                    <div className="value__bold__green">
                        <a href={mailLink} target="_top">
                            {email}
                        </a>
                    </div>
                </div>
            </div>
            <div className="phone__col">
                <div className="item">
                    <div className="key">PHONE</div>
                    <div className="value__bold__green">
                        <a href={phoneLink} target="_top">
                            {phone}
                        </a>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="item">
                    <div className="key">WORKS AT</div>
                    <div className="value__bold__green">{brokerageName}</div>
                </div>
            </div>
            <div className="button__col">{!props.readOnly && button}</div>
        </div>
    );
};
