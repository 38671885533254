import React from "react";
import classnames from "classnames";
import SsnField from "./SsnField";
import "./ssn-toggle-visibility-field.css";

export default class SsnToggleVisibilityField extends React.Component {
    buildVisibilityButton = () => (
        <button
            type="button"
            className="btn btn-link ssn-toggle-visibility-field__button"
            onClick={this.props.onToggleVisibility}
        >
            <i className={this.buildCssForIcon()} />
        </button>
    );

    buildCssForIcon = () =>
        classnames("ssn-toggle-visibility-field__icon", "fa", {
            "fa-eye-slash": this.props.isVisible,
            "fa-eye": !this.props.isVisible
        });

    render = () => (
        <div className="ssn-toggle-visibility-field">
            <SsnField
                {...this.props}
                type={this.props.isVisible ? "text" : "password"}
            />
            {this.buildVisibilityButton()}
        </div>
    );
}
