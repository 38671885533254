import React from 'react';
import { NumberFormat } from '../../NumberFormat/NumberFormat';

const BrewBoardNumberCurrency = (number) => {
    if (number) {
        return (
            <NumberFormat
                currency="USD"
                format="currency"
                decimals={2}
                number={number}
            />
        );
    }

    return null;
};

export default BrewBoardNumberCurrency;
