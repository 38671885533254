import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import { format } from 'date-fns';
import { STATUS_REALTOR } from '../../../shared/constants';
import BrewBoardNumberRange from './BrewBoardNumberRange';
import AvatarAndNameCol from './AvatarAndNameCol';
import StatusRow from '../../StatusRow/StatusRow';

import '../userAdminProfile.css';

export default class SellerTable extends React.Component {
    buildStatus = (user) => {
        const approved = get(user, 'response.hasRealtorAcceptedMatch', false) ? 'MATCHED' : 'NEEDAGENT';
        const status = find(STATUS_REALTOR, { id: approved });
        return <StatusRow status={status} />
    }

    buildSalesPrice = (user) => {
        const homeValueMin = get(user, 'publicProfile.activeProperty.homeValueLow.amount');
        const homeValueMax = get(user, 'publicProfile.activeProperty.homeValueHigh.amount');
        const salesPrice = BrewBoardNumberRange(homeValueMin, homeValueMax);
        return (
            <div className="admin-role-tables__sales-price-range">
                {salesPrice}
            </div>
        )
    }

    buildFirstRow = (user) => {
        const streetNumber = get(user, 'publicProfile.activeProperty.address.streetNumberName');
        const state = get(user, 'publicProfile.activeProperty.address.state');
        const city = get(user, 'publicProfile.activeProperty.address.city');
        const phone = get(user, 'publicProfile.userInfo.phone');
        const phoneNum = `tel: ${phone}`;

        return (
            <div className="admin-role-tables__item-row seller-table">
            {this.props.buildActionButton(user)}
                <div className="admin-role-tables__item-row-section-plh">
                    <div className="admin-role-tables__item-row-section-one">
                        <div className="">
                            <div className="admin-role-tables__sale-title">SELLER</div>
                            <AvatarAndNameCol user={get(user, 'publicProfile')} showEmail={true} />
                            <div className="phone--num">
                                <a href={phoneNum}>
                                    {phone}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="admin-role-tables__item-row-section-two">
                        <div className="admin-role-tables__sale-price admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__sale-title">SALES PRICE RANGE</div>
                            {this.buildSalesPrice(user)}
                        </div>
                        <div className="admin-role-tables__address-selling admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__sale-title">SELLING</div>
                                <span>{streetNumber},</span><br />
                                <span>{state}, </span>
                                <span>{city}</span>
                        </div>
                        <div className="admin-role-tables__date-format admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__sale-title">CREATED DATE</div>
                            {format(get(user, 'publicProfile.userInfo.ts'), this.props.dateFormat)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    buildContentList = () => (
        <div className="admin-role-tables__body">
            {map(this.props.users, (user, index) => {
                return (
                    <div className="admin-role-tables__item" key={index}>
                        {this.buildStatus(user)}
                        {this.buildFirstRow(user)}
                    </div>
                );
            })}
        </div>
    );

    render = () => (
        <div>
            {this.buildContentList()}
        </div>
    );
}

SellerTable.propTypes = {
    dateFormat: PropTypes.string,
    users: PropTypes.any,
    buildActionButton: PropTypes.func,
};
