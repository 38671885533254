import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import RealtorActiveMatchItem from '../RealtorActiveMatchItem/RealtorActiveMatchItem';

export default class RealtorProfileActiveBuyersMatchesTab extends React.PureComponent {
    buildActiveMatch = (activeMatch) => (
        <RealtorActiveMatchItem
            key={get(activeMatch, 'buyer.userInfo.id')}
            picture={invoke(this.props, 'getMatchPictureUrl', activeMatch)}
            name={invoke(this.props, 'buildMatchFullName', activeMatch)}
            location={invoke(this.props, 'buildLocation', activeMatch)}
            budget={invoke(this.props, 'buildMatchBudget', activeMatch)}
            maxBudget={invoke(this.props, 'buildMatchMaxBudget', activeMatch)}
            rebate={invoke(this.props, 'getMatchRebatePercentage', activeMatch)}
            brewScore={invoke(this.props, 'getMatchBrewScore', activeMatch)}
            status={invoke(this.props, 'getMatchStatus', activeMatch)}
            viewProfileUrl={invoke(this.props, 'buildViewProfileForMatch', activeMatch)}
        />
    );
    
    render = () => (
        <div className="realtor-profile-active-matches-tab">
            {map(this.props.buyersList, this.buildActiveMatch)}
        </div>
    );
}