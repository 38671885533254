import React from 'react';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';

export class NumberFormat extends React.Component {
    static propTypes = {
        // BCP 47 language tag
        locale: PropTypes.string,

        // Currency code in ISO 4217
        currency: PropTypes.string,

        format: PropTypes.oneOf([
            'decimal',
            'currency',
            'percent',
        ]),

        decimals: PropTypes.number,

        number: PropTypes.number.isRequired,
    };

    static defaultProps = {
        locale: 'en-US',
        currency: 'USD',
    };

    constructor(props) {
        super(props);
        this.formatter = new Intl.NumberFormat(props.locale, {
            style: props.format,
            currency: trim(props.currency) || 'USD',
            minimumFractionDigits: props.decimals,
            maximumFractionDigits: props.decimals,
        });
    }

    render = () => (
        <span {...this.props}>
            {this.formatter.format(this.props.number || 0)}
        </span>
    );
}
