import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from 'react-google-maps';
import { GoogleMaps } from '../GoogleMaps/GoogleMaps';
import './realtormatchprofilesubjectpropertyinformation.css';

export default class RealtorMatchProfileSubjectPropertyInformationMap extends Component {
    static propTypes = {
        streetNumberName: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
    };

    buildMapPreview = () => {
        const { streetNumberName, lat, lng } = this.props;
        return (
            <div className="subject-property-information__map">
                <GoogleMaps height="100%" center={{ lat, lng }} zoom={11} isMarkerShown key={streetNumberName}>
                    <Marker
                        title={streetNumberName}
                        name="Location"
                        position={{ lat, lng }}
                    >
                        <InfoWindow visible>
                            <div className="subject-property-information__google-maps-marker">
                                {streetNumberName}
                            </div>
                        </InfoWindow>
                    </Marker>
                </GoogleMaps>
            </div>
        );
    }

    render = () => (
        this.buildMapPreview()
    )
}
