import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import './realtorTransactionBuyerReferralAgreementModal.css';
import CloseModalButton from '../CloseModalButton/CloseModalButton';
import { IframeWatcher } from '../../util/util';

export default class RealtorTransactionBuyerReferralAgreementModal extends Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        onClose: PropTypes.func,
        buyerDocuSignLink: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.IframeWatcher = new IframeWatcher(() => {
            this.props.onClose();
            this.IframeWatcher.stop();
        });
    }

    componentDidMount() {
        this.IframeWatcher.start();
    }

    componentWillUnmount() {
        this.IframeWatcher.stop();
    }

    render = () => (
        <Modal
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            className="realtor-profile-brokerage-information-modal"
        >
            <ModalHeader>
                <CloseModalButton onClick={this.props.onClose} />
            </ModalHeader>
            <ModalBody>
                <iframe
                    src={this.props.buyerDocuSignLink}
                    className="spinner realtor-profile-brokerage-information-modal__iframe-voa"
                    title="voa"
                    frameBorder="0"
                    id="lemonbrew"
                />
            </ModalBody>
        </Modal>
    );
}
