import React, { Fragment } from "react";
import get from "lodash/get";
import find from "lodash/find";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    ROLE_BROKER,
    SELECT_ROLE_ALL,
    SELECT_ROLE_REALTOR,
    SELECT_ROLE_BUYER
} from "../../shared/constants";
import NavBarStatsMenuItem from "./NavBarStatsMenuItem";

const NavbarMenu = ({ selected = "users", roles = [] }) => {
    const roleBrewcrew = find(roles, { name: SELECT_ROLE_ALL }) ? true : false;
    const roleRealtor = find(roles, { name: SELECT_ROLE_REALTOR })
        ? true
        : false;
    const roleBuyer = find(roles, { name: SELECT_ROLE_BUYER }) ? true : false;
    const roleBroker = find(roles, { name: ROLE_BROKER }) ? true : false;

    if (roleBroker) return;

    return (
        <Fragment>
            <Link to="/admin-profile">
                <button
                    type="button"
                    className={`navbar-button ${
                        selected === "users" ? "is-active" : ""
                    }`}
                >
                    Users
                </button>
            </Link>
            {roleBrewcrew || roleRealtor ? (
                <Link to="/admin-realtor-map">
                    <button
                        type="button"
                        className={`navbar-button ${
                            selected === "realtor-map" ? "is-active" : ""
                        }`}
                    >
                        Partner Agent Map
                    </button>
                </Link>
            ) : null}
            {roleBrewcrew ? (
                <Link to="/admin-brewcrew">
                    <button
                        type="button"
                        className={`navbar-button ${
                            selected === "brewcrew" ? "is-active" : ""
                        }`}
                    >
                        BrewCrew
                    </button>
                </Link>
            ) : null}
            {roleBrewcrew ? (
                <Link to="/admin-licenses">
                    <button
                        type="button"
                        className={`navbar-button ${
                            selected === "licenses" ? "is-active" : ""
                        }`}
                    >
                        Licenses
                    </button>
                </Link>
            ) : null}
            <NavBarStatsMenuItem
                selected={selected}
                canSeeAgents={roleBrewcrew || roleRealtor}
                canSeeBuyers={roleBrewcrew || roleBuyer}
            />
        </Fragment>
    );
};

export default connect(state => ({
    roles: get(state, "user.profile.publicProfile.userInfo.roles", [])
}))(NavbarMenu);
