export const CREATE_NEW_PRIZE_EVENT_FETCH = 'CREATE_NEW_PRIZE_EVENT_FETCH';
export const CREATE_NEW_PRIZE_EVENT_SUCCESS = 'CREATE_NEW_PRIZE_EVENT_SUCCESS';
export const CREATE_NEW_PRIZE_EVENT_ERROR = 'CREATE_NEW_PRIZE_EVENT_ERROR';
export const GET_WHEEL_ACTIVE_LIST_FETCH = 'GET_WHEEL_ACTIVE_LIST_FETCH';
export const GET_WHEEL_ACTIVE_LIST_SUCCESS = 'GET_WHEEL_ACTIVE_LIST_SUCCESS';
export const GET_WHEEL_ACTIVE_LIST_ERROR = 'GET_WHEEL_ACTIVE_LIST_ERROR';
export const GET_WHEEL_SPECIFIC_EVENT_FETCH = 'GET_WHEEL_SPECIFIC_EVENT_FETCH';
export const GET_WHEEL_SPECIFIC_EVENT_SUCCESS = 'GET_WHEEL_SPECIFIC_EVENT_SUCCESS';
export const GET_WHEEL_SPECIFIC_EVENT_ERROR = 'GET_WHEEL_SPECIFIC_EVENT_ERROR';
export const SPIN_WHEEL_FETCH = 'SPIN_WHEEL_FETCH';
export const SPIN_WHEEL_SUCCESS = 'SPIN_WHEEL_SUCCESS';
export const SPIN_WHEEL_ERROR = 'SPIN_WHEEL_ERROR';
