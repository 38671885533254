import React, { Fragment } from "react";
import inboxImg from "../../../../styles/assets/mail-inbox.png";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";

export default () => (
    <Fragment>
        <ScrollToTopOnMount />
        <div className="buyer__signup__step__bcg"></div>
        <div className="buyer__signup__step buyer--flow">
            <div
                className="buyer__signup__question"
                style={{ textAlign: "left", color: "#4d4d4d" }}
            >
                Check your inbox!
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "30%", marginRight: "20px" }}>
                    <img
                        src={inboxImg}
                        alt="Inbox"
                        style={{ width: "150px" }}
                    />
                </div>
                <div style={{ textAlign: "left", fontSize: "16px" }}>
                    <div style={{ color: "#01823d", marginBottom: "20px" }}>
                        Thank you for choosing LemonBrew to help sell your
                        house. A LemonBrew Partner Agent will be matched with
                        you soon.
                    </div>
                    <div>
                        In the meantime, if you have any questions, please email
                        us at{" "}
                        <a href="mailto:hello@lemonbrew.com">
                            hello@lemonbrew.com
                        </a>{" "}
                        for assistance.
                    </div>
                </div>
            </div>
            {/*<div className="buyer__signup__fieldset">
                <div className="buyer__signup__field__row">
                    <div className="buyer__signup__field__label align-center is-big">
                        Thank you for choosing LemonBrew to help sell your house.
                        A LemonBrew Partner Agent will be matched with you soon.
                        <br />
                        <br />
                        In the meantime if you have any questions, please email us at
                        &nbsp;
                        <a href="mailto:hello@lemonbrew.com">hello@lemonbrew.com</a>
                        {' '}
                        for assistance.
                        <br />
                        <br />
                        <img src={mailbox} alt="mailbox" />
                    </div> 
                </div>
            </div>*/}
        </div>
    </Fragment>
);
