import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, getIn } from 'formik';
import {Button} from '../../components/Button/Button';

import GlyphIconChat from '../../styles/assets/icon-Chat.svg';
import GlyphIconPlane from '../../styles/assets/icon-PaperPlane.svg';

import './createnewpassword.module.css';

class CreateNewPassword extends Component {
    static propTypes = {
        values: PropTypes.object,
        touched: PropTypes.object,
        isSubmitting: PropTypes.bool,
        history: PropTypes.shape({
            push: PropTypes.func
        })
    };

    getFieldError(fieldName) {
        const { errors, touched } = this.props;
        if (!getIn(touched, fieldName)) return;

        const message = getIn(errors, fieldName);
        if (typeof message === 'string') return message;
    }

    toggleForm() {
        this.props.history.push('/signin');
    }

    renderFieldError = (fieldName) => {
        const message = this.getFieldError(fieldName);
        if (message) return <div className="signin__field__error">{message}</div>;
    };

    renderFormError = () => {
        const {
            touched,
            values: { error },
        } = this.props;
        if (error && touched.password) {
            return <div className="create__password-error">{error}</div>;
        }
    };

    render() {
        const {
            isSubmitting,
            values: { success },
        } = this.props;

        return (
            <div>
                {success ? (
                    <div className="signin__form">
                        <p className="signin__message">{success}</p>
                        <div className="signin__field signin__controls__text-link width-50 align-center">
                            <div className="forgot__password" onClick={this.toggleForm.bind(this)}>
                                Back to Login
                            </div>
                        </div>
                    </div>
                ) : (
                    <Form className="signin__form">
                        <p className="signin__title">Reset Password</p>
                        <p className="signin__message">Please enter your new password</p>

                        {this.renderFormError()}

                        <div className="signin__fieldset">
                            <div className="signin__field__row">
                                <div className="signin__field align-center">
                                    <Field
                                        type="password"
                                        name="password"
                                        placeholder="PASSWORD" />
                                    {this.renderFieldError('password')}
                                </div>
                            </div>
                            <div className="signin__field__row">
                                <div className="signin__field align-center">
                                    <Field
                                        type="password"
                                        name="confirm__password"
                                        placeholder="CONFIRM PASSWORD"
                                    />
                                    {this.renderFieldError('confirm__password')}
                                </div>
                            </div>
                        </div>

                        <div className="signin__fieldset signin__controls">
                            <div className="signin__field__row">
                                <div className="signin__field width-50">
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="signin__button is-primary submit"
                                    >
                                        {isSubmitting ? (
                                            <div>
                                                <i
                                                    className="fas fa-spinner fa-spin"
                                                    style={{
                                                        textAlign: 'center',
                                                        padding: 0,
                                                    }}
                                                />
                                                <span style={{ paddingLeft: '8px' }}>
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            <Fragment>Submit</Fragment>
                                        )}
                                    </button>
                                </div>
                               <Button 
                                        text
                                        onClick={this.toggleForm.bind(this)}
                                    >
                                    {`${'< Back to Login'}`}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
                <div className="signin__footer">
                    <div className="signin__footer__block">
                        <img
                            className="signin__footer__icon"
                            src={GlyphIconPlane}
                            alt=""
                            width="42"
                        />
                        <div className="signin__footer__label">Email us:</div>
                        <div className="signin__footer__value">
                            <a href="mailto:hello@lemonbrew.com">hello@lemonbrew.com</a>
                        </div>
                    </div>
                    <div className="signin__footer__block">
                        <img
                            className="signin__footer__icon"
                            src={GlyphIconChat}
                            alt=""
                            width="36"
                        />
                        <div className="signin__footer__label">Call us:</div>
                        <div className="signin__footer__value">833-LEMONBREW (536-6627)</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateNewPassword;
