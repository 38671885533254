import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import RealtorAutoCompleteField from '../RealtorAutoCompleteField/RealtorAutoCompleteField';
import './realtoragentinformation.css';


const RealtorAgentInformation = (props) => {
    const { profile, isPublicView, onUpdateField, isEditing } = props;

    const languagesField = 'publicProfile.languages';
    const languages = get(profile, languagesField);

    const specializationsField = 'publicProfile.specializations';
    const specializations = get(profile, specializationsField);

    const designationsField = 'publicProfile.designations';
    const designations = get(profile, designationsField);

    const certificationsField = 'publicProfile.professionalCertifications';
    const certifications = get(profile, certificationsField);

    const interestsField = 'publicProfile.interests';
    const interests = get(profile, interestsField);

    const favoriteCuisinesField = 'publicProfile.cuisines';
    const favoriteCuisines = get(profile, favoriteCuisinesField);

    return (
        <Fragment>
            <div className="realtor-agent-information__card-container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <span className="section__title">Whats makes me special</span>
                    </div>
                </div>
                <div className="row">
                    <div id="realtor-tour-languages" className={`col-xs-12 col-sm-12 col-md-6 ${isEditing ? 'editable-bootsrap-column__height' : ''}`}>
                        <RealtorAutoCompleteField
                            isEditing={isEditing}
                            fieldLabel="Languages"
                            fieldProp="languages"
                            labelProp="name"
                            autoCompleteUri="autocomplete/language"
                            value={languages}
                            isMulti
                            canEdit={!isPublicView}
                            onEdit={
                                (newLanguages) => onUpdateField(
                                    languagesField,
                                    newLanguages,
                                )
                            }
                            allowRepeatedValues={false}
                            allowSelectingWithCheckbox
                        />
                    </div>
                    <div id="realtor-tour-designations" className={`col-xs-12 col-sm-12 col-md-6 ${isEditing ? 'editable-bootsrap-column__height' : ''}`}>
                        <RealtorAutoCompleteField
                            isEditing={isEditing}
                            fieldLabel="Designations"
                            fieldProp="designations"
                            labelProp="name"
                            autoCompleteUri="autocomplete/designation"
                            value={designations}
                            isMulti
                            canEdit={!isPublicView}
                            onEdit={
                                (newDesignations) => onUpdateField(
                                    designationsField,
                                    newDesignations,
                                )
                            }
                            allowRepeatedValues={false}
                            allowSelectingWithCheckbox
                        />
                    </div>
                    <div id="realtor-tour-certifications" className={`col-xs-12 col-sm-12 col-md-6 ${isEditing ? 'editable-bootsrap-column__height' : ''}`}>
                        <RealtorAutoCompleteField
                            isEditing={isEditing}
                            fieldLabel="Certifications"
                            fieldProp="professionalCertifications"
                            labelProp="name"
                            autoCompleteUri="autocomplete/professional-certification"
                            value={certifications}
                            isMulti
                            canEdit={!isPublicView}
                            onEdit={
                                (newCertifications) => onUpdateField(
                                    certificationsField,
                                    newCertifications,
                                )
                            }
                            allowRepeatedValues={false}
                            allowSelectingWithCheckbox
                        />
                    </div>
                    <div id="realtor-tour-specializations" className={`col-xs-12 col-sm-12 col-md-6 ${isEditing ? 'editable-bootsrap-column__height' : ''}`}>
                        <RealtorAutoCompleteField
                            isEditing={isEditing}
                            fieldLabel="Specializations"
                            fieldProp="specializations"
                            labelProp="name"
                            autoCompleteUri="autocomplete/specialization"
                            value={specializations}
                            isMulti
                            canEdit={!isPublicView}
                            onEdit={
                                (newSpecializations) => onUpdateField(
                                    specializationsField,
                                    newSpecializations,
                                )
                            }
                            allowRepeatedValues={false}
                            allowSelectingWithCheckbox
                        />
                    </div>
                </div>

                <div className="row">
                    <div id="realtor-tour-interests" className={`col-xs-12 col-sm-12 col-md-6 ${isEditing ? 'editable-bootsrap-column__height' : ''}`}>
                        <RealtorAutoCompleteField
                            isEditing={isEditing}
                            fieldLabel="Interests"
                            fieldProp="interests"
                            labelProp="name"
                            autoCompleteUri="autocomplete/interest"
                            value={interests}
                            isMulti
                            canEdit={!isPublicView}
                            onEdit={
                                (newInterests) => onUpdateField(
                                    interestsField,
                                    newInterests,
                                )
                            }
                            allowRepeatedValues={false}
                            allowSelectingWithCheckbox
                        />
                    </div>
                    <div id="realtor-tour-favorite-cuisines" className={`col-xs-12 col-sm-12 col-md-6 ${isEditing ? 'editable-bootsrap-column__height' : ''}`}>
                        <RealtorAutoCompleteField
                            isEditing={isEditing}
                            fieldLabel="Favorite Cuisines"
                            fieldProp="cuisines"
                            labelProp="name"
                            autoCompleteUri="autocomplete/cuisine"
                            value={favoriteCuisines}
                            isMulti
                            canEdit={!isPublicView}
                            onEdit={
                                (newFavoriteCuisines) => onUpdateField(
                                    favoriteCuisinesField,
                                    newFavoriteCuisines,
                                )
                            }
                            allowRepeatedValues={false}
                            allowSelectingWithCheckbox
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

RealtorAgentInformation.propTypes = {
    isPublicView: PropTypes.bool,
    profile: PropTypes.object.isRequired,
    onUpdateField: PropTypes.func.isRequired,
    isEditing: PropTypes.func,
    onEdit: PropTypes.func,
};

export default RealtorAgentInformation;