import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import BaseWizardStep from '../BaseWizardStep';
import Spinner from '../../../Spinner/Spinner';
import ScrollToTopOnMount from '../../../ScrollTopOnMount/ScrollTopOnMount';

const TWO_SECONDS_IN_MS = 2 * 1000;

export default class StepScanningAgents extends BaseWizardStep {
    static propTypes = {
        scanningTime: PropTypes.number,
        onNavigateStep: PropTypes.func,
    };

    static defaultProps = {
        scanningTime: TWO_SECONDS_IN_MS
    };

    componentDidMount = () => {
        this.advanceStepOnceTimeoutEnds();
    }

    advanceStepOnceTimeoutEnds = () => {
        setTimeout(this.goNextStep, this.props.scanningTime);
    };

    goNextStep = () => {
        invoke(this.props, 'onNavigateStep', 1);
    };

    countAgents = () => Number(get(this.props.searchAgents, 'searchAgents.agentsCount')) || 0;

    render = () => (
        <Fragment>
        <ScrollToTopOnMount />
            <div className="buyer__signup__step is-higher">
                <div className="buyer__signup__question white-font">
                    Scanning {this.countAgents()} agents...
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner />
                </div>
            </div>
        </Fragment>
    );
}
