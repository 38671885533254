import React from 'react';
import get from 'lodash/get';
import { formatCurrency, formatDate } from '../../shared/functions'
import './property_profile_card.scss'
/**
 * @param {props} props
 */

const PropertyProfileCard = (props) => {
    const title = get(props, 'title') || ''
    const buyer = get(props, 'data.buyer') || '-'
    const seller = get(props, 'data.seller') || '-'
    const saleDate = get(props, 'data.saleDate') || ''
    const recDate = get(props, 'data.recDate') || ''
    const salePrice = get(props, 'data.salePrice')
    
    return <div className="card__container">
                <div className="header">
                    <p className="header__text">{title}</p>
                </div>
                <div className="content">
                    <div className="item">
                        <div className="key">SELLER:</div>
                        <div className="value">{seller}</div>
                    </div>
                    <div className="item">
                        <div className="key">BUYER:</div>
                        <div className="value">{buyer}</div>
                    </div>
                    <div className="item">
                        <div className="key">SALE DATE:</div>
                        <div className="value">{saleDate ? formatDate(saleDate) : '-' }</div>
                    </div>
                    <div className="item">
                        <div className="key">REC DATE:</div>
                        <div className="value">{recDate ? formatDate(recDate) : '-' }</div>
                    </div>
                    <div className="item">
                        <div className="key">SALE PRICE:</div>
                        <div className="value">{salePrice ? formatCurrency(salePrice, 0) : '-' }</div>
                    </div>
                </div>
            </div>
};


export default PropertyProfileCard;
