import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import Intercom from "react-intercom";
import { withRouter } from "react-router-dom";

const INTERCOM_APP_ID = "tc2bf3n7";

/**
 * For more documentation on Intercom implementation, please see
 * https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
 */
class IntercomIntegrationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { lastRequestAt: this.buildLastRequestAt() };
    }

    buildLastRequestAt = () => parseInt(new Date().getTime() / 1000);

    componentDidMount = () => {
        this.props.history.listen(this.onRouteChange);
    };

    onRouteChange = () => {
        const newLastRequestAt = this.buildLastRequestAt();
        this.setState({ lastRequestAt: newLastRequestAt });
    };

    render = () => {
        let user = {};
        const userInfo = get(
            this.props.user,
            "profile.publicProfile.userInfo",
            {}
        );
        const hasUserId = Boolean(get(userInfo, "id"));

        if (hasUserId) {
            user = {
                user_id: userInfo.id,
                email: userInfo.email,
                name: `${userInfo.firstName} ${userInfo.lastName}`.trim()
            };
        }

        return (
            <Intercom
                appID={INTERCOM_APP_ID}
                {...user}
                lastRequestAt={this.state.lastRequestAt}
            />
        );
    };
}

const IntercomIntegration = withRouter(
    connect(state => ({ user: state.user }))(IntercomIntegrationComponent)
);

export default IntercomIntegration;
