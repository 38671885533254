import React from 'react';
import PropTypes from 'prop-types';
import toString from 'lodash/toString';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';
import { Button } from '../Button/Button';
import IconPerson from '../../styles/assets/icon-green-person.svg';

import './realtorprofiletransactioncontactmodal.css';
import CloseModalButton from '../CloseModalButton/CloseModalButton';

export default class RealtorProfileTransactionContactModal extends React.PureComponent {
    static propTypes = {
        isVisible: PropTypes.bool,
        onGetContacts: PropTypes.func,
        onCreateContact: PropTypes.func,
        getContactsMillisecondsDelay: PropTypes.number,
        contact: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.shape({
                firmName: PropTypes.string,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                email: PropTypes.string,
            }).isRequired,
        }),
        notes: PropTypes.string,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        onChangeContact: PropTypes.func,
        onChangeNotes: PropTypes.func,
    };

    static defaultProps = {
        getContactsMillisecondsDelay: 300,
    }

    changeContactField = (fieldName, fieldValue) => {
        const { contact, onChangeContact } = this.props;

        if (!isFunction(onChangeContact)) {
            return;
        }

        const contactLabel = toString(get(contact, 'label'));
        const contactValue = get(contact, 'value');

        onChangeContact({
            label: contactLabel,
            value: {
                ...contactValue,
                [fieldName]: fieldValue,
            },
        });
    };

    buildPlaceholder = () => (
        <div>
            <img className="realtor-profile-transaction-contact-modal__icon--size" src={IconPerson} alt={IconPerson} />
            <span className="realtor-profile-transaction-contact-modal__placeholder">Enter Name or Lastname...</span>
        </div>
    )

    buildCreateOptionLabel = (userInput) => `Create ${userInput}`;

    buildContactAutoCompleteField = () => (
        <div className="realtor-profile-transaction-contact-modal__contact-autocomplete">
            <AutoCompleteInput
                placeholder={this.buildPlaceholder()}
                getOptions={this.props.onGetContacts}
                getOptionsMillisecondsDelay={this.props.getContactsMillisecondsDelay}
                value={this.props.contact}
                onChange={this.props.onChangeContact}
                onInputChange={this.props.onChangeContact}
                onCreateOption={this.props.onCreateContact}
                buildCreateOptionLabel={this.buildCreateOptionLabel}
            />
        </div>
    );

    onChangeFirmName = (e) => this.changeContactField('businessName', e.target.value);

    onChangeFirstName = (e) => this.changeContactField('firstName', e.target.value);

    onChangeLastName = (e) => this.changeContactField('lastName', e.target.value);

    onChangeEmail = (e) => this.changeContactField('email', e.target.value);

    onChangeNotes = (e) => {
        const { onChangeNotes } = this.props;

        if (!isFunction(onChangeNotes)) {
            return;
        }

        onChangeNotes(e.target.value);
    };

    buildFirmNameField = (firmName) => (
        <div className="form-group">
            <label
                className="realtor-profile-transaction-contact-modal__form-label"
                htmlFor="businessName"
            >
                Name of the firm
                <input
                    id="businessName"
                    type="text"
                    className="form-control realtor-profile-transaction-contact-modal__form-field"
                    value={firmName}
                    onChange={this.onChangeFirmName}
                />
            </label>
        </div>
    );

    buildFirstNameField = (firstName) => (
        <div className="form-group">
            <label
                className="realtor-profile-transaction-contact-modal__form-label"
                htmlFor="firstName"
            >
                Contact First Name
                <input
                    id="firstName"
                    type="text"
                    className="form-control realtor-profile-transaction-contact-modal__form-field"
                    value={firstName}
                    onChange={this.onChangeFirstName}
                />
            </label>
        </div>
    );

    buildLastNameField = (lastName) => (
        <div className="form-group">
            <label
                className="realtor-profile-transaction-contact-modal__form-label"
                htmlFor="lastName"
            >
                Contact First LastName
                <input
                    id="lastName"
                    type="text"
                    className="form-control realtor-profile-transaction-contact-modal__form-field"
                    value={lastName}
                    onChange={this.onChangeLastName}
                />
            </label>
        </div>
    );

    buildEmailField = (email) => (
        <div className="form-group">
            <label
                className="realtor-profile-transaction-contact-modal__form-label"
                htmlFor="email"
            >
                Contact email
                <input
                    id="email"
                    type="email"
                    className="form-control realtor-profile-transaction-contact-modal__form-field"
                    value={email}
                    onChange={this.onChangeEmail}
                />
            </label>
        </div>
    );

    buildNotesField = (notes) => (
        <div className="form-group">
            <label
                className="realtor-profile-transaction-contact-modal__form-label"
                htmlFor="notes"
            >
                Notes
                <textarea
                    id="notes"
                    type="notes"
                    className="form-control realtor-profile-transaction-contact-modal__form-field"
                    value={notes}
                    onChange={this.onChangeNotes}
                />
            </label>
        </div>
    );

    hasSelectedContact = () => this.props.contact != null;

    buildForm = () => {
        const { contact, notes } = this.props;
        const hideIfNoContactSelected = this.hasSelectedContact()
            ? ''
            : 'invisible';

        const firmName = toString(get(contact, 'value.businessName'));
        const firstName = toString(get(contact, 'value.firstName'));
        const lastName = toString(get(contact, 'value.lastName'));
        const email = toString(get(contact, 'value.email'));

        return (
            <div className={`row ${hideIfNoContactSelected}`}>
                <div className="col-xs-12">
                    {this.buildFirmNameField(firmName)}
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    {this.buildFirstNameField(firstName)}
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    {this.buildLastNameField(lastName)}
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    {this.buildEmailField(email)}
                </div>
                <div className="col-xs-12">
                    {this.buildNotesField(notes)}
                </div>
            </div>
        );
    };

    render = () => (
        <Modal
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            className="realtor-profile-transaction-contact-modal"
        >
            <ModalHeader>
                <CloseModalButton onClick={this.props.onClose} />
            </ModalHeader>
            <ModalBody>
                <h1 className="realtor-profile-transaction-contact-modal__title">
                    Look for Attorney, Agency, Agent or Lender:
                </h1>
                {this.buildContactAutoCompleteField()}
                {this.buildForm()}
                <div className="realtor-profile-transaction-contact-modal__footer">
                    <Button
                        disabled={!this.hasSelectedContact()}
                        onClick={this.props.onSave}
                    >
                        Save
                    </Button>
                    <Button
                        inverted
                        onClick={this.props.onCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}
