import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import trim from 'lodash/trim';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import isFunction from 'lodash/isFunction';
import { Field, ErrorMessage } from 'formik';
import AutoCompleteFormikInput from '../../../AutoCompleteFormikInput/AutoCompleteFormikInput';
import { RadioField } from '../../../Form';
import { realtorWorkStatus } from '../../../../shared/constants';

const MAX_LENGTH_LICENSE_ID = '30';

export default class AboutYourJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isConfirmationVisible: false,
            disableBrokerSelection: false
        };
    }

    openConfirmationModal = () => {
        this.setState({ isConfirmationVisible: true });
    };

    closeConfirmationModal = () => {
        this.setState({ isConfirmationVisible: false });
    };

    getSelectedState = () => get(this.props.values, 'aboutYourJob.selectedState');

    // The modal is disabled by new requirements, which can be found in the following link
    // https://app.asana.com/0/1117433205535426/1124224746371975/f
    /*
    buildStateModal = () => (
        <Modal
            className="realtor-signup-state-modal"
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.state.isConfirmationVisible}
            onHide={this.closeConfirmationModal}
        >
            <ModalBody>
                <p className="signup-state-modal__content">
                    {`Thank you for your interest in becoming a LemonBrew Partner Agent in ${this.state.selectedState}. At this time, we are not licensed in ${this.state.selectedState}. You can still continue to signup and your account will be activated as soon as we are approved in ${this.state.selectedState}. If you choose not to continue, you will be the first to be notified once we are approved in ${this.state.selectedState}.`}
                </p>
                <div className="text-center">
                    <Button
                        icon 
                        dark 
                        symbol="times"
                        type="button"
                        onClick={this.closeConfirmationModal}
                    >
                        Close
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
    */

    buildFieldError = (field) => (
        <ErrorMessage name={field}>
            {(msg) => (
                <div className="field-error--content">
                    <div className="field-error">
                        {msg}
                    </div>
                </div>
            )}
        </ErrorMessage>
    );

    getFilterStateOptions = () => {
        const { realtorLicensedStates } = this.props;
        const allStateOptions = map(realtorLicensedStates, (state) => ({
            label: state.name,
            value: state.abbreviation,
        }));

        if (this.state.stateValue === null) {
            return allStateOptions;
        }

        const filterOptions = filter(
            allStateOptions,
            (item) => includes(toLower(item.label), toLower(this.state.stateValue))
        );
        return filterOptions;
    };

    handleSelectState = (value) => {
        const { realtorLicensedStates, realtorUsStates, setFieldValue } = this.props;
        const abbreviation = value;
        const selectedLicensedState = find(realtorLicensedStates, { abbreviation });
        const selectedStateObj = find(realtorUsStates, { abbreviation });

        if (selectedStateObj) {
            const selectedState = {
                label: get(selectedStateObj, 'name'),
                value: get(selectedStateObj, 'abbreviation')
            };

            setFieldValue('aboutYourJob.selectedState', selectedState);

            if (!selectedLicensedState) {
                this.openConfirmationModal();
            }

            setFieldValue('aboutYourJob.stateAbbreviation', abbreviation);
            this.setState({ disableBrokerSelection: false });

            if (isFunction(this.props.onSelectState)) {
                this.props.onSelectState();
            }
        }
    };

    handleStateChange = (value) => {
        this.setState({ stateValue: value || null });
    };

    buildStateField = () => {
        const fieldName = 'aboutYourJob.stateAbbreviation';
        return (
            <div className="state field">
                <Field
                    component={AutoCompleteFormikInput}
                    name={fieldName}
                    placeholder="WHICH STATE ARE YOU LICENSED IN?"
                    getOptions={this.getFilterStateOptions}
                    initializeGettingOptions
                    customOnChange={this.handleSelectState}
                    onInputChange={this.handleStateChange}
                    value={this.getSelectedState()}
                />
                {this.buildFieldError(fieldName)}
            </div>
        );
    };

    getRealtorPositionOptions = () => map(this.props.realtorPositions, (position) => ({
        label: trim(get(position, 'name')),
        value: position,
    }));

    handleRealtorPositionChange = (value) => {
        const { setFieldValue, setFieldTouched } = this.props;
        const fieldName = 'aboutYourJob.realtorPosition';

        setFieldValue(fieldName, value);
        setFieldTouched(fieldName);

        const selectedPositionObj = {
            value: get(value, 'positionId'),
            label: get(value, 'name')
        };

        setFieldValue('aboutYourJob.selectedRealtorPosition', selectedPositionObj);
    };

    getSelectedRealtorPosition = () => get(this.props.values, 'aboutYourJob.selectedRealtorPosition');

    buildAgentPosition = () => {
        const fieldName = 'aboutYourJob.realtorPosition';

        return (
            <div className="realtorPosition field">
                <Field
                    component={AutoCompleteFormikInput}
                    name={fieldName}
                    placeholder="POSITION"
                    getOptions={this.getRealtorPositionOptions}
                    initializeGettingOptions
                    customOnChange={this.handleRealtorPositionChange}
                    value={this.getSelectedRealtorPosition()}
                />
                {this.buildFieldError(fieldName)}
            </div>
        );
    };

    handleLicenceOnBlur = (e) => {
        const fieldName = 'aboutYourJob.licenseID';
        const val = (e.target.value || '').replace(/\s+/gi, ' ');
        this.props.setFieldValue(fieldName, val.trim());
    };

    buildLicenceIdField = () => {
        const fieldName = 'aboutYourJob.licenseID';
        return (
            <div className="licenseID field">
                <Field
                    type="text"
                    name={fieldName}
                    placeholder="REAL ESTATE LICENSE ID"
                    maxLength={MAX_LENGTH_LICENSE_ID}
                    onBlur={this.handleLicenceOnBlur}
                />
                {this.buildFieldError(fieldName)}
            </div>
        );
    };

    buildAgentWorkStatus = () => {
        const fieldName = 'aboutYourJob.realtorIsFullTime';

        return (
            <div className={`${fieldName} field`}>
                <div className="realtor__signupform__radio-wraper">
                    <Field
                        component={RadioField}
                        className="realtor__signupform__work-status"
                        name={fieldName}
                        options={realtorWorkStatus}
                    />
                    {this.buildFieldError(fieldName)}
                </div>
            </div>
        );
    };

    render = () => (
        <div className="realtor__signupform__step-container">
            <p className="realtor__signupform__header-create-account">
                Tell us about your job.
            </p>
            <section className="section personal job">
                {this.buildStateField()}
                {this.buildAgentPosition()}
                {this.buildLicenceIdField()}
                {this.buildAgentWorkStatus()}
            </section>
        </div>
    );
}
