import React from 'react';
import BrewBoardNumberCurrency from './BrewBoardNumberCurrency';

const BrewBoardNumberRange = (from, to) => {
    if (from && to) {
        return (
            <span>
                {BrewBoardNumberCurrency(from)}
                {' '}
                to
                {' '}
                {BrewBoardNumberCurrency(to)}
            </span>
        );
    }
    return null;
};

export default BrewBoardNumberRange;
