import React from 'react';
import PropTypes from 'prop-types';
import { Slider, Handles, Tracks, Rail } from 'react-compound-slider';
import Handle from './Handle';
import SliderRail from './SliderRail';
import Track from './Track';

import './SliderField.css'

const SliderField = ({
    field,
    form,
    range,
    values,
    step = 1,
    labels,
    ...props
}) => {
    return (
        <Slider
            mode={1}
            step={step}
            domain={range}
            className="slider-field"
            onUpdate={values => form.setFieldValue(field.name, values[0])}
            values={values}
        >
            <Rail>
                {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                    <div className="slider-handles">
                        {handles.map(handle => (
                            <Handle
                                key={handle.id}
                                handle={handle}
                                domain={range}
                                getHandleProps={getHandleProps}
                            />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                    <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                            <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                            />
                        ))}
                    </div>
                )}
            </Tracks>

            { Array.isArray(labels) && labels.length === 2 ?
                <div className="slider-field-labels">
                    <div className="field-label">
                        { labels[0] }
                    </div>

                    <div className="field-label">
                        { labels[1] }
                    </div>
                </div> : null
            }

        </Slider>
    );
};

SliderField.propTypes = {
    range: PropTypes.array,
    values: PropTypes.array,
};

export default SliderField;
