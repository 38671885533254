import React, {Component} from 'react';
import get from 'lodash/get';
import ReactStreetview from 'react-google-streetview';
 
class StreetView extends Component {
 
    render() {
        const lng = get(this.props, 'lng')  || ''
        const lat = get(this.props, 'lat')  || ''

        const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        const streetViewPanoramaOptions = {
            position: {lat:Number(lat), lng:Number(lng)},
            pov: {heading: 100, pitch: 0},
            zoom: 1
        };
 
        return (
            <div style={{
                width: '100%',
                height: '50%',
                backgroundColor: '#d0ebdc'
            }}>
                <ReactStreetview
                    apiKey={googleMapsApiKey}
                    streetViewPanoramaOptions={streetViewPanoramaOptions}
                />
            </div>
        );
    }
}

export default StreetView;
