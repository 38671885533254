import React from 'react';
import './BrewingMachine.css';

export default () =>
    <div className="brewing--machine">
        <div className="brewing--machine--tray" />

        <div className="brewing--machine--juice--container">
            <div className="brewing--machine--juice--lemonade" />
            <div className="brewing--machine--juice--levels">
                <i/><i/><i/><i/><i/><i/><i/><i/><i/><i/><i/><i/><i/>
            </div>
        </div>

        <div className="brewing--machine--gauge" />

        <div className="brewing--machine--lemons--container">
            <i/><i/><i/><i/><i/><i/><i/><i/>
        </div>

        <div className="brewing--machine--lemons--cover"/>

        <div className="brewing--machine--lemons--stack"/>

        <div className="brewing--machine--document--container">
            <div className="brewing--machine--document--item" />
            <div className="brewing--machine--document--item" />
            <div className="brewing--machine--document--item" />
        </div>
    </div>;
