import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { NumberFormat } from '../NumberFormat/NumberFormat';
import { Button } from '../Button/Button';

import './realtoractivesellermatchitem.css';

export default class RealtorActiveSellerMatchItem extends React.PureComponent {
    static propTypes = {
        picture: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        budget: PropTypes.any.isRequired,
        maxBudget: PropTypes.any.isRequired,
        status: PropTypes.string.isRequired,
        viewProfileUrl: PropTypes.string,
    };

    render = () => (
        <div className="realtor-active-seller-match-item">
            <div className="realtor-active-seller-match-item__header">
                <img src={this.props.picture} alt={this.props.name} className="realtor-active-seller-match-item__picture" />
                <div>
                    <h3 className="realtor-active-seller-match-item__name">{this.props.name}</h3>
                    <h5 className="realtor-active-seller-match-item__location">{this.props.location}</h5>
                </div>
            </div>
            <div className="realtor-active-seller-match-item__content">
                <div className="realtor-active-seller-match-item__budget">
                    <div className="realtor-active-seller-match-item__budget-amount">
                        <span>
                            <NumberFormat
                                number={get(this.props.budget, 'amount')}
                                currency={get(this.props.budget, 'currency')}
                                decimals={0}
                                format="currency"
                            />
                            {' - '}
                            <NumberFormat
                                number={get(this.props.maxBudget, 'amount')}
                                currency={get(this.props.maxBudget, 'currency')}
                                decimals={0}
                                format="currency"
                            />
                        </span>
                    </div>
                    <div className="realtor-active-seller-match-item__budget-label">
                        Budget
                    </div>
                </div>
                <div className="realtor-active-seller-match-item__brew-score">
                    <div className="realtor-active-seller-match-item__brew-score-amount">
                        {this.props.timeFrame}
                    </div>
                    <div className="realtor-active-seller-match-item__brew-score-label">
                        Time Frame
                    </div>
                </div>
            </div>
            <div className="realtor-active-seller-match-item__footer">
                <div className="realtor-active-seller-match-item__match-status">
                    {this.props.status}
                </div>
                <Link
                    className="realtor-navbar-avatar__link"
                    to={this.props.viewProfileUrl}
                >
                    <Button 
                        small
                        yellow
                    >
                        View Profile
                    </Button>
                </Link>
            </div>
        </div>
    );
}
