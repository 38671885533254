import find from 'lodash/find';

export const StepMap = [
    {
        "id": 1,
        "type": "buyer_flow_brew",
        "step": "home_purchase_location",
        "stepLabel": "Your Location",
        "stepOrder": 10
    },
    {
        "id": 2,
        "type": "buyer_flow_brew",
        "step": "home_type",
        "stepLabel": "Type of Home",
        "stepOrder": 20
    },
    {
        "id": 3,
        "type": "buyer_flow_brew",
        "step": "home_preferences",
        "stepLabel": "Preferences",
        "stepOrder": 30
    },
    {
        "id": 4,
        "type": "buyer_flow_brew",
        "step": "purchase_timeline",
        "stepLabel": "Timeline",
        "stepOrder": 40
    },
    {
        "id": 5,
        "type": "buyer_flow_brew",
        "step": "home_budget",
        "stepLabel": "Budget",
        "stepOrder": 50
    },
    {
        "id": 6,
        "type": "buyer_flow_brew",
        "step": "marital_status",
        "stepLabel": "About You",
        "stepOrder": 60
    },
];

export const getStepInfoByKey = key => find(StepMap, { step: key });
