import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import invoke from "lodash/invoke";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import Button from "components/Button/Button";
import "./admin-profile-buyer-results-table.css";

export default class AdminProfileBuyerResultsTable extends React.PureComponent {
    getState = stat => get(stat, "state");

    getNewBuyers = stat => Number(get(stat, "new")) || 0;

    getMatchedBuyers = stat => Number(get(stat, "matched")) || 0;

    getUnmatchedBuyers = stat => Number(get(stat, "unmatched")) || 0;

    buildHeader = () => (
        <thead className="admin-profile-buyer-results-table__table-head">
            <tr>
                <th className="text-left">State</th>
                <th className="text-center">New buyers</th>
                <th className="text-center">Matched with an agent</th>
                <th className="text-center">Unmatched</th>
            </tr>
        </thead>
    );

    buildCellForStat = (value, state) => (
        <Link to="/admin-profile">
            <Button
                small
                text
                onClick={() => invoke(this.props, "onClickStat", state)}
            >
                {value}
            </Button>
        </Link>
    );

    buildRow = (stat, key) => {
        const state = this.getState(stat);
        const newBuyers = this.getNewBuyers(stat);
        const matchedBuyers = this.getMatchedBuyers(stat);
        const unmatchedBuyers = this.getUnmatchedBuyers(stat);

        return (
            <tr key={key}>
                <td className="text-left">
                    <strong>{state}</strong>
                </td>
                <td className="text-center">
                    {this.buildCellForStat(newBuyers, state)}
                </td>
                <td className="text-center">
                    {this.buildCellForStat(matchedBuyers, state)}
                </td>
                <td className="text-center">
                    {this.buildCellForStat(unmatchedBuyers, state)}
                </td>
            </tr>
        );
    };

    buildNoResultsRow = () => (
        <tr>
            <td colSpan={4}>No results found for selected period</td>
        </tr>
    );

    buildContent = () => (
        <tbody>
            {map(this.props.statsByState, this.buildRow)}
            {isEmpty(this.props.statsByState) && this.buildNoResultsRow()}
        </tbody>
    );

    render = () => (
        <table className="table admin-profile-buyer-results-table">
            {this.buildHeader()}
            {this.buildContent()}
        </table>
    );
}
