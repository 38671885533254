import React from "react";
import * as PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import noop from "lodash/noop";
import { Link } from "react-router-dom";
import { NumberFormat } from "../NumberFormat/NumberFormat";
import {
    formatAddressStreetApartment,
    formatAddressCityStateZipCode
} from "../../util/util";
import DialogModal from "../DialogModal/DialogModal";
import {
    DRAFT,
    CANCEL,
    FAIL,
    COMPLETE,
    IN_PROGRESS
} from "../EarnestMoneyDepositStatusPill/EarnestMoneyDepositStatusPill";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { Button } from "../Button/Button";
import StatusRow from "../StatusRow/StatusRow";
import { STATUS_EMD } from "../../shared/constants";

import "./earnestmoneydepositprofiledeposit.css";

export default class EarnestMoneyDepositProfileDeposit extends React.Component {
    static propTypes = {
        deposit: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string
        }),
        depositId: PropTypes.number,
        stateButtonShowDeposit: PropTypes.bool,
        toggleShowDeposit: PropTypes.func,
        property: PropTypes.object,
        state: PropTypes.string,
        deposits: PropTypes.arrayOf(
            PropTypes.shape({
                bankIcon: PropTypes.string,
                bankName: PropTypes.string,
                bankAccountNumber: PropTypes.string,
                bankAccountName: PropTypes.string,
                creationUtcDate: PropTypes.string,
                completedUtcDate: PropTypes.string,
                deposit: PropTypes.shape({
                    amount: PropTypes.number,
                    currency: PropTypes.string
                }),
                completed: PropTypes.bool
            })
        ),
        earnestMoneyDepositCancel: PropTypes.func,
        eligibleForCancel: PropTypes.bool,
        fetchingCancelDeposit: PropTypes.bool,
        fetchCancelDeposit: PropTypes.bool,
        cancelDepositId: PropTypes.number,
        type: PropTypes.object,
        memo: PropTypes.string,
        onEdiDepositClick: PropTypes.func,
        isMidUserRole: PropTypes.bool,
        isReadOnlyUserRole: PropTypes.bool,
        isImpersonatingUser: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            isDialogModalVisible: false
        };
    }

    buildCreationDate = deposit => (
        <div>
            {get(deposit, "creationUtcDate")
                ? get(deposit, "creationUtcDate")
                : "Pending"}
        </div>
    );

    buildCompletedDate = deposit => (
        <div>{` ${
            get(deposit, "completedUtcDate")
                ? get(deposit, "completedUtcDate")
                : "Pending"
        }`}</div>
    );

    buildInProgressTooltip = () => {
        const popoverText = `Your electronic funds-transfer will be facilitated 
            by the automatic clearing house (ACH). 
            The ACH system typically takes between 
            2 and 4 business days to complete.`;
        const content = (
            <div className="emd-profile-deposit__glyph emd-profile-deposit__glyph-pointer is-unknown">
                -
            </div>
        );

        return (
            <CustomTooltip
                popoverText={popoverText}
                content={content}
                type="black"
            ></CustomTooltip>
        );
    };

    buildCompletedStateIcon = deposit => {
        const { state } = this.props;
        if (get(deposit, "completed")) {
            return <div className="profile__summary__glyph is-ok" />;
        }

        if (state === IN_PROGRESS) {
            return this.buildInProgressTooltip();
        }

        return <div className="emd-profile-deposit__glyph is-unknown">-</div>;
    };

    buildHeaderStatus = () => {
        const { state } = this.props;
        if (state === CANCEL || state === FAIL || state === COMPLETE) {
            const status = find(STATUS_EMD, { id: this.props.state });
            return `${get(status, "displayName")} ON`;
        }
        return "Completed ON";
    };

    buildDepositHeader = () => (
        <div className="hide--less-than-768">
            <div className="emd-profile-deposit__header--items">
                <div className="emd-profile-deposit__header--left">Source</div>
                <div className="emd-profile-deposit__header--left">
                    Created ON
                </div>
                <div className="emd-profile-deposit__header--left">
                    {this.buildHeaderStatus()}
                </div>
                <div className="emd-profile-deposit__header--left">Amount</div>
                <div className="emd-profile-deposit__header--left"> </div>
            </div>
        </div>
    );

    buildDisabledTooltip = content => {
        const popoverText = `You do not have sufficient privileges to perform this action`;

        return (
            <div className="emd-landing-page__tooltip">
                <CustomTooltip
                    popoverText={popoverText}
                    content={content}
                ></CustomTooltip>
            </div>
        );
    };

    buildDepositSource = (bankIcon, deposit) => {
        const isReadOnly =
            this.props.isImpersonatingUser && this.props.isReadOnlyUserRole;

        if (isReadOnly) {
            const source = (
                <div className="emd-profile-deposit__unavailable">Hidden</div>
            );
            return this.buildDisabledTooltip(source);
        }

        return (
            <>
                <div className="emd-profile-deposit__icon">{bankIcon}</div>
                <div className="emd-profile-deposit__data">
                    <div className="emd-profile-deposit__bank-name">
                        {get(deposit, "bankName")}
                    </div>
                    <div className="emd-profile-deposit__bank-account">
                        {`${get(deposit, "bankAccountNumber")} - ${get(
                            deposit,
                            "bankAccountName"
                        )}`}
                    </div>
                </div>
            </>
        );
    };

    buildDeposit = deposit => {
        const bankIcon = isEmpty(get(deposit, "bankIcon")) ? (
            <i
                style={{ fontSize: "55px" }}
                className="emd-profile-deposit__icon fa fa-university"
            />
        ) : (
            <img src={get(deposit, "bankIcon")} alt="" />
        );

        return (
            <div className="emd-profile-deposit__deposit-row">
                <div className="emd-profile-deposit__bank">
                    <div className="emd-profile-deposit__bank-info">
                        <div className="emd-profile-deposit-heading--left show--less-than-768">
                            Source
                        </div>
                        <div className="emd-profile-deposit__icon__data__plh">
                            {this.buildDepositSource(bankIcon, deposit)}
                        </div>
                    </div>
                </div>
                <div className="emd-profile-deposit__created">
                    <div className="emd-profile-deposit-heading--left show--less-than-768">
                        Created ON
                    </div>
                    {this.buildCreationDate(deposit)}
                </div>
                <div className="emd-profile-deposit__completed">
                    <div className="emd-profile-deposit-heading--left show--less-than-768">
                        Completed ON
                    </div>
                    {this.buildCompletedDate(deposit)}
                </div>
                <div className="emd-profile-deposit__deposit-amount">
                    <div className="emd-profile-deposit-heading--left show--less-than-768">
                        Amount
                    </div>
                    <NumberFormat
                        currency={get(deposit, "deposit.currency")}
                        format="currency"
                        decimals={2}
                        number={get(deposit, "deposit.amount")}
                    />
                </div>
                <div className="emd-profile-deposit__status">
                    {this.buildCompletedStateIcon(deposit)}
                </div>
            </div>
        );
    };

    onClickCancelDeposit = () => {
        const { depositId, earnestMoneyDepositCancel } = this.props;
        earnestMoneyDepositCancel(depositId);
    };

    isCancelButtonHide = () => {
        const { eligibleForCancel, state } = this.props;
        const cancel = state === CANCEL;
        const inProgress = state === IN_PROGRESS;

        return !eligibleForCancel || cancel || !inProgress;
    };

    isEditButtonHide = () => {
        const { state } = this.props;
        return state !== DRAFT;
    };

    buildCancelTooltip = content => {
        const popoverText = `This cancel button is used to stop the transfer, 
        preventing the bank transfer from processing further. 
        A bank transfer is cancellable up until 4pm CT on that 
        same business day if the transfer was initiated prior 
        to 4PM CT. If a transfer was initiated after 4pm CT, 
        it can be cancelled anytime before 4pm CT on the following 
        business day.`;

        return (
            <CustomTooltip
                popoverText={popoverText}
                content={content}
                type="black"
            ></CustomTooltip>
        );
    };

    buildDisabledButtonTooltip = buttonText => {
        const button = (
            <Button small disable type="button" onClick={noop}>
                {buttonText}
            </Button>
        );
        return this.buildDisabledTooltip(button);
    };

    buildCancelTransactionButton = () => {
        const {
            fetchingCancelDeposit,
            fetchCancelDeposit,
            cancelDepositId,
            depositId
        } = this.props;

        if (this.isCancelButtonHide()) return;

        const isReadOnly =
            this.props.isImpersonatingUser &&
            (this.props.isReadOnlyUserRole || this.props.isMidUserRole);
        if (isReadOnly) {
            return this.buildDisabledButtonTooltip("Cancel");
        }

        const isEqualCancelAndDepositId = cancelDepositId === depositId;
        const isLoading = fetchingCancelDeposit && isEqualCancelAndDepositId;
        const isDisabled = fetchCancelDeposit && isEqualCancelAndDepositId;
        const content = (
            <Button
                small
                yellow
                type="button"
                onClick={() => this.setState({ isDialogModalVisible: true })}
                isLoading={isLoading}
                disabled={isDisabled}
            >
                Cancel
            </Button>
        );

        return (
            <div className="emd-profile-deposit__cancel">
                {this.buildCancelTooltip(content)}
            </div>
        );
    };

    buildEditTransactionButton = () => {
        const { depositId } = this.props;

        if (this.isEditButtonHide()) return;

        const isReadOnly =
            this.props.isImpersonatingUser && this.props.isReadOnlyUserRole;
        if (isReadOnly) {
            return this.buildDisabledButtonTooltip("Edit");
        }

        return (
            <Link to={`/transfer/deposit/${depositId}`}>
                <div className="emd-profile-deposit__cancel">
                    <Button
                        small
                        inverted
                        type="button"
                        onClick={this.props.onEdiDepositClick}
                    >
                        Edit
                    </Button>
                </div>
            </Link>
        );
    };

    buildMemo = () => {
        if (isEmpty(this.props.memo)) return;

        return (
            <div className="emd-profile-deposit__footer-memo">
                <div className="emd-profile-deposit__memo-title">MEMO</div>
                <div className="emd-profile-deposit__memo-text">
                    {this.props.memo}
                </div>
            </div>
        );
    };

    buildDeposits = () => {
        const { deposits, stateButtonShowDeposit } = this.props;

        if (!stateButtonShowDeposit) return;

        return (
            <>
                <div className="table emd-profile-info__table-deposit">
                    <div className="emd-profile-deposit__header">
                        {this.buildDepositHeader()}
                    </div>
                    {map(deposits, deposit => (
                        <div
                            key={get(deposit, "bankAccountNumber")}
                            className="emd-profile-deposit__tbody"
                        >
                            {this.buildDeposit(deposit)}
                        </div>
                    ))}
                </div>
                {this.buildMemo()}
            </>
        );
    };

    buildMobileDeposit = deposit => (
        <div className="emd-profile-deposit__mobile-deposits-container">
            <div className="emd-profile-deposit__mobile-deposits-container bank-container">
                <div className="emd-profile-deposit__mobile-deposits-container__bank-name">
                    {get(deposit, "bankName")}
                </div>
                <div className="emd-profile-deposit__mobile-deposits-container__bank-name paddingTop">
                    {get(deposit, "bankAccountNumber")}
                </div>
            </div>
            <div className="emd-profile-deposit__mobile-deposits-container amount-container">
                <NumberFormat
                    currency={get(deposit, "deposit.currency")}
                    format="currency"
                    decimals={2}
                    number={get(deposit, "deposit.amount")}
                />
                <div className="emd-profile-deposit__mobile-deposits-container__state-icon-container">
                    {this.buildCompletedStateIcon(deposit)}
                </div>
            </div>
            <div className="emd-profile-deposit__mobile-deposits-container date-container">
                {this.buildCompletedDate(deposit)}
            </div>
        </div>
    );

    buildMobileDeposits = () => {
        const { deposits } = this.props;

        if (isEmpty(deposits)) {
            return;
        }

        return (
            <div className="emd-profile-deposit__mobile-deposits-header">
                <div
                    className="emd-profile-deposit__button"
                    onClick={() =>
                        this.props.toggleShowDeposit(this.props.depositId)
                    }
                >
                    {this.props.stateButtonShowDeposit
                        ? "Hide sources"
                        : "Show sources"}
                </div>
                <div
                    className={
                        this.props.stateButtonShowDeposit
                            ? "emd-profile-deposit__showTransactions"
                            : "emd-profile-deposit__hideTransactions closed"
                    }
                >
                    <div className="emd-profile-deposit__mobile-deposits-header__table-header">
                        SOURCE
                    </div>
                    <div className="emd-profile-deposit__mobile-deposits-header__table-header">
                        AMOUNT
                    </div>
                    <div className="emd-profile-deposit__mobile-deposits-header__table-border" />
                    {map(deposits, this.buildMobileDeposit)}
                </div>
            </div>
        );
    };

    buildAddress = () => {
        const { property } = this.props;
        const streetNumberName = get(property, "streetNumberName");
        const apartmentSuiteNumber = get(property, "apartmentSuiteNumber");
        const city = get(property, "city");
        const state = get(property, "state.abbreviation");
        const zipcode = get(property, "zipcode");

        return (
            <>
                <div>
                    {formatAddressStreetApartment(
                        streetNumberName,
                        apartmentSuiteNumber
                    )}
                </div>
                <div>{formatAddressCityStateZipCode(city, state, zipcode)}</div>
            </>
        );
    };

    onCloseDialogModal = () => this.setState({ isDialogModalVisible: false });

    onAcceptDialogModal = () => {
        this.onClickCancelDeposit();
        this.onCloseDialogModal();
    };

    buildInfoHeader = () => (
        <div className="emd-profile-info__header-info">
            <div className="emd-profile-deposit__amount-title">
                {get(this.props.type, "displayName")}
            </div>
            <div className="emd-profile-deposit__property">
                Property Address
            </div>
        </div>
    );

    buildInfoBody = () => (
        <div className="emd-profile-info__wrapper">
            <div className="emd-profile-deposit__amount">
                <div className="emd-profile-deposit__amount-title">
                    {get(this.props.type, "displayName")}
                </div>
                <NumberFormat
                    currency={get(this.props.deposit, "currency")}
                    format="currency"
                    decimals={2}
                    number={get(this.props.deposit, "amount")}
                />
                <div
                    className="emd-profile-deposit__button"
                    onClick={() =>
                        this.props.toggleShowDeposit(this.props.depositId)
                    }
                >
                    {this.props.stateButtonShowDeposit
                        ? "Hide sources"
                        : "Show sources"}
                </div>
            </div>
            <div className="emd-profile-deposit__property--address">
                <div className="emd-profile-deposit__property">
                    Property Address
                </div>
                <p>{this.buildAddress()}</p>
            </div>
            <div className="emd-profile-deposit__state-cancel">
                {this.buildEditTransactionButton()}
            </div>
            <div className="emd-profile-deposit__state-cancel">
                {this.buildCancelTransactionButton()}
            </div>
        </div>
    );

    buildInfoTable = () => (
        <div className="table emd-profile-info__table-info">
            {/*<div className="emd-profile-info__header">
                {this.buildInfoHeader()}
            </div>*/}
            <div className="emd-profile-info__tbody">
                {this.buildInfoBody()}
            </div>
        </div>
    );

    buildProfileDeposits = () => {
        const status = find(STATUS_EMD, { id: this.props.state });

        return (
            <div>
                <div className="emd-profile-deposit__badge">
                    <div className="emd-profile-deposit__state">
                        <StatusRow status={status} align="right" />
                    </div>
                </div>
                <div className="emd-profile-deposit">
                    <div className="emd-profile-deposit__table-info">
                        {this.buildInfoTable()}
                    </div>
                    <div className="emd-profile-deposit__table-deposit">
                        {this.buildDeposits()}
                    </div>
                </div>
                <br />
            </div>
        );
    };

    render = () => {
        const { isDialogModalVisible } = this.state;
        const label = "Are you sure you want to cancel the deposit?";

        return (
            <div className="emd-profile-deposit__container">
                <DialogModal
                    isVisible={isDialogModalVisible}
                    onClose={this.onCloseDialogModal}
                    onAccept={this.onAcceptDialogModal}
                    label={label}
                    acceptButtonText="Confirm"
                />
                {this.buildProfileDeposits()}
            </div>
        );
    };
}
