import React, { Component } from "react";
import { connect } from "react-redux";
import trim from "lodash/trim";
import get from "lodash/get";
import map from "lodash/map";
import delay from "lodash/delay";
import isFunction from "lodash/isFunction";
import cloneDeep from "lodash/cloneDeep";
import StatusRow from "../StatusRow/StatusRow";
import { Button } from "../Button/Button";
import RealtorMatchProfileAttachments from "../RealtorMatchProfileAttachments/RealtorMatchProfileAttachments";
import AgentsInfo from "./AgentsInfo/AgentsInfo";
import { NumberFormat } from "../NumberFormat/NumberFormat";
import RealtorMatchSummary from "../RealtorMatchSummary/RealtorMatchSummary";
import RealtorMatchItemSummary from "../RealtorMatchItemSummary/RealtorMatchItemSummary";
import RealtorMatchProfileNotes from "../RealtorMatchProfileNotes/RealtorMatchProfileNotes";
import MatchFileUploadModal from "../MatchFileUploadModal/MatchFileUploadModal";
import RealtorProfileContactModal from "../RealtorProfileContactModal/RealtorProfileContactModal";
import ChangeStatusModal from "../ChangeStatusModal/ChangeStatusModal";
import SpinnerLoader from "components/SpinnerLoader/SpinnerLoader";
import { getBrewCrewBuyer, selectBrewCrewBuyerMatch } from "util/service_api";
import {
    updateBuyerStatus,
    uploadRealtorFileAttachment,
    removeRealtorFileAttachment
} from "../../reducers/user/UserActions";

import "./admin-profile-buyer.scss";

class AdminProfileBuyer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buyer: undefined,
            isFetchingBuyer: false,
            matchIdBeingSelected: undefined,
            isDocumentSaved: false,
            isMatchFileUploadModalVisible: false,
            isChangeStatusModalVisible: false,
            selectedStatusIndex: 0,
            documents: [],
            selectedTypeFileName: 0,
            isContactModalVisible: false
        };
    }

    componentDidMount = () => {
        const userId = get(this.props.match, "params.userId");

        this.setState({ isFetchingBuyer: true });
        getBrewCrewBuyer(userId)
            .then(buyer => this.setState({ buyer }))
            .finally(() => this.setState({ isFetchingBuyer: false }));
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.isUploading === true &&
            this.props.isUploading === false
        ) {
            if (this.props.isFetchedUploading) {
                this.onSaveDocument();
                delay(this.onCloseMatchFileUploadModal, 400);
                this.reloadPage();
            }
        }
        if (
            prevProps.hasCompletedRemovingFile === false &&
            this.props.hasCompletedRemovingFile
        ) {
            this.reloadPage();
        }
        if (
            prevProps.hasCompletedUpdatingBuyerStatus === false &&
            this.props.hasCompletedUpdatingBuyerStatus
        ) {
            this.reloadPage();
        }
    }

    reloadPage = () => {
        window.scrollTo(0, 0);
        this.getBuyerProfile();
    };

    getBuyerProfile = () => {
        const userId = get(this.props.match, "params.userId");

        this.setState({ isFetchingBuyer: true });
        getBrewCrewBuyer(userId)
            .then(buyer => this.setState({ buyer }))
            .finally(() => this.setState({ isFetchingBuyer: false }));
    };

    onSaveDocument = () => this.setState({ isDocumentSaved: true });

    onUploadSelectTypeFile = selectedTypeFileName => {
        this.setState({ selectedTypeFileName });
    };

    getMatchPictureUrl = user =>
        get(user, "publicProfile.userInfo.profilePicUrl");

    buildMatchFullName = user => {
        const firstName = trim(get(user, "publicProfile.userInfo.firstName"));
        const lastName = trim(get(user, "publicProfile.userInfo.lastName"));
        const fullName = trim(`${firstName} ${lastName}`);
        return fullName;
    };

    getMatchRebatePercentage = user =>
        Number(get(user, "publicProfile.rebatePercentage")) || 0;

    getMatchBrewScore = user => get(user, "publicProfile.brewScore");

    getMatchPhone = user => get(user, "publicProfile.userInfo.phone");

    getMatchEmail = user => get(user, "publicProfile.userInfo.email");

    buildNumber = number => (
        <NumberFormat
            number={get(number, "amount")}
            currency={get(number, "currency")}
            decimals={0}
            format="currency"
        />
    );

    buildMinToMaxBudget = (budget, maxBudget) => (
        <span>
            {this.buildNumber(budget)}
            {" to "}
            {this.buildNumber(maxBudget)}
        </span>
    );

    buildMatchLoanAmount = user => ({
        amount: get(user, "publicProfile.loanAmount.amount"),
        currency: get(user, "publicProfile.loanAmount.currency")
    });

    buildMatchMortgage = user => ({
        amount: get(user, "publicProfile.mortgage.amount"),
        currency: get(user, "publicProfile.mortgage.currency")
    });

    buildMatchDownPayment = user => ({
        amount: get(user, "publicProfile.desiredDownPayment.amount"),
        currency: get(user, "publicProfile.desiredDownPayment.currency")
    });

    buildLocation = user => {
        const newAddresses = get(user, "publicProfile.newAddresses");
        return map(newAddresses, location => trim(get(location, "city"))).join(
            ", "
        );
    };

    onOpenChangeStatusModal = () =>
        this.setState({
            isChangeStatusModalVisible: true
        });

    onCloseChangeStatusModal = () =>
        this.setState({
            isChangeStatusModalVisible: false
        });

    onCloseContactModal = () => {
        this.setState({ isContactModalVisible: false });
    };

    onOpenContactModal = () => {
        this.setState({ isContactModalVisible: true });
    };

    buildCitiesBuyerIsLookingInto = match => {
        const newAddresses = get(match, "buyer.newAddresses");
        return map(newAddresses, location => {
            const streetNumberName = trim(get(location, "streetNumberName"));
            const apartmentSuiteNumber = trim(
                get(location, "apartmentSuiteNumber")
            );
            const city = trim(get(location, "city"));
            const state = trim(get(location, "state"));
            return trim(
                `${streetNumberName} ${apartmentSuiteNumber} ${city} ${state}`
            );
        });
    };

    formatFileSize = sizeInBytes => `${(Number(sizeInBytes) || 0) / 1000} KB`;

    buildMatchAttachments = (documents, buyerHasMatch) => {
        return (
            <RealtorMatchProfileAttachments
                documents={documents}
                onRemoveFile={this.onRemoveFile}
                onOpenMatchFileUploadModal={this.onOpenMatchFileUploadModal}
                buyerHasMatch={buyerHasMatch}
            />
        );
    };

    onUploadFile = async (file, match) => {
        const { selectedTypeFileName } = this.state;

        if (isFunction(this.props.onUploadFile)) {
            const realtorMatchId = get(match, "realtorMatchId");
            this.props.onUploadFile(realtorMatchId, file, selectedTypeFileName);
            this.onSaveDocument();
            delay(this.onCloseMatchFileUploadModal, 400);
        }
    };

    onOpenMatchFileUploadModal = () =>
        this.setState({
            isMatchFileUploadModalVisible: true,
            isDocumentSaved: false
        });

    onCloseMatchFileUploadModal = () =>
        this.setState({ isMatchFileUploadModalVisible: false });

    buildMatchBudget = user => ({
        amount: get(user, "publicProfile.budgetMin.amount"),
        currency: get(user, "publicProfile.budgetMin.currency")
    });

    buildMatchMaxBudget = user => ({
        amount: get(user, "publicProfile.budgetMax.amount"),
        currency: get(user, "publicProfile.budgetMax.currency")
    });

    buildDocuments = documents =>
        map(documents, document => ({
            id: get(document, "id"),
            name: get(document, "documentTypeDisplayName"),
            size: this.formatFileSize(get(document, "fileSize")),
            date: get(document, "ts"),
            url: get(document, "url")
        }));

    getMatchNotes = match => get(match, "notes");

    getMatchId = match => get(match, "buyer.userInfo.id");

    onRemoveFile = file => {
        if (isFunction(this.props.onRemoveFile)) {
            const realtorMatchId = get(
                this.state.buyer,
                "match.realtorMatchId"
            );
            const fileId = get(file, "id");
            this.props.onRemoveFile(realtorMatchId, fileId);
        }
    };

    onSelectStatus = selectedStatusIndex => {
        this.setState({ selectedStatusIndex });
    };

    onChangeServicesStatus = reason => {
        const { configApp } = this.props;
        const buyerId = get(
            this.state.buyer,
            "profile.publicProfile.userInfo.id"
        );
        const realtorId = get(this.state.buyer, "profile.realtor.userInfo.id");
        const { selectedStatusIndex } = this.state;
        const status = get(
            configApp.config,
            "matchStatuses.realtor_buyer_match_updated"
        );
        const selectedStatus = get(status, selectedStatusIndex);
        const data = {
            currentStatus: selectedStatus,
            statusNote: reason
        };
        if (isFunction(this.props.updateBuyerStatus)) {
            this.props.updateBuyerStatus({ buyerId, realtorId }, data);
        }
        this.onCloseChangeStatusModal();
    };

    buildPurchaseSummary = (
        location,
        timeFrame,
        loanAmount,
        mortgage,
        homeType,
        downPayment,
        budget,
        maxBudget
    ) => (
        <RealtorMatchSummary>
            <RealtorMatchItemSummary
                icon="IconMapPin"
                label="Location:"
                value={location}
            />
            <RealtorMatchItemSummary
                icon="IconCalendar"
                label="Timeframe:"
                value={timeFrame}
            />
            }
            <RealtorMatchItemSummary
                icon="IconBudget"
                label="Budget:"
                value={this.buildMinToMaxBudget(budget, maxBudget)}
            />
            <RealtorMatchItemSummary
                icon="IconMoney"
                label="Down Payment:"
                value={this.buildNumber(downPayment)}
            />
            <RealtorMatchItemSummary
                icon="IconLoan"
                label="Estimated Loan Amount:"
                value={this.buildNumber(loanAmount)}
            />
            <RealtorMatchItemSummary
                icon="IconMortgage"
                label="Estimated Mortgage:"
                value={this.buildNumber(mortgage)}
            />
            <RealtorMatchItemSummary
                icon="IconHouse"
                label="Property Type:"
                value={homeType}
            />
        </RealtorMatchSummary>
    );

    buildLeftSideContent = (picture, buyerHasMatch) => (
        <div className="admin__profile__buyer__side__content">
            <div>
                <img className="profile__picture" src={picture} alt="" />
                <div className="buttons">
                    <Button
                        green
                        className="contact__button"
                        onClick={this.onOpenContactModal.bind(this)}
                        disabled={!buyerHasMatch}
                    >
                        CONTACT
                    </Button>

                    <Button
                        yellow
                        className="change__status__button"
                        onClick={this.onOpenChangeStatusModal.bind(this)}
                        disabled={!buyerHasMatch}
                    >
                        CHANGE STATUS
                    </Button>
                </div>
            </div>
        </div>
    );

    buildUserInfoCard = (
        location,
        rebate,
        brewScore,
        email,
        phone,
        budget,
        maxBudget,
        name
    ) => {
        const mailLink = `mailto:${email}`;
        const phoneLink = `tel:${phone}`;

        return (
            <div className="user__info__card__container">
                <div className="name">
                    <div>{name}</div>
                </div>
                <div className="content">
                    <div className="col">
                        <div className="item">
                            <div className="key">EMAIL</div>
                            <div className="value__bold__green">
                                <a href={mailLink} target="_top">
                                    {email}
                                </a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="key">PHONE</div>
                            <div className="value__bold__green">
                                <a href={phoneLink} target="_top">
                                    {phone}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="item">
                            <div className="key">BUDGET</div>
                            <div className="value__normal">
                                {this.buildMinToMaxBudget(budget, maxBudget)}
                            </div>
                        </div>
                        <div className="item">
                            <div className="key">REBATE</div>
                            <div className="value__normal">{`${rebate}%`}</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="item">
                            <div className="key">BREWSCORE</div>
                            <div className="value__bold__green">
                                {brewScore}
                            </div>
                        </div>
                        <div className="item">
                            <div className="key">AREAS LOOKING</div>
                            <div className="value__normal">{location}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    onSuccessfullySelectMatch = match => {
        const newBuyer = cloneDeep(this.state.buyer);
        newBuyer.profile.realtor = match;
        this.setState({ buyer: newBuyer });
    };

    handleSelectMatch = match => {
        const buyerId = get(
            this.state.buyer,
            "profile.publicProfile.userInfo.id"
        );
        const matchId = get(match, "userInfo.id");

        this.setState({ matchIdBeingSelected: matchId });

        selectBrewCrewBuyerMatch(buyerId, matchId)
            .then(() => this.onSuccessfullySelectMatch(match))
            .finally(() => this.setState({ matchIdBeingSelected: undefined }));
    };

    buildAgent = () => {
        const { buyer } = this.state;
        const agent = get(buyer, "profile.realtor");

        return (
            <section>
                <h3 className="admin__profile__buyer__agent-title">Agent</h3>
                <AgentsInfo match={agent} readOnly />
            </section>
        );
    };

    buildAgentsSection = () => {
        const { buyer, matchIdBeingSelected } = this.state;
        const hasAlreadyAgent = get(buyer, "profile.hasRealtorAcceptedMatch");

        if (hasAlreadyAgent) {
            return this.buildAgent();
        }

        const matches = get(buyer, "profile.realtorMatches");
        const pickedMatch = get(buyer, "profile.realtor");
        const hasPickedMatch = pickedMatch != null;
        const isSelectingMatch = matchIdBeingSelected !== undefined;

        const isMatchBeingSelected = match =>
            get(match, "userInfo.id") === matchIdBeingSelected;

        const buildButtonLabelFor = match => {
            const isMatchSelected =
                hasPickedMatch &&
                get(match, "userInfo.id") === get(pickedMatch, "userInfo.id");

            if (isMatchSelected) {
                return "Pending";
            }

            return "Select";
        };

        return (
            <div className="agents__section__container">
                {map(matches, (match, key) => (
                    <AgentsInfo
                        key={key}
                        match={match}
                        isLoading={isMatchBeingSelected(match)}
                        disabledButton={isSelectingMatch || hasPickedMatch}
                        buttonLabel={buildButtonLabelFor(match)}
                        onClick={() => this.handleSelectMatch(match)}
                    />
                ))}
            </div>
        );
    };

    render = () => {
        if (this.state.isFetchingBuyer) {
            return <SpinnerLoader />;
        }

        const { configApp } = this.props;
        const user = get(this.state.buyer, "profile");
        const buyerId = get(user, "publicProfile.userInfo.id");
        const match = get(this.state.buyer, "match");
        const documentTypes = get(
            configApp,
            "config.documentTypes.lemonbrew_realtor_buyer_match"
        );
        const realtorStatusOptions = get(
            configApp,
            "config.matchStatuses.realtor_buyer_match_updated"
        );
        const buyerHasMatch = match ? true : false;
        const picture = this.getMatchPictureUrl(user);
        const name = this.buildMatchFullName(user);
        const rebate = this.getMatchRebatePercentage(user);
        const brewScore = this.getMatchBrewScore(user);
        const email = this.getMatchEmail(user);
        const phone = this.getMatchPhone(user);
        const budget = this.buildMatchBudget(user);
        const maxBudget = this.buildMatchMaxBudget(user);

        const brokerageName = get(user, "realtor.brokerage.name");
        const agentFirstName = get(user, "realtor.userInfo.firstName");
        const agentLastName = get(user, "realtor.userInfo.lastName");
        const agentName = `${trim(agentFirstName)} ${trim(agentLastName)}`;
        const agentPhone =
            get(user, "realtor.userInfo.phone") || "0280154722909";

        const location = this.buildLocation(user);
        const timeFrame = get(
            user,
            "publicProfile.purchaseTimeline.displayName"
        );
        const loanAmount = this.buildMatchLoanAmount(user);
        const mortgage = this.buildMatchMortgage(user);
        const homeType = get(user, "publicProfile.homeType.displayName");
        const downPayment = this.buildMatchDownPayment(user);
        const documents = this.buildDocuments(get(match, "documents"));
        const currentStatus =
            buyerHasMatch && get(match, "currentStatus")
                ? get(match, "currentStatus.name") || "Matched"
                : null;
        const citiesBuyerLookingInto = this.buildCitiesBuyerIsLookingInto(user);

        return (
            <div className="admin__profile__buyer__container">
                <MatchFileUploadModal
                    isVisible={this.state.isMatchFileUploadModalVisible}
                    onClose={this.onCloseMatchFileUploadModal}
                    onUploadFile={file => this.onUploadFile(file, match)}
                    documentTypes={documentTypes}
                    onUploadSelectTypeFile={this.onUploadSelectTypeFile}
                    isUploading={this.state.isFetchingBuyer}
                    isDocumentSaved={this.state.isDocumentSaved}
                />
                <RealtorProfileContactModal
                    isSeller={false}
                    buyerId={buyerId}
                    realtorId={get(user, "realtor.userInfo.id")}
                    brokerageName={brokerageName}
                    buyerName={name}
                    agentName={agentName}
                    agentPhone={agentPhone}
                    citiesBuyerLookingInto={citiesBuyerLookingInto}
                    isVisible={this.state.isContactModalVisible}
                    onClose={this.onCloseContactModal}
                    onSuccessfullyContact={this.onCloseContactModal}
                />
                <ChangeStatusModal
                    isVisible={this.state.isChangeStatusModalVisible}
                    onClose={this.onCloseChangeStatusModal}
                    onChangeStatus={this.onChangeServicesStatus}
                    realtorStatusOptions={realtorStatusOptions}
                    onSelectStatus={this.onSelectStatus}
                />
                <div className="admin__profile__buyer__header">
                    {currentStatus ? (
                        <StatusRow
                            status={{
                                displayName: currentStatus,
                                color: "green"
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="admin__profile__buyer__content">
                    {this.buildLeftSideContent(picture, buyerHasMatch)}
                    <div className="admin__profile__buyer__main__content">
                        {this.buildUserInfoCard(
                            location,
                            rebate,
                            brewScore,
                            email,
                            phone,
                            budget,
                            maxBudget,
                            name
                        )}
                        {this.buildAgentsSection()}
                        {this.buildPurchaseSummary(
                            location,
                            timeFrame,
                            loanAmount,
                            mortgage,
                            homeType,
                            downPayment,
                            budget,
                            maxBudget
                        )}
                        {this.buildMatchAttachments(documents, buyerHasMatch)}
                        <RealtorMatchProfileNotes
                            notes={this.getMatchNotes(match)}
                        />
                    </div>
                </div>
            </div>
        );
    };
}

export default connect(
    state => ({
        configApp: state.configApp,
        isUploading: state.user.isFetchingUploadFile,
        isFetchedUploading: state.user.isFetchedUploadFile,
        hasCompletedRemovingFile: state.user.hasCompletedRemovingFile,
        hasCompletedUpdatingBuyerStatus:
            state.user.hasCompletedUpdatingBuyerStatus
    }),
    {
        onRemoveFile: removeRealtorFileAttachment,
        onUploadFile: uploadRealtorFileAttachment,
        updateBuyerStatus
    }
)(AdminProfileBuyer);
