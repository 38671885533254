import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import iconApproved from '../../styles/assets/icon-approved.svg';
import iconAwaitingApproval from '../../styles/assets/icon-awaiting-approval.svg';
import './brokeragelabel.css';

export default class BrokerageLabel extends React.PureComponent {
    buildName = () => (
        <span className={classNames('brokerage-label__name', { small: this.props.small })}>
            {this.props.name}
        </span>
    );

    buildApprovalStatusIcon = () => (
        <img
            className="brokerage-label__icon"
            src={this.props.isApproved ? iconApproved : iconAwaitingApproval}
            alt={this.props.isApproved ? 'Approved' : 'Waiting Approval'}
        />
    );

    render = () => (
        <span className="brokerage-label">
            {this.buildName()}
            {this.props.showApprovalStatusIcon && this.buildApprovalStatusIcon()}
        </span>
    );
}

BrokerageLabel.propTypes = {
    name: PropTypes.string,
    isApproved: PropTypes.bool,
    showApprovalStatusIcon: PropTypes.bool,
    small: PropTypes.bool,
};
