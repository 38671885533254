export const RECEIVE_PROSPECT_VOA_URL = "RECEIVE_PROSPECT_VOA_URL";
export const RECEIVE_PROSPECT_VOA_STATUS = "RECEIVE_PROSPECT_VOA_STATUS";
export const RECEIVE_PROSPECT_DEPOSIT_ID = "RECEIVE_PROSPECT_DEPOSIT_ID";
export const RECEIVE_PROSPECT_DEPOSIT_ID_ACHS =
    "RECEIVE_PROSPECT_DEPOSIT_ID_ACHS";
export const ERROR_PROSPECT_DEPOSIT_ID_ACHS = "ERROR_PROSPECT_DEPOSIT_ID_ACHS";
export const RECEIVE_PROSPECT_DEPOSIT_ID_ACHS_FETCHING =
    "RECEIVE_PROSPECT_DEPOSIT_ID_ACHS_FETCHING";
export const UPDATE_PROSPECT_DEPOSIT_FETCHING =
    "UPDATE_PROSPECT_DEPOSIT_FETCHING";
export const FETCH_RECEIVE_PROSPECT_VOA_URL = "FETCH_RECEIVE_PROSPECT_VOA_URL";
export const FETCH_GET_FUNDING_SOURCES = "FETCH_GET_FUNDING_SOURCES";
export const SUCCESS_GET_FUNDING_SOURCES = "SUCCESS_GET_FUNDING_SOURCES";
export const FETCH_EARNEST_MONEY_DEPOSIT_CANCEL =
    "FETCH_EARNEST_MONEY_DEPOSIT_CANCEL";
export const SUCCESS_EARNEST_MONEY_DEPOSIT_CANCEL =
    "SUCCESS_EARNEST_MONEY_DEPOSIT_CANCEL";
export const FETCH_EARNEST_MONEY_DEPOSIT_FINISH =
    "FETCH_EARNEST_MONEY_DEPOSIT_FINISH";
export const SUCCESS_EARNEST_MONEY_DEPOSIT_FINISH =
    "SUCCESS_EARNEST_MONEY_DEPOSIT_FINISH";
export const RESET_DEPOSIT = "RESET_DEPOSIT";
export const FETCH_EDIT_PROSPECT_INFO = "FETCH_EDIT_PROSPECT_INFO";
export const SUCCESS_EDIT_PROSPECT_INFO = "SUCCESS_EDIT_PROSPECT_INFO";
export const FETCH_PROSPECT_SEND_EMAILS = "FETCH_PROSPECT_SEND_EMAILS";
export const SUCCESS_PROSPECT_SEND_EMAILS = "SUCCESS_PROSPECT_SEND_EMAILS";
export const FETCH_TRANSFER_RECEIPT = "FETCH_TRANSFER_RECEIPT";
export const SUCCESS_TRANSFER_RECEIPT = "SUCCESS_TRANSFER_RECEIPT";
export const FETCH_PROSPECT_SEND_WIRE_INSTRUCTIONS =
    "FETCH_PROSPECT_SEND_WIRE_INSTRUCTIONS";
export const SUCCESS_PROSPECT_SEND_WIRE_INSTRUCTIONS =
    "SUCCESS_PROSPECT_SEND_WIRE_INSTRUCTIONS";
export const ERROR_PROSPECT_SEND_WIRE_INSTRUCTIONS =
    "ERROR_PROSPECT_SEND_WIRE_INSTRUCTIONS";
export const FETCH_PROSPECT_SEND_CERTIFIED_INFO =
    "FETCH_PROSPECT_SEND_CERTIFIED_INFO";
export const SUCCESS_PROSPECT_SEND_CERTIFIED_INFO =
    "SUCCESS_PROSPECT_SEND_CERTIFIED_INFO";
export const ERROR_PROSPECT_SEND_CERTIFIED_INFO =
    "ERROR_PROSPECT_SEND_CERTIFIED_INFO";
