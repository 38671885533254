import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import split from 'lodash/split';
import { getQueryVariable } from '../../util/util';
import SignInForm from './SignInForm';
import OnboardingModal from './OnboardingModal';

export default class SignInOnboardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOnboardModalVisible: false,
            isOnboardSuccessful: true
        };
    }

    componentDidMount = () => {
        if (!this.hasParam('token')) {
            return;
        }

        if (!this.isTokenFromDocusign()) {
            const token = this.getParam('token');
            this.props.signupUser(token);
            return;
        }

        this.setState({
            isOnboardModalVisible: true,
            isOnboardSuccessful: this.isTokenFromSuccessConfirmation()
        });
    };

    hasParam = (paramName) => {
        const { location } = this.props;
        const search = trim(get(location, 'search'));
        const param = trim(paramName);
        return search.includes(param);
    };

    getParam = (paramName) => {
        const { location } = this.props;
        const search = trim(get(location, 'search'));
        const path = get(split(search, '?'), 1);
        const param = trim(paramName);
        return getQueryVariable(path, param);
    };

    getDocusignParam = () => this.getParam('event');

    isTokenFromDocusign = () => {
        const docusignParam = trim(this.getDocusignParam());
        const hasDocusignParam = docusignParam.length > 0;
        return hasDocusignParam;
    };

    isTokenFromSuccessConfirmation = () => this.getDocusignParam() === 'signing_complete';

    closeOnboardModal = () => {
        this.setState({ isOnboardModalVisible: false });
    };

    buildOnboardModal = () => (
        <OnboardingModal
            isVisible={this.state.isOnboardModalVisible}
            isSuccessful={this.state.isOnboardSuccessful}
            onClose={this.closeOnboardModal}
        />
    );

    render = () => (
        <React.Fragment>
            {this.buildOnboardModal()}
            <SignInForm {...this.props} />
        </React.Fragment>
    );
}
