import React from "react";
import PlacesAutocomplete, {
    geocodeByAddress
} from "react-places-autocomplete";

import "./LocationAutocompleteField.css";

export default ({
    field,
    form,
    options = [],
    placeholder = "ADDRESS",
    ...props
}) => {
    const handleChange = value => form.setFieldValue(field.name, value);

    const handleSelect = value => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name, true);

        if (typeof props.onPlaceDetails === "function") {
            geocodeByAddress(value).then(result =>
                props.onPlaceDetails(result)
            );
        }
    };

    const handleError = () => {
        form.setFieldValue(field.name, "");
        form.setFieldTouched(field.name, true);
    };

    return (
        <div className={`custom__input ${props.className || ""}`}>
            <PlacesAutocomplete
                value={field.value}
                onChange={handleChange}
                onSelect={handleSelect}
                onError={handleError}
                searchOptions={{
                    componentRestrictions: { country: ["us"] },
                    types: ["address"]
                }}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                }) => (
                    <div style={{ position: "relative" }}>
                        <input
                            {...getInputProps({
                                autoComplete: "no",
                                className: "location-search-input",
                                placeholder
                            })}
                            onBlur={() => {
                                const [{ description = "" } = {}] = suggestions;
                                if (description) handleSelect(description);

                                const { onBlur } = getInputProps();
                                if (onBlur) onBlur();
                            }}
                        />

                        {loading && (
                            <div className="input-hint">
                                <i
                                    style={{ textAlign: "center", padding: 0 }}
                                    className="fas fa-spinner fa-spin"
                                />
                            </div>
                        )}

                        <div
                            className={`${
                                suggestions.length
                                    ? "location__autocomplete__field"
                                    : ""
                            }`}
                        >
                            {suggestions.map(suggestion => (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className: `location__autocomplete__field__option ${
                                            suggestion.active ? "is-active" : ""
                                        }`
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    );
};
