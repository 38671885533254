import React from "react";
import * as PropTypes from "prop-types";
import isNumber from "lodash/isNumber";
import { NumberFormat } from "../NumberFormat/NumberFormat";

class AdminProfileStatsAgentInfo extends React.Component {
    buildPercentageValue = value => {
        if (value >= 0) {
            return (
                <div className="admin-profile-stats__information-symbol">
                    <i
                        className="fa fa-caret-up admin-profile-stats__information-positive-symbol"
                        aria-hidden="true"
                    ></i>
                    <div className="admin-profile-stats__information-value admin-profile-stats__information-value-positive">
                        {`${value.toFixed(2)}%`}
                    </div>
                </div>
            );
        }

        const valuePositive = isNumber(value)
            ? Math.abs(value.toFixed(2))
            : value;
        return (
            <div className="admin-profile-stats__information-symbol">
                <i
                    className="fa fa-caret-down admin-profile-stats__information-negative-symbol"
                    aria-hidden="true"
                ></i>
                <div className="admin-profile-stats__information-value admin-profile-stats__information-value-negative">
                    {`${valuePositive}%`}
                </div>
            </div>
        );
    };

    buildNumberWithFormat = number => (
        <NumberFormat format="currency" decimals={0} number={number} />
    );

    render = () => (
        <div className="admin-profile-stats__information-agents">
            <div className="admin-profile-stats__information-agents-title">
                {this.props.title}
            </div>
            <div className="admin-profile-stats__information-agents-container">
                <div className="admin-profile-stats__information-agents-total">
                    {this.props.withFormat
                        ? this.buildNumberWithFormat(this.props.number)
                        : this.props.number}
                </div>
                {this.props.alignCenter ? null : (
                    <div className="admin-profile-stats__information-agents-percentages">
                        {this.props.hideMtd ? null : (
                            <div className="admin-profile-stats__information-agents-mtd">
                                <div className="admin-profile-stats__information-percentages-title">
                                    MTD:
                                </div>
                                {this.buildPercentageValue(this.props.mtd)}
                            </div>
                        )}
                        {this.props.hideYtd ? null : (
                            <div className="admin-profile-stats__information-agents-ytd">
                                <div className="admin-profile-stats__information-percentages-title">
                                    YTD:
                                </div>
                                {this.buildPercentageValue(this.props.ytd)}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

AdminProfileStatsAgentInfo.defaultProps = {
    hideMtd: false,
    hideYdt: false,
    withFormat: false,
    alignCenter: false
};

AdminProfileStatsAgentInfo.propTypes = {
    title: PropTypes.string,
    number: PropTypes.number,
    mtd: PropTypes.number,
    ytd: PropTypes.number,
    hideMtd: PropTypes.bool,
    hideYdt: PropTypes.bool,
    withFormat: PropTypes.bool,
    alignCenter: PropTypes.bool
};

export default AdminProfileStatsAgentInfo;
