import React, { Fragment } from "react";
import get from "lodash/get";
import set from "lodash/set";
import each from "lodash/each";
import forEach from "lodash/forEach";
import { Field, FieldArray } from "formik";
import { Button } from "../../../../components/Button/Button";
import { EmptyLocation, Locality } from "../Shared";
import { AddressAutocompleteField, CityAutocompleteField } from "../../../Form";
import BaseWizardStep from "../BaseWizardStep";
import { getAddressFromPlaceDetails } from "../../../../util/util";
import { adaptStreetAddress, adaptCityAndState } from "../Shared";
import mixpanel from "mixpanel-browser";

const AddressFields = Object.keys(Locality);

class StepAddress extends BaseWizardStep {
    constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }

    updateBudgetsAndAdvance = () => {
        this.setState({ isLoading: true });
        this.updateBudgetOptions().finally(this.advanceStep);
    };

    advanceStep = () => {
        const hasMixPanelTracking = true;
        setTimeout(
            () =>
                this.props.onNavigateStep(
                    1,
                    hasMixPanelTracking,
                    this.mixpanelFunction
                ),
            0
        );
    };

    mixpanelFunction = () => {
        const { values } = this.props;
        const hasKnownLocation = get(values, "hasKnownLocation") === "yes";
        const textTitle = hasKnownLocation
            ? `What's The Address`
            : "Where Are You Looking To Buy";
        let locations = {};
        if (hasKnownLocation) {
            forEach(get(values, "knownLocations"), (value, index) => {
                set(locations, `newAddress${index}`, get(value, "address"));
                set(
                    locations,
                    `newAddressApartmentSuiteNumber${index}`,
                    get(value, "apartmentSuiteNumber")
                );
                set(
                    locations,
                    `newAddressZipcode${index}`,
                    get(value, "zipcode")
                );
                set(
                    locations,
                    `newAddressNeighborhood${index}`,
                    get(value, "neighborhood")
                );
                set(locations, `newAddressCity${index}`, get(value, "city"));
                set(locations, `newAddressState${index}`, get(value, "state"));
                set(locations, `newAddressLat${index}`, get(value, "lat"));
                set(locations, `newAddressLng${index}`, get(value, "lng"));
            });
        } else {
            forEach(get(values, "approximateLocations"), (value, index) => {
                set(
                    locations,
                    `newAddressLocation${index}`,
                    get(value, "location")
                );
                set(
                    locations,
                    `newAddressNeighborhood${index}`,
                    get(value, "neighborhood")
                );
                set(locations, `newAddressCity${index}`, get(value, "city"));
                set(locations, `newAddressState${index}`, get(value, "state"));
                set(locations, `newAddressLat${index}`, get(value, "lat"));
                set(locations, `newAddressLng${index}`, get(value, "lng"));
            });
        }

        mixpanel.track(`Completed ${textTitle}`, {
            ...locations
        });
    };

    updatePropertyFields = result => {
        const { setFieldValue, customBudgetRange, values } = this.props;

        const { homeType, monthlyTaxes, taxRate } = result;

        const buyerHasPickedOutNewHome =
            get(values, "hasKnownLocation") === "no";
        setFieldValue(
            "buyerHomeType",
            homeType || buyerHasPickedOutNewHome ? undefined : "attached"
        );
        setFieldValue("buyerMonthlyTaxes", monthlyTaxes || undefined);
        setFieldValue("buyerTaxRate", taxRate || undefined);

        const budgetRange = customBudgetRange.get();
        setFieldValue("budget", budgetRange.value);
    };

    updateBudgetOptions() {
        const {
            setFieldValue,
            setFieldTouched,
            customBudgetRange,
            values
        } = this.props;

        const buyerHasPickedOutNewHome =
            get(values, "hasKnownLocation") === "yes";
        const knownLocations = get(values, "knownLocations");
        const approximateLocations = get(values, "approximateLocations");
        const buyerNewAddresses = buyerHasPickedOutNewHome
            ? knownLocations.map(adaptStreetAddress)
            : approximateLocations.map(adaptCityAndState);

        each(["budget", "downPayment"], field => {
            setFieldValue(field, "");
            setFieldTouched(field, false);
        });

        return customBudgetRange
            .update({ buyerHasPickedOutNewHome, buyerNewAddresses })
            .then(this.updatePropertyFields);
    }

    renderKnownLocation() {
        const {
            setFieldValue,
            setFieldTouched,
            values: { knownLocations }
        } = this.props;

        const updateAddressDetails = (index, [details] = []) => {
            const components = getAddressFromPlaceDetails(details);

            [...AddressFields, "zipcode"].forEach(key => {
                const field = `knownLocations.${index}.${key}`;
                setFieldValue(field, components[key]);
                setFieldTouched(field);
            });
        };

        return (
            <Fragment>
                <FieldArray
                    name="knownLocations"
                    render={() => (
                        <div className="buyer__signup__fieldset">
                            {knownLocations.map((location, index) => (
                                <div
                                    key={index}
                                    className="buyer__signup__field__row"
                                >
                                    <div className="buyer__signup__field width-70">
                                        <Field
                                            component={AddressAutocompleteField}
                                            name={`knownLocations.${index}.address`}
                                            placeholder="Address"
                                            onPlaceDetails={details =>
                                                updateAddressDetails(
                                                    index,
                                                    details
                                                )
                                            }
                                        />
                                        {/* {this.renderFieldError(
                                            `knownLocations.${index}.address`
                                        )}
                                        {this.renderFieldError(
                                            `knownLocations.${index}.zipcode`
                                        )} */}
                                    </div>

                                    <div className="buyer__signup__field width-30">
                                        <Field
                                            type="text"
                                            name={`knownLocations.${index}.apartmentSuiteNumber`}
                                            placeholder="Unit/Apt #"
                                        />
                                        {this.renderFieldError(
                                            `knownLocations.${index}.apartmentSuiteNumber`
                                        )}
                                    </div>
                                </div>
                            ))}

                            <div className="buyer__signup__field__row">
                                <div className="buyer__signup__field">
                                    {/* {this.renderFormError("knownLocations")} */}
                                </div>
                            </div>
                        </div>
                    )}
                />
            </Fragment>
        );
    }

    renderApproxLocation() {
        const {
            setFieldValue,
            setFieldTouched,
            values: { approximateLocations }
        } = this.props;

        const updateCityDetails = (index, [details] = []) => {
            const components = details
                ? getAddressFromPlaceDetails(details)
                : {};

            AddressFields.forEach(key => {
                const field = `approximateLocations.${index}.${key}`;
                setFieldValue(field, components[key]);
                setFieldTouched(field);
            });
        };

        const resetIfEmptyValue = (index, value) => {
            if (value) {
                setFieldTouched(
                    `approximateLocations.${index}.location`,
                    false
                );
                setFieldTouched(`approximateLocations.${index}.city`, false);
                setFieldTouched(`approximateLocations.${index}.state`, false);
                return;
            }
            updateCityDetails(index);
            setFieldTouched(`approximateLocations.${index}.location`, false);
        };

        return (
            <Fragment>
                <FieldArray
                    name="approximateLocations"
                    render={arrayHelpers => (
                        <div className="buyer__signup__fieldset">
                            {approximateLocations.map((location, index) => (
                                <div
                                    key={index}
                                    className="buyer__signup__field__row no-wrap"
                                >
                                    <div className="buyer__signup__field">
                                        <Field
                                            component={CityAutocompleteField}
                                            name={`approximateLocations.${index}.location`}
                                            placeholder="CITY, STATE"
                                            onSelect={details =>
                                                updateCityDetails(
                                                    index,
                                                    details
                                                )
                                            }
                                            onInput={value =>
                                                resetIfEmptyValue(index, value)
                                            }
                                        />
                                        {/* {this.renderFieldError(
                                            `approximateLocations.${index}.state`
                                        )}
                                        {this.renderFieldError(
                                            `approximateLocations.${index}.location`
                                        )} */}
                                    </div>

                                    <div className="buyer__signup__field width-0 no-wrap">
                                        <Button
                                            type="button"
                                            icon
                                            red
                                            symbol="minus"
                                            disabled={
                                                approximateLocations.length < 2
                                            }
                                            onClick={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        ></Button>
                                        &nbsp;
                                        <Button
                                            type="button"
                                            icon
                                            symbol="plus"
                                            onClick={() =>
                                                arrayHelpers.push({
                                                    ...EmptyLocation
                                                })
                                            }
                                        ></Button>
                                    </div>
                                </div>
                            ))}

                            <div className="buyer__signup__field__row">
                                <div className="buyer__signup__field">
                                    {this.renderFormError(
                                        "approximateLocations"
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                />
            </Fragment>
        );
    }

    render() {
        const {
            values: { hasKnownLocation }
        } = this.props;

        const { isLoading } = this.state;
        const isStepFulfilled =
            (hasKnownLocation === "yes" &&
                this.areFieldsValid(["knownLocations"])) ||
            (hasKnownLocation === "no" &&
                this.areFieldsValid(["approximateLocations"]));

        const textTitle =
            hasKnownLocation === "yes"
                ? `What's the address?`
                : "Where are you looking to buy?";

        return (
            <Fragment>
                <div className="buyer__signup__step is-highest">
                    <div className="buyer__signup__question white-font">
                        {textTitle}
                    </div>

                    {hasKnownLocation === "yes"
                        ? this.renderKnownLocation()
                        : hasKnownLocation === "no"
                        ? this.renderApproxLocation()
                        : null}
                </div>
                {this.renderStepButtons(
                    {
                        canProceed: isStepFulfilled,
                        hasMixPanelTracking: true,
                        isLoading,
                        handleNext: this.updateBudgetsAndAdvance
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepAddress;
