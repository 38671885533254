import React from "react";
import CustomRealtorAutoCompleteField from "../../CustomRealtorAutoCompleteField/CustomRealtorAutoCompleteField";

import "./certifications.scss";

const Certifications = props => {
    return (
        <div className="certifications__container">
            <div className="level__one__text">
                Do you have any certifications?
            </div>
            <CustomRealtorAutoCompleteField
                fieldProp="professionalCertifications"
                labelProp="name"
                autoCompleteUri="autocomplete/professional-certification"
                placeholder="Select your certifications (You can select more than one)"
                value={props.value}
                isMulti
                onEdit={props.onEdit}
                allowRepeatedValues={false}
                allowSelectingWithCheckbox
            />
        </div>
    );
};

export default Certifications;
