import React, { Fragment } from "react";
import { Field } from "formik";
import BaseWizardStep from "../../BuyerSignUp/BaseWizardStep";
import { CheckboxField } from "../../../Form";
import PasswordStrengthInfoTooltip from "../../../PasswordStrengthInfoTooltip";
import { Link } from "react-router-dom";
import RecaptchaFormikField from "../../../RecaptchaFormikField/RecaptchaFormikField";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";

class StepConfirm extends BaseWizardStep {
    render() {
        const isStepFulfilled = this.areFieldsValid([
            "firstName",
            "lastName",
            "password",
            "password2",
            "email",
            "acceptedTermsAndConditions",
            "recaptchaSuccessfullyCompleted"
        ]);

        return (
            <Fragment>
                <ScrollToTopOnMount />
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__fieldset is-background-white">
                        <div className="buyer__signup__title">
                            Create your account
                        </div>

                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field">
                                <Field
                                    type="text"
                                    name="firstName"
                                    placeholder="FIRST NAME"
                                />
                                {this.renderFieldError("firstName")}
                            </div>

                            <div className="buyer__signup__field">
                                <Field
                                    type="text"
                                    name="lastName"
                                    placeholder="LAST NAME"
                                />
                                {this.renderFieldError("lastName")}
                            </div>
                        </div>

                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field">
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="EMAIL"
                                />
                                {this.renderFieldError("email")}
                            </div>
                        </div>

                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field">
                                <Field
                                    type="password"
                                    name="password"
                                    placeholder="PASSWORD"
                                />
                                {this.renderFieldError("password")}
                            </div>

                            <div
                                className="buyer__signup__field password-secondary"
                                style={{ position: "relative" }}
                            >
                                <Field
                                    type="password"
                                    name="password2"
                                    placeholder="CONFIRM PASSWORD"
                                />
                                {this.renderFieldError("password2")}

                                <div className="buyer__signup__field__tally">
                                    <PasswordStrengthInfoTooltip />
                                </div>
                            </div>
                        </div>

                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field buyer__signup__field_accept_term_checkbox width-5 align-center">
                                <Field
                                    id="acceptedTermsAndConditions"
                                    component={CheckboxField}
                                    name="acceptedTermsAndConditions"
                                    value
                                    checked={false}
                                />
                            </div>

                            <div className="buyer__signup__field width-95">
                                <label
                                    className="buyer__signup__checkbox__label"
                                    htmlFor="acceptedTermsAndConditions"
                                >
                                    By clicking &quot;Finish&quot;, I agree with
                                    LemonBrew&apos;s{" "}
                                    <Link
                                        to="/terms-of-use-seller"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Terms of Use
                                    </Link>
                                    ,{" "}
                                    <Link
                                        to="/privacy-policy-seller"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        to="/summary-of-rights-seller"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Summary of Your Rights
                                    </Link>
                                    .
                                </label>
                                {this.renderFieldError(
                                    "acceptedTermsAndConditions"
                                )}
                            </div>
                        </div>

                        <div className="buyer__signup__field__row with-margin buyer__signup__field__center">
                            <div className="buyer__signup__field">
                                <Field
                                    component={RecaptchaFormikField}
                                    name="recaptchaSuccessfullyCompleted"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    buttonsWithoutMargin: true
                })}
            </Fragment>
        );
    }
}

export default StepConfirm;
