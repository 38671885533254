import React from 'react';
import PropTypes from 'prop-types';
import './tabHighlighted.css';

class TabHighlighted extends React.PureComponent {
    render() {
        return (
            <div className="new-tab-styled">
                <span>{this.props.children}</span>
                <div className={`new-tab-styled__${this.props.style}`} />
            </div>
        );
    }
}

TabHighlighted.propTypes = {
    style: PropTypes.string,
    children: PropTypes.node,
};

TabHighlighted.defaultProps = {
    style: 'highlighted',
};

export default TabHighlighted;
