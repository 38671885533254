import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import size from 'lodash/size';
import invoke from 'lodash/invoke';
import isFunction from 'lodash/isFunction';
import Progress from './ProgressHeader/Progress';
import StepButtons from './Footer/StepButtons';

import './wizard.css';

export default class Wizard extends React.PureComponent {
    static propTypes = {
        steps: PropTypes.arrayOf(PropTypes.shape({
            /**
             * If true, the label won't be visible in the progress header
             * (useful for review or confirmation/final steps)
             */
            hideInProgressHeader: PropTypes.bool,
            /**
             * If true, the step buttons (back, skip, next, etc.) will be hidden.
             * Useful for confirmation or final steps
             */
            hideStepButtons: PropTypes.bool,
            /**
             * If true, the step is submittable, meaning, instead of displaying a
             * "Next" button, it will display a "Finish" and the form will be submitted
             */
            isSubmittable: PropTypes.bool,
            /**
             * Callback executed when user clicks on the step label from the progress header
             */
            onNavigate: PropTypes.func,
            /**
             * If true, a check mark will appear in the progress header.
             * Optional, since the Wizard component will take care of setting it if necessary
             */
            isCompleted: PropTypes.bool,
            /**
             * If true, a cross mark will appear in the progress header
             */
            isSkipped: PropTypes.bool,
            /**
             * If true, a "Skip" button will appear in the step buttons
             */
            canSkip: PropTypes.bool,
            /**
             * Label to be used in the progress bar. It can be a component,
             * string or a function
             */
            label: PropTypes.any,
            /**
             * Content of the step. It can be a component,
             * string or a function
             */
            component: PropTypes.any
        })),
        /**
         * Current visible step (from 0)
         */
        currentStep: PropTypes.number,
        /**
         * Callback executed when the user clicks on the "Back" button.
         * The arguments are: step, stepIndex, steps.
         * step & stepIndex refer to the step we are navigating into
         */
        onBack: PropTypes.func,
        /**
         * Callback executed when the user clicks on the "Next" button.
         * The arguments are: step, stepIndex, steps.
         * step & stepIndex refer to the step we are navigating into
         */
        onNext: PropTypes.func,
        /**
         * Callback executed when the user clicks on the "Skip" button.
         * The arguments are: step, stepIndex, steps.
         * step & stepIndex refer to the step we are navigating into
         */
        onSkip: PropTypes.func,
        /**
         * Callback executed when the user clicks on the "Finish" button
         */
        onFinish: PropTypes.func,
        /**
         * Callback executed when the user navigates to another step (by either
         * clicking on the step buttons or the progress bar)
         * The arguments are: step, stepIndex, steps.
         * step & stepIndex refer to the step we are navigating into
         */
        onNavigate: PropTypes.func,
        /**
         * If true, the progress bar at the top will be visible
         */
        displayProgressHeader: PropTypes.bool,
        /**
         * If true, the submit/finish button will display a loading spinner
         */
        isSubmitting: PropTypes.bool,
        /**
         * If true, blocks any kind of navigation (back, next, etc.).
         * Useful when form is being submitted
         */
        blockNavigation: PropTypes.bool,
        /**
         * Blocks any navigation prior to a particular step.
         * Example, if blockGoingBackwardsFromStep === 2, the user
         * wont be able to backwards to step 0 and 1
         */
        blockGoingBackwardsFromStep: PropTypes.number,
        /**
         * Prevents the user from going forward
         */
        blockForward: PropTypes.bool,
    };

    static defaultProps = {
        blockGoingBackwardsFromStep: 0,
        blockNavigation: false,
        blockForward: false,
        currentStep: 0,
        displayProgressHeader: true,
        isSubmitting: false,
        steps: [],
    };

    /**
     * @returns {boolean}
     */
    canNavigateTo = (stepIndex) => {
        const { steps, blockNavigation } = this.props;
        const isGoingBackwards = stepIndex < this.props.currentStep;
        const isGoingForward = !isGoingBackwards;
        const isSkippingMultipleSteps = this.props.currentStep + 1 < stepIndex;
        const isSkippingSubmittableStep = (
            get(steps, [this.props.currentStep, 'isSubmittable']) &&
            this.props.currentStep < stepIndex
        );

        if (blockNavigation) return false;
        if (isGoingForward && this.props.blockForward) return false;
        if (isGoingBackwards && !this.canGoBack()) return false;
        if (isSkippingMultipleSteps) return false;
        if (isSkippingSubmittableStep) return false;

        return true;
    };

    /**
     * @returns {boolean}
     */
    canGoBack = () => (
        this.props.currentStep > 0 &&
        this.props.blockGoingBackwardsFromStep < this.props.currentStep
    );

    /**
     * @returns {boolean}
     */
    mustDisplaySkipCurrentStep = () => {
        const { steps, currentStep } = this.props;
        return get(steps, [currentStep, 'canSkip']);
    };

    /**
     * @returns {boolean}
     */
    canGoNext = () => this.props.currentStep < size(this.props.steps) - 1;

    /**
     * @returns {boolean}
     */
    mustDisplayFinishButton = () => {
        const { currentStep, steps } = this.props;
        const isCurrentStepSubmittable = get(steps, [currentStep, 'isSubmittable']);
        return isCurrentStepSubmittable || !this.canGoNext();
    };

    /**
     * @returns {boolean}
     */
    mustDisplayStepButtons = () => !get(this.props.steps, [this.props.currentStep, 'hideStepButtons']);

    buildStepWithDefaults = (step, stepIndex) => ({
        isCompleted: !get(step, 'isSkipped') && stepIndex < this.props.currentStep,
        ...step,
        onNavigate: this.canNavigateTo(stepIndex) ? get(step, 'onNavigate') : null
    });

    buildProgressHeader = () => (
        <div className="wizard__header">
            <Progress
                steps={map(this.props.steps, this.buildStepWithDefaults)}
                currentStep={this.props.currentStep}
                onNavigate={this.handleNavigationFromProgressHeader}
            />
        </div>
    );

    buildStepsButtons = () => (
        <div className="wizard__action-buttons text-center">
            <StepButtons
                displayBackButton={this.canGoBack()}
                displaySkipButton={this.mustDisplaySkipCurrentStep()}
                displayNextButton={!this.mustDisplayFinishButton()}
                displayFinishButton={this.mustDisplayFinishButton()}
                onBack={this.handleGoBack}
                onNext={this.handleGoNext}
                onSkip={this.handleSkip}
                onFinish={this.props.onFinish}
                isSubmitting={this.props.isSubmitting}
                blockNavigation={this.props.blockNavigation}
                blockForward={this.props.blockForward}
            />
        </div>
    );

    handleGoBack = () => {
        const { steps, currentStep } = this.props;
        const toIndex = currentStep - 1;
        const to = steps[toIndex];

        if (!this.canNavigateTo(toIndex)) return;

        invoke(this.props, 'onNavigate', to, toIndex, steps);
        invoke(this.props, 'onBack', to, toIndex, steps);
    };

    handleGoNext = () => {
        const { steps, currentStep } = this.props;
        const toIndex = currentStep + 1;
        const to = steps[toIndex];

        if (!this.canNavigateTo(toIndex)) return;

        invoke(this.props, 'onNavigate', to, toIndex, steps);
        invoke(this.props, 'onNext', to, toIndex, steps);
    };

    handleSkip = () => {
        const { steps, currentStep } = this.props;
        const toIndex = currentStep + 1;
        const to = steps[toIndex];

        if (!this.canNavigateTo(toIndex)) return;

        invoke(this.props, 'onNavigate', to, toIndex, steps);
        invoke(this.props, 'onSkip', to, toIndex, steps);
    };

    handleNavigationFromProgressHeader = (step, stepIndex) => {
        const { steps } = this.props;
        if (!this.canNavigateTo(stepIndex)) return;
        invoke(this.props, 'onNavigate', step, stepIndex, steps);
    };

    buildCurrentStep = () => {
        const { steps, currentStep } = this.props;
        const content = get(steps, [currentStep, 'component']);

        if (isFunction(content)) {
            return content();
        }

        return content;
    };

    render = () => (
        <section className="wizard__container">
            {this.props.displayProgressHeader && this.buildProgressHeader()}
            {this.buildCurrentStep()}
            {this.mustDisplayStepButtons() && this.buildStepsButtons()}
        </section>
    );
}
