import React from "react";
import get from "lodash/get";
import trim from "lodash/trim";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import size from "lodash/size";
import head from "lodash/head";
import { Field, FieldArray } from "formik";
import addDays from "date-fns/add_days";
import {
    AddressAutocompleteField,
    AmountFloatField,
    RadioField
} from "../../Form";
import { Calendar } from "react-calendar";
import format from "date-fns/format";
import BaseWizardStep from "./BaseWizardStep";
import { ProfilePicture } from "../../ProfilePicture/ProfilePicture";
import { getAddressFromPlaceDetails, getUTCDate } from "../../../util/util";
import EarnestMoneyDepositAccountCardFormikField from "../../EarnestMoneyDepositAccountCardFormikField/EarnestMoneyDepositAccountCardFormikField";

class StepPropertyAddress extends BaseWizardStep {
    state = {
        fundingSourcesAmount: map(
            get(this.props.userProfile, "fundingSources"),
            funding => ({ id: funding.id, amount: 0 })
        ),
        amounts: 0,
        bankAccount: {},
        hasAddress: false,
        isVisibleCalendar: false
    };

    updateAddressDetails = ([details] = []) => {
        const addressComponents = get(details, "address_components" || {});
        const { city, state, zipcode, lat, lng } = getAddressFromPlaceDetails(
            details
        );
        this.setFieldValues(
            { city, state, zipcode, lat, lng },
            "subjectPropertyAddress",
            true
        );
        this.setState({ hasAddress: !isEmpty(addressComponents) });
    };

    depositAmount() {
        return (
            <div className="step-deposit-account__deposit-field buyer__signup__field__row">
                <div className="step-deposit-account__deposit buyer__signup__field">
                    <Field
                        type="text"
                        name="earnestMoneyDepositAmount"
                        component={AmountFloatField}
                        placeholder="$0.00"
                    />
                    {this.buildFieldError("earnestMoneyDepositAmount")}
                </div>
            </div>
        );
    }

    renderLocation() {
        return (
            <div className="buyer__signup__field__row">
                <div className="buyer__signup__field width-70">
                    <Field
                        component={AddressAutocompleteField}
                        name="subjectPropertyAddress.address"
                        placeholder="ADDRESS"
                        onPlaceDetails={this.updateAddressDetails}
                    />
                    {this.buildFieldError("subjectPropertyAddress.address")}
                    {this.buildFieldError("subjectPropertyAddress.zipcode")}
                </div>
                <div className="buyer__signup__field width-30">
                    <Field
                        type="text"
                        name="subjectPropertyAddress.apartmentSuiteNumber"
                        placeholder="APARTMENT"
                    />
                </div>
            </div>
        );
    }

    buildPicture = () => {
        const { userProfile } = this.props;
        const picture = trim(
            get(userProfile, "publicProfile.userInfo.profilePicUrl")
        );
        return (
            <div className="step-deposit-account__profile-picture-container">
                <ProfilePicture className="pulled-up" picture={picture} />
            </div>
        );
    };

    updateFundingSourceAmount = () => {
        const { userProfile, values } = this.props;
        const amount = get(values, "earnestMoneyDepositAmount");
        const firstFundingSource = head(get(values, "achs"));
        const firstFundingSourceAmount = get(firstFundingSource, "amount");
        if (firstFundingSourceAmount !== amount) {
            const data = {
                amount: amount,
                bankAccount: head(get(userProfile, "fundingSources"))
            };
            const isTouched = true;

            this.setFieldValues(data, "achs[0]", isTouched);
        }
    };

    buildAchsError = () => (
        <div className="step-deposit-account__message__error--content">
            <div className="step-deposit-account__message__error">
                {this.props.errorAchsMessage}
            </div>
        </div>
    );

    transferAccounts = () => {
        const { userProfile, values, errorAchs } = this.props;
        const fundingSources = get(userProfile, "fundingSources");
        const achs = get(values, "achs");
        const hasAmount = find(achs, ach => get(ach, "amount") !== "")
            ? true
            : false;
        const onlyOneFundingSource = size(fundingSources) === 1;

        if (onlyOneFundingSource) {
            this.updateFundingSourceAmount();
        }

        return (
            <>
                <FieldArray
                    name="achs"
                    render={() => (
                        <div>
                            {map(fundingSources, (funding, index) => (
                                <div
                                    key={index}
                                    className="step-deposit-account__account-card"
                                >
                                    <Field
                                        component={
                                            EarnestMoneyDepositAccountCardFormikField
                                        }
                                        name={`achs[${index}]`}
                                        account={funding}
                                        isEditable={!onlyOneFundingSource}
                                        showBorderColor={onlyOneFundingSource}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                />
                {hasAmount ? this.buildMessageError("achs") : null}
                {errorAchs ? this.buildAchsError() : null}
            </>
        );
    };

    getTypes = () => {
        const { earnestMoneyDepositTypes } = this.props;
        return map(earnestMoneyDepositTypes, type => ({
            value: get(type, "id"),
            label: get(type, "displayName")
        }));
    };

    typeOfTransfer = () => (
        <>
            <div className="buyer__signup__field">
                <Field
                    name="typeOfTransfer"
                    className="is-horizontal"
                    itemClassName="step-deposit-account__type is-horizontal"
                    options={this.getTypes()}
                    component={RadioField}
                />
            </div>
            {this.buildMessageError("typeOfTransfer")}
        </>
    );

    buildMemo = () => (
        <div className="buyer__signup__field step-deposit-account__field--memo">
            <Field type="text" name="memo" component="textarea" />
        </div>
    );

    onChangeCalendar = event => {
        const { isVisibleCalendar } = this.state;
        const utcDate = getUTCDate(event);
        this.props.setFieldValue("anticipatedClosingDate", utcDate);
        this.props.setFieldTouched("anticipatedClosingDate");
        this.setState({
            anticipatedClosingDate: utcDate,
            isVisibleCalendar: !isVisibleCalendar
        });
    };

    onChangeCalendarField = () => {
        this.props.setFieldValue("anticipatedClosingDate", "");
        this.props.setFieldTouched("anticipatedClosingDate");
    };

    buildAnticipatedClosingDate = date => format(date, this.props.dateFormat);

    setCalendarVisibility = () => {
        const { isVisibleCalendar } = this.state;
        this.setState({ isVisibleCalendar: !isVisibleCalendar });
    };

    getAnticipatedClosingDate = () => {
        const { values } = this.props;
        const anticipatedClosingDate = get(values, "anticipatedClosingDate")
            ? format(
                  get(values, "anticipatedClosingDate"),
                  this.props.calendarFormat
              )
            : "";
        return anticipatedClosingDate;
    };

    buildEMDInfo = () => {
        const { values } = this.props;
        const { isVisibleCalendar } = this.state;
        const typeOfTransfer = get(values, "typeOfTransfer");
        const earnest_money_deposit = 1;

        if (typeOfTransfer !== earnest_money_deposit) return;

        return (
            <div className="buyer__signup__fieldset">
                <div className="step-deposit-account__emd-information">
                    <div className="buyer__signup__field__row">
                        <div className="buyer__signup__field__question margin-field bold">
                            Spouse Name:
                        </div>
                    </div>
                    <div className="buyer__signup__field__row">
                        <div className="buyer__signup__field">
                            <Field
                                type="text"
                                name="firstNameSpouse"
                                placeholder="FIRST NAME"
                            />
                            {this.buildFieldError("firstNameSpouse")}
                        </div>

                        <div className="buyer__signup__field">
                            <Field
                                type="text"
                                name="lastNameSpouse"
                                placeholder="LAST NAME"
                            />
                            {this.buildFieldError("lastNameSpouse")}
                        </div>
                    </div>
                    <div className="buyer__signup__field__row">
                        <div className="step-deposit-account__emd-optional">
                            Only if you are married
                        </div>
                    </div>

                    <div className="buyer__signup__field__row">
                        <div className="buyer__signup__field__question margin-field bold">
                            Seller Name:
                        </div>
                    </div>
                    <div className="buyer__signup__field__row">
                        <div className="buyer__signup__field">
                            <Field
                                type="text"
                                name="firstNameSeller"
                                placeholder="FIRST NAME"
                            />
                            {this.buildFieldError("firstNameSeller")}
                        </div>

                        <div className="buyer__signup__field">
                            <Field
                                type="text"
                                name="lastNameSeller"
                                placeholder="LAST NAME"
                            />
                            {this.buildFieldError("lastNameSeller")}
                        </div>
                    </div>
                    <div className="buyer__signup__field__row">
                        <div className="step-deposit-account__emd-optional">
                            Optional
                        </div>
                    </div>

                    <div className="buyer__signup__field__row">
                        <div className="buyer__signup__field__question margin-field bold">
                            Tentative closing date:
                        </div>
                    </div>
                    <div className="buyer__signup__field__row">
                        <div className="buyer__signup__field step-deposit-account width-50">
                            <Field
                                type="text"
                                name="anticipatedClosingDate"
                                value={this.getAnticipatedClosingDate()}
                                onClick={this.setCalendarVisibility}
                                onChange={this.onChangeCalendarField}
                                autocomplete="off"
                            />
                            <Calendar
                                className={`${
                                    !isVisibleCalendar
                                        ? "react-calendar__not-visible"
                                        : null
                                }`}
                                onChange={this.onChangeCalendar}
                                minDate={new Date()}
                                maxDate={addDays(new Date(), 45)}
                                formatMonth={date =>
                                    this.buildAnticipatedClosingDate(date)
                                }
                            />
                        </div>
                        <div className="buyer__signup__field step-deposit-account width-50"></div>
                    </div>
                    <div className="buyer__signup__field__row">
                        <div className="step-deposit-account__emd-optional">
                            Optional
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    isStepValid = () =>
        this.areFieldsValid([
            "subjectPropertyAddress",
            "earnestMoneyDepositAmount",
            "achs",
            "typeOfTransfer"
        ]);

    render() {
        const { values } = this.props;
        const isStepFulfilled = this.isStepValid();

        return (
            <>
                <div className="profile__container">
                    <div className="profile__panel">
                        {this.buildPicture()}
                        <div className="step-deposit-account__question">
                            Your Transfer Details
                        </div>
                        <div className="step-deposit-account__content">
                            <div className="step-deposit-account__title">
                                <div className="step-deposit-account__field">
                                    Type of transfer:
                                </div>
                            </div>
                            <div className="buyer__signup__fieldset step-deposit-account__fieldset--type">
                                {this.typeOfTransfer()}
                            </div>
                            {this.buildEMDInfo()}
                            <div className="step-deposit-account__title">
                                <div className="step-deposit-account__field">
                                    Enter the property address associated with
                                    this transfer:
                                </div>
                            </div>
                            <div className="buyer__signup__fieldset">
                                {this.renderLocation()}
                            </div>
                            <div className="step-deposit-account__title">
                                <div className="step-deposit-account__field">
                                    Memo (Optional):
                                </div>
                            </div>
                            <div className="buyer__signup__fieldset">
                                {this.buildMemo()}
                            </div>
                            <div className="step-deposit-account__title">
                                <div className="step-deposit-account__field">
                                    How much do you want to transfer?
                                </div>
                            </div>
                            <div className="step-deposit-account__deposit-amount buyer__signup__fieldset">
                                {this.depositAmount()}
                            </div>
                            <div className="step-deposit-account__title">
                                <div className="step-deposit-account__field--accounts">
                                    What account(s) would you like to transfer
                                    from?
                                </div>
                                <div className="step-deposit-account__field--note">
                                    NOTE: You can split the total amount of the
                                    transfer between multiple accounts.
                                </div>
                            </div>
                            <div className="step-deposit-account__transfer-account">
                                {this.transferAccounts()}
                            </div>
                        </div>
                        {this.renderStepButtons({
                            canProceed: isStepFulfilled,
                            notPreviousStep: true,
                            hasAddress: !!get(
                                values,
                                "subjectPropertyAddress.address"
                            )
                        })}
                    </div>
                </div>
            </>
        );
    }
}

StepPropertyAddress.defaultProps = {
    calendarFormat: "MM/DD/YYYY"
};

export default StepPropertyAddress;
