import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import invoke from "lodash/invoke";
import { Redirect } from "react-router-dom";
import { logoutUser } from "../../reducers/user/UserActions";
import mixpanel from "mixpanel-browser";

class SignOut extends Component {
    componentDidMount() {
        const { user } = this.props;
        const isBuyer = get(user, "profile.publicProfile.userInfo.buyer");
        const isSeller = get(user, "profile.publicProfile.userInfo.seller");
        const isProspect = get(user, "profile.publicProfile.userInfo.prospect");
        if (isBuyer || isSeller || isProspect) {
            invoke(mixpanel, "reset");
        }
        this.props.logoutUser();
    }

    render() {
        return <Redirect to="/" />;
    }
}

SignOut.propTypes = {
    user: PropTypes.object
};

export default connect(state => ({ user: state.user }), {
    logoutUser
})(SignOut);
