import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import { ProfilePicture } from "../../ProfilePicture/ProfilePicture";

import "./adminRoleTables.css";

export default class BrokerTable extends React.Component {
    getPendingToContact = agent => Number(get(agent, "pendingToContact")) || 0;

    getOngoing = agent => Number(get(agent, "ongoing")) || 0;

    getDealClose = agent => Number(get(agent, "dealClose")) || 0;

    getUserId = agent => get(agent, "id");

    buildAvatarAndName = agent => {
        return (
            <div className="admin-role-tables__avatar-broker">
                <ProfilePicture
                    className="admin-role-tables__broker-picture"
                    picture={get(agent, "profilePicUrl")}
                    canEdit={false}
                />
                <div className="admin-role-tables__broker-agent-name">
                    <div className="admin-role-tables__broker-agent-full-name">
                        {`${get(agent, "firstName")} ${get(agent, "lastName")}`}
                    </div>
                    <div className="admin-role-tables__broker-agent-email">
                        <a href={`mailto: ${get(agent, "email")}`}>
                            {get(agent, "email")}
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    buildFirstRow = agent => (
        <div className="admin-role-tables__item-row brokerage">
            <div className="brokerage-items">
                <div className="brokerage-items-title">AGENT</div>
                <div className="brokerage-items-value">
                    {this.buildAvatarAndName(agent)}
                </div>
            </div>
            <div className="brokerage-items">
                <div className="brokerage-items-title">PENDING MATCHES</div>
                <div className="brokerage-items-value">
                    {this.getPendingToContact(agent)}
                </div>
            </div>
            <div className="brokerage-items">
                <div className="brokerage-items-title">ACTIVE MATCHES</div>
                <div className="brokerage-items-value">
                    {this.getOngoing(agent)}
                </div>
            </div>
            <div className="brokerage-items">
                <div className="brokerage-items-title">CLOSED MATCHES</div>
                <div className="brokerage-items-value">
                    {this.getDealClose(agent)}
                </div>
            </div>
        </div>
    );

    buildContentList = () => (
        <div className="admin-role-tables__body">
            {map(this.props.brokers, (broker, index) => {
                return (
                    <div className="admin-role-tables__item" key={index}>
                        <div className="admin-role-tables">
                            {this.buildFirstRow(broker, index)}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    render = () => (
        <div className="admin-role-tables__stats">
            {this.buildContentList()}
        </div>
    );
}

BrokerTable.propTypes = {
    brokers: PropTypes.any,
    impersonateUser: PropTypes.func
};
