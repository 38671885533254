import get from 'lodash/get';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import findIndex from 'lodash/findIndex';
import { getLicenses } from '../../reducers/admin/AdminActions';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { SELECT_ROLE_ALL } from '../../shared/constants';
import { Button } from '../Button/Button';
import LicenseTable from '../AdminProfile/AdminRoleTables/LicenseTable';
import WizardStepsContainer from './AddLicenseModal/WizardSteps/WizardStepsContainer';
import ContentModal from '../ContentModal/ContentModal';

import './adminProfileLicense.css';

class AdminProfileLicense extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisibleAddLicenseModal: false,
            licensesCache: [],
        };
    }

    componentDidMount = () => {
        const roleBrewcrew = find(this.props.roles, { name: SELECT_ROLE_ALL }) ? true : false;
        if(roleBrewcrew) {
            this.props.onGetLicenses()
                .then((response) => (this.setState({ licensesCache: response })))
        }
    };

    setToggleMoreInfo = (list, indexDeposit, item, stateUserMoreInfo) => (
        set(list, indexDeposit, { ...item, showMoreInfo: !stateUserMoreInfo})
    );

    findIndexIdLicense = (id) => (findIndex(this.state.licensesCache, (license) => get(license, 'id') === id));

    findIdLicense = (id) => (find(this.state.licensesCache, (license) => get(license, 'id') === id));

    getStateButtonShowMoreInfo = (item) => get(item, 'showMoreInfo') ? true : false;

    onClickMoreInfoLicenses = (id) => {
        const indexLicense = this.findIndexIdLicense(id);
        const license = this.findIdLicense(id);
        const stateUserMoreInfo = this.getStateButtonShowMoreInfo(license);

        this.setState((prevState) => {
            const prevLicenseCache = cloneDeep(prevState.licensesCache);
            const updateLicensesCache = this.setToggleMoreInfo(
                prevLicenseCache, indexLicense, license, stateUserMoreInfo
            );
            return { licensesCache: updateLicensesCache };
        });
    }

    getIsFetchedLicenses = () => get(this.props.admin, 'getLicenses.isFetchedLicenses');

    getIsFetchingLicenses = () => get(this.props.admin, 'getLicenses.isFetchingLicenses');

    buildLicenseTable = () => (
        <div className="admin-profile__table-container">
            <LicenseTable
                dateFormat={this.props.dateFormat}
                licenses={this.state.licensesCache}
                onClickMoreInfo={this.onClickMoreInfoLicenses}
            />
        </div>
    );

    buildResults = () => {
        if (this.getIsFetchingLicenses()) {
            return <SpinnerLoader />;
        }

        return this.buildLicenseTable();
    };

    onOpenAddLicense = () => this.setState({ isVisibleAddLicenseModal: true });

    onCloseAddLicense = () => this.setState({ isVisibleAddLicenseModal: false });

    buildAddLicenseModal = () => (
        <WizardStepsContainer onClose={this.onCloseAddLicense} />
    )

    buildModalCreateLicense = () => (
        <ContentModal
            isVisible={this.state.isVisibleAddLicenseModal}
            onClose={this.onCloseAddLicense}
            content={this.buildAddLicenseModal()}
            className="admin-role-tables"
            isCancelButton={false}
            progress
        />
    );

    buildAddLicenseButton = () => (
        <div className="admin-profile-license__title-button">
            <Button
                onClick={() => this.onOpenAddLicense()}
                shadow={false}
            >
                Add License
            </Button>
        </div>
    );

    render = () => (
        <div className="admin-profile-license">
            {this.buildModalCreateLicense()}
            <div className="admin-profile-license__title">
                <div className="admin-profile-license__title-text">
                    Licenses
                </div>
                {this.buildAddLicenseButton()}
            </div>
            <div className="admin-profile-license__container">
                {this.buildResults()}
            </div>
        </div>
    )
}

AdminProfileLicense.propTypes = {
    admin: PropTypes.object,
    roles: PropTypes.object,
    onGetLicenses: PropTypes.func,
};

AdminProfileLicense.defaultProps = {
    dateFormat: 'MM.DD.YYYY hh:mm a',
};

export default connect(
    (state) => ({
        admin: state.admin,
        roles: get(state, 'user.profile.publicProfile.userInfo.roles', []),
    }),
    {
        onGetLicenses: getLicenses,
    },
)(AdminProfileLicense);
