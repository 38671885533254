import React from "react";
import { Link } from "react-router-dom";

import logo from "../../styles/assets/lb_horizontal_yellow_white.png";
import "./footer.css";

export default class Footer extends React.PureComponent {
    render = () => (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12 text-center">
                        <img
                            src={logo}
                            className="footer__logo"
                            alt="LemonBrew"
                        />
                        <div className="footer__mail-us">
                            <a href="mailto:SUPPORT@LEMONBREW.COM">
                                SUPPORT@LEMONBREW.COM
                            </a>
                        </div>
                        <div className="footer__call-us">
                            <a href="tel:8335366627">833.536.6627</a>
                        </div>
                        <div className="footer__social-media">
                            <a
                                href="https://www.facebook.com/lemonbr3w/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icon-facebook" />
                            </a>
                            <a
                                href="https://www.instagram.com/lemonbrew/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icon-instagram" />
                            </a>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-2">
                        <h6 className="footer__title">
                            <a href="https://www.lemonbrew.com/about-us/">
                                Company
                            </a>
                        </h6>
                        <ul className="list-unstyled">
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/about-us">
                                    About Us
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/our-licenses">
                                    Our Licenses
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/careers">
                                    Join the Team
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/get-in-touch/">
                                    Get in Touch
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/blog/lemonbrew-in-the-press/">
                                    Press
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-2">
                        <h6 className="footer__title">
                            <a href="https://www.lemonbrew.com/get-started/">
                                Services
                            </a>
                        </h6>
                        <ul className="list-unstyled">
                            <li className="footer__link">
                                <Link to="/signup/buyer">
                                    Buying with LemonBrew
                                </Link>
                            </li>
                            <li className="footer__link">
                                <Link to="/signup/seller">
                                    Selling with LemonBrew
                                </Link>
                            </li>
                            <li className="footer__link">
                                <Link to="/agent">Real Estate Agents</Link>
                            </li>
                            <li className="footer__link">
                                <Link to="/signup/transfer">
                                    Electronic Transfer
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-2">
                        <h6 className="footer__title">
                            <a href="https://www.lemonbrew.com/faqs/">
                                Resources
                            </a>
                        </h6>
                        <ul className="list-unstyled">
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/what-is-a-brewscore">
                                    What is a BrewScore?
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/faqs">
                                    FAQs
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/blog">
                                    Blog
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-2">
                        <h6 className="footer__title">
                            <a href="https://www.lemonbrew.com/terms-of-use/">
                                Legal
                            </a>
                        </h6>
                        <ul className="list-unstyled">
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/terms">
                                    Terms of Service
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/privacy">
                                    Privacy Policy
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://lb-public-documents.s3.amazonaws.com/LemonBrew-Dispute.pdf">
                                    Disputes
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/notice">
                                    Notice to Consumers
                                </a>
                            </li>
                            <li className="footer__link">
                                <a href="https://www.lemonbrew.com/lemonbrew-affiliated-businesses/">
                                    Affiliated Businesses
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer__rights">
                    &copy; {new Date().getFullYear()} LemonBrew Technologies,
                    All Rights Reserved
                </div>
            </div>
        </footer>
    );
}
