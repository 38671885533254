import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import WizardStepsContainer from './WizardSteps/WizardStepsContainer';
import mixpanel from 'mixpanel-browser';

class SellerSignUpBrew extends Component {
    static propTypes = {
        user: PropTypes.object,
    };

    componentDidMount = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_SELLER_TOKEN);
    };

    render() {
        return <WizardStepsContainer/>;
    }
}

export default connect()(SellerSignUpBrew);
