import React from "react";
import { Link } from "react-router-dom";
import "../BuyerSignUp/SignUpStep/termsandpolicies.css";

const BrokerPrivacyPolicy = () => (
    <div className="lb__documentation__content">
        <h1 className="lb__documentation__content__title">Privacy Policy</h1>
        <div className="lb__documentation__content__inner">
            <div className="lb__documentation__content__inner__text">
                The website,{" "}
                <a href="https://www.lemonbrew.com">www.LemonBrew.com</a>,
                (“Website”) is owned and operated by LemonBrew Technologies
                Corp. LemonBrew Technologies Corp., its parent companies,
                subsidiaries, and affiliates, including its licensed real estate
                broker subsidiaries, may be referred as "LemonBrew", "we", "us",
                "our" or "Company". Your use of this Website is subject to the{" "}
                <Link
                    to="/terms-of-use-realtor"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms of Use
                </Link>
                . LemonBrew values your privacy. In that regard, we have
                established the following privacy policy to explain and describe
                what personally identifiable information LemonBrew collects on
                the Website and/or as part of LemonBrew’s services, how
                LemonBrew uses it, and your options regarding LemonBrew may
                collect about you. By accepting this Privacy Policy, you
                expressly consent to the information handling practices
                described in this Policy and represent that you are over the age
                of 18. Your continued use of the Website, mobile applications
                and/or services or any other content, products or other services
                covered by this Policy constitutes your acceptance and agreement
                to this Privacy Policy and any future updates.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                Information We Collect
            </h2>
            <div className="lb__documentation__content__inner__text">
                We collect personally identifiable information (“PII”), which is
                any information that could potentially identify a specific
                individual, including:
            </div>
            <ul className="lb__documentation__content__inner__list">
                <li className="lb__documentation__content__inner__list__item">
                    information about an individual that can be used to
                    distinguish or trace an individual’s identity, such as first
                    and last names, social security number, date and place of
                    birth, mother’s maiden name, or biometric records; and
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    any other information that is linked or linkable to an
                    individual, such as educational information, financial
                    information, employer information, email address, IP
                    addresses or other online contact information, or telephone
                    number.
                </li>
            </ul>
            <div className="lb__documentation__content__inner__text">
                We collect this information in two ways:
            </div>
            <ol className="lb__documentation__content__inner__list">
                <li className="lb__documentation__content__inner__list__item">
                    We collect information you provide to us in optional,
                    voluntary forms or requests for customer services.
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    We collect information from third parties, with your
                    consent.
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    Some information may be collected automatically.
                </li>
            </ol>
            <h2 className="lb__documentation__content__inner__title">
                How We Collect Information Information Received from You
            </h2>
            <div className="lb__documentation__content__inner__text">
                We collect information entered by you on our site in connection
                with the services offered by LemonBrew. Information entered by
                you may include:
            </div>
            <ul className="lb__documentation__content__inner__list">
                <li className="lb__documentation__content__inner__list__item">
                    your name, email address and phone number
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    city, state and zip code where you are interested to buy or
                    sell
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    type of property you are interested to buy or sell
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    price range and budget of the property you are interested to
                    buy or sell
                </li>
            </ul>
            <h2 className="lb__documentation__content__inner__title">
                Information Received from Third Party Sources
            </h2>
            <div className="lb__documentation__content__inner__text">
                We may obtain information about you from sources other than you.
                We may obtain information from third parties such the credit
                reporting agencies (e.g., Experian), with your consent. We also
                use various technologies to collect information automatically
                when you access and use the Services, including cookies, web
                beacons and other similar technologies
            </div>
            <h2 className="lb__documentation__content__inner__title">
                Cookies
            </h2>
            <div className="lb__documentation__content__inner__text">
                A cookie, also known as an HTTP cookie, web cookie, or browser
                cookie, is a small piece of data sent from a website and stored
                in a user’s web browser while the user is browsing that website.
                Every time the user loads the website, the browser sends the
                cookie back to the server to notify the website of the user’s
                previous activity. Cookies were designed to be a reliable
                mechanism for websites to remember stateful information (such as
                items in a shopping cart) or to record the user’s browsing
                activity including clicking particular buttons, logging in, or
                recording which pages were visited.
            </div>
            <ul className="lb__documentation__content__inner__list">
                <li className="lb__documentation__content__inner__list__item">
                    Functionality and Cookies
                </li>
            </ul>
            <div className="lb__documentation__content__inner__text">
                LemonBrew does not require that you accept cookies and/or you
                may withdraw your consent to our use of cookies at any time by
                adjusting your browser’s privacy settings.
            </div>
            <ul className="lb__documentation__content__inner__list">
                <li className="lb__documentation__content__inner__list__item">
                    Opting Out of Cookies
                </li>
            </ul>
            <div className="lb__documentation__content__inner__text">
                You can set your browser to notify you when you receive a
                cookie, giving you the chance to decide whether or not to accept
                it. You may also change your cookie settings through preferences
                options, where applicable.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                Google Analytics
            </h2>
            <div className="lb__documentation__content__inner__text">
                We use analytics tools and other third party technologies, such
                as Google Analytics to collect non- personal information in the
                form of various usage and user metrics when you use our online
                website. These tools and technologies collect and analyze
                certain types of information, including cookies, IP addresses,
                device and software identifiers, referring and exit URLs, onsite
                behavior and usage information, feature use metrics and
                statistics, usage and purchase history, MAC Address, mobile
                unique device ID, and other similar information.
            </div>
            <div className="lb__documentation__content__inner__text">
                Google Analytics is a service offered by Google that generates
                detailed statistics about a website‘s traffic and traffic
                sources and measures conversions and sales. The product is aimed
                at marketers as opposed to webmasters and technologists from
                which the industry of web analytics originally grew. It is the
                most widely used website statistics service. You may opt out of
                Google Analytics by visiting the Google Analytics opt-out page.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                AdWords Remarketing
            </h2>
            <div className="lb__documentation__content__inner__text">
                This website uses the Google AdWords remarketing service to
                advertise on third party websites (including Google) to previous
                visitors to our site. It could mean that we advertise to
                previous visitors who haven’t completed a task on our site, for
                example using the contact form to make an enquiry. This could be
                in the form of an advertisement on the Google search results
                page, or a site in the Google Display Network. Third-party
                vendors, including Google, use cookies to serve ads based on
                someone’s past visits to our website. Of course, any data
                collected will be used in accordance with our own privacy policy
                and Google’s privacy policy. You can set preferences for how
                Google advertises to you using the Google Ad Preferences page,
                and if you want to you can opt out of interest-based advertising
                entirely by cookie settings or permanently using a browser
                plugin. Google has additional information available about their
                Remarketing Privacy Guidelines, Policies, and Restrictions.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                How We Use Information
            </h2>
            <div className="lb__documentation__content__inner__text">
                We may use your information to:
            </div>
            <ul className="lb__documentation__content__inner__list">
                <li className="lb__documentation__content__inner__list__item">
                    Deliver services and products;
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    Manage and maintain customer support;
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    Communicate with you;
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    Develop and implement marketing campaigns; and
                </li>
                <li className="lb__documentation__content__inner__list__item">
                    Internal management purposes.
                </li>
            </ul>
            <div className="lb__documentation__content__inner__text">
                We may share non-PII with third parties at our sole discretion.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                How We Disclose Personal Information
            </h2>
            <div className="lb__documentation__content__inner__text">
                LemonBrew is committed to maintaining the accuracy,
                confidentiality, and security of your personal and corporate
                information. PII may only be used or disclosed for the purpose
                for which it was collected unless the customer has otherwise
                consented, or when it is required or permitted by law. We may
                also disclose your information in connection with a transaction
                involving the sale of our business, a merger, consolidation,
                corporate restructure, asset sale, or other substantial event.
            </div>
            <div className="lb__documentation__content__inner__text">
                Customer information may only be retained for the period of time
                required to fulfill the purpose for which it was collected.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                Sharing of Your Information
            </h2>
            <div className="lb__documentation__content__inner__text">
                LemonBrew transfers your personal information to websites
                maintained by third parties (each a “Partner”). By submitting a
                request on LemonBrew, you hereby expressly authorize LemonBrew
                to submit your PII and other information to our Partners. Please
                note that our Partners may retain or use your information,
                irrespective of whether you used their services and you should
                contact our Partners directly concerning their privacy and
                information sharing policies.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                Third-Party Links and Websites
            </h2>
            <div className="lb__documentation__content__inner__text">
                We may link to the websites of other companies and/or
                individuals. These third-party websites may collect information
                about you on those websites, however LemonBrew's privacy policy
                does not cover external websites and third parties. Please refer
                directly to these third parties and websites regarding their
                privacy policies.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                People Under 18
            </h2>
            <div className="lb__documentation__content__inner__text">
                We do not knowingly seek or collect PII from users under the age
                of 18.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                Safeguarding Your Information
            </h2>
            <div className="lb__documentation__content__inner__text">
                LemonBrew utilizes the latest security and encryption
                technologies to protect your information. LemonBrew uses
                security measures and procedures such as limiting access to PII
                only to those employees and contractors who require the
                information and enforcing strict password and access protocols.
                All confidential and PII data will be encrypted at rest
                utilizing AES/CBC/PKCS5 Padding within the LemonBrew database.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                Handling Customer Complaints and Suggestions
            </h2>
            <div className="lb__documentation__content__inner__text">
                Customers may direct any questions or inquiries with respect to
                the privacy principles outlined above or about our practices by
                contacting us as follows: LemonBrew Technologies Corp
                <br />
                P.O Box 18272
                <br />
                1233 The Plz
                <br />
                Charlotte, NC 28205
                <br />
                E-mail:{" "}
                <a href="mailto:hello@lemonbrew.com">hello@lemonbrew.com</a>
            </div>
            <h2 className="lb__documentation__content__inner__title">
                California Privacy Rights
            </h2>
            <div className="lb__documentation__content__inner__text">
                California law permits residents of California to request
                certain details about information LemonBrew discloses to third
                parties for direct marketing purposes. Such requests can be made
                to <a href="mailto:hello@lemonbrew.com">hello@lemonbrew.com</a>.
                California privacy rights requests must include the reference
                “Request for
            </div>
            <div className="lb__documentation__content__inner__text">
                California Privacy Information” on the subject line and in the
                body of the message and must include the email address or
                mailing address, as applicable, for us to send our response.
                This request may be made no more than once per calendar year. We
                reserve the right not to respond to requests submitted other
                than to the address specified above.
            </div>
            <h2 className="lb__documentation__content__inner__title">
                Changes and Updates to This Policy
            </h2>
            <div className="lb__documentation__content__inner__text">
                We may update this Privacy Policy from time to time. If we do,
                we will update the “effective date” at the top of the Policy. If
                we make material changes to how we use your PII, we will attempt
                to notify you of the change and to give you the opportunity to
                exercise choice as to how your personal information is used.
            </div>
        </div>
    </div>
);

export default BrokerPrivacyPolicy;
