import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import trim from 'lodash/trim';
import find from 'lodash/find';
import reject from 'lodash/reject';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';
import { autocomplete } from '../../util/service_api';

export default class SimpleUrlAutoCompleteInput extends Component {
    static propTypes = {
        labelProp: PropTypes.string.isRequired,
        valueProp: PropTypes.string,
        autoCompleteUri: PropTypes.string.isRequired,
        value: PropTypes.any,
        isMulti: PropTypes.bool,
        getOptionsMillisecondsDelay: PropTypes.number,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        components: PropTypes.any,
        isClearable: PropTypes.bool,
        allowRepeatedValues: PropTypes.bool,
        cacheOptions: PropTypes.bool,
        allowSelectingWithCheckbox: PropTypes.bool,
        closeMenuOnSelect: PropTypes.bool,
    };

    static defaultProps = {
        allowRepeatedValues: true,
        cacheOptions: true,
    };

    isOptionSelected = (option) => {
        const optionName = get(option, 'name');
        return find(this.props.value, { label: optionName }) !== undefined;
    };

    filterRepeatedOptions = (options) => reject(options, this.isOptionSelected);

    buildOptions = (response) => {
        const { allowRepeatedValues, allowSelectingWithCheckbox } = this.props;
        const options = (
            allowRepeatedValues || allowSelectingWithCheckbox ?
            response :
            this.filterRepeatedOptions(response)
        );
        return map(options, (result) => ({
            value: this.props.valueProp ? get(result, this.props.valueProp) : result,
            label: trim(get(result, this.props.labelProp)),
        }));
    };

    getOptionsAndBuildOptions = (term) => autocomplete(
        this.props.autoCompleteUri,
        term,
    ).then(this.buildOptions);

    render = () => (
        <AutoCompleteInput
            isMulti={this.props.isMulti}
            getOptions={this.getOptionsAndBuildOptions}
            getOptionsMillisecondsDelay={this.props.getOptionsMillisecondsDelay}
            openMenuOnClick={false}
            value={this.props.value}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            components={this.props.components}
            isClearable={this.props.isClearable}
            cacheOptions={this.props.cacheOptions}
            closeMenuOnSelect={this.props.closeMenuOnSelect}
        />
    );
}
