import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import get from "lodash/get";
import trim from "lodash/trim";
import noop from "lodash/noop";
import { Button } from "../Button/Button";
import { formatAddressStreetApartment } from "../../util/util";
import EmdLandingPageProfileEdit from "../EmdLandingPageProfileEdit/EmdLandingPageProfileEdit";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import mixpanel from "mixpanel-browser";

import "./emdLandingPageProfile.css";

export const ACCOUNTS_TAB = "accounts";
export const PROFILE_TAB = "profile";

export default class EmdLandingPageProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isVisibleSsn: false
        };
    }

    buildFullName = () => {
        const firstName = trim(this.getProfileFirstName());
        const lastName = trim(this.getProfileLastName());
        return `${firstName} ${lastName}`;
    };

    getProfilePicture = () =>
        get(this.props.user, "profile.publicProfile.userInfo.profilePicUrl");

    getProfileSSN = () => get(this.props.user, "profile.ssn");

    getProfileFirstName = () =>
        get(this.props.user, "profile.publicProfile.userInfo.firstName");

    getProfileLastName = () =>
        get(this.props.user, "profile.publicProfile.userInfo.lastName");

    getProfileCurrentAddress = () =>
        `${this.getProfileStreetNumberName()}, ${this.getProfileCity()}, ${this.getProfileState()}`;

    getProfileStreetNumberName = () =>
        get(this.props.user, "profile.currentAddress.streetNumberName");

    getProfileNeighborhood = () =>
        get(this.props.user, "profile.currentAddress.neighborhood");

    getProfileCity = () => get(this.props.user, "profile.currentAddress.city");

    getProfileState = () =>
        get(this.props.user, "profile.currentAddress.state");

    getProfileZipcode = () =>
        get(this.props.user, "profile.currentAddress.zipcode");

    getProfileApartment = () =>
        get(this.props.user, "profile.currentAddress.apartmentSuiteNumber");

    getProfileBirthMonth = () => get(this.props.user, "profile.birthMonth");

    getProfileBirthDayOfMonth = () =>
        get(this.props.user, "profile.birthDayOfMonth");

    getProfileBirthYear = () => get(this.props.user, "profile.birthYear");

    getProfileAddress = () =>
        formatAddressStreetApartment(
            this.getProfileCurrentAddress(),
            this.getProfileApartment()
        );

    getFormatBirthday = () => {
        const day = this.getProfileBirthDayOfMonth();
        const month = this.getProfileBirthMonth();
        const year = this.getProfileBirthYear();
        const date = new Date(year, month - 1, day);
        return format(date, "MMMM DD, YYYY");
    };

    buildItemList = (text, label) => (
        <div className="emd-landing-page__profile-item">
            <div className="emd-landing-page__profile-item-label">{label}</div>
            <div className="emd-landing-page__profile-items-text">{text}</div>
        </div>
    );

    onChangeVisibleSsn = e => {
        this.setState({ isVisibleSsn: !this.state.isVisibleSsn });
    };

    hideVisibleSsn = e => this.setState({ isVisibleSsn: false });

    transformTextHide = text => {
        const safeString = typeof text === "string" ? text : "";
        return `•••••••${safeString.substring(5)}`;
    };

    transformText = text => {
        return `${text.substring(0, 3)}-${text.substring(
            3,
            5
        )}-${text.substring(5)}`;
    };

    buildSsn = () => {
        const isVisibleDisable =
            this.props.isImpersonatingUser && this.props.isReadOnlyUserRole;

        if (isVisibleDisable) {
            const content = (
                <i
                    className={`emd-landing-page__profile-icon-eye-info col-xs-1 fa fa-eye`}
                />
            );

            return this.buildDisabledButtonTooltip(content);
        }

        return (
            <i
                onClick={this.onChangeVisibleSsn}
                className={`emd-landing-page__profile-icon-eye-info col-xs-1 fa ${
                    this.state.isVisibleSsn ? "fa-eye-slash" : "fa-eye"
                }`}
            />
        );
    };

    buildItemWithMask = (text, label) => (
        <div className="emd-landing-page__profile-item">
            <div className="emd-landing-page__profile-item-label">{label}</div>
            <div className="emd-landing-page__profile-item-mask">
                <div className="emd-landing-page__profile-items-text">
                    {this.state.isVisibleSsn
                        ? this.transformText(text)
                        : this.transformTextHide(text)}
                </div>
                <div className="emd-landing-page__profile-item-icon">
                    {this.buildSsn()}
                </div>
            </div>
        </div>
    );

    onClickEdit = () => {
        mixpanel.track(`Clicked On Edit Profile`);
        this.setState({ isEditing: true });
    };

    onCancelEdit = (trackMixpanel = true) => {
        if (trackMixpanel) {
            mixpanel.track(`Clicked On Cancel Edit Profile`);
        }
        this.setState({ isEditing: false, isVisibleSsn: false });
    };

    buildEditButton = () => {
        const disabledButton =
            this.props.isImpersonatingUser && this.props.isReadOnlyUserRole;

        if (disabledButton) {
            const content = (
                <Button
                    type="button"
                    onClick={noop}
                    className=" emd-landing-page__profile-edit-button"
                    disable
                >
                    Edit
                </Button>
            );

            return this.buildDisabledButtonTooltip(content);
        }

        return (
            <div className="emd-landing-page__profile-footer-edit-button">
                <Button
                    type="button"
                    onClick={this.onClickEdit}
                    className=" emd-landing-page__profile-edit-button"
                >
                    Edit
                </Button>
            </div>
        );
    };

    buildDisabledButtonTooltip = content => {
        const popoverText = `You do not have sufficient privileges to perform this action`;

        return (
            <div className="emd-landing-page__tooltip">
                <CustomTooltip
                    popoverText={popoverText}
                    content={content}
                ></CustomTooltip>
            </div>
        );
    };

    buildEditContent = () => (
        <EmdLandingPageProfileEdit
            loadUserProfile={this.props.loadUserProfile}
            user={this.props.user}
            prospect={this.props.prospect}
            editProspectInfo={this.props.editProspectInfo}
            onCancelEdit={this.onCancelEdit}
        />
    );

    buildProfileInfo = () => (
        <>
            <div className="emd-landing-page__profile-items">
                {this.buildItemList(this.buildFullName(), "Name:")}
                {this.buildItemList(this.getProfileAddress(), "Address:")}
                {this.buildItemList(this.getFormatBirthday(), "Birthday:")}
                {this.buildItemWithMask(this.getProfileSSN(), "SSN:")}
            </div>
            {this.buildEditButton()}
        </>
    );

    render = () => {
        const buildContent = this.state.isEditing
            ? this.buildEditContent()
            : this.buildProfileInfo();

        return (
            <div className="emd-landing-page__profile-container">
                {buildContent}
            </div>
        );
    };
}

EmdLandingPageProfile.propTypes = {
    loadUserProfile: PropTypes.func,
    user: PropTypes.object,
    prospect: PropTypes.object,
    editProspectInfo: PropTypes.func,
    isMidUserRole: PropTypes.bool,
    isReadOnlyUserRole: PropTypes.bool,
    isImpersonatingUser: PropTypes.bool
};
