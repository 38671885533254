import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function Handle({
    domain: [min, max],
    handle: { id, value, percent },
    disabled,
    getHandleProps
}) {
    return (
        <Fragment>
            <div className="range-slider-field-outer-handle"
                style={{
                    left: `${percent}%`
                }}
                {...getHandleProps(id)}
            />
            <div
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                className="range-slider-field-inner-handle"
                style={{
                    left: `${percent}%`,
                    borderBottomColor: disabled ? "#666" : "#000"
                }}
            />
        </Fragment>
    );
}

Handle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

Handle.defaultProps = {
    disabled: false
};

export default Handle;
