import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import join from "lodash/join";
import invoke from "lodash/invoke";
import size from "lodash/size";
import { components } from "react-select";
import LocationAutoCompleteInput from "../LocationAutoCompleteInput/LocationAutoCompleteInput";
import { maxNumberOfCities } from "../../shared/constants";
import { rawGoogleLocationToAddresses } from "../../util/util";
import { YupUniqueAddress } from "../../shared/validations";

import "./realtorservinginfield.css";

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <i className="fa fa-search" />
    </components.DropdownIndicator>
);

export default class RealtorServingInField extends Component {
    static propTypes = {
        value: PropTypes.any,
        canEdit: PropTypes.bool,
        onEdit: PropTypes.func,
        maxOptions: PropTypes.number,
        isEditing: PropTypes.func
    };

    static defaultProps = {
        maxOptions: maxNumberOfCities
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.valuesToLabeledOptions(props.value),
            hasErrorServingField: false,
            messageError: ""
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.canEdit !== this.props.canEdit) {
            this.onCancelEdit();
        }
    }

    valuesToLabeledOptions = values => map(values, this.valueToLabeledOption);

    valueToLabeledOption = value => {
        const label = this.buildLabel(value);
        return value && { value, label };
    };

    labeledOptionsToValues = labeledOptions =>
        map(labeledOptions, this.labeledOptionToValue);

    labeledOptionToValue = labeledOption => get(labeledOption, "value");

    onChangeField = fieldNewValue => {
        Promise.resolve()
            .then(this.cleanLocationInputValidations)
            .then(() => this.validateValue(fieldNewValue))
            .then(() => this.updateFieldValue(fieldNewValue))
            .catch(error =>
                this.setState({
                    hasErrorServingField: true,
                    messageError: get(error, "message")
                })
            );
    };

    cleanLocationInputValidations = () =>
        this.setState({
            hasErrorServingField: false,
            messageError: ""
        });

    validateValue = fieldNewValue =>
        Promise.resolve()
            .then(() => this.validateNumberOfAddresses(fieldNewValue))
            .then(() => this.validateUniqueAddresses(fieldNewValue));

    validateNumberOfAddresses = addresses => {
        const { maxOptions } = this.props;
        if (size(addresses) > maxOptions) {
            throw new Error(
                `The maximum number of cities to add is ${maxOptions}`
            );
        }
    };

    validateUniqueAddresses = rawAddresses =>
        rawGoogleLocationToAddresses(this.labeledOptionsToValues(rawAddresses))
            .then(this.hasRepeatedCities)
            .then(isInvalid => {
                if (isInvalid)
                    throw new Error("Duplicated cities are not allowed");
            });

    hasRepeatedCities = addresses => !YupUniqueAddress().isValidSync(addresses);

    updateFieldValue = addresses => {
        this.setState({
            value: addresses,
            hasErrorServingField: false,
            messageError: ""
        });
        invoke(this.props, "onEdit", addresses);
    };

    buildEditableField = () => {
        const allowAllResults = null;
        return (
            <LocationAutoCompleteInput
                value={this.state.value}
                getOptionsMillisecondsDelay={300}
                onChange={this.onChangeField}
                hasError={this.state.hasErrorServingField}
                components={{ DropdownIndicator }}
                isMulti
                types={allowAllResults}
            />
        );
    };

    buildLabel = value => {
        if (get(value, "label")) return get(value, "label");
        const neighborhood = get(value, "neighborhood");
        const city = get(value, "city");
        const abbreviation = get(value, "state");
        return neighborhood || city + ", " + abbreviation;
    };

    buildFieldValue = () => {
        const { value } = this.props;
        const location = map(value, this.buildLabel);
        const fieldValue = join(location, "; ");
        return (
            <div className="realtor-serving-in-field__container">
                <i className="fas fa-map-marker-alt realtor-serving-in-field__icon" />
                <div className="realtor-serving-in-field__field-value">
                    {fieldValue}
                </div>
            </div>
        );
    };

    onEdit = () => this.setState({ isEditing: true });

    onCancelEdit = () => {
        this.setState({
            isEditing: false,
            hasErrorServingField: false,
            messageError: "",
            value: this.valuesToLabeledOptions(this.props.value)
        });
    };

    render = () => {
        const { hasErrorServingField, messageError } = this.state;
        const { isEditing } = this.props;
        const field = isEditing
            ? this.buildEditableField()
            : this.buildFieldValue();
        const fieldError =
            hasErrorServingField && isEditing ? (
                <div className="realtor-serving-in-field__field-label__error">
                    {messageError}
                </div>
            ) : null;

        return (
            <div className="realtor-serving-in-field">
                <h5 className="realtor-serving-in-field__field-label">
                    WHAT CITIES DO YOU COVER
                </h5>
                {field}
                {fieldError}
            </div>
        );
    };
}
