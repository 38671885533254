import find from "lodash/find";
import isNull from "lodash/isNull";
import filter from "lodash/filter";
import join from "lodash/join";
import map from "lodash/map";
import first from "lodash/first";
import reject from "lodash/reject";
import invoke from "lodash/invoke";
import queryString from "querystring";
import toString from "lodash/toString";
import split from "lodash/split";
import parse from "date-fns/parse";
import isEmpty from "lodash/isEmpty";
import { geocodeByPlaceId } from "react-places-autocomplete";

export function getCsrfToken() {
    const name = "XSRF-TOKEN=";
    const ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// Maps array of strings X to array of objects: { value: X, label: X } to use with select and radio inputs
export const mapValuesToOptions = (arr = []) =>
    arr.map(item => ({ value: item, label: `${item}` }));

// Creates an array of numbers given range
export const numberRangeOptions = (from, to, s = Math.sign(to - from)) =>
    mapValuesToOptions(
        Array.from({ length: Math.abs(to - from) + 1 }, (_, k) => from + k * s)
    );

// Creates an array of whole and half numbers given range
export const halfAndWholeNumberRangeOptions = (from, to, s = 0.5) =>
    mapValuesToOptions(
        Array.from(
            { length: Math.abs(to - from) + 0.5 },
            (_, k) => from + k * s
        )
    );

// Get label by option value
export const getOptionLabel = (options, value, defaultValue = "") =>
    (find(options, { value }) || {}).label || defaultValue;

const rxDigitGroup = /(\d)(?=(\d{3})+(?:\.\d+)?$)/g;
export const formatCurrency = (value, fractionDigits = 2) =>
    `$${(value || 0).toFixed(fractionDigits).replace(rxDigitGroup, "$1,")}`;

const getAddressComponentByType = (components = [], type, path = "long_name") =>
    (components.find(component => component.types.includes(type)) || {})[
        path
    ] || "";

export function getAddressFromPlaceDetails({
    address_components: addressComponents = {},
    geometry = {}
} = {}) {
    const getComponent = (type, path) =>
        getAddressComponentByType(addressComponents, type, path);

    const streetNumber = getComponent("street_number");
    const streetName = getComponent("route");
    const neighborhood = getComponent("neighborhood");

    return {
        address: `${streetNumber} ${streetName}`.trim(),
        streetNumber,
        streetName,
        neighborhood,
        zipcode: getComponent("postal_code"),
        city:
            getComponent("locality") ||
            getComponent("sublocality") ||
            neighborhood ||
            getComponent("administrative_area_level_3") ||
            getComponent("administrative_area_level_2"),
        state: `${getComponent("administrative_area_level_1", "short_name")}`,
        country: getComponent("country"),
        lat: invoke(geometry, "location.lat"),
        lng: invoke(geometry, "location.lng")
    };
}

export async function rawGoogleLocationToAddresses(rawLocations) {
    const alreadyValidLocations = reject(rawLocations, "place_id");
    const invalidLocations = filter(rawLocations, "place_id");
    const invalidLocationPlaceIds = map(invalidLocations, "place_id");
    const invalidPlacesGeoCodes = map(
        invalidLocationPlaceIds,
        geocodeByPlaceId
    );

    const locationToAddress = location =>
        getAddressFromPlaceDetails(first(location));

    const locations = await Promise.all(invalidPlacesGeoCodes);
    const newValidLocations = map(locations, locationToAddress);
    return [...alreadyValidLocations, ...newValidLocations];
}

export class IframeWatcher {
    constructor(callback = () => {}) {
        this.handler = event => {
            if (event.data && event.data.__type === "frame-close")
                callback(event.data);
        };
    }

    start() {
        window.addEventListener("message", this.handler, false);
    }

    stop() {
        window.removeEventListener("message", this.handler, false);
    }
}

// Get the percentage multiplied by 100 to use in NumberFormat
export const getFormatPercentage = percentage => percentage / 100;

// The document is downloaded depending on the browser
export const downloadDocument = (response, fileNameWithExtension) => {
    const blob = new Blob([response.data]);
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileNameWithExtension);
    } else {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileNameWithExtension;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const getEnvironmentName = () => {
    const hostname = `${window.location.hostname}`.toLowerCase();
    return (
        {
            localhost: "local",
            "127.0.0.1": "local",
            "local.lemonbrew.com": "local",
            "dev.lemonbrew.com": "staging"
        }[hostname] || "production"
    );
};

export const parseQueryParams = rawQueryParams => {
    const stringParams = toString(rawQueryParams);
    const rawQueryParamsWithoutPrefix = stringParams.slice(1);
    const queryParams = queryString.parse(rawQueryParamsWithoutPrefix);
    return queryParams;
};

export const getUTCDate = dateStr => {
    const date = parse(dateStr);
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
};

export const isMobile = () => window.innerWidth <= 600;

export const rangeOptions = (min, max) =>
    map(numberRangeOptions(min, max), option => ({
        value: option.value,
        label: option.value.toString()
    }));

export const getQueryVariable = (query, variable, splitSymbol = "&") => {
    if (!isEmpty(query)) {
        const vars = split(query, splitSymbol);
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }
    }
    return null;
};

export const filterNotNull = list => filter(list, item => !isNull(item));

export const formatAddressStreetApartment = (
    streetNumberName,
    apartmentSuiteNumber,
    withSymbol = true
) => {
    const formatAddress = filterNotNull([
        streetNumberName,
        apartmentSuiteNumber
    ]);
    if (withSymbol) {
        return join(formatAddress, ", #");
    }
    return join(formatAddress, ", ");
};

export const formatAddressCityStateZipCode = (city, state, zipcode) => {
    const formatAddress = filterNotNull([city, state]);
    const formatZipCode = !isNull(zipcode) ? ` ${zipcode}` : "";
    return `${join(formatAddress, ", ")}${formatZipCode}`;
};

export const delayBy = (msec = 0) =>
    new Promise(resolve => window.setTimeout(resolve, msec));
