import React, { Fragment } from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import trim from "lodash/trim";
import includes from "lodash/includes";
import RealtorNavbarAvatar from "../RealtorNavbarAvatar/RealtorNavbarAvatar";
import logoImg from "../../styles/assets/logo.svg";
import { Button } from "../Button/Button";
import { onLogoutImpersonateUser } from "../../reducers/admin/AdminActions";
import NavbarMenu from "./NavbarMenu";
import "./navbar.css";

const profile = "/admin-profile";
const realtorMap = "/admin-realtor-map";
const roles = "/admin-brewcrew";
const licenses = "/admin-licenses";
const stats = "/admin-stats";
const buyerStats = "/admin-buyer-stats";
const campaigns = "/admin-campaigns";
const broker = "/admin-broker";

const newDesignPaths = [
    profile,
    realtorMap,
    roles,
    licenses,
    stats,
    buyerStats,
    campaigns,
    broker
];

class HeaderComponent extends React.Component {
    static propTypes = {
        isAuth: PropTypes.bool,
        isRealtor: PropTypes.bool,
        isAdmin: PropTypes.bool,
        picture: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        location: PropTypes.any,
        jobName: PropTypes.string,
        jobLocation: PropTypes.string,
        createNewAdmin: PropTypes.func,
        successCreateNewAdmin: PropTypes.bool,
        isFetchingCreateNewAdmin: PropTypes.bool,
        isImpersonateUser: PropTypes.bool,
        logoutImpersonateUser: PropTypes.func,
        isFetchingImpersonateUserProfile: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            isVisibleCreateAdminModal: false,
            menuOpen: false
        };
        this.logOutRouteChange = this.logOutRouteChange.bind(this);
        this.logInRouteChange = this.logInRouteChange.bind(this);
        this.exitImpersonatedUserRouteChange = this.exitImpersonatedUserRouteChange.bind(
            this
        );
    }

    handleMenuClick() {
        this.setState({ menuOpen: !this.state.menuOpen });
        let body = document.body;
        body.classList.toggle("bodyFixed");
    }

    handleLinkClick() {
        this.setState({ menuOpen: false });
    }

    buildRealtorNavMenu = () => (
        <RealtorNavbarAvatar
            picture={this.props.picture}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
            jobName={this.props.jobName}
            jobLocation={this.props.jobLocation}
            isImpersonateUser={this.props.isImpersonateUser}
            logoutImpersonateUser={this.props.logoutImpersonateUser}
        />
    );

    logOutRouteChange(event) {
        let path = `/signout`;
        this.props.history.push(path);
        event.preventDefault();
    }

    logInRouteChange(event) {
        let path = `/signin`;
        this.props.history.push(path);
        event.preventDefault();
    }

    exitImpersonatedUserRouteChange(event) {
        this.props.logoutImpersonateUser();
        let path = `/profile`;
        this.props.history.push(path);
        event.preventDefault();
    }

    buildLogoutButton = () => (
        <Button className=" logout-admin" onClick={this.logOutRouteChange}>
            LOG OUT
        </Button>
    );

    buildLoginButton = () => (
        <Button onClick={this.logInRouteChange}>LOG IN</Button>
    );

    buildSessionButton = () => {
        if (this.props.isBeingImpersonated) {
            return null;
        }

        if (this.props.isAuth) {
            return this.buildLogoutButton();
        }

        if (this.props.location.pathname !== "/signin") {
            return this.buildLoginButton();
        }

        return null;
    };

    buildPhoneNumber = () => (
        <div className="phone-number">
            Call us: <a href="tel:1-833-536-6627">833-LEMONBREW (536-6627)</a>
        </div>
    );

    buildAdminNavMenu = () => (
        <div className="phone-number-wraper">
            {this.buildPhoneNumber()}
            {this.buildLogoutButton()}
        </div>
    );

    buildDefaultNavMenu = () => (
        <div className="phone-number-wraper">
            {this.buildPhoneNumber()}
            {this.buildSessionButton()}
        </div>
    );

    buildNavMenu = () => {
        if (this.props.isAdmin) return this.buildAdminNavMenu();

        if (this.props.isRealtor) return this.buildRealtorNavMenu();

        return this.buildDefaultNavMenu();
    };

    buildImpersonateUserNotification = () => {
        const {
            isImpersonateUser,
            isFetchingImpersonateUserProfile,
            firstName,
            lastName,
            isBeingImpersonated
        } = this.props;

        if (
            (!isImpersonateUser || isFetchingImpersonateUserProfile) &&
            !isBeingImpersonated
        ) {
            return null;
        }

        return (
            <div className="navbar__container--impersonate-user">
                <div className="navbar__container--impersonate-user-name">
                    {`You are impersonating user ${firstName} ${lastName}`}
                </div>
                <Button gray onClick={this.exitImpersonatedUserRouteChange}>
                    Exit
                </Button>
            </div>
        );
    };

    renderOldNavBar = () => (
        <div>
            {this.buildImpersonateUserNotification()}
            <div className="navbar__container">
                <a href={process.env.REACT_APP_LOGO_URL} className="link">
                    <img className="icon" src={logoImg} alt="LemonBrew" />
                    <span className="logo">LemonBrew</span>
                </a>
                {this.buildNavMenu()}
            </div>
        </div>
    );

    buildNavAdmin = () => {
        const { pathname } = this.props.location;
        if (includes(profile, pathname)) return <NavbarMenu selected="users" />;
        if (includes(realtorMap, pathname))
            return <NavbarMenu selected="realtor-map" />;
        if (includes(roles, pathname))
            return <NavbarMenu selected="brewcrew" />;
        if (includes(licenses, pathname))
            return <NavbarMenu selected="licenses" />;
        if (includes(stats, pathname)) return <NavbarMenu selected="stats" />;
        if (includes(buyerStats, pathname))
            return <NavbarMenu selected="stats-buyer" />;
        if (includes(campaigns, pathname))
            return <NavbarMenu selected="campaigns" />;
        return;
    };

    renderNewNavBar = () => {
        const isAdminBroker = window.location.pathname;

        return (
            <Fragment>
                {this.buildImpersonateUserNotification()}
                <div
                    className={`navbar__container is-new ${
                        isAdminBroker === "/admin-broker"
                            ? "is-admin-broker"
                            : ""
                    }`}
                >
                    <a href={process.env.REACT_APP_LOGO_URL} className="link">
                        <div className="icon-logo-black" />
                        <span className="logo">LemonBrew</span>
                    </a>
                    <div
                        className={`navbar__toggle navbar__toggle--hamburger ${
                            this.state.menuOpen ? "active" : ""
                        }`}
                        onClick={() => this.handleMenuClick()}
                    >
                        <span></span>
                    </div>
                    <div className="navbar__menu">
                        {this.buildNavAdmin()}
                        {this.buildNavMenu()}
                    </div>
                    <div
                        className={`navbar__menu-mobile ${
                            this.state.menuOpen ? "opened" : ""
                        }`}
                        onClick={() => this.handleMenuClick()}
                    >
                        {this.buildNavAdmin()}
                        {this.buildNavMenu()}
                    </div>
                </div>
            </Fragment>
        );
    };

    render() {
        const { pathname } = this.props.location;

        return newDesignPaths.includes(pathname)
            ? this.renderNewNavBar()
            : this.renderOldNavBar();
    }
}

HeaderComponent.propTypes = {
    isImpersonateUser: PropTypes.bool
};

const mapStateToProps = state => ({
    isAuth: state.user.authorization,
    isRealtor: get(state.user.profile, "publicProfile.userInfo.realtor"),
    isAdmin: get(state.user.profile, "publicProfile.userInfo.brewCrew"),
    picture: get(state.user.profile, "publicProfile.userInfo.profilePicUrl"),
    firstName: trim(
        get(state.user.profile, "publicProfile.userInfo.firstName")
    ),
    lastName: trim(get(state.user.profile, "publicProfile.userInfo.lastName")),
    jobName:
        trim(get(state.user.profile, "publicProfile.position.name")) ||
        "Real Estate Agent",
    jobLocation: get(state.user.profile, "publicProfile.brokerage.name"),
    isFetchingImpersonateUserProfile: get(
        state.user,
        "isFetchingImpersonateUserProfile"
    ),
    successCreateNewAdmin: get(state.admin, "newAdmin.successCreateNewAdmin"),
    isFetchingCreateNewAdmin: get(
        state.admin,
        "newAdmin.isFetchingCreateNewAdmin"
    ),
    isImpersonateUser: get(
        state.admin,
        "loginImpersonateUser.successImpersonateUser"
    ),
    isBeingImpersonated: get(
        state.user.profile,
        "publicProfile.userInfo.isBeingImpersonated"
    )
});

const Header = withRouter(
    connect(mapStateToProps, {
        logoutImpersonateUser: onLogoutImpersonateUser
    })(HeaderComponent)
);

export default Header;
