import {
    CREATE_NEW_PRIZE_EVENT_FETCH,
    CREATE_NEW_PRIZE_EVENT_SUCCESS,
    CREATE_NEW_PRIZE_EVENT_ERROR,
    GET_WHEEL_ACTIVE_LIST_FETCH,
    GET_WHEEL_ACTIVE_LIST_SUCCESS,
    GET_WHEEL_ACTIVE_LIST_ERROR,
    GET_WHEEL_SPECIFIC_EVENT_FETCH,
    GET_WHEEL_SPECIFIC_EVENT_SUCCESS,
    GET_WHEEL_SPECIFIC_EVENT_ERROR,
    SPIN_WHEEL_FETCH,
    SPIN_WHEEL_SUCCESS,
    SPIN_WHEEL_ERROR,
} from './SpinActionTypes';

const initialState = {
    createNewEvent: {
        events: {},
        successCreateNewEvent: false,
        isFetchingCreateNewEvent: false,
        errorCreateNewEvent: false,
    },
    getActiveList: {
        list: [],
        successGetActiveList: false,
        isFetchingGetActiveList: false,
        errorGetActiveList: false,
    },
    getSpecificEvent: {
        data: {},
        successGetSpecificEvent: false,
        isFetchingGetSpecificEvent: false,
        errorGetSpecificEvent: false,
    },
    spinWheel: {
        prize: {},
        successSpinWheel: false,
        isFetchingSpinWheel: false,
        errorSpinWheel: false,
    },
};

const spinReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case CREATE_NEW_PRIZE_EVENT_FETCH:
        return {
            ...state,
            createNewEvent: {
                ...state.createNewEvent,
                isFetchingCreateNewEvent: true,
            },
        };
    case CREATE_NEW_PRIZE_EVENT_SUCCESS:
        return {
            ...state,
            createNewEvent: {
                ...state.createNewEvent,
                isFetchingCreateNewEvent: false,
                successCreateNewEvent: true,
                events: payload.response,
            },
        };
    case CREATE_NEW_PRIZE_EVENT_ERROR:
        return {
            ...state,
            createNewEvent: {
                ...state.createNewEvent,
                isFetchingCreateNewEvent: false,
                successCreateNewEvent: false,
                errorCreateNewEvent: true,
            },
        };
    case GET_WHEEL_ACTIVE_LIST_FETCH:
        return {
            ...state,
            getActiveList: {
                ...state.getActiveList,
                isFetchingGetActiveList: true,
            },
        };
    case GET_WHEEL_ACTIVE_LIST_SUCCESS:
        return {
            ...state,
            getActiveList: {
                ...state.getActiveList,
                isFetchingGetActiveList: false,
                successGetActiveList: true,
                list: payload.response,
            },
        };
    case GET_WHEEL_ACTIVE_LIST_ERROR:
        return {
            ...state,
            getActiveList: {
                ...state.getActiveList,
                isFetchingGetActiveList: false,
                successGetActiveList: false,
                errorGetActiveList: true,
            },
        };
    case GET_WHEEL_SPECIFIC_EVENT_FETCH:
        return {
            ...state,
            getSpecificEvent: {
                ...state.getSpecificEvent,
                isFetchingGetSpecificEvent: true,
            },
        };
    case GET_WHEEL_SPECIFIC_EVENT_SUCCESS:
        return {
            ...state,
            getSpecificEvent: {
                ...state.getSpecificEvent,
                isFetchingGetSpecificEvent: false,
                successGetSpecificEvent: true,
                data: payload.response,
            },
        };
    case GET_WHEEL_SPECIFIC_EVENT_ERROR:
        return {
            ...state,
            getSpecificEvent: {
                ...state.getSpecificEvent,
                isFetchingGetSpecificEvent: false,
                successGetSpecificEvent: false,
                errorGetSpecificEvent: true,
            },
        };

    case SPIN_WHEEL_FETCH:
        return {
            ...state,
            spinWheel: {
                ...state.spinWheel,
                isFetchingSpinWheel: true,
            },
        };
    case SPIN_WHEEL_SUCCESS:
        return {
            ...state,
            spinWheel: {
                ...state.spinWheel,
                prize: payload.response,
                isFetchingSpinWheel: false,
                successSpinWheel: true,
            },
        };
    case SPIN_WHEEL_ERROR:
        return {
            ...state,
            spinWheel: {
                ...state.spinWheel,
                isFetchingSpinWheel: false,
                successSpinWheel: false,
                errorSpinWheel: true,
            },
        };
    default:
        return state;
    }
};

export default spinReducer;
