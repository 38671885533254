import React, { Fragment } from 'react';
import get from 'lodash/get';
import BaseWizardStep from '../BaseWizardStep';
import Summary from './Summary';
import forEach from 'lodash/forEach';
import set from 'lodash/set';
import mixpanel from 'mixpanel-browser';

class StepSummary extends BaseWizardStep {
    mixpanelFunction = () => {
        const { brewScore, searchAgents } = this.props;
        const rebateAmount = get(brewScore, 'brewScore.rebateAmount');
        const score = get(brewScore, 'brewScore.brewScore');
        const agentsCount = get(searchAgents, 'searchAgents.agentsCount');
        const agents = get(searchAgents, 'searchAgents.agents');

        let matchedAgents = {};
        forEach(agents, (value, index) => {
            set(matchedAgents,`agentId${index}`, get(value, 'userInfo.id'));
            set(matchedAgents,`agentProfilePicUrl${index}`, get(value, 'userInfo.profilePicUrl'));
            set(matchedAgents,`agentBrokerage${index}`, get(value, 'brokerage.name'));
            set(matchedAgents,`agentPosition${index}`, get(value, 'position.name'));
        });

        mixpanel.track(
            "Reviewed Summary And Clicked On View My Rebate And Agents", {
                "brewScore": score,
                "rebateAmount": rebateAmount,
                "agentsCount": agentsCount,
                ...matchedAgents, 
            }
        )
    };

    render() {
        return (
            <Fragment>
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__fieldset is-white-widest">
                        <div className="buyer__signup__question">
                            Your Purchase Summary
                        </div>

                        <div className="buyer__signup__fieldset is-full-width">
                            <Summary
                                values={this.props.values}
                            />
                            <div className="buyer__signup__brewscore-under-info"></div>
                        </div>
                    </div>
                </div>
                { this.renderStepButtons({
                    canProceed: true,
                    getBrewScore: true,
                    getBrowseAgents: true,
                    hasMixPanelTracking: true,
                    buttonsWithoutMargin: true,
                }, this.mixpanelFunction)}
            </Fragment>
        );
    };
}

export default StepSummary;
