import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
    Switch,
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom';
import RealtorProfile, { ACTIVE_MATCHES_TAB, PENDING_MATCHES_TAB } from '../RealtorProfile/RealtorProfile';
import UserRealtorProfile from '../UserProfile/UserRealtorProfile';
import { receiveUserProfile } from '../../reducers/user/UserActions';
import RealtorActiveMatchesTabRoute from '../RealtorActiveMatchesTabRoute/RealtorActiveMatchesTabRoute';
import TourRealtorProfile from '../UserProfile/TourRealtorProfile/TourRealtorProfile';

class RealtorRoute extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.any,
        match: PropTypes.any,
        onUpdateProfile: PropTypes.func,
    };

    getProfile = () => get(this.props.user, 'profile');

    componentDidMount = () => {
        const { user, history } = this.props;
        const isProfileFetched = get(user, 'isProfileFetched');
        const isNotRealtor = !get(user, 'profile.publicProfile.userInfo.realtor');

        if (isProfileFetched && isNotRealtor) {
            history.replace('/');
        }
    };

    onChangeTab = (tab) => {
        this.props.history.push(`${this.props.match.path}/${tab}`);
    };

    hasRealtorActiveMatches = () => {
        const activeMatches = get(this.props.user, 'profile.buyers');
        return !isEmpty(activeMatches);
    };

    getIsImpersonatingUser = () => (get(this.props.user, 'isImpersonatingUser'));

    render = () => (
        <React.Fragment>
            <Switch>
                <Route
                    path={`${this.props.match.path}/${ACTIVE_MATCHES_TAB}/seller/view/:userId`}
                    render={({ match }) => (
                        <RealtorActiveMatchesTabRoute
                            hasActiveMatches={this.hasRealtorActiveMatches()}
                            onChangeTab={this.onChangeTab}
                            displaySellerProfile={Number(match.params.userId)}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.path}/${ACTIVE_MATCHES_TAB}/view/:userId`}
                    render={({ match }) => (
                        <RealtorActiveMatchesTabRoute
                            hasActiveMatches={this.hasRealtorActiveMatches()}
                            onChangeTab={this.onChangeTab}
                            displayBuyerProfile={Number(match.params.userId)}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.path}/${ACTIVE_MATCHES_TAB}`}
                    render={() => (
                        <RealtorActiveMatchesTabRoute
                            hasActiveMatches={this.hasRealtorActiveMatches()}
                            onChangeTab={this.onChangeTab}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.path}/${PENDING_MATCHES_TAB}`}
                    render={() => (
                        <RealtorProfile
                            tab={PENDING_MATCHES_TAB}
                            onChangeTab={this.onChangeTab}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.path}/profile/preview`}
                    render={() => (
                        <UserRealtorProfile
                            isPublicView
                            profile={this.getProfile()}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.path}/profile/edit`}
                    render={() => (
                        <TourRealtorProfile
                            profile={this.getProfile()}
                            isImpersonatingUser={this.getIsImpersonatingUser()}
                            onUpdateProfile={this.props.onUpdateProfile}
                        />
                    )}
                />
                <Route render={() => <Redirect to={`/agents-landing-page`} />} />
            </Switch>
        </React.Fragment>
    );
}

export default withRouter(connect(
    (state) => ({ user: state.user }),
    {
        onUpdateProfile: receiveUserProfile,
    },
)(RealtorRoute));
