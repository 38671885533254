import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import { Button } from '../Button/Button';
import { updateRealtorProfile } from '../../util/service_api';
import './realtorvideoprofilebutton.css';

export default class RealtorVideoProfileButton extends React.Component {
    static propTypes = {
        videoUrl: PropTypes.string,
        canEdit: PropTypes.bool,
        className: PropTypes.string,
        isImpersonatingUser: PropTypes.bool,
        design: Button.propTypes.design,
        onUpdateField: PropTypes.func,
    };

    static defaultProps = {
        design: 'yellow',
    };

    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            value: this.props.videoUrl,
        };
    }

    onGoToVideoProfile = () => window.open(this.props.videoUrl, '_blank');

    buildCompleteVideoProfileButton = () => (
        <div id="realtor-tour-complete-video-interview">
            <Link to="/sparkhire/intro">
                <Button
                    small
                    className={` ${this.props.className}`}
                    design={this.props.design}
                >
                    Complete Video Interview
                </Button>
            </Link>
        </div>
    );

    onEdit = () => {
        this.setState({ isEditing: true });
    };

    onCancelEdit = () => {
        this.setState({
            isEditing: false,
            value: this.props.videoUrl,
        });
    };

    buildEditButton = () => (
        <button
            type="button"
            className="btn-link realtor-video-profile-button__edit-button"
            onClick={this.onEdit}
        >
            Edit
        </button>
    );

    onCancelEdit = () => {
        this.setState({
            isEditing: false,
        });
    };

    buildCancelEditButton = () => (
        <button
            type="button"
            className="btn-link realtor-video-profile-button__cancel-button"
            onClick={this.onCancelEdit}
        >
            Cancel
        </button>
    );

    onConfirmEdit = () => {
        const videoProfileUrl = 'profileVideoUrl';
        updateRealtorProfile({ [videoProfileUrl]: this.state.value })
            .then(this.onSuccessfullyEdit);
    };

    onSuccessfullyEdit = () => {
        this.setState({ isEditing: false });
        if (isFunction(this.props.onUpdateField)) {
            this.props.onUpdateField(
                'publicProfile.profileVideoUrl',
                this.state.value,
            );
        }
    };

    buildConfirmEditButton = () => (
        <button
            type="button"
            className="btn-link realtor-video-profile-button__save-button"
            onClick={this.onConfirmEdit}
        >
            Save
        </button>
    );

    buildCancelAndConfirmEditButtons = () => (
        <div className="realtor-video-profile-button__cancel-confirm-buttons">
            {this.buildCancelEditButton()}
            {this.buildConfirmEditButton()}
        </div>
    );

    onChange = (value) => {
        if (isNil(value.target.value)) { return; }
        this.setState({ value: value.target.value });
    };

    buildInputVideoUrl = () => (
        <input
            className="form-control realtor-video-profile-button__field-editable"
            value={this.state.value}
            type="text"
            placeholder=""
            onChange={this.onChange}
        />
    )

    buildAdminVideoProfileButton = () => (
        <div className="realtor-video-profile-button__admin-view">
            {!this.state.isEditing && this.buildEditButton()}
            {this.state.isEditing && this.buildCancelAndConfirmEditButtons()}
            {this.state.isEditing && this.buildInputVideoUrl()}
            {!this.state.isEditing && !this.state.value && this.buildCompleteVideoProfileButton()}
            {!this.state.isEditing && this.state.value && this.buildVideoProfileButton()}
        </div>
    );

    buildVideoProfileButton = () => (
        <Button
            small
            yellow
            design={this.props.design}
            onClick={this.onGoToVideoProfile}
        >
            View Video Profile
        </Button>
    );

    render = () => {
        if (this.props.canEdit && !this.props.videoUrl && !this.props.isImpersonatingUser) {
            return this.buildCompleteVideoProfileButton();
        }
        if (this.props.canEdit && this.props.isImpersonatingUser) {
            return this.buildAdminVideoProfileButton();
        }

        return this.props.videoUrl ? this.buildVideoProfileButton() : null;
    }
}
