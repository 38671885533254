import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Form } from "formik";
import get from "lodash/get";
import BrokerSignUpSuccess from "./BrokerSignUpSuccess/BrokerSignUpSuccess";
import AboutYourself from "./AboutYourself/AboutYourself";
import SimpleWizard from "../../Wizard/SimpleWizard";

import "../signupform.css";
import "./index.css";

const STEP_NAMES = ["aboutYourself"];

class BrokerSignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = { currentStep: 0 };
    }

    onNavigate = (step, index, steps) => {
        this.setState({ currentStep: index });
    };

    isStepInvalid = stepName => {
        const { errors, touched } = this.props;
        const untouched = !get(touched, stepName);
        const hasErrors = get(errors, stepName) != null;
        return untouched || hasErrors;
    };

    isCurrentStepInvalid = () => {
        const currentStepName = STEP_NAMES[this.state.currentStep];
        return currentStepName && this.isStepInvalid(currentStepName);
    };

    buildAboutYourSelfStep = () => ({
        label: (
            <span>
                About
                <br />
                Yourself
            </span>
        ),
        component: <AboutYourself {...this.props} />,
        isSubmittable: true
    });

    buildSuccessStep = () => ({
        hideStepButtons: true,
        component: <BrokerSignUpSuccess />
    });

    render = () => {
        const isCompleted = Boolean(get(this.props.status, "success"));

        return (
            <Form className="broker__signupform">
                <SimpleWizard
                    initialStep={0}
                    completedStep={1}
                    displayProgressHeader={false}
                    isSubmitting={this.props.isSubmitting}
                    isCompleted={isCompleted}
                    blockForward={this.isCurrentStepInvalid()}
                    onNavigate={this.onNavigate}
                    steps={[
                        this.buildAboutYourSelfStep(),
                        this.buildSuccessStep()
                    ]}
                />
            </Form>
        );
    };
}

BrokerSignUpForm.propTypes = {
    error: PropTypes.any,
    errors: PropTypes.object,
    touched: PropTypes.shape({}),
    isSubmitting: PropTypes.bool,
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
    dirty: PropTypes.bool,
    status: PropTypes.string,
    setFieldTouched: PropTypes.func,
    isValid: PropTypes.bool
};

export default withRouter(BrokerSignUpForm);
