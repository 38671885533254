import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import { components } from "react-select";
import isFunction from "lodash/isFunction";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import { Button } from "../Button/Button";
import CloseModalButton from "../CloseModalButton/CloseModalButton";
import PasswordStrengthInfoTooltip from "../PasswordStrengthInfoTooltip/index";
import AutoCompleteInput from "../AutoCompleteInput/AutoCompleteInput";
import {
    REGEXP_AT_LEAST_9_CHAR,
    REGEXP_UPPERCASE_NUMBER_SPECIAL_CHAR,
    ERROR_MESSAGE_ADMIN_MODAL
} from "../../shared/constants";

import "./createAdminModal.css";
import isEmpty from "lodash/isEmpty";

const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
        <i className="fa fa-search" />
    </components.DropdownIndicator>
);

export default class CreateAdminModal extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        isSavingAdmin: PropTypes.bool,
        isSavedAdmin: PropTypes.bool,
        roles: PropTypes.any,
        errorCreateNewAdmin: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.resetState();
        }
    }

    resetState = () => this.setState(this.getInitialState());

    getInitialState = () => ({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        password: "",
        password2: "",
        firstNameError: null,
        lastNameError: null,
        emailError: null,
        roleError: null,
        passwordError: null,
        password2Error: null,
        isSaveInit: false
    });

    onChangeFirstName = e =>
        this.setState({ firstName: e.target.value, firstNameError: null });

    onChangeLastName = e =>
        this.setState({ lastName: e.target.value, lastNameError: null });

    onChangeEmail = e =>
        this.setState({ email: e.target.value, emailError: null });

    onChangeRole = e => this.setState({ role: e, typeError: null });

    onChangePassword = e =>
        this.setState({ password: e.target.value, passwordError: null });

    onChangePassword2 = e =>
        this.setState({ password2: e.target.value, password2Error: null });

    buildErrorMessage = error => (
        <div className="create-admin-modal__password--error">
            <div className="create-admin-modal__field-error--content">
                <div className="create-admin-modal__field-error">{error}</div>
            </div>
        </div>
    );

    setErrorMessageFieldRequired = (field, value, message) => {
        const newValue = !value ? ERROR_MESSAGE_ADMIN_MODAL[0](message) : null;
        this.setState({ [`${field}Error`]: newValue });
    };

    setErrorMessage = (field, type) => {
        const newValue = ERROR_MESSAGE_ADMIN_MODAL[type];
        this.setState({ [`${field}Error`]: newValue });
    };

    validateErrorMessage = field => {
        const errorMessage = get(this.state, `${field}Error`);
        return errorMessage ? this.buildErrorMessage(errorMessage) : null;
    };

    validatePassword = () => {
        const { password } = this.state;
        if (!password) {
            this.setErrorMessageFieldRequired("password", password, "Password");
            return;
        }

        if (!REGEXP_AT_LEAST_9_CHAR.test(password)) {
            this.setErrorMessage("password", 1);
            return;
        }

        if (!REGEXP_UPPERCASE_NUMBER_SPECIAL_CHAR.test(password)) {
            this.setErrorMessage("password", 2);
            return;
        }

        this.setState({ passwordError: null });
    };

    validateConfirmPassword = () => {
        const { password, password2 } = this.state;
        if (!password2) {
            this.setErrorMessageFieldRequired(
                "password2",
                password2,
                "Confirm Password"
            );
            return;
        }

        if (!isEqual(password, password2)) {
            this.setErrorMessage("password2", 3);
            return;
        }

        this.setState({ passwordError2: null });
    };

    buildFirstNameField = () => {
        const { firstName } = this.state;
        return (
            <div className="form-group create-admin-modal__container">
                <label
                    className="create-admin-modal__form-label--first-name"
                    htmlFor="firstNameLabel"
                >
                    Contact First Name
                    <input
                        id="firstName"
                        type="text"
                        className="form-control create-admin-modal__form-field--first-name"
                        value={firstName}
                        onChange={this.onChangeFirstName}
                        onBlur={() =>
                            this.setErrorMessageFieldRequired(
                                "firstName",
                                firstName,
                                "First Name"
                            )
                        }
                    />
                </label>
                {this.validateErrorMessage("firstName")}
            </div>
        );
    };

    buildLastNameField = () => {
        const { lastName } = this.state;
        return (
            <div className="form-group create-admin-modal__container">
                <label
                    className="create-admin-modal__form-label--last-name"
                    htmlFor="lastNameLabel"
                >
                    Contact Last Name
                    <input
                        id="lastName"
                        type="text"
                        className="form-control create-admin-modal__form-field--last-name"
                        value={lastName}
                        onChange={this.onChangeLastName}
                        onBlur={() =>
                            this.setErrorMessageFieldRequired(
                                "lastName",
                                lastName,
                                "Last Name"
                            )
                        }
                    />
                </label>
                {this.validateErrorMessage("lastName")}
            </div>
        );
    };

    buildEmailField = () => {
        const { email } = this.state;
        return (
            <div className="form-group create-admin-modal__container">
                <label
                    className="create-admin-modal__form-label--email"
                    htmlFor="emailLabel"
                >
                    Contact email
                    <input
                        id="email"
                        type="email"
                        className="form-control create-admin-modal__form-field--email"
                        value={email}
                        onChange={this.onChangeEmail}
                        onBlur={() =>
                            this.setErrorMessageFieldRequired(
                                "email",
                                email,
                                "Email"
                            )
                        }
                    />
                </label>
                {this.validateErrorMessage("email")}
            </div>
        );
    };

    getRoleOptions = () => {
        const { roles } = this.props;

        return map(roles, role => ({
            label: role.name,
            value: role.id
        }));
    };

    buildTypeAdminField = () => {
        const { role } = this.state;
        return (
            <div className="form-group create-admin-modal__container">
                <label
                    className="create-admin-modal__form-label--role"
                    htmlFor="roleLabel"
                >
                    Role
                    <AutoCompleteInput
                        placeholder="SELECT AN OPTION"
                        onChange={this.onChangeRole}
                        getOptions={this.getRoleOptions}
                        initializeGettingOptions
                        value={this.state.role}
                        components={{ DropdownIndicator }}
                        onBlur={() =>
                            this.setErrorMessageFieldRequired(
                                "role",
                                !isEmpty(role),
                                "Role"
                            )
                        }
                        isMulti
                    />
                </label>
                {this.validateErrorMessage("role")}
            </div>
        );
    };

    buildPasswordField = () => {
        const { password, password2 } = this.state;
        return (
            <div className="create-admin-modal__container field">
                <div className="create-admin-modal col-xs-6">
                    <label
                        className="create-admin-modal__form-label--password"
                        htmlFor="passwordLabel"
                    >
                        Password
                        <input
                            id="password"
                            type="password"
                            className="form-control create-admin-modal__form-field--password"
                            value={password}
                            onChange={this.onChangePassword}
                            onBlur={() => this.validatePassword()}
                        />
                    </label>
                    {this.validateErrorMessage("password")}
                </div>
                <div className="create-admin-modal col-xs-6 mobile-top">
                    <label
                        className="create-admin-modal__form-label--password"
                        htmlFor="password2Label"
                    >
                        Confirm Password
                        <input
                            id="password2"
                            type="password"
                            className="form-control create-admin-modal__form-field--password"
                            value={password2}
                            onChange={this.onChangePassword2}
                            onBlur={() => this.validateConfirmPassword()}
                        />
                    </label>
                    {this.validateErrorMessage("password2")}
                </div>
                <div className="create-admin-modal__tally">
                    <PasswordStrengthInfoTooltip />
                </div>
            </div>
        );
    };

    buildForm = () => (
        <div>
            <div className="row">
                <div className="create-admin-modal col-xs-6">
                    {this.buildFirstNameField()}
                </div>
                <div className=" create-admin-modal col-xs-6">
                    {this.buildLastNameField()}
                </div>
                <div className="create-admin-modal col-xs-6">
                    {this.buildEmailField()}
                </div>
                <div className="create-admin-modal col-xs-6">
                    {this.buildTypeAdminField()}
                </div>
            </div>
            <div className="row">{this.buildPasswordField()}</div>
        </div>
    );

    haveEmptyFields = () => {
        const {
            firstName,
            lastName,
            email,
            role,
            password,
            password2
        } = this.state;

        return !(
            firstName &&
            lastName &&
            email &&
            role &&
            password &&
            password2
        );
    };

    haveErrors = () => {
        const {
            firstNameError,
            lastNameError,
            emailError,
            roleError,
            passwordError,
            password2Error
        } = this.state;

        return (
            firstNameError ||
            lastNameError ||
            emailError ||
            roleError ||
            passwordError ||
            password2Error
        );
    };

    isDisableSaveButton = () => {
        const { password, password2 } = this.state;
        const { isSavingAdmin, isSavedAdmin } = this.props;

        return (
            this.haveEmptyFields() ||
            this.haveErrors() ||
            !isEqual(password, password2) ||
            isSavingAdmin ||
            isSavedAdmin
        );
    };

    buildSuccessMessage = () => (
        <div className="create-admin-modal__success-message">
            The Admin user has successfully been created but is not active yet.
            <br />
            Please email Ashish the email address of this user so he can
            activate them.
        </div>
    );

    buildErrorMessageCreateAdmin = () => (
        <div className="create-admin-modal__error-message">
            There was an error creating the admin user, please try again in a
            few minutes
        </div>
    );

    onSave = () => {
        const { onSave } = this.props;
        const { firstName, lastName, email, role, password } = this.state;

        if (isFunction(onSave)) {
            const data = {
                user: {
                    firstName,
                    lastName,
                    email,
                    password
                },
                roles: map(role, "label")
            };
            this.props.onSave(data);
        }
    };

    render = () => {
        const {
            isSavedAdmin,
            isSavingAdmin,
            onCancel,
            onClose,
            isVisible,
            errorCreateNewAdmin
        } = this.props;
        const textSaveButton = isSavedAdmin ? "Saved Admin" : "Save";
        const successMessage =
            isSavedAdmin && !isSavingAdmin ? this.buildSuccessMessage() : null;
        const errorMessage =
            !isSavedAdmin && !isSavingAdmin && errorCreateNewAdmin
                ? this.buildErrorMessageCreateAdmin()
                : null;

        return (
            <Modal
                animation
                autoFocus
                backdrop
                enforceFocus
                show={isVisible}
                className="create-admin-modal"
            >
                <ModalHeader>
                    <CloseModalButton onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <h1 className="create-admin-modal__title">Complete:</h1>
                    {this.buildForm()}
                    <div className="create-admin-modal__footer">
                        <Button
                            onClick={this.onSave}
                            disabled={this.isDisableSaveButton()}
                            isLoading={isSavingAdmin}
                            loadingText="Saving..."
                        >
                            {textSaveButton}
                        </Button>
                        <Button inverted onClick={onCancel}>
                            Cancel
                        </Button>
                    </div>
                    {successMessage}
                    {errorMessage}
                </ModalBody>
            </Modal>
        );
    };
}
