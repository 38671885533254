import React from 'react';
import get from 'lodash/get'
import { Redirect } from 'react-router-dom';
import { BasePolicy } from './PolicyContext';

export class DefaultPolicy extends BasePolicy {
    static validate = profile => {
        const isBuyer = get(profile, 'publicProfile.userInfo.buyer');
        const isDisputing = get(profile, 'disputing');

        return (isBuyer && isDisputing)
            ? <Redirect to={{ pathname: '/disputed' }}/>
            : null;
    }
}
