import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function SliderRail({ getRailProps }) {
    return (
        <Fragment>
            <div className="range-slider-field-outer-style" {...getRailProps()} />
            <div className="range-slider-field-inner-style" />
        </Fragment>
    );
}

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired
};

export default SliderRail;
