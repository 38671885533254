import axios from "axios";
import map from "lodash/map";
import get from "lodash/get";
import APIDomain from "../shared/apiSettings";
import { getBaseClient, getHeaders } from "./client";
import { downloadDocument } from "./util";

axios.defaults.baseURL = APIDomain;
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-XSRF-Token";
axios.defaults.withCredentials = true;

const client = getBaseClient();

export const autocomplete = (uri, term) =>
    axios({
        method: "get",
        url: `${APIDomain}${uri}`,
        headers: getHeaders(),
        withCredentials: true,
        params: { term }
    }).then(response => response.data);

export const getFinicityInstitutionList = term =>
    axios({
        method: "get",
        url: `${APIDomain}/autocomplete/finicity-institution?limit=100`,
        headers: getHeaders(),
        withCredentials: true,
        params: { term }
    });

export const brokerageAutocomplete = (state, term) =>
    axios({
        method: "get",
        url: "/autocomplete/brokerage",
        headers: getHeaders(),
        withCredentials: true,
        params: { state, term }
    });

export const loginUser = data => client.post("/login", data);

export const logoutUser = () => client.post("/logout");

export const requestResetPassword = data =>
    axios({
        method: "post",
        url: "/reset-password/send-email",
        headers: getHeaders(),
        withCredentials: true,
        data
    })
        .then(response => response.data)
        .catch(error => Promise.reject(error));

export const createNewPassword = data =>
    axios({
        method: "post",
        url: "/reset-password/save-new-password",
        headers: getHeaders(),
        withCredentials: true,
        data
    })
        .then(response => response.data)
        .catch(error => Promise.reject(error));

export const loadUserProfile = () =>
    client.get("/api/user/profile").then(response => response.data);

export const sendVerification = ({ phone }) =>
    client.post(
        `/onboarding/phone/verification/send?phone=${encodeURIComponent(phone)}`
    );

export const checkVerification = ({ phone, code }) =>
    client.post(
        `/onboarding/phone/verification/check?phone=${encodeURIComponent(
            phone
        )}&code=${encodeURIComponent(code)}`
    );

export const signupBuyer = data =>
    axios({
        method: "post",
        url: `/onboarding/buyer`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const signupSeller = data =>
    axios({
        method: "post",
        url: `/onboarding/seller`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const signupBroker = data =>
    axios({
        method: "post",
        url: `/onboarding/broker`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const contactRebate = data =>
    axios({
        method: "post",
        url: `/onboarding/buyer/increase-rebate`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const signupUser = token =>
    axios({
        method: "get",
        url: "/onboarding/confirm-email",
        headers: getHeaders(),
        withCredentials: true,
        params: { token: token }
    }).then(response => response.data);

export const userExists = data =>
    client.get("/onboarding/user/exists", { params: data });

export const updateProspect = data => client.post("/api/prospect", data);

export const updateBuyer = data => client.post("/api/buyer", data);

export const finishBuyerFlow = () =>
    client.post("/api/buyer/finish").then(response => response.data);

export const getRealtorStats = () =>
    client.get("/api/brewcrew/realtor/stats/realtors/count-by-state");

export const countRealtorsByCityInState = state =>
    client.get("/api/brewcrew/realtor/stats/realtors/count-by-city", {
        params: { state }
    });

export const getRealtorListByCity = (state, city) =>
    client.get(`/api/brewcrew/realtor/list/realtors/${state}/${city}`);

export const getBudgetOptions = data =>
    axios({
        method: "post",
        url: `/onboarding/buyer/form-data`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const getPropertyInfo = data =>
    axios({
        method: "post",
        url: `/onboarding/seller/property-info`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const getSearchAgents = data =>
    axios({
        method: "post",
        url: `/onboarding/buyer/browse-agents`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const getSearchSellerAgents = data =>
    axios({
        method: "post",
        url: `/onboarding/seller/browse-agents`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const getCurrentHomeValue = () =>
    client.get("/api/buyer/current-address").then(response => response.data);

export const getProperty = params =>
    client.get("/property/_3p", { params }).then(response => response.data);

export const updateProfilePicture = base64Picture =>
    axios({
        method: "post",
        url: "/api/user/profile/upload-pic",
        headers: getHeaders(),
        withCredentials: true,
        data: { picture: base64Picture }
    });

export const updateRealtorProfile = props =>
    axios({
        method: "post",
        url: "/api/realtor",
        headers: getHeaders(),
        withCredentials: true,
        data: props
    });

export const createRealtorProfile = props =>
    axios({
        method: "post",
        url: "/api/realtor",
        headers: getHeaders(),
        withCredentials: true,
        data: props
    });

export const downloadRealtorDoc = (fileNameWithExtension, documentUrl) =>
    axios({
        method: "get",
        url: `/${documentUrl}`,
        headers: getHeaders(),
        withCredentials: true,
        responseType: "blob"
    }).then(response => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileNameWithExtension;
        link.click();
    });

export const chooseAgent = realtorId =>
    axios({
        method: "post",
        url: `/api/buyer/realtor-match/${realtorId}`,
        headers: getHeaders(),
        withCredentials: true
    });

export const chooseSellerAgent = realtorId =>
    axios({
        method: "post",
        url: `/api/seller/realtor-match/${realtorId}`,
        headers: getHeaders(),
        withCredentials: true
    });

export const findAgents = () =>
    axios({
        method: "post",
        url: "/api/buyer/realtor-matches",
        headers: getHeaders(),
        withCredentials: true
    });

export const getVoaUrl = () =>
    axios({
        method: "post",
        url: `${APIDomain}/api/buyer/voa-start`,
        headers: getHeaders(),
        withCredentials: true
    })
        .then(response => response.data)
        .catch(({ error }) => error);

export const getVoaProspectUrl = () =>
    axios({
        method: "post",
        url: "/api/prospect/earnest-money-deposit/funding-sources",
        headers: getHeaders(),
        withCredentials: true
    })
        .then(response => response.data)
        .catch(({ error }) => error);

export const getFundingSources = consumerId =>
    axios({
        method: "get",
        url: `/api/prospect/earnest-money-deposit/funding-sources/${consumerId}`,
        headers: getHeaders(),
        withCredentials: true
    })
        .then(response => response.data)
        .catch(({ error }) => error);

export const prospectSetFundingSourcesV2 = (publicToken, payload) =>
    client.post(
        `/api/prospect/earnest-money-deposit/funding-sources/v2/${publicToken}`,
        payload
    );

export const checkVoaStatus = () =>
    axios({
        method: "post",
        url: `${APIDomain}/api/buyer/voa-complete`,
        headers: getHeaders(),
        withCredentials: true
    })
        .then(response => response.data)
        .catch(({ error }) => error);

export const generateVoiReport = () =>
    axios({
        method: "post",
        url: `${APIDomain}/api/buyer/voi-generate-report`,
        headers: getHeaders(),
        withCredentials: true
    })
        .then(response => response.data)
        .catch(({ error }) => error);

export const loadRealtor = id =>
    axios({
        method: "get",
        url: `/api/buyer/realtor/${id}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const getProspectUploads = prospectId =>
    axios({
        method: "get",
        url: `${APIDomain}/api/brewcrew/prospect/documents?prospectId=${prospectId}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const loadBuyer = id =>
    axios({
        method: "get",
        url: `/api/realtor/buyer/${id}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const acceptMatch = (id, data) =>
    axios({
        method: "get",
        url: `/api/realtor/accept-match/buyer/${id}/docusign`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const declineMatch = (id, data) =>
    axios({
        method: "post",
        url: `/api/realtor/decline-match/buyer/${id}`,
        headers: getHeaders(),
        withCredentials: true,
        data
    });

export const getConfig = () => client.get("/config");

export const buyerDispute = selectedItems =>
    client
        .post("/api/buyer/dispute", selectedItems)
        .then(response => response.data);

export const updateBuyerInformation = (params, data) =>
    axios({
        method: "post",
        url: `/api/brewcrew/realtor/update-match/buyer`,
        headers: getHeaders(),
        params,
        withCredentials: true,
        data
    }).then(response => response.data);

export const uploadAttachment = (realtorMatchId, file, fileType) => {
    const form = new FormData();

    form.append("documentType", fileType);
    form.append("file", file);

    return axios({
        method: "post",
        url: `/api/document/upload/${realtorMatchId}`,
        headers: {
            "Content-Type": "multipart/form-data"
        },
        withCredentials: true,
        data: form
    }).then(response => response.data);
};

export const removeRealtorMatchAttachment = (realtorMatchId, fileId) =>
    axios({
        method: "delete",
        url: `/api/document/remove/${realtorMatchId}/${fileId}`,
        headers: getHeaders(),
        withCredentials: true
    });

const getNameLastName = item =>
    `${get(item, "firstName", "")} ${get(item, "lastName", "")}`;

const adaptAutoCompleteResponse = response =>
    map(response, item => ({ label: getNameLastName(item), value: item }));

export const autocompleteContract = (term, contactType) =>
    axios({
        method: "get",
        url: "/autocomplete/contact",
        headers: getHeaders(),
        withCredentials: true,
        params: { term, contactType }
    }).then(response => adaptAutoCompleteResponse(response.data));

export const getDocument = (id, fileNameWithExtension) =>
    axios({
        method: "get",
        url: `/api/document/download/${id}`,
        headers: getHeaders(),
        withCredentials: true,
        responseType: "blob"
    }).then(response => {
        downloadDocument(response, fileNameWithExtension);
    });

export const getLocationInformationService = () => {
    let service;
    if (window.google) {
        service = new window.google.maps.places.PlacesService(
            document.createElement("div")
        );
    }
    return service;
};

export const getActiveMatches = (id, data) =>
    axios({
        method: "get",
        url: "/api/realtor/active-matches",
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const getUsersRole = (
    role,
    { term, page, size, sortBy, sortOrder, approved, state, startDate, endDate }
) => {
    return axios({
        method: "get",
        url: `/api/brewcrew/${role}/users`,
        headers: getHeaders(),
        withCredentials: true,
        params: {
            term,
            page,
            size,
            sortBy,
            sortOrder,
            approved,
            state,
            startDate,
            endDate
        }
    }).then(response => response.data);
};

export const getUsersAdmin = (
    filterBy,
    { term, page, size, sortBy, sortOrder, approved, state, startDate, endDate }
) => {
    return axios({
        method: "get",
        url: `/api/brewcrew/users`,
        headers: getHeaders(),
        withCredentials: true,
        params: {
            filterBy,
            term,
            page,
            size,
            sortBy,
            sortOrder,
            approved,
            state,
            startDate,
            endDate
        }
    }).then(response => response.data);
};

export const createNewAdmin = data =>
    axios({
        method: "post",
        url: "/api/brewcrew/admin",
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const loginImpersonateUser = id =>
    axios({
        method: "get",
        url: "/api/brewcrew/impersonate/in",
        headers: getHeaders(),
        withCredentials: true,
        params: {
            id
        }
    }).then(response => response.data);

export const loginImpersonateUserWithRole = (id, role) =>
    axios({
        method: "get",
        url: `/api/brewcrew/${role}/impersonate/in`,
        headers: getHeaders(),
        withCredentials: true,
        params: {
            id
        }
    }).then(response => response.data);

export const logoutImpersonateUser = () =>
    axios({
        method: "get",
        url: "/api/impersonate/out",
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const createNewPrizeEvent = data =>
    axios({
        method: "post",
        url: "/api/admin/prize-event",
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const getBrewWheelActiveList = userEmail =>
    axios({
        method: "get",
        url: "/api/brew-wheel/prize-events",
        headers: getHeaders(),
        withCredentials: true,
        params: {
            userEmail
        }
    }).then(response => response.data);

export const getBrewWheelSpecificEvent = id =>
    axios({
        method: "get",
        url: `/api/brew-wheel/prize-event/${id}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const spinWheel = (id, userEmail) =>
    axios({
        method: "post",
        url: `/api/brew-wheel/spin/${id}`,
        headers: getHeaders(),
        withCredentials: true,
        params: {
            userEmail
        }
    }).then(response => response.data);

export const realtorContactBuyer = (params, message) =>
    axios({
        method: "post",
        url: `/api/brewcrew/realtor/contact-match/buyer`,
        params,
        headers: getHeaders(),
        withCredentials: true,
        data: message
    }).then(response => response.data);

export const resendRealtorAgreement = realtorId =>
    axios({
        method: "post",
        url: "/api/brewcrew/realtor/docusign/realtor/resend",
        headers: getHeaders(),
        withCredentials: true,
        params: { realtorId }
    }).then(response => response.data);

export const resendBrokerAgreement = realtorId =>
    axios({
        method: "post",
        url: "/api/brewcrew/realtor/docusign/broker/resend",
        headers: getHeaders(),
        withCredentials: true,
        params: { realtorId }
    }).then(response => response.data);

export const updateRealtorsName = data =>
    axios({
        method: "post",
        url: "/api/user",
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const suspendUser = (realtorId, toggle) =>
    axios({
        method: "post",
        url: `/api/brewcrew/realtor/${realtorId}/suspend`,
        headers: getHeaders(),
        withCredentials: true,
        params: { toggle }
    }).then(response => response.data);

export const newProspectDeposit = data =>
    axios({
        method: "post",
        url: "/api/prospect/earnest-money-deposit/new",
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const updateProspectDeposit = (data, earnestMoneyDepositId) =>
    axios({
        method: "post",
        url: `/api/prospect/earnest-money-deposit/${earnestMoneyDepositId}`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const addAchsDeposit = (data, earnestMoneyDepositId) =>
    axios({
        method: "post",
        url: `/api/prospect/earnest-money-deposit/${earnestMoneyDepositId}/add-achs`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const earnestMoneyDepositFinish = earnestMoneyDepositId =>
    axios({
        method: "post",
        url: `/api/prospect/earnest-money-deposit/${earnestMoneyDepositId}/finish`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const editProspectInfo = data =>
    axios({
        method: "post",
        url: `/api/prospect/edit`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const sendEmails = (earnestMoneyDepositId, data) =>
    axios({
        method: "post",
        url: `/api/prospect/earnest-money-deposit/${earnestMoneyDepositId}/receipt`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const completeTransferReceipt = (earnestMoneyDepositId, prospectId) =>
    axios({
        method: "get",
        url: `/api/brewcrew/prospect/earnest-money-deposit/${earnestMoneyDepositId}/receipt`,
        headers: getHeaders(),
        withCredentials: true,
        params: { prospectId }
    }).then(response => response.data);

export const sendWireInstructions = data =>
    axios({
        method: "post",
        url: `/onboarding/wiring-instruction`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const sendCertifiedInfo = data =>
    axios({
        method: "post",
        url: `/onboarding/prospect/certified-check-info`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const earnestMoneyDepositCancel = earnestMoneyDepositId =>
    axios({
        method: "post",
        url: `/api/prospect/earnest-money-deposit/${earnestMoneyDepositId}/cancel`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const earnestMoneyDepositUploadVerification = (documentType, file) => {
    const form = new FormData();

    form.append("documentType", documentType);
    form.append("file", file);

    return axios({
        method: "post",
        url: "/api/prospect/upload-document",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        withCredentials: true,
        data: form
    }).then(response => response.data);
};

export const brewBoardUpdateBrokerage = brokerage => {
    return axios({
        method: "post",
        url: "/api/brewcrew/realtor/brokerage",
        headers: getHeaders(),
        withCredentials: true,
        data: brokerage
    }).then(response => response.data);
};

export const getRoles = () =>
    axios({
        method: "get",
        url: "/api/brewcrew/roles",
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const getBrewCrewUsers = () =>
    axios({
        method: "get",
        url: "/api/brewcrew/brewcrew-users",
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const getLicenses = () => {
    return axios({
        method: "get",
        url: "/licenses",
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);
};

export const createNewLicense = license => {
    return axios({
        method: "post",
        url: "/api/brewcrew/license",
        headers: getHeaders(),
        withCredentials: true,
        data: license
    }).then(response => response.data);
};

export const getStats = data => {
    return axios({
        method: "get",
        url: "/api/brewcrew/realtor/agent-stats",
        headers: getHeaders(),
        withCredentials: true,
        params: data
    }).then(response => response.data);
};

export const getBrokerage = brokerageId => {
    return axios({
        method: "get",
        url: `/api/broker/brokerage/${brokerageId}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);
};

export const completeRealtorTour = () =>
    axios({
        method: "post",
        url: "/api/realtor/completed-onboarding",
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const getBrewScore = data =>
    axios({
        method: "post",
        url: "/onboarding/buyer/brew-score",
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const getPropertyProfile = data => {
    return axios({
        method: "post",
        url: "/_3p/property/report",
        headers: getHeaders(),
        data
    }).then(response => response.data);
};

export const loadSeller = id =>
    axios({
        method: "get",
        url: `/api/realtor/seller/${id}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const acceptSellerMatch = (id, data) =>
    axios({
        method: "get",
        url: `/api/realtor/accept-match/seller/${id}/docusign`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const declineSellerMatch = (id, data) =>
    axios({
        method: "post",
        url: `/api/realtor/decline-match/seller/${id}`,
        headers: getHeaders(),
        withCredentials: true,
        data
    });

export const updateSellerInformation = (id, data) =>
    axios({
        method: "post",
        url: `/api/realtor/update-match/seller/${id}`,
        headers: getHeaders(),
        withCredentials: true,
        data
    }).then(response => response.data);

export const realtorContactSeller = (sellerId, message) =>
    axios({
        method: "post",
        url: `/api/realtor/contact-match/seller/${sellerId}`,
        headers: getHeaders(),
        withCredentials: true,
        data: message
    }).then(response => response.data);

export const finishSellerFlow = () =>
    client.post("/api/seller/finish").then(response => response.data);

export const getSellerCurrentHomeValue = () =>
    client.get("/api/seller/current-address").then(response => response.data);

export const sellerChooseAgent = realtorId =>
    axios({
        method: "post",
        url: `/api/seller/realtor-match/${realtorId}`,
        headers: getHeaders(),
        withCredentials: true
    });

export const findSellerAgents = () =>
    axios({
        method: "post",
        url: "/api/seller/realtor-matches",
        headers: getHeaders(),
        withCredentials: true
    });

export const getSellerVoaUrl = () =>
    axios({
        method: "post",
        url: `${APIDomain}/api/seller/voa-start`,
        headers: getHeaders(),
        withCredentials: true
    })
        .then(response => response.data)
        .catch(({ error }) => error);

export const checkSellerVoaStatus = () =>
    axios({
        method: "post",
        url: `${APIDomain}/api/seller/voa-complete`,
        headers: getHeaders(),
        withCredentials: true
    })
        .then(response => response.data)
        .catch(({ error }) => error);

export const generateSellerVoiReport = () =>
    axios({
        method: "post",
        url: `${APIDomain}/api/seller/voi-generate-report`,
        headers: getHeaders(),
        withCredentials: true
    })
        .then(response => response.data)
        .catch(({ error }) => error);

export const loadSellerRealtor = id =>
    axios({
        method: "get",
        url: `/api/seller/realtor/${id}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const sellerDispute = selectedItems =>
    client
        .post("/api/seller/dispute", selectedItems)
        .then(response => response.data);

export const getActiveSellerMatches = () =>
    axios({
        method: "get",
        url: "/api/realtor/seller/active-matches",
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);

export const getBuyerStats = params =>
    client
        .get("/api/brewcrew/buyer/buyer-stats", { params })
        .then(response => response.data);

export const getBrewCrewBuyer = buyerId =>
    axios({
        method: "get",
        url: `/api/brewcrew/buyer?buyerId=${buyerId}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);
export const selectBrewCrewBuyerMatch = (buyerId, realtorId) =>
    axios({
        method: "post",
        url: `/api/brewcrew/buyer/agent-match?buyerId=${buyerId}&realtorId=${realtorId}`,
        headers: getHeaders(),
        withCredentials: true
    }).then(response => response.data);
