import get from 'lodash/get'
import { FETCHING_PROPERTY_PROFILE, GET_PROPERTY_PROFILE_FAIL, GET_PROPERTY_PROFILE_SUCCESS } from './PropertyProfileActionTypes';
import * as APIUtil from '../../util/service_api';

const fetchingPropertyProfile = () => ({ type: FETCHING_PROPERTY_PROFILE });

export const getPropertyProfileSuccess = payload => ({
    type: GET_PROPERTY_PROFILE_SUCCESS,
    payload,
});

export const getPropertyProfileFailed = payload => ({
    type: GET_PROPERTY_PROFILE_FAIL,
    payload: payload,
});

export const getPropertyProfile = (address) => (dispatch) => {
    dispatch(fetchingPropertyProfile());
    return APIUtil.getPropertyProfile(address)
        .then(response => dispatch(getPropertyProfileSuccess(get(response, 'Reports[0]'))))
        .catch(error => dispatch(getPropertyProfileFailed(get(error, 'response.data'))))
};
