import get from "lodash/get";
import React, { Fragment } from "react";
import { Field } from "formik";
import {
    firstTimeBuyerLivingSituation,
    livingSituation
} from "../../../../shared/constants";
import { RadioField } from "../../../Form";
import BaseWizardStep from "../BaseWizardStep";
import mixpanel from "mixpanel-browser";

class StepLivingSituation extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        const buyerCurrentLivingSituation = get(
            values,
            "buyerCurrentLivingSituation"
        );

        mixpanel.track("Completed What Is Your Current Living Situation?", {
            currentLivingSituation: buyerCurrentLivingSituation
        });
    };

    stepCurrentLivingSituationNext = () => {
        setTimeout(
            () => this.props.onNavigateStep(1, true, this.mixpanelFunction),
            0
        );
    };

    render() {
        const {
            values: { buyerIsFirstTimeHomeBuyer }
        } = this.props;

        const isStepFulfilled = this.areFieldsValid([
            "buyerCurrentLivingSituation"
        ]);
        const classNameWidth =
            buyerIsFirstTimeHomeBuyer === "yes" ? "" : "is-widest";

        return (
            <Fragment>
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        What is your current living situation?
                    </div>
                    <Fragment>
                        <div
                            className={`buyer__signup__fieldset ${classNameWidth}`}
                        >
                            <div className="buyer__signup__field__row">
                                <div className="buyer__signup__field">
                                    <Field
                                        name="buyerCurrentLivingSituation"
                                        className="is-horizontal with-image"
                                        itemClassName="wider with-image"
                                        options={
                                            buyerIsFirstTimeHomeBuyer === "yes"
                                                ? firstTimeBuyerLivingSituation
                                                : livingSituation
                                        }
                                        component={RadioField}
                                    />
                                    {/* { this.renderFieldError('buyerCurrentLivingSituation') } */}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    ;
                </div>

                {this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    handleNext: this.stepCurrentLivingSituationNext
                })}
            </Fragment>
        );
    }
}

export default StepLivingSituation;
