import React, { Fragment } from "react";
import { Field } from "formik";
import get from "lodash/get";
import { homeTypes } from "../../../../shared/constants";
import { RadioField } from "../../../Form";
import BaseWizardStep from "../BaseWizardStep";
import { rangeOptionsWithSuffix } from "../Shared";
import mixpanel from "mixpanel-browser";

class StepPropertyType extends BaseWizardStep {
    static skip(props) {
        const { hasKnownLocation } = props.values;
        return hasKnownLocation === "yes";
    }

    mixpanelFunction = () => {
        const { values } = this.props;
        const bedrooms = get(values, "buyerNumberOfBedrooms");
        const bathrooms = get(values, "buyerNumberOfBathrooms");
        mixpanel.track("Completed What Type Of Property Are You Looking For", {
            homeType: get(values, "buyerHomeType"),
            numberOfBedrooms: bedrooms ? bedrooms : 0,
            numberOfBathrooms: bathrooms ? bathrooms : 0
        });
    };

    buildPreferences = () => {
        const {
            values: { buyerHomeType }
        } = this.props;

        if (!buyerHomeType) return;

        const includeChooseOption = true;

        return (
            <>
                <div className="buyer__signup__fieldset">
                    <div className="buyer__signup__field__row">
                        <div className="buyer__signup__field">
                            <div className="buyer__signup__field__question margin-label white-font bold">
                                How Many Beds?
                            </div>
                            <Field
                                component="select"
                                name="buyerNumberOfBedrooms"
                                className="buyer__signup__preferences-field"
                            >
                                {rangeOptionsWithSuffix(
                                    1,
                                    5,
                                    "+",
                                    includeChooseOption
                                )}
                            </Field>
                            {this.renderFieldError("buyerNumberOfBedrooms")}
                        </div>

                        <div className="buyer__signup__field">
                            <div className="buyer__signup__field__question margin-label white-font bold">
                                How Many Baths?
                            </div>
                            <Field
                                component="select"
                                name="buyerNumberOfBathrooms"
                                className="buyer__signup__preferences-field"
                            >
                                {rangeOptionsWithSuffix(
                                    1,
                                    5,
                                    "+",
                                    includeChooseOption
                                )}
                            </Field>

                            {this.renderFieldError("buyerNumberOfBathrooms")}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    render() {
        const isStepFulfilled = this.areFieldsValid(["buyerHomeType"]);

        return (
            <Fragment>
                <div className="buyer__signup__step">
                    <div className="buyer__signup__question margin--top white-font">
                        What type of property are you looking for?
                    </div>

                    <div className="buyer__signup__fieldset is-widest">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field align-center">
                                <Field
                                    className="is-horizontal with-image"
                                    itemClassName="wider with-image"
                                    name="buyerHomeType"
                                    options={homeTypes}
                                    component={RadioField}
                                />
                                {/* { this.renderFieldError('buyerHomeType') } */}
                            </div>
                        </div>
                    </div>

                    {this.buildPreferences()}
                </div>
                {this.renderStepButtons(
                    {
                        canProceed: isStepFulfilled,
                        hasMixPanelTracking: true
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepPropertyType;
