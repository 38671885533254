import map from 'lodash/map';
import React, { Fragment } from 'react'
import {Field} from 'formik';
import get from 'lodash/get';
import BaseWizardStep from '../BaseWizardStep';
import {FicoCategoryRadioField} from '../../../Form';
import mixpanel from 'mixpanel-browser';
import ScrollToTopOnMount from '../../../ScrollTopOnMount/ScrollTopOnMount';

class StepCreditCategory extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        
        mixpanel.track(
            "Completed Estimate Your Credit Score", {
                "ficoScoreCategory": get(values, 'ficoScoreCategory.name'),
            }
        )
    };

    radioFieldOptions = () => {
        const { ficoScoreCategories } = this.props;
        return map(ficoScoreCategories, category => ({
            value: category,
            label: category.name + '\n' + category.displayName
        }))
    };

    render() {
        const { ficoScoreCategory } = this.props.values;
        const isStepFulfilled = this.areFieldsValid(['ficoScoreCategory']) && ficoScoreCategory;

        return (
            <Fragment>
            <ScrollToTopOnMount />
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        Estimate Your Credit Score
                    </div>

                    <div className="buyer__signup__fieldset is-full-width">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field">
                                <Field
                                    name="ficoScoreCategory"
                                    className="is-horizontal"
                                    options={ this.radioFieldOptions() }
                                    component={ FicoCategoryRadioField }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                { this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    hasMixPanelTracking: true,
                    buttonName: 'VIEW MY SUMMARY',
                }, this.mixpanelFunction)}
            </Fragment>
        );
    }
}

export default StepCreditCategory;
