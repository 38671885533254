import filter from 'lodash/filter';
import React from 'react';
import invoke from 'lodash/invoke';
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';

import './LocationAutocompleteField.css';

export default ({ field, form, options = [], ...props }) => {
    const {
        placeholder = 'CITY, STATE',
    } = props;

    const handleChange = value => {
        form.setFieldValue(field.name, value);
        invoke(props, 'onInput', value);
    };

    const handleSelect = value => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name);

        if (typeof props.onSelect === 'function') {
            geocodeByAddress(value).then(
                result => props.onSelect(result)
            );
        }
    };

    const handleError = () => {
        form.setFieldValue(field.name, '');
        form.setFieldTouched(field.name);
    };

    const filteredSuggestions = suggestions => filter(suggestions, s =>
        s.types && s.types[0] && ['locality', 'neighborhood'].includes(s.types[0])
    );

    const renderCitySuggestions = (suggestions, getSuggestionItemProps) =>
        filteredSuggestions(suggestions).map(suggestion =>
            <div
                {...getSuggestionItemProps(suggestion, {
                    className: `location__autocomplete__field__option ${ suggestion.active ? 'is-active' : '' }`
                })}
            >
                <span>{ suggestion.description}</span>
            </div>
        );

    return (
        <div className={`custom__input ${props.className || ''}`}>
            <PlacesAutocomplete
                value={ field.value }
                onChange={ handleChange }
                onSelect={ handleSelect }
                onError={ handleError }
                searchOptions ={{
                    componentRestrictions: { country: ['us'] },
                }}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div style={{ position: 'relative' }}>
                        <input
                            {...getInputProps({
                                className: 'location-search-input',
                                placeholder,
                            })}
                            onBlur={() => {
                                const [{ description = '' } = {}] = filteredSuggestions(suggestions);
                                if (description) handleSelect(description);

                                const { onBlur } = getInputProps();
                                if (onBlur) onBlur();
                            }}
                        />

                        { loading && <div className="input-hint">
                            <i style={{ 'textAlign': 'center', 'padding': 0 }} className="fas fa-spinner fa-spin"/>
                        </div> }

                        <div className={`${ suggestions.length ? 'location__autocomplete__field' : ''}`}>
                            { renderCitySuggestions(suggestions, getSuggestionItemProps) }
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    )
};
