import React from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

import './googlemaps.css';

const Map = withScriptjs(withGoogleMap((props) => <GoogleMap {...props}>{props.children}</GoogleMap>));

export const GoogleMaps = (props) => {
    return (
        <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=places`}
            loadingElement={<div className="google-maps"/>}
            containerElement={<div className="google-maps"/>}
            mapElement={<div className="google-maps"/>}
            defaultZoom={props.zoom}
            defaultCenter={props.center}
        >
            {props.children}
        </Map>
    );
};

GoogleMaps.propTypes = {
    height: PropTypes.string.isRequired,
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    zoom: PropTypes.number.isRequired
};
