import isObject from 'lodash/isObject';
import every from 'lodash/every';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import isBoolean from 'lodash/isBoolean';
import { getIn } from 'formik';
import '../../signupform.css';

class BaseWizardStep extends Component {
    static propTypes = {
        errors: PropTypes.object,
        values: PropTypes.object,
        touched: PropTypes.object,
        onTrackProgress: PropTypes.func,
        renderNavigation: PropTypes.func,
    };

    static defaultProps = {
        onTrackProgress: () => {}
    };

    componentDidMount() {
        this._oldValues = cloneDeep(this.props.values);
    }

    componentWillUnmount() {
        const newValues = cloneDeep(this.props.values);
        this.afterStep(this._oldValues, newValues);
    }

    buildFieldError = (fieldName) => {
        const message = this.getFieldError(fieldName);
        if (!message) return null;

        return (
            <div className="buyer__signup__field__error--content">
                <div className="buyer__signup__field__error">{message}</div>
            </div>
        );
    };

    renderFormError = (fieldName) => {
        const message = this.getFieldError(fieldName);
        if (!message) return null;

        return (
            <div className="buyer__signup__form__error--content">
                <div className="buyer__signup__form__error">{message}</div>
            </div>
        );
    };

    getFieldError = (fieldName) => {
        const { errors } = this.props;

        if (!this.isFieldTouched(fieldName)) return;

        const message = getIn(errors, fieldName);
        if (typeof message === 'string') return message;
    };

    setFieldValues = (values, path = '', touch = false) => {
        const { setFieldValue, setFieldTouched } = this.props;
        // eslint-disable-next-line no-unused-vars
        for (const [name, value] of Object.entries(values)) {
            const key = path ? [path, name].join('.') : name;
            setFieldValue(key, value);
            if (touch) setFieldTouched(key);
        }
    };

    isFieldTouched(fieldName) {
        const { touched } = this.props;
        const fieldTouched = getIn(touched, fieldName);

        if (typeof fieldTouched === 'boolean') return fieldTouched;
        if (typeof fieldTouched === 'object') return every(fieldTouched);

        return false;
    }

    afterStep() {
        return Promise.resolve(this.props.onTrackProgress());
    }

    areFieldsValid(fieldNames = []) {
        const { errors, values } = this.props;
        return !fieldNames.some((fieldName) => {
            const fieldValue = getIn(values, fieldName);
            const emptyValue = isBoolean(fieldValue) ? !fieldValue
                : (isEmpty(toString(fieldValue)));
            if (emptyValue) return true;
            const error = getIn(errors, fieldName);
            if (Array.isArray(error) || isObject(error)) {
                return find(error, (value) => !!value);
            }
            return error !== undefined;
        });
    }

    renderStepButtons = (isStepFulfilled = true, mixpanel, button) => this.props.renderNavigation(isStepFulfilled, mixpanel, button)
}

export default BaseWizardStep;
