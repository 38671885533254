import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { connect } from 'react-redux';
import { onLogoutImpersonateUser } from '../../reducers/admin/AdminActions';
import RealtorAvatar from '../RealtorAvatar/RealtorAvatar';

import './index.scss';

class AgentsStartPageComponent extends React.PureComponent {
    static propTypes = {
        picture: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    };

    buildRealtorAvatarWrapper = () => (
        <RealtorAvatar
            picture={this.props.picture}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
        />
    );

    render = () => {
        return (
            <div className="realtor-starting-page">
                <div className="realtor-starting-page-img">
                    {this.buildRealtorAvatarWrapper()}
                </div>
                <div className="realtor-starting-page-h1">Hey, {this.props.firstName}</div>
                <div className="realtor-starting-page-p">What would you like to do today?</div>
                <div className="realtor-starting-page-cta">
                    <div className="realtor-starting-page-cta-item">
                        <div className="realtor-starting-page-cta-item-text">Our algorithm matches you with leads based on the information you provide. So complete your profile as much as possible.</div>
                        <Link className="button button--small" to="/realtor/profile/edit">Edit My Profile</Link>
                    </div>
                    <div className="realtor-starting-page-cta-item">
                        <div className="realtor-starting-page-cta-item-text">Don’t miss a deal! <br />
                        Stay active and follow your matches.</div>
                        <Link className="button button--small" to="/realtor/active-matches">View your active matches</Link>
                    </div>
                    <div className="realtor-starting-page-cta-item">
                        <div className="realtor-starting-page-cta-item-text">Review any new matches that have chosen you to help them find their dream home.</div>
                        <Link className="button button--small" to="/realtor/pending-matches">View your pending matches</Link>
                    </div>
                </div>
                <div className="realtor-starting-page-city--img">
                </div>
            </div>
        );
    };
};

const mapStateToProps = (state) => ({
    isAuth: state.user.authorization,
    isRealtor: get(state.user.profile, 'publicProfile.userInfo.realtor'),
    isAdmin: get(state.user.profile, 'publicProfile.userInfo.brewCrew'),
    picture: get(state.user.profile, 'publicProfile.userInfo.profilePicUrl'),
    firstName: trim(get(state.user.profile, 'publicProfile.userInfo.firstName')),
    lastName: trim(get(state.user.profile, 'publicProfile.userInfo.lastName')),
});

const AgentsStartPage = withRouter(
    connect(mapStateToProps, {
        logoutImpersonateUser: onLogoutImpersonateUser,
    })(AgentsStartPageComponent),
);

export default AgentsStartPage;
