import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import 'normalize.css';
import './index.css';
import './styles/fonts/index.css';
import ConfigureStore from './store/Store';
import { fetchConfig } from './reducers/config/ConfigActions';
import { initialLoadUserProfile } from './reducers/user/UserActions';

document.addEventListener('DOMContentLoaded', () => {
    const root = document.getElementById('root');
    let preloadedState = {};
    if (window.currentUser) {
        preloadedState = { session: { currentUser: window.currentUser } };
    }
    const store = ConfigureStore(preloadedState);

    store.dispatch(fetchConfig());
    store.dispatch(initialLoadUserProfile());

    ReactDOM.render(<App store={store} />, root);
    // registerServiceWorker();
    unregister();
});
