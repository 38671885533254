import React, { Component } from 'react';
import PropTypes from 'prop-types'; // ES6
import { Form, Field } from 'formik';

import './adminportal.css';

class AdminPortal extends Component {
    render() {
        const {
            errors,
            isSubmitting,
            touched,
        } = this.props;

        return (
            <Form className="admin__portal">
                <p className="admin__portalheader">Sign In to your LemonBrew Account</p>

                <div className="section personal">
                    <div className="licenseid">
                        <div className="title">License ID</div>
                        <div>
                            <i className="fas fa-id-badge fa-lg"></i>
                            <Field
                                type="licenseID"
                                name="licenseID"
                                placeholder="license ID"
                            />
                        </div>
                        {touched.licenseID && errors.licenseID && <div className="error">{errors.licenseID}</div>}
                    </div>

                    <div className="sparkhire__url">
                        <div className="title">Realtor SparkHire URL</div>
                        <div>
                            <i className="fas fa-link"></i>
                            <Field
                                type="SparkhireURL"
                                name="SparkhireURL"
                                placeholder="Sparkhire URL"
                            />
                        </div>
                        {touched.SparkhireURL && errors.SparkhireURL && <div className="error">{errors.SparkhireURL}</div>}
                    </div>
                </div>

                <button disabled={isSubmitting} type="submit" className="submit">
                    {isSubmitting ?
                        <div>
                            <i style={{ 'textAlign': 'center', 'padding': 0 }} className="fas fa-spinner fa-spin"></i>
                            <span style={{ 'paddingLeft': '8px' }}>Loading...</span>
                        </div>
                        :
                        <span>Approve Realtor</span>
                    }
                </button>
            </Form>
        )
    }
};

AdminPortal.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.shape({}),
    isSubmitting: PropTypes.bool,
}

export default AdminPortal;
