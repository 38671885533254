import React, { Fragment } from "react";
import get from "lodash/get";
import find from "lodash/find";
import BaseWizardStep from "./BaseWizardStep";
import mixpanel from "mixpanel-browser";
import EmdChooseModal from "../../../EmdChooseModal/EmdChooseModal";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";
import EmdWireModal from "../../../EmdWireModal/EmdWireModal";

class StepChooseMethod extends BaseWizardStep {
    constructor(props) {
        super(props);
        this.state = {
            isCertifiedModalVisible: false,
            isVisibleEmdWireModal: false
        };
    }

    onCloseCertifiedModal = () =>
        this.setState({ isCertifiedModalVisible: false });

    onOpenCertifiedModal = () => {
        mixpanel.track("Clicked On Certified Check / Bank Check");
        this.setState({ isCertifiedModalVisible: true });
    };

    onCloseEmdWireModal = () => this.setState({ isVisibleEmdWireModal: false });

    onOpenEmdWireModal = () => {
        mixpanel.track("Clicked On Wire");
        this.setState({ isVisibleEmdWireModal: true });
    };

    mixpanelFunction = () => {
        const { values } = this.props;
        mixpanel.track("Completed How Soon Are You Looking To Buy", {
            purchaseTimeline: get(values, "purchaseTimeline")
        });
    };

    buildCertifiedButton = () => (
        <div className="buyer__signup__choose-method">
            <button
                type="button"
                onClick={this.onOpenCertifiedModal}
                className=" buyer__signup__choose-method-button"
            >
                Certified Check / Bank Check
            </button>
        </div>
    );

    buildWireButton = () => (
        <div className="buyer__signup__choose-method">
            <button
                type="button"
                onClick={this.onOpenEmdWireModal}
                className=" buyer__signup__choose-method-button"
            >
                Wire
            </button>
        </div>
    );

    render() {
        const { isCertifiedModalVisible, isVisibleEmdWireModal } = this.state;
        const {
            onWireInstructions,
            onSendCertifiedInfo,
            successWireInstructions,
            successSendCertifiedInfo,
            isFetchingWireInstructions,
            isFetchingSendCertifiedInfo,
            transactionContactTypes
        } = this.props;
        const isStepFulfilled = true;
        const prospectCertifiedContactType = find(transactionContactTypes, {
            name: "prospect_certified_check_info"
        });

        return (
            <Fragment>
                <ScrollToTopOnMount />
                <EmdChooseModal
                    isVisible={isCertifiedModalVisible}
                    onClose={this.onCloseCertifiedModal}
                    onSend={onSendCertifiedInfo}
                    isSended={successSendCertifiedInfo}
                    isSending={isFetchingSendCertifiedInfo}
                    transactionContactType={prospectCertifiedContactType}
                />
                <EmdWireModal
                    isVisible={isVisibleEmdWireModal}
                    onClose={this.onCloseEmdWireModal}
                    onCancel={this.onCloseEmdWireModal}
                    onSend={onWireInstructions}
                    isSended={successWireInstructions}
                    isSending={isFetchingWireInstructions}
                    transactionContactTypes={transactionContactTypes}
                />
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        Choose Method of Payment
                    </div>

                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field align-center">
                                <div className="buyer__signup__method-container">
                                    {this.buildCertifiedButton()}
                                    {this.buildWireButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    hideNextButton: true
                })}
            </Fragment>
        );
    }
}

export default StepChooseMethod;
