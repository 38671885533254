import { GLOBAL_LOADER_SHOW, GLOBAL_LOADER_HIDE } from './GlobalLoaderActionTypes';

const initialState = {
    counter: 0,
    isVisible: false,
};

const tryToHide = (state) => {
    const finalCounter = Math.max(0, state.counter - 1);
    return {
        counter: finalCounter,
        isVisible: finalCounter > 0,
    };
};

const globalLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
    case GLOBAL_LOADER_SHOW:
        return {
            counter: state.counter + 1,
            isVisible: true,
        };
    case GLOBAL_LOADER_HIDE:
        return tryToHide(state);
    default:
        return state;
    }
}

export default globalLoaderReducer;
