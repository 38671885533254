import React from 'react';
import { Field } from 'formik';
import map from 'lodash/map';
import BaseWizardStep from '../BaseWizardStep';
import AutoCompleteFormikInput from '../../../AutoCompleteFormikInput/AutoCompleteFormikInput';

class StepNewLicense extends BaseWizardStep {
    getFilterStateOptions = () => {
        const { states } = this.props;
        return map(states, (state) => ({
            label: state.name,
            value: state.id,
        }));
    }

    updateStateDetails = (value) => {
        const shouldValidate = true;
        this.props.setFieldValue('state', value, shouldValidate);
        this.props.setFieldTouched('state');
    };

    buildState = () => {
        const fieldName = 'state';
        return (
            <div className="add-new-license__field buyer__signup__field width-30">
                <div className="add-new-license__label-field">
                    State
                </div>
                <Field
                    component={AutoCompleteFormikInput}
                    name={fieldName}
                    placeholder="STATE"
                    getOptions={this.getFilterStateOptions}
                    customOnChange={this.updateStateDetails}
                    initializeGettingOptions
                    isOptionsOnTop
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildLicenseName = () => {
        const fieldName = 'licenseName';
        return (
            <div className="add-new-license__field buyer__signup__field width-70">
                <div className="add-new-license__label-field">
                    License Name
                </div>
                <Field
                    type="text"
                    name={fieldName}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildLicenseNumber = () => {
        const fieldName = 'licenseNumber';
        return (
            <div className="add-new-license__field buyer__signup__field width-30">
                <div className="add-new-license__label-field">
                    License Number
                </div>
                <Field
                    type="text"
                    name={fieldName}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildBoardCommission = () => {
        const fieldName = 'boardCommission';
        return (
            <div className="add-new-license__field buyer__signup__field width-70">
                <div className="add-new-license__label-field">
                    Board / Commission
                </div>
                <Field
                    type="text"
                    name={fieldName}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildLicenseType = () => {
        const fieldName = 'licenseType';
        return (
            <div className="add-new-license__field buyer__signup__field width-30">
                <div className="add-new-license__label-field">
                    License Type
                </div>
                <Field
                    type="text"
                    name={fieldName}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildDesignation = () => {
        const fieldName = 'designation';
        return (
            <div className="add-new-license__field buyer__signup__field width-70">
                <div className="add-new-license__label-field">
                    Designation
                </div>
                <Field
                    type="text"
                    name={fieldName}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildTitle = () => (
        <div className="add-new-license__title buyer__signup__step">
            <div className="add-new-license__title buyer__signup__question">
                Add New License
            </div>
        </div>
    );

    isStepValid = () => this.areFieldsValid([
        'state',
        'licenseName',
        'licenseNumber',
        'boardCommission',
        'licenseType',
        'designation',
    ]);

    render() {
        const isStepFulfilled = this.isStepValid();
        return (
            <>
                <div className="buyer__signupform">
                    <div className="buyer__signupform__step-container">
                        <>
                            {this.buildTitle()}
                            <div className="buyer__signup__fieldset">
                                <div className="buyer__signup__field__row">
                                    {this.buildState()}
                                    {this.buildLicenseName()}
                                </div>
                                <div className="buyer__signup__field__row">
                                    {this.buildLicenseNumber()}
                                    {this.buildBoardCommission()}
                                </div>
                                <div className="buyer__signup__field__row">
                                    {this.buildLicenseType()}
                                    {this.buildDesignation()}
                                </div>
                            </div>
                            { this.renderStepButtons(isStepFulfilled) }
                        </>
                    </div>
                </div>
            </>
        );
    }
}

export default StepNewLicense;
