import React from 'react'
import _map from 'lodash/map'
import './summary.scss'

const replaceInvalidValues = value => value ? value : ''

const arrayToString = arr => arr ? arr.join(", ") : ''

const Summary = (props) => {
    const {
        saleAddresses,
        yearsOfExperience,
        languages,
        professionalCertifications,
        specializations,
        interests,
        cuisines,
        aboutMe
    } = props.values

    const placesServingIn = _map(saleAddresses, ({ city }) => city)
    const experience = yearsOfExperience
    const lngs = _map(languages, ({ name }) => name)
    const certifications = _map(professionalCertifications, ({ name }) => name)
    const spcls = _map(specializations, ({ name }) => name)
    const hobbies = _map(interests, ({ name }) => name)
    const csns = _map(cuisines, ({ name }) => name)
    const biography = aboutMe
    const userName = props.userName

    return (
        <div className="summary__container">
            <div className="header">
                <div>Your Profile Summary</div>
            </div>
            <div className="content">
                <div className="content__header">
                    <div  className="key">
                        You are:
                    </div>
                    <div  className="value">
                        {`${replaceInvalidValues(userName)}`}
                    </div>
                </div>
                <div className="separator" />
                <div className="content__body">
                    <div  className="left">
                        <div className="item">
                            <div  className="item__key">
                                SERVING IN:
                            </div>
                            <div  className="item__value">
                                {`${arrayToString(placesServingIn)}`}
                            </div>
                        </div>
                        <div className="separator__level__two" />
                        <div className="item">
                            <div  className="item__key">
                                YEARS OF EXPERIENCE:
                            </div>
                            <div  className="item__value">
                                {`${experience} Years`}
                            </div>
                        </div>
                        <div className="separator__level__two" />
                        <div className="item">
                            <div  className="item__key">
                                BIOGRAPHY:
                            </div>
                            <div  className="item__value">
                                {`${biography ? biography : ''}`}
                            </div>
                        </div>
                        <div className="separator__level__two" />
                    </div>
                    <div  className="right">
                        <div className="item">
                            <div  className="item__key">
                                LANGUAGES:
                            </div>
                            <div  className="item__value">
                                {`${arrayToString(lngs)}`}
                            </div>
                        </div>
                        <div className="separator__level__two" />
                        <div className="item">
                            <div  className="item__key">
                                CERTIFICATIONS:
                            </div>
                            <div  className="item__value">
                                {`${arrayToString(certifications)}`}
                            </div>
                        </div>
                        <div className="separator__level__two" />
                        <div className="item">
                            <div  className="item__key">
                                SPECIALIZATIONS:
                            </div>
                            <div  className="item__value">
                                {`${arrayToString(spcls)}`}
                            </div>
                        </div>
                        <div className="separator__level__two" />
                        <div className="item">
                            <div  className="item__key">
                                HOBBIES:
                            </div>
                            <div  className="item__value">
                                {`${arrayToString(hobbies)}`}
                            </div>
                        </div>
                        <div className="separator__level__two" />
                        <div className="item">
                            <div  className="item__key">
                                FAVORITE CUISINES:
                            </div>
                            <div  className="item__value">
                                {`${arrayToString(csns)}`}
                            </div>
                        </div>
                        <div className="separator__level__two" />
                    </div>
                </div>
                
            </div>
            
        </div>
    )
}

export default Summary;
