export const SUCCESSFULLY_ACCEPTED_SELLER_MATCH = 'SUCCESSFULLY_ACCEPTED_SELLER_MATCH';
export const SUCCESSFULLY_REJECTED_SELLER_MATCH = 'SUCCESSFULLY_REJECTED_SELLER_MATCH';
export const DISPLAY_NEW_ACTIVE_SELLER_MATCH_MODAL = 'DISPLAY_NEW_ACTIVE_SELLER_MATCH_MODAL';
export const HIDE_NEW_ACTIVE_SELLER_MATCH_MODAL = 'HIDE_NEW_ACTIVE_SELLER_MATCH_MODAL';
export const SUCCESSFULLY_FETCH_ACTIVE_SELLER_MATCHES = 'SUCCESSFULLY_FETCH_ACTIVE_SELLER_MATCHES';
export const SUCCESSFULLY_UPDATE_SELLER_STATUS = 'SUCCESSFULLY_UPDATE_SELLER_STATUS';
export const RECEIVE_UPDATE_SELLER_STATUS_ERROR = 'RECEIVE_UPDATE_SELLER_STATUS_ERROR';
export const START_UPLOADING_REALTOR_SELLER_FILE = 'START_UPLOADING_REALTOR_SELLER_FILE';
export const SUCCESSFULLY_UPLOAD_REALTOR_SELLER_FILE = 'SUCCESSFULLY_UPLOAD_REALTOR_SELLER_FILE';
export const SUCCESSFULLY_REMOVE_REALTOR_SELLER_FILE_ATTACHMENT = 'SUCCESSFULLY_REMOVE_REALTOR_SELLER_FILE_ATTACHMENT';
export const SUCCESSFULLY_UPDATE_REALTOR_SELLER_STATUS = 'SUCCESSFULLY_UPDATE_REALTOR_SELLER_STATUS';