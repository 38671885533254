import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import trim from "lodash/trim";
import { format } from "date-fns";
import { NumberFormat } from "../NumberFormat/NumberFormat";
import RealtorMatchProfileSubjectPropertyInformationMap from "./RealtorMatchProfileSubjectPropertyInformation.map";
import RealtorMatchProfileSubjectPropertyInformationEdit from "./RealtorMatchProfileSubjectPropertyInformation.edit";
import { getFormatPercentage, getUTCDate } from "../../util/util";
import "./realtormatchprofilesubjectpropertyinformation.css";

export default class RealtorMatchProfileSubjectPropertyInformation extends Component {
    static propTypes = {
        propertyAddress: PropTypes.object,
        finalPurchasePrice: PropTypes.number,
        commission: PropTypes.number,
        newConstruction: PropTypes.bool,
        onUpdateLocationInformation: PropTypes.func.isRequired,
        anticipatedClosingDate: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            isEditable: false
        };
    }

    onEdit = () => this.setState({ isEditable: true });

    onCancel = () => this.setState({ isEditable: false });

    onConfirmEdit = data => {
        this.props.onUpdateLocationInformation(data);
        this.onCancel();
    };

    calculatePercentage = () => {
        const { finalPurchasePrice, commission } = this.props;
        return finalPurchasePrice * (commission / 100);
    };

    getFormatAddress = () =>
        this.props.propertyAddress ? (
            <span className="subject-property-information__address">
                {`${trim(get(this.props.propertyAddress, "streetNumberName"))}`}
                <br />
                {`${trim(get(this.props.propertyAddress, "city"))}, ${trim(
                    get(this.props.propertyAddress, "state")
                )}, ${trim(get(this.props.propertyAddress, "zipcode"))}`}
            </span>
        ) : null;

    getFormatAnticipatedDate = () => {
        const { anticipatedClosingDate } = this.props;
        const anticipatedClosingUTCDate = getUTCDate(anticipatedClosingDate);
        const formatAnticipatedClosingDate = format(
            anticipatedClosingUTCDate,
            "MM/DD/YYYY"
        );
        const currentDate = format(new Date(), "MM/DD/YYYY");
        return anticipatedClosingDate
            ? formatAnticipatedClosingDate
            : currentDate;
    };

    buildRightColumn = () => (
        <div className="subject-property-information__box-information default-font">
            <div className="row subject-property-information__box-information is-subtitle">
                <div className="col-xs-12 col-md-8 col-sm-8 is-subtitle">
                    PROPERTY ADDRESS
                </div>
                <div className="col-xs-12 col-md-8 col-sm-8 hide-desktop">
                    <i className="fa fa-map-marker-alt subject-property-information__location-icon" />
                    {this.getFormatAddress()}
                </div>
                <div className="col-xs-12 col-md-4 col-sm-4 is-subtitle">
                    ANTICIPATED CLOSING DATE
                </div>
                <div className="col-xs-12 col-md-4 col-sm-4 hide-desktop">
                    {this.getFormatAnticipatedDate()}
                </div>
            </div>
            <div className="row subject-property-information__box-information__value-property-address hide-mobile">
                <div className="col-xs-8 col-md-8 col-sm-8">
                    <i className="fa fa-map-marker-alt subject-property-information__location-icon" />
                    {this.getFormatAddress()}
                </div>
                <div className="col-xs-4 col-md-4 col-sm-4">
                    {this.getFormatAnticipatedDate()}
                </div>
            </div>
            <div className="row subject-property-information__box-information box-purchase">
                <div className="col-xs-12 col-md-4 col-sm-4 subject-property-information__box-information is-subtitle">
                    FINAL PURCHASE
                </div>
                <div className="col-xs-12 col-md-4 col-sm-4 subject-property-information__box-information final-purchase hide-desktop">
                    <NumberFormat
                        decimals={2}
                        number={this.props.finalPurchasePrice}
                        format="currency"
                    />
                </div>
                <div className="col-xs-12 col-md-4 col-sm-4 subject-property-information__box-information is-subtitle">
                    COMMISION (%)
                </div>
                <div className="col-xs-12 col-md-4 col-sm-4 subject-property-information__box-information hide-desktop">
                    <NumberFormat
                        number={getFormatPercentage(this.props.commission)}
                        format="percent"
                        decimals={1}
                    />
                </div>
                <div className="col-xs-12 col-md-4 col-sm-4 subject-property-information__box-information is-subtitle">
                    COMMISION ($)
                </div>
                <div className="col-xs-12 col-md-4 col-sm-4 subject-property-information__box-information hide-desktop">
                    <NumberFormat
                        decimals={2}
                        number={this.calculatePercentage()}
                        format="currency"
                    />
                </div>
            </div>
            <div className="row subject-property-information__box-information default-font is-purchase-value hide-mobile">
                <div className="col-xs-4 col-md-4 col-sm-4 subject-property-information__box-information final-purchase">
                    <NumberFormat
                        decimals={2}
                        number={this.props.finalPurchasePrice}
                        format="currency"
                    />
                </div>
                <div className="col-xs-4 col-md-4 col-sm-4 subject-property-information__box-information">
                    <NumberFormat
                        number={getFormatPercentage(this.props.commission)}
                        format="percent"
                        decimals={1}
                    />
                </div>
                <div className="col-xs-4 col-md-4 col-sm-4 subject-property-information__box-information">
                    <NumberFormat
                        decimals={2}
                        number={this.calculatePercentage()}
                        format="currency"
                    />
                </div>
            </div>
        </div>
    );

    buildEditButton = () => (
        <button
            type="button"
            className="btn-link subject-property-information__edit-btn"
            onClick={this.onEdit}
        >
            Edit
        </button>
    );

    buildCancelButton = () => (
        <button
            type="button"
            className="btn-link subject-property-information__edit-btn"
            onClick={this.onCancel}
        >
            Cancel
        </button>
    );

    buildRealtorLocationInformation = () => (
        <div className="subject-property-information__row row">
            <div className="subject-property-information__map-container col-xs-4 col-sm-4 col-md-4">
                <RealtorMatchProfileSubjectPropertyInformationMap
                    streetNumberName={trim(
                        get(this.props.propertyAddress, "streetNumberName")
                    )}
                    lat={get(this.props.propertyAddress, "latitude")}
                    lng={get(this.props.propertyAddress, "longitude")}
                />
            </div>
            <div className="subject-property-information__col col-xs-12 col-sm-8 col-md-8">
                {this.buildRightColumn()}
            </div>
        </div>
    );

    buildRealtorLocationForm = () => (
        <RealtorMatchProfileSubjectPropertyInformationEdit
            propertyAddress={this.props.propertyAddress}
            commission={this.props.commission}
            finalPurchasePrice={this.props.finalPurchasePrice}
            newConstruction={this.props.newConstruction}
            anticipatedClosingDate={this.props.anticipatedClosingDate}
            onConfirmEdit={this.onConfirmEdit}
        />
    );

    render = () => {
        const { isEditable } = this.state;
        const container = isEditable
            ? this.buildRealtorLocationForm()
            : this.buildRealtorLocationInformation();
        const actionButton = isEditable
            ? this.buildCancelButton()
            : this.buildEditButton();
        return (
            <div className="subject-property-information__container">
                <div className="subject-property-information__title">
                    Subject Property Information
                </div>
                <div className="subject-property-information__editButton">
                    {actionButton}
                </div>
                {container}
            </div>
        );
    };
}
