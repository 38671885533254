import React from "react";
import * as PropTypes from "prop-types";
import filter from "lodash/filter";
import get from "lodash/get";
import map from "lodash/map";
import concat from "lodash/concat";
import isFunction from "lodash/isFunction";
import find from "lodash/find";
import head from "lodash/head";
import includes from "lodash/includes";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import uniqBy from "lodash/uniqBy";
import findIndex from "lodash/findIndex";
import { SELECT_ROLE_ALL, SELECT_ROLE_OPTION } from "../../shared/constants";
import UserAdminProfileResults from "./UserAdminProfileResults";

import "./userAdminProfile.css";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";

export default class UserAdminProfile extends React.Component {
    constructor(props) {
        super(props);
        const filterByValue =
            get(props.users, "filterBy") || get(head(this.roleOption()), "id");
        const roleLabel = get(this.findRoleLabel(filterByValue), "displayName");
        this.state = {
            term: get(props.users, "term") || undefined,
            sortBy: undefined,
            sortOrder: undefined,
            filterBy: {
                value: filterByValue,
                label: roleLabel
            },
            typingTimeout: null,
            hasMore: true,
            usersCache: [],
            loadMoreUsers: false,
            changeRoleOption: false,
            showLoading: false,
            approved: get(props.users, "approved"),
            state: get(props.users, "state") || undefined,
            sortByState: undefined,
            startDate: get(props.users, "startDate") || undefined,
            endDate: get(props.users, "endDate") || undefined
        };
    }

    componentDidMount = () => {
        this.getUsers();
    };

    onChangeApproved = value =>
        this.setState({ usersCache: [], approved: value }, this.getUsers);

    resetUsersCache = () =>
        this.setState({
            usersCache: [],
            term: undefined,
            sortBy: undefined,
            sortOrder: undefined,
            showLoading: true
        });

    refreshPage = () => {
        const page = get(this.props.users, "page");
        this.resetUsersCache();
        this.getUsers(page, null);
    };

    findRoleLabel = filterByValue =>
        filterByValue
            ? find(SELECT_ROLE_OPTION, { id: filterByValue })
            : "Agent";

    onChangeTerm = e => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            term: e.target.value,
            typingTimeout: setTimeout(() => {
                this.getUsers();
            }, this.props.searchTime),
            usersCache: [],
            showLoading: true
        });
    };

    toggleOrderBy = option => {
        this.setState(() => {
            this.setState(
                {
                    usersCache: [],
                    sortBy: option.value.field,
                    sortOrder: option.value.order
                },
                this.getUsers
            );
        });
    };

    toggleStateBy = option => {
        this.setState(() => {
            this.setState(
                { usersCache: [], sortByState: option.value },
                this.getUsers
            );
        });
    };

    onChangeRoleOption = option => {
        this.setState(
            {
                usersCache: [],
                filterBy: option,
                sortBy: undefined,
                sortOrder: undefined,
                term: undefined,
                changeRoleOption: true,
                sortByState: undefined
            },
            this.getUsers
        );
    };

    loadMore = () => {
        const page = get(this.props.users, "page");

        if (!this.state.loadMoreUsers) {
            this.setState({ loadMoreUsers: true });
            this.getUsers(page + 1, null);
        }
    };

    hasMore = () => {
        const page = get(this.props.users, "page");
        const totalPages = get(this.props.users, "totalPages");
        return totalPages >= page;
    };

    setToggleMoreInfo = (list, indexDeposit, item, stateUserMoreInfo) =>
        set(list, indexDeposit, { ...item, showMoreInfo: !stateUserMoreInfo });

    isUserRealtor = user =>
        Boolean(get(user, "publicProfile.userInfo.realtor"));

    isUserProspect = user =>
        Boolean(get(user, "publicProfile.userInfo.prospect"));

    isUserBuyer = user => Boolean(get(user, "publicProfile.userInfo.buyer"));

    isPublicProfilePrefix = user =>
        this.isUserProspect(user) ||
        this.isUserBuyer(user) ||
        this.isUserRealtor(user);

    getUserId = user =>
        this.isPublicProfilePrefix(user)
            ? get(user, "publicProfile.userInfo.id")
            : get(user, "userInfo.id");

    findIndexUserIdUsers = id =>
        findIndex(this.state.usersCache, user => this.getUserId(user) === id);

    findUserIdUsers = id =>
        find(this.state.usersCache, user => this.getUserId(user) === id);

    getStateButtonShowMoreInfo = item =>
        get(item, "showMoreInfo") ? true : false;

    onClickMoreInfo = id => {
        const indexUser = this.findIndexUserIdUsers(id);
        const user = this.findUserIdUsers(id);
        const stateUserMoreInfo = this.getStateButtonShowMoreInfo(user);

        this.setState(prevState => {
            const prevUsersCache = cloneDeep(prevState.usersCache);
            const updateUsersCache = this.setToggleMoreInfo(
                prevUsersCache,
                indexUser,
                user,
                stateUserMoreInfo
            );
            return { usersCache: updateUsersCache };
        });
    };

    getUsersRole = (page, size, role) => {
        const {
            term,
            filterBy,
            sortBy,
            sortOrder,
            approved,
            sortByState,
            startDate,
            endDate
        } = this.state;

        if (!isFunction(this.props.getUsersRole)) {
            return;
        }
        const showStateForAgent =
            filterBy.value === "ROLE_REALTOR" ? sortByState : undefined;

        this.props
            .getUsersRole(filterBy.value, role, {
                term,
                page,
                size,
                sortBy,
                sortOrder,
                approved,
                state: showStateForAgent,
                startDate,
                endDate
            })
            .then(response => {
                if (!isEmpty(get(response, "users"))) {
                    this.setState(prevState => ({
                        usersCache: concat(
                            prevState.usersCache,
                            get(response, "users")
                        )
                    }));
                    this.setState({
                        loadMoreUsers: false,
                        changeRoleOption: false,
                        showLoading: false
                    });
                } else {
                    this.setState({
                        usersCache: [],
                        showLoading: false,
                        loadMoreUsers: false,
                        changeRoleOption: false
                    });
                }
            });
    };

    getUsersAdmin = (page, size) => {
        const {
            term,
            filterBy,
            sortBy,
            sortOrder,
            approved,
            sortByState,
            startDate,
            endDate
        } = this.state;

        if (!isFunction(this.props.getUsersAsAdmin)) {
            return;
        }
        const showStateForAgent =
            filterBy.value === "ROLE_REALTOR" ? sortByState : undefined;

        this.props
            .getUsersAsAdmin(filterBy.value, {
                term,
                page,
                size,
                sortBy,
                sortOrder,
                approved,
                state: showStateForAgent,
                startDate,
                endDate
            })
            .then(response => {
                if (!isEmpty(get(response, "users"))) {
                    this.setState(prevState => ({
                        usersCache: concat(
                            prevState.usersCache,
                            get(response, "users")
                        )
                    }));
                    this.setState({
                        loadMoreUsers: false,
                        changeRoleOption: false,
                        showLoading: false
                    });
                } else {
                    this.setState({
                        usersCache: [],
                        showLoading: false,
                        loadMoreUsers: false,
                        changeRoleOption: false
                    });
                }
            });
    };

    getRoleNames = () => map(this.props.roles, "name");

    getUsers = (page, size) => {
        const { filterBy } = this.state;
        const role = includes(this.getRoleNames(), SELECT_ROLE_ALL)
            ? SELECT_ROLE_ALL
            : filterBy.value;

        switch (role) {
            case "ROLE_REALTOR":
                this.getUsersRole(page, size, "realtor");
                break;
            case "ROLE_SELLER":
                this.getUsersRole(page, size, "seller");
                break;
            case "ROLE_BUYER":
                this.getUsersRole(page, size, "buyer");
                break;
            case "ROLE_PROSPECT":
                this.getUsersRole(page, size, "prospect");
                break;
            case SELECT_ROLE_ALL:
                this.getUsersAdmin(page, size);
                break;
            default:
                break;
        }
    };

    onSuspendUser = (userId, newApproved) => {
        this.setState({ usersCache: [], showLoading: true });
        this.props.onSuspendUser(userId, newApproved).then(response => {
            this.setState({
                usersCache: get(response, "users"),
                showLoading: false
            });
        });
    };

    buildResults = () => {
        const {
            term,
            sortBy,
            usersCache,
            sortOrder,
            sortByState,
            changeRoleOption
        } = this.state;

        if (changeRoleOption) {
            return (
                <div className="admin-profile__container-results">
                    <div className="admin-profile__options-content">
                        <SpinnerLoader />
                    </div>
                </div>
            );
        }

        return (
            <UserAdminProfileResults
                term={term}
                sortBy={sortBy}
                sortOrder={sortOrder}
                sortByState={sortByState}
                usersCache={usersCache}
                dateFormat={this.props.dateFormat}
                filterBy={this.state.filterBy.value}
                showLoading={this.state.showLoading}
                users={this.props.users}
                isFetchingGetUsers={this.props.isFetchingGetUsers}
                onImpersonateUser={this.props.onImpersonateUser}
                onImpersonateUserByRole={this.props.onImpersonateUserByRole}
                onResendRealtorAgreement={this.props.onResendRealtorAgreement}
                onResendBrokerAgreement={this.props.onResendBrokerAgreement}
                onSuspendUser={this.onSuspendUser}
                onChangeTerm={this.onChangeTerm}
                refreshPage={this.refreshPage}
                loadMore={this.loadMore}
                toggleOrderBy={this.toggleOrderBy}
                toggleStateBy={this.toggleStateBy}
                onClickMoreInfo={this.onClickMoreInfo}
                roles={this.getRoleNames()}
                approved={this.state.approved}
                onChangeApproved={this.onChangeApproved}
                completeTransferReceipt={this.props.completeTransferReceipt}
                isFetchingTransferReceipt={this.props.isFetchingTransferReceipt}
                successTransferReceipt={this.props.successTransferReceipt}
                licensedStates={this.props.licensedStates}
            />
        );
    };

    roleOption = () => {
        const roleNames = this.getRoleNames();
        return includes(roleNames, SELECT_ROLE_ALL)
            ? SELECT_ROLE_OPTION
            : filter(SELECT_ROLE_OPTION, ({ role }) =>
                  includes(roleNames, role)
              );
    };

    renderProfileTabs() {
        const { filterBy: { value: currentValue } = {} } = this.state;
        const roleOptions = this.roleOption();
        const removeDuplicatedOptions = uniqBy(roleOptions, "id");

        return (
            <div className="admin-profile__tabs">
                {map(
                    removeDuplicatedOptions,
                    ({ id: value, displayName: label, displayNamePlural }) => (
                        <button
                            className={`admin-profile__tabs__button ${
                                value === currentValue ? "is-active" : ""
                            }`}
                            onClick={() =>
                                this.onChangeRoleOption({ label, value })
                            }
                            disabled={
                                this.props.isFetchingGetUsers &&
                                value !== currentValue
                            }
                        >
                            {displayNamePlural}
                        </button>
                    )
                )}
            </div>
        );
    }

    render = () => (
        <div className="admin-profile">
            <div className="admin-profile__title">Users</div>

            {this.renderProfileTabs()}

            <div className="admin-profile__container">
                {this.buildResults()}
            </div>
        </div>
    );
}

UserAdminProfile.defaultProps = {
    dateFormat: "MM.DD.YYYY hh:mm a",
    searchTime: 300
};

UserAdminProfile.propTypes = {
    dateFormat: PropTypes.string,
    searchTime: PropTypes.number,
    getUsersAsAdmin: PropTypes.func,
    getUsersRole: PropTypes.func,
    users: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.object),
    isFetchedGetUsers: PropTypes.bool,
    isFetchingGetUsers: PropTypes.bool,
    onImpersonateUser: PropTypes.func,
    onImpersonateUserByRole: PropTypes.func,
    onResendRealtorAgreement: PropTypes.func,
    onResendBrokerAgreement: PropTypes.func,
    onSuspendUser: PropTypes.func,
    onCreateNewEvent: PropTypes.func,
    completeTransferReceipt: PropTypes.func,
    isFetchingTransferReceipt: PropTypes.bool,
    successTransferReceipt: PropTypes.bool,
    licensedStates: PropTypes.object
};
