import { connect } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import { withFormik } from 'formik';
import {
    object as YupObject,
    string as YupString,
    number as YupNumber,
} from 'yup';
import toNumber from 'lodash/toNumber';
import {
    createNewLicense,
} from '../../../../util/service_api';
import { YupUsPhoneNumber } from '../../../../shared/validations';
import WizardStepsForm from './WizardStepsForm';
import { loadUserProfile } from '../../../../reducers/user/UserActions';

const WizardStepsContainer = withFormik({
    mapPropsToStatus: () => ({
        formState: 'initial',
    }),

    mapPropsToValues: () => ({
        state: '',
        licenseName: '',
        licenseNumber: '',
        boardCommission: '',
        licenseType: '',
        designation: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        licenseId: '',
    }),

    validationSchema: () => YupObject().shape({
        state: YupString()
            .trim()
            .required('State is required'),

        licenseName: YupString()
            .trim()
            .required('License Name is required'),

        licenseNumber: YupString()
            .trim()
            .required('License Number is required'),

        boardCommission: YupString()
            .trim()
            .required('Board/Commission is required'),
        
        licenseType: YupString()
            .trim()
            .required('License Type is required'),
    
        designation: YupString()
            .trim()
            .required('Designation is required'),

        firstName: YupString()
            .trim()
            .required('First Name is required'),

        lastName: YupString()
            .trim()
            .required('Last Name is required'),

        email: YupString()
            .trim()
            .email('Invalid email address')
            .required('Email is required'),

        phone: YupUsPhoneNumber(),

        licenseId: YupNumber()
            .required('License Id is required'),
    }),

    buildPayload(values,props) {
        const stateId = get(values, 'state');
        return {
            name: get(values, 'licenseName'),
            number: get(values, 'licenseNumber'),
            boardCommission: get(values, 'boardCommission'),
            type: get(values, 'licenseType'),
            designation: get(values, 'designation'),
            state: find(props.states, { id: stateId }),
            broker: {
                licenseId: toNumber(get(values, 'licenseId')),
                firstName: get(values, 'firstName'),
                lastName: get(values, 'lastName'),
                email: get(values, 'email'),
                phoneNumber: get(values, 'phone'),
            }
        };
    },

    // Submission handler
    handleSubmit(values, {
        props, setStatus, setSubmitting, setError
    }) {
        setSubmitting(true);
        setStatus({ formState: 'submitting' });
        const data = this.buildPayload(values, props);
        createNewLicense(data)
            .then(props.loadUserProfile)
            .catch((error) => {
                setError({ error });
            })
            .finally(() => {
                setStatus({ formState: 'submitted' });
                setSubmitting(false);
            });
    },
})(WizardStepsForm);

const mapStateToProps = (state) => ({
    states: state.configApp.config ? state.configApp.config.usStates : [],
});

const mapDispatchToProps = (dispatch) => ({
    loadUserProfile: () => dispatch(loadUserProfile()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStepsContainer);
