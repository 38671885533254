import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import { Button } from '../Button/Button';


import './dialogmodal.css';

const DialogModal = (props) => (
    <Modal
        animation
        autoFocus
        backdrop
        enforceFocus
        show={props.isVisible}
        className="dialog-modal"
    >
        <ModalHeader className="dialog-modal__header">
            <Button
                icon 
                dark 
                symbol="times"
                type="button"
                onClick={props.onClose}
            >
            </Button>
        </ModalHeader>
        <ModalBody className="dialog-modal__body">
            {props.label}
            <div className="dialog-modal__container-buttons">
                <Button
                    inverted
                    onClick={props.onClose}
                >
                    {props.cancelButtonText}
                </Button>
                <Button
                    onClick={props.onAccept}
                >
                    {props.acceptButtonText}
                </Button>
            </div>
        </ModalBody>
    </Modal>
);

DialogModal.propTypes = {
    label: PropTypes.string,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    cancelButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string,
};

DialogModal.defaultProps = {
    cancelButtonText: 'Cancel',
    acceptButtonText: 'OK',
};

export default DialogModal;
