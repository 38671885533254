import React from "react";
import CustomRealtorAutoCompleteField from "../../CustomRealtorAutoCompleteField/CustomRealtorAutoCompleteField";

import "./interests.scss";

const Interests = props => {
    return (
        <div className="interests__container">
            <div className="level__one__text">What are your hobbies?</div>
            <CustomRealtorAutoCompleteField
                fieldProp="interests"
                labelProp="name"
                autoCompleteUri="autocomplete/interest"
                placeholder="Search"
                value={props.value}
                isMulti
                onEdit={props.onEdit}
                allowRepeatedValues={false}
                allowSelectingWithCheckbox
            />
        </div>
    );
};

export default Interests;
