import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { format } from 'date-fns';
import { formatAddressStreetApartment, formatAddressCityStateZipCode } from '../../../util/util';
import EarnestMoneyDepositStatusPill from '../../EarnestMoneyDepositStatusPill/EarnestMoneyDepositStatusPill';
import BrewBoardNumberCurrency from './BrewBoardNumberCurrency';

import '../userAdminProfile.css';

export default class TransfersTable extends React.Component {
    buildAddress = (property) => {
        const streetNumberName = get(property, 'streetNumberName');
        const apartmentSuiteNumber = get(property, 'apartmentSuiteNumber');
        const city = get(property, 'city');
        const state = get(property, 'state.abbreviation');
        const zipcode = get(property, 'zipcode');

        return (
            <div className="admin-profile__address-content">
                <i className="fa fa-map-marker-alt emd-right-column__location-icon" />
                <div>
                    <span>
                        {formatAddressStreetApartment(streetNumberName, apartmentSuiteNumber, false)}
                    </span>
                    <span>, </span>
                    <span>
                        {formatAddressCityStateZipCode(city, state, zipcode)}
                    </span>
                </div>
            </div>
        );
    }

    buildHeaderTransferList = () => (
        <div className="admin-profile-transfers-header hide-on-mobile">
            <div className="admin-profile-transfers-header-items">
                <div className="admin-profile-transfers-header-item">Property Address</div>
                <div className="admin-profile-transfers-header-item">Amount</div>
                <div className="admin-profile-transfers-header-item">Transfer Date</div>
                <div className="admin-profile-transfers-header-item">Status</div>
            </div>
        </div>
    );

    buildContentTransferList = () => {
        const { earnestMoneyDeposits } = this.props;
        return (
            <div className="admin-profile-transfers-content">
                {map(earnestMoneyDeposits, (deposit, index) => {
                    const address = get(deposit, 'subjectPropertyAddress');
                    const totalAmount = get(deposit, 'totalAmount');
                    const dateOfTransfer = get(deposit, 'ts');
                    const status = get(deposit, 'status.name');

                    return (
                        <div key={index} className="admin-profile-transfers-content-items">
                            <div className="admin-profile-transfers-content-item">
                                <div className="admin-profile-transfers-content-item--label show-on-mobile">Property Address</div>
                                <div className="admin-profile-transfers-content-item--value">
                                    {this.buildAddress(address)}
                                </div>
                            </div>
                            <div className="admin-profile-transfers-content-item">
                                <div className="admin-profile-transfers-content-item--label show-on-mobile">Amount</div>
                                <div className="admin-profile-transfers-content-item--value">
                                    <b>{BrewBoardNumberCurrency(totalAmount)}</b>
                                </div>
                            </div>
                            <div className="admin-profile-transfers-content-item">
                                <div className="admin-profile-transfers-content-item--label show-on-mobile">Transfer Date</div>
                                <div className="admin-profile-transfers-content-item--value">
                                    {format(dateOfTransfer, this.props.dateFormat)}
                                </div>
                            </div>
                            <div className="admin-profile-transfers-content-item">
                                <div className="admin-profile-transfers-content-item--label show-on-mobile">Status</div>
                                <div className="admin-profile-transfers-content-item--value">
                                    <EarnestMoneyDepositStatusPill status={status} />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        )
    };

    render = () => (
        <div className="admin-profile-transfers">
            {this.buildHeaderTransferList()}
            {this.buildContentTransferList()}
        </div>
    );
}

TransfersTable.propTypes = {
    earnestMoneyDeposits: PropTypes.array,
    dateFormat: PropTypes.string,
};
