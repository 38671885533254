import React from 'react';
import get from 'lodash/get';
import Ruler from '../../images/icons/ruler.svg';
import House from '../../images/icons/house.svg';
import Bed from '../../images/icons/beds.svg';
import Car from '../../images/icons/car.svg';
import Bath from '../../images/icons/toilet.svg';
import CupBoard from '../../images/icons/cupboard.svg'

import './property_details.scss'
/**
 * @param {props} props
 */

const PropertyDetails = (props) => {
    const beds = get(props, 'data.beds') || '-'
    const baths = get(props, 'data.baths') || '-'
    const rooms = get(props, 'data.rooms') || '-'
    const garage = get(props, 'data.garage') || '-'
    const livingArea = get(props, 'data.livingArea') || '-'
    const grossArea = get(props, 'data.grossArea') || '-'
    const pool = get(props, 'data.pool') || '-'
    const firePlace = get(props, 'data.firePlace') || '-'
    const basement = get(props, 'data.basement') || '-'
    const cooling = get(props, 'data.cooling') || '-'
    const heating = get(props, 'data.heating') || '-'
    const yearBuilt = get(props, 'data.yearBuilt') || '-'
    const numberOfStories = get(props, 'data.numberOfStories') || '-'
    const price = get(props, 'data.price')
    const listingStatus = get(props, 'data.listingStatus ')
    const formattedPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
        .format(price)
    const landUse = get(props, 'data.landUse') || '-'
    const zoning = get(props, 'data.zoning') || '-'
    const style = get(props, 'data.style') || '-'
    const exteriorWall = get(props, 'data.exteriorWall') || '-'

    return <div className="property__details__container">
                <div className="header">
                    <div className="header__text">Property Details</div>
                    <div className="space"/>
                    {
                        listingStatus ? <div className="for__sale"><div>FOR SALE</div></div> :
                        ''
                    }
                    
                </div>
                <div className="separator"/>
                <div className="property__details__summary">
                    <div className="item">
                        <img className="icon" src={Bed} alt="bed" />
                        <div className="text">{`${beds} beds`}</div>
                    </div>
                    <div className="item">
                        <img className="icon" src={Bath} alt="bath" />
                        <div className="text">{`${baths} baths`}</div>
                    </div>
                    <div className="item">
                        <img className="icon" src={CupBoard} alt="cupboard" />
                        <div className="text">{`${rooms} rooms`}</div>
                    </div>
                    <div className="item">
                        <img className="icon" src={Car} alt="car" />
                        <div className="text">{`${garage} garage`}</div>
                    </div>
                    <div className="item">
                        <img className="icon" src={House} alt="house" />
                        <div className="text">{`${livingArea} ft `}&sup2;</div>
                    </div>
                    <div className="item">
                        <img className="icon" src={Ruler} alt="ruler" />
                        <div className="text">{`${grossArea} ft`}&sup2; lot</div>
                    </div>
                </div>
                <div className="separator"/>
                <div className="property__details__content">
                    <div className="property__details__content__column">
                        <div className="column__item">
                            <div className="column__item__key">POOL:</div>
                            <div className="column__item__value">{pool}</div>
                        </div>
                        <div className="column__item">
                            <div className="column__item__key">FIREPLACE:</div>
                            <div className="column__item__value">{firePlace}</div>
                        </div>
                        <div className="column__item">
                            <div className="column__item__key">BASEMENT:</div>
                            <div className="column__item__value">{basement}</div>
                        </div>
                    </div>
                    <div className="property__details__content__column">
                        <div className="column__item">
                            <div className="column__item__key">COOLING:</div>
                            <div className="column__item__value">{cooling}</div>
                        </div>
                        <div className="column__item">
                            <div className="column__item__key">HEATING:</div>
                            <div className="column__item__value">{heating}</div>
                        </div>
                        <div className="column__item">
                            <div className="column__item__key">YEAR BUILT / EFF:</div>
                            <div className="column__item__value">{yearBuilt}</div>
                        </div>
                    </div>
                    <div className="property__details__content__column">
                        <div className="column__item">
                            <div className="column__item__key">STORIES:</div>
                            <div className="column__item__value">{numberOfStories}</div>
                        </div>
                        <div className="column__item">
                            <div className="column__item__key">PRICE / SQ. FT.:</div>
                            <div className="column__item__value">{price ? formattedPrice:'-'}</div>
                        </div>
                        <div className="column__item">
                            <div className="column__item__key">LAND USE:</div>
                            <div className="column__item__value">{landUse}</div>
                        </div>
                    </div>
                    <div className="property__details__content__column">
                        <div className="column__item">
                            <div className="column__item__key">ZONING:</div>
                            <div className="column__item__value">{zoning}</div>
                        </div>
                        <div className="column__item">
                            <div className="column__item__key">STYLE:</div>
                            <div className="column__item__value">{style}</div>
                        </div>
                        <div className="column__item">
                            <div className="column__item__key">EXTERIOR WALLS:</div>
                            <div className="column__item__value">{exteriorWall}</div>
                        </div>
                    </div>
                    
                </div>
            </div>
};


export default PropertyDetails;
