import React from 'react';
import get from 'lodash/get';
import { ResponsiveBar } from '@nivo/bar'

const SalesAndForeclosuresGraph = (props) => {
    
        const salesCount = get(props, 'marketTrendSaleCount') || []
        const foreclosureCount = get(props, 'marketTrendForeclosureCount') || []
        const periods = get(props, 'marketTrendPeriodMonthYear') || []

        const data = periods.map((period, index) => {
                const dataValues = {};
                dataValues.period = period
                dataValues.Sales = salesCount[index];
                dataValues.Foreclosures = foreclosureCount[index];
                return dataValues;
            });

        return (
                <ResponsiveBar
                    data={data}
                    keys={[ 'Foreclosures', 'Sales', ]}
                    indexBy="period"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    colors={{ scheme: 'accent' }}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    axisTop={null}
                    axisRight={null}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 12,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
            />
        )
}

export default SalesAndForeclosuresGraph;