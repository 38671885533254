import get from "lodash/get";
import React, { Fragment } from "react";
import { Field } from "formik";
import { AmountField } from "../../../Form";
import BaseWizardStep from "../BaseWizardStep";
import mixpanel from "mixpanel-browser";

class StepHowMuchProceeds extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;

        mixpanel.track(
            "Completed How Much Of The Proceeds Do You Plan On Using",
            {
                buyerIsUsingNetProceedsFromCurrentHome: get(
                    values,
                    "buyerNetProceedsBeingUsedFromCurrentHome"
                )
            }
        );
    };

    render() {
        const isStepFulfilled = this.areFieldsValid([
            "buyerNetProceedsBeingUsedFromCurrentHome"
        ]);

        return (
            <Fragment>
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        How much of the proceeds do you plan on using?
                    </div>
                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field width-25 hide-xs" />
                            <div className="buyer__signup__field buyer__signup__field_dollar width-50">
                                <Field
                                    type="text"
                                    name="buyerNetProceedsBeingUsedFromCurrentHome"
                                    placeholder="Enter a dollar amount"
                                    component={AmountField}
                                    className="is-bigger"
                                />
                                {/* { this.renderFieldError('buyerNetProceedsBeingUsedFromCurrentHome') } */}
                            </div>
                            <div className="buyer__signup__field width-25 hide-xs" />
                        </div>
                    </div>
                </div>

                {this.renderStepButtons(
                    {
                        canProceed: isStepFulfilled,
                        hasMixPanelTracking: true
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepHowMuchProceeds;
