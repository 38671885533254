import React from "react";
import { connect, Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import Admin from "./components/Admin/Admin";
import SignIn from "./components/SignIn/SignIn";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import SignUp from "./components/SignUp/SignUp";
import SignOut from "./components/SignOut/SignOut";
import TermsAndConditions from "./components/SignUp/BuyerSignUp/SignUpStep/TermsAndConditions";
import PrivacyPolicy from "./components/SignUp/BuyerSignUp/SignUpStep/PrivacyPolicy";
import SummaryOfRights from "./components/SignUp/BuyerSignUp/SignUpStep/SummaryOfRights";
import SellerTermsAndConditions from "./components/SignUp/SellerSignUpBrew/TermsAndConditions";
import SellerPrivacyPolicy from "./components/SignUp/SellerSignUpBrew/PrivacyPolicy";
import SellerSummaryOfRights from "./components/SignUp/SellerSignUpBrew/SummaryOfRights";
import RealtorPrivacyPolicy from "./components/SignUp/RealtorSignUp/RealtorPrivacyPolicy";
import RealtorTermsAndConditions from "./components/SignUp/RealtorSignUp/RealtorTermsAndConditions";
import EmdTermsAndConditions from "./components/SignUp/EmdSignUp/SignUpSteps/EmdTermsAndConditions";
import EmdPrivacyPolicy from "./components/SignUp/EmdSignUp/SignUpSteps/EmdPrivacyPolicy";
import BrokerTermsAndConditions from "./components/SignUp/BrokerSignUp/BrokerTermsAndConditions";
import BrokerPrivacyPolicy from "./components/SignUp/BrokerSignUp/BrokerPrivacyPolicy";
import NavBar from "./components/NavBar/NavBar";
import NewPassword from "./components/CreateNewPassword/NewPassword";
import PrivateRoute from "./components/Shared/PrivateRoute";
import GetStarted from "./components/SignUp/GetStarted/GetStarted";
import BuyerSeller from "./components/SignUp/GetStarted/BuyerSeller";
import UserProfile from "./components/UserProfile/UserProfile";
import LandingPage from "./components/Landing/Landing";
import GlobalLoader from "./components/GlobalLoader/GlobalLoader";
import RealtorPublicProfile from "./components/RealtorPublicProfile/RealtorPublicProfile";
import BuyerPublicProfile from "./components/BuyerPublicProfile/BuyerPublicProfile";
import BuyerDisputedProfile from "./components/BuyerDisputedProfile/BuyerDisputedProfile";
import TimeoutSessionExpiryNotification from "./components/TimeoutSessionExpiryNotification/TimeoutSessionExpiryNotification";
import RealtorNewActiveMatchModal from "./components/RealtorNewActiveMatchModal/RealtorNewActiveMatchModal";
import EmailConfirmationBanner from "./components/EmailConfirmationBanner/EmailConfirmationBanner";
import RealtorRoute from "./components/RealtorRoute/RealtorRoute";
import SellerRoute from "./components/SellerRoute/SellerRoute";
import EmdRoute from "./components/EmdRoute/EmdRoute";
import EmdRouteDeposit from "./components/EmdRoute/EmdRouteDeposit";
import AgentsStartPage from "./components/AgentsStartPage";
import AdminProfile from "./components/AdminProfile/AdminProfile";
import AdminProfileBrewCrew from "./components/AdminProfileBrewCrew/AdminProfileBrewCrew";
import AdminProfileLicense from "./components/AdminProfileLicense/AdminProfileLicense";
import AdminProfileStats from "./components/AdminProfileStats/AdminProfileStats";
import AdminProfileBroker from "./components/AdminProfileBroker/AdminProfileBroker";
import AdminProfileCampaigns from "./components/AdminProfileCampaigns/AdminProfileCampaigns";
import AdminRealtorMap from "./components/AdminProfile/AdminRealtorMap";
import { DefaultPolicy } from "./components/Shared/DefaultPolicy";
import { PolicyContextProvider } from "./components/Shared/PolicyContext";
import DocusignCompleted from "./components/Verification/DocusignCompleted/DocusignCompleted";
import Sparkhire from "./components/Verification/SparkHireVideoVerification/SparkHireVideoVerification";
import GoToDocuSign from "./components/Verification/GoToDocuSign/GoToDocuSign";
import WhatsABrewScore from "./components/WhatsABrewScore";
import Tracking from "./util/tracking";
import Footer from "./components/Footer/Footer";
import ScrollToTopOnPageChange from "./components/ScrollToTopOnPageChange/ScrollToTopOnPageChange";
import IntercomIntegration from "./components/Intercom/Intercom";
import PropertyProfile from "./components/PropertyProfile/PropertyProfile";
import AgentFlow from "./components/AgentFlow/AgentFlow";
import { getEnvironmentName } from "./util/util";
import AdminProfileBuyerStatsContainer from "components/AdminProfileBuyerStats/AdminProfileBuyerStatsContainer";
import AdminProfileBuyer from "./components/AdminProfileBuyer/AdminProfileBuyer";
import "./App.css";
import "./styles/react-boostrap/bootstrapv3.css";
import "react-toastify/dist/ReactToastify.css";

const THIRTY_MINUTES_IN_MILLISECONDS = 30 * 60 * 1000;

class App extends React.PureComponent {
    componentDidMount() {
        Tracking.init();
    }

    render() {
        return (
            <Provider store={this.props.store}>
                <Router>
                    <ScrollToTopOnPageChange />
                    <div className="App">
                        <div className="App-container">
                            <Helmet>
                                <body data-env={getEnvironmentName()} />
                            </Helmet>
                            <PolicyContextProvider value={DefaultPolicy}>
                                <TimeoutSessionExpiryNotification
                                    millisecondsToShowNotification={
                                        THIRTY_MINUTES_IN_MILLISECONDS
                                    }
                                />
                                <RealtorNewActiveMatchModal />
                                <NavBar />
                                <EmailConfirmationBanner />
                                <Route
                                    render={() => (
                                        <GlobalLoader>
                                            <Switch>
                                                <Route
                                                    exact
                                                    path="/"
                                                    component={SignIn}
                                                />
                                                <Route
                                                    path="/onboarding"
                                                    component={SignIn}
                                                />
                                                <Route
                                                    path="/agent"
                                                    component={LandingPage}
                                                />
                                                <Route
                                                    path="/admin"
                                                    component={Admin}
                                                />
                                                <Route
                                                    path="/change-password"
                                                    component={NewPassword}
                                                />
                                                <Route
                                                    path="/disputed"
                                                    component={
                                                        BuyerDisputedProfile
                                                    }
                                                />
                                                <Route
                                                    path="/get-started"
                                                    component={GetStarted}
                                                />
                                                <Route
                                                    path="/buyer-seller"
                                                    component={BuyerSeller}
                                                />
                                                <Route
                                                    path="/reset-password"
                                                    component={ResetPassword}
                                                />
                                                <Route
                                                    path="/signin"
                                                    component={SignIn}
                                                />
                                                <Route
                                                    path="/signout"
                                                    component={SignOut}
                                                />
                                                <Route
                                                    path="/signup"
                                                    component={SignUp}
                                                />
                                                <Route
                                                    path="/terms-of-use-buyer"
                                                    component={
                                                        TermsAndConditions
                                                    }
                                                />
                                                <Route
                                                    path="/privacy-policy-buyer"
                                                    component={PrivacyPolicy}
                                                />
                                                <Route
                                                    path="/summary-of-rights-buyer"
                                                    component={SummaryOfRights}
                                                />
                                                <Route
                                                    path="/terms-of-use-seller"
                                                    component={
                                                        SellerTermsAndConditions
                                                    }
                                                />
                                                <Route
                                                    path="/privacy-policy-seller"
                                                    component={
                                                        SellerPrivacyPolicy
                                                    }
                                                />
                                                <Route
                                                    path="/summary-of-rights-seller"
                                                    component={
                                                        SellerSummaryOfRights
                                                    }
                                                />
                                                <Route
                                                    path="/privacy-policy-realtor"
                                                    component={
                                                        RealtorPrivacyPolicy
                                                    }
                                                />
                                                <Route
                                                    path="/terms-of-use-realtor"
                                                    component={
                                                        RealtorTermsAndConditions
                                                    }
                                                />
                                                <Route
                                                    path="/privacy-policy-emd"
                                                    component={EmdPrivacyPolicy}
                                                />
                                                <Route
                                                    path="/terms-of-use-emd"
                                                    component={
                                                        EmdTermsAndConditions
                                                    }
                                                />
                                                <Route
                                                    path="/privacy-policy-broker"
                                                    component={
                                                        BrokerPrivacyPolicy
                                                    }
                                                />
                                                <Route
                                                    path="/terms-of-use-broker"
                                                    component={
                                                        BrokerTermsAndConditions
                                                    }
                                                />
                                                <Route
                                                    path="/go-to/docusign"
                                                    component={GoToDocuSign}
                                                />
                                                <Route
                                                    path="/docusign/complete"
                                                    component={
                                                        DocusignCompleted
                                                    }
                                                />
                                                <Route
                                                    path="/sparkhire/intro"
                                                    component={Sparkhire}
                                                />
                                                <Route
                                                    path="/what-is-a-brewscore"
                                                    component={WhatsABrewScore}
                                                />
                                                <Route
                                                    path="/agents-landing-page"
                                                    component={AgentsStartPage}
                                                />
                                                <Route
                                                    path="/property-profile"
                                                    component={PropertyProfile}
                                                />
                                                <PrivateRoute
                                                    path="/buyer/:id"
                                                    component={
                                                        BuyerPublicProfile
                                                    }
                                                />
                                                <PrivateRoute
                                                    path="/profile"
                                                    component={UserProfile}
                                                />
                                                <PrivateRoute
                                                    path="/realtor/public-profile/:id"
                                                    component={
                                                        RealtorPublicProfile
                                                    }
                                                />
                                                <PrivateRoute
                                                    path="/realtor"
                                                    component={RealtorRoute}
                                                />
                                                <PrivateRoute
                                                    path="/seller"
                                                    component={SellerRoute}
                                                />
                                                <PrivateRoute
                                                    path="/transfer/deposit/:id"
                                                    component={EmdRouteDeposit}
                                                />
                                                <PrivateRoute
                                                    path="/transfer"
                                                    component={EmdRoute}
                                                />
                                                <PrivateRoute
                                                    path="/admin-profile"
                                                    component={AdminProfile}
                                                />
                                                <PrivateRoute
                                                    path="/admin-realtor-map"
                                                    component={AdminRealtorMap}
                                                />
                                                <PrivateRoute
                                                    path="/admin-brewcrew"
                                                    component={
                                                        AdminProfileBrewCrew
                                                    }
                                                />
                                                <PrivateRoute
                                                    path="/admin-licenses"
                                                    component={
                                                        AdminProfileLicense
                                                    }
                                                />
                                                <PrivateRoute
                                                    path="/admin-stats"
                                                    component={
                                                        AdminProfileStats
                                                    }
                                                />
                                                <PrivateRoute
                                                    path="/admin-buyer-stats"
                                                    component={
                                                        AdminProfileBuyerStatsContainer
                                                    }
                                                />
                                                <PrivateRoute
                                                    path="/admin-broker"
                                                    component={
                                                        AdminProfileBroker
                                                    }
                                                />
                                                <PrivateRoute
                                                    path="/admin-campaigns"
                                                    component={
                                                        AdminProfileCampaigns
                                                    }
                                                />
                                                <PrivateRoute
                                                    path="/agent-flow"
                                                    component={AgentFlow}
                                                />
                                                <PrivateRoute
                                                    path="/admin-profile-buyer/:userId"
                                                    component={
                                                        AdminProfileBuyer
                                                    }
                                                />
                                            </Switch>
                                        </GlobalLoader>
                                    )}
                                />
                            </PolicyContextProvider>
                            <ToastContainer />
                            <IntercomIntegration />
                        </div>
                        <Footer />
                    </div>
                </Router>
            </Provider>
        );
    }
}
export default connect(state => ({ user: state.user }))(App);
