import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import invoke from "lodash/invoke";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import find from "lodash/find";
import concat from "lodash/concat";
import APIDomain from "../../shared/apiSettings";
import AutoCompleteInput from "../AutoCompleteInput/AutoCompleteInput";
import { SELECT_SORT_BY_OPTION, SELECT_ROLE_ALL } from "../../shared/constants";
import ProspectTable from "./AdminRoleTables/ProspectTable";
import BuyerTable from "./AdminRoleTables/BuyerTable";
import RealtorTable from "./AdminRoleTables/RealtorTable";
import SellerTable from "./AdminRoleTables/SellerTable";
import InfiniteScroll from "react-infinite-scroller";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import ProspectUploadDocumentButton from "../ProspectUploadDocumentButton/ProspectUploadDocumentButton";
import BrewBoardDownloadModal from "../BrewBoardDownloadModal/BrewBoardDownloadModal";
import ProspectUploadedFilesPreviewModal from "../AdminProfile/AdminRoleTables/ProspectUploadedFilesPreviewModal";
import { BREWCREW_APPROVE_OPTIONS } from "../../shared/constants";
import { getProspectUploads } from "../../util/service_api";

import "./userAdminProfile.css";

const roleOptionTable = {
    ROLE_PROSPECT: ProspectTable,
    ROLE_REALTOR: RealtorTable,
    ROLE_SELLER: SellerTable,
    ROLE_BUYER: BuyerTable
};

export default class UserAdminProfileResults extends React.Component {
    static defaultProps = {
        onGetProspectDocument: getProspectUploads
    };

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showProspectModal: false,
            selectedOption: "csv",
            prospectDocuments: [],
            isFetchingProspectDocuments: false,
            isVisibleViewTransfers: false,
            earnestMoneyDeposits: []
        };
    }

    handleOptionChange = changeEvent => {
        this.setState({
            selectedOption: changeEvent.target.value
        });
    };

    close = () => {
        this.setState({ showModal: false });
    };

    open = () => {
        this.setState({ showModal: true });
    };

    closeProspect = () => {
        this.setState({ showProspectModal: false });
    };

    onOpenViewTransfers = earnestMoneyDeposits =>
        this.setState({
            earnestMoneyDeposits: earnestMoneyDeposits,
            isVisibleViewTransfers: true
        });

    onCloseViewTransfers = () =>
        this.setState({ isVisibleViewTransfers: false });

    openProspect = prospectId => {
        this.setState({
            showProspectModal: true,
            isFetchingProspectDocuments: true
        });

        Promise.resolve()
            .then(() => invoke(this.props, "onGetProspectDocument", prospectId))
            .then(prospectDocuments => {
                this.setState({ prospectDocuments });
            })
            .finally(() => {
                this.setState({ isFetchingProspectDocuments: false });
            });
    };

    buildDownloadReportLink = () => {
        let downloadLinkParams = {
            filterBy: this.props.filterBy,
            format: this.state.selectedOption,
            term: this.props.term,
            sortBy: this.props.sortBy,
            approved: this.props.approved,
            sortOrder: this.props.sortOrder
        };

        const apiRootUrl = APIDomain;
        const queryString = Object.keys(downloadLinkParams)
            .map(key => {
                if (downloadLinkParams[key] !== undefined || "") {
                    return `&${key}=${downloadLinkParams[key]}`;
                }
                return undefined;
            })
            .join("");
        const buildURL = get(downloadLinkParams, "filterBy");
        switch (buildURL) {
            case "ROLE_BUYER":
                return `${apiRootUrl}/api/brewcrew/buyer/users/download?${queryString}`;
            case "ROLE_SELLER":
                return `${apiRootUrl}/api/brewcrew/seller/users/download?${queryString}`;
            case "ROLE_PROSPECT":
                return `${apiRootUrl}/api/brewcrew/prospect/users/download?${queryString}`;
            case "ROLE_REALTOR":
                return `${apiRootUrl}/api/brewcrew/realtor/users/download?${queryString}`;
            default:
                return "";
        }
    };

    handleDownload = () => {
        return this.buildDownloadReportLink(this.props);
    };

    viewUploadsLink = () => ({
        id: "viewUploads",
        label: "View Uploads"
    });

    viewTransfers = () => ({
        id: "viewTransfers",
        label: "View Transfers"
    });

    buildImpersonateUserOption = () => ({
        id: "impersonate",
        label: "Impersonate"
    });

    buildResendRealtorAgreementOption = () => ({
        id: "resendRealtorAgreement",
        label: "Resend Realtor Agreement"
    });

    buildResendBrokerAgreementOption = () => ({
        id: "resendBrokerAgreement",
        label: "Resend Broker Agreement"
    });

    buildSuspendRealtorOption = approved => {
        const suspendLabel = approved ? "Suspend" : "Approve";
        return {
            id: "suspend",
            label: suspendLabel
        };
    };

    isApproved = user =>
        this.isPublicProfilePrefix(user)
            ? get(user, "publicProfile.approved")
            : get(user, "userInfo.active");

    getUserId = user =>
        this.isPublicProfilePrefix(user)
            ? get(user, "publicProfile.userInfo.id")
            : get(user, "userInfo.id");

    getEmailVerified = user =>
        this.isPublicProfilePrefix(user)
            ? get(user, "publicProfile.userInfo.isEmailVerified")
            : get(user, "userInfo.isEmailVerified");

    isUserRealtor = user =>
        Boolean(get(user, "publicProfile.userInfo.realtor"));

    isUserProspect = user =>
        Boolean(get(user, "publicProfile.userInfo.prospect"));

    isUserBuyer = user => Boolean(get(user, "publicProfile.userInfo.buyer"));

    isUserSeller = user => Boolean(get(user, "publicProfile.userInfo.seller"));

    isPublicProfilePrefix = user =>
        this.isUserProspect(user) ||
        this.isUserBuyer(user) ||
        this.isUserRealtor(user) ||
        this.isUserSeller(user);

    buildActionOptionsForUser = user => {
        const impersonateOption = this.buildImpersonateUserOption();
        const approved = this.isApproved(user);
        const isVerified = this.getEmailVerified(user);

        if (this.isUserRealtor(user)) {
            const resendRealtorAgreementOption = this.buildResendRealtorAgreementOption();
            const resendBrokerAgreementOption = this.buildResendBrokerAgreementOption();
            const buildSuspendRealtorOption = this.buildSuspendRealtorOption(
                approved
            );

            if (isVerified) {
                return [
                    impersonateOption,
                    resendRealtorAgreementOption,
                    resendBrokerAgreementOption,
                    buildSuspendRealtorOption
                ];
            }

            return [
                resendRealtorAgreementOption,
                resendBrokerAgreementOption,
                buildSuspendRealtorOption
            ];
        }

        if (this.isUserProspect(user)) {
            const viewUploadsLink = this.viewUploadsLink();
            const viewTransfers = this.viewTransfers();

            if (isVerified) {
                return [impersonateOption, viewUploadsLink, viewTransfers];
            }

            return [viewUploadsLink, viewTransfers];
        }

        if (this.isUserSeller(user)) {
            return [impersonateOption];
        }

        if (isVerified) {
            return [impersonateOption];
        }

        return [];
    };

    buildDropdownActionOptionsForUser = user => {
        const options = this.buildActionOptionsForUser(user);

        return map(options, option => ({
            label: option.label,
            value: option.id
        }));
    };

    getSortByOptions = () =>
        map(SELECT_SORT_BY_OPTION[this.props.filterBy], type => ({
            label: get(type, "displayName"),
            value: { field: get(type, "field"), order: get(type, "order") }
        }));

    impersonateUserByType = userId => {
        const { roles, filterBy } = this.props;
        const roleBrewcrew = find(roles, { name: SELECT_ROLE_ALL });
        const role = roleBrewcrew ? get(roleBrewcrew, "name") : filterBy;

        switch (role) {
            case "ROLE_REALTOR":
                if (isFunction(this.props.onImpersonateUserByRole)) {
                    this.props.onImpersonateUserByRole(userId, "realtor");
                }
                break;
            case "ROLE_SELLER":
                if (isFunction(this.props.onImpersonateUserByRole)) {
                    this.props.onImpersonateUserByRole(userId, "seller");
                }
                break;
            case "ROLE_BUYER":
                if (isFunction(this.props.onImpersonateUserByRole)) {
                    this.props.onImpersonateUserByRole(userId, "buyer");
                }
                break;
            case "ROLE_PROSPECT":
                if (isFunction(this.props.onImpersonateUserByRole)) {
                    this.props.onImpersonateUserByRole(userId, "prospect");
                }
                break;
            case SELECT_ROLE_ALL:
                if (isFunction(this.props.isVisibleViewTransfers)) {
                    this.props.isVisibleViewTransfers(userId);
                }
                break;
            default:
                break;
        }
    };

    onChangeActionOption = (user, option) => {
        const userId = this.getUserId(user);
        const newApproved = !this.isApproved(user);
        const selectedOptionId = get(option, "value");
        const impersonateOptionId = this.buildImpersonateUserOption().id;
        const resendRealtorAgreementOptionId = this.buildResendRealtorAgreementOption()
            .id;
        const resendBrokerAgreementOptionId = this.buildResendBrokerAgreementOption()
            .id;
        const buildSuspendRealtorOptionId = this.buildSuspendRealtorOption().id;
        const viewUploadsLink = this.viewUploadsLink().id;
        const viewTransfers = this.viewTransfers().id;

        switch (selectedOptionId) {
            case impersonateOptionId:
                this.impersonateUserByType(userId);
                break;
            case resendRealtorAgreementOptionId:
                if (isFunction(this.props.onResendRealtorAgreement)) {
                    this.props.onResendRealtorAgreement(userId);
                }
                break;
            case resendBrokerAgreementOptionId:
                if (isFunction(this.props.onResendBrokerAgreement)) {
                    this.props.onResendBrokerAgreement(userId);
                }
                break;
            case buildSuspendRealtorOptionId:
                if (isFunction(this.props.onSuspendUser)) {
                    this.props.onSuspendUser(userId, newApproved);
                }
                break;
            case viewUploadsLink:
                this.openProspect(userId);
                break;
            case viewTransfers:
                if (!isEmpty(get(user, "earnestMoneyDeposits"))) {
                    this.onOpenViewTransfers(get(user, "earnestMoneyDeposits"));
                }
                break;
            default:
                break;
        }
    };

    buildActionButton = user => {
        return (
            <AutoCompleteInput
                placeholder="..."
                onChange={option => this.onChangeActionOption(user, option)}
                getOptions={() => this.buildDropdownActionOptionsForUser(user)}
                initializeGettingOptions
                isSearchable={false}
                isOptionsOnTop
                isDots
                value=" "
            />
        );
    };

    hasMore = () => {
        const page = get(this.props.users, "page");
        const totalPages = get(this.props.users, "totalPages");
        return totalPages > page + 1;
    };

    buildSearchResultTable = () => {
        const { filterBy, users } = this.props;
        const RoleTable = get(roleOptionTable, filterBy);
        return (
            <div className="admin-profile__table-container">
                <div className="admin-profile__total-users">
                    {`Total Users: ${get(users, "totalUserCount")}`}
                </div>
                <InfiniteScroll
                    loadMore={this.props.loadMore}
                    pageStart={400}
                    threshold={900}
                    loader={<SpinnerLoader />}
                    hasMore={this.hasMore()}
                    initialLoad={false}
                >
                    <RoleTable
                        dateFormat={this.props.dateFormat}
                        users={this.props.usersCache}
                        buildActionButton={this.buildActionButton}
                        viewUploadsLink={this.viewUploadsLink}
                        viewTransfers={this.props.viewTransfers}
                        refreshPage={this.props.refreshPage}
                        onClickMoreInfo={this.props.onClickMoreInfo}
                        onCompleteTransfer={this.props.completeTransferReceipt}
                        isFetchingTransferReceipt={
                            this.props.isFetchingTransferReceipt
                        }
                        successTransferReceipt={
                            this.props.successTransferReceipt
                        }
                        isVisibleViewTransfers={
                            this.state.isVisibleViewTransfers
                        }
                        onCloseViewTransfers={this.onCloseViewTransfers}
                        earnestMoneyDeposits={this.state.earnestMoneyDeposits}
                    />
                    {this.buildFooterResult()}
                </InfiniteScroll>
            </div>
        );
    };

    getNoResultsMessage = () => (
        <div className="admin-profile__no-results-message">No users found</div>
    );

    getNoMoreResultsMessage = () => (
        <div className="admin-profile__no-results-message">
            No more users found
        </div>
    );

    buildFooterResult = () => {
        const { usersCache } = this.props;
        const hasMoreUsers = this.hasMore();
        const isEmptyUsers = isEmpty(usersCache);
        return (
            <>
                {this.props.isFetchingGetUsers ? <SpinnerLoader /> : null}
                {!this.props.isFetchingGetUsers && !hasMoreUsers && isEmptyUsers
                    ? this.getNoResultsMessage()
                    : null}
                {!this.props.isFetchingGetUsers &&
                !hasMoreUsers &&
                !isEmptyUsers
                    ? this.getNoMoreResultsMessage()
                    : null}
            </>
        );
    };

    buildSearchResultsBlock = () => {
        const { usersCache, showLoading } = this.props;

        if (
            (this.props.isFetchingGetUsers && isEmpty(usersCache)) ||
            showLoading
        ) {
            return <SpinnerLoader />;
        }

        if (!this.props.isFetchingGetUsers && isEmpty(usersCache)) {
            return this.getNoResultsMessage();
        }

        return this.buildSearchResultTable();
    };

    buildSelectedOption = (sortBy, sortOrder) => {
        const options = SELECT_SORT_BY_OPTION[this.props.filterBy];
        const displayName = find(
            options,
            item => item.field === sortBy && item.order === sortOrder
        );
        return isEmpty(displayName)
            ? null
            : Object.assign({
                  label: get(displayName, "displayName"),
                  value: { field: sortBy, order: sortOrder }
              });
    };

    buildSelectedStateOption = () => {
        const selectedState = find(
            this.props.licensedStates,
            item => get(item, "abbreviation") === this.props.sortByState
        );

        return isEmpty(selectedState)
            ? null
            : Object.assign({
                  label: get(selectedState, "name"),
                  value: get(selectedState, "abbreviation")
              });
    };

    buildApprovedOptions = () => {
        const { onChangeApproved, approved, filterBy } = this.props;
        if (filterBy !== "ROLE_REALTOR") return;

        return (
            <div className="admin-profile__options-approve">
                {map(BREWCREW_APPROVE_OPTIONS, (option, index) => {
                    const displayName = get(option, "displayName");
                    const approvedOption = get(option, "approved");
                    const activeClass =
                        approvedOption === approved ? "--active" : "";

                    return (
                        <div
                            className="admin-profile__option-approve"
                            key={index}
                        >
                            <button
                                className={`admin-profile__option-button${activeClass}`}
                                onClick={() => onChangeApproved(approvedOption)}
                            >
                                {displayName}
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    };

    getStateOptions = () => {
        const states = map(this.props.licensedStates, option => ({
            label: option.name,
            value: option.abbreviation
        }));

        return concat({ label: "All States", value: undefined }, states);
    };

    render = () => {
        const { filterBy } = this.props;
        const { term, sortBy, sortOrder } = this.props;
        return (
            <div>
                <ProspectUploadedFilesPreviewModal
                    isVisible={this.state.showProspectModal}
                    isFetchingProspectDocuments={
                        this.state.isFetchingProspectDocuments
                    }
                    documents={this.state.prospectDocuments}
                    onClose={this.closeProspect}
                    onOptionChange={this.handleOptionChange}
                    selectedExtensionType={this.state.selectedOption}
                    searchTermUsed={this.state.term}
                    users={this.props.users}
                    roles={this.props.roles}
                />
                <BrewBoardDownloadModal
                    isVisible={this.state.showModal}
                    onClose={this.close}
                    handleDownloadLink={this.handleDownload()}
                    onOptionChange={this.handleOptionChange}
                    selectedExtensionType={this.state.selectedOption}
                    searchTermUsed={this.state.term}
                />
                <div className="admin-profile__container-results">
                    <div className="admin-profile__options-content-box">
                        <div className="admin-profile__options-content">
                            <div className="admin-profile__options-content-left">
                                {filterBy === "ROLE_REALTOR" ? (
                                    <div className="admin-profile__options-state">
                                        <div className="admin-profile__options-state-sort-by">
                                            <AutoCompleteInput
                                                placeholder="All states"
                                                onChange={
                                                    this.props.toggleStateBy
                                                }
                                                getOptions={
                                                    this.getStateOptions
                                                }
                                                value={this.buildSelectedStateOption()}
                                                initializeGettingOptions
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                <div className="admin-profile__options">
                                    <i className="fa fa-search" />
                                    <input
                                        type="text"
                                        className="form-control admin-profile__options__input"
                                        value={term}
                                        onChange={this.props.onChangeTerm}
                                        placeholder="Search ..."
                                    />
                                </div>
                            </div>
                            <div className="admin-profile__options-sort">
                                <div className="admin-profile__options-sort-by">
                                    <AutoCompleteInput
                                        placeholder="Sort By..."
                                        onChange={this.props.toggleOrderBy}
                                        getOptions={this.getSortByOptions}
                                        value={this.buildSelectedOption(
                                            sortBy,
                                            sortOrder
                                        )}
                                        initializeGettingOptions
                                    />
                                </div>
                                {filterBy === "ROLE_PROSPECT" ? (
                                    <div>
                                        <ProspectUploadDocumentButton />
                                    </div>
                                ) : null}
                                <div
                                    className="lemonbrew-download--csv"
                                    onClick={this.open}
                                >
                                    Export
                                </div>
                            </div>
                        </div>
                        <div className="admin-profile__options-content">
                            {this.buildApprovedOptions()}
                        </div>
                    </div>
                    {this.buildSearchResultsBlock()}
                </div>
            </div>
        );
    };
}

UserAdminProfileResults.propTypes = {
    term: PropTypes.string,
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,
    sortByState: PropTypes.string,
    dateFormat: PropTypes.string,
    filterBy: PropTypes.string,
    showLoading: PropTypes.bool,
    showModal: PropTypes.bool,
    showProspectModal: PropTypes.bool,
    selectedOption: PropTypes.string,
    close: PropTypes.func,
    users: PropTypes.object,
    isFetchingGetUsers: PropTypes.bool,
    onImpersonateUser: PropTypes.func,
    onImpersonateUserByRole: PropTypes.func,
    onResendRealtorAgreement: PropTypes.func,
    onResendBrokerAgreement: PropTypes.func,
    onSuspendUser: PropTypes.func,
    onChangeTerm: PropTypes.func,
    toggleOrderBy: PropTypes.func,
    toggleStateBy: PropTypes.func,
    refreshPage: PropTypes.func,
    loadMore: PropTypes.func,
    onClickMoreInfo: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    approved: PropTypes.string,
    onChangeApproved: PropTypes.func,
    viewUploadsLink: PropTypes.func,
    viewTransfers: PropTypes.func,
    completeTransferReceipt: PropTypes.func,
    isFetchingTransferReceipt: PropTypes.bool,
    successTransferReceipt: PropTypes.bool,
    licensedStates: PropTypes.object
};
