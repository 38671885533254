import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import EarnestMoneyDepositAccountCard from '../EarnestMoneyDepositAccountCard/EarnestMoneyDepositAccountCard';

export default class EarnestMoneyDepositAccountCardFormikField extends React.PureComponent {
    getFieldName = () => get(this.props.field, 'name');

    getFieldAmountValue = () => get(this.props.field, 'value.amount');

    onChange = (e) => {
        const { account } = this.props;
        const fieldName = this.getFieldName();
        const newAmountValue = e;
        const newFieldValue = {
            bankAccount: account,
            amount: newAmountValue,
        };

        invoke(this.props.form, 'setFieldValue', fieldName, newFieldValue);
        invoke(this.props, 'onChange', newFieldValue);
    };

    onBlur = (e) => {
        invoke(this.props.form, 'setFieldTouched', 'achs', true);
    }

    buildAccountProperty = () => ({
        id: get(this.props.account, 'institution.id'),
        icon: get(this.props.account, 'institution.logoUrl'),
        name: get(this.props.account, 'institution.name'),
        number: get(this.props.account, 'maskedBankAccountNumber'),
        balance: {
            amount: get(this.props.account, 'balance'),
            currency: get(this.props.account, 'currency'),
        },
        type: get(this.props.account, 'type.name'),
    });

    render = () => (
        <EarnestMoneyDepositAccountCard
            {...this.props}
            account={this.buildAccountProperty()}
            amount={this.getFieldAmountValue()}
            onChangeAmount={this.onChange}
            onBlur={this.onBlur}
        />
    );
}

EarnestMoneyDepositAccountCardFormikField.propTypes = {
    account: PropTypes.shape({
        balance: PropTypes.number,
        currency: PropTypes.string,
        balanceDate: PropTypes.string,
        institution: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            iconUrl: PropTypes.string,
        }),
        maskedBankAccountNumber: PropTypes.string,
        type: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    }),
    form: PropTypes.shape({
        setFieldValue: PropTypes.func,
        setFieldTouched: PropTypes.func,
    }),
    field: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.object,
    }),
    onChange: PropTypes.func,
};
