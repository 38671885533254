import React, { Fragment, useEffect } from 'react';
import {Link} from 'react-router-dom';
import IconHouse from '../../../styles/assets/buy-icon.svg';
import IconSale from '../../../styles/assets/sale-icon.svg';

import './index.css';
import Tracking from '../../../util/tracking';

const BuyerSeller = () => {
    useEffect(Tracking.trackView, []);

    return (
        <Fragment>
            <div className="content-fluid get-started-bcg"></div>
            <div className="content-fluid get-started">
                <h1>What would you like to do today?</h1>
                <Link className="get-started__seller-container"  to="/signup/seller">
                    <div className="get-started__content">
                        <div className="get-started__icon">
                            <img className="get-started__icon--size" src={IconSale} alt="IconSale" />
                        </div>
                        <div className="get-started__content--inner">
                            <h3 className="get-started__header">I’m Thinking of Selling</h3>
                            <span className="get-started__informative-text get-started__informative-text--seller">
                                {`Match with the perfect agent and pay a reduced listing fee - save up to 2%`}
                            </span>
                        </div>
                    </div>
                </Link>
                <Link className="get-started__buyer-container"  to="/signup/buyer">
                    <div className="get-started__content">
                        <div className="get-started__icon">
                            <img className="get-started__icon--size" src={IconHouse} alt="IconHouse" />
                        </div>
                        <div className="get-started__content--inner">
                            <h3 className="get-started__header">I’m Thinking of Buying</h3>
                            <span className="get-started__informative-text">
                                {`Match with the perfect agent and get a buyer commission rebate at closing`}
                            </span>
                        </div>
                    </div>
                </Link>
            </div>
        </Fragment>
    );
};

export default BuyerSeller;
