import { combineReducers } from 'redux';
import globalLoaderReducer from './global-loader/GlobalLoaderReducer';
import signUpReducer from './signUp/signUpReducer';
import userReducer from './user/UserReducer';
import configAppReducer from './config/ConfigReducer';
import adminReducer from './admin/AdminReducer';
import spinReducer from './spin/SpinReducer';
import prospectReducer from './prospect/prospectReducer';
import propertyProfile from './PropertyProfile/PropertyProfileReducer'
import realtorProfile from './RealtorProfile/RealtorProfileReducer'

const rootReducer = combineReducers({
    globalLoader: globalLoaderReducer,
    signUp: signUpReducer,
    user: userReducer,
    configApp: configAppReducer,
    admin: adminReducer,
    spin: spinReducer,
    prospect: prospectReducer,
    propertyProfile,
    realtorProfile
});

export default rootReducer;
