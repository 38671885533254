import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import map from "lodash/map";
import includes from "lodash/includes";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import EmdLandingPage, {
    ACCOUNTS_TAB,
    PROFILE_TAB
} from "../EmdLandingPage/EmdLandingPage";
import DepositFlow from "../ProspectDeposit/ProspectDeposit";
import { loadUserProfile } from "../../reducers/user/UserActions";
import {
    fetchVoaProspectUrl,
    getFundingSourcesAndReloadProfile,
    earnestMoneyDepositCancel,
    resetDeposit,
    editProspectInfo,
    sendEmails
} from "../../reducers/prospect/prospectActions";
import { MID_USER, READ_ONLY_USER } from "../../shared/constants";

import mixpanel from "mixpanel-browser";

class EmdRoute extends React.Component {
    getProfile = () => get(this.props.user, "profile");

    onChangeTab = tab => {
        this.props.history.push(`${this.props.match.path}/${tab}`);
    };

    componentDidMount = () => {
        const { user, history } = this.props;
        const isProfileFetched = get(user, "isProfileFetched");
        const isNotEmd = !get(user, "profile.publicProfile.userInfo.prospect");
        mixpanel.init(process.env.REACT_APP_MIXPANEL_PROSPECT_TOKEN);

        if (isProfileFetched && isNotEmd) {
            history.replace("/");
        }
    };

    getIsImpersonatingUser = () =>
        get(
            this.props.user,
            "profile.publicProfile.userInfo.isBeingImpersonated"
        );

    getRoleNames = () =>
        map(
            get(
                this.props.user,
                "cacheAdminProfile.publicProfile.userInfo.roles"
            ),
            "name"
        );

    getIsMidUserRole = () => includes(this.getRoleNames(), MID_USER);

    getIsReadOnlyUserRole = () => includes(this.getRoleNames(), READ_ONLY_USER);

    getVerificationDocumentTypes = () =>
        get(
            this.props.documentTypes,
            "lemonbrew_prospect_earnest_money_deposit"
        );

    onSendEmails = (earnestMoneyDepositId, data) =>
        this.props.onSendEmails(earnestMoneyDepositId, data);

    render = () => {
        const { configApp } = this.props;
        const earnestMoneyDepositDestinationAccount = get(
            configApp.config,
            "earnestMoneyDepositDestinationAccount"
        );

        return (
            <Switch>
                <Route
                    path={`${this.props.match.path}/deposit`}
                    render={() => <DepositFlow history={this.props.history} />}
                />
                <Route
                    path={`${this.props.match.path}/${ACCOUNTS_TAB}`}
                    render={() => (
                        <EmdLandingPage
                            user={this.props.user}
                            prospect={this.props.prospect}
                            verificationDocumentTypes={this.getVerificationDocumentTypes()}
                            getFundingSourcesAndReloadProfile={
                                this.props.onFetchFundingSourcesAndReloadProfile
                            }
                            loadUserProfile={this.props.onLoadUserProfile}
                            fetchVoaUrl={this.props.onFetchVoaProspectUrl}
                            earnestMoneyDepositCancel={
                                this.props.onEarnestMoneyDepositCancel
                            }
                            resetDeposit={this.props.onResetDeposit}
                            earnestMoneyDepositDestinationAccount={
                                earnestMoneyDepositDestinationAccount
                            }
                            tab={ACCOUNTS_TAB}
                            onChangeTab={this.onChangeTab}
                            sendEmails={this.onSendEmails}
                            successSendEmails={this.props.successSendEmails}
                            isFetchingSendEmails={
                                this.props.isFetchingSendEmails
                            }
                            isMidUserRole={this.getIsMidUserRole()}
                            isReadOnlyUserRole={this.getIsReadOnlyUserRole()}
                            isImpersonatingUser={this.getIsImpersonatingUser()}
                        />
                    )}
                />
                <Route
                    path={`${this.props.match.path}/${PROFILE_TAB}`}
                    render={() => (
                        <EmdLandingPage
                            user={this.props.user}
                            prospect={this.props.prospect}
                            editProspectInfo={this.props.onEditProspectInfo}
                            tab={PROFILE_TAB}
                            onChangeTab={this.onChangeTab}
                            loadUserProfile={this.props.onLoadUserProfile}
                            isMidUserRole={this.getIsMidUserRole()}
                            isReadOnlyUserRole={this.getIsReadOnlyUserRole()}
                            isImpersonatingUser={this.getIsImpersonatingUser()}
                        />
                    )}
                />
                <Route
                    render={() => (
                        <Redirect
                            to={`${this.props.match.path}/${ACCOUNTS_TAB}`}
                        />
                    )}
                />
            </Switch>
        );
    };
}

EmdRoute.propTypes = {
    user: PropTypes.object,
    history: PropTypes.any,
    match: PropTypes.any,
    configApp: PropTypes.object,
    documentTypes: PropTypes.object,
    prospect: PropTypes.object,
    onLoadUserProfile: PropTypes.func,
    onFetchVoaProspectUrl: PropTypes.func,
    onFetchFundingSourcesAndReloadProfile: PropTypes.func,
    onEarnestMoneyDepositCancel: PropTypes.func,
    onResetDeposit: PropTypes.func,
    onEditProspectInfo: PropTypes.func,
    onSendEmails: PropTypes.func,
    successSendEmails: PropTypes.bool,
    isFetchingSendEmails: PropTypes.bool,
    earnestMoneyDepositId: PropTypes.any
};

export default withRouter(
    connect(
        state => ({
            user: state.user,
            documentTypes: get(state.configApp, "config.documentTypes"),
            prospect: state.prospect,
            configApp: state.configApp,
            successSendEmails: get(state.prospect, "successSendEmails"),
            isFetchingSendEmails: get(state.prospect, "isFetchingSendEmails")
        }),
        {
            onLoadUserProfile: loadUserProfile,
            onFetchVoaProspectUrl: fetchVoaProspectUrl,
            onFetchFundingSourcesAndReloadProfile: getFundingSourcesAndReloadProfile,
            onEarnestMoneyDepositCancel: earnestMoneyDepositCancel,
            onResetDeposit: resetDeposit,
            onEditProspectInfo: editProspectInfo,
            onSendEmails: sendEmails
        }
    )(EmdRoute)
);
