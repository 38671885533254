import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SessionExpiryNotification from '../SessionExpiryNotification/SessionExpiryNotification';

class TimeoutSessionExpiryNotificationComponent extends React.Component {
    static propTypes = {
        isLoggedIn: PropTypes.bool,
        millisecondsToShowNotification: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.timeout = null;
        this.activityEvents = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress',
        ];

        this.state = { isVisible: false };
    }

    componentDidMount = () => {
        this.startInactivityTimer();
        this.listenForActivity();
    };

    componentWillUnmount = () => {
        this.destroyInactivityTimer();
        this.stopListeningForActivity();
    };

    resetInactivityTimer = () => {
        this.destroyInactivityTimer();
        this.startInactivityTimer();
    };

    startInactivityTimer = () => {
        this.timeout = setTimeout(
            this.onShowNotification,
            this.props.millisecondsToShowNotification,
        );
    };

    destroyInactivityTimer = () => {
        clearTimeout(this.timeout);
    };

    listenForActivity = () => {
        this.activityEvents.forEach(
            (event) => window.addEventListener(event, this.resetInactivityTimer),
        );
    };

    stopListeningForActivity = () => {
        this.activityEvents.forEach(
            (event) => window.removeEventListener(event, this.resetInactivityTimer),
        );
    };

    onShowNotification = () => {
        if (this.props.isLoggedIn) {
            this.destroyInactivityTimer();
            this.stopListeningForActivity();
            this.setState({ isVisible: true });
        } else {
            this.resetInactivityTimer();
        }
    };

    onCloseNotification = () => {
        this.startInactivityTimer();
        this.listenForActivity();
        this.setState({ isVisible: false });
    };

    render = () => (
        <SessionExpiryNotification
            isVisible={this.state.isVisible}
            onClose={this.onCloseNotification}
        />
    );
}

const TimeoutSessionExpiryNotification = connect(({ user }) => ({
    isLoggedIn: user.authorization,
}))(TimeoutSessionExpiryNotificationComponent);

export default TimeoutSessionExpiryNotification;
