import reject from "lodash/reject";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import {
    INVALIDATE_USER,
    RECEIVE_USER_AUTH_ERROR,
    RECEIVE_USER_PROFILE,
    SUCCESSFULLY_ACCEPTED_MATCH,
    UPDATE_BUYER_STATUS,
    SUCCESSFULLY_UPDATE_BUYER_STATUS,
    RECEIVE_UPDATE_BUYER_STATUS_ERROR,
    FETCH_UPLOAD_REALTOR_FILE_ATTACHMENT,
    SUCCESSFULLY_UPLOAD_REALTOR_FILE_ATTACHMENT,
    SUCCESSFULLY_REMOVE_REALTOR_FILE_ATTACHMENT,
    UPLOAD_REALTOR_FILE_ATTACHMENT_ERROR,
    SUCCESSFULLY_REJECTED_MATCH,
    FETCH_ACTIVE_MATCHES_ERROR,
    SUCCESSFULLY_FETCH_ACTIVE_MATCHES,
    FETCH_SIGNUP_USER,
    SUCCESSFULLY_SIGNUP_USER,
    ERROR_SIGNUP_USER,
    DISPLAY_NEW_ACTIVE_MATCH_MODAL,
    HIDE_NEW_ACTIVE_MATCH_MODAL,
    SUCCESSFULLY_COMPLETED_REALTOR_TOUR,
    FETCH_BREW_SCORE_USER,
    SUCCESS_BREW_SCORE_USER,
    FETCH_SEARCH_AGENTS_USER,
    SUCCESS_SEARCH_AGENTS_USER,
    FETCH_SEARCH_SELLER_AGENTS_USER,
    SUCCESS_SEARCH_SELLER_AGENTS_USER,
    FETCH_CONTACT_REBATE,
    SUCCESS_CONTACT_REBATE,
    REMOVE_REALTOR_FILE_ATTACHMENT
} from "./UserActionTypes";
import {
    LOGOUT_IMPERSONATE_SUCCESS,
    RECEIVE_IMPERSONATE_USER_PROFILE,
    FETCH_IMPERSONATE_USER_PROFILE
} from "../admin/AdminActionTypes";
import RealtorSellerMatchReducer from "./realtorSellerMatch/RealtorSellerMatchReducer";

const initialState = {
    isProfileFetched: false,
    isFetchingProfile: false,
    isFetchingUploadFile: false,
    hasCompletedRemovingFile: false,
    hasCompletedUpdatingBuyerStatus: false,
    isFetchedUploadFile: false,
    authorization: false,
    profile: {},
    cacheAdminProfile: {},
    isFetchingImpersonateUserProfile: false,
    isImpersonatingUser: false,
    errors: "",
    isFetchingSignUpUser: false,
    isFetchedSignUpUser: false,
    errorSignUpMessage: "",
    displayNewActiveMatchModal: false,
    getBrewScore: {
        brewScore: {},
        isFetchingBrewScore: false,
        successBrewScore: false
    },
    getSearchAgents: {
        searchAgents: {},
        isFetchingSearchAgents: false,
        successSearchAgents: false
    },
    getSearchSellerAgents: {
        searchAgents: {},
        isFetchingSearchAgents: false,
        successSearchAgents: false
    },
    isFetchingContactRebate: false,
    successContactRebate: false
};

const getBuyersFromState = state => get(state, "profile.buyers") || [];

const getMatchesFromState = state =>
    get(state, "profile.buyerMatchRequests") || [];

const updatePendingMatches = (state, acceptedMatch) => {
    const newState = cloneDeep(state);
    const matches = getMatchesFromState(newState);
    const matchesWithoutAcceptedMatch = reject(matches, {
        userInfo: { id: acceptedMatch }
    });

    newState.profile = newState.profile || {};
    newState.profile.buyerMatchRequests = matchesWithoutAcceptedMatch;

    return newState;
};

const rejectMatch = (state, rejectedMatchId) => {
    const newState = cloneDeep(state);
    const matches = getMatchesFromState(newState);
    const matchesWithoutRejectedMatch = reject(matches, {
        userInfo: { id: rejectedMatchId }
    });

    newState.profile = newState.profile || {};
    newState.profile.buyerMatchRequests = matchesWithoutRejectedMatch;

    return newState;
};

const updateBuyerStatus = (state, buyerWithNewStatus) => {
    const newState = cloneDeep({
        ...state,
        hasCompletedUpdatingBuyerStatus: true
    });
    const buyers = getBuyersFromState(newState);
    const buyerWithNewStatusId = get(buyerWithNewStatus, "realtorMatchId");

    newState.profile = newState.profile || {};
    newState.profile.buyers = map(buyers, buyer => {
        const buyerId = get(buyer, "realtorMatchId");

        if (buyerId === buyerWithNewStatusId) {
            return {
                ...buyer,
                currentStatus: get(buyerWithNewStatus, "currentStatus"),
                transactionContacts: get(
                    buyerWithNewStatus,
                    "transactionContacts"
                )
            };
        }

        return buyer;
    });

    return newState;
};

const buildBuyerWithNewAttachment = (buyer, attachment) => {
    const updatedBuyer = cloneDeep(buyer);

    const documents = updatedBuyer.documents || [];
    updatedBuyer.documents = [attachment, ...documents];

    return updatedBuyer;
};

const buildBuyerWithoutAttachment = (buyer, fileId) => {
    const updatedBuyer = cloneDeep(buyer);

    const documents = updatedBuyer.documents || [];
    updatedBuyer.documents = reject(documents, { id: fileId });

    return updatedBuyer;
};

const uploadAttachmentFiles = (state, buyerIdWithNewAttachment, attachment) => {
    const newState = merge(cloneDeep(state), {
        isFetchingUploadFile: false,
        isFetchedUploadFile: true
    });
    const buyers = getBuyersFromState(newState);

    newState.profile = newState.profile || {};
    newState.profile.buyers = map(buyers, buyer => {
        const buyerId = get(buyer, "realtorMatchId");

        if (buyerId === buyerIdWithNewAttachment) {
            return buildBuyerWithNewAttachment(buyer, attachment);
        }

        return buyer;
    });

    return newState;
};

const includeBuyer = (buyers, buyerMatchID) => {
    let result = false;
    map(buyers, buyer => {
        const buyerId = get(buyer, "buyer.userInfo.id");
        if (buyerId === buyerMatchID) {
            result = true;
        }
    });
    return result;
};

const buildActiveMatches = (state, response) => {
    const newState = cloneDeep(state);
    newState.profile.buyers = response.activeMatches;
    const buyerMatchRequests = state.profile.buyerMatchRequests.filter(
        buyerRequest => {
            const buyerId = get(buyerRequest, "userInfo.id");
            if (!includeBuyer(response.activeMatches, buyerId)) {
                return true;
            }
            return false;
        }
    );
    newState.profile.buyerMatchRequests = buyerMatchRequests;
    return newState;
};

const removeAttachmentFile = (state, realtorMatchId, fileId) => {
    const newState = cloneDeep({ ...state, hasCompletedRemovingFile: true });
    const buyers = getBuyersFromState(newState);

    newState.profile = newState.profile || {};
    newState.profile.buyers = map(buyers, buyer => {
        const buyerId = get(buyer, "realtorMatchId");

        if (buyerId === realtorMatchId) {
            return buildBuyerWithoutAttachment(buyer, fileId);
        }

        return buyer;
    });

    return newState;
};

const successfullyCompletedRealtorTour = state => {
    const newState = cloneDeep(state);
    set(
        newState,
        "profile.publicProfile.userInfo.hasCompletedOnboarding",
        true
    );
    return newState;
};

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case RECEIVE_USER_AUTH_ERROR:
            return {
                ...initialState,
                errors: payload.errors
            };
        case RECEIVE_USER_PROFILE: {
            const adminProfile = state.cacheAdminProfile;
            const impersonatingUser = state.isImpersonatingUser;
            return {
                ...initialState,
                cacheAdminProfile: adminProfile,
                isImpersonatingUser: impersonatingUser,
                profile: payload.profile,
                authorization: true,
                isProfileFetched: true
            };
        }
        case INVALIDATE_USER:
            return initialState;
        case SUCCESSFULLY_ACCEPTED_MATCH:
            return updatePendingMatches(state, payload.match);
        case SUCCESSFULLY_REJECTED_MATCH:
            return rejectMatch(state, payload.matchId);
        case SUCCESSFULLY_UPDATE_BUYER_STATUS:
            return updateBuyerStatus(state, payload.buyerData);
        case UPDATE_BUYER_STATUS:
            return {
                ...state,
                hasCompletedUpdatingBuyerStatus: false
            };
        case FETCH_SIGNUP_USER:
            return {
                ...state,
                isFetchingSignUpUser: true,
                isFetchedSignUpUser: false
            };
        case SUCCESSFULLY_SIGNUP_USER:
            return {
                ...state,
                isFetchingSignUpUser: false,
                isFetchedSignUpUser: true
            };
        case ERROR_SIGNUP_USER:
            return {
                ...state,
                isFetchingSignUpUser: false,
                isFetchedSignUpUser: true,
                errorSignUpMessage: get(payload, "error.response.data.message")
            };
        case RECEIVE_UPDATE_BUYER_STATUS_ERROR:
            return {
                ...state,
                errors: payload.error
            };
        case FETCH_UPLOAD_REALTOR_FILE_ATTACHMENT:
            return {
                ...state,
                isFetchingUploadFile: true,
                isFetchedUploadFile: false
            };
        case SUCCESSFULLY_UPLOAD_REALTOR_FILE_ATTACHMENT:
            return uploadAttachmentFiles(
                state,
                payload.realtorMatchId,
                payload.attachment
            );
        case SUCCESSFULLY_REMOVE_REALTOR_FILE_ATTACHMENT:
            return removeAttachmentFile(
                state,
                payload.realtorMatchId,
                payload.fileId
            );
        case REMOVE_REALTOR_FILE_ATTACHMENT:
            return {
                ...state,
                hasCompletedRemovingFile: false
            };
        case UPLOAD_REALTOR_FILE_ATTACHMENT_ERROR:
            return {
                ...state,
                isFetchingUploadFile: false,
                errors: payload.error
            };
        case FETCH_ACTIVE_MATCHES_ERROR:
            return {
                ...state,
                errors: payload.error
            };
        case SUCCESSFULLY_FETCH_ACTIVE_MATCHES:
            return buildActiveMatches(state, payload);
        case FETCH_IMPERSONATE_USER_PROFILE:
            return {
                ...state,
                isFetchingImpersonateUserProfile: true
            };
        case RECEIVE_IMPERSONATE_USER_PROFILE: {
            const adminProfile = state.profile;
            return {
                ...state,
                profile: payload.profile,
                cacheAdminProfile: adminProfile,
                isFetchingImpersonateUserProfile: false,
                isImpersonatingUser: true
            };
        }
        case LOGOUT_IMPERSONATE_SUCCESS: {
            const adminProfile = state.cacheAdminProfile;
            return {
                ...state,
                profile: adminProfile,
                cacheAdminProfile: {},
                isProfileFetched: !isEmpty(adminProfile)
            };
        }
        case FETCH_BREW_SCORE_USER:
            return {
                ...state,
                getBrewScore: {
                    isFetchingBrewScore: true,
                    successBrewScore: false
                }
            };
        case SUCCESS_BREW_SCORE_USER:
            return {
                ...state,
                getBrewScore: {
                    isFetchingBrewScore: false,
                    successBrewScore: true,
                    brewScore: payload
                }
            };
        case FETCH_SEARCH_AGENTS_USER:
            return {
                ...state,
                getSearchAgents: {
                    isFetchingSearchAgents: true,
                    successSearchAgents: false
                }
            };
        case SUCCESS_SEARCH_AGENTS_USER:
            return {
                ...state,
                getSearchAgents: {
                    isFetchingSearchAgents: false,
                    successSearchAgents: true,
                    searchAgents: payload
                }
            };
        case FETCH_SEARCH_SELLER_AGENTS_USER:
            return {
                ...state,
                getSearchSellerAgents: {
                    isFetchingSearchAgents: true,
                    successSearchAgents: false
                }
            };
        case SUCCESS_SEARCH_SELLER_AGENTS_USER:
            return {
                ...state,
                getSearchSellerAgents: {
                    isFetchingSearchAgents: false,
                    successSearchAgents: true,
                    searchAgents: payload
                }
            };
        case FETCH_CONTACT_REBATE:
            return {
                ...state,
                isFetchingContactRebate: true,
                successContactRebate: false
            };
        case SUCCESS_CONTACT_REBATE:
            return {
                ...state,
                isFetchingContactRebate: false,
                successContactRebate: true
            };
        case DISPLAY_NEW_ACTIVE_MATCH_MODAL:
            return { ...state, displayNewActiveMatchModal: true };
        case HIDE_NEW_ACTIVE_MATCH_MODAL:
            return { ...state, displayNewActiveMatchModal: false };
        case SUCCESSFULLY_COMPLETED_REALTOR_TOUR:
            return successfullyCompletedRealtorTour(state);
        default:
            return state;
    }
};

export default (state, action) =>
    userReducer(
        {
            ...state,
            profile: RealtorSellerMatchReducer(get(state, "profile"), action)
        },
        action
    );
