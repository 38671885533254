import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import Switch from 'react-switch';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { updateRealtorProfile } from '../../util/service_api';

import './realtorProfileAvailableSwitch.css';

class RealtorProfileAvailableSwitch extends Component {
    static propTypes = {
        profile: PropTypes.any,
        onUpdateField: PropTypes.func.isRequired,
    };

    handleAvailableChange = () => {
        const { profile } = this.props;
        const availableField = 'available';
        const newAvailable = !get(profile, availableField);
        updateRealtorProfile({ available: newAvailable })
            .then(this.onSuccessfullyEdit);
    };

    onSuccessfullyEdit = () => {
        const { profile, onUpdateField } = this.props;
        const availableField = 'available';
        const newAvailable = !get(profile, availableField);
        if (isFunction(this.props.onUpdateField)) {
            onUpdateField(availableField, newAvailable);
        }
    }

    render = () => {
        const { profile } = this.props;
        const available = get(profile, 'available');
        const matchingTooltip = (
            <Tooltip id="realtor-profile-switch__tooltip">
                Use this switch to turn buyer lead generation on or off
            </Tooltip>
        );
        const availableText = available ? 'YES' : 'NO';

        return (
            <div className="realtor-profile-switch__switch-available">
                <div className="realtor-profile-switch__available">
                    Available
                    {' '}
                    <OverlayTrigger placement="top" overlay={matchingTooltip}>
                        <i className="fa fa-info-circle" />
                    </OverlayTrigger>
                </div>
                <div className="realtor-profile-switch__switch-container">
                    <Switch
                        className="realtor-profile-switch__switch"
                        onChange={this.handleAvailableChange}
                        checked={available}
                        height={20}
                        width={38}
                        uncheckedIcon={false}
                        checkedIcon={false}
                    />
                    <div className="realtor-profile-switch__switch-text">
                        {availableText}
                    </div>
                </div>
            </div>
        );
    };
}

export default RealtorProfileAvailableSwitch;
