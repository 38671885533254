import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import isFunction from "lodash/isFunction";
import includes from "lodash/includes";
import { Button } from "../Button/Button";
import { differenceInYears } from "date-fns";
import {
    months,
    REGEXP_ONLY_9_CHAR,
    ERROR_MESSAGE_EDIT_PROSPECT
} from "../../shared/constants";
import AutoCompleteAddressInput from "../AutoCompleteAddressInput/AutoCompleteAddressInput";
import { rangeOptions, getAddressFromPlaceDetails } from "../../util/util";
import mixpanel from "mixpanel-browser";

import "../EmdLandingPageProfile/emdLandingPageProfile.css";
import SsnToggleVisibilityField from "components/Form/SsnToggleVisibilityField";

export default class EmdLandingPageProfileEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        ssn: this.getProfileSSN(),
        firstName: this.getProfileFirstName(),
        lastName: this.getProfileLastName(),
        currentAddress: this.getProfileCurrentAddress(),
        birthMonth: this.getProfileBirthMonth(),
        birthDayOfMonth: this.getProfileBirthDayOfMonth(),
        birthYear: this.getProfileBirthYear(),
        apartmentSuiteNumber: this.getProfileApartment(),
        currentAddressDetails: {
            address: this.getProfileStreetNumberName(),
            neighborhood: this.getProfileNeighborhood(),
            city: this.getProfileCity(),
            state: this.getProfileState(),
            zipcode: this.getProfileZipcode()
        },
        isEditing: false,
        ssnError: null,
        firstNameError: null,
        lastNameError: null,
        currentAddressError: null,
        birthdayError: null,
        isSaving: false,
        isVisibleSsn: false
    });

    getProfileSSN = () => get(this.props.user, "profile.ssn");

    getProfileFirstName = () =>
        get(this.props.user, "profile.publicProfile.userInfo.firstName");

    getProfileLastName = () =>
        get(this.props.user, "profile.publicProfile.userInfo.lastName");

    getProfileCurrentAddress = () =>
        `${this.getProfileStreetNumberName()}, ${this.getProfileCity()}, ${this.getProfileState()}`;

    getProfileStreetNumberName = () =>
        get(this.props.user, "profile.currentAddress.streetNumberName");

    getProfileNeighborhood = () =>
        get(this.props.user, "profile.currentAddress.neighborhood");

    getProfileCity = () => get(this.props.user, "profile.currentAddress.city");

    getProfileState = () =>
        get(this.props.user, "profile.currentAddress.state");

    getProfileZipcode = () =>
        get(this.props.user, "profile.currentAddress.zipcode");

    getProfileApartment = () =>
        get(this.props.user, "profile.currentAddress.apartmentSuiteNumber");

    getProfileBirthMonth = () => get(this.props.user, "profile.birthMonth");

    getProfileBirthDayOfMonth = () =>
        get(this.props.user, "profile.birthDayOfMonth");

    getProfileBirthYear = () => get(this.props.user, "profile.birthYear");

    onEditProspectInfo = () => {
        const { editProspectInfo } = this.props;
        const ssnWithoutDash = this.state.ssn.replace(/-/g, "");
        const data = {
            ssn: ssnWithoutDash,
            userInfo: {
                firstName: this.state.firstName,
                lastName: this.state.lastName
            },
            currentAddress: {
                streetNumberName: this.state.currentAddressDetails.address,
                apartmentSuiteNumber: this.state.apartmentSuiteNumber,
                neighborhood: this.state.currentAddressDetails.neighborhood,
                city: this.state.currentAddressDetails.city,
                state: this.state.currentAddressDetails.state,
                zipcode: this.state.currentAddressDetails.zipcode
            },
            birthMonth: this.state.birthMonth,
            birthDayOfMonth: this.state.birthDayOfMonth,
            birthYear: this.state.birthYear
        };

        if (isFunction(editProspectInfo)) {
            this.setState({ isSaving: true });
            editProspectInfo(data).then(() => {
                mixpanel.track(`Clicked On Save Edit Profile`);
                const trackMixpanel = false;
                this.props.loadUserProfile().then(() => {
                    this.props.onCancelEdit(trackMixpanel);
                });
            });
        }
    };

    buildFooterButtons = () => (
        <div className="emd-landing-page__profile-footer-buttons">
            <Button
                small
                type="button"
                onClick={this.onEditProspectInfo}
                disabled={this.isDisableSaveButton() || this.state.isSaving}
                isLoading={this.state.isSaving}
                className=" emd-landing-page__profile-buttons"
            >
                Save
            </Button>
            <Button
                small
                inverted
                type="button"
                onClick={this.props.onCancelEdit}
                className=" emd-landing-page__profile-buttons"
            >
                Cancel
            </Button>
        </div>
    );

    onChangeFirstName = e =>
        this.setState({ firstName: e.target.value, firstNameError: null });

    onChangeLastName = e =>
        this.setState({ lastName: e.target.value, lastNameError: null });

    onChangeSSN = e => this.setState({ ssn: e, ssnError: null });

    onChangeApartment = e =>
        this.setState({ apartmentSuiteNumber: e.target.value });

    onChangeBirthMonth = e =>
        this.setState(
            { birthMonth: e.target.value, birthdayError: null },
            this.validateBirthday
        );

    onChangeBirthDayOfMonth = e =>
        this.setState(
            { birthDayOfMonth: e.target.value, birthdayError: null },
            this.validateBirthday
        );

    onChangeBirthYear = e =>
        this.setState(
            { birthYear: e.target.value, birthdayError: null },
            this.validateBirthday
        );

    onChangeCurrentAddress = e => {
        const hasErrorAddressField = false;
        this.setState({ currentAddress: e });
        if (!this.validateAddress(e)) {
            this.setState({
                hasErrorAddressField: !hasErrorAddressField,
                isEmptyAddressField: isEmpty(e)
            });
            return;
        }
    };

    buildErrorMessage = error => (
        <div className="emd-landing-page__password--error">
            <div className="emd-landing-page__field-error--content">
                <div className="emd-landing-page__field-error">{error}</div>
            </div>
        </div>
    );

    setErrorMessageFieldRequired = (field, value, message) => {
        const newValue = !value
            ? ERROR_MESSAGE_EDIT_PROSPECT[0](message)
            : null;
        this.setState({ [`${field}Error`]: newValue });
    };

    setErrorMessage = (field, type) => {
        const newValue = ERROR_MESSAGE_EDIT_PROSPECT[type];
        this.setState({ [`${field}Error`]: newValue });
    };

    validateErrorMessage = field => {
        const errorMessage = get(this.state, `${field}Error`);
        return errorMessage ? this.buildErrorMessage(errorMessage) : null;
    };

    buildFirstNameField = () => {
        const { firstName } = this.state;
        return (
            <div className="form-group emd-landing-page__container">
                <label
                    className="emd-landing-page__form-label--first-name"
                    htmlFor="firstNameLabel"
                >
                    <input
                        id="firstName"
                        type="text"
                        className="form-control emd-landing-page__form-field--first-name"
                        value={firstName}
                        placeholder="First Name"
                        onChange={this.onChangeFirstName}
                        onBlur={() =>
                            this.setErrorMessageFieldRequired(
                                "firstName",
                                firstName,
                                "First Name"
                            )
                        }
                    />
                </label>
                {this.validateErrorMessage("firstName")}
            </div>
        );
    };

    buildLastNameField = () => {
        const { lastName } = this.state;
        return (
            <div className="form-group emd-landing-page__container">
                <label
                    className="emd-landing-page__form-label--last-name"
                    htmlFor="lastNameLabel"
                >
                    <input
                        id="lastName"
                        type="text"
                        className="form-control emd-landing-page__form-field--last-name"
                        value={lastName}
                        placeholder="Last Name"
                        onChange={this.onChangeLastName}
                        onBlur={() =>
                            this.setErrorMessageFieldRequired(
                                "lastName",
                                lastName,
                                "Last Name"
                            )
                        }
                    />
                </label>
                {this.validateErrorMessage("lastName")}
            </div>
        );
    };

    validateAddress = addressSelected => {
        const addressType = get(addressSelected, "value.types");
        return includes(addressType, "street_address");
    };

    onPlaceDetails = ([details] = [], value) => {
        const addressComponents = get(details, "address_components" || {});
        const {
            address,
            neighborhood,
            city,
            state,
            zipcode
        } = getAddressFromPlaceDetails(details);
        this.setState({
            currentAddressDetails: {
                address,
                neighborhood,
                city,
                state,
                zipcode
            },
            currentAddress: value
        });
        this.setState({ hasAddress: !isEmpty(addressComponents) });
    };

    buildAddress = () => {
        const { currentAddress } = this.state;
        return (
            <div className="form-group emd-landing-page__container double-margin">
                <label
                    className="emd-landing-page__form-label--address"
                    htmlFor="addressLabel"
                >
                    <AutoCompleteAddressInput
                        value={currentAddress}
                        placeholder="Current Address"
                        customOnChange={this.onChangeCurrentAddress}
                        onPlaceDetails={this.onPlaceDetails}
                    />
                </label>
                {this.validateErrorMessage("currentAddress")}
            </div>
        );
    };

    buildApartment = () => {
        const { apartmentSuiteNumber } = this.state;
        return (
            <div className="form-group emd-landing-page__container">
                <label
                    className="emd-landing-page__form-label--first-name"
                    htmlFor="apartmentLabel"
                >
                    <input
                        id="apartmentSuiteNumber"
                        type="text"
                        className="form-control emd-landing-page__form-field--first-name"
                        value={apartmentSuiteNumber}
                        placeholder="Apartment"
                        onChange={this.onChangeApartment}
                    />
                </label>
            </div>
        );
    };

    validateSSN = () => {
        const { ssn } = this.state;
        if (!ssn) {
            this.setErrorMessageFieldRequired(
                "ssn",
                ssn,
                "Social Security Number"
            );
            return;
        }

        const ssnWithoutDash = ssn.replace(/-/g, "");
        if (!REGEXP_ONLY_9_CHAR.test(ssnWithoutDash)) {
            this.setErrorMessage("ssn", 1);
            return;
        }

        this.setState({ ssnError: null });
    };

    onChangeVisibleSsn = e =>
        this.setState({ isVisibleSsn: !this.state.isVisibleSsn });

    buildSSN = () => {
        const { ssn, isVisibleSsn } = this.state;
        return (
            <div className="form-group emd-landing-page__ssn">
                <div className="form-group emd-landing-page__container col-xs-12 without-padding">
                    <label
                        className="emd-landing-page__form-label--ssn"
                        htmlFor="ssn"
                    >
                        SSN
                    </label>
                    <SsnToggleVisibilityField
                        id="ssn"
                        name="ssn"
                        className="form-control emd-landing-page__form-field--password"
                        value={ssn}
                        onChange={this.onChangeSSN}
                        onBlur={() => this.validateSSN()}
                        placeholder="Social Security Number"
                        isInputField={true}
                        isVisible={isVisibleSsn}
                        onToggleVisibility={this.onChangeVisibleSsn}
                    />
                    {this.validateErrorMessage("ssn")}
                </div>
            </div>
        );
    };

    validateBirthday = () => {
        const { birthDayOfMonth, birthMonth, birthYear } = this.state;

        const validate =
            !birthDayOfMonth ||
            !birthMonth ||
            !birthYear ||
            differenceInYears(
                new Date(),
                new Date(birthYear, birthMonth - 1, birthDayOfMonth)
            ) >= 18;
        if (!validate) {
            this.setErrorMessage("birthday", 3);
            return;
        }

        this.setState({ birthdayError: null });
    };

    birthMonth = () => {
        const { birthMonth } = this.state;
        return (
            <div className="form-group emd-landing-page__container col-xs-5 without-padding">
                <select
                    className="form-control emd-landing-page__form-label--birth-month"
                    onChange={this.onChangeBirthMonth}
                    value={birthMonth}
                >
                    <option
                        className="emd-landing-page__form-label--birth-month"
                        value=""
                    >
                        MONTH
                    </option>
                    {map(months, month => (
                        <option
                            className="emd-landing-page__form-label--birth-month"
                            key={month.value}
                            value={month.value}
                        >
                            {month.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    birthDayOfMonth = () => {
        const days = rangeOptions(1, 31);
        const { birthDayOfMonth } = this.state;
        return (
            <div className="form-group emd-landing-page__container col-xs-3 without-padding-right">
                <select
                    className="form-control emd-landing-page__form-label--birth-day"
                    onChange={this.onChangeBirthDayOfMonth}
                    value={birthDayOfMonth}
                >
                    <option
                        className="emd-landing-page__form-label--birth-day"
                        value=""
                    >
                        DAY
                    </option>
                    {map(days, day => (
                        <option
                            className="emd-landing-page__form-label--birth-day"
                            key={day.value}
                            value={day.value}
                        >
                            {day.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    birthYear = () => {
        const { birthYear } = this.state;
        const currentYear = new Date().getFullYear();
        const yearOptions = rangeOptions(currentYear - 10, currentYear - 80);

        return (
            <div className="form-group emd-landing-page__container col-xs-4 without-padding-right">
                <select
                    className="form-control emd-landing-page__form-label--birth-year"
                    onChange={this.onChangeBirthYear}
                    value={birthYear}
                >
                    <option
                        className="emd-landing-page__form-label--birth-year"
                        value=""
                    >
                        YEAR
                    </option>
                    {map(yearOptions, year => (
                        <option
                            className="emd-landing-page__form-label--birth-year"
                            key={year.value}
                            value={year.value}
                        >
                            {year.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    titleDOB = () => (
        <div className="emd-landing-page__birth-text-container">
            <div className="emd-landing-page__birth-text">Date of Birth:</div>
        </div>
    );

    buildDateOfBirth = () => (
        <>
            <div className="emd-landing-page__birth">
                {this.titleDOB()}
                <div className="emd-landing-page__birth-container">
                    {this.birthMonth()}
                    {this.birthDayOfMonth()}
                    {this.birthYear()}
                </div>
                {this.validateErrorMessage("birthday")}
            </div>
        </>
    );

    haveEmptyFields = () => {
        const {
            firstName,
            lastName,
            ssn,
            currentAddress,
            birthMonth,
            birthDayOfMonth,
            birthYear
        } = this.state;

        return !(
            firstName &&
            lastName &&
            ssn &&
            currentAddress &&
            birthMonth &&
            birthDayOfMonth &&
            birthYear
        );
    };

    haveErrors = () => {
        const {
            ssnError,
            firstNameError,
            lastNameError,
            currentAddressError,
            birthdayError
        } = this.state;

        return (
            firstNameError ||
            lastNameError ||
            ssnError ||
            currentAddressError ||
            birthdayError
        );
    };

    isDisableSaveButton = () => {
        const { prospect } = this.props;
        const fetchingEditProspect = get(prospect, "fetchingEditProspect");

        return (
            this.haveEmptyFields() || this.haveErrors() || fetchingEditProspect
        );
    };

    render = () => (
        <div className="emd-landing-page__profile-edit-container">
            <div className="emd-landing-page__profile-subtitle">
                Edit Your Information
            </div>
            <div className="emd-landing-page__profile-items">
                <div className="emd-landing-page__container without-padding-left col-xs-12">
                    {this.buildFirstNameField()}
                </div>
                <div className="emd-landing-page__container without-padding-left col-xs-12">
                    {this.buildLastNameField()}
                </div>
                <div className="emd-landing-page__container without-padding-left col-xs-12">
                    {this.buildAddress()}
                </div>
                <div className="emd-landing-page__container without-padding-left col-xs-12">
                    {this.buildApartment()}
                </div>
                <div className="emd-landing-page__container without-padding-left col-xs-12">
                    {this.buildDateOfBirth()}
                </div>
                <div className="emd-landing-page__container without-padding-left col-xs-12">
                    {this.buildSSN()}
                </div>
            </div>
            {this.buildFooterButtons()}
        </div>
    );
}

EmdLandingPageProfileEdit.propTypes = {
    loadUserProfile: PropTypes.func,
    user: PropTypes.object,
    prospect: PropTypes.object,
    editProspectInfo: PropTypes.func,
    onCancelEdit: PropTypes.func
};
