import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import { format } from 'date-fns';
import BrewBoardBrokerageLabel from '../../BrewBoardBrokerageLabel/BrewBoardBrokerageLabel';
import BrewBoardNumberCurrency from './BrewBoardNumberCurrency';
import { MoreInfoCloseIcon, MoreInfoButton } from './MoreInfoButton';
import { STATUS_REALTOR } from '../../../shared/constants';
import AvatarAndNameCol from './AvatarAndNameCol';
import StatusRow from '../../StatusRow/StatusRow';
import AgentPhone from './AgentPhone';

import './adminRoleTables.css';

export default class RealtorTable extends React.Component {
    buildStatus = (user) => {
        const approved = get(user, 'publicProfile.approved') ? 'APPROVED' : 'SUSPEND';
        const status = find(STATUS_REALTOR, { id: approved });
        return <StatusRow status={status} />
    }

    buildBrokerageMobile = (user) => (
        <div className="admin-role-tables__brokerage">
            <div className="admin-role-tables__brokerage-text">
                WORKS AT
            </div>
            <BrewBoardBrokerageLabel
                brokerage={get(user, 'publicProfile.brokerage')}
                onSuccessfullyUpdateBrokerage={this.props.refreshPage}
            />
    </div>	
    )

    buildStateMobile = (user) => (
        <div className="admin-role-tables__state-mobile">
            <div className="admin-role-tables__state-text">
                STATE
            </div>
            <div className="admin-role-tables__state">
                {get(user, 'publicProfile.state.name')}
            </div>
    </div>	
    )

    buildAvgSalePriceMobile = (user) => (
        <div className="admin-role-tables__sale-price-mobile">
            <div className="admin-role-tables__sale-price-text">
                AVG SALES PRICE
            </div>
            <div className="admin-role-tables__sale-price">
                {BrewBoardNumberCurrency((get(user, 'publicProfile.averageSalePrice.amount')))}
            </div>
    </div>	
    )

    buildBrokerage = (user) => {
        const id = get(user, 'publicProfile.userInfo.id');
        const showMoreInfo = get(user, 'showMoreInfo', false);
        return (
            <>
                <div className="admin-role-tables__brokerage-title">
                    WORKS AT
                </div>
                <BrewBoardBrokerageLabel
                    brokerage={get(user, 'publicProfile.brokerage')}
                    onSuccessfullyUpdateBrokerage={this.props.refreshPage}
                />
                <div className="admin-role-tables__more-info">
                    <MoreInfoButton id={id} showMoreInfo={showMoreInfo} onClickMoreInfo={this.props.onClickMoreInfo} />
                </div>
            </>
        )
    }

    buildFirstRow = (user) => {
        return (
            <div className="admin-role-tables__item-row realtor-table">
                <div className="admin-role-tables__action-button">
                    {this.props.buildActionButton(user)}
                </div>
                <div className="admin-role-tables__item-row-section-plh">
                    <div className="admin-role-tables__item-row-section-one">
                        <div className="admin-role-tables__agent">
                            <div className="admin-role-tables__brokerage-title">AGENT</div>
                            <AvatarAndNameCol user={get(user, 'publicProfile')} />
                        </div>
                    </div>
                    <div className="admin-role-tables__item-row-section-two">
                        <div className="admin-role-tables__brokerage admin-role-tables__item-row-section-two-item">
                            {this.buildBrokerage(user)}
                        </div>
                        <div className="admin-role-tables__state admin-role-tables__item-row-section-two-item">
                            <div className="admin-role-tables__brokerage-title">STATE</div>
                            {get(user, 'publicProfile.state.name')}
                        </div>
                        <div className="admin-role-tables__sale-price admin-role-tables__item-row-section-two-item">
                            {BrewBoardNumberCurrency((get(user, 'publicProfile.averageSalePrice.amount')))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    buildCheckOrCrossFrom = (value) => (
        value ?
        <div className="profile__summary__glyph is-ok" /> :
        <div className="profile__summary__glyph is-error" />
    );

    buildVideo = (user, classIsMobile = '') => (
        <div className={`admin-role-tables__video${classIsMobile}`}>
            <div className="admin-role-tables__video-text">
                VIDEO
            </div>
            {this.buildCheckOrCrossFrom(get(user, 'publicProfile.profileVideoUrl') != null)}
        </div>
    )

    buildProfile = (user, classIsMobile = '') => (
        <div className={`admin-role-tables__profile${classIsMobile}`}>
            <div className="admin-role-tables__profile-text">
                PROFILE
            </div>
            {this.buildCheckOrCrossFrom(get(user, 'publicProfile.userInfo.hasCompletedOnboarding') === true)}
        </div>
    )

    buildDate = (user) => (
        <div className="admin-role-tables__date-content">
            <div className="admin-role-tables__date-text">
                CREATED DATE
            </div>
            <div className="admin-role-tables__date-format">
                {format(get(user, 'publicProfile.userInfo.ts'), this.props.dateFormat)}
            </div>
        </div>
    )

    buildSuspended = (user, classIsMobile = '') => (
        <div className={`admin-role-tables__suspended${classIsMobile}`}>
            <div className="admin-role-tables__suspended-text">
                SUSPENDED
            </div>
            {this.buildCheckOrCrossFrom(get(user, 'publicProfile.approved') === false)}
        </div>
    )

    buildSecondRow = (user) => {
        const moreInfo = get(user, 'showMoreInfo', false);

        if(!moreInfo) return;

        return (
            <div className="admin-role-tables__item-row secondary ">
                <div className="admin-role-tables__item-row-section-phone">
                    <AgentPhone phone={get(user, 'publicProfile.userInfo.phone')} />
                </div>
                <div className="admin-role-tables__item-row-section-list">
                    <div className="">
                        {this.buildVideo(user)}
                    </div>
                    <div className="">
                        {this.buildProfile(user)}
                    </div>
                    <div className="">
                        {this.buildSuspended(user)}
                    </div>
                </div>
                <div className="admin-role-tables__date">
                    {this.buildDate(user)}
                </div>
            </div>
        );
    }

    buildContentList = () => (
        <div className="admin-role-tables__body">
            {map(this.props.users, (user, index) => {
                const id = get(user, 'publicProfile.userInfo.id');
                const showMoreInfo = get(user, 'showMoreInfo', false);
                return (
                    <div className="admin-role-tables__item" key={index}>
                        <MoreInfoCloseIcon id={id} showMoreInfo={showMoreInfo} onClickMoreInfo={this.props.onClickMoreInfo} />
                        {this.buildStatus(user)}
                        {this.buildFirstRow(user)}
                        {this.buildSecondRow(user)}
                    </div>
                );
            })}
        </div>
    );

    render = () => (
        <div>
            {this.buildContentList()}
        </div>
    );
}

RealtorTable.propTypes = {
    dateFormat: PropTypes.string,
    users: PropTypes.any,
    buildActionButton: PropTypes.func,
    refreshPage: PropTypes.func,
    onClickMoreInfo: PropTypes.func,
};
