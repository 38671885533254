import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";

import "./custom-tooltip.css";

export default class CustomTooltip extends React.Component {
    render = () => {
        const tooltipContent = (
            <Popover id={`lemonbrew-tooltip--${this.props.type}`}>
                {this.props.popoverText}
            </Popover>
        );

        return (
            <OverlayTrigger
                ref="overlay"
                placement="top"
                overlay={tooltipContent}
            >
                {this.props.content}
            </OverlayTrigger>
        );
    };
}

CustomTooltip.propTypes = {
    popoverText: PropTypes.any,
    content: PropTypes.any,
    type: PropTypes.oneOf(["default", "black"])
};
