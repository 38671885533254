import React, { Component } from 'react';
import {Button} from '../../../components/Button/Button';
import { withRouter } from 'react-router-dom';
import VimeoVideo from 'react-vimeo';

import './index.css';

class SparkHireVideoVerification extends Component {
    onClickContinue = () => {
        window.open('https://hire.li/9c5921b', '_blank');

        return false;
    };

    buildVideo = () => (
        <div className="sparkhire__verify">
            <h1 className="title">One last thing...</h1>
            <p className="header">
            You&#39;re one step away from the industry&#39;s best leads.
            </p>

            <VimeoVideo
                className="video"
                videoId="326477590"
                autoplay
            />

            <div>
                <Button type="button" onClick={this.onClickContinue}>COMPLETE INTERVIEW</Button>
            </div>
        </div>
    );

    render = () => (this.buildVideo());
}

export default withRouter(SparkHireVideoVerification);
