import React from 'react';
import { withRouter } from 'react-router-dom';
import ResetPasswordContainer from './ResetPasswordContainer';
import {Helmet} from 'react-helmet';

const ResetPassword = () => (
    <div className="signin__container">
        <Helmet>
            <body className="bg-emphasis" />
        </Helmet>
        <ResetPasswordContainer />
    </div>
);

export default withRouter(ResetPassword);
