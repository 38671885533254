import React from 'react';
import CustomRealtorExperienceField from '../../CustomRealtorExperienceField/CustomRealtorExperienceField';

import './experience.scss';

const Experience = (props) => {
    return (
        <div className="experience__container">
            <div className="level__one__text">
                How many year experience do you have?
            </div>
            <CustomRealtorExperienceField
                experience={props.experience}
                onEdit={props.onEdit}
            />
        </div>
    )
}

export default Experience;
