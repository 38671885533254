import find from 'lodash/find';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getUsersAsAdmin,
    impersonateUser,
    resendRealtorAgreement,
    resendBrokerAgreement,
    suspendUser,
} from '../../reducers/admin/AdminActions';
import { createNewEvent } from '../../reducers/spin/SpinActions';
import {GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs} from 'react-google-maps';
import CustomMapControl from '../GoogleMapCustomControl/CustomMapControl';
import {getRealtorStats, countRealtorsByCityInState, getRealtorListByCity} from '../../util/service_api';
import {NumberFormat} from '../NumberFormat/NumberFormat';

const Map = withScriptjs(
    withGoogleMap(props =>
        <GoogleMap {...props}>{props.children}</GoogleMap>
    )
);

const getScalingFn = (inMin, inMax, outMin = 0, outMax = 1) => {
    const inRange = inMax - inMin;
    const outRange = outMax - outMin;
    return value => outMin + outRange * (value - inMin) / inRange;
};

const PROFILE_PIC_S3_ROOT = 'https://s3.amazonaws.com/lb-public-images/user/profile-pics';
const DEFAULT_PROFILE_PIC_URL = 'https://s3.amazonaws.com/lb-public-images/profile_pic_default.png';
const getProfilePic = name =>
    name && `${name}`.length > 5
        ? `${PROFILE_PIC_S3_ROOT}/${name}`
        : DEFAULT_PROFILE_PIC_URL;

const InitialMapLocation = {
    lat: 37.9851394,
    lng: -98.8764388,
    zoom: 4
};

const googleMapDefaultOptions = {
    fullscreenControl: false,
    disableDefaultUI: true,
};

const googleMapUrl =
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=places`;

const NumberedMapPin = ({
        label: text = '',
        scale = 1,
        position: [lat, lng],
        onClick,
        children,
        zIndex = 100,
    }) =>
    <Marker
        label={{
            color: '#009f4d',
            fontWeight: 'bold',
            fontSize: '15px',
            text,
        }}
        icon = {{
            path: 'M -25 0 a 25,25 0 1,1 50,0 25,25 0 1,1 -50,0',
            fillColor: '#ffffff',
            fillOpacity: 0.8,
            strokeColor: '#009f4d',
            strokeWeight: 3,
            scale }}
        position={{ lat, lng }}
        onClick={ onClick }
        zIndex={zIndex}
    >
        { children }
    </Marker>;

class AdminRealtorMap extends React.Component {
    static propTypes = {
        admin: PropTypes.object,
        usStates: PropTypes.array,
        onGetUsersAsAdmin: PropTypes.func,
        loginImpersonateUser: PropTypes.func,
        onResendRealtorAgreement: PropTypes.func,
        onResendBrokerAgreement: PropTypes.func,
        onSuspendUser: PropTypes.func,
        onCreateNewEvent: PropTypes.func,
    };

    state = {
        states: [],
        countryMinMax: [0, 0],
        stateStats: {},
        stateLocations: {},
        selectedState: null,
        cityInfo: null
    };

    componentDidMount() {
        getRealtorStats().then(({ data }) => {
            const states = sortBy(data, 'name');

            this.setState({
                states,
                countryMinMax: [
                    minBy(states, 'count').count,
                    maxBy(states, 'count').count
                ]
            });
        });

        let body = document.body;
        body.classList.add("bodyGoogleMap");
    }

    componentWillUnmount() {
        let body = document.body;
        body.classList.remove("bodyGoogleMap");
    }

    getStateInfoByName = (name) =>
        find(this.props.usStates, { name }) || { latitude: 0, longitute: 0 };

    handleToggleCityInfo = (name) => {
        if (!name) return this.setState({ cityInfo: null });

        getRealtorListByCity(this.state.selectedState, name).then(({data}) => {
            this.setState({ cityInfo: {
                name,
                data: map(data, agent => {
                    const {
                        averageSalePrice,
                        userInfo: { id, firstName, lastName, profilePicUrl }
                    } = agent;

                    return {
                        name: `${firstName} ${lastName}`,
                        profileUrl: `/realtor/public-profile/${id}`,
                        profilePicUrl: getProfilePic(profilePicUrl),
                        averageSalePrice,
                    };
                })
            }});
        });
    };

    handleSelectState = (e) => this.selectState(e.currentTarget.value || null);

    selectState = selectedState => {
        this.setState({ selectedState });

        if (selectedState && this.state.stateStats[selectedState]) return;
        countRealtorsByCityInState(selectedState).then(({ data }) => {
            this.setState(state => ({
                stateStats: {
                    ...state.stateStats,
                    [selectedState]: filter(data, item => item.latitude && item.longitude)
                }
            }));
        });
    };

    renderAgentRow(agent) {
        const {
            name,
            profileUrl,
            profilePicUrl,
            averageSalePrice
        } = agent;

        return (
            <tr key={profileUrl}>
                <td><div className='profile-picture' style={{ backgroundImage: `url(${profilePicUrl})`}}/>
                </td>
                <td><a href={profileUrl} target={"_blank"}>{`${name}`}</a></td>
                <td>~
                    <NumberFormat
                        currency={'USD' || averageSalePrice.currency}
                        number={averageSalePrice.amount || 0}
                        format="currency"
                        decimals={0}
                    />
                </td>
            </tr>
        )
    }

    renderCityInfo(name) {
        const { cityInfo, selectedState: state } = this.state;
        if (!(cityInfo && cityInfo.name === name && cityInfo.data)) return null;

        const cityInfoHeader = `${cityInfo.data.length} Agents in ${name}, ${state}`;
        return (
            <InfoWindow onCloseClick={this.handleToggleCityInfo}>
                <div className="admin-profile__agent-list">
                    <table>
                        <thead>
                            <th colSpan="3">{cityInfoHeader}</th>
                        </thead>
                        <tbody>
                            { map(cityInfo.data, this.renderAgentRow) }
                        </tbody>
                    </table>
                </div>
            </InfoWindow>
        )
    }

    renderCityMarkers() {
        const {
            selectedState,
            stateStats: { [selectedState]: stateStats = [] }
        } = this.state;
        if (stateStats.length < 1) return null;

        const scalingFn = getScalingFn(
            minBy(stateStats, 'count').count,
            maxBy(stateStats, 'count').count,
            0.5,
            1);

        return (
            <Fragment>
                { map(stateStats, ({ count, city, latitude, longitude }) =>
                    <NumberedMapPin
                        key={city}
                        label={`${count}`}
                        scale={scalingFn(count)}
                        position={[ latitude, longitude ]}
                        onClick={() => this.handleToggleCityInfo(city)}
                        zIndex={ 100 + count }
                    >
                        { this.renderCityInfo(city)}
                    </NumberedMapPin>
                )}
            </Fragment>
        )
    }

    renderStateMarkers() {
        const { states, countryMinMax: [ minState, maxState] } = this.state;
        if (states.length < 1) return null;

        const scalingFn = getScalingFn(minState, maxState, 0.5, 1);
        return (
            <Fragment>
                { map(states, ({ state, count }) => {
                    const { latitude, longitute } = this.getStateInfoByName(state);
                    return (
                        <NumberedMapPin
                            key={state}
                            label={`${count}`}
                            scale={scalingFn(count)}
                            position={[latitude, longitute]}
                            onClick={() => this.selectState(state)}
                            zIndex={ 100 + count }
                        />
                    );
                })}
            </Fragment>
        )
    }

    renderSelectStates() {
        const { states } = this.state;
        return (
            <div className="admin-profile__state-select">
                <select onChange={this.handleSelectState} value={this.state.selectedState || ''}>
                    <option value="">USA</option>
                    { map(states, ({ state, count }) =>
                        <option key={state} value={state}>{`${state} (${count})`}</option>
                    )}
                </select>
            </div>
        )
    }

    render = () => {
        const mapCenter = {
            lat: InitialMapLocation.lat,
            lng: InitialMapLocation.lng
        };
        let mapZoom = InitialMapLocation.zoom;

        const { selectedState } = this.state;
        if (selectedState) {
            const { latitude, longitute, zoomLevel } = this.getStateInfoByName(selectedState);
            mapCenter.lat = latitude;
            mapCenter.lng = longitute;
            mapZoom = zoomLevel;
        }

        return (
            <div className="admin-profile for-google-map">
                <div className="admin-profile__container for-google-map">
                    <Map
                        googleMapURL={googleMapUrl}
                        loadingElement={<div className="google-maps"/>}
                        containerElement={<div className="google-maps"/>}
                        mapElement={<div className="google-maps"/>}
                        zoom={mapZoom}
                        center={mapCenter}
                        defaultOptions={googleMapDefaultOptions}
                        isMarkerShown
                    >
                        <CustomMapControl position={window.google.maps.ControlPosition.TOP_LEFT}>
                            { this.renderSelectStates() }
                        </CustomMapControl>

                        { selectedState
                            ? this.renderCityMarkers(selectedState)
                            : this.renderStateMarkers()
                        }
                    </Map>
                </div>
            </div>
        );
    };
}

export default connect(
    state => ({
        admin: state.admin,
        usStates: get(state.configApp, 'config.usStates', [])
    }), {
        onGetUsersAsAdmin: getUsersAsAdmin,
        loginImpersonateUser: impersonateUser,
        onResendRealtorAgreement: resendRealtorAgreement,
        onResendBrokerAgreement: resendBrokerAgreement,
        onSuspendUser: suspendUser,
        onCreateNewEvent: createNewEvent,
    },
)(AdminRealtorMap);
