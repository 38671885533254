import get from "lodash/get";
import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import RolesTable from "../AdminProfile/AdminRoleTables/RolesTable";
import {
    getRoles,
    onCreateNewAdmin,
    getBrewCrewUsers,
    resetCreateNewAdmin
} from "../../reducers/admin/AdminActions";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { SELECT_ROLE_ALL } from "../../shared/constants";
import { Button } from "../Button/Button";
import CreateAdminModal from "../CreateAdminModal/CreateAdminModal";
import delay from "lodash/delay";
import isFunction from "lodash/isFunction";
import find from "lodash/find";

import "./adminProfileBrewCrew.css";

class AdminProfileBrewCrew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisibleCreateAdminModal: false
        };
    }

    componentDidMount = () => {
        const roleBrewcrew = find(this.props.roles, { name: SELECT_ROLE_ALL })
            ? true
            : false;
        if (roleBrewcrew) {
            if (isFunction(this.props.onGetBrewCrewUsers())) {
                this.props.onGetBrewCrewUsers();
            }
            if (isFunction(this.props.onGetRoles())) {
                this.props.onGetRoles();
            }
        }
    };

    afterDelayClose = () => {
        this.onCloseCreateAdminModal();
        this.props.resetCreateNewAdmin();
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.isFetchingCreateNewAdmin &&
            !this.props.isFetchingCreateNewAdmin
        ) {
            if (this.props.successCreateNewAdmin) {
                delay(this.afterDelayClose, 8000);
            }
        }
    }

    buildModalCreateAdmin = () => {
        const {
            createNewAdmin,
            successCreateNewAdmin,
            isFetchingCreateNewAdmin,
            errorCreateNewAdmin
        } = this.props;
        const { isVisibleCreateAdminModal } = this.state;

        return (
            <CreateAdminModal
                isVisible={isVisibleCreateAdminModal}
                onClose={this.onCloseCreateAdminModal}
                onCancel={this.onCloseCreateAdminModal}
                onSave={createNewAdmin}
                isSavedAdmin={successCreateNewAdmin}
                isSavingAdmin={isFetchingCreateNewAdmin}
                errorCreateNewAdmin={errorCreateNewAdmin}
                roles={this.getRoles()}
            />
        );
    };

    onOpenCreateAdminModal = () =>
        this.setState({ isVisibleCreateAdminModal: true });

    onCloseCreateAdminModal = () =>
        this.setState({ isVisibleCreateAdminModal: false });

    buildCreateAdminButton = () => (
        <div className="admin-profile-brewcrew__title-button">
            <Button
                className=" new-admin-button"
                onClick={this.onOpenCreateAdminModal}
            >
                ADD BREWCREW
            </Button>
        </div>
    );

    getRoles = () => get(this.props.admin, "getRoles.roles");

    getIsFetchedRoles = () => get(this.props.admin, "getRoles.isFetchedRoles");

    getIsFetchingRoles = () =>
        get(this.props.admin, "getRoles.isFetchingRoles");

    getBrewCrewUsers = () => get(this.props.admin, "getBrewCrewUsers.users");

    getIsFetchedBrewCrewUsers = () =>
        get(this.props.admin, "getBrewCrewUsers.isFetchedBrewCrewUsers");

    getIsFetchingBrewCrewUsers = () =>
        get(this.props.admin, "getBrewCrewUsers.isFetchingBrewCrewUsers");

    buildRolesTable = () => (
        <div className="admin-profile-brewcrew__table-container">
            <RolesTable
                dateFormat={this.props.dateFormat}
                users={this.getBrewCrewUsers()}
            />
        </div>
    );

    buildResults = () => {
        if (this.getIsFetchingBrewCrewUsers()) {
            return <SpinnerLoader />;
        }

        return this.buildRolesTable();
    };

    render = () => (
        <div className="admin-profile-brewcrew">
            {this.buildModalCreateAdmin()}
            <div className="admin-profile-brewcrew__title">
                <div className="admin-profile-brewcrew__title-text">Roles</div>
                {this.buildCreateAdminButton()}
            </div>
            <div className="admin-profile-brewcrew__container">
                {this.buildResults()}
            </div>
        </div>
    );
}

AdminProfileBrewCrew.propTypes = {
    admin: PropTypes.object,
    roles: PropTypes.any,
    onGetRoles: PropTypes.func,
    onCreateNewAdmin: PropTypes.func,
    onGetBrewCrewUsers: PropTypes.func,
    resetCreateNewAdmin: PropTypes.func
};

AdminProfileBrewCrew.defaultProps = {
    dateFormat: "MM.DD.YYYY hh:mm a"
};

export default connect(
    state => ({
        admin: state.admin,
        roles: get(state, "user.profile.publicProfile.userInfo.roles", []),
        successCreateNewAdmin: get(
            state.admin,
            "newAdmin.successCreateNewAdmin"
        ),
        isFetchingCreateNewAdmin: get(
            state.admin,
            "newAdmin.isFetchingCreateNewAdmin"
        ),
        errorCreateNewAdmin: get(state.admin, "newAdmin.errorCreateNewAdmin")
    }),
    {
        onGetRoles: getRoles,
        onGetBrewCrewUsers: getBrewCrewUsers,
        createNewAdmin: onCreateNewAdmin,
        resetCreateNewAdmin: resetCreateNewAdmin
    }
)(AdminProfileBrewCrew);
