import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { NumberFormat } from '../NumberFormat/NumberFormat';
import { Button } from '../Button/Button';

import './realtorPendingSellerMatchItem.css';

export default class RealtorPendingSellerMatchItem extends React.PureComponent {
    static propTypes = {
        picture: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        homeValueLow: PropTypes.object.isRequired,
        homeValueHigh: PropTypes.object.isRequired,
        timeFrame: PropTypes.string.isRequired,
        onAcceptMatch: PropTypes.func.isRequired,
        onRejectMatch: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    render = () => (
        <div className="realtor-pending-seller-match-item">
            <div className="realtor-pending-match-item__header">
                <img src={this.props.picture} alt={this.props.name} className="realtor-pending-match-item__picture" />
                <div>
                    <h3 className="realtor-pending-match-item__name">{this.props.name}</h3>
                    <h5 className="realtor-pending-match-item__location">{this.props.location}</h5>
                </div>
            </div>
            <div className="row realtor-pending-match-item__content">
                <div className="col-xs-6 realtor-pending-match-item__budget">
                    <div className="realtor-pending-match-item__budget-amount">
                        <span>
                            <NumberFormat
                                number={get(this.props.homeValueLow, 'amount')}
                                currency={get(this.props.homeValueLow, 'currency')}
                                decimals={0}
                                format="currency"
                            />
                            {' - '}
                            <NumberFormat
                                number={get(this.props.homeValueHigh, 'amount')}
                                currency={get(this.props.homeValueHigh, 'currency')}
                                decimals={0}
                                format="currency"
                            />
                        </span>
                    </div>
                    <div className="realtor-pending-match-item__budget_label">
                        Budget
                    </div>
                </div>
                <div className="col-xs-6 realtor-pending-match-item__rebate">
                    <div className="realtor-pending-match-item__rebate-amount">
                        {this.props.timeFrame}
                    </div>
                    <div className="realtor-pending-match-item__rebate_label">
                        Time Frame
                    </div>
                </div>
            </div>
            <div className="realtor-pending-match-item__footer">
                <Button
                    small
                    onClick={this.props.onAcceptMatch}
                    isLoading={this.props.isLoading}
                    loadingText="Accepting..."
                >
                    ACCEPT
                </Button>
                <Button
                    small
                    yellow
                    onClick={this.props.onRejectMatch}
                >
                    DECLINE
                </Button>
            </div>
        </div>
    );
}
