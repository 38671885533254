import React from "react";
import get from "lodash/get";
import invoke from "lodash/invoke";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SimpleWizard from "../Wizard/SimpleWizard";
import PlacesServingIn from "./PlacesServingIn/PlacesServingIn";
import Experience from "./Experience/Experience";
import Languages from "./Languages/Languages";
import Designations from "./Designations/Designations";
import Certifications from "./Certifications/Certifications";
import Specializations from "./Specializations/Specializations";
import Interests from "./Interests/Interests";
import Cuisines from "./Cuisines/Cuisines";
import Biography from "./Biography/Biography";
import Summary from "./Summary/Summary";

import { createRealtorProfile } from "../../reducers/RealtorProfile/RealtorProfileActions";
import {
    preventRealtorTourFromReAppearing,
    loadUserProfile
} from "../../reducers/user/UserActions";

import "./agent_flow.scss";

const ON_SUBMIT_ERROR = `We encountered and error when processing your form.You can complete the process by editing your profile`;
const ON_SUBMIT_SUCCESS = "Profile has been updated successfully";

class AgentFlow extends React.Component {
    constructor(props) {
        super(props);
        this.handleFinish = this.handleFinish.bind(this);
        this.state = {
            isSubmitting: false,
            isCompleted: false,
            hasError: false,
            saleAddresses: [],
            yearsOfExperience: 0,
            languages: [],
            designations: [],
            professionalCertifications: [],
            specializations: [],
            interests: [],
            cuisines: [],
            aboutMe: ""
        };
    }

    performActionsBeforeRedirectionToProfile = () => {
        this.toastFormFeedbackMessage();
        setTimeout(() => this.props.history.push(`realtor/profile/edit`), 6000);
    };

    toastFormFeedbackMessage = () => {
        if (this.state.hasError) {
            toast.error(ON_SUBMIT_ERROR, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false
            });
        } else {
            toast.success(ON_SUBMIT_SUCCESS, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false
            });
        }
    };

    onSaleAddressEdit = value => {
        this.setState({ saleAddresses: value });
    };

    onExperienceEdit = value => {
        this.setState({ yearsOfExperience: value });
    };

    onLanguageEdit = value => {
        this.setState({ languages: value });
    };

    onDesignationEdit = value => {
        this.setState({ designations: value });
    };

    onCertificationEdit = value => {
        this.setState({ professionalCertifications: value });
    };

    onSpecializationEdit = value => {
        this.setState({ specializations: value });
    };

    onInterestEdit = value => {
        this.setState({ interests: value });
    };

    onCuisineEdit = value => {
        this.setState({ cuisines: value });
    };

    onBiographyEdit = value => {
        this.setState({ aboutMe: value });
    };

    getStatusCode = () => {
        return get(this.props, "realtorProfileStatus");
    };

    hasError = () => {
        return this.getStatusCode() !== "200" ? true : false;
    };

    async handleFinish() {
        // Set isSubmitting as true
        // Perform submit/finish actions
        // Once finished, mark isCompleted as true
        this.setState({ isSubmitting: true });

        const {
            saleAddresses,
            yearsOfExperience,
            languages,
            designations,
            professionalCertifications,
            specializations,
            interests,
            cuisines,
            aboutMe
        } = this.state;

        await invoke(this.props, "createRealtorProfile", {
            saleAddresses,
            yearsOfExperience,
            languages,
            designations,
            professionalCertifications,
            specializations,
            interests,
            cuisines,
            aboutMe
        });
        await invoke(this.props, "preventRealtorTourFromReAppearing");
        await invoke(this.props, "loadUserProfile");
        this.setState(
            {
                isSubmitting: false,
                isCompleted: true,
                hasError: this.hasError(),
                saleAddresses: [],
                yearsOfExperience: 0,
                languages: [],
                designations: [],
                professionalCertifications: [],
                specializations: [],
                interests: [],
                cuisines: [],
                aboutMe: ""
            },
            this.performActionsBeforeRedirectionToProfile()
        );
    }

    render() {
        const { user } = this.props;
        const userName = `${get(
            user.profile,
            "publicProfile.userInfo.firstName"
        )} 
                          ${get(
                              user.profile,
                              "publicProfile.userInfo.lastName"
                          )}`;
        return (
            <div className="agent__flow__container">
                <SimpleWizard
                    initialStep={0}
                    isSubmitting={this.state.isSubmitting}
                    isCompleted={this.state.isCompleted}
                    completedStep={9}
                    blockForward={false}
                    onFinish={this.handleFinish}
                    displayProgressHeader={false}
                    steps={[
                        {
                            canSkip: false,
                            label: "Places Serving In",
                            component: (
                                <PlacesServingIn
                                    onEdit={this.onSaleAddressEdit}
                                    value={this.state.saleAddresses}
                                />
                            )
                        },
                        {
                            canSkip: false,
                            label: "Experience",
                            component: (
                                <Experience
                                    onEdit={this.onExperienceEdit}
                                    experience={this.state.yearsOfExperience}
                                />
                            )
                        },
                        {
                            canSkip: false,
                            label: "Languages",
                            component: (
                                <Languages
                                    onEdit={this.onLanguageEdit}
                                    value={this.state.languages}
                                />
                            )
                        },
                        {
                            canSkip: false,
                            label: "Designations",
                            component: (
                                <Designations
                                    onEdit={this.onDesignationEdit}
                                    value={this.state.designations}
                                />
                            )
                        },
                        {
                            canSkip: false,
                            label: "Certifications",
                            component: (
                                <Certifications
                                    onEdit={this.onCertificationEdit}
                                    value={
                                        this.state.professionalCertifications
                                    }
                                />
                            )
                        },
                        {
                            canSkip: false,
                            label: "Specializations",
                            component: (
                                <Specializations
                                    onEdit={this.onSpecializationEdit}
                                    value={this.state.specializations}
                                />
                            )
                        },
                        {
                            canSkip: false,
                            label: "Interests",
                            component: (
                                <Interests
                                    onEdit={this.onInterestEdit}
                                    value={this.state.interests}
                                />
                            )
                        },
                        {
                            canSkip: false,
                            label: "Cuisines",
                            component: (
                                <Cuisines
                                    onEdit={this.onCuisineEdit}
                                    value={this.state.cuisines}
                                />
                            )
                        },
                        {
                            canSkip: false,
                            label: "Biography",
                            component: (
                                <Biography
                                    onEdit={this.onBiographyEdit}
                                    biography={this.state.aboutMe}
                                />
                            )
                        },
                        {
                            isSubmittable: true,
                            label: "Summary",
                            component: (
                                <Summary
                                    values={this.state}
                                    userName={userName}
                                />
                            )
                        }
                    ]}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable={false}
                    pauseOnHover
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isFetchingRealtorProfile: get(
        state,
        "realtorProfile.isFetchingRealtorProfile"
    ),
    realtorProfileStatus: get(state, "realtorProfile.status"),
    user: get(state, "user")
});

export default withRouter(
    connect(mapStateToProps, {
        createRealtorProfile,
        preventRealtorTourFromReAppearing,
        loadUserProfile
    })(AgentFlow)
);
