import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { Link } from 'react-router-dom';
import delay from 'lodash/delay';
import { NumberFormat } from '../NumberFormat/NumberFormat';
import RealtorMatchProfileLeftColumn from '../RealtorMatchProfileLeftColumn/RealtorMatchProfileLeftColumn';
import RealtorMatchProfileHeader from '../RealtorMatchProfileHeader/RealtorMatchProfileHeader';
import RealtorMatchProfileNotes from '../RealtorMatchProfileNotes/RealtorMatchProfileNotes';
import RealtorMatchProfileAttachments from '../RealtorMatchProfileAttachments/RealtorMatchProfileAttachments';
import RealtorMatchProfileTransactionInfo from '../RealtorMatchProfileTransactionInfo/RealtorMatchProfileTransactionInfo';
import ChangeStatusModal from '../ChangeStatusModal/ChangeStatusModal';
import CloseModalButton from '../CloseModalButton/CloseModalButton';
import RealtorMatchSummary from '../RealtorMatchSummary/RealtorMatchSummary';
import RealtorMatchItemSummary from '../RealtorMatchItemSummary/RealtorMatchItemSummary';
import RealtorMatchProfileSubjectPropertyInformation from '../RealtorMatchProfileSubjectPropertyInformation/RealtorMatchProfileSubjectPropertyInformation';
import RealtorProfileTransactionContactModal from '../RealtorProfileTransactionContactModal/RealtorProfileTransactionContactModal';
import { realtorMatchUnderContractStatus, realtorSellerMatchUnderContractStatus } from '../../shared/constants';
import { autocompleteContract } from '../../util/service_api';
import MatchFileUploadModal from '../MatchFileUploadModal/MatchFileUploadModal';
import RealtorProfileContactModal from '../RealtorProfileContactModal/RealtorProfileContactModal';

import './realtormatchprofile.css';

export default class RealtorMatchProfile extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isChangeStatusModalVisible: false,
            isTransactionContractModalVisible: false,
            isMatchFileUploadModalVisible: false,
            contactSelected: null,
            transactionContactTypeSelected: {},
            note: '',
            isDocumentSaved: false,
            isContactModalVisible: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isUploading === true && this.props.isUploading === false) {
            if (this.props.isFetchedUploading) {
                this.onSaveDocument();
                delay(this.onCloseMatchFileUploadModal, 400);
            }
        }
    }

    buildContactPayload = (contact) => ({
        id: get(contact, 'id'),
        firstName: get(contact, 'firstName'),
        lastName: get(contact, 'lastName'),
        businessName: get(contact, 'businessName'),
        email: get(contact, 'email'),
        /*address: {
            id: get(contact, 'address.id'),
            streetNumberName: get(contact, 'address.streetNumberName'),
            apartmentSuiteNumber: get(contact, 'address.apartmentSuiteNumber'),
            city: get(contact, 'address.city'),
            zipcode: get(contact, 'address.zipcode'),
            ts: get(contact, 'address.ts'),
            full: get(contact, 'address.full'),
            longitude: get(contact, 'address.longitude'),
            latitude: get(contact, 'address.latitude'),
            state: get(contact, 'address.state.abbreviation'),
        },*/
        businessPhone: get(contact, 'businessPhone'),
        businessFax: get(contact, 'businessFax'),
    });

    onSaveContactInfo = () => {
        const { buyerUserInfoId } = this.props;
        const contact = get(this.state, 'contactSelected.value');
        const data = {
            transactionContacts: [
                {
                    contact: this.buildContactPayload(contact),
                    transactionContactType: get(this.state, 'transactionContactTypeSelected'),
                    note: get(this.state, 'note'),
                },
            ],
        };
        this.props.onSaveContactInfo(buyerUserInfoId, data);
        this.onCloseTransactionContractModal();
    }

    onSaveSellerContactInfo = () => {
        const { sellerUserInfoId } = this.props;
        const contact = get(this.state, 'contactSelected.value');
        const data = {
            transactionContacts: [
                {
                    contact: this.buildContactPayload(contact),
                    transactionContactType: get(this.state, 'transactionContactTypeSelected'),
                    note: get(this.state, 'note'),
                },
            ],
        };
        this.props.onSaveSellerContactInfo(sellerUserInfoId, data);
        this.onCloseTransactionContractModal();
    }

    onChangeContact = (contact) => {
        const hasValue = get(contact, 'value');

        if (hasValue) {
            this.setState({ contactSelected: contact });
        }
    };

    onCreateContact = (label) => {
        const emptyNewContact = {};

        this.setState({
            contactSelected: {
                label,
                value: emptyNewContact,
            },
        });
    };

    onCloseChangeStatusModal = () => this.setState({
        isChangeStatusModalVisible: false,
    });

    onCloseTransactionContractModal = () => this.setState({
        isTransactionContractModalVisible: false,
    });

    onOpenChangeStatusModal = () => this.setState({
        isChangeStatusModalVisible: true,
    });

    onOpenTransactionContractModal = (contactType) => this.setState({
        isTransactionContractModalVisible: true,
        transactionContactTypeSelected: contactType,
        contactSelected: null,
    });

    onOpenMatchFileUploadModal = () => (
        this.setState({ isMatchFileUploadModalVisible: true, isDocumentSaved: false })
    );

    onOpenContactModal = () => {
        this.setState({ isContactModalVisible: true });
    };

    onCloseContactModal = () => {
        this.setState({ isContactModalVisible: false });
    };

    onCloseMatchFileUploadModal = () => (
        this.setState({ isMatchFileUploadModalVisible: false })
    );

    onSaveDocument = () => (this.setState({ isDocumentSaved: true }));

    getNameLastName = (item) => `${get(item, 'firstName', '')} ${get(item, 'lastName', '')}`;

    onViewContactInfoClick = (contactType) => {
        const contact = find(this.props.transactionContacts, (item) => get(item, 'transactionContactType.id') === get(contactType, 'id'));
        this.setState({
            isTransactionContractModalVisible: true,
            transactionContactTypeSelected: contactType,
            contactSelected: {
                label: this.getNameLastName(get(contact, 'contact')),
                value: get(contact, 'contact'),
            },
            note: get(contact, 'note'),
        });
    };

    buildCloseButton = () => (
        <div className="realtor-match-profile__close-button">
            <Link
                className="realtor-navbar-avatar__link"
                to="/realtor/active-matches"
            >
                <CloseModalButton label="Close" />
            </Link>
        </div>
    );

    onGetContacts = (term) => autocompleteContract(
        term,
        get(this.state.transactionContactTypeSelected, 'name')
    );

    onChangeNotes = (data) => this.setState({ note: data });

    buildLeftColumn = () => (
        <RealtorMatchProfileLeftColumn
            picture={this.props.picture}
            onChangeStatus={this.onOpenChangeStatusModal}
            onOpenModal={this.onOpenMatchFileUploadModal}
            onContact={this.onOpenContactModal}
            name={this.props.name}
            location={this.props.location}
            budget={this.props.budget}
            maxBudget={this.props.maxBudget}
            rebate={this.props.rebate}
            brewScore={this.props.brewScore}
            status={this.props.status}
            email={this.props.email}
            phone={this.props.phone}
        />
    );

    buildSubjectPropertyInformation = () => (
        <RealtorMatchProfileSubjectPropertyInformation
            onUpdateLocationInformation={this.props.onUpdateLocationInformation}
            propertyAddress={this.props.propertyAddress}
            commission={this.props.commission}
            finalPurchasePrice={this.props.finalPurchasePrice}
            newConstruction={this.props.newConstruction}
            anticipatedClosingDate={this.props.anticipatedClosingDate}
        />
    );

    buildNumber = (number) => (
        <NumberFormat
            number={get(number, 'amount')}
            currency={get(number, 'currency')}
            decimals={0}
            format="currency"
        />
    );

    buildMinToMaxBudget = () => {
        const budget = (
            <NumberFormat
                number={get(this.props.budget, 'amount')}
                currency={get(this.props.budget, 'currency')}
                decimals={0}
                format="currency"
            />
        );

        const maxBudget = (
            <NumberFormat
                number={get(this.props.maxBudget, 'amount')}
                currency={get(this.props.maxBudget, 'currency')}
                decimals={0}
                format="currency"
            />
        );

        return (
            <span>
                {budget}
                {' - '}
                {maxBudget}
            </span>
        );
    };

    buildPurchaseSummary = () => {
        return (
            <RealtorMatchSummary>
                <RealtorMatchItemSummary icon="IconMapPin" label="Location:" value={this.props.location} />
                <RealtorMatchItemSummary icon="IconCalendar" label="Timeframe:" value={this.props.timeFrame} />
                {this.props.isSeller && <RealtorMatchItemSummary icon="IconBudget" label="Property Value:" value={this.buildMinToMaxBudget()} />}
                {this.props.isSeller && <RealtorMatchItemSummary icon="IconBudget" label="Bedrooms:" value={this.props.bedRooms} />}
                {this.props.isSeller && <RealtorMatchItemSummary icon="IconBudget" label="Bathrooms:" value={this.props.bathRooms} />}
                {!this.props.isSeller && <RealtorMatchItemSummary icon="IconBudget" label="Budget:" value={this.buildMinToMaxBudget()} />}
                {!this.props.isSeller && <RealtorMatchItemSummary icon="IconMoney" label="Desired Down Payment:" value={this.buildNumber(this.props.desiredDownPayment)} />}
                {!this.props.isSeller && <RealtorMatchItemSummary icon="IconPayment" label="Verified Down Payment:" value={this.buildNumber(this.props.verifiedDownPayment)} />}
                {!this.props.isSeller && <RealtorMatchItemSummary icon="IconLoan" label="Estimated Loan Amount:" value={this.buildNumber(this.props.loanAmount)} />}
                {!this.props.isSeller && <RealtorMatchItemSummary icon="IconMortgage" label="Estimated Mortgage:" value={this.buildNumber(this.props.mortgage)} />}
                {!this.props.isSeller && <RealtorMatchItemSummary icon="IconHouse" label="Property Type:" value={this.props.homeType} />}
            </RealtorMatchSummary>
        );
    };

    buildMatchAttachments = () => (
        <RealtorMatchProfileAttachments
            documents={this.props.documents}
            onRemoveFile={this.props.onRemoveFile}
            onOpenMatchFileUploadModal={this.onOpenMatchFileUploadModal}
        />
    );

    buildRightColumn = () => {
        const isMatchUnderContract = (
            this.props.idStatus === realtorMatchUnderContractStatus ||
            this.props.idStatus === realtorSellerMatchUnderContractStatus
        );
        let subjectPropertyInformation;
        let transactionInfo;
        let purchaseSummary;

        if (isMatchUnderContract) {
            subjectPropertyInformation = !this.props.isSeller && this.buildSubjectPropertyInformation();
            transactionInfo = (
                <RealtorMatchProfileTransactionInfo
                    transactionContacts={this.props.transactionContacts}
                    transactionContactTypes={this.props.transactionContactTypes}
                    onAddContactInfoClick={this.onOpenTransactionContractModal}
                    onViewContactInfoClick={this.onViewContactInfoClick}
                />
            );
        }

        if (!isMatchUnderContract || this.props.isSeller) {
            purchaseSummary = this.buildPurchaseSummary();
        }

        return (
            <section>
                <RealtorMatchProfileHeader
                    isSeller={this.props.isSeller}
                    timeFrame={this.props.timeFrame}
                    name={this.props.name}
                    location={this.props.location}
                    budget={this.props.budget}
                    maxBudget={this.props.maxBudget}
                    rebate={this.props.rebate}
                    brewScore={this.props.brewScore}
                    status={this.props.status}
                />
                {subjectPropertyInformation}
                {purchaseSummary}
                {transactionInfo}
                {this.buildMatchAttachments()}
                <RealtorMatchProfileNotes notes={this.props.notes} />
            </section>
        );
    };

    onChangeServicesStatus = (reason) => {
        this.props.onConfirmChangeStatus(reason);
        this.onCloseChangeStatusModal();
    };

    render = () => (
        <div className="realtor-match-profile">
            <MatchFileUploadModal
                isVisible={this.state.isMatchFileUploadModalVisible}
                onClose={this.onCloseMatchFileUploadModal}
                onUploadFile={this.props.onUploadFile}
                documentTypes={this.props.documentTypes}
                onUploadSelectTypeFile={this.props.onUploadSelectTypeFile}
                isUploading={this.props.isUploading}
                isDocumentSaved={this.state.isDocumentSaved}
            />
            <ChangeStatusModal
                isVisible={this.state.isChangeStatusModalVisible}
                onClose={this.onCloseChangeStatusModal}
                onChangeStatus={this.onChangeServicesStatus}
                realtorStatusOptions={this.props.realtorStatusOptions}
                onSelectStatus={this.props.onSelectStatus}
            />
            <RealtorProfileTransactionContactModal
                isVisible={this.state.isTransactionContractModalVisible}
                onClose={this.onCloseTransactionContractModal}
                onCancel={this.onCloseTransactionContractModal}
                onGetContacts={this.onGetContacts}
                onSave={this.props.isSeller ? this.onSaveSellerContactInfo : this.onSaveContactInfo}
                contact={this.state.contactSelected}
                onChangeNotes={this.onChangeNotes}
                onChangeContact={this.onChangeContact}
                onCreateContact={this.onCreateContact}
                notes={this.state.note}
            />
            <RealtorProfileContactModal
                isSeller={this.props.isSeller}
                buyerId={this.props.id}
                brokerageName={this.props.brokerageName}
                buyerName={this.props.name}
                agentName={this.props.agentName}
                agentPhone={this.props.agentPhone}
                citiesBuyerLookingInto={this.props.citiesBuyerLookingInto}
                isVisible={this.state.isContactModalVisible}
                onClose={this.onCloseContactModal}
                onSuccessfullyContact={this.onCloseContactModal}
            />
            {this.buildCloseButton()}

            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-2">
                    {this.buildLeftColumn()}
                </div>
                <div className="col-xs-12 col-sm-8 col-md-10">
                    {this.buildRightColumn()}
                </div>
            </div>
        </div>
    );
}

RealtorMatchProfile.propTypes = {
    isSeller: PropTypes.bool,
    id: PropTypes.number.isRequired,
    brokerageName: PropTypes.string.isRequired,
    agentName: PropTypes.string.isRequired,
    agentPhone: PropTypes.string.isRequired,
    citiesBuyerLookingInto: PropTypes.arrayOf(PropTypes.string).isRequired,
    picture: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    timeFrame: PropTypes.string.isRequired,
    budget: PropTypes.any.isRequired,
    maxBudget: PropTypes.any.isRequired,
    desiredDownPayment: PropTypes.object.isRequired,
    verifiedDownPayment: PropTypes.object.isRequired,
    loanAmount: PropTypes.object.isRequired,
    mortgage: PropTypes.object.isRequired,
    homeType: PropTypes.string,
    rebate: PropTypes.number.isRequired,
    brewScore: PropTypes.number.isRequired,
    idStatus: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    realtorStatusOptions: PropTypes.array.isRequired,
    onSelectStatus: PropTypes.func.isRequired,
    onConfirmChangeStatus: PropTypes.func.isRequired,
    onSaveContactInfo: PropTypes.func.isRequired,
    documents: PropTypes.array,
    onUploadFile: PropTypes.func,
    onUploadSelectTypeFile: PropTypes.func,
    isUploading: PropTypes.bool,
    isFetchedUploading: PropTypes.bool,
    onRemoveFile: PropTypes.func,
    transactionContactTypes: PropTypes.array.isRequired,
    transactionContacts: PropTypes.array,
    notes: PropTypes.array,
    buyerUserInfoId: PropTypes.number.isRequired,
    propertyAddress: PropTypes.object,
    commission: PropTypes.number,
    finalPurchasePrice: PropTypes.number,
    newConstruction: PropTypes.bool,
    anticipatedClosingDate: PropTypes.string,
    onUpdateLocationInformation: PropTypes.func.isRequired,
    documentTypes: PropTypes.array.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string,
    onSaveSellerContactInfo: PropTypes.func,
};
