import React from 'react';
import get from 'lodash/get';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask({
    allowDecimal: true,
});

export default function AmountFloatField({field, form, ...props}) {
    const handleChange = (e) => {
        const stringWithFloatFormat = `${get(e, 'target.value')}`.replace(/[$,_ ]/g, '');
        form.setFieldValue(field.name, stringWithFloatFormat);
    };

    return (
        <MaskedInput
            mask={numberMask}
            {...field}
            {...props}
            onChange={handleChange}
        />
    );
};
