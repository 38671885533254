import get from "lodash/get";
import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import UserAdminProfile from "./UserAdminProfile";
import {
    getUsersAsAdmin,
    getUsersRole,
    impersonateUser,
    impersonateUserByRole,
    resendRealtorAgreement,
    resendBrokerAgreement,
    suspendUser
} from "../../reducers/admin/AdminActions";
import { completeTransferReceipt } from "../../reducers/prospect/prospectActions";
import { createNewEvent } from "../../reducers/spin/SpinActions";
import { PREDEFINED_INMAN_WHEEL } from "../../shared/constants";

class AdminProfile extends React.Component {
    static propTypes = {
        admin: PropTypes.object,
        onGetUsersAsAdmin: PropTypes.func,
        onGetUsersRole: PropTypes.func,
        loginImpersonateUser: PropTypes.func,
        loginImpersonateUserByRole: PropTypes.func,
        onResendRealtorAgreement: PropTypes.func,
        onResendBrokerAgreement: PropTypes.func,
        onSuspendUser: PropTypes.func,
        onCreateNewEvent: PropTypes.func,
        onCompleteTransferReceipt: PropTypes.func,
        isFetchingTransferReceipt: PropTypes.bool,
        successTransferReceipt: PropTypes.bool,
        licensedStates: PropTypes.object
    };

    getUsers = () => get(this.props.admin, "getUsers.users");

    getIsFetchedGetUsers = () =>
        get(this.props.admin, "getUsers.isFetchedGetUsers");

    getIsFetchingGetUsers = () =>
        get(this.props.admin, "getUsers.isFetchingGetUsers");

    createNewEvent = () => this.props.onCreateNewEvent(PREDEFINED_INMAN_WHEEL);

    render = () => {
        const isImpersonatingUser = get(
            this.props.admin,
            "loginImpersonateUser.successImpersonateUser"
        );

        if (isImpersonatingUser) {
            return <Redirect to="/profile" />;
        }

        return (
            <UserAdminProfile
                getUsersAsAdmin={this.props.onGetUsersAsAdmin}
                getUsersRole={this.props.onGetUsersRole}
                users={this.getUsers()}
                roles={this.props.roles}
                isFetchedGetUsers={this.getIsFetchedGetUsers()}
                isFetchingGetUsers={this.getIsFetchingGetUsers()}
                onImpersonateUser={this.props.loginImpersonateUser}
                onImpersonateUserByRole={this.props.loginImpersonateUserByRole}
                onResendRealtorAgreement={this.props.onResendRealtorAgreement}
                onResendBrokerAgreement={this.props.onResendBrokerAgreement}
                onSuspendUser={this.props.onSuspendUser}
                onCreateNewEvent={this.createNewEvent}
                completeTransferReceipt={this.props.onCompleteTransferReceipt}
                isFetchingTransferReceipt={this.props.isFetchingTransferReceipt}
                successTransferReceipt={this.props.successTransferReceipt}
                licensedStates={this.props.licensedStates}
            />
        );
    };
}

export default connect(
    state => ({
        admin: state.admin,
        roles: get(state, "user.profile.publicProfile.userInfo.roles", []),
        licensedStates: get(state, "configApp.config.licensedStates", []),
        isFetchingTransferReceipt: get(
            state,
            "prospect.isFetchingTransferReceipt"
        ),
        successTransferReceipt: get(state, "prospect.successTransferReceipt")
    }),
    {
        onGetUsersAsAdmin: getUsersAsAdmin,
        onGetUsersRole: getUsersRole,
        loginImpersonateUser: impersonateUser,
        loginImpersonateUserByRole: impersonateUserByRole,
        onResendRealtorAgreement: resendRealtorAgreement,
        onResendBrokerAgreement: resendBrokerAgreement,
        onSuspendUser: suspendUser,
        onCreateNewEvent: createNewEvent,
        onCompleteTransferReceipt: completeTransferReceipt
    }
)(AdminProfile);
