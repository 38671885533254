import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';

export default class AutoCompleteFormikInput extends React.PureComponent {
    static propTypes = {
        customOnChange: PropTypes.func,
        isMulti: PropTypes.bool,
    };

    handleChange = (selectedOptions) => {
        const { isMulti, customOnChange } = this.props;

        if (!isFunction(customOnChange)) {
            return;
        }

        const value = isMulti
            ? map(selectedOptions, 'value')
            : get(selectedOptions, 'value');

        customOnChange(value);
    };

    render = () => (
        <AutoCompleteInput
            {...this.props}
            onChange={this.handleChange}
        />
    );
}
