import React from "react";
import mixpanel from "mixpanel-browser";
import EmdSignUpWizardOrProfileIfCompleted from "./EmdSignUpWizardOrProfileIfCompleted";

class EmdSignUp extends React.PureComponent {
    componentDidMount = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_PROSPECT_TOKEN);
    };

    render = () => <EmdSignUpWizardOrProfileIfCompleted />;
}

export default EmdSignUp;
