import React, { Fragment } from "react";
import get from "lodash/get";
import set from "lodash/set";
import each from "lodash/each";
import forEach from "lodash/forEach";
import head from "lodash/head";
import pick from "lodash/pick";
import { Field, FieldArray } from "formik";
import { Locality, adaptStreetAddress } from "../../BuyerSignUp/Shared";
import { AddressAutocompleteField } from "../../../Form";
import BaseWizardStep from "../../BuyerSignUp/BaseWizardStep";
import { getAddressFromPlaceDetails } from "../../../../util/util";
import mixpanel from "mixpanel-browser";

const AddressFields = Object.keys(Locality);

class StepAddress extends BaseWizardStep {
    constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }

    updateBudgetsAndAdvance = () => {
        this.setState({ isLoading: true });
        this.updateBudgetOptions().finally(this.advanceStep);
    };

    advanceStep = () => {
        const hasMixPanelTracking = true;
        setTimeout(
            () =>
                this.props.onNavigateStep(
                    1,
                    hasMixPanelTracking,
                    this.mixpanelFunction
                ),
            0
        );
    };

    mixpanelFunction = () => {
        const { values } = this.props;
        let locations = {};
        forEach(get(values, "knownLocations"), (value, index) => {
            set(locations, `newAddress${index}`, get(value, "address"));
            set(
                locations,
                `newAddressApartmentSuiteNumber${index}`,
                get(value, "apartmentSuiteNumber")
            );
            set(locations, `newAddressZipcode${index}`, get(value, "zipcode"));
            set(
                locations,
                `newAddressNeighborhood${index}`,
                get(value, "neighborhood")
            );
            set(locations, `newAddressCity${index}`, get(value, "city"));
            set(locations, `newAddressState${index}`, get(value, "state"));
            set(locations, `newAddressLat${index}`, get(value, "lat"));
            set(locations, `newAddressLng${index}`, get(value, "lng"));
        });

        mixpanel.track(`Completed What is the Property Address?`, {
            ...locations
        });
    };

    updatePropertyFields = result => {
        const { setFieldValue } = this.props;

        const {
            value,
            valueLow,
            valueHigh,
            totalBaths,
            totalBedrooms
        } = result;
        const moreThanFiveBedrooms = totalBedrooms > 5 ? 5 : totalBedrooms;
        const moreThanFiveBaths = totalBaths > 5 ? 5 : totalBaths;

        setFieldValue("homeValue", value || undefined);
        setFieldValue("homeValueMin", valueLow || undefined);
        setFieldValue("homeValueMax", valueHigh || undefined);
        setFieldValue(
            "numberOfBedrooms",
            totalBedrooms ? moreThanFiveBedrooms : undefined
        );
        setFieldValue(
            "numberOfBathrooms",
            totalBaths ? moreThanFiveBaths : undefined
        );
    };

    updateBudgetOptions() {
        const {
            setFieldValue,
            setFieldTouched,
            customBudgetRange,
            values
        } = this.props;

        const knownLocations = get(values, "knownLocations");
        const sellerNewAddresses = head(knownLocations.map(adaptStreetAddress));
        const data = pick(sellerNewAddresses, [
            "streetNumberName",
            "zipcode",
            "city",
            "state"
        ]);

        each(["budget", "downPayment"], field => {
            setFieldValue(field, "");
            setFieldTouched(field, false);
        });

        return customBudgetRange.update(data).then(this.updatePropertyFields);
    }

    renderKnownLocation() {
        const {
            setFieldValue,
            setFieldTouched,
            values: { knownLocations }
        } = this.props;

        const updateAddressDetails = (index, [details] = []) => {
            const components = getAddressFromPlaceDetails(details);

            [...AddressFields, "zipcode"].forEach(key => {
                const field = `knownLocations.${index}.${key}`;
                setFieldValue(field, components[key]);
                setFieldTouched(field);
            });
        };

        return (
            <Fragment>
                <FieldArray
                    name="knownLocations"
                    render={() => (
                        <div className="buyer__signup__fieldset">
                            {knownLocations.map((location, index) => (
                                <div
                                    key={index}
                                    className="buyer__signup__field__row"
                                >
                                    <div className="buyer__signup__field width-70">
                                        <Field
                                            component={AddressAutocompleteField}
                                            name={`knownLocations.${index}.address`}
                                            placeholder="Address"
                                            onPlaceDetails={details =>
                                                updateAddressDetails(
                                                    index,
                                                    details
                                                )
                                            }
                                        />
                                        {this.renderFieldError(
                                            `knownLocations.${index}.address`
                                        )}
                                        {this.renderFieldError(
                                            `knownLocations.${index}.zipcode`
                                        )}
                                    </div>

                                    <div className="buyer__signup__field width-30">
                                        <Field
                                            type="text"
                                            name={`knownLocations.${index}.apartmentSuiteNumber`}
                                            placeholder="Unit/Apt #"
                                        />
                                        {this.renderFieldError(
                                            `knownLocations.${index}.apartmentSuiteNumber`
                                        )}
                                    </div>
                                </div>
                            ))}

                            <div className="buyer__signup__field__row">
                                <div className="buyer__signup__field">
                                    {this.renderFormError("knownLocations")}
                                </div>
                            </div>
                        </div>
                    )}
                />
            </Fragment>
        );
    }

    render() {
        const { isLoading } = this.state;
        const isStepFulfilled = this.areFieldsValid(["knownLocations"]);

        return (
            <Fragment>
                <div className="buyer__signup__step is-highest">
                    <div className="buyer__signup__question white-font">
                        What is the property address?
                    </div>
                    {this.renderKnownLocation()}
                </div>
                {this.renderStepButtons(
                    {
                        canProceed: isStepFulfilled,
                        hasMixPanelTracking: true,
                        isLoading,
                        handleNext: this.updateBudgetsAndAdvance
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepAddress;
