import * as signUpActionTypes from './signUpActionTypes';

const initialState = {
    voaUrl: '',
    shouldCheckVoaStatus: false,
};

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case signUpActionTypes.RECEIVE_VOA_URL: {
        return {
            ...state,
            voaUrl: payload,
        };
    }

    case signUpActionTypes.RECEIVE_VOA_STATUS:
        return {
            ...state,
            shouldCheckVoaStatus: payload,
        };

    default:
        return {
            ...state,
        };
    }
};

export default userReducer;
