import React from 'react';
import PropTypes from 'prop-types';

const AgentPhone = (props) => {
    const withoutPadding = props.withoutPadding ? 'admin-role-tables__phone-without-padding ' : '';
    return (
        <div className={`${withoutPadding}admin-role-tables__agent-phone${props.classIsMobile}`}>
            <div className="admin-role-tables__agent-phone-text">
                PHONE
            </div>
            <a href={`tel:${props.phone}`}>	
                {props.phone}
            </a>	
        </div>	
    )
}

AgentPhone.propTypes = {
    phone: PropTypes.any,
    classIsMobile: PropTypes.string,
    withoutPadding: PropTypes.bool,
};

AgentPhone.defaultProps = {
    classIsMobile: '',
    withoutPadding: false,
}

export default AgentPhone;
