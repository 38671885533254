import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import trim from 'lodash/trim';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { Field, ErrorMessage, getIn } from 'formik';
import AutoCompleteFormikInput from '../AutoCompleteFormikInput/AutoCompleteFormikInput';
import RecaptchaFormikField from '../RecaptchaFormikField/RecaptchaFormikField';
import './realtorProfileBrokerageInformationForm.css';
import { brokerageAutocomplete } from '../../util/service_api';
import { PhoneField } from '../Form';

import iconApproved from '../../styles/assets/icon-approved.svg';
import iconAwaitingApproval from '../../styles/assets/icon-awaiting-approval.svg';
import BrokerageLabel from '../BrokerageLabel/BrokerageLabel';

export default class RealtorProfileBrokerageInformationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBrokerageOption: this.buildBrokerageInformation(props),
            fieldsDisabled: false,
        };
    }

    buildBrokerageInformation = (props) => {
        if (!get(props.values, 'aboutYourBrokerage.brokerage')) return null;
        const brokerage = {
            value: get(props.values, 'aboutYourBrokerage.brokerage'),
            label: get(props.values, 'aboutYourBrokerage.brokerage.name')
        };
        return brokerage;
    };

    changeContactField = (fieldName, fieldValue) => {
        const { setFieldValue } = this.props;
        setFieldValue(fieldName, fieldValue);
    };

    onChangeBrokerFirstName = (e) => this.changeContactField(
        'aboutYourBrokerage.brokerFirstName',
        e.target.value
    );

    onChangeBrokerLastName = (e) => this.changeContactField(
        'aboutYourBrokerage.brokerLastName',
        e.target.value
    );

    onChangeBrokerPhoneNumber = (e) => this.changeContactField(
        'aboutYourBrokerage.brokerPhoneNumber',
        e.target.value
    );

    onChangeBrokerEmail = (e) => this.changeContactField(
        'aboutYourBrokerage.brokerEmail',
        e.target.value
    );

    setFormFieldsValue = (value) => {
        const { setFieldValue } = this.props;
        const brokerId = get(value, 'broker.id');
        const brokerFirstName = get(value, 'broker.firstName');
        const brokerLastName = get(value, 'broker.lastName');
        const brokerPhoneNumber = get(value, 'broker.phoneNumber');
        const brokerEmail = get(value, 'broker.email');
        setFieldValue('aboutYourBrokerage.brokerId', brokerId);
        setFieldValue('aboutYourBrokerage.brokerFirstName', brokerFirstName);
        setFieldValue('aboutYourBrokerage.brokerLastName', brokerLastName);
        setFieldValue('aboutYourBrokerage.brokerPhoneNumber', brokerPhoneNumber);
        setFieldValue('aboutYourBrokerage.brokerEmail', brokerEmail);
        this.setState({
            fieldsDisabled: true,
        });
    }

    buildForm = () => (
        <div className="row">
            <div className="col-xs-12">
                {this.buildBrokerageConfirmationFields()}
            </div>
        </div>
    );

    buildBrokerageField = () => {
        const { stateAbbreviation } = this.props;
        const { selectedBrokerageOption } = this.state;
        const fieldName = 'aboutYourBrokerage.brokerage';

        const getOptions = (term) => (
            brokerageAutocomplete(stateAbbreviation, term)
                .then((response) => {
                    const options = get(response, 'data');
                    const approvedAgentsFirst = sortBy(options, agent => !get(agent, 'approved'));
                    return map(approvedAgentsFirst, (result) => ({
                        value: result,
                        label: (
                            <BrokerageLabel
                                name={trim(get(result, 'name'))}
                                isApproved={get(result, 'approved')}
                                showApprovalStatusIcon={get(result, 'approved')}
                            />
                        )
                    }));
                })
        );

        const handleOnChange = (value) => {
            this.cleanBrokerForm();

            this.props.setFieldTouched(fieldName);
            this.props.setFieldValue(fieldName, value);

            const hasBroker = get(value, 'broker');
            if (hasBroker) this.setFormFieldsValue(value);

            const selectedBrokerageOptionObj = {
                label: get(value, 'name') || value,
                value,
            };

            this.setState({
                selectedBrokerageOption: selectedBrokerageOptionObj
            });
        };

        const onCreateBrokerageFirm = (newBrokerageName) => {
            handleOnChange(newBrokerageName);
        };

        return (
            <div className="realtor-profile-brokerage-information-form__contact-autocomplete">
                <Field
                    onCreateOption={onCreateBrokerageFirm}
                    component={AutoCompleteFormikInput}
                    name={fieldName}
                    value={selectedBrokerageOption}
                    placeholder="LOOK FOR YOUR BROKERAGE FIRM"
                    getOptions={getOptions}
                    getOptionsMillisecondsDelay={500}
                    openMenuOnClick={false}
                    customOnChange={handleOnChange}
                    isClearable
                    cacheOptions={false}
                    components={{ DropdownIndicator: () => null }}
                    styles={{
                        clearIndicator: (base) => ({ ...base, color: '#da3c00' }),
                        indicatorSeparator: () => ({ display: 'none' }),
                    }}
                    isOnTop
                />
            </div>
        );
    }

    buildBrokerageBlurb =() => (
        <div>
            <div className="realtor-profile-brokerage-information-form__informative-title">
                Your Brokerage:
            </div>
            <div className="realtor-profile-brokerage-information-form__informative-text">
                Before we can start matching you with quality leads, please enter the contact
                {' '}
                information for the person who is in charge of your Brokerage.
            </div>
        </div>
    );

    cleanBrokerForm = () => {
        this.props.setFieldValue('aboutYourBrokerage.brokerId', undefined);
        this.props.setFieldValue('aboutYourBrokerage.brokerFirstName', '');
        this.props.setFieldValue('aboutYourBrokerage.brokerLastName', '');
        this.props.setFieldValue('aboutYourBrokerage.brokerPhoneNumber', '');
        this.props.setFieldValue('aboutYourBrokerage.brokerEmail', '');
        this.setState({
            fieldsDisabled: false,
            selectedBrokerageOption: {},
        });
    };

    getFieldError = (fieldName) => {
        const { errors, touched } = this.props;
        if (getIn(touched, fieldName)) {
            const message = getIn(errors, fieldName);
            return message;
        }
        return null;
    };

    renderFieldError = (field) => (
        <ErrorMessage name={field}>
            {(msg) => (
                <div className="signin__field__error">
                    {msg}
                </div>
            )}
        </ErrorMessage>
    );

    validate = (value) => {
        let errorMessage;
        if (!/^[a-zA-Z,.!? ]*$/.test(value)) {
            errorMessage = 'This field accepts only letters and dots';
        }
        return errorMessage;
    };

    buildBrokerNameFields = () => (
        <div className="two-columns field">
            <div>
                <Field
                    type="text"
                    name="aboutYourBrokerage.brokerFirstName"
                    placeholder="BROKER FIRST NAME"
                    className="realtor-profile-brokerage-information-form"
                    onChange={this.onChangeBrokerFirstName}
                    disabled={this.state.fieldsDisabled}
                    validate={this.validate}
                />
                {this.renderFieldError('aboutYourBrokerage.brokerFirstName')}
            </div>
            <div>
                <Field
                    type="text"
                    name="aboutYourBrokerage.brokerLastName"
                    placeholder="BROKER LAST NAME"
                    onChange={this.onChangeBrokerLastName}
                    disabled={this.state.fieldsDisabled}
                    validate={this.validate}
                />
                {this.renderFieldError('aboutYourBrokerage.brokerLastName')}
            </div>
        </div>
    );

    buildBrokerContactFields = () => (
        <div className="two-columns field">
            <div>
                <Field
                    type="email"
                    name="aboutYourBrokerage.brokerEmail"
                    placeholder="BROKER EMAIL"
                    onChange={this.onChangeBrokerEmail}
                    disabled={this.state.fieldsDisabled}
                />
                {this.renderFieldError('aboutYourBrokerage.brokerEmail')}
            </div>
            <div>
                <Field
                    type="text"
                    name="aboutYourBrokerage.brokerPhoneNumber"
                    placeholder="BROKER PHONE NUMBER"
                    component={PhoneField}
                    onChange={this.onChangeBrokerPhoneNumber}
                    disabled={this.state.fieldsDisabled}
                />
                {this.renderFieldError('aboutYourBrokerage.brokerPhoneNumber')}
            </div>
        </div>
    );

    buildBrokerageStatusIcon = (icon, value) => (
        <img width="24" height="24" src={icon} alt={value.approved ? 'Approved' : 'Waiting Approval'} />
    );

    buildApprovedBrokerage = () => {
        const { values } = this.props;
        const { selectedBrokerageOption } = this.state;
        const value = get(selectedBrokerageOption, 'value');
        const firstName = get(values, 'aboutYourBrokerage.brokerFirstName');
        const lastName = get(values, 'aboutYourBrokerage.brokerLastName');
        const phoneNumber = get(values, 'aboutYourBrokerage.brokerPhoneNumber');
        const email = get(values, 'aboutYourBrokerage.brokerEmail');
        const icon = value.approved ? iconApproved : iconAwaitingApproval;

        return (
            <div className="realtor-profile-brokerage-information-form__approved-brokerage">
                <div className="realtor-profile-brokerage-information-form__approved-brokerage__title">
                    Your Brokerage:
                </div>
                <div className="realtor-profile-brokerage-information-form__approved-brokerage__congrats">
                    Congratulations! Your Brokerage is already a member of the LemonBrew Platform.
                    {' '}
                    Below are details of your Brokerage.
                </div>
                <div className="realtor-profile-brokerage-information-form__approved-brokerage__info-wraper">
                    <span className="realtor-profile-brokerage-information-form__approved-brokerage__check">
                        { this.buildBrokerageStatusIcon(icon, value)}
                    </span>
                    <div className="realtor-profile-brokerage-information-form__approved-brokerage__brokerage-title">
                        {firstName}
                        {' '}
                        {lastName}
                    </div>
                    <div className="realtor-profile-brokerage-information-form__approved-brokerage__brokerage-mail">
                        {email}
                    </div>
                    <div className="realtor-profile-brokerage-information-form__approved-brokerage__brokerage-number">
                        {phoneNumber}
                    </div>
                </div>
            </div>
        );
    };

    showBrokerFields = () => (
        <>
            {this.buildBrokerageBlurb()}
            {this.buildBrokerNameFields()}
            {this.buildBrokerContactFields()}
        </>
    );

    buildBrokerageConfirmationFields = () => {
        const { selectedBrokerageOption, fieldsDisabled } = this.state;
        const value = get(selectedBrokerageOption, 'value');
        const disabled = fieldsDisabled;

        if (value) {
            if (value.approved || (disabled && !value.approved)) {
                return this.buildApprovedBrokerage();
            }

            return this.showBrokerFields();
        }

        return null;
    };

    hasSelectedBroker = () => {
        const { values } = this.props;
        const firstName = get(values, 'aboutYourBrokerage.brokerFirstName');
        const lastName = get(values, 'aboutYourBrokerage.brokerLastName');
        const phoneNumber = get(values, 'aboutYourBrokerage.brokerPhoneNumber');
        const email = get(values, 'aboutYourBrokerage.brokerEmail');
        const fields = ['aboutYourBrokerage.brokerFirstName', 'aboutYourBrokerage.brokerLastName', 'aboutYourBrokerage.brokerPhoneNumber', 'aboutYourBrokerage.brokerEmail'];
        const fieldWithError = find(fields, this.getFieldError);

        return firstName && lastName && phoneNumber && email && !fieldWithError;
    };

    onCloseModal = () => {
        const fields = ['aboutYourBrokerage.brokerFirstName', 'aboutYourBrokerage.brokerLastName', 'aboutYourBrokerage.brokerPhoneNumber', 'aboutYourBrokerage.brokerEmail'];
        const fieldWithError = find(fields, this.getFieldError);
        const hasError = fieldWithError !== undefined;
        if (!hasError) { this.props.onClose(); }
    };

    onCancel = () => {
        this.cleanBrokerForm();
        this.props.onCancel();
    }

    buildRecaptchaField = () => (
        <div className="realtor-profile-brokerage-information-form__recaptcha-container">
            <Field
                component={RecaptchaFormikField}
                name="aboutYourBrokerage.recaptchaSuccessfullyCompleted"
            />
        </div>
    );

    render = () => (
        <div>
            {this.buildBrokerageField()}
            {this.buildForm()}
            {this.buildRecaptchaField()}
        </div>
    );
}

RealtorProfileBrokerageInformationForm.propTypes = {
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    stateAbbreviation: PropTypes.string,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.shape({}),
};
