import React from "react";
import * as PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import {
    BarChart,
    BarSeries,
    Bar,
    Gradient,
    TooltipArea,
    ChartTooltip,
    GradientStop
} from "reaviz";

class AdminProfileStatsCurrentQuarter extends React.Component {
    estructureDataChart = array =>
        map(array, (item, key) => ({
            key,
            data: [
                {
                    key: "NEW",
                    data: get(item, "all")
                },
                {
                    key: "APPROVED",
                    data: get(item, "approved")
                }
            ]
        }));

    render = () => {
        const dataChart = this.estructureDataChart(
            this.props.getRealtorsByCurrentQuarter
        );
        const gradient = (
            <Gradient stops={[<GradientStop stopOpacity={1} />]} />
        );
        const rounded = true;
        const widthBar = 10;
        const paddingBar = 0;
        const groupPadding = 80;
        const barChartWidth = 300;
        const barChartHeight = 100;
        const tooltip = (
            <TooltipArea
                tooltip={
                    <ChartTooltip className="admin-profile-stats__tooltip" />
                }
                placement={"right-end"}
            />
        );

        return (
            <div className="col admin-profile-stats__information-agents">
                <div className="admin-profile-stats__quarter-title">
                    <div className="admin-profile-stats__information-agents-title">
                        CURRENT QUARTER
                    </div>
                    <div className="admin-profile-stats__information-agents-info">
                        <div className="admin-profile-stats__information-agents-new">
                            <i
                                className="fa fa-circle admin-profile-stats__information-new"
                                aria-hidden="true"
                            ></i>
                            <div className="admin-profile-stats__information-quarter-title">
                                NEW
                            </div>
                        </div>
                        <div className="admin-profile-stats__information-agents-approved">
                            <i
                                className="fa fa-circle admin-profile-stats__information-approved"
                                aria-hidden="true"
                            ></i>
                            <div className="admin-profile-stats__information-quarter-title">
                                APPROVED
                            </div>
                        </div>
                    </div>
                </div>
                <BarChart
                    width={barChartWidth}
                    height={barChartHeight}
                    data={dataChart}
                    series={
                        <BarSeries
                            type="grouped"
                            width={20}
                            bar={
                                <Bar
                                    width={widthBar}
                                    padding={paddingBar}
                                    rounded={rounded}
                                    style={{
                                        clipPath:
                                            "inset(0 0 0 0 round 6px 6px 6px 6px"
                                    }}
                                    gradient={gradient}
                                />
                            }
                            tooltip={tooltip}
                            colorScheme={["#00A24B", "#4A90E2"]}
                            padding={paddingBar}
                            groupPadding={groupPadding}
                        />
                    }
                    gridlines={null}
                    brush={null}
                />
            </div>
        );
    };
}

AdminProfileStatsCurrentQuarter.propTypes = {
    getRealtorsByCurrentQuarter: PropTypes.object
};

export default AdminProfileStatsCurrentQuarter;
