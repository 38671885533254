import React, { Fragment } from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import set from 'lodash/set';
import BaseWizardStep from '../BaseWizardStep';
import SimpleUrlAutoCompleteInput from '../../../SimpleUrlAutoCompleteInput/SimpleUrlAutoCompleteInput';
import {components} from 'react-select';
import mixpanel from 'mixpanel-browser';
import ScrollToTopOnMount from '../../../ScrollTopOnMount/ScrollTopOnMount';

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <i className="fa fa-search" />
    </components.DropdownIndicator>
);

class StepLanguage extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        let languages = {};
        forEach(get(values, 'languages'), (value, index) => {
            set(languages,`language${index}`, get(value, 'label'));
        });

        mixpanel.track(
            "Completed What's Your Preferred Language Of Communication", {
                ...languages,
            }
        )
    };

    renderInterests() {
        const { values: { languages }, setFieldValue, setFieldTouched } = this.props;

        return (
            <Fragment>
                <div className="buyer__signup__fieldset">
                    <div className="buyer__signup__field__row">
                        <div className="buyer__signup__field buyer__signup__field_preferred_language">
                            <SimpleUrlAutoCompleteInput
                                labelProp="name"
                                autoCompleteUri="autocomplete/language"
                                isMulti
                                getOptionsMillisecondsDelay={300}
                                value={languages}
                                onChange={value => {
                                    setFieldValue('languages', value);
                                    setFieldTouched('languages');
                                }}
                                placeholder="Search"
                                components={{ DropdownIndicator }}
                                isClearable={false}
                                allowRepeatedValues={false}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    render() {
        const isStepFulfilled = true;
        
        return (
            <Fragment>
            <ScrollToTopOnMount />
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        What's your preferred language of communication?
                    </div>

                    { this.renderInterests() }
                </div>
                { this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    hasMixPanelTracking: true,
                }, this.mixpanelFunction)}
            </Fragment>
        )
    }
}

export default StepLanguage;
