import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import Recaptcha from '../Recaptcha/Recaptcha';

export default class RecaptchaFormikField extends React.PureComponent {
    componentDidMount = () => {
        this.markFieldAsInvalid();
    };

    getFieldName = () => get(this.props, 'field.name');

    updateFieldValueTo = (newValue) => {
        const fieldName = this.getFieldName();
        invoke(this.props, 'form.setFieldValue', fieldName, newValue);
    };

    markFieldAsInvalid = () => {
        this.updateFieldValueTo(false);
    };

    markFieldAsValid = () => {
        this.updateFieldValueTo(true);
    };

    render = () => (
        <Recaptcha
            onSuccessfullyCompleted={this.markFieldAsValid}
            onError={this.markFieldAsInvalid}
        />
    );
}

RecaptchaFormikField.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func,
    }),
    field: PropTypes.shape({
        name: PropTypes.string,
    }),
};
