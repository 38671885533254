import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { NumberFormat } from '../NumberFormat/NumberFormat';
import InputWithErrorMessage from '../InputWithErrorMessage/InputWithErrorMessage';

import './earnestmoneydepositaccountcard.css';

export default class EarnestMoneyDepositAccountCard extends React.PureComponent {
    onChangeAmount = (ev) => {
        const { account } = this.props;
        this.props.onChangeAmount(ev, account.id);
    };

    hasEnoughMoney = () => get(this.props, 'account.balance.amount') >= this.props.amount;

    formatNumberTypeAccount = () => `${get(this.props, 'account.number')} - ${get(this.props, 'account.type')}`;

    render = () => {
        const {
            account,
            amount,
            isEditable,
            showBorderColor,
            onSelect,
            onBlur,
        } = this.props;
        const amountAsNumber = Number(amount) || 0;
        let border = 'emd-account-card';
        const bankIcon = isEmpty(get(account, 'icon'))
            ? <i style={{ fontSize: '55px' }} className="emd-profile-deposit__icon fa fa-university" />
            : <img src={get(account, 'icon')} alt="" />
        let icon = null;

        if (amountAsNumber > 0 && (isEditable || showBorderColor)) {
            if (this.hasEnoughMoney()) {
                border = 'emd-account-card success';
                icon = <div className="profile__summary__glyph emd-account-card__success is-ok" />;
            } else {
                border = 'emd-account-card error';
                icon = <div className="profile__summary__glyph emd-account-card__error is-error" />;
            }
        }

        return (
            <div className={border}>
                {icon}
                <div className="emd-account-card__ac-info-row">
                    <div className="emd-account-card__ac-info-col">
                        {bankIcon}
                    </div>
                    <div className="emd-account-card__ac-info-col bank">
                        <h5>{get(account, 'name')}</h5>
                        <p>{this.formatNumberTypeAccount()}</p>
                    </div>
                    <div className="emd-account-card__ac-info-col balance">
                        <h5>BALANCE</h5>
                        <p>
                            <NumberFormat
                                currency={get(account, 'balance.currency')}
                                format="currency"
                                decimals={2}
                                number={get(account, 'balance.amount')}
                            />
                        </p>
                    </div>
                </div>
                <InputWithErrorMessage
                    placeholder="0.00"
                    onChange={this.onChangeAmount}
                    onBlur={onBlur}
                    onSelect={onSelect}
                    value={amount}
                    disabled={!isEditable}
                    symbol="$"
                    errorMessage="Amount entered exceeds account balance. Please enter an amount lesser than or equal to the account balance."
                    isError={amount && !this.hasEnoughMoney()}
                    isEditable={isEditable}
                    dashed
                />
            </div>
        );
    }
}

EarnestMoneyDepositAccountCard.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.number,
        icon: PropTypes.string,
        name: PropTypes.string,
        number: PropTypes.string,
        balance: PropTypes.shape({ amount: PropTypes.number, currency: PropTypes.string }),
        type: PropTypes.string,
    }),
    onSelect: PropTypes.func,
    isEditable: PropTypes.bool,
    showBorderColor: PropTypes.bool,
    amount: PropTypes.string,
    onChangeAmount: PropTypes.func,
    onBlur: PropTypes.func,
};
