import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import BuyerFlowButton from './BuyerFlowButton';

function selectOption(form, field, option) {
    const fieldName = get(field, 'name');
    const optionValue = get(option, 'value');
    form.setFieldValue(fieldName, optionValue);
    form.setFieldTouched(fieldName);
}

FormikBuyerFlowRadioButton.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any,
        buttonProps: PropTypes.any, // Props for BuyerFlowButton
    })),
    optionContainerClassName: PropTypes.string,
    form: PropTypes.any,
    field: PropTypes.any,
};

export default function FormikBuyerFlowRadioButton(props) {
    const { form, field } = props;
    const fieldValue = get(field, 'value');
    return map(props.options, (option, key) => (
        <div className={props.optionContainerClassName} key={key}>
            <BuyerFlowButton
                {...get(option, 'buttonProps')}
                isSelected={get(option, 'value') === fieldValue}
                onClick={() => {
                    selectOption(form, field, option);
                    invoke(props, 'onChange', option);
                }}
            />
        </div>
    ));
}
