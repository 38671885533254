import React from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

const isReCAPTCHADisabled = () =>
    process.env.REACT_APP_DISABLE_RECAPTCHA === "true";

export default class Recaptcha extends React.PureComponent {
    componentDidMount = () => {
        if (isReCAPTCHADisabled()) {
            setTimeout(this.props.onSuccessfullyCompleted, 0);
        }
    };

    render = () => {
        if (isReCAPTCHADisabled()) {
            // return <span>ReCAPTCHA is disabled</span>;
            return <span> </span>;
        }

        return (
            <ReCAPTCHA
                onChange={this.props.onSuccessfullyCompleted}
                onExpired={this.props.onError}
                onErrored={this.props.onError}
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
            />
        );
    };
}

Recaptcha.propTypes = {
    onSuccessfullyCompleted: PropTypes.func,
    onError: PropTypes.func
};
