import qs from 'qs';

/**
 *
 * @param {*} string the string we want to capitalize
 */
export const capitalize = (string) => string.toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

/**
 *
 * @param {*} requestedQuery the requery string we want to extract
 * @param {*} queryParam the whole queryParam
 */
export const getQueryParam = (requestedQuery = '', queryParam) => {
    if (queryParam) {
        // The ignoreQueryPrefix option is to ignore the leading question mark.
        const queryParamObject = qs.parse(queryParam, {
            ignoreQueryPrefix: true,
        });

        return queryParamObject[requestedQuery] || null;
    }

    return null;
};

/**
 * 
 * @param {*} value value that you want to format
 * @param {*} locale locale
 */
export const formatCurrency = (value, decimals, locale='en-US') => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    })
    .format(value)
}

/**
 * 
 * @param {*} dateString dateString to be formated
 * since the dateTime string appears in the format "2019-01-15T00:00:00"
 * we want to split it using 'T' so that we can only get the date and do away with
 * time
 */
export const formatDate = (dateString) => {
    return dateString.split('T')[0].replace(/-/g, '/')
}
