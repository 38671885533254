import React, { Fragment } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import noop from "lodash/noop";
import isFunction from "lodash/isFunction";
import { Button } from "../Button/Button";
import { NumberFormat } from "../NumberFormat/NumberFormat";
import logoImg from "../../styles/assets/logo.svg";
import FinicityPopup from "../Finicity/FinicityPopup";
import {
    formatAddressStreetApartment,
    formatAddressCityStateZipCode
} from "../../util/util";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import mixpanel from "mixpanel-browser";

import "./earnestmoneydepositrightcolumn.css";

export default class EarnestMoneyDepositRightColumn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            voaFinished: false,
            isAccepting: false,
            showFinicity: false,
            accountId: null
        };
    }

    formatNumberTypeAccount = item =>
        `${get(item, "number")} - ${get(item, "type")}`;

    bankIcon = icon =>
        isEmpty(icon) ? (
            <i
                style={{ fontSize: "55px" }}
                className="emd-profile-deposit__icon fa fa-university"
            />
        ) : (
            <img src={icon} alt="" />
        );

    onClickFixIssues = (id = null) => {
        this.setState({ accountId: id });
        mixpanel.track("Clicked On Update Balance");
        this.handleVerifyStepClick();
    };

    buildNumberAccount = item => {
        const { isAccepting, accountId } = this.state;
        const { fetchFundingSources } = this.props;
        const id = get(item, "id");
        const validate = id === accountId;

        if (isNil(get(item, "number"))) {
            return (
                <Button
                    disabled={isAccepting}
                    isLoading={
                        (validate && isAccepting) ||
                        (validate && fetchFundingSources)
                    }
                    extraSmall
                    darkerRed
                    onClick={() => this.onClickFixIssues(id)}
                >
                    FIX ISSUES
                </Button>
            );
        }

        return <p>{this.formatNumberTypeAccount(item)}</p>;
    };

    buildBalanceAmount = item => {
        const disableBalance =
            this.props.isImpersonatingUser && this.props.isReadOnlyUserRole;

        if (disableBalance) {
            const balance = (
                <div className="emd-right-column__unavailable">Hidden</div>
            );
            return this.buildDisabledButtonTooltip(balance);
        }

        return (
            <p>
                <NumberFormat
                    currency={get(item, "balance.currency")}
                    format="currency"
                    decimals={2}
                    number={get(item, "balance.amount")}
                />
            </p>
        );
    };

    buildAccounts = () => {
        const { accounts } = this.props;

        return (
            <div>
                {accounts.map(item => (
                    <Fragment>
                        <div
                            key={get(item, "number")}
                            className="emd-right-column__ac-info-row"
                        >
                            <div className="emd-right-column__ac-info-col">
                                {this.bankIcon(get(item, "icon"))}
                            </div>
                            <div className="emd-right-column__ac-info-col bank">
                                <h5>{get(item, "name")}</h5>
                                {this.buildNumberAccount(item)}
                            </div>
                            <div className="emd-right-column__ac-info-col balance">
                                <h5>BALANCE</h5>
                                {this.buildBalanceAmount(item)}
                            </div>
                        </div>
                        <p className="balance--date">
                            as of {get(item, "balanceDate")}
                        </p>
                    </Fragment>
                ))}
            </div>
        );
    };

    buildAddress = property => {
        const streetNumberName = get(property, "streetNumberName");
        const apartmentSuiteNumber = get(property, "apartmentSuiteNumber");
        const city = get(property, "city");
        const state = get(property, "state");
        const zipcode = get(property, "zipcode");

        return (
            <div>
                <div>
                    {formatAddressStreetApartment(
                        streetNumberName,
                        apartmentSuiteNumber,
                        false
                    )}
                </div>
                <div>{formatAddressCityStateZipCode(city, state, zipcode)}</div>
            </div>
        );
    };

    buildDestinationAccount = destinationAccount => (
        <div
            key={get(destinationAccount, "number")}
            className="emd-right-column__destination-info-row"
        >
            <div className="emd-right-column__destination-info-one">
                <img src={get(destinationAccount, "icon")} alt="" />
            </div>
            <div className="emd-right-column__destination-info-two">
                <h5>{get(destinationAccount, "bankName")}</h5>
                <p>{get(destinationAccount, "accountNumber")}</p>
            </div>
            <div className="emd-right-column__destination-info-three">
                <img className="icon" src={logoImg} alt="LemonBrew" />
            </div>
            <div className="emd-right-column__destination-info-four">
                <h5>{get(destinationAccount, "ownerName")}</h5>
                <div className="emd-right-column__property">
                    <i className="fa fa-map-marker-alt emd-right-column__location-icon" />
                    {this.buildAddress(get(destinationAccount, "address"))}
                </div>
            </div>
        </div>
    );

    buildDestinationAccounts = () => {
        const { destinationAccounts } = this.props;
        return (
            <div>{map(destinationAccounts, this.buildDestinationAccount)}</div>
        );
    };

    onCompleteFinicity = success => {
        const { getFundingSourcesAndReloadProfile } = this.props;

        this.setState({
            voaFinished: true,
            showFinicity: false
        });

        if (success !== "false") {
            if (isFunction(getFundingSourcesAndReloadProfile)) {
                getFundingSourcesAndReloadProfile(this.props.consumerId);
            }
        }
    };

    renderVerificationOfAssets = () => {
        const { voaUrl } = this.props;
        const { voaFinished } = this.state;

        if (voaFinished) return null;

        return (
            <FinicityPopup
                url={voaUrl}
                onComplete={this.onCompleteFinicity}
                mixpanelTrack={true}
            />
        );
    };

    handleVerifyStepClick = async () => {
        const { fetchVoaUrl } = this.props;
        this.setState({ isAccepting: true });

        try {
            this.setState({ showFinicity: true });
            if (isFunction(fetchVoaUrl)) {
                await fetchVoaUrl();
            }
        } catch (e) {
            console.error("Error while updating financial requirements data!");
        } finally {
            this.setState({ isAccepting: false, voaFinished: false });
        }
    };

    buildDisabledButtonTooltip = content => {
        const popoverText = `You do not have sufficient privileges to perform this action`;

        return (
            <div className="emd-landing-page__tooltip">
                <CustomTooltip
                    popoverText={popoverText}
                    content={content}
                ></CustomTooltip>
            </div>
        );
    };

    buildUpdateBalanceButton = () => {
        const { isAccepting, accountId } = this.state;
        const { fetchFundingSources } = this.props;
        const validation = isNil(accountId);
        const isReadOnly =
            this.props.isImpersonatingUser &&
            (this.props.isReadOnlyUserRole || this.props.isMidUserRole);

        if (isReadOnly) {
            const button = (
                <Button small disable onClick={noop}>
                    UPDATE BALANCE
                </Button>
            );
            return this.buildDisabledButtonTooltip(button);
        }

        return (
            <Button
                disabled={isAccepting}
                isLoading={
                    (validation && isAccepting) ||
                    (validation && fetchFundingSources)
                }
                small
                yellow
                onClick={this.onClickFixIssues}
            >
                UPDATE BALANCE
            </Button>
        );
    };

    render = () => {
        const buildFinicityModal = this.state.showFinicity
            ? this.renderVerificationOfAssets()
            : null;

        return (
            <div className="emd-right-column">
                <div className="emd-right-column__text">Your Accounts</div>
                {this.buildAccounts()}
                {buildFinicityModal}
                <hr />
                <div className="emd-right-column__text">
                    Destination Escrow Account
                </div>
                {this.buildDestinationAccounts()}
            </div>
        );
    };
}

EarnestMoneyDepositRightColumn.propTypes = {
    accounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            icon: PropTypes.string,
            name: PropTypes.string,
            number: PropTypes.string,
            type: PropTypes.string,
            balanceDate: PropTypes.date,
            balance: PropTypes.shape({
                amount: PropTypes.number,
                currency: PropTypes.string
            })
        })
    ),

    destinationAccounts: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            name: PropTypes.string,
            number: PropTypes.string,
            location: PropTypes.string
        })
    ),

    consumerId: PropTypes.string,
    voaUrl: PropTypes.string,
    fetchFundingSources: PropTypes.bool,
    getFundingSourcesAndReloadProfile: PropTypes.func,
    fetchVoaUrl: PropTypes.func,
    isMidUserRole: PropTypes.bool,
    isReadOnlyUserRole: PropTypes.bool,
    isImpersonatingUser: PropTypes.bool
};
