import React from 'react';
import get from 'lodash/get';
import UserProfileContext from '../UserProfileContext';
import trim from 'lodash/trim';

export default class ProfileTabBase extends React.Component {
    static contextType = UserProfileContext;

    getUserValue = (path, defaultValue = undefined) =>
        get(this.context.profile, path, defaultValue);

    getProfileProperty = (path, defaultValue = '') =>
        get(this.context.profile, `publicProfile.${path}`, defaultValue);

    getProfileValue = (path, defaultValue) =>
        trim(this.getProfileProperty(path, defaultValue));
}
