import React from 'react';
import PropTypes from 'prop-types';
import docReview from '../../styles/assets/doc-review.svg';
import './buyer-flow-no-agent-card.css';

BuyerFlowNoAgentCard.propTypes = {
    locations: PropTypes.string.isRequired,
};

export default function BuyerFlowNoAgentCard(props) {
    return (
        <div className="buyer-flow-no-agent-card">
            <img className="buyer-flow-no-agent-card__icon" src={docReview} alt="" />
            <p className="buyer-flow-no-agent-card__copy">
                <p>The system could not find any LemonBrew Partner Agent matches in {props.locations}.</p>
                <p>
                We're actively working on finding agents in your area. Please provide contact info below, so we can keep you updated on our progress.
                </p>
            </p>
        </div>
    );
}
