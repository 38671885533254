import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { MenuItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavBarSubMenuButton from "components/NavBarSubMenuButton/NavBarSubMenuButton";

export default class NavBarStatsMenuItem extends React.PureComponent {
    buildMenuStyles = menu =>
        classnames("navbar-button", {
            "is-active": this.props.selected === menu
        });

    buildPartnerAgentsButton = () => (
        <Link to="/admin-stats">
            <button type="button" className={this.buildMenuStyles("stats")}>
                Partner Agents
            </button>
        </Link>
    );

    buildBuyersButton = () => (
        <Link to="/admin-buyer-stats">
            <button
                type="button"
                className={this.buildMenuStyles("stats-buyer")}
            >
                Buyers
            </button>
        </Link>
    );

    render = () => {
        const { canSeeAgents, canSeeBuyers } = this.props;
        const partnerAgentButton = canSeeAgents ? (
            <MenuItem>{this.buildPartnerAgentsButton()}</MenuItem>
        ) : null;
        const buyersButton = canSeeBuyers ? (
            <MenuItem>{this.buildBuyersButton()}</MenuItem>
        ) : null;

        if (!canSeeAgents && !canSeeBuyers) return null;

        return (
            <NavBarSubMenuButton label="Stats" id="navbar-stats-menu">
                {partnerAgentButton}
                {buyersButton}
            </NavBarSubMenuButton>
        );
    };
}

NavBarStatsMenuItem.propTypes = {
    selected: PropTypes.string,
    canSeeAgents: PropTypes.bool,
    canSeeBuyers: PropTypes.bool
};
