import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { purchaseTimelines, homeTypes } from "../../../../shared/constants";
import map from "lodash/map";
import join from "lodash/join";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { NumberFormat } from "../../../NumberFormat/NumberFormat";
import { getBudgetPercentageFormatter } from "../Shared";
import { getOptionLabel } from "../../../../util/util";

function renderLocation({ city, neighborhood, state }, i) {
    return (
        <div key={i}>
            {`${neighborhood ? neighborhood + ", " : ""}${city}, ${state}`}
        </div>
    );
}

class Summary extends React.Component {
    static renderItem(label, value, className = "") {
        return !!value || value === 0 || value === false ? (
            <div className={`buyer__signup__summary-buyer__item ${className}`}>
                <div className="name">{label}</div>
                <div className="value">{value}</div>
            </div>
        ) : null;
    }

    render() {
        const {
            budget,
            purchaseTimeline,
            downPayment,
            hasKnownLocation = "yes",
            knownLocations,
            approximateLocations,
            buyerHomeType,
            ficoScoreCategory,
            languages,
            buyerIsSellingCurrentHome,
            buyerIsUsingNetProceedsFromCurrentHome,
            buyerNetProceedsBeingUsedFromCurrentHome,
            buyerCurrentLivingSituation
        } = this.props.values;

        const budgetFormatter = getBudgetPercentageFormatter(budget, 100);
        const rangeFormatter = getBudgetPercentageFormatter(
            budget,
            downPayment
        );
        const timelineLabel = getOptionLabel(
            purchaseTimelines,
            purchaseTimeline
        );
        const buyerHomeTypeLabel = getOptionLabel(homeTypes, buyerHomeType);
        const creditScoreLabel = get(ficoScoreCategory, "name");
        const languagesLabel = !isEmpty(languages)
            ? join(map(languages, "label"), ", ")
            : "";
        const isOwn = buyerCurrentLivingSituation === "own";
        const netProceedsFormat =
            buyerIsUsingNetProceedsFromCurrentHome === "yes" ? (
                <NumberFormat
                    format="currency"
                    decimals={0}
                    number={buyerNetProceedsBeingUsedFromCurrentHome}
                />
            ) : (
                0
            );

        return (
            <Fragment>
                <div className="buyer__signup__summary-buyer">
                    <div className="buyer__signup__summary-buyer__section has-border">
                        <div className="buyer__signup__summary-buyer__title">
                            <div className="summary-buyer-label is-big">
                                {hasKnownLocation === "yes"
                                    ? "Looking to buy:"
                                    : "Looking to buy in:"}
                            </div>
                            <div className="summary-buyer-label is-huge">
                                {hasKnownLocation === "yes"
                                    ? `${knownLocations[0].address}${
                                          knownLocations[0].apartmentSuiteNumber
                                              ? " (suite " +
                                                knownLocations[0]
                                                    .apartmentSuiteNumber +
                                                ")"
                                              : ""
                                      }`
                                    : hasKnownLocation === "no"
                                    ? approximateLocations.map((loc, i) =>
                                          renderLocation(loc, i)
                                      )
                                    : null}
                            </div>
                        </div>
                    </div>

                    <div className="buyer__signup__summary-buyer__section">
                        <div className="buyer__signup__summary-buyer__panel">
                            {Summary.renderItem(
                                "Property Type",
                                buyerHomeTypeLabel
                            )}
                            <div className="buyer__signup__summary-buyer__divider" />
                            {Summary.renderItem("Time Frame", timelineLabel)}
                            <div className="buyer__signup__summary-buyer__divider" />
                            {Summary.renderItem(
                                "Est. Credit score",
                                creditScoreLabel
                            )}
                            <div className="buyer__signup__summary-buyer__divider" />
                            {!isEmpty(languages)
                                ? Summary.renderItem(
                                      "Pref. Language(s)",
                                      languagesLabel
                                  )
                                : null}
                            {!isEmpty(languages) ? (
                                <div className="buyer__signup__summary-buyer__divider" />
                            ) : null}
                            {isOwn
                                ? Summary.renderItem(
                                      "Selling Current Home",
                                      buyerIsSellingCurrentHome
                                  )
                                : null}
                            {isOwn ? (
                                <div className="buyer__signup__summary-buyer__divider" />
                            ) : null}
                            {buyerIsSellingCurrentHome === "yes"
                                ? Summary.renderItem(
                                      "Using Net Proceeds",
                                      buyerIsUsingNetProceedsFromCurrentHome
                                  )
                                : null}
                            {buyerIsSellingCurrentHome === "yes" ? (
                                <div className="buyer__signup__summary-buyer__divider" />
                            ) : null}
                            {buyerIsUsingNetProceedsFromCurrentHome === "yes"
                                ? Summary.renderItem(
                                      "Net Proceeds Amount",
                                      netProceedsFormat
                                  )
                                : null}
                            {buyerIsUsingNetProceedsFromCurrentHome ===
                            "yes" ? (
                                <div className="buyer__signup__summary-buyer__divider" />
                            ) : null}
                        </div>

                        <div className="buyer__signup__summary-buyer__panel">
                            {Summary.renderItem(
                                "Budget",
                                budgetFormatter.formatRange()
                            )}
                            <div className="buyer__signup__summary-buyer__divider" />
                            {Summary.renderItem(
                                "Down Payment",
                                `${downPayment}%`
                            )}
                            <div className="buyer__signup__summary-buyer__divider" />
                            {Summary.renderItem(
                                "Est. Down Payment",
                                rangeFormatter.formatRange()
                            )}
                            <div className="buyer__signup__summary-buyer__divider" />
                            {Summary.renderItem(
                                "Est. Loan Amount",
                                rangeFormatter.formatRange(
                                    100 - (downPayment || 0)
                                )
                            )}
                            <div className="buyer__signup__summary-buyer__divider" />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

Summary.propTypes = {
    values: PropTypes.object
};

export default Summary;
