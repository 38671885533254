import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import toString from 'lodash/toString';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import { Button } from '../Button/Button';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';
import RealtorMatchProfileAttachFileButton from '../RealtorMatchProfileAttachFileButton/RealtorMatchProfileAttachFileButton';

import './matchFileUploadModal.css';

const SELECT_DOCUMENT_TYPE_OPTION = {
    id: -1,
    displayName: 'SELECT THE TYPE OF DOCUMENT',
};

export default class MatchFileUploadModal extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        onClose: PropTypes.func,
        onUploadFile: PropTypes.func,
        documentTypes: PropTypes.array.isRequired,
        onUploadSelectTypeFile: PropTypes.func,
        isUploading: PropTypes.bool,
        isDocumentSaved: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.resetState();
        }
    }

    resetState = () => (
        this.setState(this.getInitialState())
    )

    getInitialState = () => ({
        isDisable: true,
        file: null,
        isChooseFile: false,
        nameFile: 'No file chosen',
        sizeFile: 0,
        maxFileSize: 10000000,
    });

    onAcceptFile = (file) => {
        this.setState({ file, isChooseFile: true, nameFile: get(file, 'name'), sizeFile: get(file, 'size') })
    };

    getOptions = () => map([SELECT_DOCUMENT_TYPE_OPTION, ...this.props.documentTypes],
        (document) => ({
            label: get(document, 'displayName'),
            value: { id: toString(get(document, 'id')), name: get(document, 'name') },
        })
    );

    isSelectedDocumentTypeOptionValid = (option) => get(option, 'value.id') !== SELECT_DOCUMENT_TYPE_OPTION;

    onDocumentTypeChange = (option) => {
        const { onUploadSelectTypeFile } = this.props;
        const isValidOption = this.isSelectedDocumentTypeOptionValid(option);
        this.setState({ isDisable: !isValidOption });

        if (!isValidOption) {
            return;
        }

        if (isFunction(onUploadSelectTypeFile)) {
            onUploadSelectTypeFile(get(option, 'value.name'));
        }
    };

    isDisableSaveButton = () => this.state.isDisable || !this.state.isChooseFile || this.state.sizeFile >= this.state.maxFileSize
        || this.props.isUploading;

    render = () => {
        const textSaveButton = this.props.isDocumentSaved ? 'Saved' : 'Save Document';

        return (
            <Modal
                animation
                autoFocus
                backdrop
                enforceFocus
                show={this.props.isVisible}
                className="match-file-upload-modal"
            >
                <ModalHeader className="match-file-upload-modal__header">
                    <Button
                        icon 
                        dark 
                        symbol="times"
                        disabled={this.props.isUploading}
                        onClick={this.props.onClose}
                    >
                    </Button>
                </ModalHeader>
                <ModalBody className="match-file-upload-modal__body">
                    <div className="match-file-upload-modal__title">
                        Upload documents:
                    </div>
                    <div className="match-file-upload-modal__options">
                        <AutoCompleteInput
                            placeholder="SELECT THE TYPE OF DOCUMENT"
                            onChange={this.onDocumentTypeChange}
                            getOptions={this.getOptions}
                            initializeGettingOptions
                        />
                    </div>
                    <div className="match-file-upload-modal__file-size-message">
                        The file size is limited up to 10MB.
                    </div>
                    <div className="match-file-upload-modal__browser">
                        <RealtorMatchProfileAttachFileButton
                            onAcceptFile={this.onAcceptFile}
                            textButton="Browse"
                        />
                        <span className="match-file-upload-modal__file">
                            {this.state.nameFile}
                        </span>
                    </div>
                    <div className="match-file-upload-modal__error-uploading">
                        {this.state.sizeFile >= this.state.maxFileSize
                            ? (<span>Could not store file {this.state.nameFile}. Please try again with another file!</span>)
                            : null
                        }
                    </div>
                    <div className="container--buttons">
                        <Button
                            disabled={this.isDisableSaveButton()}
                            onClick={() => this.props.onUploadFile(this.state.file)}
                            isLoading={this.props.isUploading}
                            loadingText="Saving..."
                        >
                            {textSaveButton}
                        </Button>
                        <Button
                            inverted
                            disabled={this.props.isUploading}
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    };
}
