import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button/Button';

import './realtormatchprofileleftcolumn.css';

const buildContactInfo = (email, phone) => (
    <div className="realtor-match-profile-left-column__buyer-contact-info">
        <a 
            className="realtor-match-profile-left-column__buyer-contact-info-email"
            href={`mailto: ${email}`}
        >
            {email}
        </a>
        <a
            className="realtor-match-profile-left-column__buyer-contact-info-phone"
            href={`tel:${phone}`}
        >	
            {phone}
        </a>
    </div>
);

export default function RealtorMatchProfileLeftColumn({
    picture,
    onContact,
    onChangeStatus,
    onOpenModal,
    email,
    phone,
}) {
    return (
        <div className="realtor-match-profile-left-column">
            <img
                src={picture}
                alt=""
                className="realtor-match-profile-left-column__picture"
            />
            {buildContactInfo(email, phone)}
            <Button
                small
                onClick={onContact}
            >
                Contact
            </Button>
            <Button
                small
                yellow
                onClick={onChangeStatus}
            >
                Change status
            </Button>
            <Button
                small
                inverted
                onClick={onOpenModal}
            >
                Attach file
            </Button>
        </div>
    );
}

RealtorMatchProfileLeftColumn.propTypes = {
    picture: PropTypes.string.isRequired,
    onChangeStatus: PropTypes.func,
    onOpenModal: PropTypes.func,
    onContact: PropTypes.func,
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    budget: PropTypes.object.isRequired,
    maxBudget: PropTypes.object.isRequired,
    rebate: PropTypes.number.isRequired,
    brewScore: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
};
