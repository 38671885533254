import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import PlacesAutocomplete, {
    geocodeByAddress,
} from 'react-places-autocomplete';

export default class AutoCompleteAddressInput extends React.PureComponent {
    static propTypes = {
        customOnChange: PropTypes.func,
        className: PropTypes.string,
        value: PropTypes.any,
        onPlaceDetails: PropTypes.func,
        placeholder: PropTypes.string,
    };

    handleChange = (selectedOptions) => {
        const { customOnChange } = this.props;

        if (!isFunction(customOnChange)) {
            return;
        }

        customOnChange(selectedOptions);
    };

    handleSelect = value => {
        if (isFunction(this.props.onPlaceDetails)) {
            geocodeByAddress(value).then(result =>
                this.props.onPlaceDetails(result, value)
            );
        }
    };

    render = () => (
        <div className={`custom__input ${this.props.className || ''}`}>
            <PlacesAutocomplete
                value={ this.props.value }
                onChange={ this.handleChange }
                onSelect={ this.handleSelect }
                searchOptions ={{
                    componentRestrictions: { country: ['us'] },
                    types: ['address']
                }}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div style={{ position: 'relative' }}>
                        <input
                            {...getInputProps({
                                className: 'form-control location-search-input',
                                placeholder: this.props.placeholder,
                            })}
                            onBlur={() => {
                                const [{ description = '' } = {}] = suggestions;
                                if (description) this.handleSelect(description);

                                const { onBlur } = getInputProps();
                                if (onBlur) onBlur();
                            }}
                        />

                        { loading && <div className="input-hint">
                            <i style={{ 'textAlign': 'center', 'padding': 0 }} className="fas fa-spinner fa-spin"/>
                        </div> }

                        <div className={`${ suggestions.length ? 'location__autocomplete__field' : ''}`}>
                            { suggestions.map(suggestion =>
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className: `location__autocomplete__field__option ${ suggestion.active ? 'is-active' : '' }`
                                    })}
                                >
                                    <span>{ suggestion.description }</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    );
}
