import { withFormik } from "formik";
import { object as YupObject, string as YupString, ref as YupRef } from "yup";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import BrokerSignUpForm from "./BrokerSignUpForm";
import { signupBroker } from "../../../util/service_api";
import {
    YupPassword,
    YupUniqueEmail,
    YupUsPhoneNumber
} from "../../../shared/validations";

// Wrap our form with the using withFormik HoC
const BrokerSignUpFormik = compose(
    withRouter,
    withFormik({
        // Transform outer props into form values
        mapPropsToValues: () => ({
            aboutYourself: {
                firstName: "",
                lastName: "",
                email: "",
                brokerPhone: "",
                password: "",
                password2: ""
            }
        }),
        validationSchema: YupObject().shape({
            aboutYourself: YupObject().shape({
                firstName: YupString()
                    .trim()
                    .required("First Name is required"),
                lastName: YupString()
                    .trim()
                    .required("Last Name is required"),
                email: YupUniqueEmail("ROLE_REALTOR").required(
                    "Email is required"
                ),
                brokerPhone: YupUsPhoneNumber()
                    .trim()
                    .required("Phone Number is required"),
                password: YupPassword()
                    .trim()
                    .label("Password")
                    .meta({ mustNotMatch: { path: "email", label: "Email" } })
                    .required("Password is required"),
                password2: YupString()
                    .trim()
                    .oneOf([YupRef("password"), null], "Passwords don't match")
                    .required("Confirm Password is required")
            })
        }),
        // Submission handler
        handleSubmit(values, { setStatus, setSubmitting, props, setError }) {
            setSubmitting(true);

            const userData = {
                firstName: get(values, "aboutYourself.firstName"),
                lastName: get(values, "aboutYourself.lastName"),
                email: get(values, "aboutYourself.email"),
                password: get(values, "aboutYourself.password"),
                phone: get(values, "aboutYourself.brokerPhone")
            };

            signupBroker(userData)
                .then(() => setStatus({ success: true }))
                .catch(error => setError({ error }))
                .finally(() => setSubmitting(false));
        }
    })
)(BrokerSignUpForm);

const BrokerSignUpContainer = connect()(BrokerSignUpFormik);

export default BrokerSignUpContainer;
