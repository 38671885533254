import React, { Component } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import map from 'lodash/map';
import toString from 'lodash/toString';
import concat from 'lodash/concat';
import invoke from 'lodash/invoke';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';

import './realtorexperiencefield.css';

export default class RealtorExperienceField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            experience: this.props.experience,
        };
    }

    onChange = (element) => {
        this.setState({ experience: element.value })
        const newExperience = element.value;
        invoke(this.props, 'onEdit', newExperience);
        console.log(element.value);
    };

    getExperienceLabel = (experience) => {
        switch (Number(experience)) {
        case 1:
            return '1 year';
        case 15:
            return '15 years or more';
        default:
            return `${experience} years`;
        }
    }

    buildValue = () => ({
        value: this.state.experience,
        label: this.getExperienceLabel(this.state.experience),
    })

    buildEditableField = () => {
        const getOptions = () => concat(map(range(1, 16), (year) => ({
            label: this.getExperienceLabel(year),
            value: toString(year),
        })));

        return (
            <AutoCompleteInput
                placeholder="Select"
                value={this.buildValue()}
                getOptions={getOptions}
                onChange={this.onChange}
                initializeGettingOptions
            />
        );
    };

    buildFieldValue = () => (
        <div className="realtor-experience-field__field-value">
            {this.getExperienceLabel(this.props.experience)}
        </div>
    );

    buildCancelAndConfirmEditButtons = () => (
        <span>
            {this.buildCancelEditButton()}
            {this.buildConfirmEditButton()}
        </span>
    );

    render = () => {
        const { isEditing } = this.props;
        const field = isEditing
            ? this.buildEditableField()
            : this.buildFieldValue();

        return (
            <div id="realtor-tour-experience" className="realtor-experience-field">
                <h5 className="realtor-experience-field__field-label">
                    Experience
                </h5>
                {field}
            </div>
        );
    };
}

RealtorExperienceField.propTypes = {
    isEditing: PropTypes.func,
    canEdit: PropTypes.bool,
    experience: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onEdit: PropTypes.func,
};
