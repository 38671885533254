import React from "react";
import CustomRealtorAutoCompleteField from "../../CustomRealtorAutoCompleteField/CustomRealtorAutoCompleteField";

import "./specializations.scss";

const Specializations = props => {
    return (
        <div className="specializations__container">
            <div className="level__one__text">
                Do you specialize in anything in particular?
            </div>
            <CustomRealtorAutoCompleteField
                fieldProp="specializations"
                labelProp="name"
                autoCompleteUri="autocomplete/specialization"
                placeholder="Select your specializations (You can select more than one)"
                value={props.value}
                isMulti
                onEdit={props.onEdit}
                allowRepeatedValues={false}
                allowSelectingWithCheckbox
            />
        </div>
    );
};

export default Specializations;
