import get from "lodash/get";
import * as APIUtil from "../../util/service_api";
import * as prospectActionTypes from "./prospectActionTypes";
import { loadUserProfile } from "../user/UserActions";

export const receiveVoaUrl = url => ({
    type: prospectActionTypes.RECEIVE_PROSPECT_VOA_URL,
    payload: url
});

export const receiveUpdateProspect = response => ({
    type: prospectActionTypes.RECEIVE_PROSPECT_DEPOSIT_ID,
    payload: response
});

export const receiveUpdateProspectAchs = response => ({
    type: prospectActionTypes.RECEIVE_PROSPECT_DEPOSIT_ID_ACHS,
    payload: response
});

export const errorUpdateProspectAchs = error => ({
    type: prospectActionTypes.ERROR_PROSPECT_DEPOSIT_ID_ACHS,
    payload: error
});

export const receiveUpdateProspectAchsFetching = () => ({
    type: prospectActionTypes.RECEIVE_PROSPECT_DEPOSIT_ID_ACHS_FETCHING
});

export const updateProspectFetching = () => ({
    type: prospectActionTypes.UPDATE_PROSPECT_DEPOSIT_FETCHING
});

export const fetchingVoaProspectUrl = () => ({
    type: prospectActionTypes.FETCH_RECEIVE_PROSPECT_VOA_URL
});

export const fetchGetFundingSources = () => ({
    type: prospectActionTypes.FETCH_GET_FUNDING_SOURCES
});

export const successGetFundingSources = () => ({
    type: prospectActionTypes.SUCCESS_GET_FUNDING_SOURCES
});

export const fetchEarnestMoneyDepositFinish = () => ({
    type: prospectActionTypes.FETCH_EARNEST_MONEY_DEPOSIT_FINISH
});

export const successEarnestMoneyDepositFinish = () => ({
    type: prospectActionTypes.SUCCESS_EARNEST_MONEY_DEPOSIT_FINISH
});

export const fetchEditProspectInfo = () => ({
    type: prospectActionTypes.FETCH_EDIT_PROSPECT_INFO
});

export const successEditProspectInfo = () => ({
    type: prospectActionTypes.SUCCESS_EDIT_PROSPECT_INFO
});

export const fetchSendEmails = () => ({
    type: prospectActionTypes.FETCH_PROSPECT_SEND_EMAILS
});

export const successSendEmails = () => ({
    type: prospectActionTypes.SUCCESS_PROSPECT_SEND_EMAILS
});

export const fetchCompleteTransferReceipt = () => ({
    type: prospectActionTypes.FETCH_TRANSFER_RECEIPT
});

export const successCompleteTransferReceipt = () => ({
    type: prospectActionTypes.SUCCESS_TRANSFER_RECEIPT
});

export const fetchSendWireInstructions = () => ({
    type: prospectActionTypes.FETCH_PROSPECT_SEND_WIRE_INSTRUCTIONS
});

export const successSendWireInstructions = () => ({
    type: prospectActionTypes.SUCCESS_PROSPECT_SEND_WIRE_INSTRUCTIONS
});

export const errorSendWireInstructions = () => ({
    type: prospectActionTypes.ERROR_PROSPECT_SEND_WIRE_INSTRUCTIONS
});

export const fetchSendCertifiedInfo = () => ({
    type: prospectActionTypes.FETCH_PROSPECT_SEND_CERTIFIED_INFO
});

export const successSendCertifiedInfo = () => ({
    type: prospectActionTypes.SUCCESS_PROSPECT_SEND_CERTIFIED_INFO
});

export const errorSendCertifiedInfo = () => ({
    type: prospectActionTypes.ERROR_PROSPECT_SEND_CERTIFIED_INFO
});

export const fetchEarnestMoneyDepositCancel = earnestMoneyDepositId => ({
    type: prospectActionTypes.FETCH_EARNEST_MONEY_DEPOSIT_CANCEL,
    payload: earnestMoneyDepositId
});

export const successEarnestMoneyDepositCancel = () => ({
    type: prospectActionTypes.SUCCESS_EARNEST_MONEY_DEPOSIT_CANCEL
});

export const fetchVoaProspectUrl = () => dispatch => {
    dispatch(fetchingVoaProspectUrl());
    APIUtil.getVoaProspectUrl().then(response => {
        dispatch(receiveVoaUrl(response));
    });
};

export const addAchsDeposit = (data, id) => dispatch => {
    dispatch(receiveUpdateProspectAchsFetching());
    return APIUtil.addAchsDeposit(data, id)
        .then(response => {
            dispatch(receiveUpdateProspectAchs(response));
            return {
                status: "success",
                response: response
            };
        })
        .catch(error => {
            dispatch(errorUpdateProspectAchs(error));
            return {
                status: "error",
                error: get(error, "response.data.message")
            };
        });
};

export const updateDepositProspect = (data, id, dataAchs) => dispatch => {
    return APIUtil.updateProspectDeposit(data, id).then(response => {
        dispatch(receiveUpdateProspect(response));
        return dispatch(addAchsDeposit(dataAchs, get(response, "id")));
    });
};

export const newDepositProspect = (data, dataAchs) => dispatch => {
    return APIUtil.newProspectDeposit(data).then(response => {
        dispatch(receiveUpdateProspect(response));
        return dispatch(addAchsDeposit(dataAchs, get(response, "id")));
    });
};

export const updateProspect = data => dispatch => {
    dispatch(updateProspectFetching());
    APIUtil.updateProspect(data).then(response => {
        dispatch(receiveUpdateProspect(response));
    });
};

export const getFundingSourcesAndReloadProfile = consumerId => dispatch => {
    dispatch(fetchGetFundingSources());
    APIUtil.getFundingSources(consumerId)
        .then(response => {
            dispatch(successGetFundingSources(response));
            return response;
        })
        .then(() => dispatch(loadUserProfile()));
};

export const earnestMoneyDepositCancel = earnestMoneyDepositId => dispatch => {
    dispatch(fetchEarnestMoneyDepositCancel(earnestMoneyDepositId));
    APIUtil.earnestMoneyDepositCancel(earnestMoneyDepositId)
        .then(response => {
            dispatch(successEarnestMoneyDepositCancel(response));
            return response;
        })
        .then(() => dispatch(loadUserProfile()));
};

export const resetDeposit = () => dispatch => {
    dispatch({ type: prospectActionTypes.RESET_DEPOSIT });
};

export const earnestMoneyDepositFinish = earnestMoneyDepositId => dispatch => {
    dispatch(fetchEarnestMoneyDepositFinish());
    return APIUtil.earnestMoneyDepositFinish(earnestMoneyDepositId).then(
        response => {
            dispatch(successEarnestMoneyDepositFinish(response));
        }
    );
};

export const editProspectInfo = data => dispatch => {
    dispatch(fetchEditProspectInfo());
    return APIUtil.editProspectInfo(data).then(response => {
        dispatch(successEditProspectInfo(response));
        return response;
    });
};

export const sendEmails = (earnestMoneyDepositId, data) => dispatch => {
    dispatch(fetchSendEmails());
    return APIUtil.sendEmails(earnestMoneyDepositId, data).then(response => {
        dispatch(successSendEmails(response));
        return response;
    });
};

export const completeTransferReceipt = (
    earnestMoneyDepositId,
    prospectId
) => dispatch => {
    dispatch(fetchCompleteTransferReceipt());
    return APIUtil.completeTransferReceipt(
        earnestMoneyDepositId,
        prospectId
    ).then(response => {
        dispatch(successCompleteTransferReceipt(response));
        return response;
    });
};

export const sendWireInstructions = data => dispatch => {
    dispatch(fetchSendWireInstructions());
    return APIUtil.sendWireInstructions(data)
        .then(response => {
            dispatch(successSendWireInstructions(response));
            return response;
        })
        .catch(() => dispatch(errorSendWireInstructions()));
};

export const sendCertifiedInfo = data => dispatch => {
    dispatch(fetchSendCertifiedInfo());
    return APIUtil.sendCertifiedInfo(data)
        .then(response => {
            dispatch(successSendCertifiedInfo(response));
            return response;
        })
        .catch(() => dispatch(errorSendCertifiedInfo()));
};
