import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';

import './password-strength-tooltip.css';

const tooltipContent =
    <Popover id="password-strength-tooltip" title="Please create a strong password:">
        <ul>
            <li>Does not match email</li>
            <li>Has a minimal length of 9 characters</li>
            <li>Has at least one uppercase letter</li>
            <li>Has at least one numeral</li>
            <li>Has at least one special character; cannot have &lt; or &gt;</li>
        </ul>
    </Popover>;

const PasswordStrengthInfoTooltip = () =>
    <OverlayTrigger placement="top" overlay={tooltipContent}>
        <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>What are our password requirements?</a>
        </div>
    </OverlayTrigger>;

export default PasswordStrengthInfoTooltip;
