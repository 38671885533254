import React from 'react';
import PropTypes from 'prop-types';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import first from 'lodash/first';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import BrokerageLabelButtonWrapper from '../BrokerageLabelButtonWrapper/BrokerageLabelButtonWrapper';
import BrewBoardBrokerageEditModal from '../BrewBoardBrokerageEditModal/BrewBoardBrokerageEditModal';
import { brewBoardUpdateBrokerage } from '../../util/service_api';
import { getAddressFromPlaceDetails } from '../../util/util';

export default class BrewBoardBrokerageLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isLoading: false,
            brokerage: this.props.brokerage,
        };
    }

    onClick = () => {
        this.setState({
            isModalVisible: true,
            isLoading: false,
        });
    };

    onChange = (brokerage) => {
        this.setState({ brokerage });
    };

    changeServingInLocationIfRequired = () => {
        const id = get(this.props.brokerage, 'state.id');
        const placeId = get(this.state.brokerage, 'state.place_id');
        const needsToFetchLocation = placeId !== undefined;

        if (!needsToFetchLocation) {
            return;
        }

        return geocodeByPlaceId(placeId)
            .then((result) => {
                const firstResult = first(result);
                const address = getAddressFromPlaceDetails(firstResult);
                return {
                    id,
                    abbreviation: address.state,
                    name: address.city,
                    latitude: address.lat,
                    longitute: address.lng,
                    zoomLevel: 8,
                }
            })
            .then((newServingIn) => {
                this.onChange({
                    ...this.state.brokerage,
                    state: newServingIn
                });
            });
    };

    onCancel = () => {
        this.setState({
            isModalVisible: false,
            brokerage: this.props.brokerage
        });
    };

    onSave = () => {
        const {
            onUpdateBrokerage,
            onSuccessfullyUpdateBrokerage,
            onFailToUpdateBrokerage
        } = this.props;

        if (!isFunction(onUpdateBrokerage)) {
            return;
        }

        this.setState({ isLoading: true });

        Promise.resolve()
            .then(this.changeServingInLocationIfRequired)
            .then(() => onUpdateBrokerage(this.state.brokerage))
            .then((result) => {
                this.setState({
                    isModalVisible: false,
                    isLoading: false
                });

                if (isFunction(onSuccessfullyUpdateBrokerage)) {
                    onSuccessfullyUpdateBrokerage(result);
                }
            })
            .catch((error) => {
                this.setState({
                    isModalVisible: false,
                    isLoading: false
                });

                if (isFunction(onFailToUpdateBrokerage)) {
                    onFailToUpdateBrokerage(error);
                }
            });
    };

    render = () => (
        <>
            <BrewBoardBrokerageEditModal
                isLoading={this.state.isLoading}
                isVisible={this.state.isModalVisible}
                brokerage={this.state.brokerage}
                onChange={this.onChange}
                onCancel={this.onCancel}
                onSave={this.onSave}
            />
            <BrokerageLabelButtonWrapper
                name={get(this.props.brokerage, 'name')}
                isApproved={get(this.props.brokerage, 'approved')}
                onClick={this.onClick}
                showApprovalStatusIcon
                small
            />
        </>
    );
}

BrewBoardBrokerageLabel.defaultProps = {
    onUpdateBrokerage: brewBoardUpdateBrokerage,
};

BrewBoardBrokerageLabel.propTypes = {
    brokerage: PropTypes.object,
    onUpdateBrokerage: PropTypes.func,
    onSuccessfullyUpdateBrokerage: PropTypes.func,
    onFailToUpdateBrokerage: PropTypes.func,
};
