import React from 'react';
import PropTypes from 'prop-types';
import Button from './../Button/Button';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './sessionexpirynotification.css';

const SessionExpiryNotification = (props) => (
    <Modal
        animation
        autoFocus
        backdrop
        enforceFocus
        show={props.isVisible}
        className="session-expiry-notification"
    >
        <ModalHeader className="session-expiry-notification__header">
            <Link
                to="/signout"
            >
                <Button
                    icon
                    dark
                    symbol="times"
                    type="button"
                    onClick={props.onClose}
                >
                </Button>
            </Link>
        </ModalHeader>
        <ModalBody className="session-expiry-notification__body">
            <h2>
                <i className="fa fa-home" />
                {' '}
                Knock knock, anyone home?
            </h2>
            To help secure your account, we will log you out in a moment due to inactivity.
        </ModalBody>
    </Modal>
);

SessionExpiryNotification.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

export default SessionExpiryNotification;
