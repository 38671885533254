import React from 'react';
import PropTypes from 'prop-types';
import invoke from 'lodash/invoke';
import first from 'lodash/first';
import Dropzone from 'react-dropzone';
import { Button } from '../Button/Button';

export default function RealtorMatchProfileAttachFileButton(props) {
    return (
        <Dropzone
            onDropAccepted={(files) => invoke(props, 'onAcceptFile', first(files))}
            multiple={false}
        >
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button
                        className={props.className}
                        small
                        yellow
                        design={props.type}
                    >
                        {props.textButton}
                    </Button>
                </div>
            )}
        </Dropzone>
    );
}

RealtorMatchProfileAttachFileButton.propTypes = {
    onAcceptFile: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
    textButton: PropTypes.string,
};

RealtorMatchProfileAttachFileButton.defaultProps = {
    type: 'default-inverted',
    textButton: 'Attach file',
};
