import { withFormik } from 'formik';
import * as Yup from 'yup';
import { getCsrfToken } from '../../util/util.js';
import axios from 'axios';
import AdminPortal from './AdminPortal';


// Wrap our form with the using withFormik HoC
const AdminPortalContainer = withFormik({
    // Transform outer props into form values
    mapPropsToValues: props => ({
        licenseID: '',
        SparkhireURL: '',
    }),
    // Add a custom validation function (this can be async too!)
    validate: (values, props) => {
        const URLRegex = /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
        const errors = {};

        if (!values.licenseID) {
            errors.licenseID = 'License ID is Required';
        } else if (!URLRegex.test(values.SparkhireURL)) {
            errors.SparkhireURL = 'Invalid URL address';
        }

        return errors;
    },
    validationSchema: Yup.object().shape({
        licenseID: Yup.number().required('License ID is required'),
        SparkhireURL: Yup.string().required('SparkhireURL is required'),
    }),
    // Submission handler
    handleSubmit(values, { resetForm, setErrors, setSubmitting }) {
        var UserDto = new FormData();
        UserDto.append("licenseId", values.licenseID);
        UserDto.append("sparkHireUrl", values.SparkhireURL);
        UserDto.append("approveOrReject", true);

        let token = getCsrfToken();

        axios({
            method: 'post',
            url: `http://localhost:8080/admin/realtor/confirm`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                'XSRF-TOKEN': token
            },
            data: UserDto,
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN'
        })
            .then(function (response) {
                console.log('response.data', response.data)
            })
            .catch(function (error) {
                console.log('error', error)
            });

        setTimeout(() => {
            setSubmitting(false)
        }, 2000)
    }
})(AdminPortal);

export default AdminPortalContainer;
