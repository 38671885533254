import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import WizardStepsContainer from './WizardSteps/WizardStepsContainer';
import mixpanel from 'mixpanel-browser';

class BuyerSignUp extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.any,
    };

    componentDidMount = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_BUYER_TOKEN);
    };

    render() {
        return <WizardStepsContainer history={this.props.history}/>;
    }
}

const mapStateToProps = state => ({
    isAuthenticated: !!state.user.authorization
});

export default connect(mapStateToProps)(BuyerSignUp);
