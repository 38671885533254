import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import Wizard from './Wizard';

export default class SimpleWizard extends React.Component {
    static propTypes = {
        /**
         * If true, the progress bar at the top will be visible
         */
        displayProgressHeader: PropTypes.bool,
        /**
         * Initial step where the wizard will start
         */
        initialStep: PropTypes.number,
        /**
         * Same as Wizard
         */
        steps: PropTypes.array,
        /**
         * If true, the visible step will be the one specified on completedStep
         */
        isCompleted: PropTypes.bool,
        /**
         * When isCompleted === true, this is the step index that will be visible
         */
        completedStep: PropTypes.number,
        /**
         * When true, the "Finish" button will display a loading spinner
         */
        isSubmitting: PropTypes.bool,
        /**
         * When true, the user won't be able to go forward (only backwards)
         */
        blockForward: PropTypes.bool,
        /**
         * Blocks any navigation prior to a particular step.
         * Example, if blockGoingBackwardsFromStep === 2, the user
         * wont be able to backwards to step 0 and 1
         */
        blockGoingBackwardsFromStep: PropTypes.number,
        onNavigate: PropTypes.func,
        onSkip: PropTypes.func,
        onNext: PropTypes.func,
        onFinish: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentStep: this.props.initialStep,
            skippedSteps: {}
        };
    }

    handleNavigate = (toStep, toStepIndex, steps) => {
        invoke(this.props, 'onNavigate', toStep, toStepIndex, steps);
        this.setState({ currentStep: toStepIndex });
    };

    handleSkip = (toStep, toStepIndex, steps) => {
        const { skippedSteps } = this.state;
        invoke(this.props, 'onSkip', toStep, toStepIndex, steps);
        this.setState({ skippedSteps: { ...skippedSteps, [toStepIndex]: true } });
    };

    getSteps = () => map(this.props.steps, (step, stepIndex) => ({
        ...step,
        isSkipped: this.state.skippedSteps[stepIndex]
    }));

    render = () => (
        <Wizard
            displayProgressHeader={this.props.displayProgressHeader}
            steps={this.getSteps()}
            onBack={this.props.onBack}
            onSkip={this.handleSkip}
            onNext={this.props.onNext}
            onNavigate={this.handleNavigate}
            onFinish={this.props.onFinish}
            currentStep={this.props.isCompleted ? this.props.completedStep : this.state.currentStep}
            isSubmitting={this.props.isSubmitting}
            blockNavigation={this.props.isSubmitting}
            blockForward={this.props.blockForward}
            blockGoingBackwardsFromStep={this.props.blockGoingBackwardsFromStep}
        />
    );
}
