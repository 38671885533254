import React from 'react';
import CloseModalButton from '../../../CloseModalButton/CloseModalButton';
import Button from '../../../Button/Button';

import './step.css';

export default function Step(props) {
    return (
        <section className="realtor-tour-step">
            <div className="realtor-tour-step__close-button">
                <CloseModalButton onClick={props.onClose} />
            </div>
            <p className="realtor-tour-step__content">{props.children}</p>
            <footer className="realtor-tour-step__action-footer">
                <div className="realtor-tour-step__steps-counter">{props.currentStep + 1} / {props.totalSteps}</div>
                <div className="realtor-tour-step__action-buttons">
                    <Button disabled={props.currentStep === 0} text small onClick={props.onBack}>Back</Button>
                    {props.currentStep + 1 < props.totalSteps && <Button small onClick={props.onNext}>Next</Button>}
                    {props.currentStep + 1 >= props.totalSteps && <Button small onClick={props.onFinish}>Done</Button>}
                </div>
            </footer>
        </section>
    )
}
