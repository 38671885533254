import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { updateRealtorsName } from '../../util/service_api';
import invoke from 'lodash/invoke';

import './realtorNameField.css';

export default class RealtorNameField extends Component {
    static propTypes = {
        canEdit: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        onEdit: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.canEdit !== this.props.canEdit) {
            this.onCancelEdit();
        }
    }

    onEdit = () => {
        this.setState({ isEditing: true });
    };

    onCancelEdit = () => {
        this.setState({
            isEditing: false,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
        });
    };

    onChangeName = (event) => {
        invoke(this.props, 'onEditFirstName', event.target.value);
        this.setState({ firstName: event.target.value });
    };

    onChangeLastName = (event) => {
        invoke(this.props, 'onEditLastName', event.target.value);
        this.setState({ lastName: event.target.value });
    };

    buildEditableNameField = () => (
        <input
            className="form-control realtor-name-field__field-editable"
            type="text"
            maxLength="50"
            onChange={this.onChangeName}
            value={this.state.firstName}
        />
    );

    buildEditableLastNameField = () => (
        <input
            className="form-control realtor-name-field__field-editable"
            type="text"
            maxLength="50"
            onChange={this.onChangeLastName}
            value={this.state.lastName}
        />
    );

    buildEditableField = () => (
        <div className="realtor-name-field__name-last-name">
            {this.buildEditableNameField()}
            {this.buildEditableLastNameField()}
        </div>
    );

    buildFieldValue = () => (
        <p className="realtor-name-field__field-value">
            {`${this.props.firstName} ${this.props.lastName}`}
        </p>
    );

    onConfirmEdit = () => {
        updateRealtorsName({ firstName: this.state.firstName, lastName: this.state.lastName })
            .then(this.onSuccessfullyEdit)
            .catch(this.onFailureEdit);
    };

    onSuccessfullyEdit = () => {
        this.setState({
            isEditing: false,
        });

        if (isFunction(this.props.onEdit)) {
            this.props.onEdit(this.state.firstName, this.state.lastName);
        }
    };

    onFailureEdit = () => {};

    buildEditButton = () => (
        <button
            type="button"
            className="btn-link btn-link realtor-name-field__edit-button"
            onClick={this.onEdit}
        >
            Edit
        </button>
    );

    buildCancelEditButton = () => (
        <button
            type="button"
            className="btn-link realtor-name-field__cancel-button"
            onClick={this.onCancelEdit}
        >
            Cancel
        </button>
    );

    buildConfirmEditButton = () => (
        <button
            type="button"
            className="btn-link realtor-name-field__save-button"
            onClick={this.onConfirmEdit}
        >
            Save
        </button>
    );

    buildCancelAndConfirmEditButtons = () => (
        <span>
            {this.buildCancelEditButton()}
            {this.buildConfirmEditButton()}
        </span>
    );

    render = () => {
        const { canEdit } = this.props;
        const { isEditing } = this.state;
        const field = isEditing
            ? this.buildEditableField()
            : this.buildFieldValue();

        return (
            <div>
                <div className="realtor-name-field__field-label">
                    {canEdit && !isEditing && this.buildEditButton()}
                    {isEditing && this.buildCancelAndConfirmEditButtons()}
                </div>
                <div className="realtor-name-field__label">
                    {field}
                </div>
            </div>
        );
    };
}