import get from "lodash/get";
import React, { Component, Fragment } from "react";
import * as PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import split from "lodash/split";
import { Form, Field, getIn } from "formik";
import { Link, Redirect } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import GlyphIconChat from "../../styles/assets/icon-Chat.svg";
import GlyphIconPlane from "../../styles/assets/icon-PaperPlane.svg";
import Spinner from "../Spinner/Spinner";
import mixpanel from "mixpanel-browser";
import publicIp from "react-public-ip";
import { getQueryVariable, getUTCDate } from "../../util/util";

import "./signin-form.css";

class SignInForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tracked: false
        };
    }

    componentDidMount() {
        const { userProfile } = this.props;
        const isBuyer = get(userProfile, "publicProfile.userInfo.buyer");
        const isSeller = get(userProfile, "publicProfile.userInfo.seller");
        const isProspect = get(userProfile, "publicProfile.userInfo.prospect");
        if (isBuyer) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_BUYER_TOKEN);
        }
        if (isSeller) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_SELLER_TOKEN);
        }
        if (isProspect) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_PROSPECT_TOKEN);
        }
    }

    getFieldError(fieldName) {
        const { errors, touched } = this.props;
        if (!getIn(touched, fieldName)) return;

        const message = getIn(errors, fieldName);
        if (typeof message === "string") return message;
    }

    renderFieldError = fieldName => {
        const message = this.getFieldError(fieldName);
        if (message)
            return <div className="signin__field__error">{message}</div>;
    };

    renderFormError = () => {
        const { touched, loginError } = this.props;
        if (loginError && !(touched.username || touched.password)) {
            return <div className="signin__login-error">{loginError}</div>;
        }
    };

    routeChange = () => {
        let path = `/reset-password`;
        this.props.history.push(path);
    };

    trackMixpanel = () => {
        const { location } = this.props;
        const path = get(split(location.search, "?"), 1);
        const token = getQueryVariable(path, "token");
        if (!this.state.tracked) {
            mixpanel.track("Verified Email", {
                token: token
            });
            this.setState({ tracked: true });
        }
    };

    buildSignInMessage = () => {
        const {
            location,
            isFetchedSignUpUser,
            errorSignUpMessage
        } = this.props;

        if (location.search.toLowerCase().includes("token")) {
            if (!isEmpty(errorSignUpMessage)) {
                return <Fragment>{errorSignUpMessage}</Fragment>;
            }
            if (isFetchedSignUpUser) {
                const path = get(split(location.search, "?"), 1);
                const type = getQueryVariable(path, "type");
                if (type === "buyer") {
                    mixpanel.init(process.env.REACT_APP_MIXPANEL_BUYER_TOKEN);
                    this.trackMixpanel();
                }
                if (type === "seller") {
                    mixpanel.init(process.env.REACT_APP_MIXPANEL_SELLER_TOKEN);
                    this.trackMixpanel();
                }
                if (type === "prospect") {
                    mixpanel.init(
                        process.env.REACT_APP_MIXPANEL_PROSPECT_TOKEN
                    );
                    this.trackMixpanel();
                }

                return (
                    <Fragment>
                        Your email has successfully been verified.
                        <br />
                        Please log in.
                    </Fragment>
                );
            }
        }

        return (
            <Fragment>
                Are you new to this?{" "}
                <Link to="/get-started">Create your account</Link>.
            </Fragment>
        );
    };

    getUserPublicIPV4 = async () => {
        const { userProfile } = this.props;

        await publicIp.v4().then(ipv4 => {
            mixpanel.people.set({
                $email: get(userProfile, "publicProfile.userInfo.email"),
                $last_login: getUTCDate(new Date()),
                $ip: ipv4,
                $first_name: get(
                    userProfile,
                    "publicProfile.userInfo.firstName"
                ),
                $last_name: get(userProfile, "publicProfile.userInfo.lastName"),
                $phone: get(userProfile, "publicProfile.userInfo.phone"),
                hasAgentAcceptedMatch: get(
                    userProfile,
                    "hasRealtorAcceptedMatch"
                )
                    ? true
                    : false,
                agentFirstName: get(userProfile, "realtor.userInfo.firstName"),
                agentLastName: get(userProfile, "realtor.userInfo.lastName"),
                agentEmail: get(userProfile, "realtor.userInfo.email"),
                agentPhone: get(userProfile, "realtor.userInfo.phone")
            });
        });
    };

    getProspectUserPublicIPV4 = async () => {
        const { userProfile } = this.props;

        await publicIp.v4().then(ipv4 => {
            mixpanel.people.set({
                $email: get(userProfile, "publicProfile.userInfo.email"),
                $last_login: getUTCDate(new Date()),
                $ip: ipv4,
                $first_name: get(
                    userProfile,
                    "publicProfile.userInfo.firstName"
                ),
                $last_name: get(userProfile, "publicProfile.userInfo.lastName"),
                $phone: get(userProfile, "publicProfile.userInfo.phone")
            });
        });
    };

    mixpanelLogin = (basicInformation = false) => {
        const { userProfile } = this.props;
        mixpanel.identify(get(userProfile, "publicProfile.userInfo.email"));
        if (basicInformation) {
            this.getProspectUserPublicIPV4();
            return;
        }
        this.getUserPublicIPV4();
    };

    render() {
        const {
            isSubmitting,
            authorization,
            userProfile,
            location,
            isFetchingSignUpUser
        } = this.props;

        if (isFetchingSignUpUser) return <Spinner className="immediate" />;

        if (authorization) {
            const { from } = location.state || { from: undefined };
            if (from) return <Redirect to={from} />;

            const hasCompletedOnboarding = get(
                userProfile,
                "publicProfile.userInfo.hasCompletedOnboarding"
            );
            const isBuyer = get(userProfile, "publicProfile.userInfo.buyer");
            const isSeller = get(userProfile, "publicProfile.userInfo.seller");
            const isProspect = get(
                userProfile,
                "publicProfile.userInfo.prospect"
            );

            if (hasCompletedOnboarding) {
                if (isBuyer) {
                    this.mixpanelLogin();
                }
                if (isSeller) {
                    this.mixpanelLogin();
                }
                return <Redirect to={{ pathname: "/profile" }} />;
            }

            const isRealtor = get(
                userProfile,
                "publicProfile.userInfo.realtor"
            );
            const isBroker = get(userProfile, "publicProfile.userInfo.broker");
            const isAdmin = get(userProfile, "publicProfile.userInfo.brewCrew");

            if (isRealtor || isAdmin)
                return <Redirect to={{ pathname: "/profile" }} />;
            const basicInformation = true;
            if (isProspect) {
                this.mixpanelLogin(basicInformation);
                return <Redirect to={{ pathname: "/signup/transfer" }} />;
            }
            if (isBuyer) {
                this.mixpanelLogin();
                return <Redirect to={{ pathname: "/signup/buyer" }} />;
            }
            if (isSeller) {
                this.mixpanelLogin();
                return <Redirect to={{ pathname: "/profile" }} />;
            }
            if (isBroker) {
                return <Redirect to="/profile" />;
            }

            return <Redirect to={{ pathname: "/" }} />;
        }
        const signInMessage = this.buildSignInMessage();

        return (
            <div>
                <Form className="signin__form">
                    <p className="signin__title">Log Into Your Account</p>
                    <p className="signin__message">{signInMessage}</p>

                    {this.renderFormError()}

                    <div className="signin__fieldset">
                        <div className="signin__field__row">
                            <div className="signin__field align-center">
                                <Field
                                    type="text"
                                    name="username"
                                    placeholder="EMAIL"
                                />
                                {this.renderFieldError("username")}
                            </div>
                        </div>
                    </div>

                    <div className="signin__fieldset">
                        <div className="signin__field__row">
                            <div className="signin__field align-center">
                                <Field
                                    type="password"
                                    name="password"
                                    placeholder="PASSWORD"
                                />
                                {this.renderFieldError("password")}
                            </div>
                        </div>
                    </div>

                    <div className="signin__fieldset signin__controls">
                        <div className="signin__field__row">
                            <div className="signin__field width-50">
                                <Button disabled={isSubmitting} type="submit">
                                    {isSubmitting ? (
                                        <div>
                                            <i
                                                className="fas fa-spinner fa-spin"
                                                style={{
                                                    textAlign: "center",
                                                    padding: 0
                                                }}
                                            />
                                            <span
                                                style={{ paddingLeft: "8px" }}
                                            >
                                                Loading...
                                            </span>
                                        </div>
                                    ) : (
                                        <Fragment>LOG IN</Fragment>
                                    )}
                                </Button>
                            </div>
                            <Button onClick={this.routeChange} text>
                                Forgot Password
                            </Button>
                        </div>
                    </div>
                </Form>
                <div className="signin__footer">
                    <div className="signin__footer__block">
                        <img
                            className="signin__footer__icon"
                            src={GlyphIconPlane}
                            alt=""
                            width="42"
                        />
                        <div className="signin__footer__label">Email us:</div>
                        <div className="signin__footer__value">
                            <a href="mailto:hello@lemonbrew.com">
                                hello@lemonbrew.com
                            </a>
                        </div>
                    </div>
                    <div className="signin__footer__block">
                        <img
                            className="signin__footer__icon"
                            src={GlyphIconChat}
                            alt=""
                            width="36"
                        />
                        <div className="signin__footer__label">Call us:</div>
                        <div className="signin__footer__value">
                            833-LEMONBREW (536-6627)
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SignInForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.shape({}),
    isSubmitting: PropTypes.bool,
    location: PropTypes.object,
    authorization: PropTypes.bool,
    loginError: PropTypes.string,
    userProfile: PropTypes.object,
    userInfo: PropTypes.object,
    isFetchingSignUpUser: PropTypes.bool,
    isFetchedSignUpUser: PropTypes.bool,
    signupUser: PropTypes.func,
    errorSignUpMessage: PropTypes.string
};

export default SignInForm;
