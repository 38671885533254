import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import classNames from 'classnames';
import './realtor-internal-tab.css';

export default function RealtorInternalTab(props) {
    return (
        <div className="realtor-internal-tab">
            {map(props.tabs, (tab) => (
                <button
                    onClick={get(tab, 'onClick')}
                    className={classNames(
                        'realtor-internal-tab__button',
                        { selected: get(tab, 'isSelected') }
                    )}
                >
                    {get(tab, 'label')}
                </button>
            ))}
        </div>
    );
}