import React from "react";
import get from "lodash/get";
import AdminProfileStatsAgentInfo from "components/AdminProfileStats/AdminProfileStatsAgentInfo";
import AdminProfileStatsHeader from "components/AdminProfileStatsHeader/AdminProfileStatsHeader";
import AdminProfileBuyerResultsTable from "./AdminProfileBuyerResultsTable";
import SpinnerLoader from "components/SpinnerLoader/SpinnerLoader";
import "./admin-profile-buyer-stats.css";

export default class AdminProfileBuyerStats extends React.PureComponent {
    buildDateFilterHeader = () => (
        <AdminProfileStatsHeader
            selectedDate={this.props.selectedDate}
            onChangeStartMonth={this.props.onChangeStartMonth}
            onChangeStartYear={this.props.onChangeStartYear}
        />
    );

    buildAccountsCreatedStats = () => (
        <AdminProfileStatsAgentInfo
            title={"ACCOUNTS CREATED"}
            number={get(this.props, "stats.accountsCreated.number")}
            mtd={get(this.props, "stats.accountsCreated.mtd")}
            ytd={get(this.props, "stats.accountsCreated.ytd")}
        />
    );

    buildMatchedWithAgentStats = () => (
        <AdminProfileStatsAgentInfo
            title={"MATCHED WITH AN AGENT"}
            number={get(this.props, "stats.matchedWithAgent.number")}
            mtd={get(this.props, "stats.matchedWithAgent.mtd")}
            ytd={get(this.props, "stats.matchedWithAgent.ytd")}
        />
    );

    buildUnmatchedStats = () => (
        <AdminProfileStatsAgentInfo
            title={"UNMATCHED"}
            number={get(this.props, "stats.unmatched.number")}
            mtd={get(this.props, "stats.unmatched.mtd")}
            ytd={get(this.props, "stats.unmatched.ytd")}
        />
    );

    buildTableWithResults = () => (
        <AdminProfileBuyerResultsTable
            statsByState={get(this.props, "stats.byState")}
            onClickStat={this.props.onClickStat}
        />
    );

    buildContent = () => (
        <div className="admin-profile-buyer-stats">
            {this.buildDateFilterHeader()}
            <div className="admin-profile-buyer-stats__container row">
                <div className="col-xs-12 col-md-4 admin-profile-buyer-stats__stat-box">
                    {this.buildAccountsCreatedStats()}
                </div>
                <div className="col-xs-12 col-md-4 admin-profile-buyer-stats__stat-box">
                    {this.buildMatchedWithAgentStats()}
                </div>
                <div className="col-xs-12 col-md-4 admin-profile-buyer-stats__stat-box">
                    {this.buildUnmatchedStats()}
                </div>
                <div className="col-xs-12">{this.buildTableWithResults()}</div>
            </div>
        </div>
    );

    buildLoading = () => <SpinnerLoader />;

    render = () =>
        this.props.isLoading ? this.buildLoading() : this.buildContent();
}
