import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter, Switch, Route } from 'react-router-dom';
import map from 'lodash/map';
import get from 'lodash/get';
import trim from 'lodash/trim';
import invoke from 'lodash/invoke';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import RealtorPendingMatchItem from '../RealtorPendingMatchItem/RealtorPendingMatchItem';
import DecliningModal from '../DecliningModal/DecliningModal';
import { Button } from '../Button/Button';
import RealtorTransactionBuyerReferralAgreementModal from '../RealtorTransactionBuyerReferralAgreementModal/RealtorTransactionBuyerReferralAgreementModal';
import { acceptMatch, acceptSellerMatch } from '../../util/service_api';
import { fetchActiveMatches } from '../../reducers/user/UserActions';
import RealtorInternalTab from '../RealtorInternalTab/RealtorInternalTab';
import RealtorProfilePendingBuyerMatchesTab from '../RealtorProfilePendingBuyerMatchesTab/RealtorProfilePendingBuyerMatchesTab';
import RealtorProfilePendingSellerMatchesTab from '../RealtorProfilePendingSellerMatchesTab/RealtorProfilePendingSellerMatchesTab';
import RealtorProfilePendingAllMatchesTab from '../RealtorProfilePendingAllMatchesTab/RealtorProfilePendingAllMatchesTab';
import './realtorProfilePendingMatchesTab.css';

class RealtorProfilePendingMatchesTabComponent extends React.Component {
    static propTypes = {
        buyerMatchDeclinedOptions: PropTypes.array,
        onConfirmMatch: PropTypes.func,
        onConfirmDeclineMatch: PropTypes.func,
        onConfirmSellerDeclineMatch: PropTypes.func,
        onSelectRejection: PropTypes.func,
        buyerMatchRequests: PropTypes.array.isRequired,
        buyerDocuSignLink: PropTypes.string,
        hasNoActiveMatches: PropTypes.bool,
        sellerMatchDeclinedOptions: PropTypes.array,
        sellerMatchRequests: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            /* Buyer */
            idMatch: 0,
            docuSignLink: '',
            isRejectMatchModalVisible: false,
            isTransactionBuyerReferralAgreementModalVisible: false,
            isLoadingAcceptMatchingService: [],

            /* Seller */
            sellerIdMatch: 0,
            sellerDocuSignLink: '',
            isSellerRejectMatchModalVisible: false,
            isTransactionSellerReferralAgreementModalVisible: false,
            isLoadingAcceptingSellerMatchingService: [],

            displayYourMatchWillAppearShortly: false,
        };
    }

    updateDocusignLinkAndDisplayAgreementModal = (docusignLink) => {
        this.setState({
            isTransactionBuyerReferralAgreementModalVisible: true,
            docuSignLink: docusignLink,
        });
    };

    updateSellerDocusignLinkAndDisplayAgreementModal = (docusignLink) => {
        this.setState({
            isTransactionSellerReferralAgreementModalVisible: true,
            sellerDocuSignLink: docusignLink,
        });
    };

    loadingIsCompleteFor = (id) => {
        const { isLoadingAcceptMatchingService } = this.state;
        const updatedState = cloneDeep(isLoadingAcceptMatchingService);
        updatedState[id] = false;
        this.setState({ isLoadingAcceptMatchingService: updatedState });
    };

    onAcceptServiceMatch = (id) => {
        acceptMatch(id, {})
            .then(this.updateDocusignLinkAndDisplayAgreementModal)
            .finally(() => this.loadingIsCompleteFor(id));
    };

    onRouteChange = () => {
        const path = `/realtor/profile/edit`;
        this.props.history.push(path);
    };

    onCloseRejectMatchModal = () => {
        this.setState({ isRejectMatchModalVisible: false });
    };

    onCloseSellerRejectMatchModal = () => {
        this.setState({ isSellerRejectMatchModalVisible: false });
    };

    sellerLoadingIsCompleteFor = (id) => {
        const { isLoadingAcceptingSellerMatchingService } = this.state;
        const updatedState = cloneDeep(isLoadingAcceptingSellerMatchingService);
        updatedState[id] = false;
        this.setState({ isLoadingAcceptingSellerMatchingService: updatedState });
    };

    onAcceptSellerServiceMatch = (id) => {
        acceptSellerMatch(id, {})
            .then(this.updateSellerDocusignLinkAndDisplayAgreementModal)
            .finally(() => this.sellerLoadingIsCompleteFor(id));
    };

    onDeclineServiceMatch = () => {
        if (isFunction(this.props.onConfirmDeclineMatch)) {
            this.props.onConfirmDeclineMatch(this.state.idMatch);
        }
        this.onCloseRejectMatchModal();
    };

    onDeclineSellerServiceMatch = () => {
        if (isFunction(this.props.onConfirmSellerDeclineMatch)) {
            this.props.onConfirmSellerDeclineMatch(this.state.sellerIdMatch);
        }

        this.onCloseSellerRejectMatchModal();
    }

    onAcceptMatch = (id) => {
        const { isLoadingAcceptMatchingService } = this.state;
        let newState = cloneDeep(isLoadingAcceptMatchingService);
        newState[id] = true;
        this.setState({
            idMatch: id,
            isLoadingAcceptMatchingService: newState,
        });
        this.onAcceptServiceMatch(id);
    };

    onRejectMatch = (id) => {
        this.setState({
            idMatch: id,
            isRejectMatchModalVisible: true,
        });
    };

    onAcceptSellerMatch = (id) => {
        const { isLoadingAcceptingSellerMatchingService } = this.state;
        let newState = cloneDeep(isLoadingAcceptingSellerMatchingService);
        newState[id] = true;
        this.setState({
            sellerIdMatch: id,
            isLoadingAcceptingSellerMatchingService: newState,
        });
        this.onAcceptSellerServiceMatch(id);
    };

    onRejectSellerMatch = (id) => {
        this.setState({
            sellerIdMatch: id,
            isSellerRejectMatchModalVisible: true,
        });
    };

    buildPendingMatchFullName = (/* pendingMatch */) => (
        /** We're not showing the match name until accepted so commenting this out. ABP  */
        // const firstName = trim(get(pendingMatch, 'userInfo.firstName'));
        // const lastName = trim(get(pendingMatch, 'userInfo.lastName'));
        // const fullName = trim(`${firstName} ${lastName}`);
        'Looking In: '
    );

    buildPendingMatchLocation = (pendingMatch) => {
        const newAddresses = get(pendingMatch, 'newAddresses');
        return map(newAddresses, (location) => trim(get(location, 'city'))).join(', ');
    };

    buildPendingMatchBudget = (pendingMatch) => {
        const budget = {
            currency: get(pendingMatch, 'budgetMin.currency'),
            amount: get(pendingMatch, 'budgetMin.amount'),
        };
        return budget;
    };

    buildMatchMaxBudget = (pendingMatch) => ({
        amount: get(pendingMatch, 'budgetMax.amount'),
        currency: get(pendingMatch, 'budgetMax.currency'),
    });

    buildPendingMatch = (pendingMatch, key) => {
        const { isLoadingAcceptMatchingService } = this.state;
        const id = get(pendingMatch, 'userInfo.id');
        return (
            <RealtorPendingMatchItem
                key={key}
                picture={get(pendingMatch, 'userInfo.profilePicUrl')}
                name={this.buildPendingMatchFullName(pendingMatch)}
                location={this.buildPendingMatchLocation(pendingMatch)}
                budget={this.buildPendingMatchBudget(pendingMatch)}
                maxBudget={this.buildMatchMaxBudget(pendingMatch)}
                rebate={get(pendingMatch, 'rebatePercentage')}
                onAcceptMatch={() => this.onAcceptMatch(get(pendingMatch, 'userInfo.id'))}
                onRejectMatch={() => this.onRejectMatch(get(pendingMatch, 'userInfo.id'))}
                isLoading={isLoadingAcceptMatchingService[id]}
            />
        );
    };

    buildPendingMatches = () => (
        <div className="realtor-profile-pending-matches-tab__content">
            <DecliningModal
                isVisible={this.state.isRejectMatchModalVisible}
                buyerMatchDeclinedOptions={this.props.buyerMatchDeclinedOptions}
                onClose={this.onCloseRejectMatchModal}
                onDeclineMatch={this.onDeclineServiceMatch}
                onSelectRejection={this.props.onSelectRejection}
            />
            {map(this.props.buyerMatchRequests, this.buildPendingMatch)}
        </div>
    );

    buildEmptyState = () => (
        <div className="realtor-profile-pending-matches-tab__empty-state">
            <div className="realtor-profile-pending-matches-tab__title"></div>
            <span className="realtor-profile-pending-matches-tab__text">
                We&#39;re sorry we haven&#39;t been able to match you with any buyers yet.
                <br /><br />
                In the meantime, please make sure you have a complete profile<br /> and completed the video interview so we can find the best match for you! 
            </span>
            <div>
                <Link to="/realtor/profile/edit">
                    <Button>Edit My Profile</Button>
                </Link>
            </div>
        </div>
    );

    buildEmptyStateWithActiveMatches = () => (
        <div className="realtor-profile-pending-matches-tab__active-matches-empty-state">
            <span className="realtor-profile-pending-matches-tab__active-matches-label">
                No active pending matches.
            </span>
        </div>
    );

    onCloseTransactionContractModal = () => {
        this.setState({
            isTransactionBuyerReferralAgreementModalVisible: false,
            displayYourMatchWillAppearShortly: true,
        });
        if (isFunction(this.props.onConfirmMatch)) {
            this.props.onConfirmMatch(this.state.idMatch);
        }
    };

    buildTransactionBuyerReferralAgreementModal = () => {
        const { isTransactionBuyerReferralAgreementModalVisible, docuSignLink } = this.state;
        return (
            <RealtorTransactionBuyerReferralAgreementModal
                isVisible={isTransactionBuyerReferralAgreementModalVisible}
                onClose={this.onCloseTransactionContractModal}
                onCancel={this.onCloseTransactionContractModal}
                buyerDocuSignLink={docuSignLink}
            />
        );
    };

    onCloseSellerTransactionContractModal = () => {
        this.setState({
            isTransactionSellerReferralAgreementModalVisible: false,
            displayYourMatchWillAppearShortly: true,
        });
        if (isFunction(this.props.onConfirmSellerMatch)) {
            this.props.onConfirmSellerMatch(this.state.sellerIdMatch);
        }
    };

    buildTransactionSellerReferralAgreementModal = () => {
        const { isTransactionSellerReferralAgreementModalVisible, sellerDocuSignLink } = this.state;
        return (
            <RealtorTransactionBuyerReferralAgreementModal
                isVisible={isTransactionSellerReferralAgreementModalVisible}
                onClose={this.onCloseSellerTransactionContractModal}
                onCancel={this.onCloseSellerTransactionContractModal}
                buyerDocuSignLink={sellerDocuSignLink}
            />
        );
    };

    buildYourMatchWillAppearShortly = () => {
        const { displayYourMatchWillAppearShortly } = this.state;

        if (!displayYourMatchWillAppearShortly) return null;

        const style = {
            padding: '10px',
            backgroundColor: '#FFF1C3',
            borderRadius: '5px',
            textAlign: 'center',
        };

        return <div style={style}>Thank you! Your match will appear shortly in the Active Matches tab</div>;
    };

    buildContent = () => {
        const isEmptyBuyerMatchRequests = isEmpty(this.props.buyerMatchRequests);

        if (!isEmptyBuyerMatchRequests) {
            return this.buildPendingMatches();
        }

        if (this.props.hasNoActiveMatches) {
            return this.buildEmptyState();
        }

        return this.buildEmptyStateWithActiveMatches();
    };

    buildInternalTabRoutes = () => {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/buyers`}>
                    <RealtorProfilePendingBuyerMatchesTab
                        isLoadingAcceptMatchingService={this.state.isLoadingAcceptMatchingService}
                        isRejectMatchModalVisible={this.state.isRejectMatchModalVisible}
                        buyerMatchDeclinedOptions={this.props.buyerMatchDeclinedOptions}
                        onCloseRejectMatchModal={this.onCloseRejectMatchModal}
                        onDeclineServiceMatch={this.onDeclineServiceMatch}
                        onSelectRejection={this.props.onSelectRejection}
                        buyerMatchRequests={this.props.buyerMatchRequests}
                        buildPendingMatchFullName={this.buildPendingMatchFullName}
                        buildPendingMatchLocation={this.buildPendingMatchLocation}
                        buildPendingMatchBudget={this.buildPendingMatchBudget}
                        buildMatchMaxBudget={this.buildMatchMaxBudget}
                        onAcceptMatch={this.onAcceptMatch}
                        onRejectMatch={this.onRejectMatch}
                    />
                </Route>
                <Route path={`${this.props.match.path}/sellers`}>
                    <RealtorProfilePendingSellerMatchesTab
                        isLoadingAcceptMatchingService={this.state.isLoadingAcceptingSellerMatchingService}
                        isRejectMatchModalVisible={this.state.isSellerRejectMatchModalVisible}
                        sellerMatchDeclinedOptions={this.props.sellerMatchDeclinedOptions}
                        onCloseRejectMatchModal={this.onCloseSellerRejectMatchModal}
                        onDeclineServiceMatch={this.onDeclineSellerServiceMatch}
                        sellerMatchRequests={this.props.sellerMatchRequests}
                        onAcceptMatch={this.onAcceptSellerMatch}
                        onRejectMatch={this.onRejectSellerMatch}
                        onSelectRejection={this.props.onSelectSellerRejection}
                    />
                </Route>
                <Route path={`${this.props.match.path}`}>
                    <RealtorProfilePendingAllMatchesTab
                        buyer={{
                            isLoadingAcceptMatchingService: this.state.isLoadingAcceptMatchingService,
                            isRejectMatchModalVisible: this.state.isRejectMatchModalVisible,
                            buyerMatchDeclinedOptions: this.props.buyerMatchDeclinedOptions,
                            onCloseRejectMatchModal: this.onCloseRejectMatchModal,
                            onDeclineServiceMatch: this.onDeclineServiceMatch,
                            onSelectRejection: this.props.onSelectRejection,
                            buyerMatchRequests: this.props.buyerMatchRequests,
                            buildPendingMatchFullName: this.buildPendingMatchFullName,
                            buildPendingMatchLocation: this.buildPendingMatchLocation,
                            buildPendingMatchBudget: this.buildPendingMatchBudget,
                            buildMatchMaxBudget: this.buildMatchMaxBudget,
                            onAcceptMatch: this.onAcceptMatch,
                            onRejectMatch: this.onRejectMatch,
                        }}
                        seller={{
                            isLoadingAcceptMatchingService: this.state.isLoadingAcceptingSellerMatchingService,
                            isRejectMatchModalVisible: this.state.isSellerRejectMatchModalVisible,
                            sellerMatchDeclinedOptions: this.props.sellerMatchDeclinedOptions,
                            onCloseRejectMatchModal: this.onCloseSellerRejectMatchModal,
                            onDeclineServiceMatch: this.onDeclineSellerServiceMatch,
                            sellerMatchRequests: this.props.sellerMatchRequests,
                            onAcceptMatch: this.onAcceptSellerMatch,
                            onRejectMatch: this.onRejectSellerMatch,
                            onSelectRejection: this.props.onSelectSellerRejection,
                        }}
                    />
                </Route>
            </Switch>
        );
    };

    buildInternalTabs = () => {
        const { history } = this.props;
        const pathname = get(history, 'location.pathname');
        return (
            <RealtorInternalTab tabs={[
                {
                    label: 'all',
                    isSelected: pathname === "/realtor/pending-matches",
                    onClick: () => invoke(this.props, 'onChangeTab', 'pending-matches')
                },
                {
                    label: 'buyers',
                    isSelected: pathname === "/realtor/pending-matches/buyers",
                    onClick: () => invoke(this.props, 'onChangeTab', 'pending-matches/buyers')
                },
                {
                    label: 'sellers',
                    isSelected: pathname === "/realtor/pending-matches/sellers",
                    onClick: () => invoke(this.props, 'onChangeTab', 'pending-matches/sellers')
                }
            ]} />
        );
    };

    render = () => (
        <div className="realtor-profile-pending-matches-tab">
            {this.buildTransactionBuyerReferralAgreementModal()}
            {this.buildTransactionSellerReferralAgreementModal()}
            {this.buildInternalTabs()}
            {this.buildYourMatchWillAppearShortly()}
            {this.buildInternalTabRoutes()}
            {/*this.buildContent()*/}
        </div>
    );
}

RealtorProfilePendingMatchesTabComponent.propTypes = {
    onFetchActiveMatches: PropTypes.func,
};

const RealtorProfilePendingMatchesTab = withRouter(connect(
    (state) => ({
        user: state.user,
    }),
    {
        onFetchActiveMatches: fetchActiveMatches,
    },
)(RealtorProfilePendingMatchesTabComponent));

export default RealtorProfilePendingMatchesTab;
