import { CREATE_REALTOR_PROFILE_FAIL, CREATING_REALTOR_PROFILE, CREATE_REALTOR_PROFILE_SUCCESS } from './RealtorProfileActionTypes';

/**
 * Report reducer
 *
 * @export
 * @param {object} state
 * @param {ReportAction} action
 * @returns {object} state
 */
const initialRealtorProfile = {
    isFetchingRealtorProfile: false,
    status:null,
}

const fetchingRealtorProfile = (state) => ({
    ...state,
    isFetchingRealtorProfile: true
});

const successfullyCreateRealtorProfile = (state, status) => ({
    ...state,
    status,
    isFetchingRealtorProfile: false,
});

const errorFetchingRealtorProfile = (state, status) => ({
    ...state,
    status,
    isFetchingRealtorProfile: false
});

const realtorProfile = (state = initialRealtorProfile, action = {}) => {
    switch (action.type) {
        case CREATING_REALTOR_PROFILE:
            return fetchingRealtorProfile(state);
        case CREATE_REALTOR_PROFILE_SUCCESS:
            return successfullyCreateRealtorProfile(state, action.payload);
        case CREATE_REALTOR_PROFILE_FAIL:
            return errorFetchingRealtorProfile(state, action.payload);
        default:
            return state;
    }
};

export default realtorProfile;
