import React, { Fragment } from 'react';
import BaseWizardStep from '../BaseWizardStep';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import get from 'lodash/get';
import size from 'lodash/size';
import { chooseAgent, chooseSellerAgent } from '../../../../util/service_api';
import mixpanel from 'mixpanel-browser';
import BuyerFlowNoAgentCard from '../../../BuyerFlowNoAgentCard/BuyerFlowNoAgentCard';
import ScrollToTopOnMount from '../../../ScrollTopOnMount/ScrollTopOnMount';
import AgentsInfo from '../../../AgentsInfo/AgentsInfo';

class StepAgents extends BaseWizardStep {
    mixpanelFunction = () => {
        mixpanel.track(
            "Clicked On Select Agent",
        )
    };

    countAgentsScanned = () => Number(get(this.props.searchAgents, 'searchAgents.agentsCount')) || 0;

    buildAgentsScannedInLocationFormatted = () => {
        const approximateLocations = map(get(this.props.values, 'approximateLocations'), 'state');
        const knownLocations = map(get(this.props.values, 'knownLocations'), 'state');
        const nonEmptyLocations = [...approximateLocations, ...knownLocations].filter((state) => !!state);
        return nonEmptyLocations.join(', ');
    };

    buildAgentsScannedInCitiesFormatted = () => {
        const approximateLocations = map(get(this.props.values, 'approximateLocations'), 'city');
        const knownLocations = map(get(this.props.values, 'knownLocations'), 'city');
        const nonEmptyLocations = [...approximateLocations, ...knownLocations].filter((city) => !!city);
        return nonEmptyLocations.join(', ');
    };

    buildTitle = () => {
        const scannedInLocation = this.buildAgentsScannedInLocationFormatted();
        return `We scanned LemonBrew Partner Agents in ${scannedInLocation}…`;
    };

    buildEmptyAgents = () => (
        <Fragment>
            <div className="buyer__signup__step is-higher">
                <div className="buyer__signup__question white-font with-margin">
                    {this.buildTitle()}
                </div>
                <div className="buyer__signup__fieldset">
                    <BuyerFlowNoAgentCard locations={this.buildAgentsScannedInCitiesFormatted()} />
                </div>
            </div>
        </Fragment>
    );

    handleSelectAgent = (match) => {
        const { userProfile, values } = this.props;

        const isBuyer = get(userProfile, 'publicProfile.userInfo.buyer');
        const isSeller = get(userProfile, 'publicProfile.userInfo.seller');

        if(isSeller || get(values, 'isSeller')) {
            chooseSellerAgent(match.id)
                .then(() => {
                    if (isFunction(this.props.onChooseAgent)) {
                        this.props.onChooseAgent(match.agent);
                    }
                });
            return;
        }

        if(isBuyer || get(values, 'isBuyer')) {
            chooseAgent(match.id)
                .then(() => {
                    if (isFunction(this.props.onChooseAgent)) {
                        this.props.onChooseAgent(match.agent);
                    }
                });
            return;
        }
    }

    buildAgentsList = () => {
        const { searchAgents } = this.props;
        const agents = get(searchAgents, 'searchAgents.agents');
        const hasMixPanelTracking = true;

        return (
            <AgentsInfo
                matches={agents}
                canSelectAgent={false}
                profile={this.props.profile}
                onChooseAgent={() => {
                    setTimeout(() => this.props.onNavigateStep(1, hasMixPanelTracking, this.mixpanelFunction), 0)
                }}
                handleSelectAgent={this.handleSelectAgent}
            />
        )
    }

    buildFoundAgentsTitle = () => {
        const { searchAgents } = this.props;
        const agents = get(searchAgents, 'searchAgents.agents');
        const agentsCount = size(agents);

        if (agentsCount > 1) {
            return `Found ${agentsCount} highly qualified, responsive, local real estate agents that match perfectly with your profile`
        }

        return 'Found 1 highly qualified, responsive, local real estate agent that matches perfectly with your profile'
    }

    buildAgents = () => (
        <Fragment>
            <div className="buyer__signup__step is-higher">
                <div className="buyer__signup__question white-font with-margin">
                    {this.buildFoundAgentsTitle()}
                </div>

                <div className="buyer__signup__fieldset is-almost-full-widest">
                    <div className="buyer__signup__agents-list">
                        {this.buildAgentsList()}
                    </div>
                </div>
            </div>
        </Fragment>
    );

    buildAgentsView = () => !isEmpty(get(this.props.searchAgents, 'searchAgents.agents'))
        ? this.buildAgents() : this.buildEmptyAgents();

    render() {
        const isStepFulfilled = true;
        const isEmptyMatches = isEmpty(get(this.props.searchAgents, 'searchAgents.agents'));
        
        return (
            <Fragment>
            <ScrollToTopOnMount />
                {this.buildAgentsView()}
                { this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    hasMixPanelTracking: true,
                    buttonName: 'Submit Contact Info',
                    hideNextButton: !isEmptyMatches,
                    hideBackButton: true,
                }, this.mixpanelFunction)}
            </Fragment>
        );
    }
}

export default StepAgents;
