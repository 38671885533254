import React from 'react';
import RealtorProfileBrokerageInformationForm from '../../../RealtorProfileBrokerageInformationForm/RealtorProfileBrokerageInformationForm';

export default class AboutYourBrokerage extends React.Component {
    render = () => (
        <div className="realtor__signupform__step-container">
            <p className="realtor__signupform__header-create-account">
                Tell us about your brokerage.
            </p>
            <section className="section personal">
                <RealtorProfileBrokerageInformationForm
                    stateAbbreviation={this.props.stateAbbreviation}
                    values={this.props.values}
                    setFieldTouched={this.props.setFieldTouched}
                    setFieldValue={this.props.setFieldValue}
                    errors={this.props.errors}
                    touched={this.props.touched}
                />
            </section>
        </div>
    );
}
