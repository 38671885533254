import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import WizardStepsContainer from "./SignUpSteps/WizardStepsContainer";
import { Redirect } from "react-router-dom";

class EmdSignUpWizardOrProfileIfCompleted extends React.PureComponent {
    render = () =>
        this.props.hasCompletedProspectSignUp ? (
            <Redirect to="/profile" />
        ) : (
            <WizardStepsContainer />
        );
}

export default connect(state => ({
    hasCompletedProspectSignUp: get(state.user, "profile.ssn")
}))(EmdSignUpWizardOrProfileIfCompleted);
