import React from "react";
import CustomRealtorServingInField from "../../CustomRealtorServingInField/CustomRealtorServingInField";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";

import "./places_serving_in.scss";

const buildTooltip = () => {
    const popoverText = `Our algorithm has been built to find you 
                         matches based on specific cities or locales, 
                         not counties or zip codes.`;
    const content = <i className="fa fa-info-circle info-circle" />;

    return (
        <CustomTooltip
            popoverText={popoverText}
            content={content}
            type="black"
        ></CustomTooltip>
    );
};

const PlacesServingIn = props => {
    return (
        <div className="places__serving__in__container">
            <div className="level__one__text">What cities do you cover?</div>
            <div className="level__two__text">Please select up to 5 cities</div>
            <CustomRealtorServingInField
                onEdit={props.onEdit}
                placeholder="Search"
                value={props.value}
            />
            <div className="level__three__text">
                <div>
                    Please enter cities or locales only. Do not enter counties
                    or zip codes.
                </div>
                {buildTooltip()}
            </div>
        </div>
    );
};

export default PlacesServingIn;
