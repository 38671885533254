import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import './progress-item.css';

export default class ProgressItem extends React.PureComponent {
    static propTypes = {
        label: PropTypes.any,
        isCurrentStep: PropTypes.bool,
        isCompleted: PropTypes.bool,
        isSkipped: PropTypes.bool,
        onClick: PropTypes.func
    };

    buildSkippedIcon = () => <i className="fas fa-times wizard-progress-header-item__icon"/>;

    buildCompletedIcon = () => <i className="fas fa-check wizard-progress-header-item__icon"/>;

    buildLabel = () => {
        const { label } = this.props;

        if (isFunction(label)) {
            return label();
        }

        return label;
    };

    /**
     * @returns {boolean}
     */
    isCurrentStep = () => this.props.isCurrentStep;

    /**
     * @returns {boolean}
     */
    isCompleted = () => (
        !this.isSkipped() &&
        !this.isCurrentStep() &&
        this.props.isCompleted
    );

    /**
     * @returns {boolean}
     */
    isSkipped = () => !this.isCurrentStep() && this.props.isSkipped;

    buildLabelCss = () => ([
        'wizard-progress-header-item__label',
        this.isCurrentStep() && 'current-step',
        this.isCompleted() && 'completed',
        this.isSkipped() && 'skipped'
    ].filter(Boolean).join(' '));

    buildBulletCss = () => ([
        'wizard-progress-header-item__bullet',
        this.isCurrentStep() && 'current-step',
        this.isCompleted() && 'completed',
        this.isSkipped() && 'skipped'
    ].filter(Boolean).join(' '));

    render = () => (
        <button
            type="button"
            onClick={this.props.onClick}
            className="btn btn-link wizard-progress-header-item"
        >
            <div className={this.buildLabelCss()}>
                {this.buildLabel()}
            </div>
            <div className={this.buildBulletCss()}>
                {this.isCompleted() && this.buildCompletedIcon()}
                {this.isSkipped() && this.buildSkippedIcon()}
            </div>
        </button>
    );
}
