import React from 'react';
import PropTypes from 'prop-types';

export default class RealtorAvatar extends React.Component {
    buildAvatar = () => {
        const {
            picture,
            firstName,
            lastName,
        } = this.props;
        const fullName = `${firstName} ${lastName}`;
        return (
            <div>
                <img
                    src={picture}
                    alt={fullName}
                    className="realtor-navbar-avatar__picture is-mobile"
                />
            </div>
        );
    };

    render = () => {
        return (
            <div className="realtor-navbar-avatar">
                {this.buildAvatar()}
            </div>
        );
    }
}

RealtorAvatar.propTypes = {
    picture: PropTypes.string,
};
