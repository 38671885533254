import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { checkPolicy, PolicyContextConsumer } from './PolicyContext';

const PrivateRoute = connect(
    state => ({
        isAuth: state.user.authorization,
        profile: state.user.profile,
    })
)(
    ({ component: Component, isAuth, profile, ...routeProps }) => {
        return (
            <PolicyContextConsumer>
                { Policy => {
                    return checkPolicy(Policy, profile) || <Route
                        {...routeProps}
                        render={props =>
                            isAuth
                                ? <Component {...props} />
                                : <Redirect to={{pathname: '/signin', state: {from: props.location}}}/>
                        }
                    />
                }}
            </PolicyContextConsumer>
        );
    }
);

export default PrivateRoute;
