import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { getDocument } from '../../util/service_api';
import {
    ButtonToolbar,
    MenuItem,
    Dropdown,
    Glyphicon,
} from 'react-bootstrap';

import './realtornavbaravatar.css';
class RealtorNavbarAvatarComponent extends React.Component {
    getSignOutLink = () => {
        const {
            isImpersonateUser,
        } = this.props;

        return (
            !isImpersonateUser ? (
                <Link
                    className="realtor-navbar-avatar__link"
                    to="/signout"
                >
                    Sign Out
                </Link>
            ) : null
        );
    };

    buildAvatar = () => {
        const {
            picture,
            firstName,
            lastName,
        } = this.props;
        const fullName = `${firstName} ${lastName}`;
        return (
            <div>
                <img
                    src={picture}
                    alt={fullName}
                    className="realtor-navbar-avatar__picture is-mobile"
                />
            </div>
        );
    };

    buildRealtorInformation = () => {
        const {
            firstName,
            lastName,
            jobName,
            jobLocation,
        } = this.props;
        const fullName = `${firstName} ${lastName}`;
        return (
            <div className="realtor-navbar-avatar__name-wraper is-mobile">
                <p className="realtor-navbar-avatar__name">
                    <strong>{fullName}</strong>
                    {' '}
                    -
                    {' '}
                    {jobName}
                </p>
                <p className="realtor-navbar-avatar__info">
                    at
                    {' '}
                    {jobLocation}
                </p>
            </div>
        );
    }

    onDownload = () => {
        const { user } = this.props;
        const idNum = get(user.profile, 'documents[0].id');
        const fileName = get(user.profile, 'documents[0].filename');
        this.props.onDownload(idNum, fileName);
    };

    render = () => {
        const {
            isImpersonateUser,
        } = this.props;
        const divider = !isImpersonateUser ? <MenuItem divider /> : null;

        return (
            <div className="realtor-navbar-avatar">
                {this.buildAvatar()}
                {this.buildRealtorInformation()}
                <div className="realtor-navbar-avatar__options">
                    <ButtonToolbar className="toolbar-dropdown is-mobile">
                        <Dropdown id="avatarOptionDropdown" pullRight>
                            <Dropdown.Toggle noCaret className="button-dropdown">
                                <Glyphicon glyph="triangle-top" className="arrow-custom" />
                                <Glyphicon glyph="triangle-bottom" className="arrow-custom" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-custom">
                                <MenuItem className="dropdown-item-custom">
                                    <Link
                                        className="realtor-navbar-avatar__link"
                                        to="/realtor/active-matches"
                                    >
                                        My Clients
                                    </Link>
                                </MenuItem>
                                <MenuItem className="dropdown-item-custom">
                                    <Link
                                        className="realtor-navbar-avatar__link"
                                        onClick={this.onDownload}
                                    >
                                        Partner Agent Agreement
                                    </Link>
                                </MenuItem>
                                <MenuItem className="dropdown-item-custom">
                                    <Link
                                        className="realtor-navbar-avatar__link"
                                        to="/realtor/profile/edit"
                                    >
                                        Edit My Profile
                                    </Link>
                                </MenuItem>
                                <MenuItem className="dropdown-item-custom">
                                    <Link
                                        className="realtor-navbar-avatar__link"
                                        to="/realtor/profile/preview"
                                    >
                                        My Public Profile
                                    </Link>
                                </MenuItem>
                                {divider}
                                <MenuItem className="dropdown-item-custom dropdown-item-custom-sign-out">
                                    {this.getSignOutLink()}
                                </MenuItem>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ButtonToolbar>
                </div>
            </div>
        );
    }
}

const RealtorNavbarAvatar = connect(
    (state) => ({
        user: state.user,
    }),
)(RealtorNavbarAvatarComponent);

RealtorNavbarAvatar.defaultProps = {
    onDownload: getDocument,
};

RealtorNavbarAvatar.propTypes = {
    picture: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    jobName: PropTypes.string,
    jobLocation: PropTypes.string,
    isImpersonateUser: PropTypes.bool,
    onDownload: PropTypes.func,
};

export default RealtorNavbarAvatar;