import React, { Component } from "react";
import get from "lodash/get";
import zip from "lodash/zip";
import isFunction from "lodash/isFunction";
import { connect } from "react-redux";
import ValueGraph from "../ValueGraph/ValueGraph";
import PropertyProfileCard from "../PropertyProfileCard/PropertyProfileCard";
import PriceTrendGraphGraph from "../PriceTrendGraph/PriceTrendGraph";
import SalesAndForeclosuresGraph from "../SalesAndForeclosuresGraph/SalesAndForeclosuresGraph";
import PropertyDetails from "../PropertyDetails/PropertyDetails";
import Comparables from "../Comparables/Comparables";
import Schools from "../Schools/Schools";
import { GlobalLoaderComponent } from "../GlobalLoader/GlobalLoader";

import { getQueryParam } from "../../shared/functions";
import { getPropertyProfile } from "../../reducers/PropertyProfile/PropertyProfileActions";

import "./property_profile.scss";

class PropertyProfile extends Component {
    componentDidMount = () => {
        const search = get(this.props.location, "search");
        const qsStreet = getQueryParam("street", search);
        const qsZipCode = getQueryParam("zip", search);
        const qsCity = getQueryParam("city", search);
        const qsState = getQueryParam("state", search);

        if (!isFunction(this.props.getPropertyProfile)) return;

        this.props.getPropertyProfile({
            streetNumberName: qsStreet,
            zipcode: qsZipCode,
            city: qsCity,
            state: qsState
        });
    };

    render = () => {
        const { isFetchingPropertyProfile } = this.props;

        const streetAddress = get(
            this.props.propertyProfile,
            "Data.SubjectProperty.SitusAddress.StreetAddress"
        );
        const city = get(
            this.props.propertyProfile,
            "Data.SubjectProperty.SitusAddress.City"
        );
        const state = get(
            this.props.propertyProfile,
            "Data.SubjectProperty.SitusAddress.State"
        );
        const zipCode = get(
            this.props.propertyProfile,
            "Data.SubjectProperty.SitusAddress.Zip9"
        );
        const AVMValue = get(
            this.props.propertyProfile,
            "Data.HomeTrendData.FiveYearsMedianTrendDetail.AVMValue"
        );
        const MonthYear = get(
            this.props.propertyProfile,
            "Data.HomeTrendData.FiveYearsMedianTrendDetail.MonthYear"
        );
        const value = get(
            this.props.propertyProfile,
            "Data.ValuationSummary.EstimatedValue"
        );
        const estimatedValueLow = get(
            this.props.propertyProfile,
            "Data.ValuationSummary.EstimatedValueLow"
        );
        const estimatedValueHigh = get(
            this.props.propertyProfile,
            "Data.ValuationSummary.EstimatedValueHigh"
        );
        const medianListing = get(
            this.props.propertyProfile,
            "Data.MarketTrendData.MedianTrendDetail.MedianListing"
        );
        const medianSale = get(
            this.props.propertyProfile,
            "Data.MarketTrendData.MedianTrendDetail.MedianSale"
        );
        const marketTrendDataMonthYear = get(
            this.props.propertyProfile,
            "Data.MarketTrendData.MedianTrendDetail.MonthYear"
        );
        const lat = get(
            this.props.propertyProfile,
            "Data.LocationInformation.Latitude"
        );
        const lng = get(
            this.props.propertyProfile,
            "Data.LocationInformation.Longitude"
        );
        const comparables = get(
            this.props.propertyProfile,
            "Data.ComparableProperties"
        );
        const schools = get(this.props.propertyProfile, "Data.GreatSchools");
        const listingStatus = get(
            this.props.propertyProfile,
            "Data.ListingPropertyDetail.ListingStatus"
        );

        const marketTrendPeriodMonthYear = get(
            this.props.propertyProfile,
            "Data.MarketTrendData.SalesAndForeclosure.Period"
        );
        const marketTrendSaleCount = get(
            this.props.propertyProfile,
            "Data.MarketTrendData.SalesAndForeclosure.SaleCount"
        );
        const marketTrendForeclosureCount = get(
            this.props.propertyProfile,
            "Data.MarketTrendData.SalesAndForeclosure.ForeclosureCount"
        );

        /* property details data */
        const beds = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.Bedrooms"
        );
        const baths = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.FullBath"
        );
        const rooms = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.TotalRooms"
        );
        const garage = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.GarageCapacity"
        );
        const livingArea = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.LivingArea"
        );
        const grossArea = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.GrossArea"
        );
        const pool = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.Pool"
        );
        const firePlace = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.FirePlaceIndicator"
        );
        const basement = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.BasementType"
        );
        const cooling = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.AirConditioning"
        );
        const heating = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.HeatType"
        );
        const yearBuilt = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.YearBuilt"
        );
        const numberOfStories = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.NumberOfStories"
        );
        const price = get(
            this.props.propertyProfile,
            "Data.LastMarketSaleInformation.PricePerSquareFoot"
        );
        const landUse = get(
            this.props.propertyProfile,
            "Data.SiteInformation.LandUse"
        );
        const zoning = get(
            this.props.propertyProfile,
            "Data.SiteInformation.Zoning"
        );
        const style = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.Style"
        );
        const exteriorWall = get(
            this.props.propertyProfile,
            "Data.PropertyCharacteristics.ExteriorWall"
        );

        /* last market sale */
        const seller = get(
            this.props.propertyProfile,
            "Data.LastMarketSaleInformation.SellerName"
        );
        const buyer = get(
            this.props.propertyProfile,
            "Data.LastMarketSaleInformation.BuyerName"
        );
        const saleDate = get(
            this.props.propertyProfile,
            "Data.LastMarketSaleInformation.SaleDate"
        );
        const recDate = get(
            this.props.propertyProfile,
            "Data.LastMarketSaleInformation.RecordingDate"
        );
        const salePrice = get(
            this.props.propertyProfile,
            "Data.LastMarketSaleInformation.SalePrice"
        );

        /* prior sale information*/
        const priorSeller = get(
            this.props.propertyProfile,
            "Data.PriorSaleInformation.PriorSellerName"
        );
        const priorBuyer = get(
            this.props.propertyProfile,
            "Data.PriorSaleInformation.PriorBuyerName"
        );
        const priorSaleDate = get(
            this.props.propertyProfile,
            "Data.PriorSaleInformation.PriorSaleDate"
        );
        const priorRecDate = get(
            this.props.propertyProfile,
            "Data.PriorSaleInformation.PriorRecordingDate"
        );
        const priorSalePrice = get(
            this.props.propertyProfile,
            "Data.PriorSaleInformation.PriorSalePrice"
        );

        return (
            <GlobalLoaderComponent isVisible={isFetchingPropertyProfile}>
                <div className="container">
                    <div className="value__graph__section">
                        <ValueGraph
                            address={{
                                streetAddress,
                                city,
                                state,
                                zip: zipCode
                            }}
                            value={{
                                sales: zip(MonthYear, AVMValue),
                                estimatedValueHigh,
                                estimatedValueLow
                            }}
                            saleDate={saleDate}
                            salePrice={salePrice}
                            lat={lat}
                            lng={lng}
                        />
                    </div>
                    <div className="property__details">
                        <PropertyDetails
                            data={{
                                beds,
                                baths,
                                rooms,
                                garage,
                                livingArea,
                                grossArea,
                                pool,
                                firePlace,
                                basement,
                                cooling,
                                heating,
                                yearBuilt,
                                numberOfStories,
                                price,
                                landUse,
                                zoning,
                                style,
                                exteriorWall,
                                listingStatus
                            }}
                        />
                    </div>
                    <div className="comparables__section">
                        <Comparables
                            lat={lat}
                            lng={lng}
                            comparables={comparables}
                            value={value}
                            streetAddress={streetAddress}
                            city={city}
                            state={state}
                            zip={zipCode}
                        />
                    </div>
                    <div className="land__prior__market__forsale__section">
                        <div className="details__card__one">
                            <PropertyProfileCard
                                title={"Last Market Sale"}
                                data={{
                                    seller,
                                    buyer,
                                    saleDate,
                                    recDate,
                                    salePrice
                                }}
                            />
                        </div>
                        <div className="details__card__two">
                            <PropertyProfileCard
                                title={"Prior Market Sale"}
                                data={{
                                    seller: priorSeller,
                                    buyer: priorBuyer,
                                    saleDate: priorSaleDate,
                                    recDate: priorRecDate,
                                    salePrice: priorSalePrice
                                }}
                            />
                        </div>
                    </div>
                    <div className="market_trends_section">
                        <div className="header">
                            <p className="header__text">{`Market Trends for ${city ||
                                ""}, ${state || ""} (${zipCode || ""})`}</p>
                        </div>
                        <div className="graph__header">
                            <div className="price__trend__graph__header">
                                <div>Price trend</div>
                            </div>
                            <div className="sales__and__foreclosures__header">
                                <div>Sales &amp; Foreclosures</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="price__trend__graph">
                                <PriceTrendGraphGraph
                                    values={{
                                        salePrice: zip(
                                            marketTrendDataMonthYear,
                                            medianSale
                                        ),
                                        listingPrice: zip(
                                            marketTrendDataMonthYear,
                                            medianListing
                                        )
                                    }}
                                />
                            </div>
                            <div className="sales__and__foreclosures_graph">
                                <SalesAndForeclosuresGraph
                                    marketTrendPeriodMonthYear={
                                        marketTrendPeriodMonthYear
                                    }
                                    marketTrendSaleCount={marketTrendSaleCount}
                                    marketTrendForeclosureCount={
                                        marketTrendForeclosureCount
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="schools__section">
                        <Schools
                            lat={lat}
                            lng={lng}
                            schools={schools}
                            value={value}
                            streetAddress={streetAddress}
                            city={city}
                            state={state}
                            zip={zipCode}
                        />
                    </div>
                </div>
            </GlobalLoaderComponent>
        );
    };
}

const mapStateToProps = state => ({
    isFetchingPropertyProfile: get(
        state,
        "propertyProfile.isFetchingPropertyProfile"
    ),
    propertyProfile: get(state, "propertyProfile.propertyProfile")
});

export default connect(mapStateToProps, { getPropertyProfile })(
    PropertyProfile
);
