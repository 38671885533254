import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import last from 'lodash/last';
import find from 'lodash/find';
import invoke from 'lodash/invoke';
import reject from 'lodash/reject';
import isFunction from 'lodash/isFunction';
import { components } from 'react-select';
import SimpleUrlAutoCompleteInput from '../SimpleUrlAutoCompleteInput/SimpleUrlAutoCompleteInput';

import './customrealtorautocompletefield.css';

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <i className="fa fa-search" />
    </components.DropdownIndicator>
);

const CheckboxOption = (props) => {
    const selectedOptions = invoke(props, 'getValue');
    const optionLabel = get(props, 'label');
    const isSelected = find(selectedOptions, ['label', optionLabel]) != null;

    return (
        <components.Option {...props}>
            <div className="realtor-auto-complete-field__option">
                { isSelected && <div className="realtor-auto-complete-field__checkbox selected"><i className="fa fa-check" /></div> }
                { !isSelected && <div className="realtor-auto-complete-field__checkbox" /> }
                {get(props, 'children')}
            </div>
        </components.Option>
    );
};

export default class CustomRealtorAutoCompleteField extends Component {
    static propTypes = {
        fieldProp: PropTypes.string.isRequired,
        labelProp: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        autoCompleteUri: PropTypes.string.isRequired,
        value: PropTypes.any,
        isMulti: PropTypes.bool,
        canEdit: PropTypes.bool,
        onEdit: PropTypes.func,
        allowRepeatedValues: PropTypes.bool,
        cacheOptions: PropTypes.bool,
        allowSelectingWithCheckbox: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            value: this.getOriginalValueAsLabeledOption(),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.value !== prevState.value) {
            this.onConfirmEdit();
        }
    }

    getOriginalValueAsLabeledOption = () => (this.props.isMulti
        ? this.valuesToLabeledOptions(this.props.value)
        : this.valueToLabeledOption(this.props.value));

    valuesToLabeledOptions = (values) => map(values, this.valueToLabeledOption);

    valueToLabeledOption = (value) => {
        const label = get(value, this.props.labelProp);
        return value && { value, label };
    };

    labeledOptionToValue = (labeledOption) => get(labeledOption, 'value');

    labeledOptionsToValues = (labeledOptions) => map(labeledOptions, this.labeledOptionToValue);

    onChangeField = (fieldNewValue, event) => {
        if (!this.props.allowSelectingWithCheckbox) {
            this.setState({ value: fieldNewValue });
            return;
        }

        const isRemoving = get(event, 'action') === 'remove-value';
        if (isRemoving) {
            this.setState({ value: fieldNewValue });
            return;
        }

        const prevValue = this.state.value;
        const newSelectedOption = last(fieldNewValue);
        const newOptionLabel = get(newSelectedOption, 'label');
        const isOptionAlreadySelected = find(prevValue, ['label', newOptionLabel]) != null;

        if (isOptionAlreadySelected) {
            const deselectOption = reject(prevValue, { label: newOptionLabel });
            this.setState({ value: deselectOption })
            return;
        }

        this.setState({ value: fieldNewValue });
    };

    buildEditableField = () => (
        <SimpleUrlAutoCompleteInput
            labelProp={this.props.labelProp}
            autoCompleteUri={this.props.autoCompleteUri}
            isMulti={this.props.isMulti}
            getOptionsMillisecondsDelay={300}
            value={this.state.value}
            onChange={this.onChangeField}
            placeholder={this.props.placeholder}
            components={{
                DropdownIndicator,
                Option: this.props.allowSelectingWithCheckbox ? CheckboxOption : components.Option
            }}
            isClearable={false}
            allowRepeatedValues={this.props.allowRepeatedValues}
            cacheOptions={this.props.cacheOptions}
            allowSelectingWithCheckbox={this.props.allowSelectingWithCheckbox}
            closeMenuOnSelect={true}
        />
    );

    onConfirmEdit = () => {
        const { isMulti } = this.props;
        const { value } = this.state;
        const fieldNewValue = isMulti
            ? this.labeledOptionsToValues(value)
            : this.labeledOptionToValue(value);
        this.props.onEdit(fieldNewValue)
    };

    onSuccessfullyEdit = (fieldNewValue) => {
        this.setState({ isEditing: false });

        if (isFunction(this.props.onEdit)) {
            this.props.onEdit(fieldNewValue);
        }
    };

    render = () => {
        return (
            <div className="realtor-auto-complete-field">
                {this.buildEditableField()}
            </div>
        );
    };
}
