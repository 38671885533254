import React from 'react';
import mailbox from '../../../../styles/assets/Inbox-Icon.svg';

import './index.css';

const RealtorSignUpSuccess = () => (
    <div className="realtor__signup">
        <div className="realtor__signup__title">
            Check your inbox
        </div>
        <div className="realtor__signup__fieldset">
            <div className="realtor__signup__field__row">
                <div className="realtor__signup__field__label align-center is-big" />
                We just sent you an email to complete setting up your profile.
                Don&#39;t see the email? Please be sure to check your spam folder.
                <br />
                <br />
                If you still don&#39;t see the email, please contact us at
                &nbsp;
                <a href="mailto:hello@lemonbrew.com">hello@lemonbrew.com</a>
                {' '}
                and we&#39;ll get back to you.
                <br />
                <br />
                <img src={mailbox} alt="mailbox" />
            </div>
        </div>
    </div>
);

export default RealtorSignUpSuccess;
