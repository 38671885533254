import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import RealtorActiveMatchItem from '../RealtorActiveMatchItem/RealtorActiveMatchItem';
import RealtorActiveSellerMatchItem from '../RealtorActiveSellerMatchItem/RealtorActiveSellerMatchItem';

export default class RealtorProfileActiveAllMatchesTab extends React.PureComponent {
    buildBuyerActiveMatch = (activeMatch) => (
        <RealtorActiveMatchItem
            key={get(activeMatch, 'buyer.userInfo.id')}
            picture={invoke(this.props.buyer, 'getMatchPictureUrl', activeMatch)}
            name={invoke(this.props.buyer, 'buildMatchFullName', activeMatch)}
            location={invoke(this.props.buyer, 'buildLocation', activeMatch)}
            budget={invoke(this.props.buyer, 'buildMatchBudget', activeMatch)}
            maxBudget={invoke(this.props.buyer, 'buildMatchMaxBudget', activeMatch)}
            rebate={invoke(this.props.buyer, 'getMatchRebatePercentage', activeMatch)}
            brewScore={invoke(this.props.buyer, 'getMatchBrewScore', activeMatch)}
            status={invoke(this.props.buyer, 'getMatchStatus', activeMatch)}
            viewProfileUrl={invoke(this.props.buyer, 'buildViewProfileForMatch', activeMatch)}
        />
    );

    buildSellerActiveMatch = (activeMatch) => (
        <RealtorActiveSellerMatchItem
            key={get(activeMatch, 'seller.userInfo.id')}
            picture={invoke(this.props.seller, 'getMatchPictureUrl', activeMatch)}
            name={invoke(this.props.seller, 'buildMatchFullName', activeMatch)}
            location={invoke(this.props.seller, 'buildLocation', activeMatch)}
            budget={invoke(this.props.seller, 'buildMatchBudget', activeMatch)}
            timeFrame={get(activeMatch, 'seller.activeProperty.sellingTimeline')}
            maxBudget={invoke(this.props.seller, 'buildMatchMaxBudget', activeMatch)}
            status={invoke(this.props.seller, 'getMatchStatus', activeMatch)}
            viewProfileUrl={invoke(this.props.seller, 'buildViewProfileForMatch', activeMatch)}
        />
    );

    buildBuyers = () => map(this.props.buyer.buyersList, this.buildBuyerActiveMatch);

    buildSellers = () => map(this.props.seller.sellersList, this.buildSellerActiveMatch);
    
    render = () => (
        <div className="realtor-profile-active-matches-tab">
            {this.buildBuyers()}
            {this.buildSellers()}
        </div>
    );
}