import React from 'react';
import { Field } from 'formik';
import BaseWizardStep from '../BaseWizardStep';
import {
    PhoneField,
} from '../../../Form';

const MAX_LENGTH_FIRST_NAME = '50';
const MAX_LENGTH_LAST_NAME = '50';

class StepBrokerInformation extends BaseWizardStep {
    buildFirstNameField = () => {
        const fieldName = 'firstName';
        return (
            <div className="add-new-license__field buyer__signup__field width-50">
                <div className="add-new-license__label-field">
                    First Name
                </div>
                <Field
                    type="text"
                    name={fieldName}
                    maxLength={MAX_LENGTH_FIRST_NAME}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildLastNameField = () => {
        const fieldName = 'lastName';
        return (
            <div className="add-new-license__field buyer__signup__field width-50">
                <div className="add-new-license__label-field">
                    Last Name
                </div>
                <Field
                    type="text"
                    name={fieldName}
                    className="name"
                    maxLength={MAX_LENGTH_LAST_NAME}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildEmailField = () => {
        const fieldName = 'email';
        return (
            <div className="add-new-license__field buyer__signup__field width-50">
                <div className="add-new-license__label-field">
                    Email
                </div>
                <Field
                    name={fieldName}
                    component="input"
                    type="email"
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildPhoneField = () => {
        const fieldName = 'phone';
        return (
            <div className="add-new-license__field buyer__signup__field width-50">
                <div className="add-new-license__label-field">
                    Phone
                </div>
                <Field
                    type="text"
                    name={fieldName}
                    component={PhoneField}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildLicenseId = () => {
        const fieldName = 'licenseId';
        return (
            <div className="add-new-license__field buyer__signup__field width-50">
                <div className="add-new-license__label-field">
                    License ID
                </div>
                <Field
                    type="text"
                    name={fieldName}
                />
                { this.renderFieldError(fieldName) }
            </div>
        );
    };

    buildTitle = () => (
        <div className="add-new-license__title buyer__signup__step">
            <div className="add-new-license__title buyer__signup__question">
                Broker Information
            </div>
        </div>
    );

    isStepValid = () => this.areFieldsValid([
        'firstName',
        'lastName',
        'email',
        'phone',
        'licenseId',
    ]);

    render() {
        const isStepFulfilled = this.isStepValid();
        return (
            <>
                <div className="buyer__signupform">
                    <div className="buyer__signupform__step-container">
                        <>
                            {this.buildTitle()}
                            <div className="buyer__signup__fieldset">
                                <div className="buyer__signup__field__row">
                                    {this.buildFirstNameField()}
                                    {this.buildLastNameField()}
                                </div>
                                <div className="buyer__signup__field__row">
                                    {this.buildPhoneField()}
                                    {this.buildEmailField()}
                                </div>
                                <div className="buyer__signup__field__row">
                                    {this.buildLicenseId()}
                                    <div className="add-new-license__field buyer__signup__field width-50"></div>
                                </div>
                            </div>
                            { this.renderStepButtons(isStepFulfilled) }
                        </>
                    </div>
                </div>
            </>
        );
    }
}

export default StepBrokerInformation;
