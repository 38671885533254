import get from 'lodash/get';
import React from 'react';
import trim from 'lodash/trim';
import map from 'lodash/map';
import find from 'lodash/find';
import { ProfilePicture } from '../../ProfilePicture/ProfilePicture';
import { NumberFormat } from '../../NumberFormat/NumberFormat';
import BaseWizardStep from './BaseWizardStep';
import EarnestMoneyDepositAccountCard from '../../EarnestMoneyDepositAccountCard/EarnestMoneyDepositAccountCard';

class StepSummary extends BaseWizardStep {
    buildPicture = () => {
        const { userProfile } = this.props;
        const picture = trim(get(userProfile, 'publicProfile.userInfo.profilePicUrl'));
        return (
            <div className="step-deposit-account__profile-picture-container">
                <ProfilePicture
                    className="pulled-up"
                    picture={picture}
                />
            </div>
        );
    };

    buildAchs = () => {
        const { mapValuesToPayloadAchs } = this.props;
        const achs = mapValuesToPayloadAchs(this.props.values);

        return map(achs, (funding) => (
            <div key={get(funding, 'bankAccount.id')} className="step-deposit-account__account-card">
                <EarnestMoneyDepositAccountCard
                    account={{
                        name: get(funding, 'bankAccount.institution.name'),
                        icon: get(funding, 'bankAccount.institution.logoUrl'),
                        id: get(funding, 'bankAccount.id'),
                        number: get(funding, 'bankAccount.maskedBankAccountNumber'),
                        balance: {
                            amount: get(funding, 'bankAccount.balance'),
                            currency: get(funding, 'bankAccount.currency'),
                        },
                        type: get(funding, 'bankAccount.type.name'),
                    }}
                    amount={get(funding, 'amount')}
                />
            </div>
        ));
    }

    findType = () => {
        const { values, earnestMoneyDepositTypes } = this.props;
        const type = find(earnestMoneyDepositTypes,
            (item) => get(item, 'id') === get(values, 'typeOfTransfer'))
        return get(type, 'displayName');
    }

    render() {
        const { values, fetchedAchs } = this.props;
        return (
            <>
                <div className="profile__container">
                    <div className="profile__panel">
                        {this.buildPicture()}
                        <div className="step-deposit-account__question">
                            Your Transfer Details
                        </div>
                        <div className="step-deposit-account__content">
                            <div className="step-deposit-account__content-summary">
                                <div className="step-deposit-account__green-background">
                                    <div className="step-deposit-account__address">
                                        {get(values, 'subjectPropertyAddress.address')}
                                    </div>
                                    <div className="step-deposit-account__title-summary">
                                        {this.findType()}
                                    </div>
                                    <div className="step-deposit-account__amount">
                                        <NumberFormat
                                            format="currency"
                                            decimals={2}
                                            number={get(values, 'earnestMoneyDepositAmount')}
                                        />
                                    </div>
                                    <div className="step-deposit-account__title-summary">
                                        Memo:
                                    </div>
                                    <div className="step-deposit-account__memo-summary">
                                        {get(values, 'memo')}
                                    </div>
                                </div>
                            </div>
                            <div className="step-deposit-account__transfer-account">
                                { this.buildAchs() }
                            </div>
                        </div>
                        { this.renderStepButtons({
                            canProceed: fetchedAchs,
                            hasAddress: true,
                        })}
                    </div>
                </div>
            </>
        );
    }
}

export default StepSummary;
