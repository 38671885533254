import singleFamily from "../styles/assets/house_nosign_color.svg";
import multifamily from "../styles/assets/multifamily.svg";
import townhome from "../styles/assets/condo_color.svg";
import livingRent from "../styles/assets/house_with_sign_color.svg";
import livingShare from "../styles/assets/friends_color.svg";
import livingOwn from "../styles/assets/house_with_sign_color.svg";

export const homeTypes = [
    {
        value: "single_family_home",
        label: "Single Family Home",
        image: singleFamily
    },
    { value: "attached", label: "Condo / Co-op / Townhome", image: townhome },
    { value: "multifamily", label: "Multi-family", image: multifamily }
];

export const martialStatus = [
    { value: "married", label: "yes" },
    { value: "single", label: "no" },
    { value: "soon_to_be_married", label: "not yet, but soon" }
];

export const yesNoMaybe = [
    { value: "yes", label: "yes" },
    { value: "no", label: "no" },
    { value: "maybe", label: "not sure yet" }
];

export const yesNo = [
    {
        value: "yes",
        buttonProps: {
            icon: "fa fa-check",
            iconStyle: { color: "green" },
            children: "Yes",
            big: true,
            isFontBig: true
        }
    },
    {
        value: "no",
        buttonProps: {
            icon: "fa fa-times",
            iconStyle: { color: "red" },
            children: "No",
            big: true,
            isFontBig: true
        }
    }
];

export const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" }
];

export const budgetRanges = [
    {
        value: "[0,250000]",
        label: "$250K or Less",
        min: 0,
        max: 250000
    },
    {
        value: "[250000,400000]",
        label: "$250K - $400K",
        min: 250000,
        max: 400000
    },
    {
        value: "[400000,550000]",
        label: "$400K - $550K",
        min: 400000,
        max: 550000
    },
    {
        value: "[550000,700000]",
        label: "$550K - $700K",
        min: 550000,
        max: 700000
    },
    {
        value: "[700000,850000]",
        label: "$700K - $850K",
        min: 700000,
        max: 850000
    },
    {
        value: "[850000,1100000]",
        label: "$850K - $1.1M",
        min: 850000,
        max: 1100000
    },
    {
        value: "[1100000,1500000]",
        label: "$1.1M - $1.5M",
        min: 1100000,
        max: 1500000
    },
    {
        value: "[1500000,1500000]",
        label: "$1.5M +",
        min: 1500000
    }
];

export const purchaseTimelinesBase = [
    {
        value: "by_end_of_the_year",
        label: "By End of the Year",
        buttonProps: {
            children: "0 to 3 months",
            isMaxWidth: true
            // icon: "fa fa-check",
            // iconStyle: { color: "white" }
        }
    },
    {
        value: "early_next_year",
        label: "Early Next Year",
        buttonProps: {
            children: "Over the spring and summer",
            isMaxWidth: true
            // icon: "fa fa-check",
            // iconStyle: { color: "white" }
        }
    },
    {
        value: "over_the_spring_and_summer_of_next_year",
        label: "Over the Spring and Summer of Next Year",
        buttonProps: {
            children: "By the end of the year",
            isMaxWidth: true
            // icon: "fa fa-check",
            // iconStyle: { color: "white" }
        }
    }
];

/**
 * the next two are essentially the same for buyer or seller
 */
export const purchaseTimelines = [
    ...purchaseTimelinesBase,
    {
        value: "not_sure_yet_just_looking",
        label: "Not Sure Yet - Just Looking",
        buttonProps: {
            children: "Not sure yet - just looking",
            isMaxWidth: true
            // icon: "fa fa-check",
            // iconStyle: { color: "white" }
        }
    }
];

export const purchaseTimelinesSeller = [
    ...purchaseTimelinesBase,
    {
        value: "not_sure_yet_just_looking",
        label: "Not Sure Yet - Just Looking",
        buttonProps: {
            children: "Not sure yet - just looking",
            isMaxWidth: true
            // icon: "fa fa-check",
            // iconStyle: { color: "white" }
        }
    }
];

export const constructionStyles = [
    { value: "cape_code", label: "Cape Code" },
    { value: "art_deco", label: "Art Deco" },
    { value: "craftsman", label: "Craftsman" },
    { value: "contemporary", label: "Contemporary" },
    { value: "modern", label: "Modern" },
    { value: "ranch", label: "Ranch" },
    { value: "townhouse", label: "Townhouse" },
    { value: "cottage", label: "Cottage" },
    { value: "victorian", label: "Victorian" },
    { value: "farmhouse", label: "Farmhouse" },
    { value: "oriental", label: "Oriental" }
];

export const statusProspect = {
    draft: {
        className: "in-draft",
        statusName: "Draft"
    },
    in_progress: {
        className: "in-progress",
        statusName: "In progress"
    },
    complete: {
        className: "is-complete",
        statusName: "Complete"
    },
    verification_document_needed: {
        className: "verification",
        statusName: "Verification required"
    },
    discarded: {
        className: "discarded",
        statusName: "Discarded"
    },
    cancel: {
        className: "cancel",
        statusName: "Cancel"
    },
    fail: {
        className: "fail",
        statusName: "Fail"
    }
};

export const realtorWorkStatus = [
    { label: "Part Time Agent", value: "partTime" },
    { label: "Full Time Agent", value: "fullTime" }
];

export const firstTimeBuyerLivingSituation = [
    { label: "I am renting", value: "rent", image: livingRent },
    {
        label: "I am living with family or friends",
        value: "friends_family",
        image: livingShare
    }
];

export const livingSituation = [
    { label: "I own a home", value: "own", image: livingOwn },
    ...firstTimeBuyerLivingSituation
];

export const maxNumberOfCities = 5;

export const realtorMatchUnderContractStatus = 6;
export const realtorSellerMatchUnderContractStatus = 18;

export const contactTypeCalifornia = "California";

export const MIN_VALUE_AVG_SALE_PRICE = "1000";
export const MAX_VALUE_AVG_SALE_PRICE = "10000000";
export const MIN_VALUE_AVG_BEDROOMS = "1";
export const MAX_VALUE_AVG_BEDROOMS = "10";
export const MIN_VALUE_PROPERTIES_SOLD = "0";
export const MAX_VALUE_PROPERTIES_SOLD = "1000";

export const MIN_FINAL_PURCHASE_PRICE = 0;
export const MAX_FINAL_PURCHASE_PRICE = 10000000;

export const SELECT_ROLE_OPTION = [
    {
        id: "ROLE_REALTOR",
        role: "ROLE_BREWCREW_REALTOR",
        displayName: "Agent",
        displayNamePlural: "Agents"
    },
    {
        id: "ROLE_SELLER",
        role: "ROLE_BREWCREW_SELLER",
        displayName: "Seller",
        displayNamePlural: "Sellers"
    },
    {
        id: "ROLE_BUYER",
        role: "ROLE_BREWCREW_BUYER",
        displayName: "Buyer",
        displayNamePlural: "Buyers"
    },
    {
        id: "ROLE_PROSPECT",
        role: "ROLE_BREWCREW_PROSPECT",
        displayName: "Prospect",
        displayNamePlural: "Prospect"
    },
    {
        id: "ROLE_PROSPECT",
        role: "ROLE_BREWCREW_PROSPECT_READ_ONLY",
        displayName: "Prospect",
        displayNamePlural: "Prospect"
    }
];

export const SELECT_ROLE_ALL = "ROLE_BREWCREW";

export const SELECT_ROLE_REALTOR = "ROLE_BREWCREW_REALTOR";

export const SELECT_ROLE_BUYER = "ROLE_BREWCREW_BUYER";

export const MID_USER = "ROLE_BREWCREW_PROSPECT";

export const READ_ONLY_USER = "ROLE_BREWCREW_PROSPECT_READ_ONLY";

export const ROLE_BROKER = "ROLE_BROKER";

export const SELECT_SORT_BY_OPTION = {
    ROLE_REALTOR: [
        {
            field: "firstName",
            displayName: "Name: A -> Z",
            order: "asc"
        },
        {
            field: "firstName",
            displayName: "Name: Z -> A",
            order: "desc"
        },
        {
            field: "state",
            displayName: "State: A -> Z",
            order: "asc"
        },
        {
            field: "state",
            displayName: "State: Z -> A",
            order: "desc"
        },
        {
            field: "ts",
            displayName: "Date: Newest First",
            order: "desc"
        },
        {
            field: "ts",
            displayName: "Date: Oldest First",
            order: "asc"
        },
        {
            field: "averageSalePrice",
            displayName: "Price: Low To High",
            order: "asc"
        },
        {
            field: "averageSalePrice",
            displayName: "Price: High To Low",
            order: "desc"
        }
    ],
    ROLE_SELLER: [
        {
            field: "firstName",
            displayName: "Name: A -> Z",
            order: "asc"
        },
        {
            field: "firstName",
            displayName: "Name: Z -> A",
            order: "desc"
        },
        {
            field: "ts",
            displayName: "Date: Newest First",
            order: "desc"
        },
        {
            field: "ts",
            displayName: "Date: Oldest First",
            order: "asc"
        }
    ],
    ROLE_BUYER: [
        {
            field: "firstName",
            displayName: "Name: A -> Z",
            order: "asc"
        },
        {
            field: "firstName",
            displayName: "Name: Z -> A",
            order: "desc"
        },
        {
            field: "ts",
            displayName: "Date: Newest First",
            order: "desc"
        },
        {
            field: "ts",
            displayName: "Date: Oldest First",
            order: "asc"
        }
    ],
    ROLE_PROSPECT: [
        {
            field: "firstName",
            displayName: "Name: A -> Z",
            order: "asc"
        },
        {
            field: "firstName",
            displayName: "Name: Z -> A",
            order: "desc"
        }
    ]
};

export const STATUS_REALTOR = [
    {
        id: "APPROVED",
        displayName: "Approved",
        color: "green"
    },
    {
        id: "SUSPEND",
        displayName: "Awaiting Approval",
        color: "red"
    },
    {
        id: "MATCHED",
        displayName: "Matched With Agent",
        color: "green"
    },
    {
        id: "NEEDAGENT",
        displayName: "Needs Agent",
        color: "red"
    }
];

export const STATUS_EMD = [
    {
        id: "draft",
        displayName: "Draft",
        color: "draft"
    },
    {
        id: "in_progress",
        displayName: "In Progress",
        color: "in-progress"
    },
    {
        id: "complete",
        displayName: "Completed",
        color: "complete"
    },
    {
        id: "verification_document_needed",
        displayName: "Id Verification Required",
        color: "needs-validation"
    },
    {
        id: "discarded",
        displayName: "Discarded",
        color: "discarded"
    },
    {
        id: "cancel",
        displayName: "Cancelled",
        color: "cancel"
    },
    {
        id: "fail",
        displayName: "Failed",
        color: "fail"
    }
];

export const REGEXP_AT_LEAST_9_CHAR = /^.{9,}$/;

export const REGEXP_ONLY_9_CHAR = /^.{9}$/;

export const REGEXP_10_CHAR = /^.{10}$/;

export const BREWCREW_APPROVE_OPTIONS = [
    {
        id: "all",
        displayName: "ALL",
        approved: undefined
    },
    {
        id: "approved",
        displayName: "APPROVED",
        approved: true
    },
    {
        id: "notApproved",
        displayName: "AWAITING APPROVAL",
        approved: false
    }
];

export const REGEXP_UPPERCASE_NUMBER_SPECIAL_CHAR = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s])(?!.*[<>])/;

export const REGEXP_VALIDATION_EMAIL = /^\w+([.-\\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const ERROR_MESSAGE_ADMIN_MODAL = {
    0: e => `${e} is Required`,
    1: "Password must be at least 9 characters",
    2: "Must include uppercase, a number and a special character (except < or >)",
    3: "Passwords don't match"
};

export const ERROR_MESSAGE_EDIT_PROSPECT = {
    0: e => `${e} is Required`,
    1: "Social security number must have 9 digits",
    2: "Please enter a valid address",
    3: "Must be at least 18 years old"
};

export const EMD_WIRE_INSTRUCTION_MODAL = {
    required: e => `${e} is Required`,
    email: "Please enter a valid email address",
    address: "Please enter a valid address"
};

export const ERROR_MESSAGE_EMD_CONGRATULATION_MODAL = {
    email: "Please enter a valid email address"
};

export const BUYER_INCREASE_REBATE_MODAL = {
    required: e => `${e} is Required`,
    email: "Please enter a valid email address",
    phone: "Phone number must have 10 characters"
};

export const PREDEFINED_INMAN_WHEEL = {
    name: "Inman 2019",
    prizes: [
        {
            name: "Spindrift",
            description:
                "You Won A Year Supply Of SpinDrift Lemon Sparkling Water!",
            imageUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/spindrift.png",
            iconUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/spindrift.svg",
            percentage: 50,
            active: true,
            quantity: 2
        },
        {
            name: "Realtor of the Month",
            description: "You Will Be Featured On LemonBrew.com For 1 Month!",
            imageUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/profile-of-the-month.png",
            iconUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/vip-user.svg",
            percentage: 70,
            active: true,
            quantity: 12
        },
        {
            name: "Amazon Gift Card",
            description: "You Won A $50 Amazon Gift Card!",
            imageUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/amazon-card-50.png",
            iconUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/amazon-card.svg",
            percentage: 50,
            active: true,
            quantity: 2
        },
        {
            name: "Google Nest",
            description: "You Won A Google Nest!",
            imageUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/nest.png",
            iconUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/google-nest.svg",
            percentage: 33,
            active: true,
            quantity: 1
        },
        {
            name: "Apple AirPods",
            description: "You Won Apple AirPods!",
            imageUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/airpods.png",
            iconUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/airpods.svg",
            percentage: 50,
            active: true,
            quantity: 2
        },
        {
            name: "Away Luggage",
            description: "You Won An Away Carry-On!",
            imageUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/away.png",
            iconUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/carry-on.svg",
            percentage: 33,
            active: true,
            quantity: 1
        },
        {
            name: "LemonBrew Wine Opener",
            description: "You Won A LemonBrew Wine Opener!",
            imageUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/wine-opener.png",
            iconUrl:
                "https://lb-public-images.s3.amazonaws.com/brew-wheel/wine-opener-icon.png",
            percentage: 100,
            active: true,
            quantity: 100
        }
    ],
    roles: [
        {
            name: "ROLE_REALTOR"
        }
    ],
    startDate: "2019-07-01T00:00:00",
    endDate: "2019-07-31T00:00:00"
};
