import { connect } from "react-redux";
import map from "lodash/map";
import filter from "lodash/filter";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import sum from "lodash/sum";
import toNumber from "lodash/toNumber";
import toString from "lodash/toString";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { withFormik } from "formik";
import {
    object as YupObject,
    number as YupNumber,
    array as YupArray,
    mixed as YupMixed,
    string as YupString
} from "yup";
import { updateProspect } from "../../../util/service_api";
import {
    adaptStreetAddressFromService,
    adaptStreetAddress,
    EmptyAddress
} from "../../SignUp/BuyerSignUp/Shared";
import { YupStreetAddress } from "../../../shared/validations";
import {
    fetchVoaProspectUrl,
    updateDepositProspect,
    newDepositProspect,
    earnestMoneyDepositFinish
} from "../../../reducers/prospect/prospectActions";
import WizardStepsForm from "./WizardStepsForm";
import { loadUserProfile } from "../../../reducers/user/UserActions";

const mapValuesToPayload = (
    values,
    earnestMoneyDepositTypes,
    transactionContactTypes
) => {
    const {
        memo,
        typeOfTransfer,
        subjectPropertyAddress,
        earnestMoneyDepositAmount,
        firstNameSpouse,
        lastNameSpouse,
        firstNameSeller,
        lastNameSeller,
        anticipatedClosingDate
    } = values;

    const payload = {
        totalAmount: Number(earnestMoneyDepositAmount),
        subjectPropertyAddress: adaptStreetAddress(subjectPropertyAddress),
        type: find(
            earnestMoneyDepositTypes,
            item => get(item, "id") === typeOfTransfer
        ),
        memo: memo
    };

    if (typeOfTransfer === 1) {
        const contactSpouse = find(
            transactionContactTypes,
            item => get(item, "name") === "earnest_money_deposit_spouse"
        );
        const contactSeller = find(
            transactionContactTypes,
            item => get(item, "name") === "earnest_money_deposit_seller"
        );

        payload.transactionContacts = [];

        if (!isEmpty(firstNameSpouse) || !isEmpty(lastNameSpouse)) {
            payload.transactionContacts.push({
                contact: {
                    firstName: firstNameSpouse,
                    lastName: lastNameSpouse
                },
                transactionContactType: contactSpouse
            });
        }

        if (!isEmpty(firstNameSeller) || !isEmpty(lastNameSeller)) {
            payload.transactionContacts.push({
                contact: {
                    firstName: firstNameSeller,
                    lastName: lastNameSeller
                },
                transactionContactType: contactSeller
            });
        }

        if (!isNil(anticipatedClosingDate)) {
            payload.anticipatedClosingDate = anticipatedClosingDate;
        }
    }

    return payload;
};

const mapValuesToPayloadEdit = (
    values,
    earnestMoneyDepositTypes,
    transactionContactTypes
) => {
    const {
        memo,
        typeOfTransfer,
        subjectPropertyAddress,
        earnestMoneyDepositAmount,
        firstNameSpouse,
        lastNameSpouse,
        firstNameSeller,
        lastNameSeller,
        anticipatedClosingDate
    } = values;

    const payload = {
        totalAmount: Number(earnestMoneyDepositAmount),
        subjectPropertyAddress: {
            streetNumberName: get(subjectPropertyAddress, "streetNumberName"),
            latitude: get(subjectPropertyAddress, "latitude"),
            longitude: get(subjectPropertyAddress, "longitude"),
            city: get(subjectPropertyAddress, "city"),
            neighborhood: get(subjectPropertyAddress, "neighborhood"),
            state: get(subjectPropertyAddress, "state.abbreviation"),
            apartmentSuiteNumber: get(
                subjectPropertyAddress,
                "apartmentSuiteNumber"
            ),
            zipcode: get(subjectPropertyAddress, "zipcode")
        },
        type: find(
            earnestMoneyDepositTypes,
            item => get(item, "id") === typeOfTransfer
        ),
        memo: memo
    };

    if (typeOfTransfer === 1) {
        const contactSpouse = find(
            transactionContactTypes,
            item => get(item, "name") === "earnest_money_deposit_spouse"
        );
        const contactSeller = find(
            transactionContactTypes,
            item => get(item, "name") === "earnest_money_deposit_seller"
        );

        payload.transactionContacts = [];

        if (!isEmpty(firstNameSpouse) || !isEmpty(lastNameSpouse)) {
            payload.transactionContacts.push({
                contact: {
                    firstName: firstNameSpouse,
                    lastName: lastNameSpouse
                },
                transactionContactType: contactSpouse
            });
        }

        if (!isEmpty(firstNameSeller) || !isEmpty(lastNameSeller)) {
            payload.transactionContacts.push({
                contact: {
                    firstName: firstNameSeller,
                    lastName: lastNameSeller
                },
                transactionContactType: contactSeller
            });
        }

        if (!isNil(anticipatedClosingDate)) {
            payload.anticipatedClosingDate = anticipatedClosingDate;
        }
    }

    return payload;
};

const mapValuesToPayloadAchs = values => {
    const { achs } = values;
    return filter(achs, ach => ach && ach.amount > 0);
};

const getAddress = editEMD => {
    const streetNumberName = get(
        editEMD,
        "subjectPropertyAddress.streetNumberName"
    );
    const state = get(editEMD, "subjectPropertyAddress.state.name");
    return `${streetNumberName}, ${state}`;
};

const renameValues = arr =>
    map(arr, v => ({ ...v, bankAccount: get(v, "plaidBankAccount") }));

const WizardStepsContainer = withFormik({
    mapPropsToStatus: () => ({
        formState: "initial"
    }),

    mapPropsToValues: props => {
        if (props.isEdit) {
            return {
                ...props.editEMD,
                id: get(props.editEMD, "id"),
                subjectPropertyAddress: {
                    ...adaptStreetAddressFromService(
                        get(props.editEMD, "subjectPropertyAddress")
                    ),
                    address: getAddress(props.editEMD)
                },
                earnestMoneyDepositAmount: get(props.editEMD, "totalAmount"),
                memo: get(props.editEMD, "memo"),
                achs: renameValues(get(props.editEMD, "achs")),
                typeOfTransfer: get(props.editEMD, "type.id"),
                firstNameSpouse: get(props.editEMD, "spouse.contact.firstName"),
                lastNameSpouse: get(props.editEMD, "spouse.contact.lastName"),
                firstNameSeller: get(props.editEMD, "seller.contact.firstName"),
                lastNameSeller: get(props.editEMD, "seller.contact.lastName"),
                anticipatedClosingDate: get(
                    props.editEMD,
                    "anticipatedClosingDate"
                )
            };
        }

        return {
            id: null,
            subjectPropertyAddress: { ...EmptyAddress },
            earnestMoneyDepositAmount: "",
            achs: [],
            memo: "",
            typeOfTransfer: "",
            firstNameSpouse: "",
            lastNameSpouse: "",
            firstNameSeller: "",
            lastNameSeller: "",
            anticipatedClosingDate: undefined
        };
    },

    validationSchema: props =>
        YupObject().shape({
            typeOfTransfer: YupString()
                .oneOf(
                    map(props.earnestMoneyDepositTypes, type =>
                        toString(get(type, "id"))
                    )
                )
                .required(),

            subjectPropertyAddress: YupStreetAddress(),

            earnestMoneyDepositAmount: YupNumber()
                .min(
                    0.01,
                    "Requested transfer amount should be between $0.01 and $15,000.00"
                )
                .max(
                    15000,
                    "Requested transfer amount should be between $0.01 and $15,000.00"
                )
                .required("Earnest Money Deposit Amount is required"),

            achs: YupArray()
                .of(
                    YupObject().shape({
                        bankAccount: YupObject().shape({
                            balance: YupNumber(),
                            balanceDate: YupMixed(),
                            currency: YupMixed(),
                            id: YupNumber(),
                            institution: YupMixed(),
                            maskedBankAccountNumber: YupMixed(),
                            type: YupMixed()
                        }),
                        amount: YupString().test(
                            "account-balance",
                            "The amount cannot be greater than the balance",
                            function accountBalanceValidation(amount) {
                                const bankAccountBalance = get(
                                    this,
                                    "parent.bankAccount.balance"
                                );

                                const isBankAccountBalanceMissing =
                                    bankAccountBalance === undefined;
                                const isAmountMissing = amount === undefined;
                                const isAccountNotSelected =
                                    isBankAccountBalanceMissing &&
                                    isAmountMissing;

                                if (isAccountNotSelected) {
                                    const ignoreValidation = true;
                                    return ignoreValidation;
                                }

                                const amountAsNumber = Number(amount) || 0;
                                return bankAccountBalance >= amountAsNumber;
                            }
                        )
                    })
                )
                .required()
                .test(
                    "deposit-amount",
                    `The total amount you would like to transfer to LemonBrew Abstract doesn't equal the total amount from your bank account(s).`,
                    function depositAmountValidation(accounts) {
                        const isValidAccount = account =>
                            account && account.amount;
                        const validAccounts = filter(accounts, isValidAccount);
                        const amounts = map(validAccounts, "amount");
                        const validAmounts = map(amounts, toNumber);
                        const earnestMoneyDepositAmount = get(
                            this,
                            "parent.earnestMoneyDepositAmount"
                        );
                        return (
                            Math.abs(
                                sum(validAmounts) -
                                    Number(earnestMoneyDepositAmount)
                            ) <= 0.001
                        );
                    }
                )
        }),

    // Submission handler
    handleSubmit(values, { props, setStatus, setSubmitting, setError }) {
        const { depositData } = props;
        const depositId = get(depositData, "id");
        setSubmitting(true);
        setStatus({ formState: "submitting" });
        props
            .earnestMoneyDepositFinish(depositId)
            .then(() => {
                setStatus({ formState: "submitted" });
            })
            .catch(error => {
                setError({ error });
            })
            .finally(() => {
                props.loadUserProfile();
                setSubmitting(false);
            });
    }
})(WizardStepsForm);

let lastUpdate = {};
const notifyUpdate = payload => {
    // Perform update only when there is a change
    if (isEqual(lastUpdate, payload)) return;

    lastUpdate = payload;
    updateProspect({ ...payload });
};

const getEarnestMoneyDepositTypes = state =>
    get(state.configApp, "config.earnestMoneyDepositTypes");

const getTransactionContactTypes = state =>
    get(state.configApp, "config.transactionContactTypes.Other");

const mapStateToProps = state => ({
    notifyUpdate,
    mapValuesToPayload,
    mapValuesToPayloadAchs,
    mapValuesToPayloadEdit,
    voaUrl: state.prospect.voaUrl,
    consumerId: state.prospect.consumerId,
    depositData: state.prospect.depositData,
    userProfile: state.user.profile,
    fetchingAchs: state.prospect.fetchingAchs,
    fetchedAchs: state.prospect.fetchedAchs,
    errorAchs: state.prospect.errorAchs,
    errorAchsMessage: state.prospect.errorAchsMessage,
    earnestMoneyDepositTypes: getEarnestMoneyDepositTypes(state),
    transactionContactTypes: getTransactionContactTypes(state)
});

const mapDispatchToProps = dispatch => ({
    fetchVoaUrl: () => dispatch(fetchVoaProspectUrl()),
    loadUserProfile: () => dispatch(loadUserProfile()),
    updateDepositProspect: (data, id, dataAchs) =>
        dispatch(updateDepositProspect(data, id, dataAchs)),
    newDepositProspect: (data, dataAchs) =>
        dispatch(newDepositProspect(data, dataAchs)),
    earnestMoneyDepositFinish: earnestMoneyDepositId =>
        dispatch(earnestMoneyDepositFinish(earnestMoneyDepositId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardStepsContainer);
