import {
    number as YupNumber,
    object as YupObject,
    string as YupString,
    array as YupArray,
    addMethod as YupAddMethod
} from "yup";
import { differenceInYears } from "date-fns";
import get from "lodash/get";
import some from "lodash/some";
import trim from "lodash/trim";
import filter from "lodash/filter";
import { userExists } from "../util/service_api";

const usPhoneRegExp = /^\(\d{3}\)\s+\d{3}-\d{4}$/;
const twilioCodeRegExp = /^\d{6}$/;
const ssnRegExp = /^(?!666|000|9\d{2})\d{3}[- ]?(?!00)\d{2}[- ]?(?!0{4})\d{4}$/;
const ssnBlacklist = ["078051120", "219099999", "457555462"];
const regexpZipcodeOptional = /^(\d{5})?$/;

YupAddMethod(YupArray, "unique", function(message, mapper = a => a) {
    return this.test("unique", message, function(list) {
        return list.length === new Set(list.map(mapper)).size;
    });
});

export const YupCity = () =>
    YupString()
        .trim()
        .required()
        .min(3, "Location must contain City");

export const YupState = () =>
    YupString()
        .trim()
        .min(2, "Location must contain State");

export const YupStreetAddress = licensedStatesAbbr => {
    const components = {
        address: YupString()
            .trim()
            .required("Address is required"),
        apartmentSuiteNumber: YupString(),
        neighborhood: YupString().trim(),
        city: YupCity(),
        state: YupState(),
        lat: YupNumber(),
        lng: YupNumber(),
        zipcode: YupString()
            .trim()
            .test(
                "validate-location-zipcode",
                "Location must contain valid Zip",
                value => regexpZipcodeOptional.test(value)
            )
            .required("The Address must be a valid street address")
    };

    if (licensedStatesAbbr) {
        components.address = components.address.test(
            "validate-state",
            `Address must be in one of these states: ${licensedStatesAbbr.join(
                ", "
            )}`,
            function() {
                return licensedStatesAbbr.includes(this.parent.state);
            }
        );
        components.state = components.state.oneOf(
            licensedStatesAbbr,
            `Address must be in one of these states: ${licensedStatesAbbr.join(
                ", "
            )}`
        );
    }
    return YupObject(components).required(
        "The Address must be a valid street address"
    );
};

export const YupCityState = licensedStatesAbbr => {
    const components = {
        location: YupString()
            .trim()
            .required("City/neighborhood is required"),
        neighborhood: YupString().trim(),
        city: YupCity(),
        state: YupState(),
        lat: YupNumber(),
        lng: YupNumber()
    };

    if (licensedStatesAbbr) {
        components.location = components.location.test(
            "validate-state",
            `State must be one of these: ${licensedStatesAbbr.join(", ")}`,
            function() {
                return licensedStatesAbbr.includes(this.parent.state);
            }
        );
    }
    return YupObject(components).required(
        "The Address must be a valid street address"
    );
};

export const YupUsPhoneNumber = () =>
    YupString()
        .trim()
        .matches(usPhoneRegExp, "Phone number is not valid");

export const YupTwilioVerificationCode = () =>
    YupString()
        .trim()
        .matches(twilioCodeRegExp, "Verification code is a 6-digit number");

const WholeNumberRange = (min, max, message) =>
    YupNumber()
        .integer(message)
        .min(min, message)
        .max(max, message);

export const YupDayOfMonth = () =>
    WholeNumberRange(
        1,
        31,
        "Day of month must be a whole number between 1 and 31"
    );

export const YupMonth = () =>
    WholeNumberRange(1, 12, "Month must be a valid month number");

export const YupFullYear = () =>
    WholeNumberRange(1900, 2019, "Year must be a valid year number since 1900");

export const YupSsn = () =>
    YupString()
        .ensure()
        .test(
            "validate-ssn",
            "Must be a valid social security number",
            value =>
                !value ||
                (ssnRegExp.test(value) &&
                    !ssnBlacklist.includes(value.replace(/\D/g, "")))
        );

export const YupDobAdult = () =>
    YupObject({
        day: YupDayOfMonth().typeError(""),
        month: YupMonth().typeError(""),
        year: YupFullYear().typeError("")
    }).test(
        "validate-18y-old",
        "Must be at least 18 years old",
        ({ day, month, year }) =>
            !day ||
            !month ||
            !year ||
            differenceInYears(new Date(), new Date(year, month - 1, day)) >= 18
    );

export const YupDobAdultRequired = () =>
    YupObject({
        day: YupDayOfMonth()
            .typeError("* required")
            .required("* required"),
        month: YupMonth()
            .typeError("* required")
            .required("* required"),
        year: YupFullYear()
            .typeError("* required")
            .required("* required")
    }).test(
        "validate-18y-old-required",
        "Must be at least 18 years old",
        ({ day, month, year }) =>
            differenceInYears(new Date(), new Date(year, month - 1, day)) >= 18
    );

const memoizeEmailAsyncValidation = (() => {
    const cache = {};
    return (role, email) => {
        const key = `${role}.${email}`;
        if (!cache[key]) {
            cache[key] = userExists({ email, role });
        }
        return cache[key];
    };
})();

export const YupUniqueEmail = role =>
    YupString()
        .trim()
        .email("Invalid email address")
        .test(
            "unique-email",
            "This email address is already in use, please use a different email address",
            function(email) {
                if (
                    !(
                        email &&
                        email.length > 5 &&
                        YupString()
                            .email()
                            .isValidSync(email)
                    )
                )
                    return true;

                return memoizeEmailAsyncValidation(role, email)
                    .then(({ data }) =>
                        data === true ? this.createError() : true
                    )
                    .catch(() =>
                        this.createError({
                            message: `Couldn't verify the email uniqueness`
                        })
                    );
            }
        );

export const YupPassword = () => {
    const YupValidator = YupString()
        .ensure()
        .trim()
        .min(9)
        .matches(
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s])(?!.*[<>])/,
            "Must include uppercase, a number and a special character (except < or >)"
        );

    return YupValidator.test({
        name: "must-not-match-other",
        test: function(value) {
            const { meta: { mustNotMatch = {} } = {} } = this.schema.describe();

            if (
                mustNotMatch.path &&
                `${value}`.trim().toLowerCase() ===
                    `${this.parent[mustNotMatch.path]}`.trim().toLowerCase()
            )
                return this.createError({
                    message: `\${label} must not match ${mustNotMatch.label ||
                        mustNotMatch.path}`
                });

            return true;
        },
        message: ""
    });
};

export const YupUniqueAddress = errorMessage =>
    YupArray()
        .ensure()
        .test("unique-address", errorMessage, addresses => {
            const areEqualAddresses = (addressA, addressB) => {
                const cityA = trim(get(addressA, "city")).toLowerCase();
                const stateA = trim(get(addressA, "state")).toLowerCase();
                const neighborhoodA = trim(
                    get(addressA, "neighborhood")
                ).toLowerCase();
                const cityB = trim(get(addressB, "city")).toLowerCase();
                const stateB = trim(get(addressB, "state")).toLowerCase();
                const neighborhoodB = trim(
                    get(addressB, "neighborhood")
                ).toLowerCase();

                return (
                    cityA === cityB &&
                    stateA === stateB &&
                    neighborhoodA === neighborhoodB
                );
            };

            const addressesEqualTo = address =>
                filter(addresses, possibleDuplicatedAddress =>
                    areEqualAddresses(address, possibleDuplicatedAddress)
                );

            const hasDuplicatedAddress = some(addresses, address => {
                const duplicatedAddresses = addressesEqualTo(address);
                return duplicatedAddresses.length > 1;
            });

            return !hasDuplicatedAddress;
        });
