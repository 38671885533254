import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import set from "lodash/set";
import find from "lodash/find";
import UserBuyerProfile from "./UserBuyerProfile";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import {
    receiveUserProfile,
    updateBuyerProfile
} from "../../reducers/user/UserActions";
import { ROLE_BROKER } from "../../shared/constants";
import mixpanel from "mixpanel-browser";

class UserProfile extends Component {
    componentDidMount() {
        const { user } = this.props;
        const isBuyer = get(user.profile, "publicProfile.userInfo.buyer");
        const isSeller = get(user.profile, "publicProfile.userInfo.seller");
        const isProspect = get(user.profile, "publicProfile.userInfo.prospect");

        if (isBuyer) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_BUYER_TOKEN);
        }
        if (isSeller) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_SELLER_TOKEN);
        }
        if (isProspect) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_PROSPECT_TOKEN);
        }
    }

    updateField = profile => {
        if (isFunction(this.props.onUpdateProfile)) {
            this.props.onUpdateProfile(profile);
        }
    };

    onUpdateAgent = newAgent => {
        const {
            user: { profile }
        } = this.props;
        this.updateField({ ...profile, realtor: newAgent });
        mixpanel.track("Completed Agent Selection", {
            agentSelectedFirstName: get(newAgent, "userInfo.firstName"),
            agentSelectedLastName: get(newAgent, "userInfo.lastName"),
            agentSelectedEmail: get(newAgent, "userInfo.email"),
            agentSelectedPhone: get(newAgent, "userInfo.phone")
        });
    };

    onUpdateAgentMatches = matches => {
        const {
            user: { profile }
        } = this.props;
        this.updateField({ ...profile, realtorMatches: matches });
    };

    onUploadPicture = picture => {
        const {
            user: { profile }
        } = this.props;
        const profileWithUpdatedPicture = { ...profile };
        set(
            profileWithUpdatedPicture,
            "publicProfile.userInfo.profilePicUrl",
            picture
        );
        this.updateField(profileWithUpdatedPicture);
    };

    render = () => {
        const { user, admin } = this.props;
        const isBuyer = get(user.profile, "publicProfile.userInfo.buyer");
        const isSeller = get(user.profile, "publicProfile.userInfo.seller");
        const isRealtor = get(user.profile, "publicProfile.userInfo.realtor");
        const isEmd = get(user.profile, "publicProfile.userInfo.prospect");
        const isAdmin = get(user.profile, "publicProfile.userInfo.brewCrew");
        const roles = get(user.profile, "publicProfile.userInfo.roles");
        const roleBroker = find(roles, { name: ROLE_BROKER }) ? true : false;
        const isImpersonateUser = get(
            admin.loginImpersonateUser,
            "successImpersonateUser"
        );
        const isFetchingImpersonateUser = get(
            admin.loginImpersonateUser,
            "isFetchingImpersonateUser"
        );
        const isFetchingLogoutImpersonateUser = get(
            admin.logoutImpersonateUser,
            "isFetchingLogoutImpersonateUser"
        );
        const isFetchingImpersonateUserProfile = get(
            user,
            "isFetchingImpersonateUserProfile"
        );
        const hasCompletedOnboarding = get(
            user.profile,
            "publicProfile.userInfo.hasCompletedOnboarding"
        );

        if (
            isFetchingImpersonateUser ||
            isFetchingLogoutImpersonateUser ||
            isFetchingImpersonateUserProfile
        ) {
            return <SpinnerLoader />;
        }

        if (!isImpersonateUser && roleBroker) {
            return <Redirect to="/admin-broker" />;
        }

        if (isAdmin && !isImpersonateUser) {
            return <Redirect to="admin-profile" />;
        }

        if (isSeller) {
            return <Redirect to="seller" />;
        }

        if (isBuyer) {
            return (
                <UserBuyerProfile
                    profile={user.profile}
                    onChooseAgent={this.onUpdateAgent}
                    onGetAgentMatches={this.onUpdateAgentMatches}
                    onUploadPicture={this.onUploadPicture}
                    onUpdateProfile={this.props.onUpdateBuyerProfile}
                />
            );
        }

        if (isRealtor && !hasCompletedOnboarding) {
            return <Redirect to="agent-flow" />;
        }

        if (isRealtor) {
            return <Redirect to="realtor" />;
        }

        if (isEmd) {
            return <Redirect to="transfer" />;
        }

        return <div />;
    };
}

UserProfile.propTypes = {
    user: PropTypes.object,
    admin: PropTypes.object,
    onUpdateProfile: PropTypes.func,
    onUpdateBuyerProfile: PropTypes.func
};

const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin
});

export default withRouter(
    connect(mapStateToProps, {
        onUpdateBuyerProfile: updateBuyerProfile,
        onUpdateProfile: receiveUserProfile
    })(UserProfile)
);
