import isObject from 'lodash/isObject';
import every from 'lodash/every';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {getIn} from 'formik';

class BaseWizardStep extends Component {
    static propTypes = {
        errors: PropTypes.object,
        touched: PropTypes.object,
        onTrackProgress: PropTypes.func,
        onSkipStep: PropTypes.func,
        renderNavigation: PropTypes.func
    };

    static defaultProps = {
        onTrackProgress: () => {}
    };

    componentDidMount() {
        this._oldValues = cloneDeep(this.props.values);
    }

    componentWillUnmount() {
        const newValues = cloneDeep(this.props.values);
        this.afterStep(this._oldValues, newValues);
    }

    afterStep() {
        return Promise.resolve(this.props.onTrackProgress())
    }

    isFieldTouched(fieldName) {
        const { touched } = this.props;
        const fieldTouched = getIn(touched, fieldName);

        if (typeof fieldTouched === 'boolean') return fieldTouched;
        if (typeof fieldTouched === 'object') return every(fieldTouched);

        return false;
    }

    getFieldError = (fieldName) => {
        const { errors } = this.props;

        if (!this.isFieldTouched(fieldName)) return;

        const message = getIn(errors, fieldName);
        if (typeof message === 'string') return message;
    };

    renderFieldError = (fieldName) => {
        const message = this.getFieldError(fieldName);
        if (message) {
            return (
                <div className="buyer__signup__field__error--content">
                    <div className="buyer__signup__field__error">{message}</div>
                </div>
            );
        }
    };

    renderFormError = (fieldName) => {
        const message = this.getFieldError(fieldName);
        if (message) {
            return (
                <div className="buyer__signup__form__error--content">
                    <div className="buyer__signup__form__error">{message}</div>
                </div>
            );
        }
        return null;
    };

    setValues = (values, path = '', touch = false) => {
        const { setFieldValue, setFieldTouched } = this.props;
        // eslint-disable-next-line no-unused-vars
        for (const [name, value] of Object.entries(values)) {
            const key = path ? [path, name].join('.') : name;
            setFieldValue(key, value);
            if (touch) setFieldTouched(key);
        }
    };

    areFieldsValid(fieldNames = []) {
        const { errors } = this.props;
        return !fieldNames.some(fieldName => {
            const error = getIn(errors, fieldName);
            if (Array.isArray(error) || isObject(error)) {
                return find(error, value => !!value)
            } else {
                return error !== undefined;
            }
        });
    }

    renderStepButtons = (isStepFulfilled = true) => this.props.renderNavigation(isStepFulfilled)
}

export default BaseWizardStep;
