import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import find from "lodash/find";
import set from "lodash/set";
import split from "lodash/split";
import findIndex from "lodash/findIndex";
import isFunction from "lodash/isFunction";
import cloneDeep from "lodash/cloneDeep";
import { format } from "date-fns";
import { ProfilePicture } from "../ProfilePicture/ProfilePicture";
import EarnestMoneyDepositFirst from "../EarnestMoneyDepositFirst/EarnestMoneyDepositFirst";
import EarnestMoneyDepositRightColumn from "../EarnestMoneyDepositRightColumn/EarnestMoneyDepositRightColumn";
import EarnestMoneyDepositProfileDepositNeedsValidationButtonWrapper from "../EarnestMoneyDepositProfileDepositNeedsValidationButtonWrapper/EarnestMoneyDepositProfileDepositNeedsValidationButtonWrapper";
import EarnestMoneyDepositValidationModal from "../EarnestMoneyDepositValidationModal/EarnestMoneyDepositValidationModal";
import EmdCongratulationsModal from "../EmdCongratulationsModal/EmdCongratulationsModal";
import { earnestMoneyDepositUploadVerification } from "../../util/service_api";
import {
    COMPLETE,
    CANCEL
} from "../EarnestMoneyDepositStatusPill/EarnestMoneyDepositStatusPill";
import { getUTCDate, formatAddressStreetApartment } from "../../util/util";
import getYear from "date-fns/get_year";
import mixpanel from "mixpanel-browser";

import "./emdLandingPageAccounts.css";

export const ACCOUNTS_TAB = "accounts";
export const PROFILE_TAB = "profile";

export default class EmdLandingPageAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document: undefined,
            documentType: undefined,
            isValidationModalVisible: false,
            isUploadingVerification: false,
            uploadVerificationError: "",
            listDepositId: this.initialListDepositId(),
            isVisibleCongratulationModal: false,
            isShowedModal: false
        };
    }

    getdate = () => getYear(new Date());

    isDepositCollapsed = deposit =>
        get(deposit, "status.name") !== COMPLETE &&
        get(deposit, "status.name") !== CANCEL;

    initialListDepositId = () =>
        map(this.getEarnestMoneyDeposits(), deposit => ({
            id: get(deposit, "id"),
            showAccounts: this.isDepositCollapsed(deposit)
        }));

    buildDocumentTypeAsSelectOptions = () =>
        map(this.props.verificationDocumentTypes, document => ({
            label: get(document, "displayName"),
            value: get(document, "name")
        }));

    onSelectDocument = document => {
        this.setState({ document });
    };

    onDocumentTypeChange = documentType => {
        this.setState({ documentType });
    };

    onClickOnNeedsValidation = () => {
        this.setState({
            isValidationModalVisible: true,
            isUploadingVerification: false,
            document: undefined,
            documentType: undefined,
            uploadVerificationError: ""
        });
    };

    onCancelUploadVerification = () => {
        this.setState({
            isValidationModalVisible: false,
            isUploadingVerification: false,
            document: undefined,
            documentType: undefined,
            uploadVerificationError: ""
        });
    };

    onUploadVerification = document => {
        const { documentType } = this.state;
        const documentTypeValue = get(documentType, "value");
        const uploadDocument = earnestMoneyDepositUploadVerification(
            documentTypeValue,
            document
        );

        this.setState({ isUploadingVerification: true });

        uploadDocument
            .then(() => {
                this.setState({ isValidationModalVisible: false });
            })
            .catch(error => {
                const data = get(error, "response.data.message");
                const arrayDataError = split(data, ",");
                const message = arrayDataError.find(it =>
                    it.includes("message")
                );
                const textMessage = get(split(message, '"'), 3);
                const errorMessage =
                    "Sorry, there was a problem uploading your verification. Please try again in a few minutes.";
                this.setState({
                    uploadVerificationError: isEmpty(textMessage)
                        ? errorMessage
                        : textMessage
                });
            })
            .finally(() => {
                this.setState({ isUploadingVerification: false });
            });
    };

    getProfilePicture = () =>
        get(this.props.user, "profile.publicProfile.userInfo.profilePicUrl");

    getEarnestMoneyDeposits = () =>
        get(this.props.user, "profile.earnestMoneyDeposits");

    getFundingSources = () => get(this.props.user, "profile.fundingSources");

    buildProfilePicture = () => (
        <div className="emd-landing-page__profile-picture-container">
            <ProfilePicture picture={this.getProfilePicture()} />
        </div>
    );

    findDepositId = id =>
        find(this.state.listDepositId, deposit => get(deposit, "id") === id);

    findIndexDepositId = id =>
        findIndex(
            this.state.listDepositId,
            deposit => get(deposit, "id") === id
        );

    getStateButtonShowDeposit = id =>
        get(this.findDepositId(id), "showAccounts");

    setToggleStateDeposit = (list, indexDeposit, id, stateDeposit) =>
        set(list, indexDeposit, { id, showAccounts: !stateDeposit });

    toggleShowDeposit = id => {
        const indexDeposit = this.findIndexDepositId(id);
        const stateDeposit = this.getStateButtonShowDeposit(id);
        this.setState(prevState => {
            const prevDeposits = cloneDeep(prevState.listDepositId);
            const updateDeposits = this.setToggleStateDeposit(
                prevDeposits,
                indexDeposit,
                id,
                stateDeposit
            );
            return { listDepositId: updateDeposits };
        });
    };

    buildDeposit = deposit => {
        const amount = {
            amount: get(deposit, "totalAmount"),
            currency: "USD"
        };

        const deposits = map(get(deposit, "achs"), ach => ({
            bankIcon: get(ach, "plaidBankAccount.institution.logoUrl"),
            bankName: get(ach, "plaidBankAccount.institution.name"),
            bankAccountNumber: get(
                ach,
                "plaidBankAccount.maskedBankAccountNumber"
            ),
            bankAccountName: get(ach, "plaidBankAccount.type.name"),
            creationUtcDate: format(
                getUTCDate(get(ach, "dwolla.ts")),
                "MM/DD/YYYY"
            ),
            completedUtcDate: get(ach, "dwolla.completedDate")
                ? format(get(ach, "dwolla.completedDate"), "MM/DD/YYYY")
                : null,
            deposit: {
                amount: get(ach, "amount"),
                currency: get(ach, "plaidBankAccount.currency")
            },
            completed: get(ach, "dwolla.transferComplete")
        }));

        return (
            <div
                key={get(deposit, "id")}
                className="emd-landing-page__earnest-money-deposit"
            >
                <EarnestMoneyDepositProfileDepositNeedsValidationButtonWrapper
                    deposit={amount}
                    depositId={get(deposit, "id")}
                    stateButtonShowDeposit={this.getStateButtonShowDeposit(
                        get(deposit, "id")
                    )}
                    toggleShowDeposit={this.toggleShowDeposit}
                    property={get(deposit, "subjectPropertyAddress")}
                    state={get(deposit, "status.name")}
                    deposits={deposits}
                    onClickOnNeedsValidation={this.onClickOnNeedsValidation}
                    earnestMoneyDepositCancel={
                        this.props.earnestMoneyDepositCancel
                    }
                    eligibleForCancel={get(deposit, "eligibleForCancel")}
                    fetchingCancelDeposit={get(
                        this.props.prospect,
                        "fetchingCancelDeposit"
                    )}
                    fetchCancelDeposit={get(
                        this.props.prospect,
                        "fetchCancelDeposit"
                    )}
                    cancelDepositId={get(
                        this.props.prospect,
                        "cancelDepositId"
                    )}
                    type={get(deposit, "type")}
                    memo={get(deposit, "memo")}
                    onEdiDepositClick={this.onMakeDepositClick}
                    isMidUserRole={this.props.isMidUserRole}
                    isReadOnlyUserRole={this.props.isReadOnlyUserRole}
                    isImpersonatingUser={this.props.isImpersonatingUser}
                />
            </div>
        );
    };

    onMakeDepositClick = () => {
        mixpanel.track("Clicked On Edit");
        if (isFunction(this.props.resetDeposit)) {
            this.props.resetDeposit();
        }
    };

    buildSuccessDeposit = () => {
        const { prospect } = this.props;
        const successFinishDeposit = get(prospect, "successFinishDeposit");

        if (!successFinishDeposit) return;

        return (
            <div className="emd-landing-page__deposit-success">
                <div className="emd-landing-page__deposit-success--text">
                    <strong className="emd-landing-page__deposit-success--text">
                        Congratulations!
                    </strong>{" "}
                    Your transfer was successfully created.
                    <br />
                    We will send you an email notification when your transfer is
                    completed.
                </div>
            </div>
        );
    };

    isTotalBalancePositive = () => {
        const fundingSources = this.getFundingSources();
        return find(fundingSources, account => get(account, "balance") > 0);
    };

    buildDeposits = () => {
        const earnestMoneyDeposits = this.getEarnestMoneyDeposits();
        const deposits = map(earnestMoneyDeposits, this.buildDeposit);

        return (
            <div className="col-xs-12 col-sm-12 col-md-8">
                <div className="emd-landing-page__deposit-button">
                    <EarnestMoneyDepositFirst
                        onMakeDepositClick={this.onMakeDepositClick}
                        positiveBalance={this.isTotalBalancePositive()}
                        isMidUserRole={this.props.isMidUserRole}
                        isReadOnlyUserRole={this.props.isReadOnlyUserRole}
                        isImpersonatingUser={this.props.isImpersonatingUser}
                        onlyButton
                    />
                </div>
                {this.buildSuccessDeposit()}
                {deposits}
            </div>
        );
    };

    buildCreateFirstDepositBox = () => (
        <div className="col-md-offset-1 col-md-6 col-xs-12">
            <EarnestMoneyDepositFirst
                onMakeDepositClick={this.onMakeDepositClick}
                positiveBalance={this.isTotalBalancePositive()}
                isMidUserRole={this.props.isMidUserRole}
                isReadOnlyUserRole={this.props.isReadOnlyUserRole}
                isImpersonatingUser={this.props.isImpersonatingUser}
            />
        </div>
    );

    buildLeftColumn = () => {
        const earnestMoneyDeposits = this.getEarnestMoneyDeposits();

        if (!isEmpty(earnestMoneyDeposits)) {
            if (
                earnestMoneyDeposits.length !== this.state.listDepositId.length
            ) {
                this.setState({ listDepositId: this.initialListDepositId() });
            }
            return this.buildDeposits();
        }

        return this.buildCreateFirstDepositBox();
    };

    buildTosLink = () => (
        <a
            className="signup__emd--links"
            target="_blank"
            rel="noopener noreferrer"
            href="/terms-of-use-emd"
        >
            Terms of Service
        </a>
    );

    buildPrivacyPolicyLink = () => (
        <a
            className="signup__emd--links"
            target="_blank"
            rel="noopener noreferrer"
            href="/privacy-policy-emd"
        >
            Privacy Policy
        </a>
    );

    buildRightColumn = () => {
        const {
            prospect,
            getFundingSourcesAndReloadProfile,
            loadUserProfile,
            fetchVoaUrl,
            earnestMoneyDepositDestinationAccount
        } = this.props;
        const consumerId = get(prospect, "consumerId");
        const voaUrl = get(prospect, "voaUrl");
        const fetchFundingSources = get(prospect, "fetchFundingSources");
        const successFundingSources = get(prospect, "successFundingSources");
        const fundingSources = this.getFundingSources();
        const fundingSourcesList = map(fundingSources, account => ({
            id: get(account, "id"),
            icon: get(account, "institution.logoUrl"),
            name: get(account, "institution.name"),
            number: get(account, "maskedBankAccountNumber"),
            type: get(account, "type.name"),
            balanceDate: format(
                getUTCDate(get(account, "balanceDate")),
                "MM/DD/YYYY"
            ),
            balance: {
                amount: get(account, "balance"),
                currency: get(account, "currency")
            }
        }));

        const destinationAccount = [
            {
                icon:
                    "https://is4-ssl.mzstatic.com/image/thumb/Purple123/v4/20/0f/71/200f718e-4ad3-ca81-a8a8-768b2a16db6e/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-7.png/246x0w.jpg",
                bankName: get(
                    earnestMoneyDepositDestinationAccount,
                    "bankName"
                ),
                accountNumber: get(
                    earnestMoneyDepositDestinationAccount,
                    "accountNumber"
                ),
                address: get(earnestMoneyDepositDestinationAccount, "address"),
                ownerName: get(
                    earnestMoneyDepositDestinationAccount,
                    "ownerName"
                )
            }
        ];

        return (
            <EarnestMoneyDepositRightColumn
                accounts={fundingSourcesList}
                destinationAccounts={destinationAccount}
                consumerId={consumerId}
                voaUrl={voaUrl}
                fetchFundingSources={fetchFundingSources}
                successFundingSources={successFundingSources}
                getFundingSourcesAndReloadProfile={
                    getFundingSourcesAndReloadProfile
                }
                loadUserProfile={loadUserProfile}
                fetchVoaUrl={fetchVoaUrl}
                isMidUserRole={this.props.isMidUserRole}
                isReadOnlyUserRole={this.props.isReadOnlyUserRole}
                isImpersonatingUser={this.props.isImpersonatingUser}
            />
        );
    };

    onOpenCongratulationModal = () =>
        this.setState({ isVisibleCongratulationModal: true });

    onCloseCongratulationModal = () => {
        this.setState({ isVisibleCongratulationModal: false });
        if (isFunction(this.props.resetDeposit)) {
            this.props.resetDeposit();
        }
    };

    getProfileCurrentAddress = newDeposit =>
        `${get(newDeposit, "subjectPropertyAddress.streetNumberName")}, ${get(
            newDeposit,
            "subjectPropertyAddress.city"
        )}, ${get(newDeposit, "subjectPropertyAddress.zipcode")}`;

    getProfileApartment = newDeposit =>
        get(newDeposit, "subjectPropertyAddress.apartmentSuiteNumber");

    getProfileAddress = newDeposit =>
        formatAddressStreetApartment(
            this.getProfileCurrentAddress(newDeposit),
            this.getProfileApartment(newDeposit)
        );

    render = () => {
        const { prospect } = this.props;
        const successFinishDeposit = get(prospect, "successFinishDeposit");
        const newDeposit = get(prospect, "depositData");
        const type = get(newDeposit, "type.id");
        const earnest_money_deposit = 1;

        if (
            successFinishDeposit &&
            type === earnest_money_deposit &&
            !this.state.isShowedModal
        ) {
            this.setState({ isShowedModal: true });
            this.onOpenCongratulationModal();
        }

        return (
            <div className="emd-landing-page">
                <EarnestMoneyDepositValidationModal
                    document={this.state.document}
                    documentType={this.state.documentType}
                    documentTypes={this.buildDocumentTypeAsSelectOptions()}
                    onSelectDocument={this.onSelectDocument}
                    onDocumentTypeChange={this.onDocumentTypeChange}
                    isVisible={this.state.isValidationModalVisible}
                    isLoading={this.state.isUploadingVerification}
                    onCancel={this.onCancelUploadVerification}
                    onUploadDocument={this.onUploadVerification}
                    error={this.state.uploadVerificationError}
                />
                <EmdCongratulationsModal
                    isVisible={this.state.isVisibleCongratulationModal}
                    onClose={this.onCloseCongratulationModal}
                    onCancel={this.onCloseCongratulationModal}
                    onSend={this.props.sendEmails}
                    isSendedEmail={this.props.successSendEmails}
                    isSendingEmail={this.props.isFetchingSendEmails}
                    earnestMoneyDepositId={get(newDeposit, "id")}
                    amount={get(newDeposit, "totalAmount")}
                    address={this.getProfileAddress(newDeposit)}
                />
                <div className="row">
                    {this.buildLeftColumn()}
                    <div className="col-xs-12 col-md-4 col-sm-12">
                        {this.buildRightColumn()}
                    </div>
                </div>
                <div className="emd-landing-page__footer__container">
                    <div className="emd-landing-page__footer">
                        <div className="emd-landing-page__footer__copyright">
                            {this.buildTosLink()}
                            {" | "}
                            {this.buildPrivacyPolicyLink()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

EmdLandingPageAccounts.propTypes = {
    user: PropTypes.object,
    prospect: PropTypes.object,
    verificationDocumentTypes: PropTypes.array,
    getFundingSourcesAndReloadProfile: PropTypes.func,
    loadUserProfile: PropTypes.func,
    fetchVoaUrl: PropTypes.func,
    earnestMoneyDepositCancel: PropTypes.func,
    resetDeposit: PropTypes.func,
    earnestMoneyDepositDestinationAccount: PropTypes.object,
    tab: PropTypes.string,
    onChangeTab: PropTypes.func,
    sendEmails: PropTypes.func,
    successSendEmails: PropTypes.bool,
    isFetchingSendEmails: PropTypes.bool,
    isMidUserRole: PropTypes.bool,
    isReadOnlyUserRole: PropTypes.bool,
    isImpersonatingUser: PropTypes.bool
};
