import React, {Fragment}  from 'react';
import get from 'lodash/get';
import BaseWizardStep from '../BuyerSignUp/BaseWizardStep';
import PhoneVerification from './PhoneVerification/PhoneVerification';
import mixpanel from 'mixpanel-browser';
import ScrollToTopOnMount from '../../ScrollTopOnMount/ScrollTopOnMount';

const VERIFICATION_STATE_VALID = 'VALID';

class StepContact extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        if(this.props.isBuyerFlow) {
            mixpanel.track(
                "Completed Phone Verification", {
                    "phone": get(values, 'phone'),
                }
            )
        }
    };

    getCheckState = () => this.props.values.phoneCheckState;

    isStateFulfilled = () => this.getCheckState() === VERIFICATION_STATE_VALID && this.areFieldsValid(['phone']);

    render() {
        const isStepFulfilled = this.isStateFulfilled();

        return (
            <Fragment>
            <ScrollToTopOnMount />
                <div className="buyer__signup__step is-higher">
                    <div className={`buyer__signup__question${this.props.isBuyerFlow ? ' white-font': ''}`}>
                        Let&apos;s Verify Your Phone Number
                    </div>

                    <div className={`buyer__signup__fieldset${this.props.isBuyerFlow ? ' is-beyond-widest': ''}`}>
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field">
                                <div className="buyer__signup__field__label align-left">
                                    We need your phone number for verification
                                    {' '}
                                    purposes only. Don’t worry, we won’t share
                                    {' '}
                                    your number with anyone.
                                    {' '}
                                    We hate Robocalls as much as you do.
                                </div>
                            </div>
                        </div>
                        <PhoneVerification
                            renderFieldError={this.renderFieldError}
                            setFieldValue={this.props.setFieldValue}
                            setFieldTouched={this.props.setFieldTouched}
                            values={this.props.values}
                            errors={this.props.errors}
                        />
                    </div>
                </div>
                { this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    hasMixPanelTracking: true,
                }, this.mixpanelFunction)}
            </Fragment>
        );
    };
}

export default StepContact;
