import { toast } from "react-toastify";
import * as APIUtil from "../../util/service_api";
import {
    GET_USERS_AS_ADMIN_FETCH,
    GET_USERS_AS_ADMIN_SUCCESS,
    GET_USERS_AS_ADMIN_ERROR,
    CREATE_NEW_ADMIN_FETCH,
    CREATE_NEW_ADMIN_SUCCESS,
    CREATE_NEW_ADMIN_ERROR,
    CREATE_NEW_ADMIN_RESET,
    LOGIN_IMPERSONATE_FETCH,
    LOGIN_IMPERSONATE_SUCCESS,
    LOGIN_IMPERSONATE_ERROR,
    LOGOUT_IMPERSONATE_FETCH,
    LOGOUT_IMPERSONATE_SUCCESS,
    RECEIVE_IMPERSONATE_USER_PROFILE,
    FETCH_IMPERSONATE_USER_PROFILE,
    GET_LICENSES,
    GET_LICENSES_SUCCESS,
    GET_ROLES_FETCH,
    GET_ROLES_SUCCESS,
    GET_ROLES_ERROR,
    GET_BREWCREW_USERS_FETCH,
    GET_BREWCREW_USERS_SUCCESS,
    GET_BREWCREW_USERS_ERROR,
    GET_STATS,
    GET_STATS_SUCCESS,
    SET_SEARCH_PARAMETERS,
    GET_BROKERAGE,
    GET_BROKERAGE_SUCCESS
} from "./AdminActionTypes";
import { SELECT_ROLE_OPTION } from "../../shared/constants";
import { loadUserProfile } from "../user/UserActions";

export const fetchingGetUsersAsAdmin = () => ({
    type: GET_USERS_AS_ADMIN_FETCH
});

export const successfulGetUsersAsAdmin = (
    searchParams,
    filterBy,
    response
) => ({
    type: GET_USERS_AS_ADMIN_SUCCESS,
    payload: { searchParams, filterBy, response }
});

export const errorGetUsersAsAdmin = error => ({
    type: GET_USERS_AS_ADMIN_ERROR,
    payload: { error }
});

export const fetchingGetRoles = () => ({
    type: GET_ROLES_FETCH
});

export const successfulGetRoles = response => ({
    type: GET_ROLES_SUCCESS,
    payload: { response }
});

export const errorGetRoles = error => ({
    type: GET_ROLES_ERROR,
    payload: { error }
});

export const fetchingGetBrewCrewUsers = () => ({
    type: GET_BREWCREW_USERS_FETCH
});

export const successfulGetBrewCrewUsers = response => ({
    type: GET_BREWCREW_USERS_SUCCESS,
    payload: { response }
});

export const errorGetBrewCrewUsers = error => ({
    type: GET_BREWCREW_USERS_ERROR,
    payload: { error }
});

export const getUsersAsAdmin = (filterBy, searchParams) => dispatch => {
    dispatch(fetchingGetUsersAsAdmin());
    return APIUtil.getUsersAdmin(filterBy, searchParams)
        .then(response => {
            dispatch(
                successfulGetUsersAsAdmin(searchParams, filterBy, response)
            );
            return response;
        })
        .catch(error => dispatch(errorGetUsersAsAdmin(error)));
};

export const getUsersRole = (filterBy, role, searchParams) => dispatch => {
    dispatch(fetchingGetUsersAsAdmin());
    return APIUtil.getUsersRole(role, searchParams)
        .then(response => {
            dispatch(
                successfulGetUsersAsAdmin(searchParams, filterBy, response)
            );
            return response;
        })
        .catch(error => dispatch(errorGetUsersAsAdmin(error)));
};

export const fetchingCreateNewAdmin = () => ({
    type: CREATE_NEW_ADMIN_FETCH
});

export const successfulCreateNewAdmin = response => ({
    type: CREATE_NEW_ADMIN_SUCCESS,
    payload: { response }
});

export const errorCreateNewAdmin = error => ({
    type: CREATE_NEW_ADMIN_ERROR,
    payload: { error }
});

export const onCreateNewAdmin = data => dispatch => {
    dispatch(fetchingCreateNewAdmin());
    return APIUtil.createNewAdmin(data)
        .then(response => {
            dispatch(successfulCreateNewAdmin(response));
            return response;
        })
        .catch(error => dispatch(errorCreateNewAdmin(error)));
};

export const resetCreateNewAdmin = () => ({
    type: CREATE_NEW_ADMIN_RESET
});

export const fetchingLoginImpersonateUser = () => ({
    type: LOGIN_IMPERSONATE_FETCH
});

export const successLoginImpersonateUser = () => ({
    type: LOGIN_IMPERSONATE_SUCCESS
});

export const errorLoginImpersonateUser = error => ({
    type: LOGIN_IMPERSONATE_ERROR,
    payload: { error }
});

export const successLogoutImpersonateUser = () => ({
    type: LOGOUT_IMPERSONATE_SUCCESS,
    payload: {}
});

export const fetchingLogoutImpersonateUser = () => ({
    type: LOGOUT_IMPERSONATE_FETCH
});

export const onLogoutImpersonateUser = () => dispatch => {
    dispatch(fetchingLogoutImpersonateUser());
    APIUtil.logoutImpersonateUser().finally(response =>
        dispatch(
            successLogoutImpersonateUser(response),
            dispatch(loadUserProfile())
        )
    );
};

export const fetchImpersonateUserProfile = () => ({
    type: FETCH_IMPERSONATE_USER_PROFILE,
    payload: {}
});

export const receiveImpersonateUserProfile = profile => ({
    type: RECEIVE_IMPERSONATE_USER_PROFILE,
    payload: {
        profile
    }
});

export const fetchingLicenses = () => ({
    type: GET_LICENSES
});

export const successLicenses = response => ({
    type: GET_LICENSES_SUCCESS,
    payload: { response }
});

export const fetchingStats = () => ({
    type: GET_STATS
});

export const successStats = response => ({
    type: GET_STATS_SUCCESS,
    payload: { response }
});

export const fetchingBrokerage = () => ({
    type: GET_BROKERAGE
});

export const successBrokerage = response => ({
    type: GET_BROKERAGE_SUCCESS,
    payload: { response }
});

export const loadImpersonateUserProfile = () => dispatch => {
    dispatch(fetchImpersonateUserProfile());
    return APIUtil.loadUserProfile()
        .then(response => {
            dispatch(receiveImpersonateUserProfile(response));
            dispatch(successLoginImpersonateUser());
        })
        .catch(error => {
            if (error && error.response && error.response.status === 401) {
                dispatch(successLogoutImpersonateUser());
            }
            return Promise.reject(error);
        });
};

export const impersonateUser = id => dispatch => {
    const notification = toast.info("Impersonating user...", {
        autoClose: false
    });
    //const errorNotification = {
    //    render: 'Could not impersonate user, please try again in a few minutes',
    //    type: toast.TYPE.ERROR,
    //    autoClose: 3000,
    //};

    dispatch(fetchingLoginImpersonateUser());
    APIUtil.loginImpersonateUser(id).finally(() => {
        dispatch(loadImpersonateUserProfile());
        toast.dismiss(notification);
    });
    //.catch((error) => {
    //    dispatch(errorLoginImpersonateUser(error));
    //    toast.update(notification, errorNotification);
    //});
};

export const impersonateUserByRole = (id, role) => dispatch => {
    const notification = toast.info("Impersonating user...", {
        autoClose: false
    });
    //const errorNotification = {
    //    render: 'Could not impersonate user, please try again in a few minutes',
    //    type: toast.TYPE.ERROR,
    //    autoClose: 3000,
    //};

    dispatch(fetchingLoginImpersonateUser());
    APIUtil.loginImpersonateUserWithRole(id, role).finally(() => {
        dispatch(loadImpersonateUserProfile());
        toast.dismiss(notification);
    });
    //.catch((error) => {
    //    dispatch(errorLoginImpersonateUser(error));
    //    toast.update(notification, errorNotification);
    //});
};

export const resendRealtorAgreement = realtorId => () => {
    const notification = toast.info("Sending realtor agreement...", {
        autoClose: false
    });
    const successNotification = {
        render: "Realtor agreement sent!",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000
    };
    const errorNotification = {
        render:
            "Could not send realtor agreement, please try again in a few minutes",
        type: toast.TYPE.ERROR,
        autoClose: 3000
    };

    APIUtil.resendRealtorAgreement(realtorId)
        .then(() => toast.update(notification, successNotification))
        .catch(() => toast.update(notification, errorNotification));
};

export const resendBrokerAgreement = realtorId => () => {
    const notification = toast.info("Sending broker agreement...", {
        autoClose: false
    });
    const successNotification = {
        render: "Broker agreement sent!",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000
    };
    const errorNotification = {
        render:
            "Could not send broker agreement, please try again in a few minutes",
        type: toast.TYPE.ERROR,
        autoClose: 3000
    };

    APIUtil.resendBrokerAgreement(realtorId)
        .then(() => toast.update(notification, successNotification))
        .catch(() => toast.update(notification, errorNotification));
};

export const suspendUser = (realtorId, toggle) => dispatch => {
    const data = {
        term: null,
        page: null,
        size: null,
        sortBy: null,
        sortOrder: null
    };
    const notificationText = toggle
        ? "Approving agent..."
        : "Suspending agent...";
    const notification = toast.info(notificationText, { autoClose: false });

    const successText = toggle
        ? "Agent successfully approved"
        : "Agent successfully suspended ";
    const successNotification = {
        render: successText,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000
    };

    const errorText = toggle
        ? "Agent could not be approved, please try again in a few minutes"
        : "Agent could not be suspended, please try again in a few minutes";
    const errorNotification = {
        render: errorText,
        type: toast.TYPE.ERROR,
        autoClose: 3000
    };

    return APIUtil.suspendUser(realtorId, toggle)
        .then(() => {
            toast.update(notification, successNotification);
            return dispatch(getUsersAsAdmin(SELECT_ROLE_OPTION[0].id, data));
        })
        .catch(() => toast.update(notification, errorNotification));
};

export const getLicenses = () => dispatch => {
    dispatch(fetchingLicenses());
    return APIUtil.getLicenses().then(response => {
        dispatch(successLicenses(response));
        return response;
    });
};

export const getRoles = () => dispatch => {
    dispatch(fetchingGetRoles());
    APIUtil.getRoles()
        .then(response => dispatch(successfulGetRoles(response)))
        .catch(error => dispatch(errorGetRoles(error)));
};

export const getBrewCrewUsers = () => dispatch => {
    dispatch(fetchingGetBrewCrewUsers());
    APIUtil.getBrewCrewUsers()
        .then(response => dispatch(successfulGetBrewCrewUsers(response)))
        .catch(error => dispatch(errorGetBrewCrewUsers(error)));
};

export const getStats = data => dispatch => {
    dispatch(fetchingStats());
    APIUtil.getStats(data).then(response => dispatch(successStats(response)));
};

export const setSearchParameters = searchParams => ({
    type: SET_SEARCH_PARAMETERS,
    payload: { searchParams }
});

export const getBrokerage = brokerageId => dispatch => {
    dispatch(fetchingBrokerage());
    APIUtil.getBrokerage(brokerageId).then(response =>
        dispatch(successBrokerage(response))
    );
};
