import React, { Component } from 'react';
import AdminPortalContainer from './AdminPortalContainer';
// import backgroundImage from '../../styles/assets/bg-yellow-bubble.svg';

class Admin extends Component {
    render() {
        return (
            <div className="admin__container">
                <AdminPortalContainer />
            </div>
        );
    }
}

export default Admin;
