import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import {
    GET_USERS_AS_ADMIN_FETCH,
    GET_USERS_AS_ADMIN_SUCCESS,
    GET_USERS_AS_ADMIN_ERROR,
    CREATE_NEW_ADMIN_FETCH,
    CREATE_NEW_ADMIN_SUCCESS,
    CREATE_NEW_ADMIN_ERROR,
    CREATE_NEW_ADMIN_RESET,
    LOGIN_IMPERSONATE_FETCH,
    LOGIN_IMPERSONATE_SUCCESS,
    LOGIN_IMPERSONATE_ERROR,
    LOGOUT_IMPERSONATE_FETCH,
    LOGOUT_IMPERSONATE_SUCCESS,
    GET_LICENSES,
    GET_LICENSES_SUCCESS,
    GET_ROLES_FETCH,
    GET_ROLES_SUCCESS,
    GET_BREWCREW_USERS_FETCH,
    GET_BREWCREW_USERS_SUCCESS,
    GET_STATS,
    GET_STATS_SUCCESS,
    SET_SEARCH_PARAMETERS,
    GET_BROKERAGE,
    GET_BROKERAGE_SUCCESS
} from "./AdminActionTypes";
import { INVALIDATE_USER } from "../user/UserActionTypes";

const initialState = {
    getUsers: {
        users: {
            usersList: [],
            page: null,
            totalPages: null,
            totalUserCount: null,
            filterBy: "",
            term: null,
            state: null,
            startDate: null,
            endDate: null
        },
        isFetchedGetUsers: false,
        isFetchingGetUsers: false,
        errorGetUsers: false
    },
    getLicenses: {
        licenses: [],
        isFetchingLicenses: false,
        isFetchedLicenses: false
    },
    getStats: {
        stats: [],
        isFetchingStats: false,
        isFetchedStats: false
    },
    getBrokerage: {
        brokerage: {},
        isFetchingBrokerage: false,
        isFetchedBrokerage: false
    },
    getRoles: {
        roles: [],
        isFetchingRoles: false,
        isFetchedRoles: false
    },
    getBrewCrewUsers: {
        users: [],
        isFetchingBrewCrewUsers: false,
        isFetchedBrewCrewUsers: false
    },
    newAdmin: {
        successCreateNewAdmin: false,
        isFetchingCreateNewAdmin: false,
        errorCreateNewAdmin: false
    },
    loginImpersonateUser: {
        successImpersonateUser: false,
        isFetchingImpersonateUser: false,
        errorImpersonateUser: false
    },
    logoutImpersonateUser: {
        successLogoutImpersonateUser: false,
        isFetchingLogoutImpersonateUser: false
    }
};

const setUsers = payload => ({
    usersList: get(payload.response, "users"),
    page: get(payload.response, "page"),
    totalPages: get(payload.response, "totalPages"),
    totalUserCount: get(payload.response, "totalUserCount"),
    filterBy: payload.filterBy,
    term: payload.searchParams.term,
    state: payload.searchParams.state,
    startDate: payload.searchParams.startDate,
    endDate: payload.searchParams.endDate
});

const adminReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case INVALIDATE_USER:
            return initialState;
        case GET_USERS_AS_ADMIN_FETCH:
            return {
                ...state,
                getUsers: {
                    isFetchingGetUsers: true,
                    isFetchedGetUsers: false,
                    errorGetUsers: false
                }
            };
        case GET_USERS_AS_ADMIN_SUCCESS:
            return {
                ...state,
                getUsers: {
                    isFetchingGetUsers: false,
                    isFetchedGetUsers: true,
                    errorGetUsers: false,
                    users: setUsers(payload)
                }
            };
        case GET_USERS_AS_ADMIN_ERROR:
            return {
                ...state,
                getUsers: {
                    isFetchingGetUsers: false,
                    isFetchedGetUsers: false,
                    errorGetUsers: true
                }
            };
        case CREATE_NEW_ADMIN_FETCH:
            return {
                ...state,
                newAdmin: {
                    isFetchingCreateNewAdmin: true,
                    successCreateNewAdmin: false,
                    errorCreateNewAdmin: false
                }
            };
        case CREATE_NEW_ADMIN_SUCCESS:
            return {
                ...state,
                newAdmin: {
                    isFetchingCreateNewAdmin: false,
                    successCreateNewAdmin: true,
                    errorCreateNewAdmin: false
                }
            };
        case CREATE_NEW_ADMIN_ERROR:
            return {
                ...state,
                newAdmin: {
                    isFetchingCreateNewAdmin: false,
                    successCreateNewAdmin: false,
                    errorCreateNewAdmin: true
                }
            };
        case CREATE_NEW_ADMIN_RESET:
            return {
                ...state,
                newAdmin: {
                    isFetchingCreateNewAdmin: false,
                    successCreateNewAdmin: false,
                    errorCreateNewAdmin: false
                }
            };
        case LOGIN_IMPERSONATE_FETCH:
            return {
                ...state,
                loginImpersonateUser: {
                    isFetchingImpersonateUser: true,
                    successImpersonateUser: false,
                    errorImpersonateUser: false
                }
            };
        case LOGIN_IMPERSONATE_SUCCESS:
            return {
                ...state,
                loginImpersonateUser: {
                    successImpersonateUser: true,
                    isFetchingImpersonateUser: false
                }
            };
        case LOGIN_IMPERSONATE_ERROR:
            return {
                ...state,
                loginImpersonateUser: {
                    errorImpersonateUser: true,
                    isFetchingImpersonateUser: false
                }
            };
        case LOGOUT_IMPERSONATE_FETCH:
            return {
                ...state,
                logoutImpersonateUser: {
                    isFetchingLogoutImpersonateUser: true,
                    successLogoutImpersonateUser: false
                }
            };
        case LOGOUT_IMPERSONATE_SUCCESS:
            return {
                ...state,
                loginImpersonateUser: {
                    successImpersonateUser: false
                },
                logoutImpersonateUser: {
                    successLogoutImpersonateUser: true,
                    isFetchingLogoutImpersonateUser: false
                }
            };
        case GET_LICENSES:
            return {
                ...state,
                getLicenses: {
                    isFetchingLicenses: true,
                    isFetchedLicenses: false
                }
            };
        case GET_LICENSES_SUCCESS:
            return {
                ...state,
                getLicenses: {
                    isFetchingLicenses: false,
                    isFetchedLicenses: true,
                    licenses: payload.response
                }
            };
        case GET_ROLES_FETCH:
            return {
                ...state,
                getRoles: {
                    isFetchingRoles: true,
                    isFetchedRoles: false
                }
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                getRoles: {
                    isFetchingRoles: false,
                    isFetchedRoles: true,
                    roles: payload.response
                }
            };
        case GET_BREWCREW_USERS_FETCH:
            return {
                ...state,
                getBrewCrewUsers: {
                    isFetchingBrewCrewUsers: true,
                    isFetchedBrewCrewUsers: false
                }
            };
        case GET_BREWCREW_USERS_SUCCESS:
            return {
                ...state,
                getBrewCrewUsers: {
                    isFetchingBrewCrewUsers: false,
                    isFetchedBrewCrewUsers: true,
                    users: payload.response
                }
            };
        case GET_STATS:
            return {
                ...state,
                getStats: {
                    isFetchingStats: true,
                    isFetchedStats: false
                }
            };
        case GET_STATS_SUCCESS:
            return {
                ...state,
                getStats: {
                    isFetchingStats: false,
                    isFetchedStats: true,
                    stats: payload.response
                }
            };
        case GET_BROKERAGE:
            return {
                ...state,
                getBrokerage: {
                    isFetchingBrokerage: true,
                    isFetchedBrokerage: false
                }
            };
        case GET_BROKERAGE_SUCCESS:
            return {
                ...state,
                getBrokerage: {
                    isFetchingBrokerage: false,
                    isFetchedBrokerage: true,
                    brokerage: payload.response
                }
            };
        case SET_SEARCH_PARAMETERS: {
            const newState = cloneDeep(state);
            set(newState, "getUsers.users.state", payload.searchParams.state);
            set(
                newState,
                "getUsers.users.startDate",
                payload.searchParams.startDate
            );
            set(
                newState,
                "getUsers.users.endDate",
                payload.searchParams.endDate
            );
            set(
                newState,
                "getUsers.users.filterBy",
                payload.searchParams.filterBy
            );
            set(
                newState,
                "getUsers.users.approved",
                payload.searchParams.approved
            );
            return newState;
        }
        default:
            return state;
    }
};

export default adminReducer;
