import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import ReactDOM from "react-dom";
import * as PropTypes from "prop-types";
import { Form } from "formik";
import findIndex from "lodash/findIndex";
import pickBy from "lodash/pickBy";
import get from "lodash/get";
import { Button } from "../../../../components/Button/Button";
import StepAboutYou from "./StepAboutYou";
import StepCreateAccount from "./StepCreateAccount";
import StepSuccessRegister from "./StepSuccessRegister";
import StepFinancial from "./StepFinancial";
import StepContact from "../../StepContactPhone/StepContact";
import StepChooseMethod from "./StepChooseMethod";

import { getStepInfoByKey } from "./WizardStepMap";

import "../../BuyerSignUp/buyer-signup-form.css";
import "./index.css";

const aboutYouStepIndex = 0;
const verifyIdStepIndex = 4;

class WizardStepsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: this.props.isAuthenticated
                ? verifyIdStepIndex
                : aboutYouStepIndex,
            startFrom: this.props.isAuthenticated
                ? verifyIdStepIndex
                : aboutYouStepIndex,
            showConfirm: false,
            isRegister: false,
            verifiedFoundingSources: false
        };
    }

    componentDidMount() {
        const {
            status: { currentStep = "" }
        } = this.props;
        if (currentStep) {
            // Initialize first step
            const step = findIndex(this.getSteps(), { key: currentStep });
            if (step > 0) this.setState({ step });
        }
        this.props.validateForm();
    }

    renderProgress() {
        const progressBarArray = this.getSteps();
        const { step } = this.state;
        const {
            status: { formState }
        } = this.props;
        let actualndex = Math.round((step * 100) / progressBarArray.length);
        return (
            <div className="buyer__signup__progress__wrap">
                <div className="buyer__signup__flow__percentage">
                    {actualndex}%
                </div>
                <div className="buyer__signup__progress buyer__flow">
                    {progressBarArray.map(
                        ({ key, label, component }, index) => {
                            const isComplete =
                                index < step || formState === "submitted";
                            const isSkipped =
                                component.skip &&
                                component.skip(this.props, this.state);
                            const className = [
                                "step",
                                isComplete
                                    ? "is-complete"
                                    : index === step
                                    ? "is-current"
                                    : isSkipped
                                    ? "is-disabled"
                                    : "",
                                progressBarArray[index] === progressBarArray[0]
                                    ? "is-first"
                                    : "",
                                progressBarArray[index] ===
                                progressBarArray[progressBarArray.length - 1]
                                    ? "is-last"
                                    : ""
                            ].join(" ");
                            return (
                                <div
                                    key={key}
                                    onClick={() => {
                                        if (isComplete && !isSkipped)
                                            this.setState({ step: index });
                                    }}
                                    className={className}
                                >
                                    <div className="step-label">{label}</div>
                                    <div className="step-point"></div>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        );
    }

    navigateStep = (delta, hasMixPanelTracking = false, mixpanel) => {
        if (hasMixPanelTracking) {
            this.mixpanelTracking(hasMixPanelTracking, mixpanel);
        }
        const step = this.getNextStep(delta);
        this.setState({ step });
    };

    getNextStep(delta = 1, step = this.state.step) {
        for (
            let next = step + delta;
            next >= 0 && next <= this.getSteps().length - 1;
            next += delta
        ) {
            const { component } = this.getSteps()[next];
            if (!(component.skip && component.skip(this.props, this.state))) {
                return next;
            }
        }
        return null;
    }

    mixpanelTracking = (hasMixPanelTracking, mixpanel, skip = false) => {
        if (hasMixPanelTracking) {
            mixpanel(skip);
        }
    };

    registerStep = () => {
        this.setState({ isRegister: true });
    };

    handleNextClick = (
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm,
        registerSubmit,
        verifiedFoundingSources
    ) => {
        if (hasNextStep && !registerSubmit && !verifiedFoundingSources) {
            this.mixpanelTracking(hasMixPanelTracking, mixpanel);
            this.navigateStep(1);
            return;
        }

        this.mixpanelTracking(hasMixPanelTracking, mixpanel);
        submitForm();
    };

    handleSkipButton = (confirmText, hasMixPanelTracking, mixpanel) => {
        this.setState({
            showConfirm: true,
            confirmText,
            hasMixPanelTracking,
            mixpanel
        });
    };

    renderStepButtons = (options, mixpanel, button) => {
        const { step } = this.state;
        const { isSubmitting, submitForm } = this.props;
        const hasNextStep = this.getNextStep() !== null;

        let canProceed;
        let registerSubmit = false;
        let successRegisterSubmit = false;
        let agreeAndContinue = false;
        let notPreviousStep = false;
        let verifyStep = false;
        let verifiedFoundingSources = false;
        let replaceButton = false;
        let hideNextButton = false;
        let canSkip = false;
        let canNavigate = true;
        let confirmText = "Are you sure you want to skip?";
        let hasMixPanelTracking = false;
        let buttonsWithoutMargin = false;
        let hideBackButton = false;
        let isLoading = false;
        let handleNext = this.handleNextClick;
        if (typeof options === "boolean") {
            canProceed = options;
        } else {
            handleNext = options.handleNext || this.handleNextClick;
            isLoading = options.isLoading || false;
            canProceed = options.canProceed || false;
            canSkip = options.canSkip || false;
            if (canSkip && canSkip.confirmText)
                confirmText = canSkip.confirmText;
            if (options.canNavigate === false) canNavigate = false;
            hasMixPanelTracking = options.hasMixPanelTracking || false;
            hideNextButton = options.hideNextButton || false;
            buttonsWithoutMargin = options.buttonsWithoutMargin || false;
            hideBackButton = options.hideBackButton || false;
            registerSubmit = options.registerSubmit || false;
            successRegisterSubmit = options.successRegisterSubmit || false;
            notPreviousStep = options.notPreviousStep || false;
            verifyStep = options.verifyStep || false;
            verifiedFoundingSources = options.verifiedFoundingSources || false;
            replaceButton = options.replaceButton || false;
            agreeAndContinue = options.agreeAndContinue || false;
        }
        const continueText = agreeAndContinue ? "Agree & Continue" : "Continue";
        const buttonText = verifyStep ? "Verify" : continueText;

        if (successRegisterSubmit) return null;

        return (
            <Fragment>
                <div
                    className={`buyer__signup__controls${
                        buttonsWithoutMargin ? "--without-margin" : ""
                    }`}
                >
                    {step < 1 || notPreviousStep || hideBackButton ? null : (
                        <Button
                            type="button"
                            disabled={!canNavigate}
                            text
                            onClick={() => this.navigateStep(-1)}
                        >
                            {`${"< Back"}`}
                        </Button>
                    )}

                    {canSkip ? (
                        <Button
                            type="button"
                            inverted
                            onClick={() =>
                                this.handleSkipButton(
                                    confirmText,
                                    hasMixPanelTracking,
                                    mixpanel
                                )
                            }
                        >
                            Skip
                        </Button>
                    ) : null}

                    {replaceButton ? (
                        button
                    ) : hideNextButton ? null : (
                        <Button
                            type="button"
                            disabled={!(canProceed && canNavigate)}
                            className={` ${
                                !(canProceed && canNavigate)
                                    ? "is-disabled"
                                    : ""
                            }`}
                            isLoading={isLoading}
                            onClick={() =>
                                handleNext(
                                    hasNextStep,
                                    hasMixPanelTracking,
                                    mixpanel,
                                    submitForm,
                                    registerSubmit,
                                    verifiedFoundingSources
                                )
                            }
                        >
                            {isSubmitting ? (
                                <i
                                    style={{ textAlign: "center", padding: 0 }}
                                    className="fas fa-spinner fa-spin"
                                />
                            ) : (
                                <Fragment>{buttonText}</Fragment>
                            )}
                        </Button>
                    )}
                </div>
            </Fragment>
        );
    };

    trackProgress = () => {
        const {
            values,
            mapValuesToPayload,
            customBudgetRange,
            notifyUpdate
        } = this.props;
        const step = this.getSteps()[this.state.step];

        if (!(mapValuesToPayload && notifyUpdate)) return;

        const data = this.props.mapValuesToPayload(values);
        const payload = pickBy(
            data,
            value =>
                !(
                    value === undefined ||
                    (typeof value === "number" && isNaN(value)) ||
                    (typeof value === "string" && value.trim().length === 0)
                )
        );

        payload.onboardingFormState = JSON.stringify({
            values,
            budget: customBudgetRange.get(),
            step: step.key
        });

        const completedStep = this.getNextStep(-1);
        const stepInfo = getStepInfoByKey(
            this.getSteps()[completedStep || 0].key
        );

        return notifyUpdate(payload, stepInfo);
    };

    renderSkipConfirmation() {
        if (!this.state.showConfirm) return null;
        const { confirmText, hasMixPanelTracking, mixpanel } = this.state;
        const skip = true;

        return ReactDOM.createPortal(
            <Fragment>
                <div
                    className="modal-blocker"
                    onClick={e => e.preventDefault()}
                />
                <div className="modal-container" style={{ height: "auto" }}>
                    <div className="modal-header">
                        Please confirm
                        <div
                            className="modal-close"
                            onClick={() =>
                                this.setState({ showConfirm: false })
                            }
                        >
                            <i className="fa fa-times-circle" />
                        </div>
                    </div>
                    <div className="modal-body-container">
                        <div className="modal-body">
                            {confirmText}
                            <div className="buyer__signup__controls align-right">
                                <Button
                                    inverted
                                    onClick={() => {
                                        this.setState({ showConfirm: false });
                                        this.navigateStep(1);
                                        this.mixpanelTracking(
                                            hasMixPanelTracking,
                                            mixpanel,
                                            skip
                                        );
                                    }}
                                >
                                    Yes, Skip
                                </Button>
                                <Button
                                    onClick={() =>
                                        this.setState({ showConfirm: false })
                                    }
                                >
                                    No, Go Back
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>,
            document.querySelector("#modal-root")
        );
    }

    getSteps = () => {
        const { values } = this.props;
        const isNotSupported = get(values, "isNotSupported");

        const emdFirstSignupSteps = [];

        const emdLastSignupSteps = [
            { key: "about_you", label: "About You", component: StepAboutYou },
            {
                key: "contact_info",
                label: "Phone Verification",
                component: StepContact
            },
            {
                key: "create_account",
                label: "Create Account",
                component: StepCreateAccount
            },
            {
                key: "success",
                label: "Check Your Inbox",
                component: StepSuccessRegister
            },
            {
                key: "verify_id",
                label: "Verify your ID",
                component: StepFinancial
            }
        ];

        if (isNotSupported) {
            return [
                ...emdFirstSignupSteps,
                {
                    key: "choose_method",
                    label: "Choose Method of Payment",
                    component: StepChooseMethod
                },
                ...emdLastSignupSteps
            ];
        }

        return [...emdFirstSignupSteps, ...emdLastSignupSteps];
    };

    render() {
        const {
            status: { formState = "initial" }
        } = this.props;

        if (formState === "submittedRegister" && !this.state.isRegister) {
            this.registerStep();
            this.navigateStep(1);
        }

        if (formState === "submittedSignUp") return <Redirect to="/profile" />;

        const StepComponent = this.getSteps()[this.state.step].component;
        if (!StepComponent) return null;

        return (
            <Fragment>
                <Form className="buyer__signup transfer buyer">
                    {this.renderProgress()}
                    {formState === "initial" ||
                    formState === "submittedRegister" ||
                    formState === "submittingRegister" ? (
                        <StepComponent
                            {...this.props}
                            isBuyerFlow={true}
                            onNavigateStep={this.navigateStep}
                            onTrackProgress={this.trackProgress}
                            onSkipStep={options =>
                                this.setState({
                                    ...options,
                                    showConfirm: true
                                })
                            }
                            renderNavigation={this.renderStepButtons}
                        />
                    ) : null}
                </Form>
                {this.renderSkipConfirmation()}
            </Fragment>
        );
    }
}

WizardStepsForm.propTypes = {
    status: PropTypes.object,
    userProfile: PropTypes.object,
    userInfo: PropTypes.object,
    isSubmitting: PropTypes.bool,
    submitForm: PropTypes.func,
    validateForm: PropTypes.func,
    mapValuesToPayload: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    voaUrl: PropTypes.string,
    updateProspect: PropTypes.func,
    prospect: PropTypes.object,
    onWireInstructions: PropTypes.func,
    isFetchingWireInstructions: PropTypes.bool,
    successWireInstructions: PropTypes.bool,
    transactionContactTypes: PropTypes.any,
    onSendCertifiedInfo: PropTypes.func,
    successSendCertifiedInfo: PropTypes.bool,
    isFetchingSendCertifiedInfo: PropTypes.bool
};

export default WizardStepsForm;
