import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';

import './Finicity.css';

class FinicityFrame extends Component {
    static propTypes = {
        url: PropTypes.string,
        onComplete: PropTypes.func.isRequired,
        mixpanelTrack: PropTypes.bool,
    };

    componentDidMount() {
        if(this.props.mixpanelTrack) {
            mixpanel.track(
                "Started Financial Verification"
            );
        }
    }

    componentWillUnmount() {
        if(this.props.mixpanelTrack) {
            mixpanel.track(
                "Completed Financial Verification"
            );
        }
    }

    UNSAFE_componentWillUpdate = (newProps) => {
        const {url} = newProps;
        
        if (!url) return;
        if (this.isFinicityAlreadyOpen) return;

        this.isFinicityAlreadyOpen = true;

        window.finicityConnect.destroyIFrame();
        window.finicityConnect.connectIFrame(url, {
            selector: '#connect-container',
            overlay: 'rgba(255, 255, 255, 1)',
            /*
            
            These are the events available in Finicity
            Keep in mind `success` is not being called when the user finishes selecting
            the bank accounts
            More info: https://community.finicity.com/s/article/Embedded-Finicity-Connect-Web-Based

            success: function(data) {
                console.log('Success', data);
            },
            cancel: function() {
                console.log('The user cancelled the iframe');
            },
            error: function(err) {
                console.error('Some runtime error was generated during Finicity Connect', err);
            },
            loaded: function() {
                console.log('This gets called only once after the iframe has finished loading');
            },
            */
            route: function(event) {
                const eventType = get(event, 'data.screen');
                const successfullyCompleted = true;
                const failedToComplete = false;

                switch (eventType) {
                    case 'Done':
                        newProps.onComplete(successfullyCompleted);
                        break;
                    case 'Cancel':
                        newProps.onComplete(failedToComplete);
                        break;
                    default:
                        break;
                }
            }
        });
    }

    render = () => <div id="connect-container" />
}

export default FinicityFrame;
