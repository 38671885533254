import React, { Fragment } from 'react';
import BaseWizardStep from './BaseWizardStep';
import EmdSignUpSuccess from '../EmdSignUpSuccess/EmdSignUpSuccess';


class StepSuccessRegister extends BaseWizardStep {
    render() {
        const success = EmdSignUpSuccess();
        return (
            <Fragment>
                {success}
                { this.renderStepButtons({
                    successRegisterSubmit: true,
                })}
            </Fragment>
        );
    }
}

export default StepSuccessRegister;
