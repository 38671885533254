import * as APIUtil from '../../util/service_api';
import * as configActionTypes from './ConfigActionTypes';


export const fetchConfig = () => dispatch => {
    dispatch(requestConfig());

    APIUtil.getConfig()
        .then((response) => {
            dispatch(successRequestConfig(response.data));
        })
        .catch((response) =>{
            dispatch(failureRequestConfig(response.error));
        });
};

export const requestConfig = () => ({
    type: configActionTypes.CONFIG_LIST_REQUESTED
});

export const successRequestConfig = (response) => ({
    type: configActionTypes.CONFIG_LIST_SUCCESS,
    payload: response
});

export const failureRequestConfig = (response) => ({
    type: configActionTypes.CONFIG_LIST_FAILURE,
    payload: response
});
