import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { format } from 'date-fns';
import { Modal } from 'react-bootstrap';
import { Button } from '../../Button/Button';

import './ProspectUploadedFilesPreviewModal.css';
export default class ProspectUploadedFilesPreviewModal extends React.Component {
    static propTypes = {
        isFetchingProspectDocuments: PropTypes.bool,
        isVisible: PropTypes.bool,
        onClose: PropTypes.func,
        dateFormat: PropTypes.string,
    };

    buildHeadings = () => (
        <div className="uploaded-files-heading-row">
            <div className="uploaded-files-heading-row-item">Preview</div>
            <div className="uploaded-files-heading-row-item">Date</div>
            <div className="uploaded-files-heading-row-item">Doc type</div>
        </div>
    )

    getItems = () => {
        const docs = this.props.documents;

        const items = map(docs, item => {
            return (
                <Fragment>
                    <div className="uploaded-files-row">
                        <div className="uploaded-files-row-item">
                            <img key={item.name} src={`data:image/png;base64, ${item.base64content}`} alt={item.name} width="100%" height="100%" />
                        </div>
                        <div className="uploaded-files-row-item">
                            <div className="uploaded-files-row-item--mobile">Date</div>
                            {format(item.ts, 'MM.DD.YYYY, HH:mm, A')}
                        </div>
                        <div className="uploaded-files-row-item">
                            <div className="uploaded-files-row-item--mobile">Doc type</div>
                            {item.documentTypeName}
                        </div>
                    </div>
                </Fragment>
            )
        });

        if (size(items) !== 0 && !this.props.isFetchingProspectDocuments) {
            return <Fragment>{this.buildHeadings()}{items}</Fragment>
        } else if (size(items) === 0 && !this.props.isFetchingProspectDocuments) {
            return <div className="loader-no-files">No uploaded files.</div>;
        }
    }

    render = () => {
        return (
            <Modal
                show={this.props.isVisible}
                onHide={this.props.onClose}
                className="modal-file-download">
                <Modal.Header>
                    <Button
                        icon
                        dark
                        symbol="times"
                        onClick={this.props.onClose}
                    >
                    </Button>
                    <h2>Prospect Uploads</h2>
                </Modal.Header>
                <Modal.Body>
                    <p className="loader-plh">
                        {this.props.isFetchingProspectDocuments ?
                            <div className="loader">Loading...</div>
                            : null}
                    </p>
                    <div>{this.getItems()}</div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-uploaded-files-controlls">
                        <Button inverted onClick={this.props.onClose}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}


