import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Button } from "../../../Button/Button";
import { PhoneField } from "../../../Form";
import {
    checkVerification,
    sendVerification
} from "../../../../util/service_api";

import "./phoneVerification.css";

const sanitizePhone = s => `+1${s.replace(/\D/g, "")}`;
const VERIFICATION_STATE_INITIAL = "";
const VERIFICATION_STATE_REQUESTING = "REQUESTING";
const VERIFICATION_STATE_SENT = "SENT";
const VERIFICATION_STATE_CHECKING = "CHECKING";
const VERIFICATION_STATE_VALID = "VALID";
const VERIFICATION_STATE_INVALID = "INVALID";

export default class PhoneVerification extends React.Component {
    setCheckState = state =>
        this.props.setFieldValue(this.props.phoneCheckStateName, state);

    getCheckState = () => this.props.values.phoneCheckState;

    handleRequestVerification = () => {
        const { phone } = this.props.values;

        this.setCheckState(VERIFICATION_STATE_REQUESTING);

        sendVerification({ phone: sanitizePhone(phone) })
            .then(() => this.setCheckState(VERIFICATION_STATE_SENT))
            .catch(() => this.setCheckState(VERIFICATION_STATE_INVALID));
    };

    renderPhoneForm = () => {
        const {
            errors,
            values: { phone },
            fieldPhoneName
        } = this.props;
        const checkState = this.getCheckState();

        const showLoadingState = checkState === VERIFICATION_STATE_REQUESTING;
        const isPhoneValid = !errors.phone;

        if (
            checkState === VERIFICATION_STATE_INITIAL ||
            checkState === VERIFICATION_STATE_REQUESTING
        ) {
            return (
                <div className="row phone-verification__row">
                    <div className="phone-verification__center">
                        <div className="phone-verification__phone">
                            <div className="phone-verification__field">
                                <Field
                                    type="text"
                                    name={fieldPhoneName}
                                    placeholder="PHONE NUMBER"
                                    disabled={showLoadingState}
                                    component={PhoneField}
                                />
                                {/* {this.props.renderFieldError(fieldPhoneName)} */}
                            </div>
                            <div className="phone-verification__message">
                                <small>
                                    Standard text messaging rates apply.
                                </small>
                            </div>
                        </div>

                        <div className="phone-verification__button">
                            <Button
                                type="button"
                                loadingText="Requesting"
                                disabled={showLoadingState || !isPhoneValid}
                                isLoading={showLoadingState}
                                onClick={this.handleRequestVerification}
                            >
                                Send Verification
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }

        const message =
            checkState === VERIFICATION_STATE_VALID ? (
                <>
                    The phone <b>{phone}</b> has been verified!
                </>
            ) : checkState === VERIFICATION_STATE_INVALID ? (
                <>
                    The phone <b>{phone}</b> could not be verified.
                </>
            ) : (
                <>
                    Verifying phone: <b>{phone}</b>
                </>
            );

        return (
            <div className="row">
                <div className="phone-verification__message-center align-center">
                    {message}
                </div>
            </div>
        );
    };

    handleVerifyCode = () => {
        const { phone, phoneCode: code } = this.props.values;

        this.setCheckState(VERIFICATION_STATE_CHECKING);
        checkVerification({ phone: sanitizePhone(phone), code })
            .then(({ data }) => (data === true ? data : Promise.reject()))
            .then(() => this.setCheckState(VERIFICATION_STATE_VALID))
            .catch(() => this.setCheckState(VERIFICATION_STATE_INVALID));
    };

    renderVerification = () => {
        const { errors, fieldPhoneCodeName } = this.props;

        if (
            !(
                this.getCheckState() === VERIFICATION_STATE_SENT ||
                this.getCheckState() === VERIFICATION_STATE_CHECKING
            )
        )
            return null;

        const showLoadingState =
            this.getCheckState() === VERIFICATION_STATE_CHECKING;
        const isCodeValid = !errors.phoneCode;

        return (
            <div
                className="row phone-verification__row"
                style={{ marginLeft: "8px" }}
            >
                <div className="phone-verification__center">
                    <div className="phone-verification__phone-code">
                        <div className="phone-verification__field">
                            <Field
                                type="text"
                                name={fieldPhoneCodeName}
                                placeholder="VERIFICATION CODE"
                                disabled={showLoadingState}
                            />
                            {this.props.renderFieldError(fieldPhoneCodeName)}
                        </div>
                    </div>

                    <div className="phone-verification__button">
                        <Button
                            type="button"
                            loadingText="Verifying"
                            disabled={showLoadingState || !isCodeValid}
                            isLoading={showLoadingState}
                            onClick={this.handleVerifyCode}
                        >
                            Verify Code
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    handleRetry = () => {
        const {
            setFieldValue,
            setFieldTouched,
            fieldPhoneName,
            fieldPhoneCodeName,
            phoneCheckStateName
        } = this.props;

        setFieldValue(fieldPhoneName, "");
        setFieldTouched(fieldPhoneName, false);
        setFieldValue(fieldPhoneCodeName, "");
        setFieldTouched(fieldPhoneCodeName, false);
        setFieldValue(phoneCheckStateName, "");
    };

    renderInvalid = () => {
        if (this.getCheckState() !== VERIFICATION_STATE_INVALID) return null;
        return (
            <div className="row">
                <div className="phone-verification__button-center align-center">
                    <Button type="button" onClick={this.handleRetry}>
                        Retry
                    </Button>
                </div>
            </div>
        );
    };

    render = () => (
        <>
            {this.renderPhoneForm()}
            {this.renderVerification()}
            {this.renderInvalid()}
        </>
    );
}

PhoneVerification.defaultProps = {
    fieldPhoneName: "phone",
    fieldPhoneCodeName: "phoneCode",
    phoneCheckStateName: "phoneCheckState"
};

PhoneVerification.propTypes = {
    errors: PropTypes.object,
    values: PropTypes.object,
    renderFieldError: PropTypes.func,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    fieldPhoneName: PropTypes.string,
    fieldPhoneCodeName: PropTypes.string,
    phoneCheckStateName: PropTypes.string
};
