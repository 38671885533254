import React from "react";
import CustomRealtorAutoCompleteField from "../../CustomRealtorAutoCompleteField/CustomRealtorAutoCompleteField";

import "./designations.scss";

const Designations = props => {
    return (
        <div className="designations__container">
            <div className="level__one__text">
                Do you have any professional designations?
            </div>
            <CustomRealtorAutoCompleteField
                fieldProp="designations"
                labelProp="name"
                autoCompleteUri="autocomplete/designation"
                placeholder="Select your designations (You can select more than one)"
                value={props.value}
                isMulti
                onEdit={props.onEdit}
                allowRepeatedValues={false}
                allowSelectingWithCheckbox
            />
        </div>
    );
};

export default Designations;
