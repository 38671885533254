import { withFormik } from 'formik';
import * as Yup from 'yup';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import ResetPasswordForm from './ResetPasswordForm';
import { requestResetPassword } from '../../util/service_api';

export default compose(
    withRouter,
    withFormik({
        mapPropsToValues: () => ({
            email: '',
            accepted: '',
            failed: '',
        }),

        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email()
                .required(),
        }),

        handleSubmit(values, { setSubmitting, setValues, setFieldValue }) {
            const { email } = values;

            requestResetPassword({ email })
                .then((response) => {
                    setFieldValue('accepted', response.message);
                })
                .catch((error) => {
                    setValues({
                        email: '',
                    });
                    setFieldValue('failed', error.response.data.message);
                })
                .finally(() => setSubmitting(false));
        },
    }),
)(ResetPasswordForm);
