import { withFormik } from 'formik';
import * as Yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SignInFormOnboardWrapper from './SignInFormOnboardWrapper';
import { loginUser, signupUser } from '../../reducers/user/UserActions';

const SignInFormValidator = compose(
    withRouter,
    withFormik({
        mapPropsToValues: () => ({
            username: '',
            password: '',
        }),

        validationSchema: Yup.object().shape({
            username: Yup.string().trim(),
            password: Yup.string().trim(),
        }),

        handleSubmit(
            { username, password },
            {
                props: { loginUser },
                setSubmitting,
                resetForm,
            },
        ) {
            loginUser({ username, password })
                .catch(() => resetForm())
                .finally(() => setSubmitting(false));
        },
    }),
)(SignInFormOnboardWrapper);

const mapStateToProps = (state) => ({
    authorization: state.user.authorization,
    userProfile: state.user.profile,
    loginError: state.user.errors,
    isFetchingSignUpUser: state.user.isFetchingSignUpUser,
    isFetchedSignUpUser: state.user.isFetchedSignUpUser,
    errorSignUpMessage: state.user.errorSignUpMessage,
});

const mapDispatchToProps = (dispatch) => ({
    consoleDispatch: () => {
        console.log('dispatch', dispatch);
    },
    loginUser: (formData) => dispatch(loginUser(formData)),
    signupUser: (token) => dispatch(signupUser(token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignInFormValidator);
