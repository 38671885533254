import React from 'react';
import { withRouter } from 'react-router-dom';
import CreateNewPasswordContainer from './CreateNewPasswordContainer';
import {Helmet} from 'react-helmet';

const SignIn = () => (
    <div className="signin__container">
        <Helmet>
            <body className="bg-emphasis" />
        </Helmet>
        <CreateNewPasswordContainer />
    </div>
);

export default withRouter(SignIn);
