import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter'

import IconBudget from '../../styles/assets/icon-outline-Budget.svg';
import IconCalendar from '../../styles/assets/icon-outline-Calendar.svg';
import IconHouse from '../../styles/assets/icon-outline-House.svg';
import IconMapPin from '../../styles/assets/icon-outline-MapPin.svg';
import IconMoney from '../../styles/assets/icon-outline-Money.svg';
import IconLoan from '../../styles/assets/icon-outline-Loan.svg';
import IconMortgage from '../../styles/assets/icon-outline-Mortgage.svg';
import IconPayment from '../../styles/assets/icon-outline-Payment.svg';

import './realtorMatchSummary.css';

const icon = {
    IconBudget,
    IconCalendar,
    IconHouse,
    IconMapPin,
    IconMoney,
    IconLoan,
    IconMortgage,
    IconPayment,
};

export default class RealtorMatchSummary extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
    };

    getNonEmptyChildren = () => filter(
        this.props.children,
        (children) => children != null && children.props != null
    );

    buildItems = () => React.Children.map(
        this.getNonEmptyChildren(),
        (item, key) => (
            React.cloneElement(item, { key, icon: icon[item.props.icon] })
        ),
    );

    render = () => (
        <div className="realtor-profile-summary">
            <div className="realtor-profile-summary__section-title">
                Purchase Summary
            </div>
            <div className="realtor-profile-summary__content">
                {this.buildItems()}
            </div>
        </div>
    );
}
