import React from "react";
import CustomRealtorBiographyField from "../../CustomRealtorBiographyField/CustomRealtorBiographyField";

import "./biography.scss";

const Biography = props => {
    return (
        <div className="biography__container">
            <div className="level__one__text">
                Tell us a little more about you
            </div>
            <div className="level__two__text">
                Feel free to share a personal story or anything about who you
                are.
            </div>
            <CustomRealtorBiographyField
                onEdit={props.onEdit}
                biography={props.biography}
            />
            <div className="level__three__text">
                <div>Optional</div>
            </div>
        </div>
    );
};

export default Biography;
