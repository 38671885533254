import { toast } from 'react-toastify';
import * as APIUtil from '../../util/service_api';
import {
    CREATE_NEW_PRIZE_EVENT_FETCH,
    CREATE_NEW_PRIZE_EVENT_SUCCESS,
    CREATE_NEW_PRIZE_EVENT_ERROR,
    GET_WHEEL_ACTIVE_LIST_FETCH,
    GET_WHEEL_ACTIVE_LIST_SUCCESS,
    GET_WHEEL_ACTIVE_LIST_ERROR,
    GET_WHEEL_SPECIFIC_EVENT_FETCH,
    GET_WHEEL_SPECIFIC_EVENT_SUCCESS,
    GET_WHEEL_SPECIFIC_EVENT_ERROR,
    SPIN_WHEEL_FETCH,
    SPIN_WHEEL_SUCCESS,
    SPIN_WHEEL_ERROR,
} from './SpinActionTypes';

export const fetchCreateNewEvent = () => ({
    type: CREATE_NEW_PRIZE_EVENT_FETCH,
});

export const successCreateNewEvent = (response) => ({
    type: CREATE_NEW_PRIZE_EVENT_SUCCESS,
    payload: { response },
});

export const errorCreateNewEvent = (error) => ({
    type: CREATE_NEW_PRIZE_EVENT_ERROR,
    payload: { error },
});

export const createNewEvent = (data) => (dispatch) => {
    const notification = toast.info('Creating Inman Wheel...', { autoClose: false });
    const successNotification = (id) => ({
        render: `Inman wheel created with ID: ${id}`,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
    });

    const errorNotification = {
        render: 'Could not create Inman Wheel, please try again in a few minutes',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
    };

    dispatch(fetchCreateNewEvent());
    APIUtil.createNewPrizeEvent(data)
        .then((response) => {
            dispatch(successCreateNewEvent(response));
            toast.update(notification, successNotification(response.id));
        })
        .catch((error) => {
            dispatch(errorCreateNewEvent(error));
            toast.update(notification, errorNotification);
        });
};

export const fetchGetWheelActiveList = () => ({
    type: GET_WHEEL_ACTIVE_LIST_FETCH,
});

export const successGetWheelActiveList = (response) => ({
    type: GET_WHEEL_ACTIVE_LIST_SUCCESS,
    payload: { response },
});

export const errorGetWheelActiveList = (error) => ({
    type: GET_WHEEL_ACTIVE_LIST_ERROR,
    payload: { error },
});

export const getBrewWheelActiveList = (userEmail) => (dispatch) => {
    dispatch(fetchGetWheelActiveList());
    APIUtil.getBrewWheelActiveList(userEmail)
        .then((response) => dispatch(successGetWheelActiveList(response)))
        .catch((error) => dispatch(errorGetWheelActiveList(error)));
};

export const fetchGetWheelSpecificEvent = () => ({
    type: GET_WHEEL_SPECIFIC_EVENT_FETCH,
});

export const successGetWheelSpecificEvent = (response) => ({
    type: GET_WHEEL_SPECIFIC_EVENT_SUCCESS,
    payload: { response },
});

export const errorGetWheelSpecificEvent = (error) => ({
    type: GET_WHEEL_SPECIFIC_EVENT_ERROR,
    payload: { error },
});

export const getBrewWheelSpecificEvent = (id) => (dispatch) => {
    dispatch(fetchGetWheelSpecificEvent());
    APIUtil.getBrewWheelSpecificEvent(id)
        .then((response) => dispatch(
            successGetWheelSpecificEvent(response),
        ))
        .catch((error) => dispatch(errorGetWheelSpecificEvent(error)));
};

export const fetchSpinWheel = () => ({
    type: SPIN_WHEEL_FETCH,
});

export const successSpinWheel = (response) => ({
    type: SPIN_WHEEL_SUCCESS,
    payload: { response },
});

export const errorSpinWheel = (error) => ({
    type: SPIN_WHEEL_ERROR,
    payload: { error },
});

export const spinWheel = (id, userEmail) => (dispatch) => {
    dispatch(fetchSpinWheel());
    APIUtil.spinWheel(id, userEmail)
        .then((response) => dispatch(successSpinWheel(response)))
        .catch((error) => dispatch(errorSpinWheel(error)));
};
