import { connect } from "react-redux";
import map from "lodash/map";
import { withFormik } from "formik";
import get from "lodash/get";
import {
    bool as YupBool,
    object as YupObject,
    ref as YupRef,
    string as YupString
} from "yup";
import axios from "axios";
import { prospectSetFundingSourcesV2 } from "../../../../util/service_api";
import APIDomain from "../../../../shared/apiSettings";
import { adaptStreetAddress, EmptyAddress } from "../../BuyerSignUp/Shared";
import {
    YupDobAdultRequired,
    YupPassword,
    YupStreetAddress,
    YupUniqueEmail,
    YupUsPhoneNumber,
    YupTwilioVerificationCode
} from "../../../../shared/validations";
import {
    fetchVoaProspectUrl,
    updateProspect,
    sendWireInstructions,
    sendCertifiedInfo
} from "../../../../reducers/prospect/prospectActions";
import WizardStepsForm from "./WizardStepsForm";
import { loadUserProfile } from "../../../../reducers/user/UserActions";
import mixpanel from "mixpanel-browser";

const mapValuesToPayload = values => {
    const {
        ssn,
        birthday: { day: birthDayOfMonth, month: birthMonth, year: birthYear }
    } = values;

    return {
        ssn,
        birthDayOfMonth,
        birthMonth,
        birthYear
    };
};

const WizardStepsContainer = withFormik({
    mapPropsToStatus: () => ({
        formState: "initial"
    }),

    mapPropsToValues: () => ({
        financialInstitution: undefined,
        isNotSupported: false,
        notSupportedMessage: "",
        id: null,
        firstName: "",
        lastName: "",
        addressDetails: { ...EmptyAddress },
        email: "",
        phone: "",
        phoneCode: "",
        phoneCheckState: "",
        password: "",
        password2: "",
        acceptedTermsAndConditions: false,
        acceptedVoaTermsAndConditions: false,
        ssn: "",
        birthday: { day: "", month: "", year: "" }
    }),

    validationSchema: props => {
        if (!props.isAuthenticated) {
            return YupObject().shape({
                firstName: YupString()
                    .trim()
                    .required("First Name is required"),

                lastName: YupString()
                    .trim()
                    .required("Last Name is required"),

                addressDetails: YupStreetAddress(),

                email: YupUniqueEmail("ROLE_PROSPECT").required(
                    "Email is required"
                ),

                phone: YupUsPhoneNumber().required("Phone is required"),

                phoneCode: YupTwilioVerificationCode().required(
                    "Verification code is required"
                ),

                phoneCheckState: YupString(),

                password: YupPassword()
                    .trim()
                    .label("Password")
                    .meta({ mustNotMatch: { path: "email", label: "Email" } })
                    .required("Password is required"),

                password2: YupString()
                    .trim()
                    .oneOf([YupRef("password"), null], "Passwords don't match")
                    .required("Confirm Password is required"),

                acceptedTermsAndConditions: YupBool().oneOf(
                    [true],
                    "You must Accept Terms and Conditions to continue"
                )
            });
        }
        return YupObject().shape({
            ssn: YupString()
                .trim()
                .test(
                    "ssnLength",
                    "Social security number must have 9 digits",
                    val => String(val || "").length === 9
                )
                .required("Social security number is required"),

            birthday: YupDobAdultRequired()
        });
    },

    // Submission handler
    handleSubmit(values, { props, setStatus, setSubmitting, setError }) {
        const { isAuthenticated } = props;
        if (!isAuthenticated) {
            setSubmitting(true);
            setStatus({ formState: "submittingRegister" });
            const userData = {
                firstName: values.firstName,
                lastName: values.lastName,
                prospectCurrentAddress: adaptStreetAddress(
                    values.addressDetails
                ),
                email: values.email,
                phone: values.phone,
                password: values.password,
                acceptedTermsAndConditions: values.acceptedTermsAndConditions
            };

            axios({
                method: "POST",
                url: `${APIDomain}/onboarding/prospect`,
                headers: {
                    Accept: "application/json",
                    "X-Requested-With": "XMLHttpRequest"
                },
                data: userData,
                withCredentials: true
            })
                .then(response => {
                    if (response.status === 200) {
                        setStatus({ formState: "submittedRegister" });
                    }
                    mixpanel.alias(get(values, "email"));
                    mixpanel.track("Finished Onboarding", {
                        firstName: get(values, "firstName"),
                        lastName: get(values, "lastName")
                    });
                    return response;
                })
                .catch(error => {
                    setError({ error });
                })
                .finally(() => setSubmitting(false));
        } else {
            setSubmitting(true);

            const plaidToken = get(values, "plaidResponse.token");
            const institution = get(
                values,
                "plaidResponse.metadata.institution"
            );
            const accounts = get(values, "plaidResponse.metadata.accounts");
            const accountIds = map(accounts, "id");

            const plaidPayload = {
                institution: {
                    name: get(institution, "name"),
                    fkInstitutionId: get(institution, "institution_id")
                },
                accountIds
            };
            prospectSetFundingSourcesV2(plaidToken, plaidPayload)
                .then(props.loadUserProfile)
                .then(() => {
                    setStatus({ formState: "submittedSignUp" });
                })
                .finally(() => setSubmitting(false));
        }
    }
})(WizardStepsForm);

const getTransactionContactTypes = state =>
    get(state.configApp, "config.transactionContactTypes.Other");

const mapStateToProps = state => ({
    mapValuesToPayload,
    licensedStates: state.configApp.config
        ? state.configApp.config.licensedStates
        : [],
    licensedStatesAbbr: state.configApp.config
        ? map(state.configApp.config.licensedStates, "abbreviation")
        : [],
    isAuthenticated: !!state.user.authorization,
    voaUrl: state.prospect.voaUrl,
    consumerId: state.prospect.consumerId,
    prospect: state.prospect,
    isFetchingWireInstructions: state.prospect.isFetchingWireInstructions,
    successWireInstructions: state.prospect.successWireInstructions,
    transactionContactTypes: getTransactionContactTypes(state),
    successSendCertifiedInfo: state.prospect.successSendCertifiedInfo,
    isFetchingSendCertifiedInfo: state.prospect.isFetchingSendCertifiedInfo
});

const mapDispatchToProps = dispatch => ({
    fetchVoaUrl: () => dispatch(fetchVoaProspectUrl()),
    loadUserProfile: () => dispatch(loadUserProfile()),
    updateProspect: payload => dispatch(updateProspect(payload)),
    onWireInstructions: data => dispatch(sendWireInstructions(data)),
    onSendCertifiedInfo: data => dispatch(sendCertifiedInfo(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardStepsContainer);
