import React, { Fragment } from "react";
import PropType from "prop-types";
import calendar from "../../../../styles/assets/schedule_appt_calendar.svg";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";
import Button from "components/Button/Button";

export default class StepInbox extends React.PureComponent {
    static propTypes = {
        calendlyUrl: PropType.string
    };

    static defaultProps = {
        calendlyUrl: "https://calendly.com/leads-22"
    };

    buildCalendlyLink = () => (
        <Button onClick={() => window.open(this.props.calendlyUrl, "_blank")}>
            Schedule appointment
        </Button>
    );

    render = () => (
        <Fragment>
            <ScrollToTopOnMount />
            <div className="buyer__signup__step__bcg"></div>
            <div className="buyer__signup__step buyer--flow">
                <div className="buyer__signup__question buyer__signup__inbox-title">
                    Schedule your appointment
                </div>

                <div className="buyer__signup__fieldset">
                    <div className="buyer__signup__field__label align-center is-big">
                        Thank you! Our concierge team will be in touch shortly.
                    </div>
                    <div className="buyer__signup__inbox-calendar-container schedule-wrapper">
                        <img
                            className="buyer__signup__inbox-calendar-icon"
                            src={calendar}
                            alt="calendar"
                        />
                        <div className="buyer__signup__inbox-calendar-content">
                            <h4 className="buyer__signup__inbox-calendar-title">
                                What's next?
                            </h4>
                            <p>
                                Schedule an appointment with one of our
                                representatives to start your process!
                            </p>
                            {this.buildCalendlyLink()}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
