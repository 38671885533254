import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import UserRealtorProfile from '../UserProfile/UserRealtorProfile';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { showGlobalLoader, hideGlobalLoader } from '../../reducers/global-loader/GlobalLoaderActions';
import { loadRealtor } from '../../util/service_api';
import { receiveUserProfile } from '../../reducers/user/UserActions';

class RealtorPublicProfileComponent extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        match: PropTypes.any,
        onFetchProfile: PropTypes.func.isRequired,
        onUpdateProfile: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { profile: null };
    }

    componentDidMount = () => {
        const {
            onFetchProfile,
            match,
        } = this.props;

        const id = get(match, 'params.id');

        Promise.resolve()
            .then(() => onFetchProfile(id))
            .then((profile) => this.setState({
                profile: { publicProfile: profile },
            }));
    };

    isNotRealtorChosen = () => isEmpty(get(this.props.user, 'profile.realtor'));

    updateField = (profile) => {
        if (isFunction(this.props.onUpdateProfile)) {
            this.props.onUpdateProfile(profile);
        }
    };

    onUpdateAgent = (newAgent) => {
        const { user: { profile } } = this.props;
        this.updateField({ ...profile, realtor: newAgent });
    };

    render = () => {
        const { profile } = this.state;
        if (!profile) {
            return <SpinnerLoader />;
        }

        return (
            <UserRealtorProfile
                isPublicView
                showAcceptButton={this.isNotRealtorChosen()}
                profile={profile}
                onChooseAgent={this.onUpdateAgent}
            />
        );
    };
}

const RealtorPublicProfile = connect(
    (state) => ({ user: state.user }),
    {
        onLoadProfile: showGlobalLoader,
        onFetchProfile: (id) => () => loadRealtor(id),
        onFetchedProfile: hideGlobalLoader,
        onUpdateProfile: receiveUserProfile,
    },
)(RealtorPublicProfileComponent);

export default RealtorPublicProfile;
