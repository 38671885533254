import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import invoke from 'lodash/invoke';
import ProgressItem from './ProgressItem';

import './progress.css';

export default class Progress extends React.PureComponent {
    static propTypes = {
        onNavigate: PropTypes.func,
        currentStep: PropTypes.number,
        steps: PropTypes.array,
    };

    handleClick = (step, index) => {
        invoke(step, 'onNavigate');
        invoke(this.props, 'onNavigate', step, index);
    };

    buildProgressItem = (step, index) => (
        <ProgressItem
            key={index}
            {...step}
            isCurrentStep={index === this.props.currentStep}
            isSkipped={index < this.props.currentStep && get(step, 'isSkipped')}
            isCompleted={index < this.props.currentStep && get(step, 'isCompleted')}
            onClick={() => this.handleClick(step, index)}
        />
    );

    buildProgressItemIfVisible = (step, index) => (
        get(step, 'hideInProgressHeader') ?
        null :
        this.buildProgressItem(step, index)
    );

    render = () => (
        <header className="wizard-progress-header">
            {map(this.props.steps, this.buildProgressItemIfVisible)}
        </header>
    );
}
