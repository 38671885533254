import React from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

class ScrollToTopOnPageChange extends React.Component {
  componentDidUpdate = (prevProps) => {
    const prevPage = get(prevProps, 'location.pathname');
    const currentPage = get(this.props, 'location.pathname');
    const hasChangedPage = currentPage !== prevPage;

    if (hasChangedPage) {
        window.scrollTo(0, 0);
    }
  };

  render = () => null;
}

export default withRouter(ScrollToTopOnPageChange);
