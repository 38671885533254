import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '../../components/Button/Button';
import './closemodalbutton.css';

export default function CloseModalButton(props) {
    return (
        <Button
            {...props}
            icon 
            dark 
            symbol="times"
            type="button"
        >
            <span className="text--label">{props.label}</span>
        </Button>
    );
}

CloseModalButton.propTypes = {
    label: PropTypes.node,
};
