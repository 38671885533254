import ReactPixel from 'react-facebook-pixel';

const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL;

export default {
    init: () => ReactPixel.init(FACEBOOK_PIXEL_ID, undefined, {
        autoConfig: true,
        debug: process.env.NODE_ENV === 'development'
    }),

    trackView: () => ReactPixel.track('ViewContent'),

    trackLead: (data = {}) => ReactPixel.track('Lead', data),

    trackSignupComplete: (data = {}) => ReactPixel.track('CompleteRegistration', data)
}
