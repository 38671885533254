import get from "lodash/get";
import trim from "lodash/trim";
import React, { Fragment } from "react";
import { NumberFormat } from "../../NumberFormat/NumberFormat";
import ProfileSummaryItem from "./ProfileSummaryItem";
import ProfileTabBase from "./ProfileTabBase";

import IconMapPin from "../../../styles/assets/icon-outline-MapPin.svg";
import IconHouse from "../../../styles/assets/icon-outline-House.svg";
import IconBudget from "../../../styles/assets/icon-outline-Budget.svg";
import IconMoney from "../../../styles/assets/icon-outline-Money.svg";
import IconPayment from "../../../styles/assets/icon-outline-Payment.svg";
import IconLoan from "../../../styles/assets/icon-outline-Loan.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IconMortgage from "../../../styles/assets/icon-outline-Mortgage.svg";
import IconTaxes from "../../../styles/assets/icon-outline-Taxes.svg";
import IconTotal from "../../../styles/assets/icon-outline-Total.svg";
import IconCalendar from "../../../styles/assets/icon-outline-Calendar.svg";

const paymentBreakdownFields = [
    { key: "mortgage", label: "Estimated Mortgage Payment:" },
    { key: "monthlyTaxes", label: "Estimated Monthly Property Taxes:" },
    { key: "approximateHoiAmount", label: "Estimated Home Owners Insurance:" },
    { key: "approximateHoaAmount", label: "Estimated HOA Dues:" }
];

export default class PurchaseSummaryTab extends ProfileTabBase {
    state = {
        isShowingDetails: false
    };

    toggleShowDetails = () =>
        this.setState(state => ({
            isShowingDetails: !state.isShowingDetails
        }));

    buildLocationSummary = () => {
        const hasPickedOutNewHome = this.getProfileProperty(
            "hasPickedOutNewHome"
        );
        const newAddresses = this.getProfileProperty("newAddresses", []);
        const location = hasPickedOutNewHome
            ? [
                  trim(get(newAddresses, "[0].streetNumberName")),
                  trim(get(newAddresses, "[0].apartmentSuiteNumber")),
                  trim(get(newAddresses, "[0].city")),
                  trim(get(newAddresses, "[0].state")),
                  trim(get(newAddresses, "[0].zipcode"))
              ].join(" ")
            : newAddresses
                  .map(address => {
                      const city = trim(get(address, "city"));
                      const state = trim(get(address, "state"));
                      return `${city} ${state}`;
                  })
                  .join(", ");

        return (
            <ProfileSummaryItem
                icon={IconMapPin}
                label="Location"
                value={location}
            />
        );
    };

    buildPropertyTypeSummary = () => (
        <ProfileSummaryItem
            icon={IconHouse}
            label="Property Type:"
            value={this.getProfileValue("homeType.displayName")}
        />
    );

    buildBudgetSummary = () => {
        const minBudget = this.getProfileProperty("budgetMin", {});
        const maxBudget = this.getProfileProperty("budgetMax", {});

        const budget = (
            <span>
                <NumberFormat
                    currency={minBudget.currency}
                    number={minBudget.amount}
                    format="currency"
                    decimals={0}
                />
                {" to "}
                <NumberFormat
                    currency={maxBudget.currency}
                    number={maxBudget.amount}
                    format="currency"
                    decimals={0}
                />
            </span>
        );

        return (
            <ProfileSummaryItem
                icon={IconBudget}
                label="Budget:"
                value={budget}
            />
        );
    };

    buildFormattedAmountSummary = (path, icon, label, skipIfZero = false) => {
        const value = this.getProfileProperty(path, {});
        if (skipIfZero && !value.amount) return null;

        const formattedAmount = (
            <NumberFormat
                currency={value.currency}
                number={value.amount}
                format="currency"
                decimals={0}
            />
        );
        return (
            <ProfileSummaryItem
                key={path}
                icon={icon}
                label={label}
                value={formattedAmount}
            />
        );
    };

    buildDesiredDownPaymentSummary = () =>
        this.buildFormattedAmountSummary(
            "desiredDownPayment",
            IconMoney,
            "Desired Down Payment:"
        );

    buildVerifiedDownPaymentSummary = () =>
        this.buildFormattedAmountSummary(
            "verifiedDownPayment",
            IconPayment,
            "Verified Down Payment:"
        );

    buildEstimatedLoanSummary = () =>
        this.buildFormattedAmountSummary(
            "loanAmount",
            IconLoan,
            "Estimated Loan Amount:"
        );

    buildEstimatedMortgageSummary() {
        const mortgageTooltip = (
            <Tooltip id="buyer-profile-mortgage-tooltip">
                Amount reflected shows Principal & Interest only on a 30 Year
                Mortgage at rate of 4.25%.
            </Tooltip>
        );
        const label = (
            <span>
                Estimated Mortgage Payment:{" "}
                <OverlayTrigger placement="top" overlay={mortgageTooltip}>
                    <i className="fa fa-info-circle" />
                </OverlayTrigger>
            </span>
        );

        return this.buildFormattedAmountSummary(
            "mortgage",
            IconMortgage,
            label
        );
    }

    buildEstimatedMonthlyTaxesSummary = () =>
        this.buildFormattedAmountSummary(
            "monthlyTaxes",
            IconTaxes,
            "Estimated Monthly Property Taxes:"
        );

    buildEstimatedTotalPaymentSummary() {
        const { isShowingDetails } = this.state;

        const label = (
            <Fragment>
                Total Estimated Payment:{" "}
                <small>
                    <span
                        className="act-as-link"
                        onClick={this.toggleShowDetails}
                    >
                        ({isShowingDetails ? "Less" : "More"})
                    </span>
                </small>
            </Fragment>
        );

        return this.buildFormattedAmountSummary("total", IconTotal, label);
    }

    buildEstimatedTotalPaymentBreakdown() {
        if (!this.state.isShowingDetails) return null;
        return (
            <div className="profile__breakdown is-compact">
                {paymentBreakdownFields.map(({ key, label }) => {
                    const formattedLabel = (
                        <div className="label-with-ellipsis">
                            <span className="for-label">{label}</span>
                            <span className="for-ellipsis" />
                        </div>
                    );

                    return this.buildFormattedAmountSummary(
                        key,
                        "",
                        formattedLabel,
                        true
                    );
                })}
                <div className="profile__summary__row for-message">
                    The estimated monthly payment breakdown is provided solely
                    for general education and informational purposes only. We
                    use data from third party companies and affiliates to create
                    the most accurate data possible. The estimated monthly
                    payment breakdown information is not guaranteed by LemonBrew
                    and should not be used in lieu of professional financial
                    advice.
                </div>
            </div>
        );
    }

    buildTimeFrameSummary = () => (
        <ProfileSummaryItem
            icon={IconCalendar}
            label="Timeframe:"
            value={this.getProfileValue("purchaseTimeline.displayName")}
        />
    );

    render() {
        return (
            <div className="profile__personal__container">
                <div
                    className="profile__personal__section is-full-width"
                    style={{ marginTop: "10px" }}
                >
                    <div className="profile__summary">
                        {this.buildLocationSummary()}
                        {this.buildPropertyTypeSummary()}
                        {this.buildTimeFrameSummary()}
                        {this.buildBudgetSummary()}
                        {this.buildDesiredDownPaymentSummary()}
                        {this.buildVerifiedDownPaymentSummary()}
                        {this.buildEstimatedLoanSummary()}
                        {this.buildEstimatedMortgageSummary()}
                        {this.buildEstimatedMonthlyTaxesSummary()}
                        {this.buildEstimatedTotalPaymentSummary()}
                        {this.buildEstimatedTotalPaymentBreakdown()}
                    </div>
                </div>
            </div>
        );
    }
}
