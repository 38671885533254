import React from 'react';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const numberMask = createNumberMask();

export default ({field, form, ...props}) => {
    const handleChange = (e) => {
        form.setFieldValue(field.name, `${e.target.value}`.replace(/[$,._ ]/g, ''))
    };

    return (
        <MaskedInput
            mask={numberMask}
            {...field}
            {...props}
            onChange={handleChange}
        />
    );
};
