import React from 'react';
import PropTypes from 'prop-types';

const ProfileSummaryItem = ({ icon, label, value }) => {
    return (
        <div className="profile__summary__row">
            <div className="profile__summary__icon">
                {icon && <img src={icon} alt={label} />}
            </div>
            <div className="profile__summary__label">
                {label}
            </div>
            <div className="profile__summary__value">
                {value}
            </div>
        </div>
    );
};

ProfileSummaryItem.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    value: PropTypes.node.isRequired,
};

export default ProfileSummaryItem;
