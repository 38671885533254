import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import get from 'lodash/get';
import replace from 'lodash/replace';
import startsWith from 'lodash/startsWith';
import find from 'lodash/find';
import trim from 'lodash/trim';
import { withRouter, Link } from 'react-router-dom';
import APIDomain from '../../../shared/apiSettings';
import SpinnerLoader from '../../SpinnerLoader/SpinnerLoader';

class GoToDocuSign extends Component {
    static propTypes = {
        location: PropTypes.string,
    };

    constructor(props){
        super(props);
        this.state={
            alreadySigned: false,
        };
    }

    componentDidMount = () => {
        const { location } = this.props;
        const query = get(location, 'search');
        const token = this.getTokenFromLocationQuery(query);
        const type = this.getTypeFromLocationQuery(query);
        this.newDocuSignURL(token, type);
    };

    getTokenFromLocationQuery = (query) => {
        return this.getParamValueFromLocationQuery(query, 'token');
    };

    getTypeFromLocationQuery = (query) => {
        return this.getParamValueFromLocationQuery(query, 'type');
    };

    getParamValueFromLocationQuery = (query, paramName) => {
        const cleanQuery = trim(replace(query, '?', ''));
        const params = cleanQuery.split('&');
        const rawParam = find(params, (param) => startsWith(param, `${paramName}=`));
        const paramValue = replace(rawParam, `${paramName}=`, '');
        return paramValue;
    };

    newDocuSignURL = (token, type) => {
        axios({
            method: 'GET',
            url: `${APIDomain}/_3p/docusign/referral-agreement?token=${token}&type=${type}`,
            withCredentials: true,
        })
            .then((response) => {
                const { data } = response;
                if (data) {
                    window.open(data, '_self');
                }
                return response;
            })
            .catch((error) => {
                this.setState({
                    alreadySigned: true,
                });
            });
    };

    buildSpinner = () => (
        <div className="sparkhire__verify__redirect-title">
            <h1> Redirecting you to the DocuSign page... </h1>
            <SpinnerLoader />
        </div>
    );

    buildErrorMessage = () => (
        <div className="sparkhire__verify__redirect-title">
            <h1> Ooops! It looks like you have already signed this Agreement. You're all set! </h1>
            <Link to="/signin" className="sparkhire__verify__login-btn">GO TO LOGIN</Link>
            
        </div>
    );

    render = () => {
        const { alreadySigned } = this.state;
        return ( !alreadySigned ? this.buildSpinner() : this.buildErrorMessage());
    };
}

export default withRouter(GoToDocuSign);
