import React, { Component } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import StreetView from '../StreetView/StreetView'
import { formatCurrency, formatDate } from '../../shared/functions'
import './value_graph.scss';

export default class ValueGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const lng = get(this.props, 'lng')  || ''
        const lat = get(this.props, 'lat')  || ''
        const saleDate = get(this.props, 'saleDate')  || ''
        const salePrice  = get(this.props, 'salePrice')
        const lowerValue = get(this.props ,'value.estimatedValueLow') || ''
        const higherValue = get(this.props ,'value.estimatedValueHigh') || ''
        const sales = get(this.props, 'value.sales') || []
        const streetAddress = get(this.props,'address.streetAddress') || ''
        const city = get(this.props, 'address.city') || ''
        const state = get(this.props, 'address.state') || ''
        const zip = get(this.props, 'address.zip')  || ''

        /**
         * loop through the sales data and change the keys to x and y as
         * they are the only valid keys accepted by @nivo/line chart
         */

        const data = sales
            ? sales.map(sale => {
                const dataValues = {};
                dataValues.x = sale[0];
                dataValues.y = sale[1];
                return dataValues;
            })
            : [];

        const charData = [{ x: '0', y: 0 }, ...data, { x: '11', y: 0 }];

        return (
            <div className="value_graph_container">
                <div className="header_container">
                    <div className="header">
                        <div className="address">
                            <div className="street__view__container">
                                <StreetView
                                    lat={lat}
                                    lng={lng}
                                 />
                            </div>
                            <div className="street">
                                <div>{`${streetAddress}`}</div>
                                <div>{`${city}, ${state} ${zip}`}</div> 
                                <div className="address__info">
                                    {
                                       `Last Sold on ${saleDate ? formatDate(saleDate) : '' } 
                                        for ${salePrice ? formatCurrency(salePrice, 0)  :'' }`}
                                </div>
                            </div> 
                        </div>
                        <div className="cost">
                            <div className="amount">
                                {   lowerValue && higherValue ?
                                    `${formatCurrency(lowerValue, 0)} - 
                                     ${formatCurrency(higherValue, 0)}`
                                     :
                                     ''
                                }
                            </div>
                            <div className="estimate">
                                {
                                    lowerValue && higherValue ?
                                    <div className="estimate">{`Estimate Value Range  as of:
                                        ${formatDate(new Date().toJSON().slice(0,10))}`}</div>:
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="graph">
                    {// if the sales array is not empty then render the graph
                        sales && sales.length > 0 ? (
                            <ResponsiveLine
                                data={[{ id: 'sales', data: charData }]}
                                curve="natural"
                                animate
                                enableArea
                                areaOpacity={1}
                                colors="#fff"
                                pointSize={10}
                                pointColor="#00A24B"
                                pointBorderColor="#FFFFFF"
                                pointBorderWidth={2}
                                enableGridY={false}
                                enablePointLabel
                                pointLabel={d => new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })
                                    .format(d.y)
                                    .split('.')[0]
                                }
                                pointLabelYOffset={-15}
                                margin={{
                                    top: 50,
                                }}
                                isInteractive={false}
                                axisTop={{
                                    orient: 'top',
                                    tickSize: 0,
                                    tickPadding: 40
                                }}
                            />
                        ) : (
                            ''
                        )}
                </div>
            </div>
        );
    }
}

ValueGraph.propTypes = {
    value: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
};
