import React from "react";
import PropTypes from "prop-types";
import invoke from "lodash/invoke";
import trim from "lodash/trim";
import get from "lodash/get";
import map from "lodash/map";
import classNames from "classnames";
import { format } from "date-fns";
import { Glyphicon } from "react-bootstrap";
import DialogModal from "../DialogModal/DialogModal";

import "./realtormatchprofileattachments.css";

const filePropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    date: PropTypes.any
});

export default class RealtorMatchProfileAttachments extends React.Component {
    static propTypes = {
        documents: PropTypes.arrayOf(filePropType),
        onRemoveFile: PropTypes.func,
        dateFormat: PropTypes.string,
        onOpenMatchFileUploadModal: PropTypes.func
    };

    static defaultProps = {
        dateFormat: "MM/DD/YYYY"
    };

    constructor(props) {
        super(props);
        this.state = {
            isDialogModalVisible: false,
            selectedFile: null
        };
    }

    buildFileKey = file => get(file, "id");

    buildFileName = file => trim(get(file, "name"));

    buildFileSize = file => trim(get(file, "size"));

    buildFileDate = file => format(get(file, "date"), this.props.dateFormat);

    openDocument = file => {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const URL = trim(get(file, "url"));
        window.open(`${baseUrl}/${URL}`, "_blank");
    };

    buildFile = file => (
        <div
            key={this.buildFileKey(file)}
            className="realtor-match-profile-attachments__file"
        >
            <div className="realtor-match-profile-attachments__file-name">
                <button
                    type="button"
                    className="btn btn-link realtor-match-profile-attachments__file-open-button"
                    onClick={() => this.openDocument(file)}
                >
                    {this.buildFileName(file)}
                </button>
            </div>
            <div className="realtor-match-profile-attachments__file-size">
                {this.buildFileSize(file)}
            </div>
            <div className="realtor-match-profile-attachments__file-date">
                {this.buildFileDate(file)}
            </div>
            <div className="realtor-match-profile-attachments__file-remove">
                <button
                    type="button"
                    className="btn btn-link realtor-match-profile-attachments__file-remove-button"
                    onClick={() => this.onOpenDialogModal(file)}
                >
                    Remove
                </button>
            </div>
        </div>
    );

    buildDropFiles = () => (
        <div
            className={classNames(
                "realtor-match-profile-attachments__dropzone",
                { dropzone__disabled: !this.props.buyerHasMatch }
            )}
            onClick={this.props.onOpenMatchFileUploadModal}
        >
            <Glyphicon
                glyph="cloud-upload"
                className="realtor-match-profile-attachments__dropzone-icon"
            />
            <span className="realtor-match-profile-attachments__dropzone--text">
                Upload a document
            </span>
        </div>
    );

    buildFiles = () => map(this.props.documents, this.buildFile);

    onRemoveFile = () => {
        const { selectedFile } = this.state;
        invoke(this.props, "onRemoveFile", selectedFile);
        this.onCloseDialogModal();
    };

    onOpenDialogModal = file => {
        this.setState({
            isDialogModalVisible: true,
            selectedFile: file,
            fileName: this.buildFileName(file)
        });
    };

    onCloseDialogModal = () => {
        this.setState({
            isDialogModalVisible: false,
            selectedFile: null,
            fileName: null
        });
    };

    render = () => {
        const { isDialogModalVisible, fileName } = this.state;
        const label = `Are you sure you want to remove ${fileName}?`;
        return (
            <div className="realtor-match-profile-attachments">
                <DialogModal
                    isVisible={isDialogModalVisible}
                    onClose={this.onCloseDialogModal}
                    onAccept={this.onRemoveFile}
                    label={label}
                />
                <h3 className="realtor-match-profile-attachments__title">
                    Documents
                </h3>
                {this.buildDropFiles()}
                {this.buildFiles()}
            </div>
        );
    };
}
