import React from "react";
import { ButtonToolbar, DropdownButton } from "react-bootstrap";
import "./navbar-submenu-button.css";

export default class NavBarSubMenuButton extends React.PureComponent {
    render = () => (
        <div className="navbar-submenu-button">
            <ButtonToolbar>
                <DropdownButton
                    title={this.props.label}
                    id={this.props.id}
                    bsStyle="navbar"
                >
                    {this.props.children}
                </DropdownButton>
            </ButtonToolbar>
        </div>
    );
}
