import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";
import invoke from "lodash/invoke";
import { NumberFormat } from "../NumberFormat/NumberFormat";
import MaskedInputField from "../MaskedInput/MaskedInputField";

import "./realtoravgfield.css";

export default class RealtorAvgField extends Component {
    isUsingCurrency = () => this.props.format === "currency";

    getValue = newAmount => {
        const currency = this.getCurrency();
        return this.isUsingCurrency()
            ? { amount: newAmount, currency }
            : newAmount;
    };

    getCurrency = () => trim(get(this.props.value, "currency"));

    onChange = value => {
        if (isEmpty(value)) {
            return;
        }
        invoke(this.props, "onChange", this.getValue(value));
    };

    getAmount = () => {
        const amount = this.isUsingCurrency()
            ? get(this.props.value, "amount")
            : this.props.value;
        return Number(amount) || 0;
    };

    isInvalid = () => {
        const value = this.getAmount();
        return value < this.props.minValue || value > this.props.maxValue;
    };

    getErrorMessage = () =>
        `The value should be between ${this.props.minValue} and ${this.props.maxValue}`;

    buildEditableField = () => (
        <MaskedInputField
            className="form-control realtor-avg-field__field-editable"
            maskType={this.props.maskType}
            value={String(this.getAmount())}
            min={this.props.minValue}
            max={this.props.maxValue}
            placeholder={this.props.placeholder}
            onChange={this.onChange}
            type={this.props.type}
        />
    );

    buildFieldValue = () => (
        <NumberFormat
            className="realtor-avg-field__field-value"
            format={this.props.format}
            decimals={0}
            number={this.getAmount()}
            currency={this.getCurrency()}
        />
    );

    buildErrorMessageIfRequired = () => {
        if (!this.props.displayErrors) {
            return null;
        }

        if (!this.isInvalid()) {
            return null;
        }

        return (
            <div className="realtor-avg-field__error-label">
                {this.getErrorMessage()}
            </div>
        );
    };

    render = () => {
        const { isEditing } = this.props;
        const field = isEditing
            ? this.buildEditableField()
            : this.buildFieldValue();

        return (
            <div className="realtor-avg-field">
                <h5 className="realtor-avg-field__field-label">
                    {this.props.fieldLabel}
                </h5>
                {field}
                {this.buildErrorMessageIfRequired()}
            </div>
        );
    };
}

RealtorAvgField.propTypes = {
    fieldLabel: PropTypes.string.isRequired,
    fieldProp: PropTypes.string.isRequired,
    canEdit: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string
        })
    ]),
    minValue: PropTypes.string,
    maxValue: PropTypes.string,
    onEdit: PropTypes.func,
    format: PropTypes.string,
    maskType: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    isEditing: PropTypes.bool,
    displayErrors: PropTypes.bool
};
