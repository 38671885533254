import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Glyphicon } from 'react-bootstrap';
import get from 'lodash/get';

import './emailconfirmationbanner.css';

const EmailConfirmationBannerComponent = (props) => (
    props.mustValidateEmail
        ? (
            <Alert bsStyle="warning" className="email-confirmation-banner">
                <Glyphicon glyph="warning-sign" />
                {' '}
                Your email address has not been verified.
                {' '}
                Please verify your email address from
                {' '}
                the Welcome to LemonBrew email you received during sign up.
                {' '}
                If you cannot find that email, please contact us as
                {' '}
                <a href={`mailto:${props.supportEmail}`}>{props.supportEmail}</a>
                .
            </Alert>
        )
        : null
);

EmailConfirmationBannerComponent.propTypes = {
    mustValidateEmail: PropTypes.bool,
    supportEmail: PropTypes.string,
};

EmailConfirmationBannerComponent.defaultProps = {
    supportEmail: 'hello@lemonbrew.com',
};

const EmailConfirmationBanner = connect(
    ({ user }) => ({
        mustValidateEmail: user.isProfileFetched && get(user, 'profile.publicProfile.userInfo.isEmailVerified') !== true,
    }),
)(EmailConfirmationBannerComponent);

export default EmailConfirmationBanner;
