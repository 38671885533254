import React, { Fragment } from "react";
import { Field } from "formik";
import get from "lodash/get";
import { yesNo } from "../../../../shared/constants";
import BaseWizardStep from "../BaseWizardStep";
import FormikBuyerFlowRadioButton from "../../../BuyerFlowButton/FormikBuyerFlowRadioButton";
import mixpanel from "mixpanel-browser";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";

class StepLocation extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        const hasKnownLocation =
            get(values, "hasKnownLocation") === "yes" ? true : false;

        mixpanel.track("Completed Do You Have A Home Picked Out", {
            hasPickedOutNewHome: hasKnownLocation
        });
    };

    stepLocationNext = () => {
        setTimeout(
            () => this.props.onNavigateStep(1, true, this.mixpanelFunction),
            0
        );
    };

    render() {
        const isStepFulfilled = this.areFieldsValid(["hasKnownLocation"]);

        return (
            <Fragment>
                <ScrollToTopOnMount />
                <div className="buyer__signup__step is-highest">
                    <div className="buyer__signup__question white-font">
                        Do you already have a home picked out?
                    </div>

                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field display-flex">
                                <Field
                                    name="hasKnownLocation"
                                    className="is-horizontal"
                                    itemClassName="is-widest"
                                    options={yesNo}
                                    component={FormikBuyerFlowRadioButton}
                                />
                                {this.renderFieldError("hasKnownLocation")}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderStepButtons(
                    {
                        canProceed: isStepFulfilled,
                        hasMixPanelTracking: true,
                        isLoading: false,
                        handleNext: this.stepLocationNext
                    },
                    this.mixpanelFunction
                )}
            </Fragment>
        );
    }
}

export default StepLocation;
