import React from 'react';

export class BasePolicy {
    /**
     * Given profile, validate the policy and return null
     * or whatever else to render (usually a redirect)
     *
     * @abstract
     *
     * @param {Object} profile
     * @returns {Object}
     */
    validate = profile => null
}

export function checkPolicy (policy, profile) {
    const policies = Array.isArray(policy) ? policy : [policy];

    // eslint-disable-next-line no-unused-vars
    for (const p of policies) {
        const result = (p.prototype instanceof BasePolicy) ? p.validate(profile) : null;
        if (result) return result;
    }
    return null;
}

const PolicyContext = React.createContext({});

export const PolicyContextProvider = PolicyContext.Provider;
export const PolicyContextConsumer = PolicyContext.Consumer;
