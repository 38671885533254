import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link } from "react-router-dom";
import Button from "../../Button/Button";
import "./more_info_button.scss";

export const MoreInfoCloseIcon = props => {
    if (!props.showMoreInfo) return null;

    return (
        <div className="admin-role-tables__close-more-info">
            <Button
                type="button"
                icon
                dark
                symbol="times"
                onClick={() => props.onClickMoreInfo(props.id)}
            ></Button>
        </div>
    );
};

MoreInfoCloseIcon.propTypes = {
    id: PropTypes.any,
    showMoreInfo: PropTypes.bool,
    onClickMoreInfo: PropTypes.func
};

export const MoreInfoButton = props => {
    const buttonText = "More Info";

    return (
        <Button onClick={() => props.onClickMoreInfo(props.id)} small text>
            {buttonText}
        </Button>
    );
};

export const CustomMoreInfoButton = props => {
    const id = get(props, "id");
    return (
        <Link className="link" to={`admin-profile-buyer/${id}`} target="_blank">
            More Info
        </Link>
    );
};

MoreInfoButton.propTypes = {
    id: PropTypes.any,
    showMoreInfo: PropTypes.bool,
    onClickMoreInfo: PropTypes.func
};
