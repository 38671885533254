import get from "lodash/get";
import isFunction from "lodash/isFunction";
import trim from "lodash/trim";
import forEach from "lodash/forEach";
import set from "lodash/set";
import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import UserProfileContext from "../UserProfileContext";
import AgentTab from "../UserBuyerProfile/AgentTab";
import { findSellerAgents } from "../../../util/service_api";
import mixpanel from "mixpanel-browser";

import "../user-profile.css";
import { delayBy } from "../../../util/util";

const ProfileTabs = [
    { name: "agent", label: "Your Agent", component: AgentTab, isPublic: false }
];

const DELAY_FIND_AGENTS_MSEC = 2000;

class UserSellerProfile extends Component {
    static propTypes = {
        profile: PropTypes.object,
        isPublicView: PropTypes.bool,
        onChooseAgent: PropTypes.func,
        onGetAgentMatches: PropTypes.func,
        onUploadPicture: PropTypes.func,
        onUpdateProfile: PropTypes.func
    };

    constructor(props) {
        super(props);

        const {
            location: { hash }
        } = this.props;
        const activeTab = hash.slice(1);

        this.state = {
            activeTab: ProfileTabs.some(({ name }) => name === activeTab)
                ? activeTab
                : ProfileTabs[0].name,
            didSearchForMatches: false,
            isFetchingAgents: false
        };
    }

    mixpanelTrackAgents = result => {
        let agents = {};
        forEach(result, (agent, index) => {
            set(
                agents,
                `agentMatched${index}FirstName`,
                get(agent, "userInfo.firstName")
            );
            set(
                agents,
                `agentMatched${index}LastName`,
                get(agent, "userInfo.lastName")
            );
            set(
                agents,
                `agentMatched${index}Email`,
                get(agent, "userInfo.email")
            );
            set(
                agents,
                `agentMatched${index}Phone`,
                get(agent, "userInfo.phone")
            );
        });

        mixpanel.track("Completed Agent Matching", {
            ...agents
        });
    };

    searchForAgentMatches = () => {
        this.setState({
            didSearchForMatches: true,
            isFetchingAgents: true
        });

        const { onGetAgentMatches } = this.props;
        return delayBy(DELAY_FIND_AGENTS_MSEC)
            .then(() => findSellerAgents())
            .then(result => {
                if (isFunction(onGetAgentMatches))
                    onGetAgentMatches(result.data);
                this.mixpanelTrackAgents(result.data);
                return Promise.resolve(result.data);
            })
            .finally(() => {
                this.setState({ isFetchingAgents: false });
            });
    };

    buildFullName = () => {
        const { profile } = this.props;
        const firstName = trim(
            get(profile, "publicProfile.userInfo.firstName")
        );
        const lastName = trim(get(profile, "publicProfile.userInfo.lastName"));
        return (
            <div className="profile__name">
                {`Congratulations ${firstName} ${lastName}!`}
            </div>
        );
    };

    activateTab = activeTab => () => this.setState({ activeTab });

    tabTitleDecorator(name, label) {
        if (name !== "agent") return label;
        return "Your Agent";
    }

    buildProfileTabs() {
        const { activeTab } = this.state;

        return (
            <UserProfileContext.Provider
                value={{
                    activateTab: this.activateTab,
                    didSearchForMatches: this.state.didSearchForMatches,
                    isFetchingAgents: this.state.isFetchingAgents,
                    searchForAgentMatches: this.searchForAgentMatches,
                    profile: this.props.profile,
                    isPublicView: this.props.isPublicView,
                    onChooseAgent: this.props.onChooseAgent,
                    onGetAgentMatches: this.props.onGetAgentMatches,
                    onUploadPicture: this.props.onUploadPicture,
                    onUpdateProfile: this.props.onUpdateProfile
                }}
            >
                <div className="profile__tabs">
                    {ProfileTabs.map(({ name, label }) => (
                        <a
                            key={name}
                            href={`#${name}`}
                            className={`${
                                name === this.state.activeTab ? "is-active" : ""
                            } ${name === "agent" ? "with-dot" : ""}`}
                            onClick={this.activateTab(name)}
                        >
                            <div
                                className={
                                    name === this.state.activeTab
                                        ? "tab__name__marker"
                                        : "tab__name__marker__hide"
                                }
                            >
                                {this.tabTitleDecorator(name, label)}
                            </div>
                            <span>{this.tabTitleDecorator(name, label)}</span>
                        </a>
                    ))}
                </div>

                {ProfileTabs.map(({ name, component: TabComponent }) =>
                    activeTab === name ? <TabComponent key={name} /> : null
                )}
            </UserProfileContext.Provider>
        );
    }

    render() {
        return (
            <div className="profile__container">
                <div className="profile__panel">
                    {this.buildFullName()}
                    {this.buildProfileTabs()}
                </div>
            </div>
        );
    }
}

export default withRouter(UserSellerProfile);
