import React, { Fragment } from "react";
import mailbox from "../../../../styles/assets/Inbox-Icon.svg";
import ScrollToTopOnMount from "../../../ScrollTopOnMount/ScrollTopOnMount";

import "./index.css";

const BrokerSignUpSuccess = () => (
    <Fragment>
        <ScrollToTopOnMount />
        <div className="buyer__signup__step__bcg"></div>
        <div className="buyer__signup__step buyer--flow">
            <div className="buyer__signup__question">Check your inbox</div>

            <div className="buyer__signup__fieldset">
                <div className="buyer__signup__field__row">
                    <div className="buyer__signup__field__label align-center is-big">
                        We've sent a message to you with a link to verify your
                        Email Address. If you don't see an email from us within
                        a few minutes, be sure to check your spam folder.
                        <br />
                        <br />
                        But if you don't see the email send us a message at
                        &nbsp;
                        <a href="mailto:hello@lemonbrew.com">
                            hello@lemonbrew.com
                        </a>{" "}
                        for assistance.
                        <br />
                        <br />
                        <img src={mailbox} alt="mailbox" />
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
);

export default BrokerSignUpSuccess;
