import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SignInContainer from './SignInContainer';
import {Helmet} from 'react-helmet';

class SignIn extends Component {
    render() {
        return (
            <div className="signin__container">
                <Helmet>
                    <body className="bg-emphasis" />
                </Helmet>

                <SignInContainer />
            </div>
        );
    }
}

export default withRouter(SignIn);
