import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Tab } from "react-bootstrap";
import Tabbed from "../Tabbed/Tabbed";
import EmdLandingPageAccounts from "../EmdLandingPageAccounts/EmdLandingPageAccounts";
import EmdLandingPageProfile from "../EmdLandingPageProfile/EmdLandingPageProfile";

import "./emdlandingpage.css";

export const ACCOUNTS_TAB = "accounts";
export const PROFILE_TAB = "profile";

export default class EmdLandingPage extends React.Component {
    getProfilePicture = () =>
        get(this.props.user, "profile.publicProfile.userInfo.profilePicUrl");

    buildYourAccountsTab = () => (
        <Tab eventKey={ACCOUNTS_TAB} title="Your Accounts">
            <EmdLandingPageAccounts
                user={this.props.user}
                prospect={this.props.prospect}
                verificationDocumentTypes={this.props.verificationDocumentTypes}
                getFundingSourcesAndReloadProfile={
                    this.props.getFundingSourcesAndReloadProfile
                }
                loadUserProfile={this.props.loadUserProfile}
                fetchVoaUrl={this.props.fetchVoaUrl}
                earnestMoneyDepositCancel={this.props.earnestMoneyDepositCancel}
                resetDeposit={this.props.resetDeposit}
                earnestMoneyDepositDestinationAccount={
                    this.props.earnestMoneyDepositDestinationAccount
                }
                sendEmails={this.props.sendEmails}
                successSendEmails={this.props.successSendEmails}
                isFetchingSendEmails={this.props.isFetchingSendEmails}
                isMidUserRole={this.props.isMidUserRole}
                isReadOnlyUserRole={this.props.isReadOnlyUserRole}
                isImpersonatingUser={this.props.isImpersonatingUser}
            />
        </Tab>
    );

    buildYourProfileTab = () => (
        <Tab eventKey={PROFILE_TAB} title="Your Profile">
            <EmdLandingPageProfile
                loadUserProfile={this.props.loadUserProfile}
                user={this.props.user}
                prospect={this.props.prospect}
                editProspectInfo={this.props.editProspectInfo}
                isMidUserRole={this.props.isMidUserRole}
                isReadOnlyUserRole={this.props.isReadOnlyUserRole}
                isImpersonatingUser={this.props.isImpersonatingUser}
            />
        </Tab>
    );

    render = () => {
        return (
            <div className="emd-landing-page__content">
                <div className="row">
                    <div className="container emd-profile-tabbed">
                        <Tabbed
                            activeKey={this.props.tab}
                            onSelect={this.props.onChangeTab}
                            picture={this.getProfilePicture()}
                        >
                            {this.buildYourAccountsTab()}
                            {this.buildYourProfileTab()}
                        </Tabbed>
                    </div>
                </div>
            </div>
        );
    };
}

EmdLandingPage.propTypes = {
    user: PropTypes.object,
    prospect: PropTypes.object,
    verificationDocumentTypes: PropTypes.array,
    getFundingSourcesAndReloadProfile: PropTypes.func,
    loadUserProfile: PropTypes.func,
    fetchVoaUrl: PropTypes.func,
    earnestMoneyDepositCancel: PropTypes.func,
    resetDeposit: PropTypes.func,
    earnestMoneyDepositDestinationAccount: PropTypes.object,
    tab: PropTypes.string,
    onChangeTab: PropTypes.func,
    editProspectInfo: PropTypes.func,
    sendEmails: PropTypes.func,
    successSendEmails: PropTypes.bool,
    isFetchingSendEmails: PropTypes.bool,
    isMidUserRole: PropTypes.bool,
    isReadOnlyUserRole: PropTypes.bool,
    isImpersonatingUser: PropTypes.bool
};
