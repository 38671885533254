import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import invoke from "lodash/invoke";
import MaskedInputField from "./MaskedInputField";

export default class MaskedInputFormik extends React.PureComponent {
    getFieldName = () => get(this.props, "field.name");

    updateFieldValueTo = newValue => {
        const fieldName = this.getFieldName();
        invoke(this.props, "form.setFieldValue", fieldName, newValue);
        invoke(this.props, "form.setFieldTouched", fieldName, true);
    };

    render = () => (
        <MaskedInputField {...this.props} onChange={this.updateFieldValueTo} />
    );
}

MaskedInputFormik.propTypes = {
    form: PropTypes.shape({
        setFieldValue: PropTypes.func
    }),
    field: PropTypes.shape({
        name: PropTypes.string
    })
};
