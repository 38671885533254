import * as Action from './RealtorSellerMatchActionTypes';
import * as APIUtil from '../../../util/service_api';
import get from 'lodash/get';
import size from 'lodash/size';

const requestActiveMatchesUntilNewFound = (currentActiveMatches) => (dispatch) => {
    const hasNewActiveMatches = (newActiveMatches) => size(currentActiveMatches) < size(newActiveMatches);

    const dispatchNewActiveMatchesModalOrRetry = (newActiveMatches) => {
        if (hasNewActiveMatches(newActiveMatches)) {
            dispatch(successfullyFetchActiveMatches(newActiveMatches));
            dispatch(displayNewActiveSellerMatchModal());
        } else {
            dispatch(waitAndRequestActiveMatchesUntilNewFound(currentActiveMatches));
        }
    };

    APIUtil.getActiveSellerMatches()
        .then(dispatchNewActiveMatchesModalOrRetry)
        .catch(() => {
            dispatch(waitAndRequestActiveMatchesUntilNewFound(currentActiveMatches));
        });
};

const waitAndRequestActiveMatchesUntilNewFound = (currentActiveMatches) => (dispatch) => {
    const requestActiveMatches = () => dispatch(requestActiveMatchesUntilNewFound(currentActiveMatches));
    const afterThirtySeconds = 30 * 1000;
    setTimeout(requestActiveMatches, afterThirtySeconds);
};

const successfullyFetchActiveMatches = (activeMatches) => ({
    type: Action.SUCCESSFULLY_FETCH_ACTIVE_SELLER_MATCHES,
    payload: { activeMatches },
});

export const displayNewActiveSellerMatchModal = () => ({
    type: Action.DISPLAY_NEW_ACTIVE_SELLER_MATCH_MODAL
});

export const hideNewActiveSellerMatchModal = () => ({
    type: Action.HIDE_NEW_ACTIVE_SELLER_MATCH_MODAL
});

export const acceptSellerMatch = (matchId, data) => (dispatch, getState) => {
    const currentActiveMatches = get(getState(), 'user.profile.sellers');

    dispatch(successfullyAcceptedSellerMatch(matchId));
    dispatch(waitAndRequestActiveMatchesUntilNewFound(currentActiveMatches));
};

const successfullyAcceptedSellerMatch = (matchId) => ({
    type: Action.SUCCESSFULLY_ACCEPTED_SELLER_MATCH,
    payload: { matchId },
});

export const declineSellerMatch = (matchId, data) => (dispatch) => {
    const declineSeller = APIUtil.declineSellerMatch(matchId, data);
    declineSeller.then(() => dispatch(successfullyRejectedSellerMatch(matchId)));
};

const successfullyRejectedSellerMatch = (matchId) => ({
    type: Action.SUCCESSFULLY_REJECTED_SELLER_MATCH,
    payload: { matchId },
});

export const updateSellerStatus = (matchId, data) => (dispatch) => {
    APIUtil.updateSellerInformation(matchId, data)
        .then((response) => dispatch(successfullyUpdateSellerStatus(response)))
        .catch((error) => {
            dispatch(receiveUpdateSellerStatusErrors(error));
            return Promise.reject(error);
        });
};

const successfullyUpdateSellerStatus = (sellerData) => ({
    type: Action.SUCCESSFULLY_UPDATE_SELLER_STATUS,
    payload: { sellerData },
});

const receiveUpdateSellerStatusErrors = (error) => ({
    type: Action.RECEIVE_UPDATE_SELLER_STATUS_ERROR,
    payload: { error },
});

export const uploadRealtorSellerFileAttachment = (realtorMatchId, file, fileType) => (dispatch) => {
    dispatch(startToUploadRealtorSellerFile());
    APIUtil.uploadAttachment(realtorMatchId, file, fileType)
        .then((response) => dispatch(successfullyUploadRealtorSellerFile(realtorMatchId, response)))
        //.catch((error) => dispatch(uploadRealtorFileAttachmentErrors(error)));
};

const startToUploadRealtorSellerFile = () => ({
    type: Action.START_UPLOADING_REALTOR_SELLER_FILE,
});

const successfullyUploadRealtorSellerFile = (realtorMatchId, file) => ({
    type: Action.SUCCESSFULLY_UPLOAD_REALTOR_SELLER_FILE,
    payload: { realtorMatchId, file }
});

export const removeRealtorSellerFileAttachment = (realtorMatchId, fileId) => (dispatch) => {
    APIUtil.removeRealtorMatchAttachment(realtorMatchId, fileId)
        .then(() => dispatch(successfullyRemoveRealtorSellerFileAttachment(realtorMatchId, fileId)));
};

const successfullyRemoveRealtorSellerFileAttachment = (realtorMatchId, fileId) => ({
    type: Action.SUCCESSFULLY_REMOVE_REALTOR_SELLER_FILE_ATTACHMENT,
    payload: { realtorMatchId, fileId },
});

export const updateRealtorSellerStatus = (matchId, data) => (dispatch) => {
    APIUtil.updateSellerInformation(matchId, data)
        .then((response) => dispatch(successfullyUpdateRealtorSellerStatus(response)))
        // .catch((error) => {
        //     dispatch(receiveUpdateBuyerStatusErrors(error));
        //     return Promise.reject(error);
        // });
};

const successfullyUpdateRealtorSellerStatus = (sellerData) => ({
    type: Action.SUCCESSFULLY_UPDATE_REALTOR_SELLER_STATUS,
    payload: { sellerData },
});