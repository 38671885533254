import React, {Fragment}  from 'react'
import { Field } from 'formik';
import {
    AddressAutocompleteField,
} from '../../../Form';
import BaseWizardStep from './BaseWizardStep';
import { getAddressFromPlaceDetails } from '../../../../util/util';
import get from 'lodash/get';
import ScrollToTopOnMount from '../../../ScrollTopOnMount/ScrollTopOnMount';
import mixpanel from 'mixpanel-browser';

const MAX_LENGTH_FIRST_NAME = '50';
const MAX_LENGTH_LAST_NAME = '50';

class StepAboutYou extends BaseWizardStep {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    mixpanelFunction = () => {
        const { values } = this.props;
        mixpanel.track(
            "Completed Name & Current Address", {
                "firstName": get(values, 'firstName'),
                "lastName": get(values, 'lastName'),
                "currentAddressStreetNumberAndName": get(values, 'addressDetails.address'),
                "currentAddressApartmentSuiteNumber": get(values, 'addressDetails.apartmentSuiteNumber'),
                "currentAddressCity": get(values, 'addressDetails.city'),
                "currentAddressLat": get(values, 'addressDetails.lat'),
                "currentAddressLang": get(values, 'addressDetails.lng'),
                "currentAddressNeighborhood": get(values, 'addressDetails.neighborhood'),
                "currentAddressState": get(values, 'addressDetails.state'),
                "currentAddressZipcode": get(values, 'addressDetails.zipcode'),
            }
        )
        this.props.setFieldValue('trackEvent', true);
    };

    closeFinicitySearchModal = () => {
        this.setState({ showModal: false });
    }

    openFinicitySearchModal = () => {
        this.setState({ showModal: true });
    }

    buildFirstNameField = () => {
        const fieldName = 'firstName';
        return (
            <div className="buyer__signup__field width-50">
                <Field
                    type="text"
                    name={fieldName}
                    placeholder="FIRST NAME"
                    maxLength={MAX_LENGTH_FIRST_NAME}
                />
                {this.buildFieldError(fieldName)}
            </div>
        );
    };

    buildLastNameField = () => {
        const fieldName = 'lastName';
        return (
            <div className="buyer__signup__field width-50">
                <Field
                    type="text"
                    name={fieldName}
                    placeholder="LAST NAME"
                    className="name"
                    maxLength={MAX_LENGTH_LAST_NAME}
                />
                {this.buildFieldError(fieldName)}
            </div>
        );
    };

    buildNameTextInfo = () => (
        <div className="buyer__signup__field-under-info">
            Please use the name as it appears in your government issued identification document.
        </div>
    )

    updateAddressDetails = ([details] = []) => {
        const { address, city, state, zipcode, lat, lng } = getAddressFromPlaceDetails(details);
        this.setFieldValues({ address, city, state, zipcode, lat, lng }, 'addressDetails', true);
    };

    renderLocation() {
        return (
            <div className="buyer__signup__field__row">
                <div className="buyer__signup__field width-70">
                    <Field
                        component={AddressAutocompleteField}
                        name="addressDetails.address"
                        placeholder="CURRENT ADDRESS"
                        onPlaceDetails={this.updateAddressDetails}
                    />
                    {this.buildFieldError('addressDetails.address')}
                    {this.buildFieldError('addressDetails.zipcode')}
                </div>
                <div className="buyer__signup__field width-30">
                    <Field
                        type="text"
                        name="addressDetails.apartmentSuiteNumber"
                        placeholder="APARTMENT"
                    />
                </div>
            </div>
        );
    }

    isStepValid = () => this.areFieldsValid([
        'firstName',
        'lastName',
        'addressDetails',
    ]);

    render() {
        const isStepFulfilled = this.isStepValid();
        return (
            <Fragment>
                <ScrollToTopOnMount />
                <div className="buyer__signup__step is-highest">
                    <div className="buyer__signup__question white-font">
                        Let's Get Started
                    </div>
                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            {this.buildFirstNameField()}
                            {this.buildLastNameField()}
                        </div>
                        {this.buildNameTextInfo()}
                        {this.renderLocation()}

                        { this.renderStepButtons({
                            canProceed: isStepFulfilled,
                            hasMixPanelTracking: true,
                        }, this.mixpanelFunction) }
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default StepAboutYou;
