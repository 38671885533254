import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import BrokerSignUpContainer from "./BrokerSignUpContainer";

class SignUp extends Component {
    render() {
        return (
            <div className="signup__broker">
                <BrokerSignUpContainer />
            </div>
        );
    }
}

export default withRouter(SignUp);
