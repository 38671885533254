import { connect } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import head from 'lodash/head';
import { withFormik } from 'formik';
import {
    array as YupArray,
    object as YupObject,
    string as YupString,
    bool as YupBool,
    number as YupNumber,
    ref as YupRef,
} from 'yup';
import {
    getPropertyInfo,
} from '../../../../util/service_api';
import { signupSeller, getSearchSellerAgents } from '../../../../reducers/user/UserActions';
import {
    YupPassword,
    YupUsPhoneNumber,
    YupUniqueEmail,
    YupTwilioVerificationCode,
} from '../../../../shared/validations';
import {
    adaptStreetAddress,
    EmptyAddress,
} from '../../BuyerSignUp/Shared';
import WizardStepsForm from './WizardStepsForm';
import mixpanel from 'mixpanel-browser';

const mapValuesToPayload = (values, matchedAgents) => {
    const {
        numberOfBedrooms,
        numberOfBathrooms,
        purchaseTimeline,
        knownLocations,
        firstName,
        lastName,
        password,
        homeValue,
        homeValueMin,
        homeValueMax,
        email,
        phone,
        acceptedTermsAndConditions,
    } = values;

    const payload = {
        sellerSellingTimeline: purchaseTimeline,
        sellerAddress: head(knownLocations.map(adaptStreetAddress)),
        firstName,
        lastName,
        password,
        email,
        phone,
        sellerNumberOfBathrooms: numberOfBathrooms,
        sellerNumberOfBedrooms: numberOfBedrooms,
        sellerHomeValueMin: homeValueMin,
        sellerHomeValueMax: homeValueMax,
        sellerHomeValue: homeValue,
        acceptedTermsAndConditions,
        matchedAgents: matchedAgents ? matchedAgents : [],
    };

    if (numberOfBedrooms) {
        payload.sellerNumberOfBedrooms = parseInt(numberOfBedrooms, 10);
    }

    if (numberOfBathrooms) {
        payload.sellerNumberOfBathrooms = parseInt(
            numberOfBathrooms,
            10,
        );
    }

    return payload;
};

const mapValuesToSearchAgentsPayload = (values) => {
    const {
        knownLocations,
    } = values;

    const newAddresses = head(knownLocations.map(adaptStreetAddress));

    return { 'sellerAddress': newAddresses };
}

const defaultBudgetRange = {
    value: '',
    valueLow: '',
    valueHigh: '',
    totalBaths: 0,
    totalBedrooms: 0,
};

const customBudgetRange = (function () {
    let range = defaultBudgetRange;

    return {
        get: () => (range),

        set: r => { range = r },

        update: (data) => {
            return getPropertyInfo(data)
                .then(result => {
                    const {
                        foundMatch,
                        value,
                        valueLow,
                        valueHigh,
                        totalBaths,
                        totalBedrooms,
                    } = result;
                    if (foundMatch) {
                        range = ({
                            value,
                            valueLow,
                            valueHigh,
                            totalBaths,
                            totalBedrooms,
                        });
                    } else range = defaultBudgetRange;

                    return result;
                })
                .catch(() => range = defaultBudgetRange);
        }
    }
})();

const WizardStepsContainer = withFormik({
    mapPropsToStatus: () => 'initial',

    mapPropsToValues: (props) => {
        return {
            isSeller: true,
            knownLocations: [{ ...EmptyAddress }],
            purchaseTimeline: undefined,
            numberOfBedrooms: '',
            numberOfBathrooms: '',
            homeValue: '',
            homeValueMin: '',
            homeValueMax: '',
            firstName: '',
            lastName: '',
            password: '',
            password2: '',
            email: '',
            phone: '',
            phoneCode: '',
            phoneCheckState: '',
            acceptedTermsAndConditions: '',
            recaptchaSuccessfullyCompleted: false,
        };
    },

    validationSchema: (props) => YupObject().shape({

        knownLocations: YupArray().of(
            YupObject({
                    address: YupString().trim().required('Please enter a valid address'),
                    state: YupString().trim().required('Please enter a valid address'),
                }))
            .min(1, 'Address is required'),

        purchaseTimeline: YupString()
            .required('Timeline is required'),

        numberOfBedrooms: YupNumber()
            .typeError('Bedrooms must be a number')
            .min(0, 'Bedrooms must be greater or equal to 0')
            .nullable(),

        numberOfBathrooms: YupNumber()
            .typeError('Bathrooms must be a number')
            .min(0, 'Bathrooms must be greater or equal to 0')
            .nullable(),
 
        firstName: YupString()
            .trim()
            .required('First Name is required'),

        lastName: YupString()
            .trim()
            .required('Last Name is required'),
        
        homeValueMin: YupNumber('The value must be a positive number')
            .label('Proceeds')
            .positive()
            .min(0,1, 'The minimum value is 0,1')
            .required(),

        homeValueMax: YupNumber('The value must be a positive number')
            .label('Proceeds')
            .positive()
            .max(10000000, 'The maximum value is $10 million')
            .required(),

        password: YupPassword()
            .trim()
            .label('Password')
            .meta({ mustNotMatch: { path: 'email', label: 'Email' }})
            .required('Password is required'),

        password2: YupString()
            .trim()
            .oneOf([YupRef('password'), null], `Passwords don't match`)
            .required('Confirm Password is required'),

        email: YupUniqueEmail('ROLE_SELLER')
            .required('Email is required'),

        phone: YupUsPhoneNumber()
            .required('Phone is required'),

        phoneCode: YupTwilioVerificationCode()
            .required('Verification code is required'),

        phoneCheckState: YupString(),

        acceptedTermsAndConditions: YupBool()
            .test('validate-tos', 'To proceed, please accept Terms and Conditions', value => !!value),

        recaptchaSuccessfullyCompleted: YupBool().oneOf([true], 'Please complete ReCAPTCHA field'),
    }),

    // Submission handler
    handleSubmit(values, { setStatus, props }) {
        setStatus({ formState: 'submitting' });
        const matchedAgents = get(props, 'searchAgents.searchAgents.agents');
        const payload = mapValuesToPayload(values, matchedAgents);

        props.signupSeller(payload)
            .then(response => {
                window.fbq('track', 'SubmitApplication');
                mixpanel.alias(get(values, 'email'));
                mixpanel.track(
                    "Finished Onboarding", {
                        "firstName": get(values, 'firstName'),
                        "lastName": get(values, 'lastName'),
                    }
                );
            })
            .catch(error => {
                console.error('Submission failed', error);
            })
            .finally(() => setStatus({ formState: 'submitted' }));
    },
})(WizardStepsForm);

const mapStateToProps = (state) => ({
    mapValuesToPayload,
    mapValuesToSearchAgentsPayload,
    customBudgetRange,
    licensedStates: state.configApp.config ? state.configApp.config.licensedStates : [],
    licensedStatesAbbr: state.configApp.config ? map(state.configApp.config.licensedStates, 'abbreviation') : [],
    userProfile: state.user.profile,
    searchAgents: state.user.getSearchSellerAgents,
});

const mapDispatchToProps = (dispatch) => ({
    getSearchSellerAgents: (data) => dispatch(getSearchSellerAgents(data)),
    signupSeller: (payload) => dispatch(signupSeller(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStepsContainer);
