import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { getBrewWheelActiveList, getBrewWheelSpecificEvent, spinWheel } from '../../reducers/spin/SpinActions';
import RealtorSignUpSuccess from '../SignUp/RealtorSignUp/RealtorSignUpSuccess/RealtorSignUpSuccess';
import SpinWheel from './SpinWheel';

class SpinWheelView extends React.Component {
    static propTypes = {
        spin: PropTypes.object,
        history: PropTypes.any,
        email: PropTypes.string,
        getActiveListEvents: PropTypes.func,
        getSpecificEvent: PropTypes.func,
        spinBrewWheel: PropTypes.func,
        wheelId: PropTypes.number,
    };

    componentDidMount() {
        this.props.getActiveListEvents(this.props.email);
    }

    getActiveWheel = () => {
        const activeList = get(this.props.spin, 'getActiveList.list');
        return find(activeList, (item) => get(item, 'id') === this.props.wheelId);
    }

    getPrizes = () => get(this.getActiveWheel(), 'prizes');

    getPrize = () => {
        const prizeId = get(this.props.spin, 'spinWheel.prize.id');
        const listPrizes = get(this.getActiveWheel(), 'prizes');
        return prizeId ? findIndex(listPrizes, (item) => get(item, 'id') === prizeId) : -1;
    }

    getPrizeName = () => get(this.props.spin, 'spinWheel.prize.name');

    getPrizeDescription = () => get(this.props.spin, 'spinWheel.prize.description');

    getPrizeUrl = () => get(this.props.spin, 'spinWheel.prize.imageUrl');

    getSuccessSpinWheel = () => get(this.props.spin, 'spinWheel.successSpinWheel');

    onSpinWheel = () => {
        const activeWheel = this.getActiveWheel();
        const activeWheelId = get(activeWheel, 'id');
        this.props.spinBrewWheel(activeWheelId, this.props.email);
    }

    render = () => {
        const successActiveList = get(this.props.spin, 'getActiveList.successGetActiveList');
        const prizes = this.getPrizes();
        if (successActiveList && !isEmpty(prizes)) {
            return (
                <SpinWheel
                    history={this.props.history}
                    prizes={prizes}
                    getActiveListEvents={this.props.getActiveListEvents}
                    getSpecificEvent={this.props.getSpecificEvent}
                    spinBrewWheel={this.onSpinWheel}
                    selectedPrizeIndex={this.getPrize()}
                    selectedPrizeName={this.getPrizeName()}
                    selectedPrizeDescription={this.getPrizeDescription()}
                    selectedPrizeUrl={this.getPrizeUrl()}
                    successSpinWheel={this.getSuccessSpinWheel()}
                />
            );
        }
        if (successActiveList && isEmpty(prizes)) {
            return <RealtorSignUpSuccess />;
        }
        return null;
    }
}

export default withRouter(connect(
    (state) => ({ spin: state.spin }),
    {
        getActiveListEvents: getBrewWheelActiveList,
        getSpecificEvent: getBrewWheelSpecificEvent,
        spinBrewWheel: spinWheel,
    },
)(SpinWheelView));
