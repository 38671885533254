import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Tab } from 'react-bootstrap';
import Tabbed from '../Tabbed/Tabbed';
import TabHighlighted from '../TabHighlighted/TabHighlighted';
import RealtorProfileActiveMatchesTab from '../RealtorProfileActiveMatchesTab/RealtorProfileActiveMatchesTab';
import RealtorProfilePendingMatchesTab from '../RealtorProfilePendingMatchesTab/RealtorProfilePendingMatchesTab';

import './realtorprofiletabbed.css';
import './realtorprofiletabbed.mobile.css';

export const ACTIVE_MATCHES_TAB = 'active-matches';
export const PENDING_MATCHES_TAB = 'pending-matches';

export default class RealtorProfileTabbed extends React.PureComponent {
    buildActiveMatchesTab = () => {
        const {
            onChangeTab,
            realtorStatusOptions,
            onSelectStatus,
            onConfirmChangeStatus,
            buyersList,
            onUploadFile,
            onUploadSelectTypeFile,
            onRemoveFile,
            transactionContactTypes,
            onSaveContactInfo,
            onUpdateLocationInformation,
            displayBuyerProfile,
            displaySellerProfile,
            documentTypes,
            isUploading,
            isFetchedUploading,
            brokerageName,
            agentName,
            agentPhone,
            sellersList,
            realtorSellerStatusOptions,
            onSelectSellerStatus,
            onConfirmSellerChangeStatus,
            onUploadSellerFile,
            onUploadSellerSelectTypeFile,
            isUploadingSeller,
            isSellerUploadSuccessfully,
            onRemoveSellerFile,
            onSaveSellerContactInfo,
        } = this.props;

        const hasNoActiveMatches = isEmpty(buyersList) && isEmpty(sellersList);

        return (
            <Tab
                eventKey={ACTIVE_MATCHES_TAB}
                title="Active Matches"
                disabled={hasNoActiveMatches}
            >
                <RealtorProfileActiveMatchesTab
                    onChangeTab={onChangeTab}
                    realtorStatusOptions={realtorStatusOptions}
                    onSelectStatus={onSelectStatus}
                    onConfirmChangeStatus={onConfirmChangeStatus}
                    buyersList={buyersList}
                    transactionContactTypes={transactionContactTypes}
                    onUploadFile={onUploadFile}
                    onUploadSelectTypeFile={onUploadSelectTypeFile}
                    isUploading={isUploading}
                    isFetchedUploading={isFetchedUploading}
                    onRemoveFile={onRemoveFile}
                    onSaveContactInfo={onSaveContactInfo}
                    onUpdateLocationInformation={onUpdateLocationInformation}
                    displayBuyerProfile={displayBuyerProfile}
                    displaySellerProfile={displaySellerProfile}
                    documentTypes={documentTypes}
                    brokerageName={brokerageName}
                    agentName={agentName}
                    agentPhone={agentPhone}
                    sellersList={sellersList}
                    realtorSellerStatusOptions={realtorSellerStatusOptions}
                    onSelectSellerStatus={onSelectSellerStatus}
                    onConfirmSellerChangeStatus={onConfirmSellerChangeStatus}
                    onUploadSellerFile={onUploadSellerFile}
                    onUploadSellerSelectTypeFile={onUploadSellerSelectTypeFile}
                    isUploadingSeller={isUploadingSeller}
                    isSellerUploadSuccessfully={isSellerUploadSuccessfully}
                    onRemoveSellerFile={onRemoveSellerFile}
                    onSaveSellerContactInfo={onSaveSellerContactInfo}
                />
            </Tab>
        );
    };

    buildPendingMatchesTab = () => {
        const isEmptyBuyerMatchRequests = isEmpty(this.props.buyerMatchRequests);
        const tabTitleStyled = isEmptyBuyerMatchRequests
            ? 'Pending Matches' : <TabHighlighted>Pending Matches</TabHighlighted>;

        return (
            <Tab
                eventKey={PENDING_MATCHES_TAB}
                title={tabTitleStyled}
            >
                <RealtorProfilePendingMatchesTab
                    onChangeTab={this.props.onChangeTab}
                    buyerMatchDeclinedOptions={this.props.buyerMatchDeclinedOptions}
                    onConfirmMatch={this.props.onConfirmMatch}
                    onConfirmDeclineMatch={this.props.onConfirmDeclineMatch}
                    onSelectRejection={this.props.onSelectRejection}
                    buyerMatchRequests={this.props.buyerMatchRequests}
                    buyerDocuSignLink={this.props.buyerDocuSignLink}
                    hasNoActiveMatches={isEmpty(this.props.buyersList)}
                    sellerMatchDeclinedOptions={this.props.sellerMatchDeclinedOptions}
                    sellerMatchRequests={this.props.sellerMatchRequests}
                    onConfirmSellerDeclineMatch={this.props.onConfirmSellerDeclineMatch}
                    onSelectSellerRejection={this.props.onSelectSellerRejection}
                    onConfirmSellerMatch={this.props.onConfirmSellerMatch}
                />
            </Tab>
        );
    };

    buildFooter = () => (
        <footer className="realtor-profile-tabbed__footer">
            By accepting matching, you agree to the Terms of Service,
            {' '}
            Privacy Policy and Notice to Users of Consumer Reports.
        </footer>
    );

    render = () => {
        const footer = this.props.tab === PENDING_MATCHES_TAB ? this.buildFooter() : null;
        return (
            <div className="container realtor-profile-tabbed">
                <Tabbed
                    activeKey={this.props.tab}
                    onSelect={this.props.onChangeTab}
                >
                    {this.buildActiveMatchesTab()}
                    {this.buildPendingMatchesTab()}
                </Tabbed>
                {footer}
            </div>
        );
    };
}

RealtorProfileTabbed.propTypes = {
    tab: PropTypes.string,
    onChangeTab: PropTypes.func,
    realtorStatusOptions: PropTypes.array,
    onSelectStatus: PropTypes.func.isRequired,
    onConfirmChangeStatus: PropTypes.func.isRequired,
    buyerMatchDeclinedOptions: PropTypes.array,
    onConfirmMatch: PropTypes.func,
    onConfirmDeclineMatch: PropTypes.func,
    onSelectRejection: PropTypes.func,
    buyersList: PropTypes.array.isRequired,
    onUploadFile: PropTypes.func.isRequired,
    onUploadSelectTypeFile: PropTypes.func,
    isUploading: PropTypes.bool,
    isFetchedUploading: PropTypes.bool,
    onRemoveFile: PropTypes.func.isRequired,
    buyerMatchRequests: PropTypes.array.isRequired,
    downloadFilesList: PropTypes.array.isRequired,
    transactionContactTypes: PropTypes.array.isRequired,
    onSaveContactInfo: PropTypes.func.isRequired,
    onUpdateLocationInformation: PropTypes.func.isRequired,
    displayBuyerProfile: PropTypes.number,
    buyerDocuSignLink: PropTypes.string,
    documentTypes: PropTypes.array.isRequired,
    brokerageName: PropTypes.string.isRequired,
    agentName: PropTypes.string.isRequired,
    agentPhone: PropTypes.string.isRequired,
};
