import * as prospectActionTypes from "./prospectActionTypes";
import last from "lodash/last";
import split from "lodash/split";
import get from "lodash/get";
import { getQueryVariable } from "../../util/util";

const initialState = {
    voaUrl: "",
    consumerId: "",
    depositData: {},
    errorAchsMessage: "",
    fetchingAchs: false,
    fetchedAchs: false,
    errorAchs: false,
    fetchingProspect: false,
    fetchedProspect: false,
    fetchFundingSources: false,
    successFundingSources: false,
    fetchingCancelDeposit: false,
    fetchCancelDeposit: false,
    cancelDepositId: null,
    fetchingFinishDeposit: false,
    successFinishDeposit: false,
    fetchingEditProspect: false,
    successEditProspect: false,
    isFetchingSendEmails: false,
    successSendEmails: false,
    isFetchingTransferReceipt: false,
    successTransferReceipt: false,
    isFetchingWireInstructions: false,
    successWireInstructions: false,
    errorWireInstructions: false,
    isFetchingSendCertifiedInfo: false,
    successSendCertifiedInfo: false,
    errorSendCertifiedInfo: false
};

const getCustomIdFromPayload = uri => {
    const params = last(split(uri, "?"));
    return getQueryVariable(params, "consumerId");
};

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case prospectActionTypes.FETCH_RECEIVE_PROSPECT_VOA_URL:
            return {
                ...state,
                voaUrl: ""
            };

        case prospectActionTypes.RECEIVE_PROSPECT_VOA_URL: {
            const consumerId = getCustomIdFromPayload(payload);
            return {
                ...state,
                voaUrl: payload,
                consumerId
            };
        }

        case prospectActionTypes.UPDATE_PROSPECT_DEPOSIT_FETCHING:
            return {
                ...state,
                fetchingProspect: true,
                fetchedProspect: false
            };

        case prospectActionTypes.RECEIVE_PROSPECT_DEPOSIT_ID:
            return {
                ...state,
                depositData: payload,
                fetchingProspect: false,
                fetchedProspect: true
            };

        case prospectActionTypes.RECEIVE_PROSPECT_DEPOSIT_ID_ACHS_FETCHING:
            return {
                ...state,
                fetchingAchs: true,
                fetchedAchs: false,
                errorAchs: false
            };

        case prospectActionTypes.RECEIVE_PROSPECT_DEPOSIT_ID_ACHS:
            return {
                ...state,
                depositData: payload,
                fetchingAchs: false,
                fetchedAchs: true
            };

        case prospectActionTypes.ERROR_PROSPECT_DEPOSIT_ID_ACHS:
            return {
                ...state,
                errorAchsMessage: get(payload, "response.data.message"),
                fetchingAchs: false,
                fetchedAchs: false,
                errorAchs: true
            };

        case prospectActionTypes.FETCH_GET_FUNDING_SOURCES:
            return {
                ...state,
                fetchFundingSources: true,
                successFundingSources: false
            };

        case prospectActionTypes.SUCCESS_GET_FUNDING_SOURCES:
            return {
                ...state,
                fetchFundingSources: false,
                successFundingSources: true
            };

        case prospectActionTypes.FETCH_EARNEST_MONEY_DEPOSIT_CANCEL:
            return {
                ...state,
                cancelDepositId: payload,
                fetchingCancelDeposit: true,
                fetchCancelDeposit: false
            };

        case prospectActionTypes.SUCCESS_EARNEST_MONEY_DEPOSIT_CANCEL:
            return {
                ...state,
                fetchingCancelDeposit: false,
                fetchCancelDeposit: true
            };

        case prospectActionTypes.FETCH_EARNEST_MONEY_DEPOSIT_FINISH:
            return {
                ...state,
                fetchingFinishDeposit: true,
                successFinishDeposit: false
            };

        case prospectActionTypes.SUCCESS_EARNEST_MONEY_DEPOSIT_FINISH:
            return {
                ...state,
                fetchingFinishDeposit: false,
                successFinishDeposit: true
            };

        case prospectActionTypes.FETCH_EDIT_PROSPECT_INFO:
            return {
                ...state,
                fetchingEditProspect: true,
                successEditProspect: false
            };

        case prospectActionTypes.SUCCESS_EDIT_PROSPECT_INFO:
            return {
                ...state,
                fetchingEditProspect: false,
                successEditProspect: true
            };

        case prospectActionTypes.FETCH_PROSPECT_SEND_EMAILS:
            return {
                ...state,
                isFetchingSendEmails: true,
                successSendEmails: false
            };

        case prospectActionTypes.SUCCESS_PROSPECT_SEND_EMAILS:
            return {
                ...state,
                isFetchingSendEmails: false,
                successSendEmails: true
            };

        case prospectActionTypes.FETCH_TRANSFER_RECEIPT:
            return {
                ...state,
                isFetchingTransferReceipt: true,
                successTransferReceipt: false
            };

        case prospectActionTypes.SUCCESS_TRANSFER_RECEIPT:
            return {
                ...state,
                isFetchingTransferReceipt: false,
                successTransferReceipt: true
            };

        case prospectActionTypes.FETCH_PROSPECT_SEND_WIRE_INSTRUCTIONS:
            return {
                ...state,
                isFetchingWireInstructions: true,
                successWireInstructions: false,
                errorWireInstructions: false
            };

        case prospectActionTypes.SUCCESS_PROSPECT_SEND_WIRE_INSTRUCTIONS:
            return {
                ...state,
                isFetchingWireInstructions: false,
                successWireInstructions: true,
                errorWireInstructions: false
            };

        case prospectActionTypes.ERROR_PROSPECT_SEND_WIRE_INSTRUCTIONS:
            return {
                ...state,
                isFetchingWireInstructions: false,
                successWireInstructions: false,
                errorWireInstructions: true
            };

        case prospectActionTypes.FETCH_PROSPECT_SEND_CERTIFIED_INFO:
            return {
                ...state,
                isFetchingSendCertifiedInfo: true,
                successSendCertifiedInfo: false,
                errorSendCertifiedInfo: false
            };

        case prospectActionTypes.SUCCESS_PROSPECT_SEND_CERTIFIED_INFO:
            return {
                ...state,
                isFetchingSendCertifiedInfo: false,
                successSendCertifiedInfo: true,
                errorSendCertifiedInfo: false
            };

        case prospectActionTypes.ERROR_PROSPECT_SEND_CERTIFIED_INFO:
            return {
                ...state,
                isFetchingSendCertifiedInfo: false,
                successSendCertifiedInfo: false,
                errorSendCertifiedInfo: true
            };

        case prospectActionTypes.RESET_DEPOSIT:
            return {
                ...initialState
            };

        default:
            return {
                ...state
            };
    }
};

export default userReducer;
