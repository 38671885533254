import has from 'lodash/has';
import every from 'lodash/every';
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import '../../SignUp/signupform.css';

class BaseWizardStep extends Component {
    componentDidMount() {
        this._oldValues = cloneDeep(this.props.values);
    }

    componentWillUnmount() {
        const newValues = cloneDeep(this.props.values);
        this.afterStep(this._oldValues, newValues);
    }

    buildFieldError = (fieldName) => {
        const message = this.getFieldError(fieldName);
        if (message) {
            return (
                <div className="buyer__signup__field__error--content">
                    <div className="buyer__signup__field__error">{message}</div>
                </div>
            );
        }
        return null;
    };

    buildMessageError = (fieldName) => {
        const message = this.getFieldError(fieldName);
        if (message) {
            return (
                <div className="step-deposit-account__message__error--content">
                    <div className="step-deposit-account__message__error">{message}</div>
                </div>
            );
        }
        return null;
    };

    getFieldError = (fieldName) => {
        const { errors } = this.props;

        if (!this.isFieldTouched(fieldName)) return undefined;

        const message = getIn(errors, fieldName);
        if (typeof message === 'string') return message;

        return undefined;
    };

    setFieldValues = (values, path = '', touch = false) => {
        const { setFieldValue, setFieldTouched } = this.props;
        // eslint-disable-next-line no-unused-vars
        for (const [name, value] of Object.entries(values)) {
            const key = path ? [path, name].join('.') : name;
            setFieldValue(key, value);
            if (touch) setFieldTouched(key);
        }
    };

    isFieldTouched = (fieldName) => {
        const { touched } = this.props;
        const fieldTouched = getIn(touched, fieldName);

        if (typeof fieldTouched === 'boolean') return fieldTouched;
        if (typeof fieldTouched === 'object') return every(fieldTouched);

        return false;
    }

    afterStep =() => Promise.resolve(this.props.onTrackProgress());

    isFieldValid = (fieldName) => !has(this.props, ['errors', fieldName]);

    areFieldsValid = (fieldNames) => every(fieldNames, this.isFieldValid);

    renderStepButtons = (isStepFulfilled = true) => this.props.renderNavigation(isStepFulfilled)
}

BaseWizardStep.defaultProps = {
    onTrackProgress: () => {}
};

BaseWizardStep.propTypes = {
    errors: PropTypes.object,
    values: PropTypes.object,
    touched: PropTypes.object,
    onTrackProgress: PropTypes.func,
    renderNavigation: PropTypes.func,
};

export default BaseWizardStep;
