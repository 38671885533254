import * as Action from './RealtorSellerMatchActionTypes';
import get from 'lodash/get';
import map from 'lodash/map';
import reject from 'lodash/reject';

const initialState = {
    sellers: [],
    sellerMatchRequests: [],
    isNewActiveSellerMatchModalVisible: false,
    isUploadingSellerFile: false,
    isSellerFileUploadedSuccessfully: false,
};

const removeMatchFromPendings = (state, match) => ({
    ...state,
    sellerMatchRequests: reject(state.sellerMatchRequests, { userInfo: { id: match } })
});

const updateActiveSellerMatches = (state, activeMatches) => ({
    ...state,
    sellers: activeMatches
});

const updateSellerStatus = (state, sellerWithNewStatus) => ({
    ...state,
    sellers: map(state.sellers, (seller) => {
        const sellerId = get(seller, 'realtorMatchId');
        const sellerWithNewStatusId = get(sellerWithNewStatus, 'realtorMatchId');

        const sellerCurrentStatus = get(seller, 'currentStatus');
        const sellerNewStatus = get(sellerWithNewStatus, 'currentStatus');

        const sellerNotes = get(seller, 'notes');
        const sellerNewNotes = get(sellerWithNewStatus, 'notes');

        const mustUpdateStatus = sellerId === sellerWithNewStatusId;

        return {
            ...seller,
            currentStatus: mustUpdateStatus ? sellerNewStatus : sellerCurrentStatus,
            notes: mustUpdateStatus ? sellerNewNotes : sellerNotes
        };
    })
});

const displayNewSellerMatchModal = (state) => ({
    ...state,
    isNewActiveSellerMatchModalVisible: true
});

const hideNewSellerMatchModal = (state) => ({
    ...state,
    isNewActiveSellerMatchModalVisible: false
});

const startUploadingRealtorSellerFile = (state) => ({
    ...state,
    isUploadingSellerFile: true,
    isSellerFileUploadedSuccessfully: false,
});

const successfullyUploadRealtorSellerFile = (state, realtorMatchId, newFile) => ({
    ...state,
    sellers: map(state.sellers, (seller) => {
        const sellerId = get(seller, 'realtorMatchId');
        const documents = sellerId === realtorMatchId ? [...seller.documents, newFile] : seller.documents;
        return {
            ...seller,
            documents
        };
    }),
    isUploadingSellerFile: false,
    isSellerFileUploadedSuccessfully: true,
});

const successfullyRemovedRealtorSellerFile = (state, realtorMatchId, removedFileId) => ({
    ...state,
    sellers: map(state.sellers, (seller) => {
        const sellerId = get(seller, 'realtorMatchId');
        const documents = sellerId === realtorMatchId ? reject(seller.documents, { id: removedFileId }) : seller.documents;
        return {
            ...seller,
            documents
        };
    })
});

const updateRealtorSellerStatus = (state, sellerWithNewStatus) => ({
    ...state,
    sellers: map(state.sellers, (seller) => {
        const sellerMatchId = get(seller, 'realtorMatchId');
        const sellerMatchIdWithNewStatus = get(sellerWithNewStatus, 'realtorMatchId');
        const newTransactionDocuments = (
            sellerMatchId === sellerMatchIdWithNewStatus ?
            get(sellerWithNewStatus, 'transactionContacts') :
            get(seller, 'transactionContacts')
        );
        return {
            ...seller,
            transactionContacts: newTransactionDocuments
        };
    })
})

export default function RealtorSellerMatchReducer(state = initialState, action) {
    switch (action.type) {
        case Action.SUCCESSFULLY_ACCEPTED_SELLER_MATCH:
        case Action.SUCCESSFULLY_REJECTED_SELLER_MATCH:
            return removeMatchFromPendings(state, action.payload.matchId);
        case Action.SUCCESSFULLY_FETCH_ACTIVE_SELLER_MATCHES:
            return updateActiveSellerMatches(state, action.payload.activeMatches);
        case Action.SUCCESSFULLY_UPDATE_SELLER_STATUS:
            return updateSellerStatus(state, action.payload.sellerData);
        case Action.DISPLAY_NEW_ACTIVE_SELLER_MATCH_MODAL:
            return displayNewSellerMatchModal(state);
        case Action.HIDE_NEW_ACTIVE_SELLER_MATCH_MODAL:
            return hideNewSellerMatchModal(state);
        case Action.START_UPLOADING_REALTOR_SELLER_FILE:
            return startUploadingRealtorSellerFile(state);
        case Action.SUCCESSFULLY_UPLOAD_REALTOR_SELLER_FILE:
            return successfullyUploadRealtorSellerFile(
                state,
                action.payload.realtorMatchId,
                action.payload.file
            );
        case Action.SUCCESSFULLY_REMOVE_REALTOR_SELLER_FILE_ATTACHMENT:
            return successfullyRemovedRealtorSellerFile(
                state,
                action.payload.realtorMatchId,
                action.payload.fileId
            );
        case Action.SUCCESSFULLY_UPDATE_REALTOR_SELLER_STATUS:
            return updateRealtorSellerStatus(state, action.payload.sellerData);
        default:
            return state;
    }
}