import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import React from 'react';
import ProfileTabBase from './ProfileTabBase';
import {Button} from '../../Button/Button';
import { chooseAgent, chooseSellerAgent } from '../../../util/service_api';
import UserRealtorProfile from '../UserRealtorProfile';
import AgentsInfo from '../../AgentsInfo/AgentsInfo';
import Spinner from '../../Spinner/Spinner';

export default class AgentTab extends ProfileTabBase {
    handleSelectAgent = (match) => {
        const isSeller = this.getProfileProperty('userInfo.seller');
        const isBuyer = this.getProfileProperty('userInfo.buyer');

        if(isSeller) {
            chooseSellerAgent(match.id)
                .then(() => {
                    if (isFunction(this.context.onChooseAgent)) {
                        this.context.onChooseAgent(match.agent);
                    }
                });
            return;
        }

        if(isBuyer) {
            chooseAgent(match.id)
                .then(() => {
                    if (isFunction(this.context.onChooseAgent)) {
                        this.context.onChooseAgent(match.agent);
                    }
                });
            return;
        }
    }

    handleFindMatches = () => this.context.searchForAgentMatches();

    buildYouAgentSection = () =>
        <div className="profile__personal__container">
            <div className="profile__personal__section is-full-width">
                <section>
                    <UserRealtorProfile
                        isPublicView
                        className="profile__personal__embedded__realtor"
                        profile={{ publicProfile: this.getUserValue('realtor') }}
                    />
                </section>
            </div>
        </div>;

    buildAgentMatches = () => (
        <AgentsInfo
            matches={this.getUserValue('realtorMatches')}
            canSelectAgent={true}
            onChooseAgent={this.context.onChooseAgent}
            profile={this.context.profile}
            handleSelectAgent={this.handleSelectAgent}
        />
    )

    buildNoMatchesFound = () =>
        <div className="profile__personal__container">
            <div
                className="profile__personal__section"
                style={{
                    display: 'flex',
                    paddingBottom: '40px',
                    margin: '0 auto'
                }}
            >
                <div className="profile__message is-centred">
                    <div className="profile__section__title">
                        We're Working On It!
                    </div>

                    <p>
                        Not to worry, The BrewCrew is working on finding you the best match and will reach out to you shortly. Thank you for your patience.
                    </p>

                </div>
            </div>
        </div>;

    buildFindAgentsSection = () =>
        <div className="profile__personal__container">
            <div
                className="profile__personal__section"
                style={{
                    display: 'flex',
                    paddingBottom: '40px',
                    margin: '0 auto'
                }}
            >
                <div className="profile__message is-centred">
                    <div className="profile__section__title">
                        Find your agent
                    </div>

                    <p>
                        We will match you with up to 3 agents.
                    </p>

                    <br/>

                    <Button
                        onClick={this.handleFindMatches}
                    >
                        Find My Agent
                    </Button>
                </div>
            </div>
        </div>;

    buildLoader = () => (
        <div className="profile__agents__loader">
            <Spinner />
        </div>
    );

    render() {
        const realtor = this.getUserValue('realtor');
        const matches = this.getUserValue('realtorMatches');
        const hasAccepted = this.getUserValue('hasRealtorAcceptedMatch');
        const hasAgent = realtor != null;
        const hasMatches = !isEmpty(matches);

        if (this.context.isFetchingAgents) return this.buildLoader();
        if (hasAgent && hasAccepted) return this.buildYouAgentSection();
        if (hasMatches) return this.buildAgentMatches();
        if (this.context.didSearchForMatches) return this.buildNoMatchesFound();

        return this.buildFindAgentsSection();
    }
}
