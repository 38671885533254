import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import includes from "lodash/includes";
import isFunction from "lodash/isFunction";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { Button } from "../Button/Button";
import CloseModalButton from "../CloseModalButton/CloseModalButton";
import {
    EMD_WIRE_INSTRUCTION_MODAL,
    REGEXP_VALIDATION_EMAIL
} from "../../shared/constants";
import { getAddressFromPlaceDetails } from "../../util/util";
import AutoCompleteAddressInput from "../AutoCompleteAddressInput/AutoCompleteAddressInput";
import mailbox from "../../styles/assets/Inbox-Icon.svg";
import mixpanel from "mixpanel-browser";
import Recaptcha from "../Recaptcha/Recaptcha";

import "./emdWireModal.css";

export default class EmdWireModal extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        onSend: PropTypes.func,
        isSending: PropTypes.bool,
        isSended: PropTypes.bool,
        transactionContactTypes: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isVisible !== this.props.isVisible) {
            this.resetState();
        }
    }

    markRecaptchaFieldAsInvalid = () => {
        this.setState({
            recaptchaState: false
        });
    };

    markRecaptchaFieldAsValid = () => {
        this.setState({
            recaptchaState: true
        });
    };

    resetState = () => this.setState(this.getInitialState());

    getInitialState = () => ({
        firstName: "",
        lastName: "",
        email: "",
        apartmentSuiteNumber: "",
        currentAddressDetails: {
            address: "",
            neighborhood: "",
            city: "",
            state: "",
            zipcode: ""
        },
        recaptchaState: false,
        currentAddress: undefined,
        firstNameError: null,
        lastNameError: null,
        emailError: null,
        currentAddressError: null
    });

    onChangeFirstName = e =>
        this.setState({ firstName: e.target.value, firstNameError: null });

    onChangeLastName = e =>
        this.setState({ lastName: e.target.value, lastNameError: null });

    onChangeEmail = e =>
        this.setState({ email: e.target.value, emailError: null });

    onChangeApartment = e =>
        this.setState({ apartmentSuiteNumber: e.target.value });

    onChangeCurrentAddress = e => {
        this.setState({ currentAddress: e, currentAddressError: null });
        if (isEmpty(e)) {
            this.setErrorMessageFieldRequired(
                "currentAddress",
                !isEmpty(e),
                "Address"
            );
        }
    };

    buildErrorMessage = error => (
        <div className="emd-wire-modal__password--error">
            <div className="emd-wire-modal__field-error--content">
                <div className="emd-wire-modal__field-error">{error}</div>
            </div>
        </div>
    );

    setErrorMessageFieldRequired = (field, value, message) => {
        const newValue = !value
            ? EMD_WIRE_INSTRUCTION_MODAL["required"](message)
            : null;
        this.setState({ [`${field}Error`]: newValue });
    };

    setErrorMessage = (field, type) => {
        const newValue = EMD_WIRE_INSTRUCTION_MODAL[type];
        this.setState({ [`${field}Error`]: newValue });
    };

    validateErrorMessage = field => {
        const errorMessage = get(this.state, `${field}Error`);
        return errorMessage ? this.buildErrorMessage(errorMessage) : null;
    };

    validateEmail = field => {
        const element = get(this.state, field);

        if (!isEmpty(element)) {
            if (!REGEXP_VALIDATION_EMAIL.test(element)) {
                this.setErrorMessage(field, "email");
                return;
            }
        }

        if (isEmpty(element)) {
            this.setErrorMessageFieldRequired(field, element, "Email");
            return;
        }

        this.setState({ [`${field}Error`]: null });
    };

    buildFirstNameField = () => {
        const { firstName } = this.state;
        return (
            <div className="form-group emd-wire-modal__container">
                <label
                    className="emd-wire-modal__form-label--first-name"
                    htmlFor="firstNameLabel"
                >
                    First Name
                    <input
                        id="firstName"
                        type="text"
                        className="form-control emd-wire-modal__form-field"
                        value={firstName}
                        onChange={this.onChangeFirstName}
                        onBlur={() =>
                            this.setErrorMessageFieldRequired(
                                "firstName",
                                firstName,
                                "First Name"
                            )
                        }
                    />
                </label>
                {this.validateErrorMessage("firstName")}
            </div>
        );
    };

    validateAddress = addressSelected => {
        const addressType = get(addressSelected, "types");
        return includes(addressType, "street_address");
    };

    onPlaceDetails = ([details] = [], value) => {
        const addressComponents = get(details, "address_components" || {});
        const { address, city, state, zipcode } = getAddressFromPlaceDetails(
            details
        );
        if (!this.validateAddress(details)) {
            this.setErrorMessage("currentAddress", "address");
        }
        if (isEmpty(addressComponents)) {
            this.setErrorMessageFieldRequired(
                "currentAddress",
                !isEmpty(addressComponents),
                "Address"
            );
        }
        this.setState({
            currentAddressDetails: { address, city, state, zipcode },
            currentAddress: value
        });
        this.setState({ hasAddress: !isEmpty(addressComponents) });
    };

    buildAddress = () => {
        const { currentAddress } = this.state;
        return (
            <div className="form-group emd-wire-modal__container without-padding-top col-xs-8">
                <AutoCompleteAddressInput
                    value={currentAddress}
                    customOnChange={this.onChangeCurrentAddress}
                    onPlaceDetails={this.onPlaceDetails}
                    className="emd-wire-modal__form-field"
                />
                {this.validateErrorMessage("currentAddress")}
            </div>
        );
    };

    buildApartment = () => {
        const { apartmentSuiteNumber } = this.state;
        return (
            <div className="form-group emd-wire-modal__container without-padding-top col-xs-4">
                <input
                    id="apartmentSuiteNumber"
                    type="text"
                    className="form-control emd-wire-modal__form-field"
                    value={apartmentSuiteNumber}
                    onChange={this.onChangeApartment}
                />
            </div>
        );
    };

    buildLastNameField = () => {
        const { lastName } = this.state;
        return (
            <div className="form-group emd-wire-modal__container">
                <label
                    className="emd-wire-modal__form-label--last-name"
                    htmlFor="firstNameLabel"
                >
                    Last Name
                    <input
                        id="lastName"
                        type="text"
                        className="form-control emd-wire-modal__form-field"
                        value={lastName}
                        onChange={this.onChangeLastName}
                        onBlur={() =>
                            this.setErrorMessageFieldRequired(
                                "lastName",
                                lastName,
                                "Last Name"
                            )
                        }
                    />
                </label>
                {this.validateErrorMessage("lastName")}
            </div>
        );
    };

    buildEmailField = () => {
        const { email } = this.state;
        return (
            <div className="form-group emd-wire-modal__container emd-wire-modal__container-email">
                <label
                    className="emd-wire-modal__form-label--email"
                    htmlFor="addressLabel"
                >
                    Your email
                    <input
                        id="email"
                        type="email"
                        className="form-control emd-wire-modal__form-field"
                        value={email}
                        onChange={this.onChangeEmail}
                        onBlur={() => this.validateEmail("email")}
                    />
                </label>
                {this.validateErrorMessage("email")}
            </div>
        );
    };

    buildRecaptchaField = () => {
        return (
            <Recaptcha
                onSuccessfullyCompleted={this.markRecaptchaFieldAsValid}
                onError={this.markRecaptchaFieldAsInvalid}
            />
        );
    };

    titleAddress = () => (
        <div className="emd-wire-modal__address-text-container">
            <div className="emd-wire-modal__address-text">
                Enter the property address associated with this transfer
            </div>
        </div>
    );

    buildAddressBlock = () => (
        <div className="emd-wire-modal__address">
            {this.titleAddress()}
            <div className="emd-wire-modal__address-container">
                {this.buildAddress()}
                {this.buildApartment()}
            </div>
        </div>
    );

    buildForm = () => (
        <div>
            <div className="row emd-wire-modal__fields">
                <div className="emd-wire-modal">{this.buildAddressBlock()}</div>
                <div className="emd-wire-modal col-xs-6">
                    {this.buildFirstNameField()}
                </div>
                <div className="emd-wire-modal col-xs-6">
                    {this.buildLastNameField()}
                </div>
                <div className="emd-wire-modal col-xs-12">
                    {this.buildEmailField()}
                </div>
                <div className="emd-wire-modal col-xs-12 recaptcha-plh">
                    {this.buildRecaptchaField()}
                </div>
            </div>
        </div>
    );

    haveEmptyFields = () => {
        const { firstName, lastName, email, currentAddress } = this.state;

        return !(firstName && lastName && email && currentAddress);
    };

    haveErrors = () => {
        const {
            firstNameError,
            lastNameError,
            emailError,
            currentAddressError,
            recaptchaState
        } = this.state;

        return (
            firstNameError ||
            lastNameError ||
            emailError ||
            currentAddressError ||
            recaptchaState !== true
        );
    };

    isDisableSendButton = () => {
        const { isSending, isSended } = this.props;
        return (
            this.haveEmptyFields() || this.haveErrors() || isSending || isSended
        );
    };

    onSend = () => {
        const { onSend } = this.props;
        const {
            firstName,
            lastName,
            email,
            currentAddressDetails,
            apartmentSuiteNumber
        } = this.state;
        const contact = find(
            this.props.transactionContactTypes,
            item => get(item, "name") === "earnest_money_deposit_wire"
        );

        if (isFunction(onSend)) {
            this.setState({ isSendInit: true });
            const data = {
                transactionContact: {
                    contact: {
                        firstName,
                        lastName,
                        email
                    },
                    transactionContactType: contact
                },
                address: {
                    streetNumberName: currentAddressDetails.address,
                    apartmentSuiteNumber: apartmentSuiteNumber,
                    city: currentAddressDetails.city,
                    state: currentAddressDetails.state,
                    zipcode: currentAddressDetails.zipcode
                }
            };
            onSend(data).then(() => {
                mixpanel.track("Completed Send Wire Information", {
                    transactionContactFirstName: firstName,
                    transactionContactLastName: lastName,
                    transactionContactEmail: email,
                    transactionContactTypeId: contact.id,
                    transactionContactTypeName: contact.name,
                    transactionContactTypeDisplayName: get(
                        contact,
                        "displayName"
                    ),
                    transactionContactTypeState: get(contact, "state"),
                    streetNumberName: get(currentAddressDetails, "address"),
                    apartmentSuiteNumber: apartmentSuiteNumber,
                    city: get(currentAddressDetails, "city"),
                    state: get(currentAddressDetails, "state"),
                    zipcode: get(currentAddressDetails, "zipcode")
                });
            });
        }
    };

    buildWireModal = () => (
        <>
            <h1 className="emd-wire-modal__title">
                Please complete the form below to receive wiring instructions
            </h1>
            {this.buildForm()}
            <div className="emd-wire-modal__footer">
                <Button inverted onClick={this.props.onClose}>
                    Cancel
                </Button>
                <Button
                    onClick={this.onSend}
                    disabled={this.isDisableSendButton()}
                    isLoading={this.props.isSending}
                >
                    SEND
                </Button>
            </div>
        </>
    );

    buildModalMailInfo = () => (
        <Fragment>
            <div className="emd-wire-modal__mail-information">
                We&#39;ve sent a message to you with wiring instructions to
                complete the transfer of funds. If you don&#39;t see an email
                from us within a few minutes, be sure to check your spam folder.
                <br />
                <br />
                But If you don&#39;t see the email send us a message at &nbsp;
                <a href="mailto:closings@lemonbrew.com">
                    closings@lemonbrew.com
                </a>{" "}
                for assistance.
                <br />
                <br />
                <img src={mailbox} alt="mailbox" />
            </div>
            <Button
                yellow
                onClick={() => {
                    window.location = "https://www.lemonbrewabstract.com ";
                }}
            >
                Done
            </Button>
        </Fragment>
    );

    render = () => {
        const { isSended, onClose, isVisible } = this.props;

        const buildContent = isSended
            ? this.buildModalMailInfo()
            : this.buildWireModal();

        return (
            <Modal
                animation
                autoFocus
                backdrop
                enforceFocus
                show={isVisible}
                className="emd-wire-modal"
            >
                <ModalHeader>
                    <CloseModalButton onClick={onClose} />
                </ModalHeader>
                <ModalBody>{buildContent}</ModalBody>
            </Modal>
        );
    };
}
