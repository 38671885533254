import get from "lodash/get";
import React, { Fragment } from "react";
import { Field } from "formik";
import { yesNo } from "../../../../shared/constants";
import FormikBuyerFlowRadioButton from "../../../BuyerFlowButton/FormikBuyerFlowRadioButton";
import BaseWizardStep from "../BaseWizardStep";
import mixpanel from "mixpanel-browser";

class StepPlanSelling extends BaseWizardStep {
    mixpanelFunction = () => {
        const { values } = this.props;
        const buyerIsSellingCurrentHome =
            get(values, "buyerIsSellingCurrentHome") === "yes" ? true : false;

        mixpanel.track("Completed Do You Plan On Selling Your Current Home?", {
            buyerIsSellingCurrentHome: buyerIsSellingCurrentHome
        });
    };

    stepPlanSellingNext = () => {
        setTimeout(
            () => this.props.onNavigateStep(1, true, this.mixpanelFunction),
            0
        );
    };

    render() {
        const isStepFulfilled = this.areFieldsValid([
            "buyerIsSellingCurrentHome"
        ]);

        return (
            <Fragment>
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__question white-font">
                        Do you plan on selling your current home?
                    </div>
                    <div className="buyer__signup__fieldset">
                        <div className="buyer__signup__field__row">
                            <div className="buyer__signup__field display-flex">
                                <Field
                                    name="buyerIsSellingCurrentHome"
                                    className="is-horizontal"
                                    options={yesNo}
                                    component={FormikBuyerFlowRadioButton}
                                />
                                {/* {this.renderFieldError(
                                    "buyerIsSellingCurrentHome"
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderStepButtons({
                    canProceed: isStepFulfilled,
                    hideNextButton: false,
                    handleNext: this.stepPlanSellingNext
                })}
            </Fragment>
        );
    }
}

export default StepPlanSelling;
