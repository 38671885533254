import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import { format } from "date-fns";

import "./realtormatchprofilenotes.css";

export default class RealtorMatchProfileNotes extends React.PureComponent {
    static propTypes = {
        notes: PropTypes.array,
        dateFormat: PropTypes.string
    };

    static defaultProps = {
        dateFormat: "MM/DD/YYYY HH:MM:SS"
    };

    buildNote = note => {
        const id = get(note, "id");
        const message = get(note, "note");
        const date = get(note, "ts");
        const formattedDate = format(date, this.props.dateFormat);

        return (
            <div key={id} className="realtor-match-profile-notes__content-body">
                <div className="col-xs-12 col-md-8 col-sm-8">
                    <div className="realtor-match-profile-notes__note-info">
                        {message}
                    </div>
                </div>
                <div className="realtor-match-profile-notes__date col-xs-12 col-md-8 col-sm-8">
                    <div className="realtor-match-profile-notes__date-info">
                        {formattedDate}
                    </div>
                </div>
            </div>
        );
    };

    buildNotes = () => map(this.props.notes, this.buildNote);

    render = () => (
        <div className="realtor-match-profile-notes">
            <h3 className="realtor-match-profile-notes__title">
                Notes and Activities
            </h3>
            <div className="realtor-match-profile-notes__content-head">
                <div className="col-xs-12 col-md-8 col-sm-8">
                    <div className="realtor-match-profile-notes__note-label">
                        Note
                    </div>
                </div>
                <div className="realtor-match-profile-notes__date col-xs-12 col-md-8 col-sm-8">
                    <div className="realtor-match-profile-notes__date-label">
                        Date
                    </div>
                </div>
            </div>
            <div className="realtor-match-profile-notes__notes-container">
                {this.buildNotes()}
            </div>
        </div>
    );
}
