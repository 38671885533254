import React from 'react';
// import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import axios from 'axios'
import { Button } from '../Button/Button';

export default class ProspectUploadDocumentButton extends React.Component {
    constructor() {
        super();
        this.onDropAccepted = (files) => {
            this.setState({ files })
        };
        this.state = {
            files: []
        };
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        file: null,
        isChooseFile: false,
        nameFile: 'No file chosen',
        sizeFile: 0,
        maxFileSize: 10000000,
    });

    toastId = null;
    success = () => this.toastId = toast(`"File uploaded!"`, { type: toast.TYPE.SUCCESS, autoClose: 2000,hideProgressBar: true });

    handleDrop = files => {
        const uploaders = files.map(file => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("timestamp", (Date.now() / 1000) | 0);
            // this.setState(this.notify)

            return axios.post("api/brewcrew/prospect/upload", formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }).then(response => {
                const data = response.data;
                console.log(data);
            })
        });

        // Once all the files are uploaded 
        axios.all(uploaders).then(() => {
            this.setState(this.success)
            // ... perform after upload is successful operation
        });
    }

    render = () => {
        return (
            <Dropzone
                onDropAccepted={this.handleDrop}
                accept=".csv, application/vnd.ms-excel, text/csv"
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button inverted>
                            IMPORT
                        </Button>
                    </div>
                )}
            </Dropzone>
        );
    }
};