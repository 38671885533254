import findIndex from "lodash/findIndex";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import get from "lodash/get";
import set from "lodash/set";
import forEach from "lodash/forEach";
import pickBy from "lodash/pickBy";
import PropTypes from "prop-types";
import { Form } from "formik";
import { Button } from "../../../components/Button/Button";
import StepPropertyAddress from "./StepPropertyAddress";
import StepSummary from "./StepSummary";
import mixpanel from "mixpanel-browser";

import "../../SignUp/BuyerSignUp/buyer-signup-form.css";
import "./index.css";

const prospectDepositSteps = [
    {
        key: "property_address",
        label: "Property Address",
        component: StepPropertyAddress
    },
    { key: "summary", label: "Summary", component: StepSummary }
];

class WizardStepsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            returnToProfile: false,
            isCancel: false,
            fromPreviousStep: false
        };
    }

    componentDidMount() {
        const {
            status: { currentStep = "" }
        } = this.props;
        if (currentStep) {
            // Initialize first step
            const step = findIndex(prospectDepositSteps, { key: currentStep });
            if (step > 0) this.setState({ step });
        }

        this.props.validateForm();
    }

    getNextStep(delta = 1, step = this.state.step) {
        for (
            let next = step + delta;
            next >= 0 && next <= prospectDepositSteps.length - 1;
            next += delta
        ) {
            const { component } = prospectDepositSteps[next];
            if (!(component.skip && component.skip(this.props, this.state))) {
                return next;
            }
        }
        return null;
    }

    goToPreviousStep = (isComplete, isSkipped, index) => {
        const { isAuthenticated } = this.props;
        const { startFrom } = this.state;
        if (isAuthenticated && startFrom <= index) {
            if (isComplete && !isSkipped) {
                this.setState({ step: index });
            }
        }
    };

    submit = () => {
        const { submitForm } = this.props;
        submitForm();
    };

    trackMixpanelClickOn = button => {
        mixpanel.track(`Clicked On ${button}`);
    };

    renderStepButtons = options => {
        const { step, returnToProfile, fromPreviousStep } = this.state;
        const { isSubmitting, fetchingAchs, isEdit } = this.props;
        const hasNextStep = this.getNextStep() !== null;
        const editOrPreviousStep = !isEdit && !fromPreviousStep;
        const cancelButton = hasNextStep && editOrPreviousStep && (
            <Button
                type="button"
                inverted
                onClick={() => this.setState({ isCancel: true })}
            >
                Cancel
            </Button>
        );

        let canProceed;
        let notPreviousStep = false;
        let hasAddress = false;
        if (typeof options === "boolean") {
            canProceed = options;
        } else {
            canProceed = options.canProceed || false;
            notPreviousStep = options.notPreviousStep || false;
            hasAddress = options.hasAddress || false;
        }
        const nextTextButton = hasNextStep ? "Continue" : "Confirm";

        return (
            <div className="buyer__signup__controls transfer-details">
                {
                    <Button
                        type="button"
                        text
                        disabled={!hasAddress}
                        onClick={() => {
                            this.update(1);
                            this.trackMixpanelClickOn("Finish Later");
                            this.setState({ returnToProfile: true });
                        }}
                    >
                        {returnToProfile ? (
                            <i
                                style={{ textAlign: "center", padding: 0 }}
                                className="fas fa-spinner fa-spin"
                            />
                        ) : (
                            <>FINISH LATER</>
                        )}
                    </Button>
                }

                {cancelButton}

                {step < 1 || notPreviousStep ? null : (
                    <Button
                        type="button"
                        inverted
                        onClick={() => {
                            this.trackMixpanelClickOn("Modify");
                            this.update(-1);
                        }}
                    >
                        MODIFY
                    </Button>
                )}

                {
                    <Button
                        type="button"
                        disabled={!canProceed}
                        className={` ${!canProceed ? "is-disabled" : ""}`}
                        onClick={
                            hasNextStep
                                ? () => {
                                      this.update(1);
                                      this.trackMixpanelClickOn(nextTextButton);
                                      this.setState({ fromPreviousStep: true });
                                  }
                                : () => this.submit()
                        }
                    >
                        {isSubmitting || (fetchingAchs && !returnToProfile) ? (
                            <i
                                style={{ textAlign: "center", padding: 0 }}
                                className="fas fa-spinner fa-spin"
                            />
                        ) : (
                            <>{nextTextButton}</>
                        )}
                    </Button>
                }
            </div>
        );
    };

    getContactInfo = (response, type) => {
        let contact = {};
        set(contact, `${type}Id`, get(response, `${type}.id`));
        set(contact, `${type}ContactId`, get(response, `${type}.contact.id`));
        set(
            contact,
            `${type}ContactFirstName`,
            get(response, `${type}.contact.firstName`)
        );
        set(
            contact,
            `${type}ContactLastName`,
            get(response, `${type}.contact.lastName`)
        );
        set(
            contact,
            `${type}TransactionContactTypeId`,
            get(response, `${type}.transactionContactType.id`)
        );
        set(
            contact,
            `${type}TransactionContactTypeName`,
            get(response, `${type}.transactionContactType.name`)
        );
        set(
            contact,
            `${type}TransactionContactTypeDisplayName`,
            get(response, `${type}.transactionContactType.displayName`)
        );
        set(
            contact,
            `${type}TransactionContactTypeState`,
            get(response, `${type}.transactionContactType.state`)
        );
        return contact;
    };

    trackMixpanel = response => {
        let achs = {};
        forEach(get(response, "achs"), (value, index) => {
            set(achs, `id${index}`, get(value, "id"));
            set(
                achs,
                `bankAccountId${index}`,
                get(value, "plaidBankAccount.id")
            );
            set(
                achs,
                `bankAccountTypeId${index}`,
                get(value, "plaidBankAccount.type.id")
            );
            set(
                achs,
                `bankAccountTypeName${index}`,
                get(value, "plaidBankAccount.type.name")
            );
            set(achs, `balance${index}`, get(value, "balance"));
            set(achs, `institutionId${index}`, get(value, "institution.id"));
            set(
                achs,
                `institutionName${index}`,
                get(value, "institution.name")
            );
            set(
                achs,
                `validFundingSourceTypeForAch${index}`,
                get(value, "id") ? "Yes" : "No"
            );
        });
        const contactSeller = this.getContactInfo(response, "seller");
        const contactSpouse = this.getContactInfo(response, "spouse");

        mixpanel.track("Completed Create Deposit", {
            id: get(response, "id"),
            totalAmount: get(response, "totalAmount"),
            addressId: get(response, "subjectPropertyAddress.id"),
            addressStreetNumberName: get(
                response,
                "subjectPropertyAddress.streetNumberName"
            ),
            addressApartmentSuiteNumber: get(
                response,
                "subjectPropertyAddress.apartmentSuiteNumber"
            ),
            addressCity: get(response, "subjectPropertyAddress.city"),
            addressZipcode: get(response, "subjectPropertyAddress.zipcode"),
            addressTs: get(response, "subjectPropertyAddress.ts"),
            addressFull: get(response, "subjectPropertyAddress.full"),
            addressLongitude: get(response, "subjectPropertyAddress.longitude"),
            addressLatitude: get(response, "subjectPropertyAddress.latitude"),
            addressStateId: get(response, "subjectPropertyAddress.state.id"),
            addressStateAbbreviation: get(
                response,
                "subjectPropertyAddress.state.abbreviation"
            ),
            addressStateName: get(
                response,
                "subjectPropertyAddress.state.name"
            ),
            addressStateLongitute: get(
                response,
                "subjectPropertyAddress.state.longitute"
            ),
            addressStateLatitude: get(
                response,
                "subjectPropertyAddress.state.latitude"
            ),
            addressStateZoomLevel: get(
                response,
                "subjectPropertyAddress.state.zoomLevel"
            ),
            addressNeighborhood: get(
                response,
                "subjectPropertyAddress.neighborhood"
            ),
            statusId: get(response, "status.id"),
            statusType: get(response, "status.type"),
            statusName: get(response, "status.name"),
            ...achs,
            typeId: get(response, "type.id"),
            typeName: get(response, "type.name"),
            typeDisplayName: get(response, "type.displayName"),
            memo: get(response, "memo"),
            anticipatedClosingDate: get(response, "anticipatedClosingDate"),
            complete: get(response, "complete") ? "Yes" : "No",
            inProgress: get(response, "inProgress") ? "Yes" : "No",
            ...contactSeller,
            ofTypeEarnestMoneyDeposit: get(
                response,
                "ofTypeEarnestMoneyDeposit"
            )
                ? "Yes"
                : "No",
            ...contactSpouse,
            eligibleForCancel: get(response, "eligibleForCancel")
                ? "Yes"
                : "No",
            ofTypeCashAtClose: get(response, "ofTypeCashAtClose")
                ? "Yes"
                : "No",
            ofTypeReferralFeeAgent: get(response, "ofTypeReferralFeeAgent")
                ? "Yes"
                : "No",
            verifiedTransferAmount: get(response, "verifiedTransferAmount")
                ? "Yes"
                : "No"
        });
    };

    update = delta => {
        const {
            isEdit,
            editEMD,
            values,
            mapValuesToPayload,
            mapValuesToPayloadEdit,
            depositData,
            updateDepositProspect,
            newDepositProspect,
            mapValuesToPayloadAchs,
            earnestMoneyDepositTypes,
            transactionContactTypes
        } = this.props;
        const { isCancel } = this.state;
        const data = isEdit
            ? mapValuesToPayloadEdit(
                  values,
                  earnestMoneyDepositTypes,
                  transactionContactTypes
              )
            : mapValuesToPayload(
                  values,
                  earnestMoneyDepositTypes,
                  transactionContactTypes
              );
        const achs = mapValuesToPayloadAchs(values);
        const depositId = !isEdit ? get(depositData, "id") : get(editEMD, "id");

        if (isCancel) return;

        if (depositId || isEdit) {
            updateDepositProspect(data, depositId, achs).then(response => {
                this.trackMixpanel(get(response, "response"));
                if (get(response, "status") === "success") {
                    this.navigateStep(delta);
                }
            });
        } else {
            newDepositProspect(data, achs).then(response => {
                this.trackMixpanel(get(response, "response"));
                if (get(response, "status") === "success") {
                    this.navigateStep(delta);
                }
            });
        }
    };

    navigateStep = delta => {
        const step = this.getNextStep(delta);
        this.setState({ step });
    };

    isReturnToProfile = () => {
        const {
            status: { formState = "initial" },
            error,
            errorAchs,
            fetchedAchs
        } = this.props;
        const { returnToProfile, isCancel } = this.state;
        const isSubmited = formState === "submitted";
        const isFetchedAchsAndReturnToProfile = returnToProfile && fetchedAchs;
        const insufficientFunds = returnToProfile && errorAchs;
        return (
            isSubmited ||
            error ||
            isFetchedAchsAndReturnToProfile ||
            isCancel ||
            insufficientFunds
        );
    };

    trackProgress = () => {
        const { values, mapValuesToPayload, notifyUpdate } = this.props;

        if (!(mapValuesToPayload && notifyUpdate)) return;

        const data = this.props.mapValuesToPayload(values);
        const payload = pickBy(
            data,
            value =>
                !(
                    value === undefined ||
                    (typeof value === "number" && isNaN(value)) ||
                    (typeof value === "string" && value.trim().length === 0)
                )
        );

        return notifyUpdate(payload);
    };

    render() {
        const { fetchedAchs, loadUserProfile, errorAchs } = this.props;
        const { returnToProfile } = this.state;

        if (
            (returnToProfile && fetchedAchs) ||
            (returnToProfile && errorAchs)
        ) {
            loadUserProfile();
        }

        if (this.isReturnToProfile()) return <Redirect to="/profile" />;

        const StepComponent = prospectDepositSteps[this.state.step].component;

        return (
            <>
                <Form className="buyer__signup">
                    <StepComponent
                        {...this.props}
                        renderNavigation={this.renderStepButtons}
                        onTrackProgress={this.trackProgress}
                    />
                </Form>
            </>
        );
    }
}

WizardStepsForm.propTypes = {
    status: PropTypes.object,
    userProfile: PropTypes.object,
    userInfo: PropTypes.object,
    isSubmitting: PropTypes.bool,
    submitForm: PropTypes.func,
    validateForm: PropTypes.func,
    mapValuesToPayload: PropTypes.func,
    mapValuesToPayloadAchs: PropTypes.func,
    mapValuesToPayloadEdit: PropTypes.func,
    notifyUpdate: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    voaUrl: PropTypes.string,
    depositData: PropTypes.object,
    updateDepositProspect: PropTypes.func,
    newDepositProspect: PropTypes.func,
    fetchingAchs: PropTypes.bool,
    fetchedAchs: PropTypes.bool,
    loadUserProfile: PropTypes.func,
    earnestMoneyDepositTypes: PropTypes.object,
    transactionContactTypes: PropTypes.object,
    editEMD: PropTypes.object,
    isEdit: PropTypes.bool,
    errorAchs: PropTypes.bool,
    errorAchsMessage: PropTypes.string
};

export default WizardStepsForm;
