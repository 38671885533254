export const GET_USERS_AS_ADMIN_FETCH = "GET_USERS_AS_ADMIN_FETCH";
export const GET_USERS_AS_ADMIN_SUCCESS = "GET_USERS_AS_ADMIN_SUCCESS";
export const GET_USERS_AS_ADMIN_ERROR = "GET_USERS_AS_ADMIN_ERROR";
export const CREATE_NEW_ADMIN_FETCH = "CREATE_NEW_ADMIN_FETCH";
export const CREATE_NEW_ADMIN_SUCCESS = "CREATE_NEW_ADMIN_SUCCESS";
export const CREATE_NEW_ADMIN_ERROR = "CREATE_NEW_ADMIN_ERROR";
export const CREATE_NEW_ADMIN_RESET = "CREATE_NEW_ADMIN_RESET";
export const LOGIN_IMPERSONATE_FETCH = "LOGIN_IMPERSONATE_FETCH";
export const LOGIN_IMPERSONATE_SUCCESS = "LOGIN_IMPERSONATE_SUCCESS";
export const LOGIN_IMPERSONATE_ERROR = "LOGIN_IMPERSONATE_ERROR";
export const LOGOUT_IMPERSONATE_FETCH = "LOGOUT_IMPERSONATE_FETCH";
export const LOGOUT_IMPERSONATE_SUCCESS = "LOGOUT_IMPERSONATE_SUCCESS";
export const RECEIVE_IMPERSONATE_USER_PROFILE =
    "RECEIVE_IMPERSONATE_USER_PROFILE";
export const FETCH_IMPERSONATE_USER_PROFILE = "FETCH_IMPERSONATE_USER_PROFILE";
export const GET_LICENSES = "GET_LICENSES";
export const GET_LICENSES_SUCCESS = "GET_LICENSES_SUCCESS";
export const GET_ROLES_FETCH = "GET_ROLES_FETCH";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";
export const GET_BREWCREW_USERS_FETCH = "GET_BREWCREW_USERS_FETCH";
export const GET_BREWCREW_USERS_SUCCESS = "GET_BREWCREW_USERS_SUCCESS";
export const GET_BREWCREW_USERS_ERROR = "GET_BREWCREW_USERS_ERROR";
export const GET_STATS = "GET_STATS";
export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const SET_SEARCH_PARAMETERS = "SET_SEARCH_PARAMETERS";
export const GET_BROKERAGE = "GET_BROKERAGE";
export const GET_BROKERAGE_SUCCESS = "GET_BROKERAGE_SUCCESS";
