import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { ProfilePicture } from "../../ProfilePicture/ProfilePicture";

const buildAgentName = props => {
    const email = get(props.user, "userInfo.email");
    const emailLink = `mailto: ${email}`;

    return (
        <div className="admin-role-tables__agent-name">
            <div className="admin-role-tables__agent-full-name">
                {`${get(props.user, "userInfo.firstName")} ${get(
                    props.user,
                    "userInfo.lastName"
                )}`}
            </div>
            {props.showEmail ? (
                <div className="admin-role-tables__agent-email">
                    <a href={emailLink}>{email}</a>
                </div>
            ) : null}
        </div>
    );
};

const AvatarAndNameCol = props => {
    if (props.hidePicture) return buildAgentName(props);

    return (
        <div className="admin-role-tables__avatar-name">
            <ProfilePicture
                className="admin-role-tables__profile-picture"
                picture={get(props.user, "userInfo.profilePicUrl")}
                canEdit={false}
            />
            {buildAgentName(props)}
        </div>
    );
};

AvatarAndNameCol.propTypes = {
    user: PropTypes.any,
    showEmail: PropTypes.bool,
    hidePicture: PropTypes.bool
};

AvatarAndNameCol.defaultProps = {
    showEmail: true,
    hidePicture: false
};

export default AvatarAndNameCol;
