import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isFunction from 'lodash/isFunction';
import toString from 'lodash/toString';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'react-bootstrap';
import { Button } from '../Button/Button';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';

import './changestatusmodal.css';

const SELECT_STATUS_OPTION = {
    id: '0',
    name: 'CHOOSE YOUR REASON',
};

class ChangeStatusModal extends React.Component {
    static propTypes = {
        isVisible: PropTypes.bool,
        realtorStatusOptions: PropTypes.array,
        onClose: PropTypes.func,
        onChangeStatus: PropTypes.func,
        onSelectStatus: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            isDisable: true,
        };
    }

    onChange = (event) => {
        const { onSelectStatus } = this.props;
        const selectedStatus = event.value.id;

        this.setState({
            isDisable: selectedStatus === SELECT_STATUS_OPTION.id,
        });

        if (isFunction(onSelectStatus)) {
            onSelectStatus(event.value.index - 1);
        }
    };

    onClose = () => {
        this.setState({ isDisable: true });
        this.props.onClose();
    };

    onChangeTextArea = (e) => {
        this.setState({ value: e.target.value });
    };

    onSubmitStatus = () => {
        const { onChangeStatus } = this.props;

        if (isFunction(onChangeStatus)) {
            onChangeStatus(this.state.value);
        }
    };

    buildSelectStatusOptions = () => map([SELECT_STATUS_OPTION, ...this.props.realtorStatusOptions],
        (option, key) => ({
            label: option.name,
            value: { id: toString(option.id), index: key },
        }));

    buildSelectStatusField = () => (
        <div className="change-status-modal__container-select">
            <AutoCompleteInput
                placeholder="CHOOSE YOUR REASON"
                onChange={this.onChange}
                isActive={!this.state.isDisable}
                getOptions={this.buildSelectStatusOptions}
                initializeGettingOptions
            />
        </div>
    );

    render = () => (
        <Modal
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            className="change-status-modal"
        >
            <ModalHeader className="change-status-modal__header">
                <Button
                    icon 
                    dark
                    symbol="times"
                    onClick={this.onClose}
                >
                </Button>
            </ModalHeader>
            <ModalBody className="change-status-modal__body">
                <div className="change-status-modal__body__header">
                    You&#39;re about to change the status:
                </div>
                <div className="change-status-modal__container-select">
                    {this.buildSelectStatusField()}
                    <textarea
                        className="form-control change-status-modal__container-select textarea"
                        onChange={this.onChangeTextArea}
                        value={this.props.reason}
                        placeholder="EXPLAIN WITH A NOTE THE REASONS OF THE STATUS CHANGE"
                    />
                </div>
                <div className="container--buttons">
                    <Button
                        yellow
                        disabled={this.state.isDisable}
                        onClick={this.onSubmitStatus}
                    >
                        Change Status
                    </Button>
                    <Button
                        inverted
                        onClick={this.onClose}
                    >
                        Discard
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ChangeStatusModal;
