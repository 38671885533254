import React from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import get from "lodash/get";
import reduce from "lodash/reduce";
import isFunction from "lodash/isFunction";
import { getBuyerStats } from "util/service_api";
import { setSearchParameters } from "reducers/admin/AdminActions";
import AdminProfileBuyerStats from "./AdminProfileBuyerStats";
import invoke from "lodash/invoke";

class AdminProfileBuyerStatsContainer extends React.Component {
    static defaultProps = {
        onFetchStats: getBuyerStats,
        filterDateFormat: "YYYY-MM-DD"
    };

    constructor(props) {
        super(props);
        this.state = this.buildInitialState();
    }

    getDefaultSelectedMonth = () => new Date().getMonth() + 1;

    getDefaultSelectedYear = () => new Date().getFullYear();

    buildInitialState = () => ({
        isLoading: false,
        selectedDate: {
            month: this.getDefaultSelectedMonth(),
            year: this.getDefaultSelectedYear()
        },
        stats: {
            accountsCreated: {
                number: 0,
                mtd: 0,
                ytd: 0
            },
            matchedWithAgent: {
                number: 0,
                mtd: 0,
                ytd: 0
            },
            unmatched: {
                number: 0,
                mtd: 0,
                ytd: 0
            },
            byState: {}
        }
    });

    componentDidMount = () => this.fetchStats(this.state.selectedDate);

    onStartFetchingStats = () => this.setState({ isLoading: true });

    onSuccessfullyFetchedStats = stats => {
        const newStats = {
            accountsCreated: {
                number: Number(get(stats, "selectedPeriodStats.all")) || 0,
                mtd: Number(get(stats, "mtdAll")) || 0,
                ytd: Number(get(stats, "ytdAll")) || 0
            },
            matchedWithAgent: {
                number: Number(get(stats, "selectedPeriodStats.matched")) || 0,
                mtd: Number(get(stats, "mtdMatched")) || 0,
                ytd: Number(get(stats, "ytdMatched")) || 0
            },
            unmatched: {
                number:
                    Number(get(stats, "selectedPeriodStats.nonMatched")) || 0,
                mtd: Number(get(stats, "mtdNonMatched")) || 0,
                ytd: Number(get(stats, "ytdNonMatched")) || 0
            },
            byState: reduce(
                get(stats, "buyersPerState"),
                (result, stat, state) => ({
                    ...result,
                    [state]: {
                        state,
                        new: get(stat, "all"),
                        matched: get(stat, "matched"),
                        unmatched: get(stat, "nonMatched")
                    }
                }),
                {}
            )
        };
        this.setState({ stats: newStats });
    };

    onFinishFetchingStats = () => this.setState({ isLoading: false });

    onChangeSelectedDate = (month, year) => {
        const newSelectedDate = {
            month,
            year
        };

        this.fetchStats(newSelectedDate);
        this.setState({ selectedDate: newSelectedDate });
    };

    onChangeSelectedStartMonth = month =>
        this.onChangeSelectedDate(
            get(month, "target.value"),
            this.state.selectedDate.year
        );

    onChangeSelectedStartYear = year =>
        this.onChangeSelectedDate(
            this.state.selectedDate.month,
            get(year, "target.value")
        );

    updateFiltersFromClickingOn = state => {
        const { filterDateFormat } = this.props;
        const { selectedDate } = this.state;
        const month = get(selectedDate, "month") - 1;
        const year = get(selectedDate, "year");
        const selectedDateFirstDay = new Date(year, month, 1);
        const selectedDateLastDay = new Date(year, month + 1, 0);
        const params = {
            filterBy: "ROLE_BUYER",
            state,
            startDate: format(selectedDateFirstDay, filterDateFormat),
            endDate: format(selectedDateLastDay, filterDateFormat)
        };
        invoke(this.props, "onSetFilters", params);
    };

    fetchStats = filterByDate => {
        const { onFetchStats } = this.props;
        if (!isFunction(onFetchStats)) return;

        Promise.resolve()
            .then(this.onStartFetchingStats)
            .then(() => onFetchStats(filterByDate))
            .then(this.onSuccessfullyFetchedStats)
            .finally(this.onFinishFetchingStats);
    };

    render = () => (
        <AdminProfileBuyerStats
            isLoading={this.state.isLoading}
            stats={this.state.stats}
            selectedDate={this.state.selectedDate}
            onChangeStartMonth={this.onChangeSelectedStartMonth}
            onChangeStartYear={this.onChangeSelectedStartYear}
            onClickStat={this.updateFiltersFromClickingOn}
        />
    );
}

export default connect(null, {
    onSetFilters: setSearchParameters
})(AdminProfileBuyerStatsContainer);
