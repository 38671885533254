import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import head from "lodash/head";
import size from "lodash/size";
import map from "lodash/map";
import { components } from "react-select";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import AdminProfileAgentInfo from "../AdminProfileStats/AdminProfileStatsAgentInfo";
import BrokerTable from "../AdminProfile/AdminRoleTables/BrokerTable";
import AutoCompleteInput from "../AutoCompleteInput/AutoCompleteInput";
import {
    getBrokerage,
    impersonateUser
} from "../../reducers/admin/AdminActions";

import "./adminProfileBroker.css";

const DropdownMenuList = props => (
    <components.Menu
        {...props}
        className="admin-profile-broker__dropdown-menu-list"
    ></components.Menu>
);

class AdminProfileBroker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedBrokerage: {
                label: get(this.getBrokerage(), "name"),
                value: get(this.getBrokerage(), "brokerageId")
            }
        };
    }

    componentDidMount = () => {
        const brokerageId = get(this.getBrokerage(), "brokerageId");
        if (isFunction(this.props.onGetBrokerage) && brokerageId) {
            this.props.onGetBrokerage(brokerageId);
        }
    };

    getBrokerage = () =>
        head(get(this.props.user, "profile.publicProfile.brokerages"));

    getBrokerages = () =>
        get(this.props.user, "profile.publicProfile.brokerages");

    getIsFetchedBrokerage = () =>
        get(this.props.admin, "getBrokerage.isFetchedBrokerage");

    getIsFetchingBrokerage = () =>
        get(this.props.admin, "getBrokerage.isFetchingBrokerage");

    getAgentsSignedUp = () =>
        get(this.props.admin, "getBrokerage.brokerage.agentsSignedUp");

    getLeads = () => get(this.props.admin, "getBrokerage.brokerage.totalLeads");

    getTotalValueOfHomesSold = () =>
        get(this.props.admin, "getBrokerage.brokerage.totalValueOfHomesSold");

    getAgents = () => get(this.props.admin, "getBrokerage.brokerage.agents");

    buildBrokerTable = () => (
        <div className="admin-profile-broker__table-container">
            <BrokerTable
                brokers={this.getAgents()}
                impersonateUser={this.props.onImpersonateUser}
            />
        </div>
    );

    buildInformation = () => (
        <div className="admin-profile-broker__information">
            <AdminProfileAgentInfo
                title={"AGENTS SIGNED UP"}
                number={this.getAgentsSignedUp()}
                hideMtd
                hideYtd
            />
            <AdminProfileAgentInfo
                title={"MATCHES"}
                number={this.getLeads()}
                hideMtd
                hideYtd
            />
            <AdminProfileAgentInfo
                title={"TOTAL VALUE OF HOMES"}
                number={this.getTotalValueOfHomesSold()}
                hideMtd
                hideYtd
                withFormat
                alignCenter
            />
        </div>
    );

    getBroker = () => get(this.getBrokerage(), "name");

    buildEmptyResults = () => (
        <>
            <div className="admin-profile-broker__title">
                <div className="admin-profile-broker__title-text"></div>
            </div>
            <div className="admin-profile-broker__empty">
                Unfortunately we do not have any Partner Agents that have signed
                up under your Brokerage
            </div>
        </>
    );

    getBrokerageOptions = () => {
        return map(this.getBrokerages(), brokerage => ({
            label: brokerage.name,
            value: brokerage
        }));
    };

    onChangeBrokerage = e => {
        const brokerageId = get(e, "value.brokerageId");
        this.setState({ selectedBrokerage: e });
        if (isFunction(this.props.onGetBrokerage) && brokerageId) {
            this.props.onGetBrokerage(brokerageId);
        }
    };

    buildBrokerageTitle = () =>
        size(this.getBrokerages()) === 1 ? (
            get(this.state.selectedBrokerage, "label")
        ) : (
            <AutoCompleteInput
                onChange={this.onChangeBrokerage}
                getOptions={this.getBrokerageOptions}
                value={this.state.selectedBrokerage}
                initializeGettingOptions
                components={{ Menu: DropdownMenuList }}
            />
        );

    buildResults = () => (
        <>
            <div className="admin-profile-broker__title">
                <div className="admin-profile-broker__title-text">
                    {this.buildBrokerageTitle()}
                </div>
            </div>
            <div className="admin-profile-broker__container">
                {this.buildInformation()}
                {this.buildBrokerTable()}
            </div>
        </>
    );

    buildContent = () => {
        const isEmpty = size(this.getBrokerages()) > 0 ? false : true;
        if (this.getIsFetchingBrokerage()) {
            return <SpinnerLoader />;
        }

        return isEmpty ? this.buildEmptyResults() : this.buildResults();
    };

    render = () => {
        const isImpersonatingUser = get(
            this.props.admin,
            "loginImpersonateUser.successImpersonateUser"
        );

        if (isImpersonatingUser) {
            return <Redirect to="/profile" />;
        }

        return (
            <div className="admin-profile-broker">{this.buildContent()}</div>
        );
    };
}

AdminProfileBroker.propTypes = {
    user: PropTypes.object,
    admin: PropTypes.object,
    onGetBrokerage: PropTypes.func,
    onImpersonateUser: PropTypes.func
};

export default connect(
    state => ({
        user: state.user,
        admin: state.admin
    }),
    {
        onGetBrokerage: getBrokerage,
        onImpersonateUser: impersonateUser
    }
)(AdminProfileBroker);
