import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { receiveUserProfile } from '../../reducers/user/UserActions';
import {
    Switch,
    Route,
    withRouter,
    Redirect,
} from 'react-router-dom';
import UserSellerProfile from '../UserProfile/UserSellerProfile';
import mixpanel from 'mixpanel-browser';

class SellerRoute extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.any,
        match: PropTypes.any,
        onUpdateProfile: PropTypes.func,
    };

    componentDidMount = () => {
        const { user, history } = this.props;
        const isProfileFetched = get(user, 'isProfileFetched');
        const isNotSeller = !get(user, 'profile.publicProfile.userInfo.seller');
        mixpanel.init(process.env.REACT_APP_MIXPANEL_SELLER_TOKEN);

        if (isProfileFetched && isNotSeller) {
            history.replace('/');
        }
    };

    updateField = (profile) => {
        if (isFunction(this.props.onUpdateProfile)) {
            this.props.onUpdateProfile(profile);
        }
    };

    onUpdateSellerAgent = (newAgent) => {
        const { user: { profile } } = this.props;
        this.updateField({ ...profile, realtor: newAgent });
        mixpanel.track(
            "Completed Agent Selection", {
                "agentSelectedFirstName": get(newAgent, 'userInfo.firstName'),
                "agentSelectedLastName": get(newAgent, 'userInfo.lastName'),
                "agentSelectedEmail": get(newAgent, 'userInfo.email'),
                "agentSelectedPhone": get(newAgent, 'userInfo.phone'),
             }
        );
    };

    getProfile = () => get(this.props.user, 'profile');

    render = () => (
        <React.Fragment>
            <Switch>
                <Route
                    path={`${this.props.match.path}/profile`}
                    render={() => (
                        <UserSellerProfile
                            isPublicView
                            profile={this.getProfile()}
                            onChooseAgent={this.onUpdateSellerAgent}
                        />
                    )}
                />
                <Route render={() => <Redirect to={`${this.props.match.path}/profile`} />} />
            </Switch>
        </React.Fragment>
    );
}

export default withRouter(connect(
    (state) => ({ user: state.user }),
    {
        onUpdateProfile: receiveUserProfile,
    },
)(SellerRoute));
