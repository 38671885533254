import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import map from "lodash/map";
import { connect } from "react-redux";
import { withFormik } from "formik";
import {
    array as YupArray,
    bool as YupBool,
    number as YupNumber,
    object as YupObject,
    string as YupString
} from "yup";
import { getBudgetOptions } from "../../../../util/service_api";
import WizardStepsForm from "./WizardStepsForm";
import {
    adaptCityAndState,
    adaptStreetAddress,
    EmptyAddress,
    EmptyLocation
} from "../Shared";
import {
    YupUsPhoneNumber,
    YupUniqueEmail
} from "../../../../shared/validations";

import mixpanel from "mixpanel-browser";

import {
    signupBuyer,
    getBrewScore,
    getSearchAgents,
    contactRebate
} from "../../../../reducers/user/UserActions";

const addressCompactor = item =>
    !(
        item.address ||
        item.apartmentSuiteNumber ||
        item.city ||
        item.state ||
        item.zipcode
    );

export const defaultBudgetRange = [
    {
        min: 0,
        max: 138000,
        label: "$138k or less",
        value: "[0, 138000]"
    },
    {
        min: 138001,
        max: 179000,
        label: "$138k - $179k",
        value: "[138000, 179000]"
    },
    {
        min: 179001,
        max: 233000,
        label: "$179k - $233k",
        value: "[179000, 233000]"
    },
    {
        min: 233001,
        max: 303000,
        label: "$233k - $303k",
        value: "[233000, 303000]"
    },
    {
        min: 303001,
        max: 424000,
        label: "$303k - $424k",
        value: "[303000, 424000]"
    },
    {
        min: 424001,
        max: 506000,
        label: "$424k - $506k",
        value: "[424000, 506000]"
    },
    {
        min: 506001,
        max: 10000000,
        label: "$506k or more",
        value: "[506000, 10000000]"
    }
];

const customBudgetRange = (function() {
    let range = defaultBudgetRange;

    return {
        get: () => {
            return defaultBudgetRange;
        },

        set: r => {
            range = r;
        },

        update: data => {
            return getBudgetOptions(data)
                .then(result => {
                    const { budgetRanges, foundMatch } = result;
                    if (budgetRanges) {
                        range = map(budgetRanges, budgetRange => ({
                            value: JSON.stringify([
                                budgetRange.min.amount,
                                budgetRange.max.amount
                            ]),
                            min: budgetRange.min.amount,
                            max: budgetRange.max.amount,
                            label: budgetRange.label,
                            foundMatch
                        }));
                    } else range = [defaultBudgetRange];

                    return result;
                })
                .catch(() => (range = [defaultBudgetRange]));
        }
    };
})();

const mapValuesToPayload = (values, matchedAgents) => {
    const {
        budget,
        buyerHomeType,
        buyerNumberOfBedrooms,
        buyerNumberOfBathrooms,
        purchaseTimeline: buyerPurchaseTimeline,
        downPayment,
        buyerMonthlyTaxes,
        buyerTaxRate,
        hasKnownLocation,
        knownLocations,
        approximateLocations,
        ficoScoreCategory,
        buyerCurrentLivingSituation,
        buyerIsSellingCurrentHome,
        buyerIsUsingNetProceedsFromCurrentHome,
        buyerNetProceedsBeingUsedFromCurrentHome,
        languages,
        firstName,
        lastName,
        password,
        email,
        phone,
        buyerIsFirstTimeHomeBuyer,
        acceptedTermsAndConditions
    } = values;

    let buyerBudgetMin;
    let buyerBudgetMax;
    try {
        [buyerBudgetMin, buyerBudgetMax] = JSON.parse(budget);
    } catch (_) {}

    const payload = {
        buyerHasPickedOutNewHome: hasKnownLocation === "yes",
        buyerHomeType,
        buyerBudgetMin,
        buyerBudgetMax,
        buyerPurchaseTimeline,

        buyerNewAddresses:
            hasKnownLocation === "yes"
                ? knownLocations.map(adaptStreetAddress)
                : hasKnownLocation === "no"
                ? approximateLocations.map(adaptCityAndState)
                : [],

        buyerCurrentLivingSituation,
        languages: isEmpty(languages) ? [] : languages.map(item => item.value),
        firstName,
        lastName,
        password,
        email,
        phone,
        buyerIsFirstTimeHomeBuyer: buyerIsFirstTimeHomeBuyer === "yes",
        acceptedTermsAndConditions,
        matchedAgents: matchedAgents ? matchedAgents : []
    };

    if (downPayment)
        payload.buyerDownPaymentPercentage = parseFloat(downPayment);

    if (buyerMonthlyTaxes !== undefined)
        payload.buyerMonthlyTaxes = buyerMonthlyTaxes.amount;
    if (buyerTaxRate !== undefined) payload.buyerTaxRate = buyerTaxRate;

    if (ficoScoreCategory) payload.ficoScoreCategory = ficoScoreCategory;

    if (buyerNumberOfBedrooms) {
        payload.buyerNumberOfBedrooms = parseInt(buyerNumberOfBedrooms, 10);
    }

    if (buyerNumberOfBathrooms) {
        payload.buyerNumberOfBathrooms = parseInt(buyerNumberOfBathrooms, 10);
    }

    if (buyerCurrentLivingSituation) {
        payload.buyerIsSellingCurrentHome = buyerIsSellingCurrentHome === "yes";
        if (payload.buyerIsSellingCurrentHome) {
            payload.buyerIsUsingNetProceedsFromCurrentHome =
                buyerIsUsingNetProceedsFromCurrentHome === "yes";
            if (payload.buyerIsUsingNetProceedsFromCurrentHome) {
                payload.buyerNetProceedsBeingUsedFromCurrentHome = parseInt(
                    buyerNetProceedsBeingUsedFromCurrentHome,
                    10
                );
            }
        }
    }

    return payload;
};

const mapValuesToBrewScorePayload = values => {
    const {
        budget,
        buyerHomeType,
        buyerNumberOfBedrooms,
        buyerNumberOfBathrooms,
        purchaseTimeline: buyerPurchaseTimeline,
        downPayment,
        buyerMonthlyTaxes,
        buyerTaxRate,
        hasKnownLocation,
        knownLocations,
        approximateLocations,
        ficoScoreCategory,
        buyerCurrentLivingSituation,
        buyerIsSellingCurrentHome,
        buyerIsUsingNetProceedsFromCurrentHome,
        buyerNetProceedsBeingUsedFromCurrentHome,
        languages
    } = values;

    let buyerBudgetMin;
    let buyerBudgetMax;
    try {
        [buyerBudgetMin, buyerBudgetMax] = JSON.parse(budget);
    } catch (_) {}

    const payload = {
        buyerHasPickedOutNewHome: hasKnownLocation === "yes",
        buyerHomeType,
        buyerBudgetMin,
        buyerBudgetMax,
        buyerPurchaseTimeline,

        buyerNewAddresses:
            hasKnownLocation === "yes"
                ? knownLocations.map(adaptStreetAddress)
                : hasKnownLocation === "no"
                ? approximateLocations.map(adaptCityAndState)
                : [],

        buyerCurrentLivingSituation,
        languages: isEmpty(languages) ? [] : languages.map(item => item.value)
    };

    if (downPayment)
        payload.buyerDownPaymentPercentage = parseFloat(downPayment);

    if (buyerMonthlyTaxes !== undefined)
        payload.buyerMonthlyTaxes = buyerMonthlyTaxes.amount;
    if (buyerTaxRate !== undefined) payload.buyerTaxRate = buyerTaxRate;

    if (ficoScoreCategory) payload.ficoScoreCategory = ficoScoreCategory;

    if (buyerNumberOfBedrooms) {
        payload.buyerNumberOfBedrooms = parseInt(buyerNumberOfBedrooms, 10);
    }

    if (buyerNumberOfBathrooms) {
        payload.buyerNumberOfBathrooms = parseInt(buyerNumberOfBathrooms, 10);
    }

    if (buyerCurrentLivingSituation) {
        payload.buyerIsSellingCurrentHome = buyerIsSellingCurrentHome === "yes";
        if (payload.buyerIsSellingCurrentHome) {
            payload.buyerIsUsingNetProceedsFromCurrentHome =
                buyerIsUsingNetProceedsFromCurrentHome === "yes";
            if (payload.buyerIsUsingNetProceedsFromCurrentHome) {
                payload.buyerNetProceedsBeingUsedFromCurrentHome = parseInt(
                    buyerNetProceedsBeingUsedFromCurrentHome,
                    10
                );
            }
        }
    }

    return payload;
};

const mapValuesToSearchAgentsPayload = values => {
    const { knownLocations, hasKnownLocation, approximateLocations } = values;

    const buyerHasPickedOutNewHome = hasKnownLocation === "yes";
    const buyerNewAddresses = buyerHasPickedOutNewHome
        ? knownLocations.map(adaptStreetAddress)
        : approximateLocations.map(adaptCityAndState);

    return { buyerNewAddresses: buyerNewAddresses };
};

const WizardStepsContainer = withFormik({
    mapPropsToStatus: () => "initial",

    mapPropsToValues: props => {
        return {
            isBuyer: true,
            hasKnownLocation: undefined,
            knownLocations: [{ ...EmptyAddress }],
            approximateLocations: [{ ...EmptyLocation }],
            purchaseTimeline: undefined,
            buyerHomeType: undefined,
            buyerNumberOfBedrooms: "",
            buyerNumberOfBathrooms: "",
            budget: "",
            buyerMonthlyTaxes: undefined,
            buyerTaxRate: undefined,
            downPayment: "",
            ficoScoreCategory: undefined,
            buyerIsFirstTimeHomeBuyer: undefined,
            buyerCurrentLivingSituation: undefined,
            buyerIsSellingCurrentHome: undefined,
            buyerIsUsingNetProceedsFromCurrentHome: undefined,
            buyerNetProceedsBeingUsedFromCurrentHome: "",
            languages: [
                { value: { name: "English", id: "17" }, label: "English" }
            ],
            firstName: "",
            lastName: "",
            password: "",
            password2: "",
            email: "",
            phone: "",
            phoneCode: "",
            phoneCheckState: "",
            acceptedTermsAndConditions: "",
            recaptchaSuccessfullyCompleted: false
        };
    },

    validationSchema: props =>
        YupObject().shape({
            hasKnownLocation: YupString()
                .oneOf(["yes", "no"])
                .required(),

            knownLocations: YupArray()
                .compact(addressCompactor)
                .when("hasKnownLocation", {
                    is: "yes",
                    then: YupArray()
                        .of(
                            YupObject({
                                address: YupString()
                                    .trim()
                                    .required("Please enter a valid address"),
                                state: YupString()
                                    .trim()
                                    .required("Please enter a valid address")
                            })
                        )
                        .min(1, "Address is required")
                }),

            approximateLocations: YupArray().when("hasKnownLocation", {
                is: "no",
                then: YupArray()
                    .of(
                        YupObject().shape({
                            location: YupString()
                                .trim()
                                .min(1, "Please enter a valid location")
                                .required("Please enter a valid location"),
                            city: YupString()
                                .trim()
                                .min(1, "Please enter a valid location")
                                .required("Please enter a valid location"),
                            state: YupString()
                                .trim()
                                .min(1, "Please enter a valid location")
                                .required("Please enter a valid location")
                        })
                    )
                    .unique("The same location is allowed only once", item =>
                        `${item.location}`.trim().toLowerCase()
                    )
                    .min(1, "A valid location is required")
            }),

            purchaseTimeline: YupString().required("Timeline is required"),

            buyerHomeType: YupString().when("hasKnownLocation", {
                is: "no",
                then: YupString().required("Property type is required")
            }),

            buyerNumberOfBedrooms: YupNumber()
                .typeError("Bedrooms must be a number")
                .min(0, "Bedrooms must be greater or equal to 0")
                .nullable(),

            buyerNumberOfBathrooms: YupNumber()
                .typeError("Bathrooms must be a number")
                .min(0, "Bathrooms must be greater or equal to 0")
                .nullable(),

            budget: YupString()
                .trim()
                .required("Budget is required"),

            downPayment: YupNumber()
                .typeError("The Down payment is a numeric value from 0 to 100")
                .min(0, "Minimum Down payment is 0%")
                .max(100, "Maximum Down payment is 100%")
                .required("The Down payment is required"),

            ficoScoreCategory: YupObject().required(),

            buyerIsFirstTimeHomeBuyer: YupString()
                .oneOf(["yes", "no"])
                .required(),

            buyerCurrentLivingSituation: YupString()
                .oneOf(["rent", "own", "friends_family"])
                .required(),

            buyerIsSellingCurrentHome: YupString().when(
                "buyerCurrentLivingSituation",
                {
                    is: "own",
                    then: YupString()
                        .oneOf(["yes", "no"])
                        .required()
                }
            ),

            buyerIsUsingNetProceedsFromCurrentHome: YupString().when(
                "buyerIsSellingCurrentHome",
                {
                    is: "yes",
                    then: YupString()
                        .oneOf(["yes", "no"])
                        .required()
                }
            ),

            buyerNetProceedsBeingUsedFromCurrentHome: YupNumber().when(
                "buyerIsUsingNetProceedsFromCurrentHome",
                {
                    is: "yes",
                    then: YupNumber("The value must be a positive number")
                        .label("Proceeds")
                        .positive()
                        .max(10000000, "The maximum value is $10 million")
                        .required()
                }
            ),

            firstName: YupString()
                .trim()
                .required("First Name is required"),

            lastName: YupString()
                .trim()
                .required("Last Name is required"),

            email: YupUniqueEmail("ROLE_BUYER").required("Email is required"),

            phone: YupUsPhoneNumber().required("Phone is required"),

            acceptedTermsAndConditions: YupBool().test(
                "validate-tos",
                "To proceed, please accept Terms and Conditions",
                value => !!value
            ),

            recaptchaSuccessfullyCompleted: YupBool().oneOf(
                [true],
                "Please complete ReCAPTCHA field"
            )
        }),

    // Submission handler
    handleSubmit(values, { setStatus, props }) {
        setStatus({ formState: "submitting" });
        const matchedAgents = get(props, "searchAgents.searchAgents.agents");
        const payload = mapValuesToPayload(values, matchedAgents);

        props
            .signupBuyer(payload)
            .then(response => {
                window.fbq("track", "SubmitApplication");
                mixpanel.alias(get(values, "email"));
                mixpanel.track("Finished Onboarding", {
                    firstName: get(values, "firstName"),
                    lastName: get(values, "lastName"),
                    downPaymentPercentage: get(
                        response,
                        "downPaymentPercentage"
                    ),
                    brewScore: get(response, "brewScore"),
                    rebatePercentage: get(response, "rebatePercentage"),
                    creditVerified: get(response, "creditVerified"),
                    assetsVerified: get(response, "assetsVerified"),
                    incomeVerified: get(response, "incomeVerified"),
                    employmentVerified: get(response, "employmentVerified"),
                    loanAmount: get(response, "loanAmount.amount"),
                    rebateAmount: get(response, "rebateAmount.amount"),
                    desiredDownPayment: get(
                        response,
                        "desiredDownPayment.amount"
                    ),
                    verifiedDownPayment: get(
                        response,
                        "verifiedDownPayment.amount"
                    ),
                    mortgage: get(response, "mortgage.amount"),
                    monthlyTaxes: get(response, "monthlyTaxes.amount"),
                    approximateHoaAmount: get(
                        response,
                        "approximateHoaAmount.amount"
                    ),
                    approximateHoiAmount: get(
                        response,
                        "approximateHoiAmount.amount"
                    ),
                    total: get(response, "total.amount")
                });
            })
            .catch(error => {
                console.error("Submission failed", error);
            })
            .finally(() => setStatus({ formState: "submitted" }));
    }
})(WizardStepsForm);

let lastUpdate = {};
function notifyUpdate(payload, onboardingStep) {
    // Perform update only when there is a change
    if (isEqual(lastUpdate, payload)) return;

    lastUpdate = payload;
    //return updateBuyer({ ...payload, onboardingStep });
}

const getTransactionContactTypes = state =>
    get(state.configApp, "config.transactionContactTypes.Other");

const mapStateToProps = state => ({
    mapValuesToPayload,
    mapValuesToBrewScorePayload,
    mapValuesToSearchAgentsPayload,
    notifyUpdate,
    customBudgetRange,
    licensedStates: state.configApp.config
        ? state.configApp.config.licensedStates
        : [],
    licensedStatesAbbr: state.configApp.config
        ? map(state.configApp.config.licensedStates, "abbreviation")
        : [],
    ficoScoreCategories: get(state, "configApp.config.ficoScoreCategories", []),
    userProfile: state.user.profile,
    brewScore: state.user.getBrewScore,
    searchAgents: state.user.getSearchAgents,
    isFetchingContactRebate: state.user.isFetchingContactRebate,
    successContactRebate: state.user.successContactRebate,
    transactionContactTypes: getTransactionContactTypes(state)
});

const mapDispatchToProps = dispatch => ({
    getBrewScore: data => dispatch(getBrewScore(data)),
    getSearchAgents: data => dispatch(getSearchAgents(data)),
    signupBuyer: payload => dispatch(signupBuyer(payload)),
    onContactRebate: data => dispatch(contactRebate(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardStepsContainer);
