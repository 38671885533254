import React, { Component } from 'react';

import './CheckboxField.css';

export default class extends Component {
    constructor(props) {
        super(props);

        const { checked, field: { name }, form, value = true } = props;
        if (checked) form.setFieldValue(name, value);
    }

    render() {
        const { field, form, label, children, ...props } = this.props;
        const isChecked = field.value === props.value;

        const toggleValue = () => {
            form.setFieldValue(field.name, !isChecked ? props.value : undefined);
            form.setFieldTouched(field.name);
        };

        const renderLabel = content =>
            content
                ? <div className="checkbox__field__label" onClick={ toggleValue }>{content}</div>
                : null;

        return <div className={ `checkbox__field ${props.className || ''}` }>
            <div
                className={ `checkbox__field__checkbox ${ isChecked ? 'is-checked' : '' }` }
                onClick={ toggleValue }
            >
                <i className={`${ isChecked ? 'fas fa-check-square' : 'far fa-square' }`}/>
            </div>
            { renderLabel(children || label || null) }
        </div>;
    }
};
