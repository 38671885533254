import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import { Button } from "../Button/Button";

import "./realtormatchprofiletransactioninfo.css";

export default class RealtorMatchProfileTransactionInfo extends React.PureComponent {
    static propTypes = {
        transactionContacts: PropTypes.array,
        transactionContactTypes: PropTypes.array.isRequired,
        onAddContactInfoClick: PropTypes.func.isRequired,
        onViewContactInfoClick: PropTypes.func.isRequired
    };

    buildAddContactInfo = (contactType, key) => (
        <div
            key={key}
            className="row realtor-match-profile-transaction-info__content-body"
        >
            <div className="col-xs-12 col-md-8 col-sm-8">
                <span className="realtor-match-profile-transaction-info__contact-label">
                    {get(contactType, "displayName")}
                </span>
            </div>
            <div className="col-xs-12 col-md-4 col-sm-4">
                <div className="realtor-match-profile-transaction-info__button-wraper">
                    <Button
                        small
                        inverted
                        onClick={() =>
                            this.props.onAddContactInfoClick(contactType)
                        }
                    >
                        add contact info
                    </Button>
                </div>
            </div>
        </div>
    );

    buildViewContactInfo = (contactType, key) => (
        <div
            key={key}
            className="row realtor-match-profile-transaction-info__content-body"
        >
            <div className="col-xs-12 col-md-8 col-sm-8">
                <span className="realtor-match-profile-transaction-info__contact-label">
                    {get(contactType, "displayName")}
                </span>
            </div>
            <div className="col-xs-12 col-md-4 col-sm-4">
                <div className="realtor-match-profile-transaction-info__button-wraper">
                    <Button
                        small
                        onClick={() =>
                            this.props.onViewContactInfoClick(contactType)
                        }
                        design="yellow"
                        className="realtor-match-profile-transaction-info__view-contact-info-button"
                    >
                        View contact info
                    </Button>
                </div>
            </div>
        </div>
    );

    buildButtons = () => {
        const { transactionContactTypes, transactionContacts } = this.props;
        return map(transactionContactTypes, (contactType, key) => {
            const isComplete = find(
                transactionContacts,
                item =>
                    get(item, "transactionContactType.id") ===
                    get(contactType, "id")
            );
            if (isComplete) {
                return this.buildViewContactInfo(contactType, key);
            }
            return this.buildAddContactInfo(contactType, key);
        });
    };

    render = () => (
        <div className="realtor-match-profile-transaction-info">
            <h3 className="realtor-match-profile-transaction-info__title">
                Transaction Information
            </h3>
            <div className="realtor-match-profile-transaction-info__content-head">
                <div className="realtor-match-profile-transaction-info__content-head-info">
                    <span className="realtor-match-profile-transaction-info__content-head-info__highlighted">
                        Remember!
                    </span>{" "}
                    <span>
                        Please complete the contact information for all parties
                        involves bellow:
                    </span>
                </div>
            </div>
            {this.buildButtons()}
        </div>
    );
}
