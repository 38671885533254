import React, { Fragment } from 'react';
import mailbox from '../../../../styles/assets/Inbox-Icon.svg';
import ScrollToTopOnMount from '../../../ScrollTopOnMount/ScrollTopOnMount';

import './index.css';

const EmdSignUpSuccess = () => (
    <Fragment>
        <ScrollToTopOnMount />
        <div className="buyer__signup__step is-higher buyer--flow">
            <div className="buyer__signup__question">
                Check your inbox
            </div>
            <div className="buyer__signup__fieldset">
                <div className="buyer__signup__field__row">
                    <div className="buyer__signup__field__label align-center is-big">
                        We&#39;ve sent a message to you with a link to verify your Email Address.
                        If you don&#39;t see an email from us within a few minutes, be sure to check your spam folder.
                        <br />
                        <br />
                        But If you don&#39;t see the email send us a message at
                        &nbsp;
                        <a href="mailto:happy@lemonbrew.com">happy@lemonbrew.com</a>
                        {' '}
                        for assistance.
                        <br />
                        <br />
                        <img src={mailbox} alt="mailbox" />
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
);

export default EmdSignUpSuccess;
