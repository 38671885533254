import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'react-bootstrap';
import isFunction from 'lodash/isFunction';
import CloseModalButton from '../CloseModalButton/CloseModalButton';
import Button from '../Button/Button';
import AutoCompleteInput from '../AutoCompleteInput/AutoCompleteInput';

class BrewBoardBrokerageEditModal extends React.PureComponent {
    onChangeField = (field, value) => {
        const { onChange } = this.props;

        if (!isFunction(onChange)) {
            return;
        }

        const brokerageWithChanges = cloneDeep(this.props.brokerage);
        set(brokerageWithChanges, field, value);
        onChange(brokerageWithChanges);
    };

    buildBrokerageNameField = () => (
        <div className="form-group">
            <label htmlFor="brokerageName">Brokerage</label>
            <input
                name="brokerageName"
                className="form-control"
                type="text"
                value={get(this.props.brokerage, 'name')}
                onChange={(e) => this.onChangeField('name', e.target.value)}
            />
        </div>
    );

    buildLicenseIdField = () => (
        <div className="form-group">
            <label htmlFor="licenseId">License ID</label>
            <input
                name="licenseId"
                className="form-control"
                type="text"
                value={get(this.props.brokerage, 'broker.licenseId')}
                onChange={(e) => this.onChangeField('broker.licenseId', e.target.value)}
            />
        </div>
    );


    getStateOptions = () => {
        const { config } = this.props;
    
        return map( get(config, 'usStates'), (state) => ({
            label: state.name,
            value: state.id,
        }));
    }

    onChangeState = (e) => {
        const { config } = this.props;
        const stateId = get(e,'value');
        const stateSelected = find( get(config, 'usStates'), { id: stateId });
        this.setState({
            stateSelected
        });
        this.onChangeField('state',stateSelected);
    }

    buildSelectedOption = (state) => {
        return Object.assign({
            label: get(state, 'name'),
            value: get(state, 'id'),
        });
    }

    buildBrokerageServingInField = () => (
        <div className="form-group">
            <label htmlFor="statesLicensedIn">States Licensed in</label>
            <AutoCompleteInput
                placeholder="SELECT AN OPTION"
                onChange={this.onChangeState}
                getOptions={this.getStateOptions}
                initializeGettingOptions
                value={this.buildSelectedOption(get(this.props.brokerage, 'state'))}
            />
        </div>
    );

    buildBrokerNameField = () => (
        <div className="form-group">
            <label htmlFor="brokerOfRecordName">Broker of Record Name</label>
            <input
                name="brokerOfRecordName"
                className="form-control"
                type="text"
                value={get(this.props.brokerage, 'broker.firstName')}
                onChange={(e) => this.onChangeField('broker.firstName', e.target.value)}
            />
        </div>
    );

    buildBrokerLastNameField = () => (
        <div className="form-group">
            <label htmlFor="brokerOfRecordLastName">Broker of Record Last Name</label>
            <input
                name="brokerOfRecordLastName"
                className="form-control"
                type="text"
                value={get(this.props.brokerage, 'broker.lastName')}
                onChange={(e) => this.onChangeField('broker.lastName', e.target.value)}
            />
        </div>
    );

    buildBrokerPhoneField = () => (
        <div className="form-group">
            <label htmlFor="phoneNumberOfTheBrokerOfRecord">Phone number of the Broker of Record</label>
            <input
                name="phoneNumberOfTheBrokerOfRecord"
                className="form-control"
                type="text"
                value={get(this.props.brokerage, 'broker.phoneNumber')}
                onChange={(e) => this.onChangeField('broker.phoneNumber', e.target.value)}
            />
        </div>
    );

    buildBrokerEmailField = () => (
        <div className="form-group">
            <label htmlFor="brokerEmail">Email address</label>
            <input
                name="brokerEmail"
                className="form-control"
                type="email"
                value={get(this.props.brokerage, 'broker.email')}
                onChange={(e) => this.onChangeField('broker.email', e.target.value)}
            />
        </div>
    );

    buildCancelButton = () => (
        <Button
            type="button"
            onClick={this.props.onCancel}
            shadow={false}
            design="default-inverted"
            disabled={this.props.isLoading}
        >
            Cancel
        </Button>
    );

    buildSaveButton = () => (
        <Button
            isLoading={this.props.isLoading}
            onClick={this.props.onSave}
            disabled={this.props.isLoading}
        >
            Save
        </Button>
    );

    render = () => (
        <Modal
            animation
            autoFocus
            backdrop
            enforceFocus
            show={this.props.isVisible}
            className="brew-board-brokerage-edit-modal"
        >
            <ModalHeader className="text-right">
                <CloseModalButton onClick={this.props.onCancel} />
            </ModalHeader>
            <ModalBody>
                {this.buildBrokerageNameField()}
                {this.buildBrokerageServingInField()}
                <div className="row">
                    <div className="col-md-6">
                        {this.buildBrokerNameField()}
                    </div>
                    <div className="col-md-6">
                        {this.buildBrokerLastNameField()}
                    </div>
                </div>
                {this.buildLicenseIdField()}
                {this.buildBrokerPhoneField()}
                {this.buildBrokerEmailField()}
            </ModalBody>
            <ModalFooter>
                <div className="text-center">
                    {this.buildCancelButton()}
                    {this.buildSaveButton()}
                </div>
            </ModalFooter>
        </Modal>
    );
}

BrewBoardBrokerageEditModal.propTypes = {
    isVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    brokerage: PropTypes.object,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
};

export default connect(
    (state) => ({ config: state.configApp.config })
)(BrewBoardBrokerageEditModal);
