import { withFormik } from 'formik';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import CreateNewPassword from './CreateNewPassword';
import { createNewPassword } from '../../util/service_api';
import { getQueryParam } from '../../shared/functions';
import {
    ref as YupRef,
    string as YupString,
} from 'yup';
import {YupPassword} from '../../shared/validations';

export default compose(
    withRouter,
    withFormik({
        mapPropsToValues: () => ({
            password: '',
            confirm__password: '',
            success: '',
            error: '',
        }),

        validationSchema: Yup.object().shape({
            password:
                YupPassword()
                    .trim()
                    .label('Password')
                    .meta({ mustNotMatch: { path: 'email', label: 'Email' }})
                    .required('Password is required'),

            confirm__password:
                YupString()
                    .trim()
                    .oneOf([YupRef('password'), null], `Passwords don't match`)
                    .required('Confirm Password is required'),
        }),

        handleSubmit(
            { password },
            {
                props: {
                    location: { search },
                },
                setSubmitting,
                setValues,
                setFieldValue,
            },
        ) {
            createNewPassword({
                newPassword: password,
                token: getQueryParam('token', search),
                id: getQueryParam('id', search),
            })
                .then((response) => {
                    setFieldValue('success', response.message);
                })
                .catch((error) => {
                    setValues({
                        password: '',
                        confirm__password: '',
                    });
                    setFieldValue('error', error.response.data.message);
                })
                .finally(() => setSubmitting(false));
        },
    }),
)(CreateNewPassword);
