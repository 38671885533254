import pickBy from "lodash/pickBy";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import * as PropTypes from "prop-types";
import { Form } from "formik";
import { Button } from "../../../../components/Button/Button";

import StepLanguage from "./StepLanguage";
import StepBudget from "./StepBudget";
import StepDownPayment from "./StepDownPayment";
import StepFirstTimeHomeBuyer from "./StepFirstTimeHomeBuyer";
import StepCreditCategory from "./StepCreditCategory";
import StepScore from "./StepScore";
import StepCurrentLivingSituation from "./StepCurrentLivingSituation";
import StepPlanSelling from "./StepPlanSelling";
import StepPlanUsingProceeds from "./StepPlanUsingProceeds";
import StepHowMuchProceeds from "./StepHowMuchProceeds";
import StepLocation from "./StepLocation";
import StepAddress from "./StepAddress";
import StepPropertyType from "./StepPropertyType";
import StepTimeline from "./StepTimeline";
import StepConfirm from "./StepConfirm";
import StepInbox from "./StepInbox";
import StepScanningAgents from "./StepScanningAgents";
import StepSummary from "./StepSummary";

import "../buyer-signup-form.css";
import BuyerSignUpFinishedScreen from "../../../BuyerSignUpFinishedScreen/BuyerSignUpFinishedScreen";
import { getStepInfoByKey } from "./WizardStepMap";

class WizardStepsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            showConfirm: false,
            confirmText: ""
        };
    }

    componentDidMount() {
        const {
            status: { currentStep = "" }
        } = this.props;
        if (currentStep) {
            // Initialize first step
            const step = findIndex(this.getSteps(), { key: currentStep });
            if (step > 0) this.setState({ step });
        }
        this.props.validateForm();
    }

    updateUrlPath = currentStep => {
        const { history } = this.props;
        history.push({
            search: `?step=${currentStep}`
        });
    };

    renderProgress() {
        const progressBarArray = this.getSteps();
        const { step } = this.state;
        const {
            status: { formState }
        } = this.props;
        let actualndex = Math.round((step * 100) / progressBarArray.length);
        const currentStep = this.getSteps()[this.state.step].key;
        this.updateUrlPath(currentStep);
        return (
            <div className="buyer__signup__progress__wrap">
                <div className="buyer__signup__flow__percentage">
                    {actualndex}%
                </div>
                <div className="buyer__signup__goal">Meet Your Agent</div>
                <div className="buyer__signup__progress buyer__flow">
                    {progressBarArray.map(
                        ({ key, label, component }, index) => {
                            const isComplete =
                                index < step || formState === "submitted";
                            const isSkipped =
                                component.skip &&
                                component.skip(this.props, this.state);
                            const className = [
                                "step",
                                isComplete
                                    ? "is-complete"
                                    : index === step
                                    ? "is-current"
                                    : isSkipped
                                    ? "is-disabled"
                                    : "",
                                progressBarArray[index] === progressBarArray[0]
                                    ? "is-first"
                                    : "",
                                progressBarArray[index] ===
                                progressBarArray[progressBarArray.length - 1]
                                    ? "is-last"
                                    : ""
                            ].join(" ");
                            return (
                                <div
                                    key={key}
                                    onClick={() => {
                                        if (isComplete && !isSkipped)
                                            this.setState({ step: index });
                                    }}
                                    className={className}
                                >
                                    <div className="step-label">{label}</div>
                                    <div className="step-point"></div>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        );
    }

    navigateStep = (delta, hasMixPanelTracking = false, mixpanel) => {
        if (hasMixPanelTracking) {
            this.mixpanelTracking(hasMixPanelTracking, mixpanel);
        }
        const step = this.getNextStep(delta);
        this.setState({ step });
    };

    getNextStep(delta = 1, step = this.state.step) {
        for (
            let next = step + delta;
            next >= 0 && next <= this.getSteps().length - 1;
            next += delta
        ) {
            const { component } = this.getSteps()[next];
            if (!(component.skip && component.skip(this.props, this.state))) {
                return next;
            }
        }
        return null;
    }

    mixpanelTracking = (hasMixPanelTracking, mixpanel, skip = false) => {
        if (hasMixPanelTracking) {
            mixpanel(skip);
        }
    };

    handleNextClick = (
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm
    ) => {
        if (hasNextStep) {
            this.mixpanelTracking(hasMixPanelTracking, mixpanel);
            this.navigateStep(1);
            return;
        }

        this.mixpanelTracking(hasMixPanelTracking, mixpanel);
        submitForm();
    };

    handleSkipButton = (confirmText, hasMixPanelTracking, mixpanel) => {
        this.setState({
            showConfirm: true,
            confirmText,
            hasMixPanelTracking,
            mixpanel
        });
    };

    handleGetBrewScore = (
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm
    ) => {
        const { values } = this.props;
        const data = this.props.mapValuesToBrewScorePayload(values);
        this.props.getBrewScore(data);
    };

    getBrewScoreButton = (
        canProceed,
        canNavigate,
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm
    ) => {
        const { brewScore, searchAgents } = this.props;
        const isFetchingBrewScore = get(brewScore, "isFetchingBrewScore");
        const isFetchingSearchAgents = get(
            searchAgents,
            "isFetchingSearchAgents"
        );

        return (
            <Button
                type="button"
                disabled={!(canProceed && canNavigate)}
                className={` ${
                    !(canProceed && canNavigate) ? "is-disabled" : ""
                }`}
                onClick={() => {
                    this.handleGetBrewScore(
                        hasNextStep,
                        hasMixPanelTracking,
                        mixpanel,
                        submitForm
                    );
                    this.handleBrowseAgents(
                        hasNextStep,
                        hasMixPanelTracking,
                        mixpanel,
                        submitForm
                    );
                }}
                isLoading={isFetchingBrewScore && isFetchingSearchAgents}
            >
                VIEW MY AGENTS
            </Button>
        );
    };

    handleBrowseAgents = (
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm
    ) => {
        const { values } = this.props;
        const data = this.props.mapValuesToSearchAgentsPayload(values);
        this.props
            .getSearchAgents(data)
            .then(() =>
                this.handleNextClick(
                    hasNextStep,
                    hasMixPanelTracking,
                    mixpanel,
                    submitForm
                )
            );
    };

    getBrowseAgents = (
        canProceed,
        canNavigate,
        hasNextStep,
        hasMixPanelTracking,
        mixpanel,
        submitForm
    ) => {
        const { searchAgents } = this.props;
        const isFetchingSearchAgents = get(
            searchAgents,
            "isFetchingSearchAgents"
        );
        return (
            <Button
                type="button"
                disabled={!(canProceed && canNavigate)}
                className={` ${
                    !(canProceed && canNavigate) ? "is-disabled" : ""
                }`}
                onClick={() =>
                    this.handleBrowseAgents(
                        hasNextStep,
                        hasMixPanelTracking,
                        mixpanel,
                        submitForm
                    )
                }
                isLoading={isFetchingSearchAgents}
            >
                BROWSE AGENTS
            </Button>
        );
    };

    renderStepButtons = (options, mixpanel) => {
        const { step } = this.state;
        const { isSubmitting, submitForm } = this.props;
        const hasNextStep = this.getNextStep() !== null;

        let canProceed;
        let hideNextButton = false;
        let canSkip = false;
        let canNavigate = true;
        let confirmText = "Are you sure you want to skip?";
        let hasMixPanelTracking = false;
        let getBrewScore = false;
        let getBrowseAgents = false;
        let buttonName = "";
        let buttonsWithoutMargin = false;
        let hideBackButton = false;
        let isLoading = false;
        let handleNext = this.handleNextClick;
        if (typeof options === "boolean") {
            canProceed = options;
        } else {
            handleNext = options.handleNext || this.handleNextClick;
            isLoading = options.isLoading || false;
            canProceed = options.canProceed || false;
            canSkip = options.canSkip || false;
            if (canSkip && canSkip.confirmText)
                confirmText = canSkip.confirmText;
            if (options.canNavigate === false) canNavigate = false;
            hasMixPanelTracking = options.hasMixPanelTracking || false;
            getBrewScore = options.getBrewScore || false;
            getBrowseAgents = options.getBrowseAgents || false;
            hideNextButton = options.hideNextButton || false;
            buttonName = options.buttonName || "";
            buttonsWithoutMargin = options.buttonsWithoutMargin || false;
            hideBackButton = options.hideBackButton || false;
        }

        return (
            <Fragment>
                <div
                    className={`buyer__signup__controls${
                        buttonsWithoutMargin ? "--without-margin" : ""
                    }`}
                >
                    {step < 1 || hideBackButton ? null : (
                        <Button
                            type="button"
                            disabled={!canNavigate}
                            text
                            onClick={() => this.navigateStep(-1)}
                        >
                            {`${"< Back"}`}
                        </Button>
                    )}

                    {canSkip ? (
                        <Button
                            type="button"
                            inverted
                            onClick={() =>
                                this.handleSkipButton(
                                    confirmText,
                                    hasMixPanelTracking,
                                    mixpanel
                                )
                            }
                        >
                            Skip
                        </Button>
                    ) : null}

                    {getBrewScore ? (
                        this.getBrewScoreButton(
                            canProceed,
                            canNavigate,
                            hasNextStep,
                            hasMixPanelTracking,
                            mixpanel,
                            submitForm
                        )
                    ) : getBrowseAgents ? (
                        this.getBrowseAgents(
                            canProceed,
                            canNavigate,
                            hasNextStep,
                            hasMixPanelTracking,
                            mixpanel,
                            submitForm
                        )
                    ) : hideNextButton ? null : (
                        <Button
                            type="button"
                            disabled={!(canProceed && canNavigate)}
                            className={` ${
                                !(canProceed && canNavigate)
                                    ? "is-disabled"
                                    : ""
                            }`}
                            isLoading={isLoading}
                            onClick={() =>
                                handleNext(
                                    hasNextStep,
                                    hasMixPanelTracking,
                                    mixpanel,
                                    submitForm
                                )
                            }
                        >
                            {isSubmitting ? (
                                <i
                                    style={{ textAlign: "center", padding: 0 }}
                                    className="fas fa-spinner fa-spin"
                                />
                            ) : (
                                <Fragment>
                                    {hasNextStep
                                        ? buttonName
                                            ? buttonName
                                            : "Continue"
                                        : "Finish"}
                                </Fragment>
                            )}
                        </Button>
                    )}
                </div>
            </Fragment>
        );
    };

    trackProgress = () => {
        const {
            values,
            mapValuesToPayload,
            customBudgetRange,
            notifyUpdate
        } = this.props;
        const step = this.getSteps()[this.state.step];

        if (!(mapValuesToPayload && notifyUpdate)) return;

        const data = this.props.mapValuesToPayload(values);
        const payload = pickBy(
            data,
            value =>
                !(
                    value === undefined ||
                    (typeof value === "number" && isNaN(value)) ||
                    (typeof value === "string" && value.trim().length === 0)
                )
        );

        payload.onboardingFormState = JSON.stringify({
            values,
            budget: customBudgetRange.get(),
            step: step.key
        });

        const completedStep = this.getNextStep(-1);
        const stepInfo = getStepInfoByKey(
            this.getSteps()[completedStep || 0].key
        );

        return notifyUpdate(payload, stepInfo);
    };

    renderSkipConfirmation() {
        if (!this.state.showConfirm) return null;
        const { confirmText, hasMixPanelTracking, mixpanel } = this.state;
        const skip = true;

        return ReactDOM.createPortal(
            <Fragment>
                <div
                    className="modal-blocker"
                    onClick={e => e.preventDefault()}
                />
                <div className="modal-container" style={{ height: "auto" }}>
                    <div className="modal-header">
                        Please confirm
                        <div
                            className="modal-close"
                            onClick={() =>
                                this.setState({ showConfirm: false })
                            }
                        >
                            <i className="fa fa-times-circle" />
                        </div>
                    </div>
                    <div className="modal-body-container">
                        <div className="modal-body">
                            {confirmText}
                            <div className="buyer__signup__controls align-right">
                                <Button
                                    inverted
                                    onClick={() => {
                                        this.setState({ showConfirm: false });
                                        this.navigateStep(1);
                                        this.mixpanelTracking(
                                            hasMixPanelTracking,
                                            mixpanel,
                                            skip
                                        );
                                    }}
                                >
                                    Yes, Skip
                                </Button>
                                <Button
                                    onClick={() =>
                                        this.setState({ showConfirm: false })
                                    }
                                >
                                    No, Go Back
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>,
            document.querySelector("#modal-root")
        );
    }

    getSteps = () => {
        const { values } = this.props;
        const buyerCurrentLivingSituation = get(
            values,
            "buyerCurrentLivingSituation"
        );
        const buyerIsSellingCurrentHome = get(
            values,
            "buyerIsSellingCurrentHome"
        );
        const buyerIsUsingNetProceedsFromCurrentHome = get(
            values,
            "buyerIsUsingNetProceedsFromCurrentHome"
        );

        const buyerSignupSteps = [
            {
                key: "home_picked_out",
                label: "Your Location",
                component: StepLocation
            },

            { key: "home_address", label: "Address", component: StepAddress },
            {
                key: "home_type",
                label: "Type of Home",
                component: StepPropertyType
            },
            {
                key: "purchase_timeline",
                label: "Timeline",
                component: StepTimeline
            },
            { key: "home_budget", label: "Budget", component: StepBudget },
            {
                key: "down_payment",
                label: "Down Payment",
                component: StepDownPayment
            },
            { key: "language", label: "Language", component: StepLanguage },
            {
                key: "first_time_home_buyer",
                label: "Home Buyer",
                component: StepFirstTimeHomeBuyer
            },
            {
                key: "current_living_situation",
                label: "Living Situation",
                component: StepCurrentLivingSituation
            }
        ];

        const buyerSignUpLastSteps = [
            { key: "credit", label: "Credit", component: StepCreditCategory },
            { key: "summary", label: "Summary", component: StepSummary },
            {
                key: "scanning_agents",
                label: "Scanning agents...",
                component: StepScanningAgents
            },
            { key: "brew_score_agents", label: "Score", component: StepScore },
            { key: "confirmation", label: "Confirm", component: StepConfirm }
        ];

        if (
            buyerCurrentLivingSituation === "own" &&
            buyerIsSellingCurrentHome === "yes" &&
            buyerIsUsingNetProceedsFromCurrentHome === "yes"
        ) {
            return [
                ...buyerSignupSteps,
                {
                    key: "plan_selling",
                    label: "Plan Selling",
                    component: StepPlanSelling
                },
                {
                    key: "plan_using_proceeds",
                    label: "Using Proceeds",
                    component: StepPlanUsingProceeds
                },
                {
                    key: "plan_how_much_proceeds",
                    label: "How Much Proceeds",
                    component: StepHowMuchProceeds
                },
                ...buyerSignUpLastSteps
            ];
        }

        if (
            buyerCurrentLivingSituation === "own" &&
            buyerIsSellingCurrentHome === "yes"
        ) {
            return [
                ...buyerSignupSteps,
                {
                    key: "plan_selling",
                    label: "Plan Selling",
                    component: StepPlanSelling
                },
                {
                    key: "plan_using_proceeds",
                    label: "Using Proceeds",
                    component: StepPlanUsingProceeds
                },
                ...buyerSignUpLastSteps
            ];
        }

        if (buyerCurrentLivingSituation === "own") {
            return [
                ...buyerSignupSteps,
                {
                    key: "plan_selling",
                    label: "Plan Selling",
                    component: StepPlanSelling
                },
                ...buyerSignUpLastSteps
            ];
        }

        return [...buyerSignupSteps, ...buyerSignUpLastSteps];
    };

    render() {
        const {
            status: { formState = "initial" }
        } = this.props;

        if (formState === "submitted") {
            this.updateUrlPath("finish");
            return <StepInbox />;
        }

        const StepComponent = this.getSteps()[this.state.step].component;
        if (!StepComponent) return null;

        if (formState === "submitting") return <BuyerSignUpFinishedScreen />;

        return (
            <Fragment>
                <Form className="buyer__signup buyer">
                    {this.renderProgress()}
                    {formState === "initial" ? (
                        <StepComponent
                            {...this.props}
                            isBuyerFlow={true}
                            onNavigateStep={this.navigateStep}
                            onTrackProgress={this.trackProgress}
                            onSkipStep={options =>
                                this.setState({
                                    ...options,
                                    showConfirm: true
                                })
                            }
                            renderNavigation={this.renderStepButtons}
                        />
                    ) : null}
                </Form>
                {this.renderSkipConfirmation()}
            </Fragment>
        );
    }
}

WizardStepsForm.propTypes = {
    status: PropTypes.object,
    userProfile: PropTypes.object,
    userInfo: PropTypes.object,
    isSubmitting: PropTypes.bool,
    submitForm: PropTypes.func,
    validateForm: PropTypes.func,
    mapValuesToPayload: PropTypes.func,
    mapValuesToBrewScorePayload: PropTypes.func,
    mapValuesToSearchAgentsPayload: PropTypes.func,
    notifyUpdate: PropTypes.func,
    customBudgetRange: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func,
        update: PropTypes.func
    }),
    getBrewScore: PropTypes.func,
    brewScore: PropTypes.object,
    getSearchAgents: PropTypes.func,
    searchAgents: PropTypes.object,
    onContactRebate: PropTypes.func,
    isFetchingContactRebate: PropTypes.bool,
    successContactRebate: PropTypes.bool,
    transactionContactTypes: PropTypes.object,
    history: PropTypes.any
};

export default WizardStepsForm;
