import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import size from "lodash/size";
import invoke from "lodash/invoke";
import Tour from "reactour";
import UserRealtorProfile from "../UserRealtorProfile";
import Step from "./Step/Step";
import { preventRealtorTourFromReAppearing } from "../../../reducers/user/UserActions";

class TourRealtorProfileComponent extends React.Component {
    static propTypes = {
        onPreventTourFromReAppearing: PropTypes.func,
        steps: PropTypes.array,
        canEdit: PropTypes.bool,
        isPublicView: PropTypes.bool,
        adminProfile: PropTypes.any
    };

    static defaultProps = {
        steps: [
            {
                selector: "#realtor-tour-experience",
                content:
                    "Please enter the number of years of experience you have here."
            },
            {
                selector: "#realtor-tour-languages",
                content:
                    "If you speak a language other than English, please enter it here so our proprietary algorithm matches you with leads who would like to work with agents that can speak their native language."
            },
            {
                selector: "#realtor-tour-designations",
                content: "If you have any Designations, please enter them here."
            },
            {
                selector: "#realtor-tour-certifications",
                content:
                    "If you have any Certifications, please enter them here."
            },
            {
                selector: "#realtor-tour-specializations",
                content:
                    "If you have any Specializations, please enter them here."
            },
            {
                selector: "#realtor-tour-interests",
                content:
                    "Please enter your interests here so leads can connect with you based on common interests."
            },
            {
                selector: "#realtor-tour-favorite-cuisines",
                content:
                    "Please enter your favorite cuisines here so leads can connect with you based on common cuisines."
            },
            {
                selector: "#realtor-tour-biography",
                content: "Tell us a little more about yourself here."
            },
            {
                selector: "#realtor-tour-properties-sold",
                content:
                    "Please enter the number of properties you have sold here."
            },
            {
                selector: "#realtor-tour-avg-sale-price",
                content:
                    "Please enter your average sales price here so that our proprietary algorithm matches you with leads who are looking for homes at a similar price point."
            },
            {
                selector: "#realtor-tour-avg-number-beedrooms",
                content:
                    "Please enter your average number of bedrooms here so that our proprietary algorithm matches you with leads who are looking for homes at a similar designation."
            },
            {
                selector: "#realtor-tour-complete-video-interview",
                content:
                    "Please complete your video interview so leads can connect with you at a personal level."
            }
        ]
    };

    constructor(props) {
        super(props);
        this.state = { isTourOpen: false, step: 0 };
    }

    componentDidMount = () => {
        if (this.hasWentThroughTour()) {
            return;
        }

        this.openTour();
    };

    hasWentThroughTour = () =>
        get(
            this.props.profile,
            "publicProfile.userInfo.hasCompletedOnboarding"
        );

    preventFromDisplayingTourAgain = () => {
        if (this.hasWentThroughTour()) {
            return;
        }

        invoke(this.props, "onPreventTourFromReAppearing");
    };

    disableScroll = () => disableBodyScroll(document.body);

    enableScroll = () => enableBodyScroll(document.body);

    buildTourStepsWithFooterActions = () => {
        const steps = this.props.steps;
        const totalSteps = size(steps);
        return map(steps, (step, index) => ({
            ...step,
            content: (
                <Step
                    currentStep={index}
                    totalSteps={totalSteps}
                    onClose={this.closeTour}
                    onBack={this.goBack}
                    onNext={this.goNext}
                    onFinish={this.closeTour}
                >
                    {step.content}
                </Step>
            )
        }));
    };

    goNext = () => {
        const totalSteps = size(this.props.steps);
        this.preventFromDisplayingTourAgain();
        this.setState({ step: Math.min(totalSteps, this.state.step + 1) });
    };

    goBack = () => {
        this.setState({ step: Math.max(0, this.state.step - 1) });
    };

    openTour = () => {
        this.disableScroll();
        this.setState({ isTourOpen: true });
    };

    closeTour = () => {
        this.enableScroll();
        this.preventFromDisplayingTourAgain();
        this.setState({ isTourOpen: false });
    };

    canEditAgentName = () => {
        const { adminProfile } = this.props;
        const adminRoles = get(adminProfile, "publicProfile.userInfo.roles");
        const adminRoleNames = map(adminRoles, "name");
        const anyPermissionToEdit = find(
            adminRoleNames,
            role => role === "ROLE_BREWCREW_REALTOR" || role === "ROLE_BREWCREW"
        );

        return anyPermissionToEdit != null;
    };

    render = () => (
        <section>
            <Tour
                className="tour-realtor-profile"
                steps={this.buildTourStepsWithFooterActions()}
                goToStep={this.state.step}
                closeWithMask={false}
                disableInteraction={true}
                disableDotsNavigation={true}
                disableKeyboardNavigation={true}
                rounded={5}
                showButtons={false}
                showCloseButton={false}
                showNavigation={false}
                showNavigationNumber={false}
                showNumber={false}
                isOpen={this.state.isTourOpen}
                maskSpace={8}
            ></Tour>
            <UserRealtorProfile
                {...this.props}
                canEdit={!this.props.isPublicView}
                canEditAgentName={this.canEditAgentName()}
            />
        </section>
    );
}

const TourRealtorProfile = connect(
    state => ({
        adminProfile: get(state, "user.cacheAdminProfile")
    }),
    {
        onPreventTourFromReAppearing: preventRealtorTourFromReAppearing
    }
)(TourRealtorProfileComponent);

export default TourRealtorProfile;
