import get from 'lodash/get';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { Button } from '../Button/Button';
import ContentModal from '../ContentModal/ContentModal';

import './adminProfileCampaigns.css';

class AdminProfileCampaigns extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisibleCreateCampaign: false,
        };
    }

    getIsFetchedCampaigns = () => get(this.props.admin, 'getCampaigns.isFetchedCampaigns');

    getIsFetchingCampaigns = () => get(this.props.admin, 'getCampaigns.isFetchingCampaigns');

    buildCampaignTable = () => (
        <div className="admin-profile__table-container">
        </div>
    );

    buildResults = () => {
        if (this.getIsFetchingCampaigns()) {
            return <SpinnerLoader />;
        }

        return this.buildCampaignTable();
    };

    onOpenCreateCampaign = () => this.setState({ isVisibleCreateCampaign: true });

    onCloseCreateCampaign = () => this.setState({ isVisibleCreateCampaign: false });

    buildModalCreateCampaign = () => (
        <ContentModal
            isVisible={this.state.isVisibleCreateCampaign}
            onClose={this.onCloseCreateCampaign}
            className="admin-role-tables"
            isCancelButton={false}
            progress
        />
    );

    buildCreateCampaignButton = () => (
        <div className="admin-profile-campaigns__title-button">
            <Button
                onClick={() => this.onOpenCreateCampaign()}
                shadow={false}
            >
                CREATE NEW CAMPAIGN
            </Button>
        </div>
    );

    render = () => (
        <div className="admin-profile-campaigns">
            {this.buildModalCreateCampaign()}
            <div className="admin-profile-campaigns__title">
                <div className="admin-profile-campaigns__title-text">
                    Campaigns
                </div>
                {this.buildCreateCampaignButton()}
            </div>
            <div className="admin-profile-campaigns__container">
                {this.buildResults()}
            </div>
        </div>
    )
}

AdminProfileCampaigns.propTypes = {
    admin: PropTypes.object,
};

AdminProfileCampaigns.defaultProps = {
    dateFormat: 'MM.DD.YYYY hh:mm a',
};

export default connect(
    (state) => ({
        admin: state.admin,
    }),
    {},
)(AdminProfileCampaigns);
