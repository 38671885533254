import React, { Component } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import ProfilePictureModal from "./ProfilePictureModal/ProfilePictureModal";
import { updateProfilePicture } from "../../util/service_api";
import isFunction from "lodash/isFunction";

import "./profilepicture.css";

export class ProfilePicture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            showModal: false
        };
        this.updateUploadImageSrc = React.createRef();
    }

    fileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    apply = file => {
        this.fileToBase64(file).then(base64Picture => {
            this.setState({
                image: base64Picture
            });
            updateProfilePicture(base64Picture).then(() =>
                this.onSuccessUploadingPicture(base64Picture)
            );
        });
        this.closeProfilePictureModal();
    };

    onSuccessUploadingPicture = picture => {
        if (isFunction(this.props.onUploadPicture)) {
            this.props.onUploadPicture(picture);
        }
    };

    closeProfilePictureModal = () => {
        this.setState({ showModal: false });
        this.updateUploadImageSrc.current.updateSrcImageOnModalClose();
    };

    openProfilePictureModal = () => {
        this.setState({ showModal: true });
    };

    buildIconButton = () => (
        <div
            className="profile-picture__plh"
            onClick={this.openProfilePictureModal}
        >
            <div className="profile-picture__update-section">
                <i className="fas fa-camera profile-picture__camera" />
                <span className="profile-picture__text">Update</span>
            </div>
        </div>
    );

    render = () => {
        return (
            <div className={`profile-picture ${this.props.className}`}>
                <ProfilePictureModal
                    onUpload={this.apply}
                    isVisible={this.state.showModal}
                    onClose={this.closeProfilePictureModal}
                    ref={this.updateUploadImageSrc}
                />
                <Image
                    className="profile-picture__image"
                    src={this.props.picture}
                    circle
                />
                {this.props.canEdit && this.buildIconButton()}
            </div>
        );
    };
}

ProfilePicture.propTypes = {
    picture: PropTypes.string.isRequired,
    onUploadPicture: PropTypes.func,
    canEdit: PropTypes.bool
};

export default ProfilePicture;
