import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';

import './step-buttons.css';

export default class StepButtons extends React.PureComponent {
    static propTypes = {
        onBack: PropTypes.func,
        onSkip: PropTypes.func,
        onNext: PropTypes.func,
        onFinish: PropTypes.func,
        blockNavigation: PropTypes.bool,
        blockForward: PropTypes.bool,
        isSubmitting: PropTypes.bool
    };

    buildBackButton = () => (
        <Button
            type="button"
            text
            onClick={this.props.onBack}
            disabled={this.props.blockNavigation}
        >
            {'< Back'}
        </Button>
    );

    buildSkipButton = () => (
        <Button
            type="button"
            inverted
            onClick={this.props.onSkip}
            disabled={this.props.blockNavigation || this.props.blockForward}
        >
            Skip
        </Button>
    );

    buildNextButton = () => (
        <Button
            type="button"
            onClick={this.props.onNext}
            disabled={this.props.blockNavigation || this.props.blockForward}
        >
            Continue
        </Button>
    );

    buildFinishButton = () => (
        <Button
            type="submit"
            onClick={this.props.onFinish}
            disabled={this.props.blockNavigation || this.props.blockForward}
            isLoading={this.props.isSubmitting}
        >
            Finish
        </Button>
    );

    render = () => (
        <footer>
            {this.props.displayBackButton && this.buildBackButton()}
            {this.props.displaySkipButton && this.buildSkipButton()}
            {this.props.displayNextButton && this.buildNextButton()}
            {this.props.displayFinishButton && this.buildFinishButton()}
        </footer>
    );
}
