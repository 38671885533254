import React from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import get from "lodash/get";
import map from "lodash/map";
import includes from "lodash/includes";
import { READ_ONLY_USER } from "../../shared/constants";
import WizardStepsContainer from "./SignUpSteps/WizardStepsContainer";

class EmdSignUp extends React.PureComponent {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.any,
        isEdit: PropTypes.bool,
        editEMD: PropTypes.any
    };

    getIsImpersonatingUser = () =>
        get(
            this.props.user,
            "profile.publicProfile.userInfo.isBeingImpersonated"
        );

    getRoleNames = () =>
        map(
            get(
                this.props.user,
                "cacheAdminProfile.publicProfile.userInfo.roles"
            ),
            "name"
        );

    getIsReadOnlyUserRole = () => includes(this.getRoleNames(), READ_ONLY_USER);

    componentDidMount = () => {
        const { history } = this.props;
        const isReadOnly =
            this.getIsImpersonatingUser() && this.getIsReadOnlyUserRole();

        if (isReadOnly) {
            history.replace("/");
        }
    };

    render() {
        return <WizardStepsContainer {...this.props} />;
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(EmdSignUp);
