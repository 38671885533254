import axios from 'axios';

import APIDomain from '../shared/apiSettings';

axios.defaults.baseURL = APIDomain;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-XSRF-Token';

export const getHeaders = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
});

export const getBaseClient = () =>
    axios.create({
        baseURL: APIDomain,
        headers: getHeaders(),
        withCredentials: true
    });
