import React, { Fragment } from 'react';
import { Field } from 'formik';
import PasswordStrengthInfoTooltip from '../../../PasswordStrengthInfoTooltip';
import termsAndConditionsContent from './EmdTermsAndConditions';
import BaseWizardStep from './BaseWizardStep';
import ScrollToTopOnMount from '../../../ScrollTopOnMount/ScrollTopOnMount';

class StepCreateAccount extends BaseWizardStep {
    buildEmailField = () => {
        const fieldName = 'email';
        return (
            <div className="buyer__signup__field">
                <Field
                    name={fieldName}
                    component="input"
                    type="email"
                    placeholder="EMAIL"
                />
                {this.buildFieldError(fieldName)}
            </div>
        );
    };

    buildPasswordField = () => (
        <div className="buyer__signup__field__row">
            <div className="buyer__signup__field">
                <Field
                    type="password"
                    name="password"
                    placeholder="PASSWORD"
                />
                <div className="password__error">
                    {this.buildFieldError('password')}
                </div>
            </div>

            <div className="buyer__signup__field">
                <Field
                    type="password"
                    name="password2"
                    placeholder="CONFIRM PASSWORD"
                />
                <div className="buyer__signup__field__tally">
                    <PasswordStrengthInfoTooltip />
                </div>
                <div className="password__error">
                    {this.buildFieldError('password2')}
                </div>
            </div>
        </div>
    );

    buildTosLink = () => (
        <a
            className="signup__emd--links"
            target="_blank"
            rel="noopener noreferrer"
            href="/terms-of-use-emd"
        >
            Terms of Service
        </a>
    );

    buildPrivacyPolicyLink = () => (
        <a
            className="signup__emd--links"
            target="_blank"
            rel="noopener noreferrer"
            href="/privacy-policy-emd"
        >
            Privacy Policy
        </a>
    );

    buildDwollaTosLink = () => (
        <a
            className="signup__emd--links"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dwolla.com/legal/tos/"
        >
            Terms of Service
        </a>
    );

    buildDwollaPrivacyPolicyLink = () => (
        <a
            className="signup__emd--links"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dwolla.com/legal/privacy/"
        >
            Privacy Policy
        </a>
    );

    buildTosField = () => {
        const fieldName = 'acceptedTermsAndConditions';
        return (
            <section className="buyer__signup__field buyer__signup__create-account-container-checkbox">
                <Field className="buyer__signup__create-account-checkbox" component="input" type="checkbox" name={fieldName} value />
                <div className="lb-copy-section-text">
                    {'By checking this box, you agree to our '}
                    {this.buildTosLink()}
                    {' and '}
                    {this.buildPrivacyPolicyLink()}
                    {', as well as our partner Dwolla’s '}
                    {this.buildDwollaTosLink()}
                    {' and '}
                    {this.buildDwollaPrivacyPolicyLink()}
                    {'.'}
                </div>
                {this.buildFieldError(fieldName)}
            </section>
        );
    };

    buildTermsAndConditionsField = () => (
        <div className="buyer__signup__field__emd buyer__signup__field__row">
            <div className="buyer__signup__field__emd buyer__signup__field">
                <div className="buyer__signup__scrollable">
                    {termsAndConditionsContent()}
                </div>
            </div>
        </div>
    );

    validateStep = () => this.areFieldsValid([
        'email',
        'password',
        'password2',
        'acceptedTermsAndConditions',
    ]);

    render() {
        const isStepFulfilled = this.validateStep();
        return (
            <Fragment>
                <ScrollToTopOnMount />
                <div className="buyer__signup__step is-higher">
                    <div className="buyer__signup__fieldset is-background-white">
                        <div className="buyer__signup__title">
                            Create Your Secure, Free Account
                        </div>
                        <div className="buyer__signup__field__row">
                            {this.buildEmailField()}
                        </div>
                        {this.buildPasswordField()}
                        <div className="buyer__signup__field__row">
                            {this.buildTosField()}
                        </div>
                    </div>
                    { this.renderStepButtons({
                        canProceed: isStepFulfilled,
                        registerSubmit: true,
                        agreeAndContinue: true,
                        buttonsWithoutMargin: true,
                    }) }
                </div>
            </Fragment>
        );
    }
}

export default StepCreateAccount;
