import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import FinicityFrame from './FinicityFrame';

import './Finicity.css';

class FinicityPopup extends Component {
    static propTypes = {
        url: PropTypes.string,
        onComplete: PropTypes.func.isRequired,
        mixpanelTrack: PropTypes.bool,
    };

    render() {
        const { url, onComplete, mixpanelTrack } = this.props;

        return ReactDOM.createPortal(
            <Fragment>
                <div
                    className="modal-blocker"
                    onClick={e => e.preventDefault()}
                />
                <div className="modal-container for-finicity-frame">
                    <div className="modal-body-container">
                        <div className="modal-body is-loading">
                            <FinicityFrame url={url} onComplete={onComplete} mixpanelTrack={mixpanelTrack} />
                        </div>
                    </div>
                </div>
            </Fragment>,
            document.querySelector('#modal-root')
        );
    }
}

FinicityPopup.defaultProps = {
    mixpanelTrack: false,
};

export default FinicityPopup;
