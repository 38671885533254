import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form } from 'formik';
import get from 'lodash/get';
import RealtorSignUpSuccess from './RealtorSignUpSuccess/RealtorSignUpSuccess';
import SpinWheelView from '../../SpinWheel/SpinWheelView';
import AboutYourself from './AboutYourself/AboutYourself';
import AboutYourJob from './AboutYourJob/AboutYourJob';
import AboutYourBrokerage from './AboutYourBrokerage/AboutYourBrokerage';
import Review from './Review/Review';
import SimpleWizard from '../../Wizard/SimpleWizard';
import { parseQueryParams } from '../../../util/util';

import '../signupform.css';
import './index.css';

const STEP_NAMES = ['aboutYourself', 'aboutYourJob', 'aboutYourBrokerage', 'review'];

class RealtorSignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = { currentStep: 0 };
    }

    onNavigate = (step, index, steps) => {
        this.setState({ currentStep: index })
    };

    isStepInvalid = (stepName) => {
        const { errors, touched } = this.props;
        const untouched = !get(touched, stepName);
        const hasErrors = get(errors, stepName) != null;
        return untouched || hasErrors;
    };

    isCurrentStepInvalid = () => {
        const currentStepName = STEP_NAMES[this.state.currentStep];
        return currentStepName && this.isStepInvalid(currentStepName);
    };

    isNotInFinalStep = () => {
        const lastStepIndex = STEP_NAMES.length - 1;
        return this.state.currentStep < lastStepIndex;
    };

    onSelectState = () => {
        const emptyBrokerage = {
            brokerFirstName: '',
            brokerPhoneNumber: '',
            brokerEmail: '',
            brokerage: undefined,
            recaptchaSuccessfullyCompleted: false
        };
        const touched = false;
        this.props.setFieldValue('aboutYourBrokerage', emptyBrokerage, touched);
    };

    render = () => {
        const { status, values } = this.props;

        if (status && status.success) {
            const queryParams = parseQueryParams(this.props.location.search);
            const inman = get(queryParams, 'inman');

            if (inman === 'true') {
                const email = get(values, 'email');
                const wheelId = Number(get(queryParams, 'wheel_id'));

                return <SpinWheelView email={email} wheelId={wheelId} />;
            }
            return <RealtorSignUpSuccess />;
        }

        return (
            <Form className="realtor__signupform">
                <SimpleWizard
                    initialStep={0}
                    isSubmitting={this.props.isSubmitting}
                    displayProgressHeader={this.isNotInFinalStep()}
                    blockForward={this.isCurrentStepInvalid()}
                    onNavigate={this.onNavigate}
                    steps={[
                        {
                            label: <span>About<br />Yourself</span>,
                            component: <AboutYourself {...this.props} />
                        },
                        {
                            label: <span>About<br />Your Job</span>,
                            component: (
                                <AboutYourJob
                                    {...this.props}
                                    onSelectState={this.onSelectState}
                                />
                            )
                        },
                        {
                            label: <span>About<br/>Your Brokerage</span>,
                            component: (
                                <AboutYourBrokerage
                                    {...this.props}
                                    stateAbbreviation={get(this.props, 'values.aboutYourJob.stateAbbreviation')}
                                />
                            )
                        },
                        {
                            isSubmittable: true,
                            hideInProgressHeader: true,
                            label: null,
                            component: <Review {...this.props} />
                        }
                    ]}
                />
            </Form>
        );
    };
}

RealtorSignUpForm.propTypes = {
    error: PropTypes.any,
    errors: PropTypes.object,
    touched: PropTypes.shape({}),
    isSubmitting: PropTypes.bool,
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
    dirty: PropTypes.bool,
    status: PropTypes.string,
    realtorPositions: PropTypes.array,
    realtorLicensedStates: PropTypes.array,
    realtorUsStates: PropTypes.array,
    setFieldTouched: PropTypes.func,
    isValid: PropTypes.bool,
};

export default withRouter(RealtorSignUpForm);
