import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { format } from 'date-fns';
import AvatarAndNameCol from './AvatarAndNameCol';

import './adminRoleTables.css';

export default class RolesTable extends React.Component {
    buildRoles = (user) => (
        <div className="admin-role-tables__brewcrew-roles">
            {map(get(user, 'roles'), (role, index) => (
                <div className="admin-role-tables__brewcrew-role" key={index}>
                    {get(role, 'name')}
                </div>
            ))}
        </div>
    )

    buildDate = (user) => (
        <div className="admin-role-tables__date-content">
            <div className="admin-role-tables__date-format">
                {format(get(user, 'ts'), this.props.dateFormat)}
            </div>
        </div>
    )

    buildFirstRow = (user) => (
        <div className="admin-role-tables__item-row">
            <div className="admin-role-tables__item-row-section-one">
                <div className="admin-role-tables__brewcrew-avatar">
                    <div className="admin-role-tables__brewcrew-title">USER</div>
                    <AvatarAndNameCol user={{userInfo: user}} />
                </div>
            </div>
            <div className="admin-role-tables__item-row-section-two">
                <div className="admin-role-tables__brewcrew-roles-content admin-role-tables__item-row-section-two-item">
                    <div className="admin-role-tables__brewcrew-title">ROLE</div>
                    {this.buildRoles(user)}
                </div>
                <div className="admin-role-tables__brewcrew-date admin-role-tables__item-row-section-two-item">
                    <div className="admin-role-tables__brewcrew-title">CREATED DATE</div>
                    {this.buildDate(user)}
                </div>
            </div>
        </div>
    );

    buildContentList = () => (
        <div className="admin-role-tables__body">
            {map(this.props.users, (user, index) => {
                return (
                    <div className="admin-role-tables__item" key={index}>
                        <div className="admin-role-tables__role">
                            {this.buildFirstRow(user)}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    render = () => (
        <div>
            {this.buildContentList()}
        </div>
    );
}

RolesTable.propTypes = {
    dateFormat: PropTypes.string,
    users: PropTypes.any,
};
