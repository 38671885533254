import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, getIn } from 'formik';
import Button from '../../components/Button/Button';

import GlyphIconChat from '../../styles/assets/icon-Chat.svg';
import GlyphIconPlane from '../../styles/assets/icon-PaperPlane.svg';

import './resetpasswordform.module.css';

class ResetPasswordForm extends Component {
    getFieldError(fieldName) {
        const { errors, touched } = this.props;
        if (!getIn(touched, fieldName)) return;

        const message = getIn(errors, fieldName);
        if (typeof message === 'string') return message;
    }

    toggleForm() {
        this.props.history.push('/signin');
    }

    renderFieldError = (fieldName) => {
        const message = this.getFieldError(fieldName);
        if (message) return <div className="signin__field__error">{message}</div>;
    };

    renderFormError = () => {
        const {
            touched,
            values: { failed },
        } = this.props;
        if (failed && touched.email) {
            return <div className="signin__login-error">{failed}</div>;
        }
    };

    render() {
        const {
            isSubmitting,
            values: { accepted },
        } = this.props;

        return (
            <div>
                {accepted ? (
                    <div className="signin__form">
                        <p className="signin__message">{accepted}</p>
                    </div>
                ) : (
                    <Form className="signin__form">
                        <p className="signin__title">Reset Password</p>
                        <p className="signin__message">
                            Please enter your email address and we&#39;ll send you a link to reset
                            your password.
                        </p>

                        {this.renderFormError()}

                        <div className="signin__fieldset">
                            <div className="signin__field__row">
                                <div className="signin__field align-center">
                                    <Field type="text" name="email" placeholder="EMAIL" />
                                    {this.renderFieldError('email')}
                                </div>
                            </div>
                        </div>

                        <div className="signin__fieldset signin__controls">
                            <div className="signin__field__row">
                                <div className="signin__field width-50">
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        {isSubmitting ? (
                                            <div>
                                                <i
                                                    className="fas fa-spinner fa-spin"
                                                    style={{
                                                        textAlign: 'center',
                                                        padding: 0,
                                                    }}
                                                />
                                                <span style={{ paddingLeft: '8px' }}>
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            <Fragment>Submit</Fragment>
                                        )}
                                    </Button>
                                </div>
                                <Button
                                        text
                                        onClick={this.toggleForm.bind(this)}
                                    >
                                    {`${'< Back to Login'}`}
                                    </Button>
                            </div>
                        </div>
                    </Form>
                )}
                <div className="signin__footer">
                    <div className="signin__footer__block">
                        <img
                            className="signin__footer__icon"
                            src={GlyphIconPlane}
                            alt=""
                            width="42"
                        />
                        <div className="signin__footer__label">Email us:</div>
                        <div className="signin__footer__value">
                            <a href="mailto:hello@lemonbrew.com">hello@lemonbrew.com</a>
                        </div>
                    </div>
                    <div className="signin__footer__block">
                        <img
                            className="signin__footer__icon"
                            src={GlyphIconChat}
                            alt=""
                            width="36"
                        />
                        <div className="signin__footer__label">Call us:</div>
                        <div className="signin__footer__value">833-LEMONBREW (536-6627)</div>
                    </div>
                </div>
            </div>
        );
    }
}

ResetPasswordForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.shape({}),
    isSubmitting: PropTypes.bool,
    history: PropTypes.object,
    values: PropTypes.object,
};

export default ResetPasswordForm;
