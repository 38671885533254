import React from 'react';
import MaskedInput from 'react-text-mask'

const SsnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export default ({field, form, ...props}) => {
    const handleChange = (e) => {
        if(props.isInputField) {
            props.onChange(`${e.target.value}`.replace(/_/g, ''));
            return;
        }
        form.setFieldValue(field.name, `${e.target.value}`.replace(/_/g, ''))
    };

    return (
        <MaskedInput
            mask={SsnMask}
            {...field} {...props}
            onChange={handleChange}
        />
    );
};
