import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import trim from "lodash/trim";
import uniq from "lodash/uniq";
import { Button } from "../Button/Button";
import { NumberFormat } from "../NumberFormat/NumberFormat";
import SocialMedia from "../SocialMedia/SocialMedia";
import RealtorVideoProfileButton from "../RealtorVideoProfileButton/RealtorVideoProfileButton";

import "../UserProfile/user-profile.css";

const AgentFieldsBase = [
    {
        key: "servingIn",
        label: "Cities Serving"
    },
    {
        key: "experience",
        label: "Years of Experience"
    },
    {
        key: "propertiesSold",
        label: "# of Properties Sold"
    },
    {
        key: "avgSalePrice",
        label: "Average Home Price"
    },
    {
        key: "avgNoBedrooms",
        label: "Average # of Bedrooms"
    },
    {
        key: "languages",
        label: "Languages"
    },
    {
        key: "designations",
        label: "Designations"
    },
    {
        key: "specializations",
        label: "Specializations"
    },
    {
        key: "certifications",
        label: "Certifications"
    }
];

const AgentFieldsWithRebate = [
    {
        key: "rebate",
        label: "Rebate"
    },
    ...AgentFieldsBase
];

const propKey = (match, prop) => `${match.id}_${prop}`;

const mapEnumsToStringList = (match, prop, name = "name") =>
    map(get(match, prop, { [name]: "" }), name);

export default class AgentsInfo extends Component {
    buildSelectButton = (match, index, realtorIndex) => {
        const { handleSelectAgent, onChooseAgent, canSelectAgent } = this.props;

        return (
            <Button
                disabled={index === realtorIndex}
                onClick={
                    canSelectAgent
                        ? () => handleSelectAgent(match)
                        : onChooseAgent
                }
                type="button"
            >
                {index === realtorIndex ? "Pending" : "Select"}
            </Button>
        );
    };

    buildAgentMatches = () => {
        const {
            canSelectAgent,
            matches,
            profile,
            showRebate,
            showStatus,
            rebateAmount
        } = this.props;
        const matchesProperties = matches.map(match => ({
            rebate: (
                <NumberFormat
                    format="currency"
                    decimals={0}
                    number={rebateAmount}
                />
            ),
            agent: match,
            id: get(match, "userInfo.id"),
            picture: trim(get(match, "userInfo.profilePicUrl")),
            video: trim(get(match, "profileVideoUrl")),
            name: `${trim(get(match, "userInfo.firstName"))} ${trim(
                get(match, "userInfo.lastName")
            )}`,
            position: `${get(match, "position.name", "")} at ${get(
                match,
                "brokerage.name",
                ""
            )}`,
            experience: get(match, "yearsOfExperience"),
            propertiesSold: get(match, "numberOfPropertiesSold", ""),
            avgNoBedrooms: get(match, "averageNumberOfBedrooms", 1),
            avgSalePrice: (
                <NumberFormat
                    currency={get(match, "averageSalePrice.currency")}
                    format="currency"
                    decimals={0}
                    number={get(match, "averageSalePrice.amount")}
                />
            ),
            linkedInUrl: get(match, "linkedInUrl"),
            igUrl: get(match, "igUrl"),
            facebookUrl: get(match, "facebookUrl"),
            youtubeUrl: get(match, "youtubeUrl"),
            languages: mapEnumsToStringList(match, "languages").join(", "),
            servingIn: uniq(
                mapEnumsToStringList(match, "saleAddresses", "city")
            ).join(", "),
            designations: mapEnumsToStringList(match, "designations").join(
                ", "
            ),
            specializations: mapEnumsToStringList(
                match,
                "specializations"
            ).join(", "),
            certifications: mapEnumsToStringList(
                match,
                "professionalCertifications"
            ).join(", ")
        }));

        const realtorId = get(profile, "realtor.userInfo.id");
        const realtorName = get(profile, "realtor.userInfo.firstName");
        const realtorIndex = realtorId
            ? findIndex(matchesProperties, ["id", realtorId])
            : -1;
        const AgentFields = showRebate
            ? AgentFieldsWithRebate
            : AgentFieldsBase;

        return (
            <Fragment>
                {canSelectAgent && realtorName ? (
                    <div className="profile__personal__container">
                        <div className="profile__message is-centred">
                            <b>Be patient!</b> We are waiting on {realtorName}{" "}
                            to accept your match.
                        </div>
                    </div>
                ) : null}

                <table
                    className={`profile__agent__desktop__container profile__matches__table match-${
                        matches.length
                    } picked-realtor-${
                        realtorIndex >= 0 ? realtorIndex + 1 : ""
                    }`}
                >
                    <thead>
                        <tr>
                            <td />
                            {matchesProperties.map((match, index) => (
                                <td key={propKey(match, "name")}>
                                    {/* <div className="profile__matches__select-button for-select-button">
                                        {this.buildSelectButton(
                                            match,
                                            index,
                                            realtorIndex
                                        )}
                                    </div> */}

                                    <img
                                        className="profile__matches__picture"
                                        src={match.picture}
                                        alt=""
                                    />
                                    <div className="profile__matches__name">
                                        {match.name}
                                    </div>
                                    {/* {showStatus ? (
                                        <div className="profile__matches__status">
                                            <div className="profile__matches__status-text">
                                                AVAILABLE
                                            </div>
                                        </div>
                                    ) : null} */}
                                    <div className="profile__matches__position">
                                        {match.position}
                                    </div>
                                </td>
                            ))}
                        </tr>
                        {/* <tr>
                            <td />
                            {matchesProperties.map(match => (
                                <td
                                    key={propKey(match, "name")}
                                    className="for-video-button"
                                >
                                    <RealtorVideoProfileButton
                                        design="default-inverted"
                                        videoUrl={match.video}
                                        isImpersonatingUser={false}
                                    />
                                </td>
                            ))}
                        </tr> */}
                        {/* <tr>
                            <td />
                            {matchesProperties.map(match => (
                                <td
                                    key={propKey(match, "name")}
                                    className="for-media-button"
                                >
                                    <SocialMedia
                                        linkedInUrl={match.linkedInUrl}
                                        igUrl={match.igUrl}
                                        facebookUrl={match.facebookUrl}
                                        youtubeUrl={match.youtubeUrl}
                                    />
                                </td>
                            ))}
                        </tr> */}
                    </thead>
                    <tbody>
                        {AgentFields.map(field => (
                            <tr key={field.key}>
                                <th
                                    style={{ verticalAlign: "top" }}
                                >{`${field.label}:`}</th>
                                {matchesProperties.map(match => (
                                    <td
                                        style={{ verticalAlign: "top" }}
                                        key={propKey(match, field.key)}
                                        className={`field-${field.key}`}
                                    >
                                        {match[field.key] || "--"}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <td />
                            {matchesProperties.map((match, index) => (
                                <td
                                    key={propKey(match, "name")}
                                    className="for-select-button"
                                >
                                    {this.buildSelectButton(
                                        match,
                                        index,
                                        realtorIndex
                                    )}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
                <div
                    className={`profile__agent__mobile__container profile__matches picked-realtor-${
                        realtorIndex >= 0 ? realtorIndex + 1 : ""
                    }`}
                >
                    {matchesProperties.map((match, index) => (
                        <Fragment>
                            <div className="profile__agent__card">
                                <div key={propKey(match, "name")}>
                                    <div className="profile__matches__select-button for-select-button">
                                        {this.buildSelectButton(
                                            match,
                                            index,
                                            realtorIndex
                                        )}
                                    </div>
                                    <div className="profile__matches__name">
                                        {match.name}
                                    </div>
                                    <img
                                        className="profile__matches__picture"
                                        src={match.picture}
                                        alt=""
                                    />
                                    <div className="profile__matches__position">
                                        {match.position}
                                    </div>
                                </div>
                                <div
                                    key={propKey(match, "name")}
                                    className="for-video-button"
                                >
                                    <RealtorVideoProfileButton
                                        design="default-inverted"
                                        videoUrl={match.video}
                                        isImpersonatingUser={false}
                                    />
                                </div>
                                {AgentFields.map(field => (
                                    <div
                                        className="profile__details__list"
                                        key={field.key}
                                    >
                                        <div className="profile__details__label">
                                            {`${field.label}:`}
                                        </div>
                                        <div
                                            className="profile__details__value"
                                            key={propKey(match, field.key)}
                                        >
                                            {match[field.key]}
                                        </div>
                                    </div>
                                ))}
                                <div
                                    key={propKey(match, "name")}
                                    className="for-select-button"
                                >
                                    {this.buildSelectButton(
                                        match,
                                        index,
                                        realtorIndex
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </Fragment>
        );
    };

    render() {
        const hasMatches = !isEmpty(this.props.matches);
        if (hasMatches) return this.buildAgentMatches();
    }
}

AgentsInfo.defaultProps = {
    showRebate: false,
    showStatus: false
};

AgentsInfo.propTypes = {
    matches: PropTypes.array,
    canSelectAgent: PropTypes.bool,
    onChooseAgent: PropTypes.func,
    profile: PropTypes.object,
    handleSelectAgent: PropTypes.func,
    showRebate: PropTypes.bool,
    showStatus: PropTypes.bool,
    rebateAmount: PropTypes.any
};
