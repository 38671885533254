import find from 'lodash/find';
import { withFormik } from 'formik';
import {
    object as YupObject,
    string as YupString,
    mixed as YupAny,
    ref as YupRef,
    bool as YupBool,
} from 'yup';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import includes from 'lodash/includes';
import RealtorSignUpForm from './RealtorSignUpForm';
import APIDomain from '../../../shared/apiSettings';
import {
    YupPassword,
    YupUniqueEmail,
    YupUsPhoneNumber,
} from '../../../shared/validations';

// Wrap our form with the using withFormik HoC
const RealtorSignUpFormik = compose(
    withRouter,
    withFormik({
    // Transform outer props into form values
        mapPropsToValues: () => ({
            aboutYourself: {
                firstName: '',
                lastName: '',
                email: '',
                realtorPhone: '',
                password: '',
                password2: ''
            },
            aboutYourJob: {
                selectedState: undefined,
                stateAbbreviation: '',
                selectedRealtorPosition: undefined,
                realtorPosition: undefined,
                realtorIsFullTime: '',
                licenseID: ''
            },
            aboutYourBrokerage: {
                brokerFirstName: '',
                brokerPhoneNumber: '',
                brokerEmail: '',
                brokerage: undefined,
                recaptchaSuccessfullyCompleted: false
            },
            review: {
                acknowledgment: false
            }
        }),
        validationSchema: YupObject().shape({
            aboutYourself: YupObject().shape({
                firstName: YupString()
                    .trim()
                    .required('First Name is required'),
                lastName: YupString()
                        .trim()
                        .required('Last Name is required'),
                email: YupUniqueEmail('ROLE_REALTOR')
                    .required('Email is required'),
                realtorPhone: YupUsPhoneNumber()
                    .trim()
                    .required('Phone Number is required'),
                password: YupPassword()
                    .trim()
                    .label('Password')
                    .meta({ mustNotMatch: { path: 'email', label: 'Email' } })
                    .required('Password is required'),
                password2: YupString()
                    .trim()
                    .oneOf([YupRef('password'), null], 'Passwords don\'t match')
                    .required('Confirm Password is required'),
            }),
            aboutYourJob: YupObject().shape({
                selectedState: YupObject(),
                stateAbbreviation: YupString().required('Current State is required'),
                selectedRealtorPosition: YupObject(),
                realtorPosition: YupObject().required('Position is required'),
                realtorIsFullTime: YupString().required('Please select either Part-Time or Full-Time'),
                licenseID: YupString().typeError('Please provide a valid ID').required('License ID is required'),
            }),
            aboutYourBrokerage: YupObject().shape({
                brokerFirstName: YupString()
                    .trim()
                    .required('First Name is required'),
                brokerLastName: YupString()
                    .trim()
                    .required('Last Name is required'),
                brokerPhoneNumber: YupUsPhoneNumber()
                    .trim()
                    .required('Phone is required'),
                brokerEmail: YupString()
                    .trim()
                    .email('Invalid email address')
                    .required('Email is required'),
                brokerage: YupAny()
                    .test({
                        name: 'must-be-object-or-string',
                        test(value) {
                            if (typeof value === 'object' && value && value.brokerageId) return true;
                            if (typeof value === 'string' && value.length) return true;
                            
                            return this.createError({
                                message: 'Brokerage Firm is required',
                            });
                        },
                }),
                recaptchaSuccessfullyCompleted: YupBool().oneOf([true], 'Please complete ReCAPTCHA field'),
            }),
            review: YupObject().when('aboutYourBrokerage.brokerage.approved', {
                is: true,
                then: YupObject(),
                otherwise: YupObject({
                    acknowledgment: YupBool().oneOf([true]).required(),
                }),
            })
        }),
        // Submission handler
        handleSubmit(values, {
            setStatus, setSubmitting, props, setError,
        }) {
            setSubmitting(true);

            const stateAbbreviation = get(values, 'aboutYourJob.stateAbbreviation');
            const state = find(props.realtorUsStates, { abbreviation: stateAbbreviation }) || {};
            const approved = get(values, 'aboutYourBrokerage.brokerage.approved');
            let realtorBrokerageName;

            if (approved) {
                realtorBrokerageName = get(values, 'aboutYourBrokerage.brokerage');
            } else {
                const brokerage = get(values, 'aboutYourBrokerage.brokerage');
                const brokerageName = (
                    typeof brokerage === 'string' ?
                    brokerage :
                    get(values, 'aboutYourBrokerage.brokerage.name')
                );
                const brokerageId = (
                    get(brokerage, 'brokerageId') ||
                    get(brokerage, 'id')
                );

                realtorBrokerageName = {
                    name: brokerageName,
                    state,
                    brokerageId,
                    approved,
                    broker: {
                        id: get(values, 'aboutYourBrokerage.brokerId'),
                        firstName: get(values, 'aboutYourBrokerage.brokerFirstName'),
                        lastName: get(values, 'aboutYourBrokerage.brokerLastName'),
                        phoneNumber: get(values, 'aboutYourBrokerage.brokerPhoneNumber'),
                        email: get(values, 'aboutYourBrokerage.brokerEmail'),
                    },
                };
            }
            const userData = {
                firstName: get(values, 'aboutYourself.firstName'),
                lastName: get(values, 'aboutYourself.lastName'),
                email: get(values, 'aboutYourself.email'),
                password: get(values, 'aboutYourself.password'),
                phone: get(values, 'aboutYourself.realtorPhone'),
                realtorState: stateAbbreviation,
                realtorLicenseId: get(values, 'aboutYourJob.licenseID'),
                realtorBrokerageName,
                realtorPosition: get(values, 'aboutYourJob.realtorPosition'),
                realtorIsFullTime: get(values, 'aboutYourJob.realtorIsFullTime') === 'fullTime',
                acceptedTermsAndConditions: true,
            };

            axios({
                method: 'POST',
                url: `${APIDomain}/onboarding/realtor`,
                headers: {
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                data: userData,
                withCredentials: true,
            })
                .then((response) => {
                    if (response.status === 200) {
                        if (!includes(props.location.search, 'inman=true')) {
                            props.history.push('/signup/realtor/success');
                        }
                        setStatus({ success: true });
                    }
                    return response;
                })
                .catch((error) => { setError({ error }); })
                .finally(() => setSubmitting(false));
        },

    }),
)(RealtorSignUpForm);

const RealtorSignUpContainer = connect(
    ({ configApp }) => ({
        realtorPositions: configApp.config ? configApp.config.positions : [],
        realtorLicensedStates: configApp.config ? configApp.config.licensedStates : [],
        realtorUsStates: configApp.config ? configApp.config.usStates : [],
    }),
)(RealtorSignUpFormik);

export default RealtorSignUpContainer;
