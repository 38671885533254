import React from 'react';
import PropTypes from 'prop-types';
import BrokerageLabel from '../BrokerageLabel/BrokerageLabel';
import Button from '../Button/Button';

export default class BrokerageLabelButtonWrapper extends React.PureComponent {
    buildBrokerage = () => (
        <BrokerageLabel
            name={this.props.name}
            isApproved={this.props.isApproved}
            showApprovalStatusIcon={this.props.showApprovalStatusIcon}
            small={this.props.small}
        />
    );

    render = () => {
        const { onClick } = this.props;
        const brokerage = this.buildBrokerage();

        return (
            <Button 
                text
                onClick={onClick}
                shadow={false}
            >
                {brokerage}
            </Button>
        );
    };
}

BrokerageLabelButtonWrapper.propTypes = {
    name: PropTypes.string,
    isApproved: PropTypes.bool,
    showApprovalStatusIcon: PropTypes.bool,
    small: PropTypes.bool,
    onClick: PropTypes.func,
};
