import React, { Component } from 'react';
import PropTypes from 'prop-types';
import invoke from 'lodash/invoke';

import './realtorbiographyfield.css';

export default class RealtorBiographyField extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChange = (event) => {
        const newBiography = event.target.value;
        invoke(this.props, 'onEdit', newBiography);
    };

    buildEditableField = () => (
        <textarea
            className="form-control realtor-biography-field__field-editable"
            onChange={this.onChange}
            maxLength="2500"
            value={this.props.biography}
        />
    );

    buildFieldValue = () => (
        <p className="realtor-biography-field__field-value">
            {this.props.biography}
        </p>
    );

    render = () => {
        const { isEditing } = this.props;
        const field = isEditing
            ? this.buildEditableField()
            : this.buildFieldValue();

        return (
            <div id="realtor-tour-biography" className="realtor-biography-field">
                <h5 className="realtor-biography-field__field-label">
                    Biography
                </h5>
                {field}
            </div>
        );
    };
}

RealtorBiographyField.propTypes = {
    canEdit: PropTypes.bool,
    biography: PropTypes.string,
    onEdit: PropTypes.func,
    isEditing: PropTypes.bool,
};
